import { find } from 'lodash';
import { fhirExtensionUrls } from '@worklist-2/core/src';

export const getStudyId = data => data.id;
export const getStudyPriority = data =>
	find(data.extension, elem => elem.url === fhirExtensionUrls.common.priority)?.valueCoding?.display;
export const getStudyDescription = data => data.description;
export const getStudyStatus = data =>
	find(data.extension, elem => elem.url === fhirExtensionUrls.common.studyStatus)?.valueCoding?.display;
export const getStudyReferringPhysian = data => data.referringPhysician;

export const getPatientId = data => data.patientID;
export const getPatientName = data => data.subject.display;
export const getPatientRace = data => data;
export const getPatientIssuer = data =>
	find(
		data.extension,
		elem =>
			elem.url === fhirExtensionUrls.organization.issuer ||
			elem.url === fhirExtensionUrls.organization.defaultIssuer
	)?.valueReference.display;

export const getPatientSmoking = data => data.smokingStatus?.at(0)?.display;
