import React from 'react';
import useGetSpineLabels from '../../../useGetSpineLabels';

export const SpineLabel = ({ viewportId }) => {
	const { spineLabels } = useGetSpineLabels({ viewportId });

	return spineLabels ? (
		<span
			style={{
				alignSelf: 'center',
				fontWeight: '600',
				margin: 0,
				padding: 0,
				whiteSpace: 'nowrap',
				fontSize: '14px',
				lineHeight: 1.1,
				letterSpacing: '0.25px',
			}}
		>
			{spineLabels}
		</span>
	) : null;
};
