import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

const ListItemLink = ({
	icon,
	iconComponent,
	primary,
	to,
	id,
	sx,
	tabSelected,
	onClick,
	collapse,
	className,
	expandIcon,
	addIcon,
	isParent,
}) => {
	const renderLink = React.useMemo(
		() => React.forwardRef((itemProps, ref) => <RouterLink ref={ref} to={to} {...itemProps} role={undefined} />),
		[to]
	);

	const active = React.useMemo(
		() => to === `${location.pathname}` || to === `${location.pathname}/` || tabSelected,
		[to, tabSelected, location.pathname]
	);

	let iconPlaceHolder =
		icon ||
		(iconComponent &&
			iconComponent({
				active,
				sideNav: true,
				className: active ? 'active' : 'inactive',
			}));
	if (addIcon) {
		iconPlaceHolder = active && (
			<div
				style={{
					width: 6,
					height: 6,
					background: '#42a5f5',
					borderRadius: 6,
					margin: 'auto',
				}}
			/>
		);
	}
	let iconType = null;
	if (collapse) {
		iconType = expandIcon ? (
			<ExpandLess style={{ right: '-20px', position: 'relative' }} />
		) : (
			<ExpandMore style={{ right: '-20px', position: 'relative' }} />
		);
	}

	return (
		<ListItem
			button
			className={className}
			classes={{ selected: 'nav-link-active' }}
			component={renderLink}
			data-cy="list-item-link"
			id={id}
			selected={active}
			sx={sx}
			onClick={onClick}
		>
			<ListItemIcon sx={{ minWidth: '40px' }}>{iconPlaceHolder}</ListItemIcon>
			<ListItemText sx={{ opacity: active ? '1' : '0.6' }}>
				<span
					style={{
						fontWeight: isParent && active ? 'bolder' : 'normal',
					}}
				>
					{primary}
				</span>
			</ListItemText>
			{iconType}
		</ListItem>
	);
};

export default ListItemLink;
