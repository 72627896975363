import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PreviewIcon from '@mui/icons-material/Preview';
import PanToolIcon from '@mui/icons-material/PanTool';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const Code = ({ code, tooltip, onDragStart, onDragEnd }) => {
	const { t } = useTranslation('imageViewer');

	const renderCode = () => (
		<Box
			draggable
			sx={{
				display: 'flex',
				alignItems: 'center',
				border: '1px solid #4D4D4D',
				borderRadius: '10px',
				padding: '10px 20px',
				flex: '0.33333333333333',
				maxWidth: 'calc(33.33333333333333% - 49px)',
				cursor: 'move',
				'&:hover': {
					background: '#42A5F5',
					transition: '0.2s',
					'& .MuiSvgIcon-root, & .MuiTypography-root': {
						color: '#fff',
					},
				},
			}}
			onDragEnd={onDragEnd}
			onDragStart={onDragStart}
		>
			<PreviewIcon
				sx={{
					color: 'rgba(255, 255, 255, 0.6)',
					fontSize: '20px',
					marginRight: '10px',
				}}
			/>

			<Typography
				sx={{
					color: 'rgba(255, 255, 255, 0.8)',
					fontSize: '14px',
					lineHeight: '16px',
					letterSpacing: '0.25px',
					textTransform: 'uppercase',
					flex: '1',
				}}
			>
				{t(`hp.${code}`, { defaultValue: code })}
			</Typography>

			<PanToolIcon
				sx={{
					color: 'rgba(255, 255, 255, 0.6)',
					fontSize: '20px',
					marginRight: '10px',
				}}
			/>
		</Box>
	);

	if (tooltip) {
		return <Tooltip title={t(`hp.${tooltip}`, { defaultValue: tooltip })}>{renderCode()}</Tooltip>;
	}

	return renderCode();
};

export default Code;
