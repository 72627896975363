import { BROADCAST_EVENTS, TOOL_GROUPS } from '../contexts/consts/consts';
import { tools } from '../features/Wheel/tools';
import { useImageViewerMultiMonitorContext } from '../contexts/ImageViewerMultiMonitorContext';
import { useImageViewerCornerstoneContext } from '../contexts/ImageViewerCornerstoneContext';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';
import { useImageViewerWheelContext } from '../contexts/ImageViewerWheelContext';
import { useImageViewerSpineLabelingContext } from '../contexts/ImageViewerSpineLabelingContext';
import SpineLabelingTool from '../cornerstoneTools/SpineLabelingTool';
import CrosshairPointerTool from '../cornerstoneTools/CrosshairPointerTool';
import { useScoutLinesStoreSelector } from '../contexts/ScoutLinesContext';

const useOnSetActiveTool = () => {
	const { activeTool, setActiveTool, removeCine, activeCine, setLastAdjustmentTool } = useImageViewerWheelContext();
	const { postMessage } = useImageViewerMultiMonitorContext();
	const { changeActiveTool, viewportCustomTool } = useImageViewerCornerstoneContext();
	const { isMPRView, isFusionView } = useImageViewerLayoutContext();
	const { setIsShownSpineLabelingMenu } = useImageViewerSpineLabelingContext();
	const setScoutLines = useScoutLinesStoreSelector(state => state.setScoutLines);

	const excludedTools = ['RotateLeft', 'RotateRight', 'FlipVertically', 'FlipHorizontally', 'Invert'];
	const _updateLastAdjustmentTool = toolName => {
		const newTool = tools.find(t => t.toolName === toolName);
		if (newTool && newTool.group === TOOL_GROUPS.ADJUSTMENT && !excludedTools.includes(toolName)) {
			setLastAdjustmentTool(toolName);
		}
	};

	const _removeCineIfExist = viewportId => {
		const existingCine = activeCine.find(c => c.id === viewportId);
		if (existingCine) {
			const element = document.getElementById(viewportId);
			removeCine({ element });
		}
	};

	const onSetActiveTool = (toolName, viewportId, shouldPostMessage = true, shouldShowSpineLabelingMenu = true) => {
		_updateLastAdjustmentTool(toolName);
		_removeCineIfExist(viewportId);

		if (excludedTools.includes(toolName)) {
			if (isMPRView || isFusionView) {
				return;
			}

			const element = document.getElementById(viewportId);

			if (!element) {
				if (shouldPostMessage) {
					postMessage({
						event: BROADCAST_EVENTS.ACTIVE_TOOL_UPDATE,
						value: { viewportId, toolName },
					});
				}

				return;
			}

			return viewportCustomTool({ viewportId, toolName });
		}

		if (toolName === CrosshairPointerTool.toolName && activeTool !== CrosshairPointerTool.toolName) {
			setScoutLines(false);
		} else if (toolName !== CrosshairPointerTool.toolName && activeTool === CrosshairPointerTool.toolName) {
			setScoutLines(true);
		}

		changeActiveTool({ activeTool: toolName });

		setActiveTool(toolName);

		if (toolName === SpineLabelingTool.toolName && shouldShowSpineLabelingMenu) {
			setIsShownSpineLabelingMenu(true);
		}

		if (shouldPostMessage) {
			postMessage({
				event: BROADCAST_EVENTS.ACTIVE_TOOL_UPDATE,
				value: { viewportId, toolName },
			});
		}
	};

	return {
		onSetActiveTool,
		_updateLastAdjustmentTool,
		_removeCineIfExist,
	};
};

export default useOnSetActiveTool;
