import React from 'react';
import Box from '@mui/material/Box';

const Row = ({ children }) => (
	<Box
		sx={{
			display: 'flex',
			gap: '10px',
		}}
	>
		{children}
	</Box>
);

export default Row;
