import React, { useState, useEffect } from 'react';
import { useAppModeContext, useIsTablet, useIsMobile, useImportTabContext } from '@worklist-2/core/src';

import Draggable from 'react-draggable';
//MUI

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import UploadProgressFile from './UploadProgressFile';
import { Typography } from '@mui/material';
//context
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

const UploadProgressWindow = ({ isDownload }) => {
	const {
		filesUploading,
		showFileUploadWindow,
		setShowFileUploadWindow,
		showFileDownloadWindow,
		setShowFileDownloadWindow,
		reloadDocumentSection,
		setReloadDocumentSection,
		recentUploadedQuantity,
		filesDownloading,
	} = useImportTabContext();
	const filesProgress = isDownload ? [...filesDownloading] : [...filesUploading];
	const showWindow = isDownload ? showFileDownloadWindow : showFileUploadWindow;
	const setShowWindow = isDownload ? setShowFileDownloadWindow : setShowFileUploadWindow;
	let [activeDrag, setActiveDrag] = useState(0);
	const [position] = useState(appMode === 'patientPortal' ? { x: 1000, y: -700 } : { x: 0, y: 0 });
	const [customToastMsg, setCustomToastMsg] = useState();
	const { setToastMsg } = useToastMessageContext();
	const [minimizeWindow, setMinimizeWindow] = useState(false);

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const { sideBarIsOpen } = useImportTabContext();
	const marginLeft = isMobile || isTablet ? undefined : sideBarIsOpen ? '210px' : '100px';

	const { appMode } = useAppModeContext();

	const { t } = useTranslation('profile');

	let totalProgress = 0;
	for (const file of filesProgress) {
		totalProgress += file.progress;
	}
	const avgProgress = totalProgress / filesProgress?.length;

	const onStart = () => {
		setActiveDrag(++activeDrag);
	};

	const onStop = () => {
		setActiveDrag(--activeDrag);
	};
	const heading = (
		<Typography
			sx={{
				color: appMode === 'patientPortal' ? 'rgba(0, 0, 0, 0.8)' : '#C4C4C4',
				fontWeight: '500',
			}}
		>
			{isDownload ? t('Downloading') : t('Uploading')} {t('File')} ({filesProgress?.length})
		</Typography>
	);

	useEffect(() => {
		if (reloadDocumentSection) {
			appMode === 'patientPortal'
				? recentUploadedQuantity > 1
					? setToastMsg(t(`Files ${isDownload ? 'Downloaded' : 'Uploaded'} Successfully`))
					: setToastMsg(t(`File ${isDownload ? 'Downloaded' : 'Uploaded'} Successfully`))
				: setCustomToastMsg(t(`Templates ${isDownload ? 'Downloaded' : 'Uploaded'} Successfully!`));
			setReloadDocumentSection(false);
		}
	}, [reloadDocumentSection]);

	return (
		<>
			<Snackbar
				autoHideDuration={3000}
				message={customToastMsg}
				open={!!customToastMsg}
				sx={{
					left: { sm: '60px' },
					bottom: { xs: 0, sm: 0 },

					'& .MuiPaper-root': {
						backgroundColor: 'rsSecondary.medium',
						color: 'text.primary',
						padding: '11px 16px',
						boxSizing: 'border-box',
					},
				}}
				onClose={() => setCustomToastMsg('')}
			/>

			{showWindow && filesProgress?.length > 0 && (
				<Draggable bounds="body" defaultPosition={position} onStart={onStart} onStop={onStop}>
					<Box
						sx={{
							zIndex: 1201,
							background: appMode === 'patientPortal' ? '#FDFDFD' : '#272727',
							borderRadius: '20px',
							position: isMobile ? 'fixed' : 'absolute',
							bottom: appMode === 'patientPortal' ? '0px' : '27px',
							right: appMode === 'patientPortal' ? undefined : '30px',
							left: appMode === 'patientPortal' ? marginLeft : undefined,
							boxShadow: '0px 5px 30px 0px #0000001A',
							maxWidth: '95%',
						}}
					>
						<TabContext>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: 'divider',
								}}
							>
								<TabList>
									<Tab label={heading} />
									<Tab
										icon={
											!minimizeWindow ? (
												<KeyboardArrowDownIcon
													onClick={() => {
														setMinimizeWindow(true);
													}}
												/>
											) : (
												<KeyboardArrowUpIcon
													onClick={() => {
														setMinimizeWindow(false);
													}}
												/>
											)
										}
										iconPosition="end"
										style={{
											marginLeft: '240px',
											minWidth: '5px',
											padding: '12px 5px',
											color: appMode === 'patientPortal' ? '#00000099' : '#FFFFFF',
										}}
									/>
									<Tab
										icon={
											<CloseIcon color={appMode === 'patientPortal' ? '#00000099' : '#FFFFFF'} />
										}
										iconPosition="end"
										style={{
											minWidth: '5px',
											padding: '12px 5px',
										}}
										onClick={() => {
											setShowWindow(false);
										}}
									/>
								</TabList>
							</Box>
							{!minimizeWindow ? (
								<Box
									sx={{
										overflowY: 'auto',
										height: '337px',
									}}
								>
									{filesProgress
										.sort((a, b) => a.progress > b.progress)
										.map((document, i) => (
											<TabPanel
												style={{
													height: '70px',
													padding: '14px',
												}}
											>
												<UploadProgressFile document={document} isDownload={isDownload} />
											</TabPanel>
										))}
								</Box>
							) : (
								<UploadProgressFile isDownload={isDownload} progress={avgProgress} />
							)}
						</TabContext>
					</Box>
				</Draggable>
			)}
		</>
	);
};
export default UploadProgressWindow;
