import { useState, useEffect } from 'react';

const useUndoRedo = intialValue => {
	const [state, setState] = useState(intialValue);
	const [history, setHistory] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [wfaEditableMode, setWfaEditableMode] = useState(false); //state to manage Edit mode of WFA
	const [isUndoRedo, setIsUndoRedo] = useState(false); //
	const [isUndoPossible, setIsUndoPossible] = useState(false);
	const [isRedoPossible, setIsRedoPossible] = useState(false);

	//set
	const set = () => {
		if (JSON.stringify(history[history.length - 1]) !== JSON.stringify(state)) {
			let newHistory = history.slice(0, currentIndex + 1);
			if (!(state.action?.length === 0 && state.allSteps.find(obj => obj.type === 'action'))) {
				newHistory.push(state);
			}
			setHistory(newHistory);
			setCurrentIndex(newHistory.length - 1);
		}
	};
	//undo
	const undo = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
			const newState = history[currentIndex - 1];
			setState(newState);
			setIsUndoRedo(true);
		}
	};

	//redo
	const redo = () => {
		if (currentIndex < history.length - 1) {
			setCurrentIndex(currentIndex + 1);
			const newState = history[currentIndex + 1];
			setState(newState);
			setIsUndoRedo(true);
		}
	};
	useEffect(() => {
		if (!isUndoRedo && wfaEditableMode) {
			set();
		}
	}, [state.allSteps, state.conditional, state.item, state.action, state.status, wfaEditableMode]);
	useEffect(() => {
		if (wfaEditableMode && currentIndex > 0) {
			setIsUndoPossible(true);
		}
		if (currentIndex === 0) {
			setIsUndoPossible(false);
		}
		if (wfaEditableMode && history.length - 1 !== currentIndex && history.length - 1 > 0) {
			setIsRedoPossible(true);
		}
		if (history.length - 1 === currentIndex) {
			setIsRedoPossible(false);
		}
		if (history.length === 0) {
			setIsUndoPossible(false);
			setIsRedoPossible(false);
		}
	}, [history, currentIndex, undo, redo]);

	return [
		state,
		setState,
		undo,
		redo,
		setCurrentIndex,
		wfaEditableMode,
		setWfaEditableMode,
		setIsUndoRedo,
		isUndoPossible,
		isRedoPossible,
		setHistory,
		history,
	];
};

export default useUndoRedo;
