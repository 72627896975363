import axios from 'axios';

import handleApiResponse from '@worklist-2/worklist/src/DocumentViewerV3/api/utils';

const deleteGlobalNote = async ({ __config, params }) => {
	let response = await axios.delete(`${__config.data_sources.dicom_web}/StudyResource?${params}`);
	response = handleApiResponse(response);
	return response ? response.data : [];
};

export default deleteGlobalNote;
