import React from 'react';
import SMSLogGrid from '../SMSLogGrid';

const SMSLog = ({ className, name, children, columns, filters, setFilters, sort, setSort, notificationType }) => (
	<div>
		{children}
		<SMSLogGrid
			className={className}
			columns={columns}
			filters={filters}
			name={name}
			notificationType={notificationType}
			setFilters={setFilters}
			setSort={setSort}
			sort={sort}
		/>
	</div>
);

export default SMSLog;
