import { BmiPossibleOptions } from "@rs-ui/views/PatientInformationView/utils/constants";

export const calculateBMI = (weight, height) => {
	const heightMeter = parseFloat(height / 100);

	return (weight / (heightMeter * heightMeter)).toFixed(1);
};

export const calculateBMIStatus = (weight, height) => {
	const bmi = calculateBMI(weight, height);

	if (bmi < 18.6) {
		return BmiPossibleOptions[0];
	}
	if (bmi < 24.9) {
		return BmiPossibleOptions[1];
	}
	if (bmi < 29.9) {
		return BmiPossibleOptions[2];
	}
	return BmiPossibleOptions[3];
};
