import { logDebug } from '@rs-core/index';
import { compressObjectToArrayBuffer, decompressArrayBufferToObject } from '@rs-core/utils';
import { debounce } from 'lodash';

const sharedWorker = (key, api, { initialize = false, ref = '', wonIvBroadcastMsgCompress = false } = {}) => {
	const worker = new SharedWorker('sharedworker.js');
	const sharedStateKey = `${key}-${ref}`;
	let externalUpdate = false;
	let timestamp = 0;

	const _postMessage = message => {
		worker?.port?.postMessage(message);
	};

	const debouncedUpdate = debounce(state => {
		if (!externalUpdate) {
			timestamp = Date.now();
			let stateData = state;

			if (wonIvBroadcastMsgCompress) {
				stateData = compressObjectToArrayBuffer(state);
			}

			logDebug('useSharedWorker', `_postMessage - KEY: ${sharedStateKey} - Start posting setState`, {
				timestamp: Date.now(),
			});

			_postMessage({ type: 'setState', key: sharedStateKey, timestamp, state: stateData });
		}
		externalUpdate = false;
	}, 50);

	const cleanup = api.subscribe(
		state => state[key],
		state => {
			debouncedUpdate(state);
		}
	);

	worker.port.onmessage = evt => {
		const message = evt.data;
		let stateData = message.state;
		logDebug('useSharedWorker', `worker.port.onmessage - KEY: ${sharedStateKey} - Start`, {
			timestamp: Date.now(),
			type: message.type,
		});

		if (wonIvBroadcastMsgCompress && stateData) {
			// decompress message after receiving
			stateData = decompressArrayBufferToObject(stateData);
		}

		if (message.type === 'stateUpdate') {
			if (
				sharedStateKey !== message.key ||
				!message.timestamp ||
				message.timestamp <= timestamp ||
				!stateData ||
				api.getState()[key] === stateData
			) {
				return;
			}

			externalUpdate = true;
			timestamp = message.timestamp;
			api.setState({ [key]: stateData });
		}
	};

	const init = () => _postMessage({ type: 'init', key: sharedStateKey });
	const sync = () => _postMessage({ type: 'getState', key: sharedStateKey });

	const resetState = () => {
		_postMessage({ type: 'resetState', key: sharedStateKey });
	};

	const unshare = () => {
		cleanup();
		resetState();
		worker.port.close();
	};

	if (initialize) {
		init();
		sync();
	}

	return [sync, unshare];
};

const getSharedWorkerKeyPrefix = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const StudyInstanceUIDs = searchParams.get('StudyInstanceUIDs');
	const internalManagingOrganizationID = searchParams.get('internalManagingOrganizationID');

	return `${StudyInstanceUIDs}-${internalManagingOrganizationID}`;
};

export { sharedWorker, getSharedWorkerKeyPrefix };
