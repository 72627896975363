import {
	AngleTool,
	BidirectionalTool,
	DragProbeTool,
	EllipticalROITool,
	LengthTool,
	MagnifyTool,
	PanTool,
	PlanarFreehandROITool,
	ProbeTool,
	RectangleROITool,
	StackScrollTool,
	ToolGroupManager,
	WindowLevelTool,
	ZoomTool,
	PlanarRotateTool,
} from '@cornerstonejs/tools';
import StackScrollMouseWheelTool from '../../../cornerstoneTools/StackScrollToolMouseWheelTool';
import ArrowAnnotateTool from '../../../cornerstoneTools/ArrowAnnotationTool/ArrowAnnotationToolModyfied';
import { MouseBindings } from '@cornerstonejs/tools/dist/esm/enums';
import { shadowActiveViewportId } from '../../../contexts/ImageViewerCornerstoneContext';
import QuadZoomTool from '../../../cornerstoneTools/QuadZoomTool';
import CobbAngleTool from '../../../cornerstoneTools/CobbAngleTool';
import CardiothoracicTool from '../../../cornerstoneTools/CardiothoracicTool';
import PlumbLineTool from '../../../cornerstoneTools/PlumbLineTool';
import SpineLabelingTool from '../../../cornerstoneTools/SpineLabelingTool';
import ShutterRectangleTool from '../../../cornerstoneTools/ShutterTools/ShutterRectangleTool';
import ShutterCircleTool from '../../../cornerstoneTools/ShutterTools/ShutterCircleTool';
import ShutterFreehandTool from '../../../cornerstoneTools/ShutterTools/ShutterFreehandTool';
import CrosshairPointerTool from '../../../cornerstoneTools/CrosshairPointerTool';
import ReferenceLinesTool from '../../../cornerstoneTools/ReferenceLinesTool';
import CADtool from '../../../cornerstoneTools/CADtool';
import CalibrationTool from '../../../cornerstoneTools/CalibrationTool';

const createViewportToolGroup = ({
	viewportId,
	modality,
	plane,
	imageIds,
	wonIvShuttertoolRef,
	wonIvQuadzoomRef,
	wonIvCalibrationToolRef,
	pixelSpacing,
}) => {
	const toolGroup = ToolGroupManager.createToolGroup(viewportId);

	if (!toolGroup) return;

	const isDefault = !modality;

	const shouldAddStackScrollTool = isDefault || imageIds?.length > 1;
	const shouldAddReferenceLinesTool = isDefault || ['CT', 'PT', 'MR'].includes(modality);
	const shouldAddCrosshairPointerTool = isDefault || ['CT', 'PT', 'MR'].includes(modality);
	const shouldAddQuadZoomTool = wonIvQuadzoomRef?.current && (isDefault || modality === 'MG');
	const shouldAddCADtool = isDefault || modality === 'MG';
	const shouldAddCardiothoracicTool = isDefault || ['CT', 'CR', 'DX', 'DR', 'RG'].includes(modality);
	const shouldAddSpineLabelingTool =
		isDefault ||
		(['PT', 'CT', 'MR'].includes(modality) && plane === 'Sagittal') ||
		['XR', 'DX', 'CR'].includes(modality);
	const shouldAddShutterTools = wonIvShuttertoolRef?.current;
	const shouldAddCalibrationTool = wonIvCalibrationToolRef?.current && !!pixelSpacing?.length;

	toolGroup.addTool(MagnifyTool.toolName);
	toolGroup.addTool(PlanarRotateTool.toolName);
	toolGroup.addTool(DragProbeTool.toolName);

	toolGroup.addTool(StackScrollMouseWheelTool.toolName);
	toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);

	toolGroup.addTool(PanTool.toolName);
	toolGroup.setToolActive(PanTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Primary_And_Secondary }],
	});

	toolGroup.addTool(WindowLevelTool.toolName);
	toolGroup.setToolActive(WindowLevelTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Secondary }],
	});

	toolGroup.addTool(ZoomTool.toolName);
	toolGroup.setToolActive(ZoomTool.toolName, {
		bindings: [{ mouseButton: MouseBindings.Auxiliary, numTouchPoints: 2 }],
	});

	toolGroup.addTool(ArrowAnnotateTool.toolName);
	toolGroup.setToolPassive(ArrowAnnotateTool.toolName);

	toolGroup.addTool(AngleTool.toolName);
	toolGroup.setToolPassive(AngleTool.toolName);

	toolGroup.addTool(ProbeTool.toolName);
	toolGroup.setToolPassive(ProbeTool.toolName);

	toolGroup.addTool(RectangleROITool.toolName, { rotateRectangle: true });
	toolGroup.setToolPassive(RectangleROITool.toolName);

	toolGroup.addTool(LengthTool.toolName);
	toolGroup.setToolPassive(LengthTool.toolName);

	toolGroup.addTool(BidirectionalTool.toolName);
	toolGroup.setToolPassive(BidirectionalTool.toolName);

	toolGroup.addTool(EllipticalROITool.toolName);
	toolGroup.setToolPassive(EllipticalROITool.toolName);

	toolGroup.addTool(CobbAngleTool.toolName);
	toolGroup.setToolPassive(CobbAngleTool.toolName);

	toolGroup.addTool(PlanarFreehandROITool.toolName, {
		calculateStats: true,
		shouldFireCompletedEvent: true,
	});
	toolGroup.setToolPassive(PlanarFreehandROITool.toolName);

	toolGroup.addTool(PlumbLineTool.toolName);
	toolGroup.setToolPassive(PlumbLineTool.toolName);

	if (shouldAddStackScrollTool) {
		toolGroup.addTool(StackScrollTool.toolName);
		toolGroup.setToolActive(StackScrollTool.toolName, {
			bindings: [{ numTouchPoints: 1 }],
		});
	}

	if (shouldAddReferenceLinesTool) {
		toolGroup.addTool(ReferenceLinesTool.toolName);
		toolGroup.setToolConfiguration(
			ReferenceLinesTool.toolName,
			{
				sourceViewportId: shadowActiveViewportId,
				showFullDimension: false,
			},
			true
		);
		toolGroup.setToolEnabled(ReferenceLinesTool.toolName);
	}

	if (shouldAddCrosshairPointerTool) {
		toolGroup.addTool(CrosshairPointerTool.toolName);
	}

	if (shouldAddQuadZoomTool) {
		toolGroup.addTool(QuadZoomTool.toolName);
		toolGroup.setToolPassive(QuadZoomTool.toolName);
	}

	if (shouldAddCADtool) {
		toolGroup.addTool(CADtool.toolName);
		toolGroup.setToolPassive(CADtool.toolName);
	}

	if (shouldAddCardiothoracicTool) {
		toolGroup.addTool(CardiothoracicTool.toolName);
		toolGroup.setToolPassive(CardiothoracicTool.toolName);
	}

	if (shouldAddSpineLabelingTool) {
		toolGroup.addTool(SpineLabelingTool.toolName);
		toolGroup.setToolPassive(SpineLabelingTool.toolName);
	}

	if (shouldAddShutterTools) {
		toolGroup.addTool(ShutterRectangleTool.toolName);
		toolGroup.addTool(ShutterCircleTool.toolName);
		toolGroup.addTool(ShutterFreehandTool.toolName);
	}

	if (shouldAddCalibrationTool) {
		toolGroup.addTool(CalibrationTool.toolName);
	}

	return toolGroup;
};

export default createViewportToolGroup;
