const DOCUMENT_VIEWER_PERMISSIONS = {
	PRELIMINARY_REPORTS: 'preliminary report',
	FINAL_REPORTS: 'final report',
	STUDY_REPORTS: 'study document',
	TEMPLATE_MANAGER: 'template manager',
	MISC: 'misc',
	IMAGE_VIEWPORT: 'image viewport',
	PROXY: 'proxy',
	VOICE_RECORDING: 'voice recording',
	VR_SETTING: 'vr',
	RADPAIR_REPORT: 'radpair report',
};

const PRELIMINARY_REPORT = {
	SIGN_STUDY: 'sign study',
	VR: 'vr',
	CONVERT_TO_STUDY_DOC: 'convert to study doc',
	TEMPLATE: 'template',
	EDIT_NAME: 'edit name',
	CREATE: 'create',
	UPDATE_REPORT: 'update report',
	DELETE: 'delete',
	PRINT_REPORT: 'print report',
	CREATE_ANNOTATION: 'create annotation',
};

const FINAL_REPORT = {
	AMEND: 'amend',
	DELETE: 'delete',
	EDIT_NAME: 'edit name',
	PEER_REVIEW: 'peer review',
	SHARE: 'share',
	UPLOAD: 'upload',
	VOICE_RECORDING: 'voice recording',
};

const STUDY_REPORT = {
	DELETE: 'delete',
	EDIT_NAME: 'edit',
	SHARE: 'share',
	UPLOAD: 'upload',
};

const TEMPLATE_MANAGER = {
	CREATE: 'create',
	DELETE: 'delete',
	EDIT_CUSTOM_FIELDS: 'edit custom fields',
	EDIT_PICKLIST: 'edit picklist',
	EDIT_TEMPLATE: 'edit template',
	EDIT_NAME: 'edit template name',
	UPLOAD_TEMPLATE: 'upload template',
	UPDATE_HEADER_AND_FOOTER: 'update header footer',
};

const MISC = {
	CRITICAL_FINDINGS: 'critical findings',
	SIGNATURE: 'signature',
	VERSION_HISTORY: 'version history',
};

const VR_SETTING = {
	CHANGE_LANGUAGE: 'change language',
	CREATE_MACROS: 'create macros',
	DELETE_MACROS: 'delete macros',
	EDIT_MACROS: 'edit macros',
	VIEW_MACROS: 'view macros',
};

const RADPAIR_REPORT = {
	CREATE_RADPAIR_REPORT: 'create radpair report',
};

export {
	DOCUMENT_VIEWER_PERMISSIONS,
	PRELIMINARY_REPORT,
	FINAL_REPORT,
	STUDY_REPORT,
	TEMPLATE_MANAGER,
	MISC,
	VR_SETTING,
	RADPAIR_REPORT,
};
