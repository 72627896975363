// @flow

// core
import React, { type Node } from 'react';

// libs
import { Box, Typography, Tooltip } from '@mui/material';

// types
import { IObservationPillProps } from '@worklist-2/ui/src/views/PatientInformationView/types/types';

export const ObservationPill = ({ icon: Icon, title, toolTipTitle }: IObservationPillProps): Node =>
	toolTipTitle ? (
		<Tooltip title={toolTipTitle}>
			<Box data-testid="observation-pill-wrapper" sx={SX.pillWrapper}>
				<Icon data-testid="observation-pill-icon" sx={{ color: '#FFFFFF99', fontSize: '16px' }} />
				<Typography sx={SX.title}>{title}</Typography>
			</Box>
		</Tooltip>
	) : (
		<Box data-testid="observation-pill-wrapper" sx={SX.pillWrapper}>
			<Icon data-testid="observation-pill-icon" sx={{ color: '#FFFFFF99', fontSize: '16px' }} />
			<Typography sx={SX.title}>{title}</Typography>
		</Box>
	);

const SX = {
	pillWrapper: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '4px',
		minHeight: '24px',
		padding: '3px 8px',
		borderRadius: '41px',
		border: '1px solid #FFFFFF1A',
		boxSizing: 'border-box',
	},

	title: {
		fontSize: '12px',
		fontWeight: '500',
		letterSpacing: '0.25px',
		fontVariant: 'all-small-caps',
		lineHeight: '14px',
		color: '#FFFFFF99',
	},
};
