import { useDocumentViewerContext } from '../../../contexts/DocumentViewerContextProvider';
import { useGlobalContext } from '../../../contexts/GlobalContextProvider';
import { OLD_BLANK_REPORT_JSON, NEW_BLANK_REPORT_JSON } from '../../../consts/reportConstants';
import createViewport from '../utils/createViewport';
import { BLANK_REPORT_ID } from '../../../consts/consts';
import getStudyPreliminaryDiagnosticReports from '../../../utils/getStudyPreliminaryDiagnosticReports';
import useGetSearchParams from '../../../hooks/useGetSearchParams';
import useCheckAccess from '../../../hooks/useCheckAccess';
import useBestMatchTemplateForStudy from './useBestMatchTemplateForStudy';
import { useStudiesStore } from '../../../store/studies';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useAddBlankReport = () => {
	const { setBlankReport, blankReport, setViewports, setAppliedPageSetup } = useDocumentViewerContext();
	const diagnosticReportsList = useStudiesStore(state => state.diagnosticReportsList);
	const { diagnosticReports } = useGlobalContext();
	const { studyId } = useGetSearchParams();
	const { getBestMatchTemplate } = useBestMatchTemplateForStudy();

	const galaxyDvEnableBestMatchTemplateBlankReportOnly = useBooleanFlagValue(
		'galaxy-dv-enable-best-match-template-blank-report-only'
	);
	const galaxyDvPerformanceRefactorGetCall = useBooleanFlagValue('galaxy-dv-performance-refactor-get-call');
	const galaxyDvSecondReportAlertPopup = useBooleanFlagValue('galaxy-dv-second-report-alert-popup');
	const galaxyDvRemoveBlankReportData = useBooleanFlagValue('galaxy-dv-remove-blank-report-data');
	const studyPreliminaryDiagnosticReports = getStudyPreliminaryDiagnosticReports({
		studyId,
		diagnosticReports: galaxyDvPerformanceRefactorGetCall ? diagnosticReportsList : diagnosticReports,
	});

	const BLANK_REPORT_JSON = galaxyDvRemoveBlankReportData ? NEW_BLANK_REPORT_JSON : OLD_BLANK_REPORT_JSON;

	const { hasFullAccess } = useCheckAccess();
	const isFeatureFlagOffAndReportCountLow = galaxyDvSecondReportAlertPopup
		? false
		: studyPreliminaryDiagnosticReports.length >= 3;
	const addBlankReport = async force => {
		if (isFeatureFlagOffAndReportCountLow || !hasFullAccess) return;
		/* prevent creation more than 1 blank report */
		if (blankReport && !force) {
			return;
		}

		if (galaxyDvEnableBestMatchTemplateBlankReportOnly) {
			await getBestMatchTemplate(true);
		}

		setBlankReport({ ...BLANK_REPORT_JSON });
		const blankViewport = createViewport({ documentId: BLANK_REPORT_ID });
		setViewports([blankViewport]);
		setAppliedPageSetup({});
		//document.querySelector('.study-navigator-content') is the Study-NavigatorScrollableContent
		//container.querySelectorAll('.study-category-title')[0] is a Preliminary report text in current study category
		const container = document?.querySelector('.study-navigator-content');
		const element = container?.querySelectorAll('.study-category-title')?.[0];

		//Scroll to Preliminary report text. 58px is the height of Study-Header which will came on top of Preliminary Text
		if (container && element) {
			const scrollTop = element.offsetTop - 58;
			container.scrollTo({
				top: scrollTop,
				behavior: 'smooth',
			});
		}
	};

	return {
		addBlankReport,
	};
};

export default useAddBlankReport;
