import {
	getSeriesUID,
	getStudyBodyPart,
	getStudyInstanceUID,
	getStudyLaterality,
	getInstanceSOPUID,
} from '../../utils/utils';
import { BODY_PART_ONLY, BODY_PART_LATERALITY, LATERALITY_TYPES } from '../../consts/measurements';
import { measurementTools } from '../utils/isValidMeasurement';
import { cad_findings, sr_object } from '../../consts/measurements';
import { isArray, isObject, isString } from 'lodash';

export const measurementSeriesId = (layoutItems, activeViewportId) => {
	const activeLayoutItem = layoutItems?.find(i => (i.viewports || []).some(({ id }) => id === activeViewportId));

	return activeLayoutItem?.series ? getSeriesUID(activeLayoutItem.series) : '';
};

export const getMeasurementManagingOrganizationId = (layoutItems, activeViewportId) => {
	const activeLayoutItem = layoutItems?.find(i => (i.viewports || []).some(({ id }) => id === activeViewportId));
	return activeLayoutItem?.series?.managingOrganizationId;
};

export const measurementStudyId = (layoutItems, activeViewportId) => {
	const activeLayoutItem = layoutItems?.find(i => (i.viewports || []).some(({ id }) => id === activeViewportId));

	return activeLayoutItem?.series?.studyInstanceUID ? activeLayoutItem?.series?.studyInstanceUID : '';
};

export const measurementSeriesInstanceId = (layoutItems, activeViewportId, imageIndex) => {
	const activeLayoutItem = layoutItems?.find(i => (i.viewports || []).some(({ id }) => id === activeViewportId));

	return activeLayoutItem?.series?.metadata?.[imageIndex]
		? getInstanceSOPUID(activeLayoutItem?.series?.metadata[imageIndex])
		: '';
};

export const diagramMeasurements = (series, studies, measurementData) => {
	let currentSeries;
	const regexSeries = new RegExp('series.([0-9]+(.[0-9]+)+)');
	return measurementData.reduce((agg, measurement) => {
		let seriesId = measurement.seriesId
			? measurement.seriesId
			: measurement.metadata?.referencedImageId?.match(regexSeries)[0].replace('series/', '');

		currentSeries = series.find(item => getSeriesUID(item) === seriesId);

		const currentStudy = studies.find(item => getStudyInstanceUID(item) === currentSeries?.studyInstanceUID);

		const bodyPart = getStudyBodyPart(currentStudy);
		const laterality = getStudyLaterality(currentStudy);

		if (laterality && laterality !== LATERALITY_TYPES.unpair) {
			if (laterality === LATERALITY_TYPES.both) {
				const bodySideR = BODY_PART_LATERALITY.find(item => item.side === LATERALITY_TYPES.right);
				const bodySideL = BODY_PART_LATERALITY.find(item => item.side === LATERALITY_TYPES.left);

				const idR = bodySideR?.locations.find(location =>
					location.value.some(item => item.toLowerCase() === bodyPart?.toLowerCase())
				)?.id;
				const idL = bodySideL?.locations.find(location =>
					location.value.some(item => item.toLowerCase() === bodyPart?.toLowerCase())
				)?.id;

				agg = [...agg, idR, idL];
			} else {
				const bodySide = BODY_PART_LATERALITY.find(item => item.side === laterality);
				const id = bodySide?.locations.find(location =>
					location.value.some(item => item.toLowerCase() === bodyPart?.toLowerCase())
				)?.id;
				agg = [...agg, id];
			}
		} else {
			const area = BODY_PART_ONLY.find(item =>
				item.value.some(value => value.toLowerCase() === bodyPart?.toLowerCase())
			)?.id;
			agg = [...agg, area];
		}
		return agg;
	}, []);
};

export const getMeasurementsFromAnnotations = frameAnnotations => {
	const result = [];
	Object.keys(frameAnnotations).forEach(toolName => {
		if (measurementTools.includes(toolName)) {
			const measurements = frameAnnotations[toolName];
			result.push(...measurements);
		}
	});
	return result;
};

const optionsExtractor = instance => {
	if (isArray(instance)) {
		return instance.map(subItem => optionsExtractor(subItem));
	} else if (isObject(instance)) {
		if (instance['Value'] && instance['Units']) {
			return `${instance['Value']} ${instance['Units']}`;
		} else
			return Object.keys(instance).map(instanceKey => {
				if (instanceKey === 'Content') {
					return optionsExtractor(instance[instanceKey]);
				} else
					return {
						label: instanceKey,
						value: optionsExtractor(instance[instanceKey]),
					};
			});
	} else if (isString(instance)) {
		return instance;
	}
};

export const srMeasurements = srObject => {
	return srObject?.Findings?.map(item => {
		return {
			content: optionsExtractor(item),
			cornerstone3D: true,
			metadata: {
				toolName: sr_object,
			},
		};
	});
};

export const cadsrMeasurements = series =>
	series.reduce((agg, item) => {
		const seriesCadsr = item.metadata?.[0]?.cadsr;
		if (seriesCadsr && (seriesCadsr.data?.numOfCalcCluster || seriesCadsr.data?.numOfDensity)) {
			const cadsrItem = {
				id: getSeriesUID(item),
				data: {
					label: seriesCadsr.data.manufacturers?.toString(),
				},
				metadata: {
					toolName: cad_findings,
				},
				text: `Viewcode: ${item?.['0008103E']?.Value?.at(0)}\nValue: Cluster x ${
					seriesCadsr.data.numOfCalcCluster || 0
				} | Calc x ${seriesCadsr.data.numOfDensity || 0}`,
				cornerstone3D: true,
			};
			agg = [...agg, cadsrItem];
		}
		return agg;
	}, []);

export const insertMeasurement = (measurements = [], measurement) => {
	const existingMeasurement = measurements.find(m => m.annotationUID === measurement.annotationUID);
	if (existingMeasurement) {
		return measurements.map(m => (m.annotationUID === measurement.annotationUID ? measurement : m));
	}
	return [...measurements, measurement];
};
