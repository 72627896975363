import React from 'react';
import { ImageViewerViewportCustomOverlayV2 } from './ImageViewerViewportCustomOverlayV2';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { parseConfig } from './utils/config';
import { useOverlayStoreSelector } from '../../contexts/OverlayContext';

export const ImageViewerViewportCustomOverlayV2Wrapper = ({ viewportId, isCurrentStudy, series, enableTextShadow }) => {
	const profile = useOverlayStoreSelector(state => state.profile);
	const customOverlays = useOverlayStoreSelector(state => state.customOverlays);
	const renderingEngine = getDefaultRenderingEngine();
	if (!renderingEngine) return null;
	const viewport = renderingEngine.getViewport(viewportId);
	if (!viewport) return null;
	if (!customOverlays) return null;
	const sectionItem = customOverlays[profile];
	const config = parseConfig(sectionItem, enableTextShadow);

	return (
		<ImageViewerViewportCustomOverlayV2
			config={config}
			isCurrentStudy={isCurrentStudy}
			series={series}
			viewport={viewport}
		/>
	);
};
