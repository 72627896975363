import React from 'react';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box } from '@mui/system';
import { blueBox } from './helpStyles';
import { useMediaQuery } from '@mui/material';

const LiveSupportButton = ({ onClick }) => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down(550));

	return (
		<Box sx={blueBox} onClick={onClick}>
			<SupportAgentIcon sx={{ fontSize: 55 }} />
			<h4 style={{ fontSize: '20px', margin: '11px 0 4px' }}>Live Support</h4>
			<span style={{ fontSize: 14, marginTop: 20 }}>An agent will assist you {!isSmall && <br />}directly.</span>
		</Box>
	);
};
export default LiveSupportButton;
