import _ from 'lodash';

const getCodingValues = (options, valueType = 'display', capitalizeOptions = false, fullOptionsList) => {
	let text = '';
	if (_.isArray(options)) {
		options.forEach((option, index) => {
			if (!!fullOptionsList && fullOptionsList.length > 0) {
				// Find option by ID
				let currentOption = _.find(fullOptionsList, listOption => option.id === listOption.id);

				if (currentOption) {
					text += currentOption[valueType] || '';
				}
			} else if (option.valueCoding) {
				text += option.valueCoding[valueType] || '';
			} else if (option.coding) {
				text += option.coding[0][valueType] || '';
			} else {
				text += option[valueType] || '';
			}

			text += options.length - 1 === index ? '' : ' | ';
		});

		return capitalizeOptions ? text.toUpperCase() : text;
	}

	if (options?.valueCoding) {
		text = options.valueCoding[valueType] || '';
	} else if (options?.coding) {
		text = options.coding[0][valueType] || '';
	} else if (options?.[valueType] != undefined) {
		text = options[valueType] || '';
	} else if (options) {
		text = options;
	} else {
		text = '';
	}

	return capitalizeOptions ? text.toUpperCase?.() : text;
};

export default getCodingValues;
