import { getStudyInstanceUID } from './utils';

const getCurrentSeries = ({ series, currentStudy }) =>
	series.filter(
		item =>
			item.studyInstanceUID === getStudyInstanceUID(currentStudy) &&
			item.managingOrganizationId === currentStudy?.managingOrganizationId
	);

export default getCurrentSeries;
