import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import axios from 'axios';

import { useAuth } from '@worklist-2/core/src/context/UserAuthContext';
import { useConfig } from '@worklist-2/core/src/context/ConfigContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import '../script/EncaptureMDAPI';

const EncaptureMDContext = createContext({});

export const EncaptureMDProvider = ({ children }) => {
	const __config = useConfig();
	const { loggedIn, accessToken } = useAuth();
	const [magicLink, setMagicLink] = useState(null);
	const [magicLinkLocalStorage, setMagicLinkLocalStorage] = useLocalStorage('encaptureMdMagicLink', null);
	const crossEnableEncaptureMdIntegration = useBooleanFlagValue('cross-enable-encapture-md-integration');

	useEffect(() => {
		if (crossEnableEncaptureMdIntegration && loggedIn && accessToken) {
			// check if local storage has magic link
			if (magicLinkLocalStorage) {
				setMagicLink(magicLinkLocalStorage);
			} else {
				// if not make api call to get the magic link
				axios
					.get(`${__config.data_sources.fhir}/EncaptureMD/magiclink`, {
						headers: { Authorization: accessToken },
					})
					.then(value => {
						const link = value?.data?.magicLink;
						setMagicLink(link);
						setMagicLinkLocalStorage(link);
					});
			}
		}
	}, [loggedIn, accessToken, crossEnableEncaptureMdIntegration, magicLinkLocalStorage]);

	useEffect(() => {
		if (magicLink && !window.EncaptureMDAPI.isEMDConnected()) {
			window.EncaptureMDAPI.connect(magicLink);
			// window.EncaptureMDAPI.addEventListener(EncaptureMDAPI.EVENTS.LOADED, () => {
			// 	console.log("encapture MD loaded");
			// });
		}
	}, [magicLink]);

	return <EncaptureMDContext.Provider value={magicLink}>{children}</EncaptureMDContext.Provider>;
};
export const useEncaptureMDContext = () => useContext(EncaptureMDContext);
