import React, { createContext, useContext, useState } from 'react';

const CalibrationToolContext = createContext({});

const CalibrationToolContextProvider = ({ children }) => {
	const [isShownCalibrationToolMenu, setIsShownCalibrationToolMenu] = useState(false);
	const [calibrationToolSettings, setCalibrationToolSettings] = useState({});
	const [isShownCalibrationRuler, setIsShownCalibrationRuler] = useState(true);

	return (
		<CalibrationToolContext.Provider
			value={{
				isShownCalibrationToolMenu,
				setIsShownCalibrationToolMenu,
				calibrationToolSettings,
				setCalibrationToolSettings,
				isShownCalibrationRuler,
				setIsShownCalibrationRuler,
			}}
		>
			{children}
		</CalibrationToolContext.Provider>
	);
};

const useCalibrationToolContext = () => useContext(CalibrationToolContext);

export { useCalibrationToolContext, CalibrationToolContextProvider, CalibrationToolContext };
