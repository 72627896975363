import React from 'react';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import HoldToDelete from '@worklist-2/ui/src/components/HoldToDelete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const NotesCardAction = ({ sx, onDelete, onEdit }) => (
	<Stack
		alignItems="center"
		className="actions"
		data-testid="notes-actions-stack"
		direction="row"
		justifyContent="flex-end"
		sx={{
			position: 'absolute',
			right: '10px',
			zIndex: 1,
			visibility: 'hidden',
			top: '6px',
			display: 'inline-flex',
			width: 'auto',
			...sx,
		}}
	>
		<Tooltip data-testid="edit-tooltip" placement="top" title="Edit">
			<IconButton
				sx={{
					height: '30px',
					width: '30px',
					margin: 0,
					opacity: 1,
					backgroundColor: '#48464A',
					marginRight: '8px',
					border: '1px solid rgba(255,255,255,0.1)',
					'&:hover': {
						backgroundColor: '#42A5F5',
					},
				}}
				onClick={onEdit}
			>
				<EditOutlinedIcon sx={{ opacity: 0.6, fontSize: '20px', '&:hover': { opacity: 1 } }} />
			</IconButton>
		</Tooltip>
		<HoldToDelete
			backgroundColor="#48464A"
			data-testid="hold-to-delete"
			height="30px"
			iconColor="#ffffff90"
			sx={{
				border: '1px solid rgba(255,255,255,0.1)',
				'&:hover': {
					backgroundColor: '#42A5F5',
				},
			}}
			width="30px"
			onDelete={onDelete}
		/>
	</Stack>
);

export default NotesCardAction;
