export const getNamePlaceholder = (name) => {
	if (name && name.length > 0) {
		const nameSlices = name.split(' ');

		return nameSlices.length > 1 ? `${nameSlices[0]?.[0] || ''}${nameSlices[1]?.[0] || ''}` : name[0];
	}

	return '...';
};

export const getPatientNameString = (patientNameArray) => {
	if (Array.isArray(patientNameArray) && patientNameArray.length) {
		const [firstName, middleName] = patientNameArray[0]?.given || [];
		const lastName = patientNameArray[0]?.family;

		const patientName = `${firstName || ''} ${lastName || middleName || ''}`;

		return patientName.trim().length ? patientName : patientNameArray[0]?.text || '';
	}

	return '';
};

export const transformName = (name) => name?.split('^').join(' ');
