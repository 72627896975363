import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import { ShowSpinner } from '@worklist-2/breeze/src/views/CrmView/SearchPanelStyle';
import { useHelpCenter } from '@worklist-2/core/src';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { EmptyResultWrapper, SearchResultInfoText, SearchResultListItem } from '../../../../components/Help/helpStyles';
import { navLinks } from '../HelpCenter';
import Searchables from './Searchables';
import { useTranslation } from 'react-i18next';

const HelpPopOverSearchResult = ({ onChangeContent }) => {
	const { t } = useTranslation('helpCenter');
	const { searchQuestion, searchObject } = useHelpCenter();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const strSearchResult = t('entries found for');

	const fetchData = _.debounce(async () => {
		try {
			if (searchQuestion?.length >= 3) {
				setLoading(true);
				const search = new Searchables(searchObject);
				search.addIndex(['title', 'textContent']);
				const results = await search.find(searchQuestion);
				setData(results);
				setTimeout(() => setLoading(false), 500);
			}
		} catch (error) {
			console.error(error);
		}
	}, 500);

	useEffect(() => {
		fetchData();
	}, [searchQuestion]);

	return (
		<Paper
			style={{
				backgroundColor: '#272727',
				margin: '10px 20px',
				borderWidth: 0,
				boxShadow: 'none',
			}}
		>
			{searchQuestion && (
				<SearchResultInfoText data-testid="HelpPopOverSearchResult" style={{ fontSize: '16px' }}>
					{loading ? (
						<ShowSpinner text={t('Searching...')} />
					) : (
						`${data?.length} ${strSearchResult} "${searchQuestion}"`
					)}
				</SearchResultInfoText>
			)}
			{!loading && (
				<div
					style={{
						borderTop: '1px solid #363636',
						marginBottom: '37px',
					}}
				>
					{data.length > 0 ? (
						data.map((res, i) => <SearchListItem key={i} item={res} onChangeContent={onChangeContent} />)
					) : (
						<EmptyResultWrapper>
							<SearchIcon style={{ fontSize: 50, color: '#FFF' }} />
							<p>{t('No search results found')}</p>
						</EmptyResultWrapper>
					)}
				</div>
			)}
		</Paper>
	);
};

export const SearchListItem = ({ item, onChangeContent }) => {
	const { setCurrentPiPTabs } = useHelpCenter();

	const articlePaths = useMemo(() => {
		const paths = [];
		_.forEach(navLinks, link => {
			paths.push({ path: link.path });
			link.subNavLink?.forEach(subLink => {
				paths.push({ path: subLink.path, tabs: subLink.tabs || [] });
			});
		});
		return paths;
	}, [navLinks]);

	const handleSelectItem = selectedItem => {
		try {
			const path = _.find(
				articlePaths,
				aPath =>
					aPath.path === selectedItem.articlePath ||
					aPath.tabs?.map(entry => entry.path).includes(selectedItem.articlePath)
			);
			onChangeContent(selectedItem.articlePath);
			setCurrentPiPTabs(path.tabs || []);
		} catch (error) {
			onChangeContent(selectedItem.articlePath);
		}
	};

	return (
		<SearchResultListItem data-testid="HelpPopOverSearchResultListItem" onClick={() => handleSelectItem(item)}>
			<h3 style={{ fontSize: '16px' }}>{item.title}</h3>
			<span className="path">{item.path}</span>
		</SearchResultListItem>
	);
};

export default HelpPopOverSearchResult;
