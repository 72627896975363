import { getUserFullName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';

const getPractitioners = async (
	fhirDataLoader,
	setPractitioners,
	practitionerColumnMapping,
	organizationId,
	queryParam
) => {
	let usersList = [];

	if (!fhirDataLoader || typeof fhirDataLoader.load !== 'function') return;

	const result = await fhirDataLoader.load({ ...queryParam }, true);
	if (result && result?.entry) {
		result?.entry?.filter(elem => {
			if (!!elem?.resource?.name && elem?.resource?.name?.[0]?.text) {
				usersList.push({
					id: elem.resource.id,
					reference: `Practitioner/${elem?.resource?.id}`,
					display: elem?.resource?.name?.[0]?.text,
					name: getUserFullName(elem?.resource?.name?.[0]),
					organization: practitionerColumnMapping['organization']?.getDisplayByKey(
						elem.resource,
						organizationId
					),
					physicianTitle: practitionerColumnMapping['physicianTitle']?.getDisplay(elem.resource),
				});
			}
		});
		setPractitioners(usersList);
	}
};
export default getPractitioners;
