import React from 'react';
import Box from '@mui/material/Box';

const Card = ({ children, sx }) => (
	<Box
		sx={{
			background: '#393939',
			boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
			borderRadius: '10px',
			padding: '20px 33px 30px 33px',
			boxSizing: 'border-box',
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			...sx,
		}}
	>
		{children}
	</Box>
);

export default Card;
