import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';

import { useHelpCenter } from '@worklist-2/core/src';

import { CustomStyledTabs } from './HelpCenter';
import { boxStyle, Scrollable } from './helpCenterStyles';
import HelpCenterContent from './HelpCenterContent';

const HelpCenterCategories = ({ category, subCategory, tabIndex }) => {
	const navigate = useNavigate();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const { omegaAIHelpLinks } = useHelpCenter();

	useEffect(() => {
		setSelectedTabIndex(0);
	}, []);

	useEffect(() => {
		setSelectedTabIndex(tabIndex || 0);
	}, [tabIndex]);

	const tabList = useMemo(() => {
		let cat = omegaAIHelpLinks.find(element => category === element.path);
		if (cat) {
			if (subCategory && cat.subNavLink) {
				cat = cat.subNavLink.find(element => subCategory === element.path);
			}
			return cat?.tabs || [];
		}
		return [];
	}, [category, subCategory, omegaAIHelpLinks]);

	const SelectedTabMdx = useMemo(() => {
		const tab = tabList[selectedTabIndex];
		return tab?.path || subCategory || category;
	}, [selectedTabIndex, tabList, omegaAIHelpLinks]);

	const handleChange = (event, newTabIndex) => {
		navigate(`/help/${tabList[newTabIndex].path}`);
	};

	return (
		<Box sx={boxStyle}>
			<CustomStyledTabs tabs={tabList} value={selectedTabIndex} onClick={handleChange} />
			<Scrollable>
				<HelpCenterContent md={SelectedTabMdx} />
			</Scrollable>
		</Box>
	);
};

export default HelpCenterCategories;
