export const checkIsJSON = str => {
	if (!str || typeof str !== 'string') return false;

	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};
