import React, { createContext, useEffect, useRef } from 'react';
import { createStore as createZustandStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import useZustandStore from './utils/zustand/useZustandStore';
import useZustandStoreSelector from './utils/zustand/useZustandStoreSelector';
import { getZustandStoreBroadcastChannelName } from './utils/zustand/getZustandStoreBroadcastChannelName';
import { zustandShare } from './utils/zustand/useZustandShare';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { sharedWorker, getSharedWorkerKeyPrefix } from '@rs-core/hooks/useSharedWorker';

const createStore = () =>
	createZustandStore(
		subscribeWithSelector(set => ({
			studies: [],
			setStudies: studies => set({ studies }),
			series: [],
			setSeries: series => set({ series }),
			addSeries: newSeries => {
				set(state => {
					const updatedSeries = [
						...state.series,
						...newSeries.filter(
							ns =>
								!state.series.some(
									s =>
										s.studyInstanceUID === ns.studyInstanceUID &&
										s.uniqueId === ns.uniqueId &&
										s.managingOrganizationId === ns.managingOrganizationId
								)
						),
					];
					return { series: updatedSeries };
				});
			},
			currentStudy: null,
			setCurrentStudy: currentStudy => set({ currentStudy }),
			currentSeries: [],
			setCurrentSeries: currentSeries => set({ currentSeries }),
		}))
	);

const useStoreSync = (storeRef, wonIvBroadcastMsgCompress, wonIvMmUseSharedworker) => {
	useEffect(() => {
		const storeName = 'imageViewerStudiesStore';
		const options = {
			initialize: true,
			ref: wonIvMmUseSharedworker ? getSharedWorkerKeyPrefix() : getZustandStoreBroadcastChannelName(storeName),
			wonIvBroadcastMsgCompress,
		};

		// Using sharedWorker
		if (wonIvMmUseSharedworker) {
			const [, unsubscribeStudies] = sharedWorker('studies', storeRef.current, options);
			const [, unsubscribeCurrentStudy] = sharedWorker('currentStudy', storeRef.current, options);
			const [, unsubscribeSeries] = sharedWorker('series', storeRef.current, options);
			return () => {
				unsubscribeStudies();
				unsubscribeCurrentStudy();
				unsubscribeSeries();
			};
		}

		// Using BroadcastChannel
		const [, unsubscribeStudies] = zustandShare('studies', storeRef.current, options);
		const [, unsubscribeCurrentStudy] = zustandShare('currentStudy', storeRef.current, options);
		const [, unsubscribeSeries] = zustandShare('series', storeRef.current, options);
		return () => {
			unsubscribeStudies();
			unsubscribeCurrentStudy();
			unsubscribeSeries();
		};
	}, [storeRef, wonIvBroadcastMsgCompress, wonIvMmUseSharedworker]);
};

const ImageViewerStudiesZustandContext = createContext({});

const ImageViewerStudiesZustandProvider = ({ children }) => {
	const wonIvBroadcastMsgCompress = useBooleanFlagValue('won-iv-broadcast-msg-compress');
	const wonIvMmUseSharedworker = useBooleanFlagValue('won-iv-mm-use-sharedworker');
	const storeRef = useRef();

	if (!storeRef.current) {
		storeRef.current = createStore();
	}

	useStoreSync(storeRef, wonIvBroadcastMsgCompress, wonIvMmUseSharedworker);

	return (
		<ImageViewerStudiesZustandContext.Provider value={storeRef.current}>
			{children}
		</ImageViewerStudiesZustandContext.Provider>
	);
};

const useImageViewerStudiesZustandStore = () => useZustandStore(ImageViewerStudiesZustandContext);

const useImageViewerStudiesZustandStoreSelector = selector =>
	useZustandStoreSelector(selector, ImageViewerStudiesZustandContext);

export {
	ImageViewerStudiesZustandContext,
	ImageViewerStudiesZustandProvider,
	useImageViewerStudiesZustandStore,
	useImageViewerStudiesZustandStoreSelector,
};
