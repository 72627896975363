const ROLE_USER_TYPE = {
	READING_PHYSICIAN: 'Reading Physician',
	REFERRING_PHYSICIAN: 'Referring Physician',
	PERFORMING_PHYSICIAN: 'Performing Physician',
	PERFORMING_Technologist: 'Performing Technologist',
	TRANSCRIPTIONIST: 'Transcriptionist',
	CONSULTING_PHYSICIAN: 'Consulting Physician',
	SCHEDULER_FRONT_DESK: 'Scheduler/Front Desk',
};

export { ROLE_USER_TYPE };
