export const roundToTwoDecimals = num => Math.round(num * 100) / 100;

export const parseDate = (date, divider = '-') => {
	let dateDisplay;
	if (date && date.length === 8) {
		const year = date.substr(0, 4);
		const month = date.substr(4, 2);
		const day = date.substr(6, 2);

		dateDisplay = `${month}${divider}${day}${divider}${year}`;
	}
	return dateDisplay;
};

export const parseTime = time => {
	if (!time) return;
	let timeDisplay;
	const actualTime = time.split('.')[0];
	if (actualTime && actualTime.length === 6) {
		const hour = actualTime.substr(0, 2);
		const min = actualTime.substr(2, 2);
		const sec = actualTime.substr(4, 2);

		timeDisplay = `${hour}:${min}:${sec}`;
	}
	return timeDisplay;
};
