import React, { useMemo } from 'react';
import { HelpPopOverButton } from '..';
import { BannerBody, BannerHeader, Container, HeroBanner } from './whatsNewStyles';
import webDesign from '../../../../assets/img/helpAssets/clip-web-design1.png';
import { loadOmegaMarkdown } from '../../../../../../../markdown/omega-ai/help-center/categories/markdownLoader';
import { useAuth } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';

const Highlights = () => {
	const { selectedLanguage } = useAuth();
	const { t } = useTranslation('helpCenter');
	const Content = useMemo(() => loadOmegaMarkdown(`${selectedLanguage}/overview/highlights`)?.content, []);

	return (
		<Container className="content-container">
			<HelpPopOverButton />
			<HeroBanner className="content-banner">
				<BannerBody>
					<BannerHeader>{t(`What's New in OmegaAI`)}</BannerHeader>
					<small>{t('We got to work to improve your experience. Explore the latest features.')}</small>
				</BannerBody>
				<img src={webDesign} />
			</HeroBanner>
			{Content}
		</Container>
	);
};
export default Highlights;
