import createViewport from '../utils/createViewport';
import { useDocumentViewerContext } from '../../../contexts/DocumentViewerContextProvider';
import { useTemplateManagerContext } from '../../../contexts/TemplateManagerContextProvider';
import { BLANK_REPORT_ID, DIAGNOSTIC_REPORT_STATUS } from '../../../consts/consts';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';

const useOnDocumentDrop = mode => {
	const { setViewports } = mode === 'TEMPLATE' ? useTemplateManagerContext() : useDocumentViewerContext();
	const galaxyDvWfaexecutionstatusFlag = useBooleanFlagValue('galaxy-dv-wfaexecutionstatus-flag');
	const galaxyDvUndoSignOff = useBooleanFlagValue('galaxy-dv-undo-sign-off');
	const { selectedPatientDocument, setSelectedPatientDocument } = usePatientInfoCardStore();

	const onDocumentDrop = ({ viewport, document }) => {
		setViewports(prevState => {
			const documentExist = prevState.find(
				item => item.documentId === document?.id && item.documentResourceType === document.resourceType
			);

			const extensions = Array.isArray(document?.extension) ? document.extension : []; // Patient Document dont have extension

			const isReportSignInProcess =
				galaxyDvWfaexecutionstatusFlag &&
				extensions?.find(item => item?.url === 'wfaExecutionStatus')?.valueString === 'started';

			if (documentExist) {
				return prevState;
			}

			if (document.resourceType === 'PatientDocument' && selectedPatientDocument?.id !== document.id) {
				setSelectedPatientDocument(document);
			}
			return prevState.map(item => {
				if (item.id === viewport.id) {
					return createViewport({
						...viewport,
						isReadonly:
							(document.status === DIAGNOSTIC_REPORT_STATUS.UNKNOWN && galaxyDvUndoSignOff) ||
							prevState[0].id !== item.id ||
							isReportSignInProcess,
						documentId: document.isBlank ? BLANK_REPORT_ID : document.id,
						documentResourceType: document.resourceType,
						amendedData: null,
						contentLoader: false,
					});
				}

				return item;
			});
		});
	};

	const onTemplateDrop = ({ document, options = {} }) => {
		setViewports(prevState => [
			{
				...prevState[0],
				...options,
				documentId: document.id,
				documentResourceType: document.resourceType,
			},
		]);
	};

	const onTemplateUploadDrop = ({ document, options = {} }) => {
		setViewports([
			createViewport({
				...options,
				documentId: document.id,
				documentResourceType: document.resourceType,
			}),
		]);
	};

	return {
		onDocumentDrop,
		onTemplateDrop,
		onTemplateUploadDrop,
	};
};

export default useOnDocumentDrop;
