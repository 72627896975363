import { v4 as uuid } from 'uuid';
import { useImportDispatcher } from '../features/Import/hooks/useImportDispatcher';
import { searchScopes, useConfig, useFhirDataLoader } from '@worklist-2/core/src';
import { patientEthnicity, patientCountry, patientRace } from '../components/PatientBanner/utils/patientUtils';
import { getStudyAccessionNumber, getStudyInstanceUID, getStudyModality } from '../utils/utils';
import { useSearchParams } from 'react-router-dom';
import { fileExtension, checkFileHeader, readFileAsync, sortFiles } from '../features/Import/utils/importUtils';
import { useImageViewerPatientContext } from '../contexts/ImageViewerPatientContext';
import {
	getStudyId,
	getStudyPriority,
	getStudyDescription,
	getStudyStatus,
	getStudyReferringPhysian,
	getPatientIssuer,
	getPatientSmoking,
} from '../features/Import/helpers/propertyExtractor';
import { ACCEPT_FILES_EXT, IMG_TYPES } from '../features/Import/consts/consts';

const error = {
	type: 'unsupported',
	message: 'Unsupported type format',
};

export const useImport = () => {
	const __config = useConfig();
	const { patient } = useImageViewerPatientContext();
	const [searchParams] = useSearchParams();
	const internalmanagingorganizationid = searchParams.get('internalManagingOrganizationID');

	let importDispatcherV2 = null;

	const studyDataLoader = useFhirDataLoader({
		scope: searchScopes.study,
	});

	if (!window.importDispatcherV2) {
		window.importDispatcherV2 = useImportDispatcher();
	} else {
		importDispatcherV2 = window.importDispatcherV2;
	}

	const importFiles = async ({ currentStudy, files }) => {
		const studyUId = getStudyInstanceUID(currentStudy);
		const managingOrganization = currentStudy.managingOrganizationId;

		const studyDataList = await studyDataLoader.load({
			internalmanagingorganizationid: managingOrganization,
			studyuid: studyUId,
		});

		const studyData = studyDataList?.at(0);

		if (!studyData) {
			return;
		}

		const studyProps = {
			id: getStudyId(studyData),
			uid: getStudyInstanceUID(currentStudy),
			priority: getStudyPriority(studyData),
			modality: getStudyModality(currentStudy),
			status: getStudyStatus(studyData),
			accessionNumber: getStudyAccessionNumber(currentStudy),
			referringPhysian: getStudyReferringPhysian(studyData),
			description: getStudyDescription(studyData),
		};

		const patientProps = {
			id: patient.patientId,
			name: patient.name,
			race: patientRace(patient)?.display || '',
			issuer: getPatientIssuer(studyData),
			country: patientCountry(patient),
			ethnicity: patientEthnicity(patient)?.display || '',
			smoking: getPatientSmoking(studyData),
		};

		const url = `${__config.data_sources.dicom_web}/studies`;

		importDispatcherV2.importFiles({
			files,
			studyProps,
			patientProps,
			internalmanagingorganizationid: managingOrganization ?? internalmanagingorganizationid,
			url,
		});
	};

	const handleInputFiles = async fileList => {
		const files = await Promise.all(
			[...fileList].map(file =>
				readFileAsync(file).then(async arrayBuffer => {
					const fileExt = fileExtension(file.name);
					const fileExtBySignature = checkFileHeader(arrayBuffer);
					let isAcceptedFileExt;
					let fileType;

					if (ACCEPT_FILES_EXT.includes(fileExt)) {
						fileType = fileExt;
						isAcceptedFileExt = true;
					} else if (ACCEPT_FILES_EXT.includes(fileExtBySignature)) {
						fileType = fileExtBySignature;
						isAcceptedFileExt = true;
					}

					const fileUid = uuid();
					return {
						file,
						arrayBuffer,
						...(!isAcceptedFileExt && {
							error,
						}),
						fileType: isAcceptedFileExt ? fileType : error.type,
						...(IMG_TYPES.includes(fileType) && { isGrouped: true }),
						id: fileUid,
						optimizedFilename: `${fileUid}.${fileType}`,
					};
				})
			)
		);
		const [pass, fail] = files.reduce(([p, f], i) => (i.error ? [p, [...f, i]] : [[...p, i], f]), [[], []]);

		return {
			pass: sortFiles(pass),
			fail,
		};
	};

	const retryImportFiles = fileIds => {
		importDispatcherV2.retryImportFiles(fileIds);
	};

	const stopImportFiles = fileIds => {
		importDispatcherV2.stopImportFiles(fileIds);
	};

	const stopImport = () => {
		importDispatcherV2.stopImport();
	};

	return { importFiles, handleInputFiles, retryImportFiles, stopImportFiles, stopImport };
};
