import React, { useState, useEffect } from 'react';
import { EVENTS } from '@cornerstonejs/core';

export const FrameNumberTag = ({ viewport, totalFrames }) => {
	const [label, setLabel] = useState('');
	const element = viewport?.element;

	const ON_CORNERSTONE_VOLUME_NEW_IMAGE = e => {
		const { imageIndex, numberOfSlices } = e.detail;
		setLabel(totalFrames ? `${imageIndex + 1}/${numberOfSlices}` : imageIndex + 1);
	};

	useEffect(() => {
		if (element) {
			element.addEventListener(EVENTS.VOLUME_NEW_IMAGE, ON_CORNERSTONE_VOLUME_NEW_IMAGE);
		}

		return () => {
			element?.removeEventListener(EVENTS.VOLUME_NEW_IMAGE, ON_CORNERSTONE_VOLUME_NEW_IMAGE);
		};
	}, [element, totalFrames]);

	if (!viewport || !element) return null;

	return label ? <span>{label}</span> : null;
};
