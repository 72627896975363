import { useState, useRef } from 'react';
import customSetStateHook from '../contexts/utils/customSetStateHook';

const useStateRef = (defaultValue) => {
	const [state, setState] = useState(defaultValue);
	const stateRef = useRef(state);

	stateRef.current = state;

	const customSetState = (value) => customSetStateHook(setState, value);

	return [state, customSetState, stateRef];
};

export default useStateRef;
