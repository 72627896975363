const customSetStateHook = async (setState, value) => {
	let result = value;

	if (value instanceof Function) {
		await setState((prevState) => {
			result = value(prevState);

			return result;
		});
	} else {
		await setState(value);
	}

	return result;
};

export default customSetStateHook;
