export const getCurrentDateTime = timezone => {
	let now = new Date();

	// Check if the Date object is valid
	if (isNaN(now.getTime())) {
		// Log the error
		console.error('Invalid Date object created. Falling back to system time.');

		// Optionally, you could try to re-instantiate the Date object
		now = new Date();
	}

	try {
		return {
			DateTime: now.toLocaleString('en-US', { timeZone: timezone }),
			UTCDateTime: now.toLocaleString('en-US', { timeZone: 'UTC' }),
		};
	} catch (error) {
		return {
			DateTime: now.toLocaleString('en-US'),
			UTCDateTime: now.toLocaleString('en-US', { timeZone: 'UTC' }),
		}; // Fallback to default locale
	}
};
