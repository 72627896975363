import React from 'react';
import NotificationLogGrid from '../NotificationLogGrid';

const NotificationLog = ({
	className,
	name,
	children,
	columns,
	filters,
	setFilters,
	sort,
	setSort,
	notificationType,
}) => (
	<div>
		{children}
		<NotificationLogGrid
			className={className}
			columns={columns}
			filters={filters}
			name={name}
			notificationType={notificationType}
			setFilters={setFilters}
			setSort={setSort}
			sort={sort}
		/>
	</div>
);

export default NotificationLog;
