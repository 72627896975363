import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import { findMetadataTag } from './utils';

const ENCODED_RESOLUTIONS_TAG = 'RESOLUTIONADDRESSES';

export const defineLowResPreview = (imageId, metadata) =>
	findMetadataTag(metadata || cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId), '7fe00010')
		?.BulkDataURI?.[0]?.toUpperCase()
		?.includes(ENCODED_RESOLUTIONS_TAG);

export const getLowResImageId = imageId => (defineLowResPreview(imageId) ? `${imageId}?fsiz=64x64` : imageId);
