import getDefaultRenderingEngine from './getDefaultRenderingEngine';
import { utilities } from '@cornerstonejs/core';

const getViewportWindowLevel = (viewportId) => {
	const renderingEngine = getDefaultRenderingEngine();

	const cornerstoneViewport = renderingEngine.getViewport(viewportId);
	const cornerstoneViewportProperties = cornerstoneViewport.getProperties();

	const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(
		cornerstoneViewportProperties.voiRange.lower,
		cornerstoneViewportProperties.voiRange.upper
	);

	return {
		windowWidth,
		windowCenter,
	};
};

export default getViewportWindowLevel;
