import getStudyDiagnosticReports from './getStudyDiagnosticReports';
import { DIAGNOSTIC_REPORT_STATUS } from '../consts/consts';

const getStudyFinalDiagnosticReports = ({ studyId, diagnosticReports }) =>
	getStudyDiagnosticReports({ studyId, diagnosticReports }).filter(diagnosticReport => {
		const signDelayStatus = diagnosticReport?.extension?.find(element =>
			element?.url?.includes('signDelayStatus')
		)?.valueString;
		return (
			diagnosticReport.status === DIAGNOSTIC_REPORT_STATUS.FINAL ||
			diagnosticReport.status === DIAGNOSTIC_REPORT_STATUS.PARTIAL ||
			diagnosticReport.status === DIAGNOSTIC_REPORT_STATUS.AMENDED ||
			(diagnosticReport.status === DIAGNOSTIC_REPORT_STATUS.UNKNOWN && signDelayStatus !== 'started')
		);
	});

export default getStudyFinalDiagnosticReports;
