import { ImportDispatcher } from '../ImportDispatcherV2';
import { useAuth, useConfig } from '@worklist-2/core/src';
import { useMsal } from '@azure/msal-react';

export function useImportDispatcher() {
	const { instance } = useMsal();
	const { accessToken, sessionId, updateAccessToken } = useAuth();
	const __config = useConfig();
	const dispatcherObject = new ImportDispatcher(
		accessToken,
		updateAccessToken,
		sessionId,
		instance,
		__config.auth.omegaai.scopes
	);

	const importFiles = async ({ files, studyProps, patientProps, internalmanagingorganizationid, url }) => {
		dispatcherObject.importFiles({
			files,
			studyProps,
			patientProps,
			internalmanagingorganizationid,
			url,
		});
	};

	const retryImportFiles = fileIds => {
		dispatcherObject.retryImportFiles(fileIds);
	};

	const stopImportFiles = fileIds => {
		dispatcherObject.stopImportFiles(fileIds);
	};

	const stopImport = () => {
		dispatcherObject.stopImport();
	};
	return { importFiles, retryImportFiles, stopImportFiles, stopImport };
}
