import _ from 'lodash';
import { fhirExtensionUrls } from '@worklist-2/core/src';

// To check user role by managing organization
const getUserRoleByOrganization = (practitionerRoles, organizationId, roleToBe) => {
	let currentOrganizationRole = _.filter(practitionerRoles, organizationRole => {
		if (organizationRole.organization?.id == organizationId) {
			return organizationRole;
		}
	})?.[0];

	if (!currentOrganizationRole) {
		return false;
	}
	const practitionerUserType = (currentOrganizationRole.extension || []).find(
		ext => ext.url === fhirExtensionUrls.practitionerRole.studyPlayerType
	);
	return Boolean(practitionerUserType?.valueCoding?.display === roleToBe);
};

export default getUserRoleByOrganization;
