import React from 'react';
import { Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';

export const UploadingTypeToggle = ({ isActive, onChangeImportCapabilities }) => (
	<ToggleButtonGroup
		exclusive
		aria-label="Small sizes"
		size="small"
		value={!!isActive}
		onChange={(_, v) => onChangeImportCapabilities(v)}
	>
		<ToggleButton
			key="IMAGES"
			sx={{
				py: '2px',
				'&.MuiToggleButton-root.Mui-selected': {
					backgroundColor: 'var(--rsprimary-main)',
				},
			}}
			value={false}
		>
			<Typography variant="body2">For Image Viewer</Typography>
		</ToggleButton>
		<ToggleButton
			key="DOCUMENT"
			value
			sx={{
				py: '2px',
				'&.MuiToggleButton-root.Mui-selected': {
					backgroundColor: 'var(--rsprimary-main)',
				},
			}}
		>
			<Typography variant="body2">For Document Viewer</Typography>
		</ToggleButton>
	</ToggleButtonGroup>
);
