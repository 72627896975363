import { useCallback, useEffect } from 'react';
import { searchScopes, useFhirDataLoader } from '@worklist-2/core/src';

import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';
import useOnDocumentDrop from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/hooks/useOnDocumentDrop';
import useOpenStartDocument from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/hooks/useOpenStartDocument';
import { useDocumentViewerContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/DocumentViewerContextProvider';

const usePatientDocuments = () => {
	const { selectedCard, selectedPatientDocument, setSelectedPatientDocument, isDocumentViewer } =
		usePatientInfoCardStore();
	const { documents: patientDocuments, setDocuments } = usePatientStore();
	const { viewports } = useDocumentViewerContext();
	const openStartDocument = useOpenStartDocument(isDocumentViewer)?.openStartDocument;
	const onDocumentDrop = useOnDocumentDrop(isDocumentViewer)?.onDocumentDrop;
	const documentsLoader = useFhirDataLoader({
		scope: searchScopes.documentReference,
	});

	const handleOnClose = useCallback(
		(document = null) => {
			setSelectedPatientDocument(document);
		},
		[setSelectedPatientDocument]
	);

	useEffect(() => {
		if (selectedCard?.code !== 'DOCUMENTS' && selectedPatientDocument) {
			handleOnClose();
		}
	}, [selectedCard, selectedPatientDocument, handleOnClose]);

	const handleOnUpload = useCallback(
		(parsedDoc, result) => {
			setDocuments(prevDocs => {
				if (prevDocs.some(doc => doc.id === parsedDoc.id)) {
					return prevDocs;
				}
				return [parsedDoc, ...prevDocs];
			});
			handleOnClose(parsedDoc);
			if (isDocumentViewer && selectedCard?.code === 'DOCUMENTS' && result.content && onDocumentDrop) {
				onDocumentDrop({ viewport: viewports[0], document: parsedDoc });
			}
		},
		[setDocuments, handleOnClose]
	);

	const handleOnDelete = useCallback(
		documentData => {
			const documentId = documentData?.id;
			if (!documentId) return;

			documentsLoader.delete(documentId).then(res => {
				if (!res) return;

				const updatedDocuments = patientDocuments.filter(doc => doc.id !== documentId);
				setDocuments(updatedDocuments);
				if (isDocumentViewer) {
					if (updatedDocuments.length > 0 && onDocumentDrop) {
						// If there are remaining documents, open the next available one
						onDocumentDrop({
							viewport: viewports[0],
							document: updatedDocuments[0], // Load the first document in the updated list
						});
					} else {
						// If no documents remain, open the start document
						openStartDocument?.();
					}
				}
				handleOnClose(updatedDocuments.length > 0 ? updatedDocuments[0] : null);
			});
		},
		[documentsLoader, patientDocuments, setDocuments, handleOnClose]
	);

	return {
		selectedPatientDocument,
		handleOnUpload,
		handleOnDelete,
		handleOnClose,
	};
};

export default usePatientDocuments;
