export const sectionsPath = {
	patientInfo: '#patient-info',
	patientNeeds: '#patient-needs',
	medicalHistory: '#medical-history',
	prescriptions: '#prescriptions',
	insurance: '#insurance',
	allergy: '#allergy',
};

export const sectionsIconColor = {
	patientInfo: '#D26FC8',
	patientNeeds: '#C38CD7',
	medicalHistory: '#568AF3',
	prescriptions: '#568AF3',
	insurance: '#468AB1',
	allergy: '#03cafc',
};

export const SmokingCodes = {
	nonSmoker: '266919005',
	unknownIfEverSmoked: '266927001',
	lightSmoker: '428061000124105',
	heavySmoker: '428071000124103',
	everydaySmoker: '449868002',
	formerSmoker: '8517006',
};

export const AlcoholUseCodes = {
	nonDrinker: '160443006',
	social: '70200004',
	daily: '160434009',
	heavyDrinker: '365981007',
};
