import useGetSearchParams from './useGetSearchParams';
import { useGlobalContext } from '../contexts/GlobalContextProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useStudiesStore } from '../store/studies';

const useGetCurrentStudy = () => {
	const { studyId } = useGetSearchParams();
	const { studies } = useGlobalContext();
	const galaxyDvPerformanceRefactorGetCall = useBooleanFlagValue('galaxy-dv-performance-refactor-get-call');
	const currentStudyFromStore = useStudiesStore(state => state.currentStudy);
	const getCurrentStudy = () => studies?.find(study => study.id === studyId);

	const currentStudy = galaxyDvPerformanceRefactorGetCall ? currentStudyFromStore : getCurrentStudy();

	return {
		currentStudy,
	};
};

export default useGetCurrentStudy;
