import { Box } from '@mui/material';
import { useAppModeContext, useHelpCenter, EventEmitter } from '@worklist-2/core/src';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { navLinks } from '..';
import { ArticleWrapper } from '@worklist-2/patientPortal/src/views/HelpView/HelpCenter/patientHelpStyles';

const HelpContent = ({ style }) => {
	const { setCurrentPiPTabs, openMenu } = useHelpCenter();
	const [html, setHtml] = useState('<div></div>');
	const { appMode } = useAppModeContext();

	useEffect(() => {
		setContent();
		EventEmitter.subscribe('update-popover-content', () => setContent());
	}, []);

	useEffect(() => {
		if (appMode !== 'patientPortal') {
			const path = localStorage.getItem('markdownPath');
			tabsFinder(path)
				.then(tabs => {
					setCurrentPiPTabs(tabs);
					openMenu(path);
				})
				.catch(error => {
					console.error(error);
				});
		}
	}, [appMode]);

	const tabsFinder = path =>
		new Promise((resolve, reject) => {
			try {
				let tabs = [];
				// search navlinks for tabs wrt path
				navLinks.forEach(element => {
					if (element.path === path) {
						tabs = element.tabs || [];
					} else if (!_.isEmpty(element.tabs) && element.tabs.map(tab => tab.path).includes(path)) {
						tabs = element.tabs;
					} else if (!_.isEmpty(element.subNavLink)) {
						if (
							_.flatMap(element.subNavLink.map(link => link.path.split('/'))).includes(path.split('/')[0])
						) {
							element.subNavLink.forEach(link => {
								if (link.path === path && !_.isEmpty(link.tabs)) {
									tabs = link.tabs;
								} else if (!_.isEmpty(link.tabs)) {
									const eIndex = _.findIndex(link?.tabs, tab => tab.path === path);
									if (eIndex >= 0) {
										tabs = link.tabs;
									}
								}
							});
						}
					}
				});
				resolve(tabs);
			} catch (error) {
				reject(error.message);
			}
		});

	const setContent = () => {
		const ele = document.querySelector('.content-container');
		if (ele) setHtml(ele?.outerHTML);
	};

	const attrs = useMemo(
		() => ({
			dangerouslySetInnerHTML: { __html: html || '<div></div>' },
		}),
		[html]
	);

	return appMode === 'patientPortal' ? (
		<ArticleWrapper data-testid="HelpContentBlume" style={style}>
			<div {...attrs} />
		</ArticleWrapper>
	) : (
		<Box
			{...attrs}
			data-testid="HelpContent"
			sx={{
				'& .content-container': {
					margin: '0px !important',
					padding: '0px !important',
				},
			}}
		/>
	);
};
export default HelpContent;
