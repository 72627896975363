import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { utilities } from '@cornerstonejs/core';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getRoundToTwo } from '../../utils/utils';
import { useImageViewerLayoutContext } from '../../contexts/ImageViewerLayoutContext';
import { useOverlayStoreSelector } from '../../contexts/OverlayContext';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { useImageViewerStudiesContext } from '../../contexts/ImageViewerStudiesContext';
import { useTranslation } from 'react-i18next';
import ImageViewerViewportCustomOverlay from './ImageViewerViewportCustomOverlay';
import levelsOfDetails from '@rs-ui/views/CustomizeOverlaysView/const/levelsOfDetails';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import { ImageViewerViewportCustomOverlayV2Wrapper } from './ImageViewerViewportCustomOverlayV2Wrapper';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const parseDate = date => {
	let dateDisplay;
	if (date && date.length === 8) {
		const year = date.substr(0, 4);
		const month = date.substr(4, 2);
		const day = date.substr(6, 2);

		dateDisplay = `${parseInt(month)}/${parseInt(day)}/${parseInt(year)}`;
	}
	return dateDisplay;
};

const parseTime = time => {
	let timeDisplay;
	if (time && time.length === 6) {
		const hour = time.substr(0, 2);
		const min = time.substr(2, 2);
		const sec = time.substr(4, 2);

		timeDisplay = `${hour}:${min}:${sec}`;
	}
	return timeDisplay;
};

const getGenderDisplay = (Gender, t) => {
	if (Gender) {
		switch (Gender) {
			case 'M':
				return t('viewport.MALE', { defaultValue: 'MALE' });
			case 'F':
				return t('viewport.FEMALE', { defaultValue: 'FEMALE' });
			case 'O':
				return 'OTHER';
			default:
		}
	}
};

const getImageCompression = (instance, t) => {
	const LossyImageCompression = instance?.['00282110']?.Value?.at(0);
	const LossyImageCompressionRatio = instance?.['00282112']?.Value?.at(0);
	const LossyImageCompressionMethod = instance?.['00282114']?.Value?.at(0);
	const TransferSyntaxUID = instance?.['00020010']?.Value?.at(0);

	if (LossyImageCompression == '00') {
		return t('viewport.Lossless');
	}
	if (LossyImageCompression == '01' && LossyImageCompressionRatio) {
		return `Lossy ${parseFloat(LossyImageCompressionRatio).toFixed(2)} : 1 ${LossyImageCompressionMethod}`;
	}
	if (!LossyImageCompression && TransferSyntaxUID) {
		if (
			TransferSyntaxUID == '1.2.840.10008.1.2' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.1' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.1.99' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.2'
		) {
			return 'Uncompressed';
		}
		if (
			TransferSyntaxUID == '1.2.840.10008.1.2.4.50' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.51' ||
			TransferSyntaxUID == '1.2.840.10008.1.2.4.52'
		) {
			return 'JPEG Lossy';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.57') {
			return 'JPEG Lossless';
		}

		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.70') {
			return 'JPEG Lossless First Order';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.80') {
			return 'JPEG-LS (Lossless)';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.81') {
			return 'JPEG-LS Lossy';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.90' || TransferSyntaxUID == '1.2.840.10008.1.2.4.92') {
			return 'JPEG 2K (Lossless)';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.91' || TransferSyntaxUID == '1.2.840.10008.1.2.4.93') {
			return 'JPEG 2K Lossy';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.5') {
			return 'RLE Lossless';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.100' || TransferSyntaxUID == '1.2.840.10008.1.2.4.101') {
			return 'MPEG-2';
		}
		if (TransferSyntaxUID == '1.2.840.10008.1.2.4.102' || TransferSyntaxUID == '1.2.840.10008.1.2.4.103') {
			return ' MPEG-4';
		}
	}
};

const getLine4 = (instance, t) => {
	const AcquisitionDateTime = instance?.['0008002a']?.Value?.at(0);
	const AcquisitionDate = instance?.['00080022']?.Value?.at(0);
	const AcquisitionTime = instance?.['00080032']?.Value?.at(0);
	const ContentDate = instance?.['00080023']?.Value?.at(0);
	const ContentTime = instance?.['00080033']?.Value?.at(0);
	const SeriesDate = instance?.['00080021']?.Value?.at(0);
	const SeriesTime = instance?.['00080031']?.Value?.at(0);
	const StudyDate = instance?.['00080020']?.Value?.at(0);
	const StudyTime = instance?.['00080030']?.Value?.at(0);

	if (AcquisitionDateTime) {
		let AcquisitionDateTimeDisplay = AcquisitionDateTime;
		if (AcquisitionDateTime.length >= 14) {
			AcquisitionDateTimeDisplay = `${parseDate(AcquisitionDateTime.substr(0, 8))} ${parseTime(
				AcquisitionDateTime.substr(8, 6)
			)}`;
		}
		return { name: 'Acquisition', value: AcquisitionDateTimeDisplay };
	}
	if (AcquisitionDate && AcquisitionTime) {
		return {
			name: 'Acquisition',
			value: `${parseDate(AcquisitionDate)} ${parseTime(AcquisitionTime.substr(0, 6))}`,
		};
	}
	if (AcquisitionDate)
		return {
			name: t('viewport.Acquisition Date'),
			value: parseDate(AcquisitionDate),
		};
	if (AcquisitionTime)
		return {
			name: 'Acquisition Time',
			value: parseTime(AcquisitionTime.substr(0, 6)),
		};
	if (ContentDate) return { name: 'Content Date', value: parseDate(ContentDate) };
	if (ContentTime)
		return {
			name: 'Content Time',
			value: parseTime(ContentTime.substr(0, 6)),
		};
	if (SeriesDate) return { name: 'Series Date', value: parseDate(SeriesDate) };
	if (SeriesTime)
		return {
			name: 'Series Time',
			value: parseTime(SeriesTime.substr(0, 6)),
		};
	if (StudyDate) return { name: 'Study Date', value: parseDate(StudyDate) };
	if (StudyTime)
		return {
			name: 'Study Time',
			value: parseDate(StudyTime.substr(0, 6)),
		};
};

const instanceNumberTemplate = (value, t) => `${t('viewport.Instance Number')} #: ${value}`;

const sliceNumberTemplate = (valueImageIndex, valueNumberOfSlices) =>
	`Slice Number #: ${valueImageIndex} / ${valueNumberOfSlices}`;

const sliceLocationTemplate = (value, t) => (value ? `${t('viewport.Slice Location')}: ${value}` : '');

const imagePositionPatientTemplate = (value, t) =>
	value?.length
		? `Image Position (Patient): ${getRoundToTwo(value.at(0))}, ${getRoundToTwo(value.at(1))}, ${getRoundToTwo(
				value.at(2)
		  )}`
		: '';

const viewIndicatorTemplate = (value, t) => `${t('viewport.View Indicator')}: ${value}`;

export const windowLevelingTemplate = (valueCenter, valueLevel) =>
	`C: ${Math.round(valueCenter)} W: ${Math.round(valueLevel)}`;

const ImageViewerViewportOverlay = ({
	isCurrentStudy,
	viewportId,
	instances,
	imageIds,
	initialInstanceIndex,
	index,
	color,
	series,
}) => {
	const { layoutItems, isMPRView, isFusionView } = useImageViewerLayoutContext();
	const { currentStudy } = useImageViewerStudiesContext();
	const boxRef = useRef();
	const setProfile = useOverlayStoreSelector(state => state.setProfile);
	const { t } = useTranslation('imageViewer');
	useEffect(() => {
		const element = boxRef?.current;

		if (!element) {
			return;
		}

		const resizeObserver = new ResizeObserver(entries => {
			entries.forEach(entry => {
				if (entry.contentRect.height > 500) {
					setProfile(levelsOfDetails.overlayConfig_Detailed);
				} else {
					setProfile(levelsOfDetails.overlayConfig_Minimal);
				}
			});
		});

		resizeObserver.observe(element);

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const wonIvApplycustomoverlay = useBooleanFlagValue('WON-IV-APPLYCUSTOMOVERLAY');
	const wonIvCustomOverlayV2 = useBooleanFlagValue('WON-IV-CUSTOM-OVERLAY-V2');
	const wonIvOverlaytextbg = useBooleanFlagValue('WON-IV-OVERLAYTEXTBG');
	const [activeImageIdIndex, setActiveImageIdIndex] = useState(initialInstanceIndex + index);

	const instanceNumberRef = useRef();
	const sliceNumberRef = useRef();
	const sliceLocationRef = useRef();
	const imagePositionPatientRef = useRef();
	const viewIndicatorRef = useRef();
	const windowLevelingRef = useRef();

	const activeInstance = imageIds?.[activeImageIdIndex]
		? cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageIds[activeImageIdIndex])
		: undefined;

	const overlayData = useMemo(() => {
		//Top Left
		const DOB = activeInstance?.['00100030']?.Value?.at(0);
		const DOBdisplay = parseDate(DOB);
		const Age = activeInstance?.['00101010']?.Value?.at(0);
		const Gender = activeInstance?.['00100040']?.Value?.at(0);
		const GenderDisplay = getGenderDisplay(Gender, t);
		const PID = activeInstance?.['00100020']?.Value?.at(0);
		const StudyId = activeInstance?.['00200010']?.Value?.at(0);

		// line 4
		const StudyDate = activeInstance?.['00080020']?.Value?.at(0);
		const Line4Display = getLine4(activeInstance, t);

		//Top Middle
		let reasonForVisit = activeInstance?.['00321066']?.Value?.at(0);
		if (typeof reasonForVisit !== 'string' || reasonForVisit === null || reasonForVisit.trim() === '') {
			reasonForVisit = activeInstance?.['00324000']?.Value?.at(0);
		}
		const StudyComments = reasonForVisit;

		//Top Right
		const StudyDateDisplay = parseDate(StudyDate);
		const StudyDescription = activeInstance?.['00081030']?.Value?.at(0);
		const SeriesNumber = activeInstance?.['00200011']?.Value?.at(0);
		const SeriesDescription = activeInstance?.['0008103e']?.Value?.at(0);
		const InstanceNumber = activeImageIdIndex;
		const ViewIndicator = activeInstance?.['00200037']?.Value?.at(0);

		//Left Middle
		const SliceThickness = activeInstance?.['00180050']?.Value?.at(0);
		const SliceLocation = activeInstance?.['00201041']?.Value?.at(0);
		const TablePosition = activeInstance?.['00189327']?.Value?.at(0);
		const ImagePositionPatient = activeInstance?.['00200032']?.Value;
		const SpacingBetweenSlices = activeInstance?.['00180088']?.Value?.at(0);

		//Down Left
		const ContrastBolusAgent = activeInstance?.['00180010']?.Value?.at(0);
		const C_WindowCenter = activeInstance?.['00281050']?.Value?.at(0);
		const W_WindowLevel = activeInstance?.['00281051']?.Value?.at(0);
		const S_SharpnessDegree = activeInstance?.['0016004a']?.Value?.at(0);
		const U_UnsharpMaskDegree = activeInstance?.['310d1020']?.Value?.at(0);
		const ImageCompressionDisplay = getImageCompression(activeInstance, t);

		//Down Middle
		const DerivationDescription = activeInstance?.['00082111']?.Value?.at(0);

		//Down Right
		const TechsName = activeInstance?.['00081070']?.Value?.at(0)?.Alphabetic;
		const StationName = activeInstance?.['00081010']?.Value?.at(0);
		const InstitutionName = activeInstance?.['00080080']?.Value?.at(0);

		const topLeft = (
			<Typography
				data-testid="top-left-display"
				id="top-left-display"
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					top: '3%',
					left: '2%',
				}}
			>
				{PID && `${t('viewport.PID')}: ${PID}`}
				<br />
				{DOBdisplay} {Age} {GenderDisplay}
				<br />
				{StudyId && `${t('Study')}: ${StudyId}`}
				<br />
				{Line4Display && `${Line4Display?.name}: ${Line4Display?.value}`}
			</Typography>
		);

		const topCenter = (
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					top: '3%',
					left: '50%',
					transform: 'translate(-50%, 0)',
					textAlign: 'center',
				}}
			>
				{StudyComments}
			</Typography>
		);

		const topRight = (
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					top: '3%',
					right: 'calc(2% + 34px)',
					textAlign: 'right',
				}}
			>
				{StudyDateDisplay}
				{StudyDescription && ` - ${StudyDescription}`}
				<br />
				{SeriesNumber && `${t('Series')} #: ${SeriesNumber}`}
				{SeriesDescription && ` - ${SeriesDescription}`}
				<br />
				<span ref={instanceNumberRef}>{instanceNumberTemplate(InstanceNumber + 1, t)}</span>
				<br />
				<span ref={viewIndicatorRef}>{ViewIndicator && viewIndicatorTemplate(ViewIndicator, t)}</span>
			</Typography>
		);

		const bottomRight = (
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					bottom: '3%',
					right:
						layoutItems[layoutItems.length - 1]?.viewports?.[0]?.id === viewportId
							? '80px'
							: 'calc(2% + 34px)', //last item should avoid document view button
					textAlign: 'right',
				}}
			>
				{TechsName && `Tech's name: ${TechsName}`}
				<br />
				{StationName && `${t('viewport.Station Name')}: ${StationName}`}
				<br />
				{InstitutionName && `${t('viewport.Institution Name')}: ${InstitutionName}`}
			</Typography>
		);

		const bottomCenter = (
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					bottom: '3%',
					left: '50%',
					transform: 'translate(-50%, 0)',
					textAlign: 'center',
				}}
			>
				{DerivationDescription}
			</Typography>
		);

		const bottomLeft = (
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					bottom: '3%',
					left: '2%',
				}}
			>
				{isCurrentStudy ? t('Current') : 'Prior'}
				<br />
				{ContrastBolusAgent && `Contrast/Bolus Agent: ${ContrastBolusAgent}`}
				{ContrastBolusAgent && <br />}
				<span ref={windowLevelingRef}>{windowLevelingTemplate(C_WindowCenter, W_WindowLevel)}</span>
				<br />
				{S_SharpnessDegree && `S: ${S_SharpnessDegree}`} {U_UnsharpMaskDegree && `U: ${U_UnsharpMaskDegree}`}
				{(S_SharpnessDegree || U_UnsharpMaskDegree) && <br />}
				{ImageCompressionDisplay}
			</Typography>
		);

		const rightCenter = (
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					right: 'calc(2% + 34px)',
					top: '50%',
					transform: 'translate(0, 50%)',
					textAlign: 'right',
				}}
			/>
		);

		const leftCenter = (
			<Typography
				sx={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color,
					opacity: '0.87',
					position: 'absolute',
					top: '50%',
					left: '2%',
					transform: 'translate(0, -50%)',
					textAlign: 'left',
				}}
			>
				{SliceThickness && `${t('viewport.Slice Thickness')}: ${SliceThickness}mm`}
				<br />
				<span ref={sliceLocationRef}>
					{SliceLocation && sliceLocationTemplate(getRoundToTwo(SliceLocation), t)}
				</span>
				{!SliceLocation && TablePosition && `Table Position: ${getRoundToTwo(TablePosition)}`}
				<br />
				<span ref={imagePositionPatientRef}>
					{!SliceLocation &&
						!TablePosition &&
						ImagePositionPatient?.length &&
						imagePositionPatientTemplate(ImagePositionPatient, t)}
				</span>
				<br />
				{SpacingBetweenSlices && `${t('viewport.Spacing Between Slices')}: ${SpacingBetweenSlices}`}
			</Typography>
		);

		if (isMPRView || isFusionView) {
			return {
				topLeft: (
					<Typography
						id="top-left-display"
						sx={{
							fontWeight: '500',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.25px',
							color,
							opacity: '0.87',
							position: 'absolute',
							top: '3%',
							left: '2%',
						}}
					>
						<span ref={windowLevelingRef}>{windowLevelingTemplate(C_WindowCenter, W_WindowLevel)}</span>
					</Typography>
				),
				topRight: (
					<Typography
						sx={{
							fontWeight: '500',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.25px',
							color,
							opacity: '0.87',
							position: 'absolute',
							top: '3%',
							right: 'calc(2% + 34px)',
							textAlign: 'right',
						}}
					>
						<span ref={sliceNumberRef}>{sliceNumberTemplate(InstanceNumber + 1)}</span>
					</Typography>
				),
			};
		}

		return {
			topLeft,
			topCenter,
			topRight,
			bottomRight,
			bottomCenter,
			bottomLeft,
			leftCenter,
			rightCenter,
		};
	}, [activeImageIdIndex, isMPRView, isFusionView]);

	const ON_CORNERSTONE_STACK_NEW_IMAGE = useCallback(
		e => {
			const activeIndex = imageIds?.findIndex(imageId => imageId === e?.detail?.imageId);

			if (activeIndex === -1) {
				return;
			}

			const activeInstance = imageIds?.[activeIndex]
				? cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageIds[activeIndex])
				: undefined;

			if (instanceNumberRef.current) {
				instanceNumberRef.current.innerHTML = instanceNumberTemplate(activeIndex + 1, t);
			}

			if (sliceLocationRef.current) {
				const SliceLocation = activeInstance?.['00201041']?.Value?.at(0);

				sliceLocationRef.current.innerHTML = sliceLocationTemplate(getRoundToTwo(SliceLocation), t);
			}

			if (imagePositionPatientRef.current) {
				const SliceLocation = activeInstance?.['00201041']?.Value?.at(0);
				const imagePositionPatient = activeInstance?.['00200032']?.Value;

				if (!SliceLocation) {
					imagePositionPatientRef.current.innerHTML = imagePositionPatientTemplate(imagePositionPatient, t);
				}
			}

			if (viewIndicatorRef.current) {
				const ViewIndicator = activeInstance?.['00200037']?.Value?.at(0);

				viewIndicatorRef.current.innerHTML = viewIndicatorTemplate(ViewIndicator, t);
			}
		},
		[index]
	);

	const ON_CORNERSTONE_VOLUME_NEW_IMAGE = e => {
		if (sliceNumberRef.current) {
			sliceNumberRef.current.innerHTML = sliceNumberTemplate(e.detail.imageIndex + 1, e.detail.numberOfSlices);
		}
	};

	const ON_CORNERSTONE_VOI_MODIFIED = e => {
		if (windowLevelingRef.current) {
			const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(
				e.detail.range.lower,
				e.detail.range.upper
			);

			windowLevelingRef.current.innerHTML = windowLevelingTemplate(windowCenter, windowWidth);
		}
	};

	useEffect(() => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const renderingEngineViewport = renderingEngine.getViewport(viewportId);

		if (renderingEngineViewport) {
			const renderingEngineViewportProperties = renderingEngineViewport?.getProperties?.();

			if (renderingEngineViewportProperties) {
				if (!renderingEngineViewportProperties.voiRange) {
					return;
				}

				const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(
					renderingEngineViewportProperties.voiRange.lower,
					renderingEngineViewportProperties.voiRange.upper
				);
				if (windowLevelingRef.current?.innerHTML) {
					windowLevelingTemplate(windowCenter, windowWidth);
				}
			}

			if (renderingEngineViewport.getImageData()) {
				setActiveImageIdIndex(renderingEngineViewport.getCurrentImageIdIndex() + index);
			}
		}

		const element = document.getElementById(viewportId);

		element?.addEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);

		element?.addEventListener('CORNERSTONE_VOLUME_NEW_IMAGE', ON_CORNERSTONE_VOLUME_NEW_IMAGE);

		element?.addEventListener('CORNERSTONE_VOI_MODIFIED', ON_CORNERSTONE_VOI_MODIFIED);

		return () => {
			element?.removeEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);

			element?.removeEventListener('CORNERSTONE_VOLUME_NEW_IMAGE', ON_CORNERSTONE_VOLUME_NEW_IMAGE);

			element?.removeEventListener('CORNERSTONE_VOI_MODIFIED', ON_CORNERSTONE_VOI_MODIFIED);
		};
	}, []);

	if (!currentStudy) return <></>;
	return (
		<Box
			ref={boxRef}
			className={`${viewportId} fs-exclude`}
			data-testid="ImageViewerViewportOverlay"
			sx={{
				position: 'absolute',
				pointerEvents: 'none',
				top: '0',
				left: '0',
				width: '100%',
				height: '100%',
				zIndex: wonIvCustomOverlayV2 ? '0' : '1',
			}}
		>
			{wonIvApplycustomoverlay ? (
				wonIvCustomOverlayV2 ? (
					<ImageViewerViewportCustomOverlayV2Wrapper
						enableTextShadow={wonIvOverlaytextbg}
						isCurrentStudy={isCurrentStudy}
						series={series}
						viewportId={viewportId}
					/>
				) : (
					<ImageViewerViewportCustomOverlay
						activeInstance={activeInstance}
						currentStudy={currentStudy}
						index={index}
						initialInstanceIndex={initialInstanceIndex}
						instances={instances}
						isCurrentStudy={isCurrentStudy}
						viewportId={viewportId}
					/>
				)
			) : (
				<>
					{overlayData.topLeft}

					{overlayData.topCenter}

					{overlayData.topRight}

					{overlayData.bottomRight}

					{overlayData.bottomLeft}

					{overlayData.bottomCenter}

					{overlayData.leftCenter}

					{overlayData.rightCenter}
				</>
			)}
		</Box>
	);
};

export default ImageViewerViewportOverlay;
