export const sectionsPath = {
	patientInfo: '#patient-info',
	patientNeeds: '#patient-needs',
	medicalHistory: '#medical-history',
	prescriptions: '#prescriptions',
	coverage: '#coverage',
	allergy: '#allergy',
};

export const sectionsIconColor = {
	patientInfo: '#D26FC8',
	patientNeeds: '#C38CD7',
	medicalHistory: '#568AF3',
	prescriptions: '#568AF3',
	coverage: '#468AB1',
	allergy: '#03cafc',
};

export const SmokingCodes = {
	nonSmoker: '266919005',
	unknownIfEverSmoked: '266927001',
	lightSmoker: '428061000124105',
	heavySmoker: '428071000124103',
	everydaySmoker: '449868002',
	formerSmoker: '8517006',
};

export const AlcoholUseCodes = {
	nonDrinker: '160443006',
	social: '70200004',
	daily: '160434009',
	heavyDrinker: '365981007',
};

export const SmokingStatuses = {
	light: 'LIGHT',
	heavy: 'HEAVY',
	daily: 'DAILY',
};

export const AlcoholStatuses = {
	social: 'SOCIAL',
	heavy: 'HEAVY',
	daily: 'DAILY',
};

export const vitalCodes = {
	bodyHeight: '8302-2',
	bodyWeight: '29463-7',
	heartRate: '8867-4',
	diastolicBlood: '8462-4',
	systolicBlood: '8480-6',
	bloodPressure: '85354-9',
};

export const BmiPossibleOptions = ['Underweight', 'Normal', 'Overweight', 'Obese'];

export const SmokingStatus = [
	{
		code: '266919005',
		display: 'Never smoker',
	},
	{
		code: '266927001',
		display: 'Unknown if ever smoked',
	},
];

export const FILE_ICON = {
	'application/msword': 'DOC',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC', // docx
	'application/pdf': 'PDF',
	'image/jpeg': 'JPG',
	'image/png': 'PNG',
};

export const PATIENT_ID_CODE = 'MR';

export const PATIENT_SSN_CODE = 'SS';

export const PATIENT_DRIVER_LICENCE_CODE = 'DL';

export const patientConfidentialitySystem = 'http://hl7.org/fhir/v3/Confidentiality';
