import {
	getExtensionValueCodingArrayConcat,
	fhirExtensionUrls,
	getExtensionValueString,
	getExtensionValueReference,
} from '@worklist-2/core/src';

export default function createQueryParam(
	data,
	valueSetResult,
	defaultStudyId,
	templateType,
	templateName = null,
	count = 50
) {
	const bodyPartExtensionValue = getExtensionValueCodingArrayConcat(data, fhirExtensionUrls.common.bodyPart)?.split(
		'|'
	);
	const lateralityExtensionValue = getExtensionValueCodingArrayConcat(
		data,
		fhirExtensionUrls.common.laterality
	)?.split('|');

	const statusParam = valueSetResult?.[2]?.find(
		elem => elem.display === getExtensionValueString(data, fhirExtensionUrls.common.status)
	);

	function getBodyPartCodeJSON() {
		const tempBodyPart = [];
		for (const iterator of bodyPartExtensionValue) {
			if (iterator != '') {
				tempBodyPart.push(valueSetResult?.[0]?.find(elem => elem.display === iterator.trim())?.code);
			}
		}
		tempBodyPart.push('ALL');
		if (tempBodyPart.length > 0) return JSON.stringify(tempBodyPart);
		return null;
	}

	function getLateralityCodeJSON() {
		const tempLaterality = [];
		for (const iterator of lateralityExtensionValue) {
			if (iterator != '') {
				tempLaterality.push(valueSetResult?.[1]?.find(elem => elem.display === iterator.trim())?.code);
			}
		}
		if (tempLaterality.length > 0) return JSON.stringify(tempLaterality);
		return null;
	}

	function getModalityJSON(modality) {
		const modalityArray = [];
		if (modality) {
			for (const iterator of modality) {
				if (iterator != '') {
					modalityArray.push(iterator.code);
				}
			}
			modalityArray.push('ALL');
		}
		if (modalityArray.length == 0) return null;
		return JSON.stringify(modalityArray);
	}

	function getStudyProcedure() {
		const procedureArr = [];
		data?.procedureCode?.[0]?.coding?.forEach(code => {
			procedureArr.push(code.code);
		});
		return procedureArr?.length > 0 ? JSON.stringify(procedureArr) : null;
	}

	const paramList = {
		internalstudyid: defaultStudyId,
		category: templateType?.toUpperCase(),
		modality: getModalityJSON(data?.modality),
		studystatusvalue: statusParam?.code,
		bodypart: getBodyPartCodeJSON(),
		laterality: getLateralityCodeJSON(),
		gender: getExtensionValueString(data.subject, fhirExtensionUrls.common.gender)?.toUpperCase()?.[0],
		procedurecode: getStudyProcedure(),
		referringphysician: getExtensionValueString(data, fhirExtensionUrls.common.referringPhysician),
		imagingOrgId: getExtensionValueReference(data, fhirExtensionUrls.common.imagingOrganization)?.id,
		isBestMatch: true,
		_count: count,
	};

	if (templateName) {
		paramList.templateName = templateName;
		paramList.templateNameContains = true;
	}

	const queryString = Object.entries(paramList)
		.filter(([key, value]) => value !== null && value !== undefined && value !== '')
		.map(([key, value]) => `${key}=${value}`)
		.join('&');

	return encodeURIComponent(queryString);
}
