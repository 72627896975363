const stringUtil = {
	// Upper cases the first letter of a text string        //          #TODO: remove this and use lodash `upperFirst()` instead !
	capitalizeFirstLetter: text => {
		return text[0].toUpperCase() + text.substring(1);
	},

	// Upper Cases Each Word
	toTileCase: text => {
		if (text) {
			let words = text.toLowerCase().split(' ');

			return words
				.filter(word => word) // removes all empty spaces in there are extra spaces between words
				.map(word => stringUtil.capitalizeFirstLetter(word))
				.join(' ');
		}
		return '';
	},
};

export default stringUtil;
