/**
 * Fix MG images Orientation
 *
 * @param {string} viewCode
 * @param {string} imageLaterality
 * @param {string} rightPatientDirection - Patient orientation information for the right side.
 * @param {string} bottomPatientDirection - Patient orientation information for the bottom side.
 * @returns {Object} - An object containing rotation and flip information after orientation adjustment.
 */

let expectedTopPatientDirection,
	expectedBottomPatientDirection,
	expectedLeftPatientDirection,
	expectedRightPatientDirection;

function fixMGOrientation(viewCode, imageLaterality, rightPatientDirection, bottomPatientDirection) {
	setupExpectedPatientDirection(viewCode, imageLaterality);

	if (!isWellOriented(rightPatientDirection, bottomPatientDirection)) {
		return performOrientation(rightPatientDirection, bottomPatientDirection);
	}

	return { rotation: null, flip: null };
}

function isWellOriented(rightPatientDirection, bottomPatientDirection) {
	return (
		expectedRightPatientDirection === rightPatientDirection &&
		expectedBottomPatientDirection === bottomPatientDirection
	);
}

function setupExpectedPatientDirection(viewCode, imageLaterality) {
	const PatientHead = 'H';
	const PatientFoot = 'F';
	const PatientLeft = 'L';
	const PatientRight = 'R';
	const PatientAnterior = 'A';
	const PatientPosterior = 'P';
	const LeftBreast = 'L';
	const RightBreast = 'R';
	const BothBreasts = 'B';

	switch (viewCode) {
		case 'MG: RISO':
		case 'MG: RLM':
		case 'MG: RLMO':
		case 'MG: RML':
		case 'MG: RMLO':
		case 'MG: RSIO':
		case 'MG: LISO':
		case 'MG: LLM':
		case 'MG: LLMO':
		case 'MG: LML':
		case 'MG: LMLO':
		case 'MG: LSIO':

		case 'ML':
		case 'MLO':
		case 'LM':
		case 'LMO':
		case 'SIO':
			expectedTopPatientDirection = PatientHead;
			expectedBottomPatientDirection = PatientFoot;

			if (imageLaterality === RightBreast || imageLaterality === BothBreasts) {
				expectedTopPatientDirection += PatientRight;
				expectedBottomPatientDirection += PatientLeft;
			} else if (imageLaterality === LeftBreast) {
				expectedTopPatientDirection += PatientLeft;
				expectedBottomPatientDirection += PatientRight;
			}

			if (imageLaterality === RightBreast || imageLaterality === BothBreasts) {
				expectedLeftPatientDirection = PatientAnterior;
				expectedRightPatientDirection = PatientPosterior;
			} else if (imageLaterality === LeftBreast) {
				expectedLeftPatientDirection = PatientPosterior;
				expectedRightPatientDirection = PatientAnterior;
			}
			break;

		case 'MG: RCC':
		case 'MG: RFB':
		case 'MG: RXCC':
		case 'MG: RXCCL':
		case 'MG: RXCCM':
		case 'MG: LCC':
		case 'MG: LFB':
		case 'MG: LXCC':
		case 'MG: LXCCL':
		case 'MG: LXCCM':

		case 'CC':
		case 'FB':
		case 'XCC':
		case 'XCCL':
		case 'XCCM':
			if (imageLaterality === RightBreast || imageLaterality === BothBreasts) {
				expectedTopPatientDirection = PatientRight;
				expectedBottomPatientDirection = PatientLeft;
				expectedLeftPatientDirection = PatientAnterior;
				expectedRightPatientDirection = PatientPosterior;
			} else if (imageLaterality === LeftBreast) {
				expectedTopPatientDirection = PatientLeft;
				expectedBottomPatientDirection = PatientRight;
				expectedLeftPatientDirection = PatientPosterior;
				expectedRightPatientDirection = PatientAnterior;
			}

			break;

		case 'CV':
			expectedTopPatientDirection = PatientHead;
			expectedBottomPatientDirection = PatientFoot;
			expectedLeftPatientDirection = PatientLeft;
			expectedRightPatientDirection = PatientRight;

			break;
	}
}

function performOrientation(rightPatientDirection, bottomPatientDirection) {
	let rotation, flip;

	if (expectedRightPatientDirection.includes(bottomPatientDirection)) {
		// Rotate 90 degrees counter-clockwise
		rotation = 'rotateL';
	} else if (expectedBottomPatientDirection.includes(rightPatientDirection)) {
		// Rotate 90 degrees clockwise
		rotation = 'rotateR';
	} else if (
		expectedTopPatientDirection.includes(bottomPatientDirection) &&
		expectedLeftPatientDirection.includes(rightPatientDirection)
	) {
		// Rotate 180 degrees clockwise
		rotation = 'rotate180';
	}

	// Exam H flip to correct left and right
	if (
		expectedBottomPatientDirection.includes(bottomPatientDirection) &&
		!expectedRightPatientDirection.includes(rightPatientDirection)
	) {
		// Image may no longer be horizontally flipped from original if we have a 180 deg rotation
		flip = 'flipH';
	} else if (
		expectedRightPatientDirection.includes(rightPatientDirection) &&
		!expectedBottomPatientDirection.includes(bottomPatientDirection)
	) {
		flip = 'flipV';
	}

	return { rotation, flip };
}

export default fixMGOrientation;
