import { useSearchParams } from 'react-router-dom';
import { useConfig, useCache } from '@worklist-2/core/src';
import { getSeriesUID, getStudyInstanceUID, getStudyDate } from '../utils/utils';
import { useImageViewerStudiesContext } from '../contexts/ImageViewerStudiesContext';
import { SynchronousElasticSearchDicomJsonSyncEvent } from '../consts/customImportHeaders';
import { useStudies } from './useStudies';
import axios from 'axios';
import { useImageViewerView3DContext } from '../contexts/ImageViewerView3DContext';
import { useImageViewerMeasurementContext } from '../contexts/ImageViewerMeasurementContext';
import loadSeriesWithMetadata from '../api/loadSeriesWithMetadata';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const useSeries = () => {
	const __config = useConfig();
	const [searchParams] = useSearchParams();
	const { seriesRef, setSeries, setCurrentStudy, setStudies, setKeyImages, keyImages } =
		useImageViewerStudiesContext();
	const { setMeasurementData, measurementData, deleteMeasurementFromAnnotationManager } =
		useImageViewerMeasurementContext();
	const { loadingManagerRef } = useImageViewerView3DContext();
	const { loadStudies } = useStudies();
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const wonIvStillsfirst = useBooleanFlagValue('WON-IV-STILLSFIRST');
	const wonIvEnhancedcurvedsort = useBooleanFlagValue('WON-IV-ENHANCEDCURVEDSORT');
	const wonIvRefactorsorting = useBooleanFlagValue('WON-IV-REFACTORSORTING');
	const { cacheLinks } = useCache();
	const internalManagingOrganizationID = searchParams.get('internalManagingOrganizationID');
	const currentStudyInstanceUID = searchParams.get('StudyInstanceUIDs');

	const deleteSeries = async (studyId, seriesIds, studyOrganizationId) => {
		const deleteMethod = (studyUid, seriesId) =>
			new Promise(async (resolve, reject) => {
				try {
					const response = await axios.delete(
						`${
							__config.data_sources.dicom_web
						}/study/${studyUid}/series/${seriesId}?InternalOrganizationID=${
							studyOrganizationId ?? internalManagingOrganizationID
						}`
					);
					resolve({
						...response,
						seriesId,
					});
				} catch (error) {
					resolve({
						status: 500,
						seriesId,
					});
				}
			});
		try {
			const results = await Promise.all(seriesIds.map(async id => deleteMethod(studyId, id)));
			const successfulDeletes = results.filter(result => result.status === 200).map(result => result.seriesId);
			const failedDeletes = results.filter(result => result.status != 200).map(result => result.seriesId);

			if (successfulDeletes.length > 0) {
				const filterSeries = seriesRef.current.filter(
					item => !(successfulDeletes.includes(getSeriesUID(item)) && item.studyInstanceUID === studyId)
				);
				setSeries(filterSeries);

				// Remove linked key images and measurements
				const linkedKeyImages = keyImages.filter(
					keyImage =>
						successfulDeletes.includes(keyImage.SeriesID) &&
						keyImage.StudyInstanceUID === studyId &&
						keyImage.Managingorganizationid === (studyOrganizationId ?? internalManagingOrganizationID)
				);
				setKeyImages(prev => prev.filter(keyImage => !linkedKeyImages.includes(keyImage)));

				const linkedMeasurements = measurementData.filter(
					measurement =>
						successfulDeletes.includes(measurement.seriesId) &&
						measurement.studyId === studyId &&
						measurement.managingOrganizationId === (studyOrganizationId ?? internalManagingOrganizationID)
				);
				setMeasurementData(prev => prev.filter(measurement => !linkedMeasurements.includes(measurement)));
				linkedMeasurements.forEach(item => deleteMeasurementFromAnnotationManager(item));
			}

			return { success: successfulDeletes, failed: failedDeletes };
		} catch {
			return { success: [], failed: seriesIds };
		}
	};

	const renameSeries = async (studyId, seriesId, value, studyOrganizationId) => {
		try {
			const URL = `${
				__config.data_sources.dicom_web
			}/studies/${studyId}/series/${seriesId}?InternalOrganizationID=${
				studyOrganizationId ?? internalManagingOrganizationID
			}&SeriesDescription=${value}`;
			await axios.put(URL);

			const remapSeries = seriesRef.current.map(item => {
				if (getSeriesUID(item) === seriesId) {
					return {
						...item,
						'0008103E': {
							Value: [value],
						},
					};
				}
				return item;
			});
			setSeries(remapSeries);
		} catch (error) {}
	};

	const reparentSeries = async (
		currentStudyUid,
		currentSeriesUids,
		destStudyUid,
		sourceManagingOrganizationId,
		destManagingOrganizationId = 0
	) => {
		const seriesUid = currentSeriesUids.toString();
		const URL = `${__config.data_sources.dicom_web}/reparent/${currentStudyUid}/series/${seriesUid}?destStudy=${destStudyUid}&internalManagingOrganizationId=${sourceManagingOrganizationId}&destManagingOrganizationId=${destManagingOrganizationId}`;
		const resp = await axios.post(URL, null, {
			headers: {
				...SynchronousElasticSearchDicomJsonSyncEvent,
			},
		});
		if (resp.status === 200) {
			//Reload studies and linked studies
			const updatedStudies = await loadStudies({}).then(async studies => {
				const linkedStudies = await loadStudies({ isLinkedPatient: true });

				const studiesArr = [...studies, ...linkedStudies];
				studiesArr?.sort((a, b) => new Date(getStudyDate(b)) - new Date(getStudyDate(a)));
				return studiesArr;
			});
			const updatedCurrentStudy = updatedStudies.find(s => getStudyInstanceUID(s) === currentStudyInstanceUID);
			updatedCurrentStudy && setCurrentStudy(updatedCurrentStudy);
			setStudies(updatedStudies);

			// Reload series
			const studiesNeedReload = [
				{
					studyUid: currentStudyUid,
					studyOrganizationId: sourceManagingOrganizationId,
				},
				{
					studyUid: destStudyUid,
					studyOrganizationId:
						destManagingOrganizationId === 0 ? sourceManagingOrganizationId : destManagingOrganizationId,
				},
			];
			const seriesBatch = await Promise.all(
				studiesNeedReload.map(async study => {
					if (loadingManagerRef?.current?.studySeries) {
						loadingManagerRef.current.studySeries[study.studyUid] = true;
					}

					return await loadSeriesWithMetadata({
						__config,
						managingOrganizationId: study.studyOrganizationId,
						studyInstanceUID: study.studyUid,
						wonIvSeriesSplitter,
						cacheLinks,
						wonIvPlaneSplitter,
						wonIvEnhancedcurvedsort,
						wonIvRefactorsorting,
						wonIvStillsfirst,
						is3D: true,
					});
				})
			);

			const updatedSeries = [];
			seriesBatch.forEach(item => updatedSeries.push(...item));

			const filterSeries = [
				...seriesRef.current.filter(
					item =>
						!studiesNeedReload.some(
							st =>
								st.studyUid === item.studyInstanceUID &&
								st.studyOrganizationId === item.managingOrganizationId
						)
				),
				...updatedSeries,
			];

			setSeries(filterSeries);
		}
	};

	return {
		deleteSeries,
		renameSeries,
		reparentSeries,
	};
};
