import React, { useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { Box } from '@mui/system';
import { QuestionSubmit } from './helpStyles';
import LiveSupportButton from './LiveSupportButton';
import CustomTextField from '../CustomTextField';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { useMediaQuery } from '@mui/material';

const SubmitQuestion = ({ setView }) => {
	const [error, setError] = useState('');
	const isSmall = useMediaQuery(theme => theme.breakpoints.down(550));

	const onEmailChange = () => {
		setError('');
	};

	const handleSubmit = (event, email, questionForm) => {
		if (!email.value) {
			setError('Please enter your email address.');
			event.preventDefault();
		} else if (!email.validity.valid) {
			setError(email.validationMessage);
			event.preventDefault();
		} else {
			const salesforceUrl = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';

			const data = new URLSearchParams();
			data.append('name', questionForm.name.value);
			data.append('email', questionForm.email.value);
			data.append('description', questionForm.description.value);
			data.append('subject', `Help Question from ${questionForm.name.value}`);
			data.append('orgid', '00D300000000vZv');
			data.append('retURL', 'http://');
			data.append('external', '1');
			data.append('00N5000000AQ0x1', 'No');

			const requestMetadata = {
				method: 'POST',
				body: data,
			};

			fetch(salesforceUrl, requestMetadata);
			localStorage.setItem('keyword', '');
			setView('questionSubmit');
		}
	};

	return (
		<>
			<Box>
				<QuestionSubmit>
					<form id="questionForm">
						<HelpIcon color="rsPrimary" sx={{ fontSize: 25, marginTop: 1 }} />
						<p className="hint">Submit your question here</p>
						<div className="text">
							<CustomTextField
								id="name"
								inputProps={{ 'aria-label': 'Full Name' }}
								name="name"
								placeholder="Full Name"
								sx={{ ml: 1, mr: 1 }}
							/>
							<CustomTextField
								autoComplete="email"
								id="email"
								inputProps={{ 'aria-label': 'Email Address' }}
								name="email"
								placeholder="Email Address"
								sx={{ ml: 1, mr: 1 }}
								type="email"
								onChange={onEmailChange}
							/>
							<CustomTextField
								multiline
								inputProps={{
									'aria-label': 'Enter your question here',
								}}
								name="description"
								placeholder="Enter your question here"
								rows={isSmall ? 4 : 1}
								sx={{ ml: 1, mr: 1 }}
							/>
						</div>
						<Button
							color="rsPrimary"
							sx={{
								width: '50%',
								maxWidth: '250px',
								height: '41px',
								mt: '25px',
								borderRadius: '5px',
								fontSize: '14px',
								lineHeight: '16px',
								fontWeight: '500',
								letterSpacing: '1.25px',
								color: '#FFFFFFD9',
								display: 'inline-block',
								margin: '5px',
								'@media (max-width: 600px)': {
									width: '150px',
									boxShadow: 'none',
								},
							}}
							type="button"
							variant="contained"
							onClick={event => handleSubmit(event, email, questionForm)}
						>
							Submit
						</Button>

						<Button
							color="rsPrimary"
							sx={{
								width: '50%',
								maxWidth: '250px',
								height: '41px',
								mt: '25px',
								borderRadius: '5px',
								fontSize: '14px',
								lineHeight: '16px',
								fontWeight: '500',
								letterSpacing: '1.25px',
								flex: 2,
								display: 'inline-block',
								margin: '5px',
								'@media (max-width: 600px)': {
									width: '150px',
								},
							}}
							type="button"
							variant="outlined"
							onClick={() => setView('faq')}
						>
							Cancel
						</Button>
						<FormLabel
							data-cy="labelerror_lgp1"
							style={{
								whiteSpace: 'pre-wrap',
								color: 'red',
								marginTop: '15px',
								maxWidth: '415px',
								display: { error } != '' ? 'inline-block' : 'none',
							}}
						>
							{error}
						</FormLabel>
					</form>
				</QuestionSubmit>
			</Box>
			<LiveSupportButton onClick={() => setView('support')} />
		</>
	);
};

export default SubmitQuestion;
