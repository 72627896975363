import React, { useMemo } from 'react';
import classnames from 'classnames';
import RsGrid from '@rs-ui/components/Grid';
import { searchScopes, useAuth } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';

const ActivityHistoryGrid = ({ className, name, columns, filters, sort, setSort, setFilters }) => {
	const { userInfo } = useAuth();
	const { t } = useTranslation('log');

	const extraParam = useMemo(
		() => ({
			'userlogin:exact': userInfo.username,
			entityType: 'Encounter,ImagingStudy,Patient,ServiceRequest,OmegaAiLink,PublicLink',
		}),
		[userInfo]
	);

	return (
		<>
			{!!userInfo && (
				<RsGrid
					className={classnames(className)}
					columns={columns}
					enableRowClick={false}
					enableRowDnd={false}
					filters={filters}
					name={name}
					scope={searchScopes.logsElk}
					searchExtra={extraParam}
					setFilters={setFilters}
					setSort={setSort}
					sort={sort}
					title={t('Activity History')}
				/>
			)}
		</>
	);
};

export default ActivityHistoryGrid;
