import findExtensionIndex from '../resource/columnMapping/utils/findExtensionIndex';

const updateExtension = (extension, url, values) => {
	if (Array.isArray(extension)) {
		const extensionIndex = findExtensionIndex(extension, url);
		const newExtensionObj = {
			url,
			...values,
		};

		if (extensionIndex >= 0) {
			extension[extensionIndex] = newExtensionObj;
		} else {
			extension.push(newExtensionObj);
		}
	}
};

const deleteExtension = (extension, url) => {
	if (Array.isArray(extension)) {
		const extensionIndex = findExtensionIndex(extension, url);

		if (extensionIndex > -1) {
			extension.splice(extensionIndex, 1);
		}
	}
};

const removeDuplicateExtensions = extensions => {
	const uniqueUrls = new Set();
	const result = [];

	for (const obj of extensions) {
		if (!uniqueUrls.has(obj.url)) {
			uniqueUrls.add(obj.url);
			result.push(obj);
		}
	}

	return result;
};

export { updateExtension, deleteExtension, removeDuplicateExtensions };
