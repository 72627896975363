const OLD_BLANK_REPORT_JSON = {
	resourceType: 'DiagnosticReport',
	status: 'preliminary',
	category: [
		{
			coding: [
				{
					code: 'LP29684-5',
					display: 'Radiology',
				},
			],
		},
	],
	code: {
		coding: [
			{
				code: '18748-4',
				display: 'Diagnostic Imaging Study',
			},
		],
	},
	subject: {
		id: '24',
		reference: 'patient/24',
	},
	imagingStudy: [
		{
			id: '29',
			reference: 'imagingstudy/29',
		},
	],
	presentedForm: [
		{
			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/originalForm',
					valueAttachment: {
						contentType: 'text/html',
						title: 'Draft',
					},
				},
			],
			title: 'Draft',
		},
	],
	extension: [],
	isBlank: true,
};

const NEW_BLANK_REPORT_JSON = {
	resourceType: 'DiagnosticReport',
	status: 'preliminary',
	category: [
		{
			coding: [
				{
					code: ' ',
					display: ' ',
				},
			],
		},
	],
	code: {
		coding: [
			{
				code: ' ',
				display: ' ',
			},
		],
	},
	subject: {
		id: ' ',
		reference: ' ',
	},
	imagingStudy: [
		{
			id: ' ',
			reference: ' ',
		},
	],
	presentedForm: [
		{
			extension: [
				{
					url: ' ',
					valueAttachment: {
						contentType: ' ',
						title: ' ',
					},
				},
			],
			title: ' ',
		},
	],
	extension: [],
	isBlank: true,
};

export { OLD_BLANK_REPORT_JSON, NEW_BLANK_REPORT_JSON };
