import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import IconButton from '@mui/material/IconButton';
import { useHelpCenter, useAuth } from '@worklist-2/core/src';
import React, { useMemo } from 'react';
import { HelpPopOverButton } from '..';
import VideoPreview from '../VideoPlayer/VideoPreview';
import { BannerBody, BannerHeader, Container, Content, HeroBanner, VideoSlide, VideoSlides } from './whatsNewStyles';
import urbanCycling from '../../../../assets/img/helpAssets/clip-urban-cycling1.png';
import videoCover1 from '../../../../assets/img/helpAssets/pexels-andrea-piacquadio-37605141.png';
import videoCover2 from '../../../../assets/img/helpAssets/pexels-jopwell-24222931.png';
import videoCover3 from '../../../../assets/img/helpAssets/pexels-mart-production-70893871.png';
import { loadOmegaMarkdown } from '../../../../../../../markdown/omega-ai/help-center/categories/markdownLoader';

const Training = () => {
	const { selectedLanguage } = useAuth();
	const { nowPlaying, setNowPlaying } = useHelpCenter();
	const videos = [
		{
			cover: videoCover1,
			title: 'Welcome to Omega',
			url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
			subtitle: 'Take a video tour of our new IP. Exciting features innovating the industry.',
		},
		{
			cover: videoCover2,
			title: 'Welcome to Omega',
			url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
			subtitle: 'Take a video tour of our new IP. Exciting features innovating the industry.',
		},
		{
			cover: videoCover3,
			title: 'Welcome to Omega',
			url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
			subtitle: 'Take a video tour of our new IP. Exciting features innovating the industry.',
		},
	];

	const ContentText = useMemo(() => loadOmegaMarkdown(`${selectedLanguage}/overview/training`)?.content, []);

	return (
		<Container className="content-container">
			{nowPlaying && (
				<IconButton
					id="help-popover-btn"
					style={{ width: 40, height: 40, left: -60, marginTop: -15 }}
					onClick={() => setNowPlaying(null)}
				>
					<ArrowBackOutlinedIcon />
				</IconButton>
			)}
			<HelpPopOverButton />
			{!nowPlaying ? (
				<>
					<HeroBanner className="content-banner">
						<BannerBody>
							<BannerHeader>Welcome to OmegaAI</BannerHeader>
							<small>We wrote up a few tutorials so you can dive right in. Welcome to Omega</small>
						</BannerBody>
						<img src={urbanCycling} />
					</HeroBanner>
					<Content>
						{ContentText}
						{videos.length > 0 && (
							<>
								<h5>Some videos to get you started</h5>
								<VideoSlides>
									{videos.map((video, i) => (
										<VideoSlide key={i} onClick={() => setNowPlaying({ video })}>
											<div
												className="video-cover"
												style={{
													backgroundImage: `url(${video.cover})`,
												}}
											/>
											<div style={{ margin: '0px 10px' }}>
												<p>{video.title}</p>
												<span>{video.subtitle}</span>
											</div>
										</VideoSlide>
									))}
								</VideoSlides>
							</>
						)}
					</Content>
				</>
			) : (
				<VideoPreview ignorePicMode={false} />
			)}
		</Container>
	);
};
export default Training;
