import React from 'react';
import ActivityHistoryGrid from '../ActivityHistoryGrid';

const ActivityHistory = ({ className, name, children, columns, filters, setFilters, sort, setSort }) => (
	<div>
		{children}
		<ActivityHistoryGrid
			className={className}
			columns={columns}
			filters={filters}
			name={name}
			setFilters={setFilters}
			setSort={setSort}
			sort={sort}
		/>
	</div>
);

export default ActivityHistory;
