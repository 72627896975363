// @flow

import React, { type Node } from 'react';
import { Box, Typography } from '@mui/material';

interface IPlainField {
	label: string;
	value: string;
}

const PlainField = ({ label, value }: IPlainField): Node => (
	<Box
		data-testid="organization-detail-field"
		sx={{
			display: 'flex',
			flexDirection: 'column',
		}}
	>
		<Typography sx={{ fontSize: '14px', color: '#FFFFFF99' }}>{label}</Typography>
		<Typography sx={{ fontSize: '16px', color: '#FFFFFFDE' }}>{value || 'N/A'}</Typography>
	</Box>
);

export default PlainField;
