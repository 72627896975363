import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { useHelpCenter, useWindowSize } from '@worklist-2/core/src';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	ControlsMask,
	TimeControlsWrapper,
	TrainingPreviewWrapper,
	VideoPlayerWrapper,
} from '../WhatsNew/whatsNewStyles';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useTranslation } from 'react-i18next';

const VideoPreview = ({ ignorePicMode }) => {
	const { t } = useTranslation('helpCenter');
	const videoRef = useRef(null);
	const [currentTime, setCurrentTime] = useState(0);
	const [progress, setProgress] = useState(0);
	const { nowPlaying, setNowPlaying } = useHelpCenter();
	const { video } = nowPlaying;
	const pictureMode = useMemo(() => nowPlaying?.isPictureMode, [nowPlaying]);
	const [showFooter, setShowFooter] = useState(true);

	useEffect(() => {
		if (nowPlaying?.currentTime) {
			if (videoRef.current) {
				videoRef.current.currentTime = nowPlaying?.currentTime;
				setCurrentTime(nowPlaying?.currentTime);
				if (nowPlaying.playing) videoRef.current.play();
			}
		}
	}, [pictureMode]);

	const videoHandler = () => {
		const vid = document.getElementById('video1');
		if (!nowPlaying.playing) {
			videoRef.current.play();
			setNowPlaying({
				...nowPlaying,
				videoTime: vid.duration,
				playing: true,
			});
		} else {
			videoRef.current.pause();
			setNowPlaying({
				...nowPlaying,
				videoTime: vid.duration,
				playing: false,
			});
		}
	};

	const fastForward = () => {
		videoRef.current.currentTime += 5;
	};

	const revert = () => {
		videoRef.current.currentTime -= 5;
	};

	const fullscreen = () => {
		videoRef.current.requestFullscreen();
	};

	window.setInterval(() => {
		setCurrentTime(videoRef.current?.currentTime);
		setProgress((videoRef.current?.currentTime / nowPlaying.videoTime) * 100);
	}, 1000);

	const openVideoPopUp = () => {
		setNowPlaying({
			...nowPlaying,
			isPictureMode: !nowPlaying.isPictureMode,
			currentTime,
		});
	};

	return (
		<TrainingPreviewWrapper>
			{ignorePicMode || !pictureMode ? (
				<VideoPlayerWrapper>
					<Controls
						fastForward={fastForward}
						revert={revert}
						setShowFooter={setShowFooter}
						videoHandler={videoHandler}
					/>
					<IconButton
						sx={{
							color: '#FFF',
							margin: '10px',
							position: 'absolute',
							zIndex: 10,
							width: 40,
						}}
						onClick={!pictureMode ? openVideoPopUp : fullscreen}
					>
						{!pictureMode ? (
							<CloseFullscreenOutlinedIcon data-testid="PictureModeButton" />
						) : (
							<OpenInFullIcon data-testid="FullScreenButton" />
						)}
					</IconButton>

					{pictureMode && (
						<IconButton
							sx={{
								color: '#FFF',
								margin: '10px',
								position: 'absolute',
								zIndex: 10,
								width: 40,
								right: 0,
							}}
							onClick={openVideoPopUp}
						>
							<CloseIcon />
						</IconButton>
					)}
					<video ref={videoRef} className="video" id="video1" src={video.url} />
					<TimeControls currentTime={currentTime} progress={progress} />
				</VideoPlayerWrapper>
			) : (
				<VideoPlayerWrapper>
					<Paper
						sx={{
							background: '#272727',
							padding: '30px',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							height: 300,
						}}
					>
						<PictureInPictureIcon sx={{ color: '#FFF', fontSize: 30 }} />
						<h1
							data-testid="PictureMode"
							style={{
								fontFamily: 'Roboto',
								margin: 0,
								textAlign: 'center',
								fontWeight: 'normal',
							}}
						>
							{t('Playing in Picture Mode')}
						</h1>
					</Paper>
				</VideoPlayerWrapper>
			)}
			{showFooter && (
				<div className="video-details" style={{ margin: '0px 10px' }}>
					<p>{video.title}</p>
					<span>{video.subtitle}</span>
				</div>
			)}
		</TrainingPreviewWrapper>
	);
};

export default VideoPreview;

const Controls = ({ videoHandler, fastForward, revert, setShowFooter }) => {
	const { nowPlaying } = useHelpCenter();
	const windowSize = useWindowSize();
	const [hidden, setHidden] = useState(true);
	let timer;

	const showVideoNotes = useMemo(
		() => (!nowPlaying?.isPictureMode ? true : nowPlaying?.isPictureMode && !hidden),
		[nowPlaying?.isPictureMode, hidden]
	);

	useEffect(() => setShowFooter(showVideoNotes), [showVideoNotes]);

	const dim = useMemo(() => {
		const videoEle = document.getElementById('video1');
		return {
			width: `${videoEle?.offsetWidth}px`,
			height: `${videoEle?.offsetHeight}px`,
			opacity: hidden ? 0 : 1,
		};
	}, [windowSize, hidden]);

	const handleBlur = () => {
		timer = setTimeout(() => {
			setHidden(true);
		}, 3000);
	};

	const handleFocus = () => {
		if (timer) {
			clearTimeout(timer);
		}
		setHidden(false);
	};

	const iconStyle = { color: '#FFF', fontSize: '32px' };
	const btnStyle = { width: 50, height: 50, margin: '0px 40px' };

	return (
		<ControlsMask
			data-testid="VideoControlsMask"
			style={dim}
			onBlur={handleBlur}
			onMouseEnter={handleFocus}
			onMouseLeave={handleBlur}
		>
			<IconButton data-testid="RevertTime" sx={btnStyle} onClick={() => revert()}>
				<SkipPreviousIcon sx={iconStyle} />
			</IconButton>
			<IconButton disabled data-testid="ClickVideoHandler" sx={btnStyle} onClick={() => videoHandler()}>
				{!nowPlaying.playing ? <PlayArrowIcon sx={iconStyle} /> : <PauseIcon sx={iconStyle} />}
			</IconButton>
			<IconButton data-testid="FastForward" sx={btnStyle} onClick={() => fastForward()}>
				<SkipNextIcon sx={iconStyle} />
			</IconButton>
		</ControlsMask>
	);
};

const TimeControls = ({ progress }) => (
	<TimeControlsWrapper>
		<div className="time_progressbarContainer">
			<div className="time_progressBar" style={{ width: `${progress}%` }} />
		</div>
	</TimeControlsWrapper>
);
