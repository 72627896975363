import CrosshairPointerTool from '../cornerstoneTools/CrosshairPointerTool';
import { StackScrollTool } from '@cornerstonejs/tools';
import { useImageViewerWheelContext } from '../contexts/ImageViewerWheelContext';
import useOnSetActiveTool from './useOnSetActiveTool';
import { useEffect, useRef } from 'react';

const useResetActiveTool = () => {
	const { activeTool } = useImageViewerWheelContext();
	const { onSetActiveTool } = useOnSetActiveTool();
	const activeToolHistory = useRef([]);

	useEffect(() => {
		if (activeToolHistory.current.length > 1) {
			activeToolHistory.current.shift();
		}
		activeToolHistory.current.push(activeTool);
	}, [activeTool]);

	const resetActiveTool = () => {
		if (activeTool === CrosshairPointerTool.toolName) {
			onSetActiveTool(StackScrollTool.toolName);
		}
	};

	const resetToPreviousActiveTool = () => {
		if (activeToolHistory.current.length > 1) {
			onSetActiveTool(activeToolHistory.current[0]);
		}
	};

	return {
		resetActiveTool,
		resetToPreviousActiveTool,
	};
};

export default useResetActiveTool;
