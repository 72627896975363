export const DEFAULT_COVERAGE = {
	resourceType: 'Coverage',
	id: '',
	meta: {
		extension: [
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateUser',
				valueReference: {
					id: '',
					reference: '',
					display: '',
				},
			},
		],
		versionId: '',
		lastUpdated: '',
	},
	extension: [
		{ url: 'isActive', valueBoolean: true },
		{ 
			url: 'insuranceStatus', 
			valueCoding: { code: '', display: ''} 
		},
		{
			url: 'financialClass',
			valueCoding: { code: '', display: '' },
		},
		{ url: 'copayType', valueString: '$' },
		{ url: 'copayAmount', valueDecimal: 0 },
		{ url: 'copayPercent', valueDecimal: 0 },
		{ url: 'claimAdjustorPhone', valueString: '' },
		{
			url: 'claimAdjustorName',
			valueHumanName: { text: '' },
		},
		{
			url: 'http://hl7.org/fhir/StructureDefinition/customfield1',
			valueString: '',
		},
		{
			url: 'http://hl7.org/fhir/StructureDefinition/customfield2',
			valueString: '',
		},
		{
			url: 'http://hl7.org/fhir/StructureDefinition/custommemo1',
			valueString: '',
		},
		{ url: 'insuranceCardBack' },
		{ url: 'insuranceCardFront' },
	],
	status: 'active',
	subscriber: {
		id: '',
		reference: '',
		display: '',
	},
	subscriberId: '',
	beneficiary: {
		id: '',
		reference: '',
		display: '',
	},
	relationship: {
		coding: [
			{
				system: 'http://hl7.org/fhir/policyholder-relationship',
				code: 'SELF',
				display: 'SELF',
			},
		],
	},
	period: {
		start: '',
		end: '',
	},
	payor: [
		{
            data: {
                networkInOut: 'In-Network',
            },
			id: '',
			reference: '',
			display: '',
		},
	],
	class: [
		{
			type: {
				coding: [
					{
						system: 'http://terminology.hl7.org/CodeSystem/coverage-class',
						code: 'group',
					},
				],
			},
			value: '',
			name: 'group',
		},
	],
	order: 1,
	subrogation: false,
};

// TO-DO: Get rid of the populated values
export const DEFAULT_PATIENT = {
	resourceType: 'Patient',
	id: '',
	meta: {
		versionId: '',
		lastUpdated: '',
	},
	extension: [
		{
			extension: [],
			url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/customfield1',
			valueString: '',
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/customfield2',
			valueString: '',
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/custommemo1',
			valueString: '',
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/patient-mothersMaidenName',
			valueHumanName: {
				text: '',
				family: '',
				given: [''],
			},
		},
		{
			url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
			valueCode: '',
		},
		{
			extension: [],
			url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/employmentstatus',
			valueCoding: { code: '', display: '' },
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/issuer',
			valueReference: {
				id: '',
				reference: '',
				display: '',
			},
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/isExternalIssuer',
			valueBoolean: false,
		},
	],
	identifier: [
		{
			type: {
				coding: [
					{
						system: 'http://hl7.org/fhir/ValueSet/identifier-type',
						code: 'MR',
					},
				],
			},
			system: 'urn:oid:1.2.36.146.595.217.0.1',
			value: '',
		},
		{
			type: {
				coding: [
					{
						system: 'http://hl7.org/fhir/ValueSet/identifier-type',
						code: 'SS',
					},
				],
			},
			system: 'urn:oid:1.2.36.146.595.217.0.1',
			value: '',
		},
		{
			type: {
				coding: [
					{
						system: 'http://hl7.org/fhir/ValueSet/identifier-type',
						code: 'DL',
					},
				],
			},
			system: 'urn:oid:1.2.36.146.595.217.0.1',
			value: '',
		},
	],
	active: true,
	name: [{ text: '', family: '' }],
	telecom: [],
	gender: '',
	birthDate: '',
	deceasedBoolean: false,
	address: [
		{
			line: [''],
			city: '',
			state: '',
			postalCode: '',
			country: 'CANADA',
		},
	],
	maritalStatus: { coding: [] },
	contact: [
		{
			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/relationship',
					valueCoding: { code: '', display: '' },
				},
			],
			relationship: [
				{
					coding: [],
				},
			],
			name: { text: '' },
			telecom: [],
		},
	],
	communication: [
		{
			language: {
				coding: [{ code: '', display: '' }],
			},
		},
	],
	managingOrganization: {
		id: '',
		reference: '',
		display: '',
	},
};

export const DEFAULT_RELATED_PERSON = {
	resourceType: 'RelatedPerson',
	patient: {
		reference: '',
	},
	identifier: [
		{
			type: {
				coding: [
					{
						system: 'http://hl7.org/fhir/ValueSet/identifier-type',
						code: 'MR',
					},
				],
			},
			system: 'urn:oid:1.2.36.146.595.217.0.1',
			value: '',
		},
	],
	name: [{ text: '', family: '' }],
	telecom: [],
	gender: '',
	address: [
		{
			line: [''],
			city: '',
			state: '',
			postalCode: '',
			country: '',
		},
	],
	communication: [
		{
			language: {
				coding: [{ code: '', display: '' }],
			},
		},
	],
};
