import axios from 'axios';
import handleApiResponse from '@worklist-2/worklist/src/DocumentViewerV3/api/utils';

const createGlobalNote = async ({ __config, noteText, params }) => {
	let response = await axios.post(`${__config.data_sources.dicom_web}/StudyResource?${params.toString()}`, {
		note: noteText,
	});
	response = handleApiResponse(response);
	return response ? response.data : [];
};

export default createGlobalNote;
