import { gzipSync, strToU8, decompressSync, strFromU8 } from 'fflate';

// Compress string to arrayBuffer
const compressObjectToArrayBuffer = msg => {
	const str = JSON.stringify(msg);
	const uint8Array = strToU8(str);
	const compressed = gzipSync(uint8Array, { level: 4 });
	return compressed.buffer;
};

// Decompress arrayBuffer to string
const decompressArrayBufferToObject = arrayBuffer => {
	const uint8Array = new Uint8Array(arrayBuffer);
	const decompressed = decompressSync(uint8Array);
	return JSON.parse(strFromU8(decompressed));
};

export { compressObjectToArrayBuffer, decompressArrayBufferToObject };
