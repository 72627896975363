import React, { useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import documentIcon from '../../../assets/icons/PatientInfoCards/documents-icon';
import registrationIcon from '../../../assets/icons/PatientInfoCards/registration-icon';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';
import NavigatorTooltip from '@worklist-2/worklist/src/DocumentViewerV3/components/Navigator/NavigatorTooltip';
import HoldToDelete from '@rs-ui/components/HoldToDelete';
import { usePatientInfoCardStore } from '../store/patientInfoCardStore';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const DocumentTypeIcon = ({ type }) => {
	let Icon;
	let color;
	if (!type) return null;

	if (type === PATIENT_DOCUMENT_FILTERS.UPLOADED_DOCUMENT) {
		Icon = documentIcon;
		color = '#7D7AFF';
	} else if (type === PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM) {
		Icon = registrationIcon;
		color = '#EB459E';
	}
	return (
		<Box sx={STYLES.ICON_CONT(color)}>
			<Icon
				style={{
					width: '12px',
					height: 'auto', // Ensures aspect ratio is maintained
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					color: 'white',
				}}
			/>
		</Box>
	);
};

export const DocumentType = ({ type }) => {
	let typeLabel;
	if (type === PATIENT_DOCUMENT_FILTERS.UPLOADED_DOCUMENT) {
		typeLabel = 'Uploaded Document';
	} else if (type === PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM) {
		typeLabel = 'Registration Form';
	}
	return <Typography sx={STYLES.DOC_TYPE_LABEL}>{typeLabel}</Typography>;
};

const DocumentCard = ({ type, fileName, isSelected, date, onDelete, ...props }) => {
	const dataLabel = moment(date).format('YYYY-MM-DD');
	const [isHovered, setIsHovered] = useState(false);
	const { isDocumentViewer } = usePatientInfoCardStore();
	const galaxyDvPatientChartRefactor = useBooleanFlagValue('galaxy-dv-patient-chart-refactor');

	return (
		<Box sx={STYLES.CONTAINER} {...props}>
			{isSelected && <Box sx={STYLES.SELECTED_LAYER} />}
			<Box sx={STYLES.CARD} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				<DocumentTypeIcon type={type} />
				<Box sx={STYLES.DETAILS_CONT}>
					<NavigatorTooltip placement="top" title={fileName}>
						<Typography sx={STYLES.TITLE}>{fileName}</Typography>
					</NavigatorTooltip>
					{galaxyDvPatientChartRefactor && isHovered && isDocumentViewer && type !== 'REGISTRATION_FORM' && (
						<HoldToDelete
							backgroundColor="#48464A"
							backgroundStyles={{
								top: '0',
							}}
							height="30px"
							iconColor="#ffffff90"
							sx={{
								position: 'absolute',
								right: '0',
								top: '-8px',
								'&:hover': {
									backgroundColor: '#42A5F5',
								},
							}}
							width="30px"
							onDelete={onDelete}
						/>
					)}
					<Box sx={STYLES.DOC_DATA_CONT}>
						<DocumentType type={type} />
						<Typography sx={STYLES.DATE_LABEL}>{dataLabel}</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default DocumentCard;

const STYLES = {
	CONTAINER: {
		position: 'relative',
		userSelect: 'none',
		WebkitUserDrag: 'element',
	},
	SELECTED_LAYER: {
		position: 'absolute',
		top: '-1px',
		right: '-1px',
		width: 'calc(100% + 2px)',
		height: 'calc(100% + 2px)',
		background: 'linear-gradient(287.39deg, #42A5F5 0.57%, rgba(66, 165, 245, 0) 24.04%)',
		borderRadius: '0 8px 8px 0',
		boxSizing: 'border-box',
	},
	CARD: {
		display: 'flex',
		padding: '12px 8px',
		backgroundColor: '#393939',
		borderRadius: '8px',
		gap: '8px',
		cursor: 'pointer',
		position: 'relative',
		alignItems: 'center',
	},
	ICON_CONT: color => ({
		minWidth: '30px',
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		padding: '6px',
		backgroundColor: color,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
	}),
	DETAILS_CONT: {
		minHeight: '42px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flex: '1',
		maxWidth: `calc(100% - 30px - 16px)`, // reduced icon width and side padding
		position: 'relative',
	},
	TITLE: {
		fontfamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: 1.14,
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	DOC_DATA_CONT: {
		display: 'flex',
		justifyContent: 'space-between',
		color: '#FFFFFF99',
	},
	DOC_TYPE_LABEL: {
		fontfamily: 'Roboto',
		fontSize: '12px',
		fontWeight: 400,
		lineHeight: 1.28,
	},
	DATE_LABEL: {
		fontfamily: 'Roboto',
		fontSize: '12px',
		fontWeight: 400,
		lineHeight: 1.28,
	},
};
