// libraries
import axios from 'axios';

// utils
import {
	fhirExtensionUrls,
	useFhirDataLoader,
	searchScopes,
	useConfig,
	getExtensionValueString,
} from '@worklist-2/core/src';
import { COVERAGE_LEVEL_DISPLAYS } from '@worklist-2/ui/src/views/PatientInfoView/utils';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// pVerify
import {
	P_VERIFY_RESPONSE,
	getEligibilitySummary,
	isAutoCorrectedSubscriberID,
	composePVerifyPayload,
	updateCoverageModel,
	clearRejectionReason,
	getPVerifyToken,
	estimateCalculation,
} from '../utils/pVerify';

const P_VERIFY_AUTO_UPDATE_SUBSCRIBER_ID_MSG = 'Subscriber ID updated with the accurate patient insurance';

export const usePVerify = ({ showToast }) => {
	const dataLoader = useFhirDataLoader({ scope: searchScopes.coverage });
	const orgDataLoader = useFhirDataLoader({ scope: searchScopes.organization });
	const orgLicenseDataLoader = useFhirDataLoader({ scope: searchScopes.organizationLicense });

	const proactEnablePVerifyIntegration = useBooleanFlagValue('proact-enable-p-verify-integration');
	const proactEnablePverifyLicenseCheck = useBooleanFlagValue('proact-enable-pverify-license-check');

	const __config = useConfig();

	const getPayerInfo = coverage => {
		const payerSource = getExtensionValueString(coverage?.payor?.[0], fhirExtensionUrls.organization.payerSource);
		const eligibilityPayerID = getExtensionValueString(
			coverage?.payor?.[0],
			fhirExtensionUrls.organization.eligibilityPayerID
		);

		return { payerSource, eligibilityPayerID };
	};

	const getProviderInfo = async orgId => {
		const organization = await orgDataLoader.load({ id: orgId });

		const providerNPI = organization?.identifier?.[0]?.value;
		const providerLastName = organization?.contact?.[0]?.name?.family;

		return { providerNPI, providerLastName };
	};

	const checkPVerifyLicense = async orgId => {
		if (!proactEnablePVerifyIntegration) {
			return false;
		}
		const orgLicense = await orgLicenseDataLoader.load(
			{
				extraValue: {
					applicationname: 'pVerify Eligibility',
					organization: orgId,
				},
			},
			true
		);

		return Boolean(orgLicense?.entry?.length);
	};

	const getEligibility = async (coverage, patient, save = false) => {
		const orgId = patient?.managingOrganization?.id;

		if (proactEnablePverifyLicenseCheck) {
			const isOrgHasLicense = await checkPVerifyLicense(orgId);

			if (!isOrgHasLicense) {
				clearRejectionReason(coverage, fhirExtensionUrls.coverage.rejectionReason);
				return;
			}
		}

		const { payerSource, eligibilityPayerID } = getPayerInfo(coverage);

		if (proactEnablePVerifyIntegration && payerSource == 'pVerify' && eligibilityPayerID) {
			const tokenResp = await axios.get(`${__config.data_sources.fhir}/pVerify/token`);

			if (tokenResp && tokenResp.data) {
				const clientId = tokenResp.data.key;
				const token = tokenResp.data.token;

				const { providerNPI, providerLastName } = await getProviderInfo(orgId);

				const payload = composePVerifyPayload(
					coverage,
					eligibilityPayerID,
					patient,
					providerNPI,
					providerLastName
				);

				try {
					const response = await getEligibilitySummary(
						__config.data_sources.p_verify,
						clientId,
						token,
						payload
					);

					if (response.data.APIResponseCode == P_VERIFY_RESPONSE.ERROR || response.data.ProcessedWithError) {
						showToast(
							COVERAGE_LEVEL_DISPLAYS[coverage.order - 1] + ': ' + response.data.APIResponseMessage
						);
					}

					updateCoverageModel(coverage, response.data);

					if (isAutoCorrectedSubscriberID(response.data)) {
						showToast(P_VERIFY_AUTO_UPDATE_SUBSCRIBER_ID_MSG);
					}

					save && dataLoader.update(coverage?.id, coverage);

					return response.data;
				} catch (error) {
					// If not enough information is provided, pVerify will throw a 400 error, which must be handled here
					if (error?.response?.data?.APIResponseMessage) {
						showToast(
							COVERAGE_LEVEL_DISPLAYS[coverage.order - 1] + ': ' + error.response.data.APIResponseMessage
						);

						updateCoverageModel(coverage, error?.response?.data);
						save && dataLoader.update(coverage?.id, coverage);
					}
				}
			}
		} else {
			// since this is the  manual update path, we can clear the rejection reason
			clearRejectionReason(coverage, fhirExtensionUrls.coverage.rejectionReason);
		}
	};

	const getEstimateCalculation = async requestData => {
		const { clientId, token } = await getPVerifyToken(__config.data_sources.fhir);
		try {
			return await estimateCalculation(__config.data_sources.p_verify, clientId, token, requestData);
		} catch (error) {
			return false;
		}
	};

	return {
		getEligibility,
		getEstimateCalculation,
	};
};
