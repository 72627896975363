import React, { useMemo, useState, useEffect } from 'react';
import { useOverlayStoreSelector } from '../../contexts/OverlayContext';
import { Box } from '@mui/material';
import { useImageViewerLayoutContext } from '../../contexts/ImageViewerLayoutContext';
import getOverlayDisplay from './ImageViewerViewportOverlayUtils';
import { Base64 } from 'js-base64';
import { utilities, getEnabledElement } from '@cornerstonejs/core';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { windowLevelingTemplate } from './ImageViewerViewportOverlay';
import { MGViewCodes } from '@rs-ui/views/HangingProtocolView/features/ViewCodes/ViewCodes';
import seriesMatchViewCodeCondition from '../../utils/seriesMatchViewCodeCondition';
import GetPositionalIndicator from './GetPositionalIndicator';
import useGetSpineLabels from './useGetSpineLabels';
import { getImageLaterality } from '@rs-ui/views/ImageViewerView3D/components/ImageViewerViewport/utils/getImageLaterality';

export const getColor = color => `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

const sectionStyles = {
	R: {
		top: {
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.25px',
			opacity: '0.87',
			position: 'absolute',
			top: '3%',
			left: '2%',
			whiteSpace: 'pre-line',
			'& p': {
				margin: 0,
			},
		},
		bottom: {
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.25px',
			opacity: '0.87',
			position: 'absolute',
			bottom: '3%',
			left: '2%',

			whiteSpace: 'pre-line',
			'& p': {
				margin: 0,
			},
		},

		center: {
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.25px',
			opacity: '0.87',
			position: 'absolute',
			top: '50%',
			left: '2%',
			transform: 'translate(0, -50%)',
			textAlign: 'left',

			whiteSpace: 'pre-line',
			'& p': {
				margin: 0,
			},
		},
	},
	L: {
		top: {
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.25px',
			opacity: '0.87',
			position: 'absolute',
			top: '3%',
			right: 'calc(2% + 34px)',
			textAlign: 'right',

			whiteSpace: 'pre-line',
			'& p': {
				margin: 0,
			},
		},

		bottom: {
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.25px',
			opacity: '0.87',
			position: 'absolute',
			bottom: '3%',
			right: 'calc(2% + 34px)',
			textAlign: 'right',

			whiteSpace: 'pre-line',
			'& p': {
				margin: 0,
			},
		},
		center: {
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.25px',
			opacity: '0.87',
			position: 'absolute',
			right: 'calc(2% + 34px)',
			top: '50%',
			transform: 'translate(0, -50%)',
			textAlign: 'right',

			whiteSpace: 'pre-line',
			'& p': {
				margin: 0,
			},
		},
	},
	topCenter: {
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '20px',
		letterSpacing: '0.25px',
		opacity: '0.87',
		position: 'absolute',
		top: '3%',
		left: '50%',
		transform: 'translate(-50%, 0)',
		textAlign: 'center',

		whiteSpace: 'pre-line',
		'& p': {
			margin: 0,
		},
	},
	bottomCenter: {
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '20px',
		letterSpacing: '0.25px',
		opacity: '0.87',
		position: 'absolute',
		bottom: '3%',
		left: '50%',
		transform: 'translate(-50%, 0)',
		textAlign: 'center',

		whiteSpace: 'pre-line',
		'& p': {
			margin: 0,
		},
	},
	positionalIndicator: {
		fontFamily: 'Helvetica, Arial, sans-serif',
		fontWeight: 'bold',
		fontSize: '14px',
		color: 'white',
		opacity: '1',
	},
};

const reversViewTagList = ['StudyDate', '00080020', '0008002a', 'Acquisition'];

const ImageViewerViewportCustomOverlay = ({
	isCurrentStudy,
	currentStudy,
	viewportId,
	activeInstance,
	initialInstanceIndex,
	instances,
	index,
}) => {
	const profile = useOverlayStoreSelector(state => state.profile);
	const customOverlays = useOverlayStoreSelector(state => state.customOverlays);
	const { layoutItems } = useImageViewerLayoutContext();

	const [activeScroll, setActiveScroll] = useState(initialInstanceIndex + index);
	const [positionalIndicator, setPositionalIndicator] = useState();

	const { spineLabels } = useGetSpineLabels({ viewportId });

	let positionalIndicatorStatus = null;
	const isMG = activeInstance?.['00080060']?.Value?.[0]?.includes('MG')
		? activeInstance['00080060'].Value[0].includes('MG')
		: instances?.[0]?.['00080060']?.Value?.[0]?.includes('MG') ?? false;

	const codeView = layoutItems?.find(layoutItem => {
		const item = layoutItem.viewports?.find(i => i.id == viewportId);
		if (item) return layoutItem;
	});

	const ON_CORNERSTONE_VOI_MODIFIED = e => {
		const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(
			e.detail.range.lower,
			e.detail.range.upper
		);
		document.querySelectorAll(`.annotation-window-level-${viewportId}`).forEach(item => {
			item.innerHTML = windowLevelingTemplate(windowCenter, windowWidth);
		});
	};

	const ON_CORNERSTONE_IMAGE_RENDERED = e => {
		const enabledElement = getEnabledElement(e.detail.element);
		const curPositionalIndicator = GetPositionalIndicator(enabledElement);
		if (
			curPositionalIndicator &&
			JSON.stringify(curPositionalIndicator) !== JSON.stringify(positionalIndicatorStatus)
		) {
			positionalIndicatorStatus = curPositionalIndicator;
			setPositionalIndicator(curPositionalIndicator);
		}
	};

	useEffect(() => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const renderingEngineViewport = renderingEngine.getViewport(viewportId);

		if (renderingEngineViewport) {
			const renderingEngineViewportProperties = renderingEngineViewport?.getProperties?.();

			if (renderingEngineViewportProperties) {
				if (!renderingEngineViewportProperties.voiRange) {
					return;
				}
			}
		}

		const element = document.getElementById(viewportId);

		element?.addEventListener('CORNERSTONE_VOI_MODIFIED', ON_CORNERSTONE_VOI_MODIFIED);
		element?.addEventListener('CORNERSTONE_IMAGE_RENDERED', ON_CORNERSTONE_IMAGE_RENDERED);

		return () => {
			element?.removeEventListener('CORNERSTONE_VOI_MODIFIED', ON_CORNERSTONE_VOI_MODIFIED);
			element?.removeEventListener('CORNERSTONE_IMAGE_RENDERED', ON_CORNERSTONE_IMAGE_RENDERED);
		};
	}, []);

	const ON_CORNERSTONE_STACK_VIEWPORT_SCROLL = e => {
		setActiveScroll(e.detail.newImageIdIndex + index);
	};

	const ON_CORNERSTONE_STACK_NEW_IMAGE = e => {
		setActiveScroll(e.detail.imageIdIndex + index);
	};

	useEffect(() => {
		setActiveScroll(initialInstanceIndex + index);

		const element = document.getElementById(viewportId);

		element?.addEventListener('CORNERSTONE_STACK_VIEWPORT_SCROLL', ON_CORNERSTONE_STACK_VIEWPORT_SCROLL);

		element?.addEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);

		return () => {
			element?.removeEventListener('CORNERSTONE_STACK_VIEWPORT_SCROLL', ON_CORNERSTONE_STACK_VIEWPORT_SCROLL);

			element?.removeEventListener('CORNERSTONE_STACK_NEW_IMAGE', ON_CORNERSTONE_STACK_NEW_IMAGE);
		};
	}, []);

	const overlayDisplay = useMemo(() => {
		const instance = activeInstance ?? instances?.[0];
		let value = { ...currentStudy, ...instance };
		if (Array.isArray(layoutItems)) {
			const layoutItem = layoutItems?.find(item => {
				if (Array.isArray(item?.viewports)) {
					return item.viewports.find(i => i?.id === viewportId);
				}
			});
			if (layoutItem) {
				value = {
					...value,
					...layoutItem?.series?.metadata?.[activeScroll],
					...layoutItem,
					activeScroll: layoutItem?.series?.imageIds ? activeScroll : null,
				};
			}

			if (isMG && !layoutItem?.series?.metadata?.[activeScroll] && layoutItem?.series?.metadata?.[0]) {
				value = {
					...value,
					...layoutItem?.series?.metadata?.[0],
				};
			}
		}

		return getOverlayDisplay(value, isCurrentStudy);
	}, [viewportId, customOverlays, profile, activeScroll, currentStudy, layoutItems, instances, activeInstance]);

	const getAnnotation = content => {
		const parser = new DOMParser();
		const htmlDoc = parser.parseFromString(content, 'text/html');
		htmlDoc.querySelectorAll('span').forEach(span => {
			let item = overlayDisplay.find(i => i.name === span.getAttribute('data-display'))?.value;

			if (['WindowCenterWidth', '00281050'].includes(span.getAttribute('data-code'))) {
				span.classList.add(`annotation-window-level-${viewportId}`);
			}

			if (!isCurrentStudy && reversViewTagList.includes(span.getAttribute('data-code'))) {
				span.style = 'filter: invert(1); background: #7B591E; display: inline-block; padding: 0 2px';
			}

			if (isMG && ['ViewcodeTag', '00200037', '0020,0037'].includes(span.getAttribute('data-code'))) {
				span.style = 'font-size: 28px; font-weight: bold;';

				const mammographyViewCode = MGViewCodes.filter(MGViewCode => !MGViewCode.includes('Any')).find(
					MGViewCode =>
						seriesMatchViewCodeCondition({
							viewCode: MGViewCode,
							series: codeView?.series,
						})
				);
				item = mammographyViewCode ? mammographyViewCode?.split(': ')?.[1] : item;
			}

			if (item && typeof item !== 'undefined' && !item.includes(' NaN') && !item.includes('undefined')) {
				span.innerHTML = item;
			} else {
				span.remove();
			}
		});

		htmlDoc.querySelectorAll('p').forEach(p => {
			if (!p.innerHTML) p.remove();
		});

		return htmlDoc.body.innerHTML;
	};

	const getAnnotations = section => (
		<div dangerouslySetInnerHTML={{ __html: getAnnotation(Base64.decode(section)) }} />
	);

	if (!customOverlays || !customOverlays[profile] || !overlayDisplay || !overlayDisplay.length) {
		return <></>;
	}
	const sectionItem = customOverlays[profile];
	const laterality = getImageLaterality(codeView?.series?.metadata?.[0], codeView?.series);
	const color = getColor(sectionItem.color);
	const fontFamily = sectionItem.font;

	if (isMG && laterality && sectionStyles?.[laterality]) {
		const alignment = laterality == 'L' ? 'L' : 'R';

		return (
			<>
				<Box
					key="top-mg"
					data-testid="top-mg"
					id="top-mg"
					sx={{
						...sectionStyles[alignment]?.top,
						color,
						fontFamily,
					}}
				>
					{sectionItem?.topLeft && getAnnotations(sectionItem?.topLeft)}
					{sectionItem?.topRight && getAnnotations(sectionItem?.topRight)}
				</Box>
				<Box
					key="bottom-mg"
					data-testid="bottom-mg"
					sx={{
						...sectionStyles[alignment]?.bottom,
						color,
						fontFamily,
					}}
				>
					{sectionItem?.downLeft && getAnnotations(sectionItem?.downLeft)}
					{sectionItem?.downRight && getAnnotations(sectionItem?.downRight)}
				</Box>
				<Box
					key="center-mg"
					data-testid="center-mg"
					sx={{
						...sectionStyles[alignment]?.center,
						color,
						fontFamily,
						position: 'relative',
					}}
				>
					{alignment === 'R' && positionalIndicator?.left && (
						<div
							style={{
								...sectionStyles.positionalIndicator,
								position: 'absolute',
								left: 'calc(-2% + 2px)',
								top: '-20px',
							}}
						>
							{positionalIndicator.left}
						</div>
					)}

					{alignment === 'L' && positionalIndicator?.right && (
						<div
							style={{
								...sectionStyles.positionalIndicator,
								position: 'absolute',
								right: '-15px',
								top: '-20px',
							}}
						>
							{positionalIndicator.right}
						</div>
					)}

					{sectionItem?.leftMiddle && getAnnotations(sectionItem?.leftMiddle)}
					{sectionItem?.rightMiddle && getAnnotations(sectionItem?.rightMiddle)}
				</Box>
				<Box
					sx={{
						...sectionStyles.topCenter,
						top: '1%',
					}}
				>
					{positionalIndicator?.top && (
						<div
							style={{
								...sectionStyles.positionalIndicator,
							}}
						>
							{positionalIndicator.top}
						</div>
					)}
				</Box>
				<Box
					sx={{
						...sectionStyles.bottomCenter,
					}}
				>
					{positionalIndicator?.bottom && (
						<div
							style={{
								...sectionStyles.positionalIndicator,
							}}
						>
							{positionalIndicator.bottom}
						</div>
					)}
				</Box>
			</>
		);
	}

	return (
		<>
			<Box
				key="top-left-display"
				data-testid="top-left-display"
				id="top-left-display"
				sx={{
					...sectionStyles.R.top,
					color,
					fontFamily,
				}}
			>
				{sectionItem?.topLeft && getAnnotations(sectionItem?.topLeft)}
			</Box>
			<Box
				sx={{
					...sectionStyles.topCenter,
					top: '1%',
				}}
			>
				<div
					style={{
						...sectionStyles.positionalIndicator,
					}}
				>
					{spineLabels && <div>{spineLabels}</div>}
					{positionalIndicator?.top && <div>{positionalIndicator.top}</div>}
				</div>
			</Box>
			<Box
				key="top-center-display"
				data-testid="top-center-display"
				id="top-center-display"
				sx={{
					...sectionStyles.topCenter,
					color,
					fontFamily,
				}}
			>
				{sectionItem?.topMiddle && getAnnotations(sectionItem?.topMiddle)}
			</Box>
			<Box
				key="top-right-display"
				data-testid="top-right-display"
				id="top-right-display"
				sx={{
					...sectionStyles.L.top,
					color,
					fontFamily,
				}}
			>
				{sectionItem?.topRight && getAnnotations(sectionItem?.topRight)}
			</Box>
			<Box
				key="bottom-left-display"
				data-testid="bottom-left-display"
				id="bottom-left-display"
				sx={{
					...sectionStyles.R.bottom,
					color,
					fontFamily,
				}}
			>
				{sectionItem?.downLeft && getAnnotations(sectionItem?.downLeft)}
			</Box>
			<Box
				key="bottom-center-display"
				data-testid="bottom-center-display"
				id="bottom-center-display"
				sx={{
					...sectionStyles.bottomCenter,
					color,
					fontFamily,
				}}
			>
				{sectionItem?.downMiddle && getAnnotations(sectionItem?.downMiddle)}
				{positionalIndicator?.bottom && (
					<div
						style={{
							...sectionStyles.positionalIndicator,
						}}
					>
						{positionalIndicator.bottom}
					</div>
				)}
			</Box>
			<Box
				key="bottom-right-display"
				data-testid="bottom-right-display"
				id="bottom-right-display"
				sx={{
					...sectionStyles.L.bottom,
					color,
					fontFamily,
				}}
			>
				{sectionItem?.downRight && getAnnotations(sectionItem?.downRight)}
			</Box>
			<Box
				key="center-left-display"
				data-testid="center-left-display"
				id="center-left-display"
				sx={{
					...sectionStyles.R.center,
					color,
					fontFamily,
					position: 'relative',
				}}
			>
				{positionalIndicator?.left && (
					<div
						style={{
							...sectionStyles.positionalIndicator,
							position: 'absolute',
							left: 'calc(-2% + 2px)',
							top: '-20px',
						}}
					>
						{positionalIndicator.left}
					</div>
				)}

				{sectionItem?.leftMiddle && getAnnotations(sectionItem?.leftMiddle)}
			</Box>
			<Box
				key="center-right-display"
				data-testid="center-right-display"
				id="center-right-display"
				sx={{
					...sectionStyles.L.center,
					color,
					fontFamily,
				}}
			>
				{positionalIndicator?.right && (
					<div
						style={{
							...sectionStyles.positionalIndicator,
							position: 'absolute',
							right: '-15px',
							top: '-20px',
						}}
					>
						{positionalIndicator.right}
					</div>
				)}

				{sectionItem?.rightMiddle && getAnnotations(sectionItem?.rightMiddle)}
			</Box>
		</>
	);
};

export default ImageViewerViewportCustomOverlay;
