import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import { useHelpCenter } from '@worklist-2/core/src';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const HelpPopOverMenu = ({ onChangeContent }) => {
	const { setCurrentPiPNav, setCurrentPiPTabs, omegaAIHelpLinks } = useHelpCenter();
	const [activeIndex, setActiveIndex] = useState(-1);

	const handleChange = v => {
		let path = v?.path;
		if (path.split('/').length == 2) {
			path = `${path}/index`;
		}
		setCurrentPiPNav(path);
		onChangeContent(path);
		setCurrentPiPTabs(v.tabs || []);
	};

	return (
		<Paper data-testid="HelpPopOverMenu" style={{ backgroundColor: '#272727', boxShadow: 'none' }}>
			<List>
				{omegaAIHelpLinks?.map((link, i) => (
					<NavLinkItem
						key={i}
						clicked={activeIndex === i}
						i={i}
						link={link}
						setActiveIndex={setActiveIndex}
						onChangeContent={handleChange}
					/>
				))}
			</List>
		</Paper>
	);
};

const NavLinkItem = ({ link, onChangeContent, clicked, setActiveIndex, i }) => {
	const { t } = useTranslation('helpCenter');
	const [expanded, setExpanded] = useState(false);
	const hasChildren = !_.isEmpty(link.subNavLink);
	const { currentPiPNav, getOmegaAIHelpIcon } = useHelpCenter();

	const icon = useMemo(
		() => (!hasChildren ? null : <>{expanded ? <ExpandLess /> : <ExpandMore />}</>),
		[hasChildren, expanded]
	);

	const active = useMemo(
		() => `${currentPiPNav}`.split('/').includes(link.path) || currentPiPNav === link.path,
		[currentPiPNav, link]
	);
	const parentColor = useMemo(() => (active || clicked ? '#42a5f5' : 'inherit'), [active, clicked]);

	const handleChange = () => {
		if (hasChildren) {
			setExpanded(!expanded);
			setActiveIndex(i);
		} else {
			setActiveIndex(-1);
			onChangeContent({ ...link, path: link.path });
		}
	};

	return (
		<li>
			<NavListItem
				active={expanded}
				style={expanded ? { borderTopLeftRadius: 5, borderTopRightRadius: 5 } : { borderRadius: 0 }}
				onClick={() => handleChange()}
			>
				<div
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'row',
						color: parentColor,
					}}
				>
					<ListItemIcon
						sx={{
							padding: 0,
							'& .custom-nav-icon': {
								padding: '0px !important',
							},
						}}
					>
						{(link.icon || link.iconSvg) &&
							getOmegaAIHelpIcon(
								link.icon,
								link.iconSvg
							)({
								className: active || clicked ? 'active' : 'inactive',
								active: active || clicked,
							})}
					</ListItemIcon>
					<span
						style={{
							color: parentColor,
						}}
					>
						{t(link.label)}
					</span>
				</div>
				{icon}
			</NavListItem>
			<Collapse
				unmountOnExit
				in={expanded}
				sx={{
					marginTop: '-10px',
					backgroundColor: expanded ? 'rgba(80, 80, 80, 0.29)' : 'transparent',
					borderBottomLeftRadius: expanded ? 5 : 0,
					borderBottomRightRadius: expanded ? 5 : 0,
				}}
				timeout="auto"
			>
				{!!link.subNavLink &&
					link.subNavLink.map((_link, i) => (
						<NavListItem
							key={i}
							active={expanded}
							style={{
								margin: 0,
								padding: '15px 40px',
								borderBottomLeftRadius: i == _.size(link.subNavLink) - 1 ? 5 : 0,
								borderBottomRightRadius: i == _.size(link.subNavLink) - 1 ? 5 : 0,
							}}
							onClick={() =>
								onChangeContent({
									path: _link.path,
									tabs: _link.tabs,
								})
							}
						>
							<div
								style={{
									flex: 1,
									display: 'flex',
									flexDirection: 'row',
									color: currentPiPNav == link.path ? '#42a5f5' : 'inherit',
								}}
							>
								<span
									style={
										currentPiPNav == _link.path
											? {
													color: '#42a5f5',
													marginLeft: '55px',
											  }
											: { marginLeft: '55px' }
									}
								>
									{t(_link.label)}
								</span>
							</div>
						</NavListItem>
					))}
			</Collapse>
		</li>
	);
};

const NavListItem = ({ children, onClick, active, style }) => (
	<ListItem
		button
		data-cy="list-item-link"
		data-testid="HelpPopOverMenuNavListItem"
		style={style}
		sx={{
			padding: '10px 40px',
			margin: '10px 0px 10px 0px',
			opacity: '0.8',
			display: 'flex',
			flexDirection: 'row',
			fontFamily: 'Roboto',
			background: active && '#393939',
		}}
		onClick={onClick}
	>
		{children}
	</ListItem>
);

export default HelpPopOverMenu;
