import React, { createContext, useContext, useEffect, useState } from 'react';
import { fhirExtensionUrls, useConfig, useAuth } from '@worklist-2/core/src';
import getDiagnosticReports from '@worklist-2/worklist/src/DocumentViewerV3/api/getDiagnosticReports';
import { DIAGNOSTIC_REPORT_STATUS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const PendingSignaturesContext = createContext({});

export const calculateTimeRemaining = document => {
	const scheduledSignDateString = document?.extension?.find(
		item => item?.url === fhirExtensionUrls.diagnosisReport.delayedSignTimestampUTC
	)?.valueDateTime;
	const cheduledSignDate = new Date(scheduledSignDateString);
	const clickedSignTime = cheduledSignDate.getTime();
	const remainingTime = clickedSignTime - Date.now();

	return remainingTime;
};

export const PendingSignaturesProvider = ({ children, pendingSignaturesIsOpen, setPendingSignaturesIsOpen }) => {
	const [pendingSignatures, setPendingSignatures] = useState([]);
	const { authorized, accessToken, loggedIn, loggedInUser } = useAuth();
	const __config = useConfig();
	const galaxyDvUndoSignOff = useBooleanFlagValue('galaxy-dv-undo-sign-off');
	const fetchSigningReports = async () => {
		const response = await getDiagnosticReports({
			__config,
			status: DIAGNOSTIC_REPORT_STATUS.UNKNOWN,
			performer: loggedInUser?.id,
		});
		setPendingSignatures([...response]);
		return response;
	};

	const fetchPendingSignatures = async () => {
		const response = await fetchSigningReports();
		for (const pendingSignature of response) {
			const remainingTime = calculateTimeRemaining(pendingSignature);
			setTimeout(() => {
				setPendingSignatures(prev => [...prev.filter(s => s.id !== pendingSignature.id)]);
			}, remainingTime);
		}
	};

	useEffect(() => {
		if (loggedIn && accessToken && loggedInUser && galaxyDvUndoSignOff) {
			fetchPendingSignatures();
		}
	}, [authorized, accessToken, loggedInUser]);

	return (
		<PendingSignaturesContext.Provider
			value={{
				pendingSignatures,
				setPendingSignatures,
				pendingSignaturesIsOpen,
				fetchPendingSignatures,
				setPendingSignaturesIsOpen,
			}}
		>
			{children}
		</PendingSignaturesContext.Provider>
	);
};

export const usePendingSignatures = () => useContext(PendingSignaturesContext);

export default PendingSignaturesProvider;
