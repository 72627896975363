import React, { useState, useEffect, memo } from 'react';
import { Box } from '@mui/material';
import { SectionRenderer } from './components/sections/SectionRenderer';
import { PositionIndicator } from './components/tags/PositionIndicator';
import { getImageLaterality } from './utils/getImageLaterality';
import { lateralityMapper } from './consts/consts';
import { EVENTS, getEnabledElement } from '@cornerstonejs/core';
import { getPositionalIndicatorV2 } from './GetPositionalIndicator';
import { omit } from 'lodash';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';

export const ImageViewerViewportCustomOverlayV2 = memo(({ isCurrentStudy, viewport, config, series }) => {
	const { element, id } = viewport;
	const [positionalIndicator, setPositionalIndicator] = useState(null);
	const [alignment, setAlignment] = useState(undefined);
	const [metadata, setMetadata] = useState(null);
	const { styles, sectionsConfig } = config;

	const init = currentViewport => {
		try {
			const imageId = currentViewport.getCurrentImageId();
			if (imageId) {
				const currentMetadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);
				setMetadata(currentMetadata);
				let curPositionalIndicator = getPositionalIndicatorV2(currentViewport, currentMetadata);

				if (currentViewport.options?.mammographyViewCode) {
					const laterality = getImageLaterality(currentMetadata, series);
					const specificAlignment = lateralityMapper[laterality];
					setAlignment(specificAlignment);
					curPositionalIndicator = omit(curPositionalIndicator, specificAlignment);
				}

				setPositionalIndicator(curPositionalIndicator);
			}
			element.removeEventListener(EVENTS.IMAGE_RENDERED, ON_CORNERSTONE_IMAGE_RENDERED);
		} catch (error) {
			console.log('error on init overlays: ', error);
		}
	};

	const ON_STACK_NEW_IMAGE = () => {
		element.addEventListener(EVENTS.IMAGE_RENDERED, ON_CORNERSTONE_IMAGE_RENDERED);
	};

	const ON_CORNERSTONE_IMAGE_RENDERED = e => {
		const enabledViewport = getEnabledElement(e.detail.element);
		if (enabledViewport?.viewport) {
			init(enabledViewport.viewport);
		}
	};

	useEffect(() => {
		if (element) {
			const enabledViewport = getEnabledElement(element);
			if (enabledViewport?.viewport) {
				init(enabledViewport.viewport);
				element.addEventListener(EVENTS.IMAGE_RENDERED, ON_CORNERSTONE_IMAGE_RENDERED);
				element.addEventListener(EVENTS.STACK_NEW_IMAGE, ON_STACK_NEW_IMAGE);
			}
		}

		return () => {
			element?.removeEventListener(EVENTS.IMAGE_RENDERED, ON_CORNERSTONE_IMAGE_RENDERED);
			element?.removeEventListener(EVENTS.STACK_NEW_IMAGE, ON_STACK_NEW_IMAGE);
		};
	}, [element]);

	return (
		<Box
			sx={{
				...styles,
				display: 'grid',
				gridTemplateAreas: `". position-indicator-top position-indicator-top position-indicator-top ."
										". top top top ." 
										"position-indicator-left position-indicator-left . position-indicator-right position-indicator-right" 
										". center center center ." 
										". bottom bottom bottom ." 
										". position-indicator-bottom position-indicator-bottom position-indicator-bottom ."
										`,
				gridAutoFlow: 'column',
				gridTemplateRows: '15px 1fr auto auto 1fr 15px',
				height: '100%',
				gridTemplateColumns: '15px 1fr auto 1fr 15px',
			}}
		>
			<PositionIndicator positionalIndicator={positionalIndicator} />
			{Object.entries(sectionsConfig).map(([verticalPosition, column]) => (
				<SectionRenderer
					key={`section-overlay-${verticalPosition}`}
					column={column}
					isCurrentStudy={isCurrentStudy}
					metadata={metadata}
					sectionAlignment={alignment}
					verticalPosition={verticalPosition}
					viewport={viewport}
					viewportId={id}
				/>
			))}
		</Box>
	);
});
