import { useQueryClient } from '@tanstack/react-query';
import ImagingStudyWorklistMapping from '../fhir/resource/columnMapping/ImagingStudyWorklistMapping';

/**
 * This function is designed to work with only DataGrid that uses infiniteQuery and react-table.
 * @param {Object} dataGridName - This is the identifier of the grid e.g.: 'worklist'.
 * @param {Array<string>[1]} cacheKey - (optional) That is the cache key to invalidate the query e.g.: when new filters are applied
 * @param {number} [customPageSize=50] - (optional) The page size for the grid. Default: 50
 * @param {Array<any>} columns - (optional) This is the columns array that contains the information of the columns of the grid
 *
 *
 */
const useDataGridQueryData = (dataGridName = 'worklist', cacheKey = [], customPageSize, columns = []) => {
	const queryClient = useQueryClient();
	const queryKey = ['table-data', dataGridName, ...[cacheKey]];
	const pageSize = customPageSize || 50; // default value for DataGrid component

	/**
	 * This function is private to useDataGridQueryData hook, do not use this function outside here, wont work!
	 * @param {Array<any>} data - This is the updated array of rows with the wanted update
	 * @returns {void}
	 */
	const setGridData = data => {
		const previousData = queryClient.getQueryData(queryKey);
		if (previousData && data) {
			queryClient.setQueryData(queryKey, previousData => {
				const pageCount = Math.ceil(data.length / pageSize); // get the actual page number
				let updatedDataWithPages = Array.from({ length: pageCount }, (_, index) =>
					data.slice(index * pageSize, (index + 1) * pageSize)
				); // generate another array separated by pages required by useInfiniteQuery
				return {
					...previousData,
					pages: updatedDataWithPages,
				};
			});
		}
	};

	/**
	 * This function is private to useDataGridQueryData hook, do not use this function outside here, wont work!
	 * @returns {Array<any>}
	 */
	const sortDataGridFields = (listToSort, sortingArray) => {
		if (dataGridName !== 'worklist' || !sortingArray || sortingArray.length === 0) {
			return listToSort;
		}
		try {
			const complexSortingFunction = (a, b) => {
				for (const field of sortingArray) {
					const parseDate = date => new Date(date);
					const sortOrder = field[0] === '-' ? -1 : 1; // 1 for ascending, -1 for descending
					const fieldName = field.slice(1); // Remove the plus/minus sign

					const dataType = ImagingStudyWorklistMapping[fieldName]?.variableTypeToParseWhenSorting;
					let aValue = a[fieldName];
					let bValue = b[fieldName];

					// Convert data types if necessary
					if (dataType === 'date') {
						if (aValue === undefined && bValue === undefined) return 0;
						if (bValue === undefined && aValue !== undefined) return 1 * sortOrder;
						if (aValue === undefined && bValue !== undefined) return -1 * sortOrder;
						aValue = parseDate(aValue);
						bValue = parseDate(bValue);
					}

					if (aValue < bValue) return -1 * sortOrder;
					if (aValue > bValue) return 1 * sortOrder;
				}
				return 0; // If all fields are equal, return 0
			};
			if (listToSort) {
				const sortedList = listToSort.sort(complexSortingFunction);
				return sortedList;
			} else {
				return [];
			}
		} catch (err) {
			console.log('[sortDataGridWith] Problem when sorting data.', err);
		}
	};

	/**
	 * This function is designed to work with only DataGrid that uses infiniteQuery and react-table.
	 * @param {Object} rowData - This is the updated row data that comes from API after a successful update request.
	 * @returns {void}
	 */
	const updateSingleRow = rowData => {
		const previousData = queryClient.getQueryData(queryKey);
		if (previousData) {
			const allRows = previousData.pages.flat(); // flatten the pages arrays into one single array to enable search
			const rowIndex = allRows.findIndex(item => item.id === rowData.id);
			const rowNotFound = rowIndex === -1;

			if (rowNotFound) {
				return;
			}

			const isLastRow = allRows.length - 1 === rowIndex;
			const firstPart = allRows.slice(0, rowIndex);
			const rowToUpdate = { ...allRows[rowIndex], ...rowData };

			const dataWithRowUpdated = isLastRow
				? [...firstPart, rowToUpdate]
				: [...firstPart, rowToUpdate, ...allRows.slice(rowIndex + 1)]; // create another array with the inserted row
			const oldRow = allRows[rowIndex];

			const shouldUpdate =
				columns.length === 0 ||
				columns.some(column => {
					return column.accessorFn ? column.accessorFn(oldRow) !== column.accessorFn(rowData) : false;
				});
			if (shouldUpdate) {
				setGridData(dataWithRowUpdated);
			}
		}
	};

	/**
	 * This function is a variation of updateDataGridRow but for multiple rows. This is designed to work with only DataGrid that uses infiniteQuery and react-table.
	 * @param {Array<Object>} rowsArray - This is an list of updated data rows that comes from API.
	 * @returns {void}
	 */
	const updateMultipleRows = (rowsArray, sort = []) => {
		const previousData = queryClient.getQueryData(queryKey);
		let allRows = previousData?.pages?.flat();
		const rowsToInsert = [];

		if (allRows?.length && rowsArray?.length) {
			rowsArray.forEach(row => {
				const rowIndex = allRows.findIndex(item => item.id === row.id);
				const rowNotFound = rowIndex === -1;

				if (rowNotFound) {
					// if the row was not found into the grid, we need to insert it instead of updating it.
					rowsToInsert.push(row);
				} else {
					const isLastRow = allRows.length - 1 === rowIndex;
					const firstPart = allRows.slice(0, rowIndex);
					const rowToUpdate = { ...allRows[rowIndex], ...row };

					const dataWithRowUpdated = isLastRow
						? [...firstPart, rowToUpdate]
						: [...firstPart, rowToUpdate, ...allRows.slice(rowIndex + 1)]; // create another array with the inserted row
					const oldRow = allRows[rowIndex];

					const shouldUpdate =
						columns.length === 0 ||
						columns.some(column => {
							return column.accessorFn ? column?.accessorFn(oldRow) !== column?.accessorFn(row) : false;
						});
					if (shouldUpdate) {
						allRows = dataWithRowUpdated;
					}
				}
			});

			const sortedList = sortDataGridFields(allRows, sort);
			setGridData(sortedList);

			if (rowsToInsert.length > 0) {
				insertMultipleRows(rowsToInsert, sort);
			}
		}
	};

	/**
	 * This function is designed to work with only DataGrid that uses infiniteQuery and react-table.
	 * @param {Object} rowToRemove - This is the row that you want to remove on the Grid.
	 * @returns {void}
	 */
	const removeSingleRow = rowToRemove => {
		const previousData = queryClient.getQueryData(queryKey);
		if (previousData) {
			const allRows = previousData.pages.flat(); // flatten the pages arrays into one single array to enable search
			const rowIndex = allRows.findIndex(item => item.id === rowToRemove.id);
			const rowNotFound = rowIndex === -1;

			if (rowNotFound) {
				return;
			}

			const isLastRow = allRows.length - 1 === rowIndex;
			const firstPart = allRows.slice(0, rowIndex);
			const dataWithoutRow = isLastRow ? firstPart : [...firstPart, ...allRows.slice(rowIndex + 1)]; // another array without the row (no need to iterate all the array e.g.: filter)
			setGridData(dataWithoutRow);
		}
	};

	/**
	 * This function is a variation of removeDataGridRow to handle with multiple rows at once. It only works for a DataGrid that uses infiniteQuery and react-table.
	 * @param {Array<Object>} rowsArray - This is a list of rows that you want to remove on the Grid.
	 * @returns {void}
	 */
	const removeMultipleRows = (rowsArray, sort = []) => {
		const previousData = queryClient.getQueryData(queryKey);
		let allRows = previousData?.pages?.flat(); // flatten the pages arrays into one single array to enable search
		if (allRows?.length && rowsArray?.length) {
			rowsArray.forEach(row => {
				const rowIndex = allRows.findIndex(item => item.id === row.id);
				const rowNotFound = rowIndex === -1;

				if (rowNotFound) {
					return;
				}

				const isLastRow = allRows.length - 1 === rowIndex;
				const firstPart = allRows.slice(0, rowIndex);
				const dataWithoutRow = isLastRow ? firstPart : [...firstPart, ...allRows.slice(rowIndex + 1)]; // another aray without the row (no need to iterate all the array e.g.: filter)
				allRows = dataWithoutRow;
			});
			const sortedList = sortDataGridFields(allRows, sort);
			setGridData(sortedList);
		}
	};

	/**
	 * This function is designed to work with only DataGrid that uses infiniteQuery and react-table.
	 * @param {Object} rowToAdd - This is the row that you want to add into the Grid.
	 * @returns {void}
	 */
	const insertRow = (rowToAdd, sort = [], eliminateExceedingRow = true) => {
		const previousData = queryClient.getQueryData(queryKey);
		if (previousData) {
			const allRows = previousData.pages.flat(); // flatten the pages arrays into one single array to enable search
			const rowIndex = allRows.findIndex(item => item.id === rowToAdd.id);
			const rowAlreadyExists = rowIndex !== -1;

			if (rowAlreadyExists) {
				updateSingleRow(rowToAdd);
				return;
			}

			const dataWithoutRow = [rowToAdd, ...allRows]; // another array with the new row at the top (no need to iterate all the array e.g.: filter)
			const sortedList = sortDataGridFields(dataWithoutRow, sort);
			const lastRow = sortedList[sortedList.length - 1];

			if (eliminateExceedingRow && rowToAdd !== lastRow) {
				setGridData(sortedList.slice(0, allRows.length)); // eliminate last exceeding row based on sorting criteria
			} else {
				setGridData(sortedList);
			}
		}
	};

	/**
	 * This function is a variation of insertRow to handle with multiple rows at once. Only works with DataGrid that uses infiniteQuery and react-table.
	 * @param {Object} rowsArray - This is the list of rows that you want to add into the Grid.
	 * @returns {void}
	 */
	const insertMultipleRows = (rowsArray, sort = [], eliminateExceedingRows = true) => {
		const previousData = queryClient.getQueryData(queryKey);
		const allRows = previousData?.pages?.flat(); // flatten the pages arrays into one single array to enable search
		if (allRows?.length && rowsArray?.length) {
			const existingRows = rowsArray.filter(row => allRows.find(item => item.id === row.id));
			const newRows = rowsArray.filter(row => !existingRows.some(item => item.id === row.id)); // every row that is not a existing row must be a new row.

			if (newRows.length === 0) {
				return;
			}

			if (existingRows.length > 0) {
				updateMultipleRows(existingRows, sort);
			}

			const allDataWithNewRows = [...newRows, ...allRows]; // another array with the new rows at the top
			const sortedList = sortDataGridFields(allDataWithNewRows, sort); // the sorting function is not 100% reliable.

			if (eliminateExceedingRows) {
				setGridData(sortedList.slice(0, allRows.length)); // eliminate additional rows count added lastly, after rows got sorted based on sorting criteria
			} else {
				setGridData(sortedList);
			}
		}
	};

	const invalidateQueries = () => {
		queryClient.invalidateQueries({ queryKey: queryKey });
	};

	const getRowIndex = rowData => {
		const previousData = queryClient.getQueryData(queryKey);
		if (previousData) {
			const allRows = previousData.pages.flat(); // flatten the pages arrays into one single array to enable search
			return allRows.findIndex(item => item.id === rowData.id);
		}
	};

	const getGridData = () => {
		const currentData = queryClient.getQueryData(queryKey);
		if (currentData) {
			return currentData.pages.flat();
		}
	};

	return {
		updateSingleRow,
		updateMultipleRows,
		removeSingleRow,
		removeMultipleRows,
		insertRow,
		insertMultipleRows,
		invalidateQueries,
		getRowIndex,
		getGridData,
	};
};

export default useDataGridQueryData;
