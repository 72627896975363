import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when) {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
		setLastLocation(null);
	}, []);

	const handleBlockedNavigation = useCallback(
		(nextLocation) => {
			if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
				setShowPrompt(true);
				setLastLocation(nextLocation);
				return false;
			}
			return true;
		},
		[confirmedNavigation, location]
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			let newLocation = '';

			if (lastLocation.location?.pathname) {
				newLocation += lastLocation.location?.pathname;
				if (lastLocation.location?.search) {
					newLocation += lastLocation.location?.search;
				}
			}

			navigate(newLocation);

			setConfirmedNavigation(false);
		}
	}, [confirmedNavigation, lastLocation]);

	useBlocker(handleBlockedNavigation, when);

	return { showPrompt, confirmNavigation, cancelNavigation };
}
