/*! For license information please see EncaptureMDAPI.js.LICENSE.txt */
!(function (e, t) {
	'object' == typeof exports && 'object' == typeof module
		? (module.exports = t())
		: 'function' == typeof define && define.amd
		? define([], t)
		: 'object' == typeof exports
		? (exports.EncaptureMDAPI = t())
		: (e.EncaptureMDAPI = t());
})(window, () =>
	(() => {
		var e = {
				915: function (e, t, r) {
					e.exports = (function (e) {
						return (
							(r = [
								function (t, r) {
									t.exports = e;
								},
								function (e, t, r) {
									'use strict';
									r.r(t),
										r.d(t, 'isStringVr', function () {
											return n;
										}),
										r.d(t, 'isPrivateTag', function () {
											return i;
										}),
										r.d(t, 'parsePN', function () {
											return o;
										}),
										r.d(t, 'parseTM', function () {
											return s;
										}),
										r.d(t, 'parseDA', function () {
											return d;
										}),
										r.d(t, 'explicitElementToString', function () {
											return f;
										}),
										r.d(t, 'explicitDataSetToJS', function () {
											return l;
										}),
										r.d(t, 'createJPEGBasicOffsetTable', function () {
											return m;
										}),
										r.d(t, 'parseDicomDataSetExplicit', function () {
											return F;
										}),
										r.d(t, 'parseDicomDataSetImplicit', function () {
											return T;
										}),
										r.d(t, 'readFixedString', function () {
											return y;
										}),
										r.d(t, 'alloc', function () {
											return B;
										}),
										r.d(t, 'version', function () {
											return _;
										}),
										r.d(t, 'bigEndianByteArrayParser', function () {
											return L;
										}),
										r.d(t, 'ByteStream', function () {
											return k;
										}),
										r.d(t, 'sharedCopy', function () {
											return q;
										}),
										r.d(t, 'DataSet', function () {
											return E;
										}),
										r.d(t, 'findAndSetUNElementLength', function () {
											return g;
										}),
										r.d(t, 'findEndOfEncapsulatedElement', function () {
											return h;
										}),
										r.d(t, 'findItemDelimitationItemAndSetElementLength', function () {
											return P;
										}),
										r.d(t, 'littleEndianByteArrayParser', function () {
											return C;
										}),
										r.d(t, 'parseDicom', function () {
											return V;
										}),
										r.d(t, 'readDicomElementExplicit', function () {
											return N;
										}),
										r.d(t, 'readDicomElementImplicit', function () {
											return x;
										}),
										r.d(t, 'readEncapsulatedImageFrame', function () {
											return $;
										}),
										r.d(t, 'readEncapsulatedPixelData', function () {
											return H;
										}),
										r.d(t, 'readEncapsulatedPixelDataFromFragments', function () {
											return G;
										}),
										r.d(t, 'readPart10Header', function () {
											return R;
										}),
										r.d(t, 'readSequenceItemsExplicit', function () {
											return I;
										}),
										r.d(t, 'readSequenceItemsImplicit', function () {
											return S;
										}),
										r.d(t, 'readSequenceItem', function () {
											return D;
										}),
										r.d(t, 'readTag', function () {
											return p;
										});
									var a = {
											AE: !0,
											AS: !0,
											AT: !1,
											CS: !0,
											DA: !0,
											DS: !0,
											DT: !0,
											FL: !1,
											FD: !1,
											IS: !0,
											LO: !0,
											LT: !0,
											OB: !1,
											OD: !1,
											OF: !1,
											OW: !1,
											PN: !0,
											SH: !0,
											SL: !1,
											SQ: !1,
											SS: !1,
											ST: !0,
											TM: !0,
											UI: !0,
											UL: !1,
											UN: void 0,
											UR: !0,
											US: !1,
											UT: !0,
										},
										n = function (e) {
											return a[e];
										},
										i = function (e) {
											if (((e = parseInt(e[4], 16)), isNaN(e)))
												throw 'dicomParser.isPrivateTag: cannot parse last character of group';
											return e % 2 == 1;
										},
										o = function (e) {
											if (void 0 !== e)
												return {
													familyName: (e = e.split('^'))[0],
													givenName: e[1],
													middleName: e[2],
													prefix: e[3],
													suffix: e[4],
												};
										};
									function s(e, t) {
										if (2 <= e.length) {
											var r = parseInt(e.substring(0, 2), 10),
												a = 4 <= e.length ? parseInt(e.substring(2, 4), 10) : void 0,
												n = 6 <= e.length ? parseInt(e.substring(4, 6), 10) : void 0,
												i = (i = 8 <= e.length ? e.substring(7, 13) : void 0)
													? parseInt(i, 10) * Math.pow(10, 6 - i.length)
													: void 0;
											if (
												t &&
												(isNaN(r) ||
													(void 0 !== a && isNaN(a)) ||
													(void 0 !== n && isNaN(n)) ||
													(void 0 !== i && isNaN(i)) ||
													r < 0 ||
													23 < r ||
													(a && (a < 0 || 59 < a)) ||
													(n && (n < 0 || 59 < n)) ||
													(i && (i < 0 || 999999 < i)))
											)
												throw "invalid TM '".concat(e, "'");
											return { hours: r, minutes: a, seconds: n, fractionalSeconds: i };
										}
										if (t) throw "invalid TM '".concat(e, "'");
									}
									function d(e, t) {
										if (e && 8 === e.length) {
											var r = parseInt(e.substring(0, 4), 10),
												a = parseInt(e.substring(4, 6), 10),
												n = parseInt(e.substring(6, 8), 10);
											if (
												t &&
												!0 !==
													(function (e, t, r) {
														return (
															!isNaN(r) &&
															0 < t &&
															t <= 12 &&
															0 < e &&
															e <=
																(function (e, t) {
																	switch (e) {
																		case 2:
																			return (t % 4 == 0 && t % 100) ||
																				t % 400 == 0
																				? 29
																				: 28;
																		case 9:
																		case 4:
																		case 6:
																		case 11:
																			return 30;
																		default:
																			return 31;
																	}
																})(t, r)
														);
													})(n, a, r)
											)
												throw "invalid DA '".concat(e, "'");
											return { year: r, month: a, day: n };
										}
										if (t) throw "invalid DA '".concat(e, "'");
									}
									function f(e, t) {
										if (void 0 === e || void 0 === t)
											throw 'dicomParser.explicitElementToString: missing required parameters';
										if (void 0 === t.vr)
											throw 'dicomParser.explicitElementToString: cannot convert implicit element to string';
										var r,
											a = t.vr,
											i = t.tag;
										function o(t, r) {
											for (var a = '', n = 0; n < t; n++)
												0 !== n && (a += '/'), (a += r.call(e, i, n).toString());
											return a;
										}
										if (!0 === n(a)) r = e.string(i);
										else {
											if ('AT' === a) {
												var s = e.uint32(i);
												return void 0 === s
													? void 0
													: 'x'.concat(
															(s = s < 0 ? 4294967295 + s + 1 : s)
																.toString(16)
																.toUpperCase()
													  );
											}
											'US' === a
												? (r = o(t.length / 2, e.uint16))
												: 'SS' === a
												? (r = o(t.length / 2, e.int16))
												: 'UL' === a
												? (r = o(t.length / 4, e.uint32))
												: 'SL' === a
												? (r = o(t.length / 4, e.int32))
												: 'FD' === a
												? (r = o(t.length / 8, e.double))
												: 'FL' === a && (r = o(t.length / 4, e.float));
										}
										return r;
									}
									function l(e, t) {
										if (void 0 === e)
											throw 'dicomParser.explicitDataSetToJS: missing required parameter dataSet';
										t = t || { omitPrivateAttibutes: !0, maxElementLength: 128 };
										var r,
											a = {};
										for (r in e.elements) {
											var n = e.elements[r];
											if (!0 !== t.omitPrivateAttibutes || !i(r))
												if (n.items) {
													for (var o = [], s = 0; s < n.items.length; s++)
														o.push(l(n.items[s].dataSet, t));
													a[r] = o;
												} else {
													var d = void 0;
													n.length < t.maxElementLength && (d = f(e, n)),
														(a[r] =
															void 0 !== d
																? d
																: { dataOffset: n.dataOffset, length: n.length });
												}
										}
										return a;
									}
									function u(e, t) {
										return 255 === e.byteArray[t] && 217 === e.byteArray[t + 1];
									}
									function c(e, t, r) {
										for (var a, n, i = r; i < t.fragments.length; i++)
											if (
												((n = i),
												u((a = e), (n = t.fragments[n]).position + n.length - 2) ||
													u(a, n.position + n.length - 3))
											)
												return i;
									}
									function m(e, t, r) {
										if (void 0 === e)
											throw 'dicomParser.createJPEGBasicOffsetTable: missing required parameter dataSet';
										if (void 0 === t)
											throw 'dicomParser.createJPEGBasicOffsetTable: missing required parameter pixelDataElement';
										if ('x7fe00010' !== t.tag)
											throw "dicomParser.createJPEGBasicOffsetTable: parameter 'pixelDataElement' refers to non pixel data tag (expected tag = x7fe00010'";
										if (!0 !== t.encapsulatedPixelData)
											throw "dicomParser.createJPEGBasicOffsetTable: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (!0 !== t.hadUndefinedLength)
											throw "dicomParser.createJPEGBasicOffsetTable: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (void 0 === t.basicOffsetTable)
											throw "dicomParser.createJPEGBasicOffsetTable: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (void 0 === t.fragments)
											throw "dicomParser.createJPEGBasicOffsetTable: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (t.fragments.length <= 0)
											throw "dicomParser.createJPEGBasicOffsetTable: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (r && r.length <= 0)
											throw "dicomParser.createJPEGBasicOffsetTable: parameter 'fragments' must not be zero length";
										r = r || t.fragments;
										for (var a = [], n = 0; ; ) {
											a.push(t.fragments[n].offset);
											var i = c(e, t, n);
											if (void 0 === i || i === t.fragments.length - 1) return a;
											n = i + 1;
										}
									}
									function p(e) {
										if (void 0 === e)
											throw "dicomParser.readTag: missing required parameter 'byteStream'";
										var t = 256 * e.readUint16() * 256;
										return (
											(e = e.readUint16()),
											'x'.concat('00000000'.concat((t + e).toString(16)).substr(-8))
										);
									}
									function h(e, t, r) {
										if (void 0 === e)
											throw "dicomParser.findEndOfEncapsulatedElement: missing required parameter 'byteStream'";
										if (void 0 === t)
											throw "dicomParser.findEndOfEncapsulatedElement: missing required parameter 'element'";
										if (
											((t.encapsulatedPixelData = !0),
											(t.basicOffsetTable = []),
											(t.fragments = []),
											'xfffee000' !== p(e))
										)
											throw 'dicomParser.findEndOfEncapsulatedElement: basic offset table not found';
										for (var a = e.readUint32() / 4, n = 0; n < a; n++) {
											var i = e.readUint32();
											t.basicOffsetTable.push(i);
										}
										for (var o = e.position; e.position < e.byteArray.length; ) {
											var s = p(e),
												d = e.readUint32();
											if ('xfffee0dd' === s)
												return e.seek(d), void (t.length = e.position - t.dataOffset);
											if ('xfffee000' !== s)
												return (
													r &&
														r.push(
															'unexpected tag '.concat(
																s,
																' while searching for end of pixel data element with undefined length'
															)
														),
													d > e.byteArray.length - e.position &&
														(d = e.byteArray.length - e.position),
													t.fragments.push({
														offset: e.position - o - 8,
														position: e.position,
														length: d,
													}),
													e.seek(d),
													void (t.length = e.position - t.dataOffset)
												);
											t.fragments.push({
												offset: e.position - o - 8,
												position: e.position,
												length: d,
											}),
												e.seek(d);
										}
										r &&
											r.push(
												'pixel data element '.concat(
													t.tag,
													' missing sequence delimiter tag xfffee0dd'
												)
											);
									}
									function g(e, t) {
										if (void 0 === e)
											throw "dicomParser.findAndSetUNElementLength: missing required parameter 'byteStream'";
										for (var r = e.byteArray.length - 8; e.position <= r; )
											if (65534 === e.readUint16() && 57565 === e.readUint16())
												return (
													0 !== e.readUint32() &&
														e.warnings(
															'encountered non zero length following item delimiter at position '
																.concat(
																	e.position - 4,
																	' while reading element of undefined length with tag '
																)
																.concat(t.tag)
														),
													void (t.length = e.position - t.dataOffset)
												);
										(t.length = e.byteArray.length - t.dataOffset),
											e.seek(e.byteArray.length - e.position);
									}
									function y(e, t, r) {
										if (r < 0) throw 'dicomParser.readFixedString - length cannot be less than 0';
										if (t + r > e.length)
											throw 'dicomParser.readFixedString: attempt to read past end of buffer';
										for (var a, n = '', i = 0; i < r; i++) {
											if (0 === (a = e[t + i])) return (t += r), n;
											n += String.fromCharCode(a);
										}
										return n;
									}
									function b(e, t) {
										for (var r = 0; r < t.length; r++) {
											var a = t[r];
											(a.enumerable = a.enumerable || !1),
												(a.configurable = !0),
												'value' in a && (a.writable = !0),
												Object.defineProperty(e, a.key, a);
										}
									}
									function v(e, t) {
										return void 0 !== e.parser ? e.parser : t;
									}
									var E = (function () {
										function e(t, r, a) {
											!(function (e, t) {
												if (!(e instanceof t))
													throw new TypeError('Cannot call a class as a function');
											})(this, e),
												(this.byteArrayParser = t),
												(this.byteArray = r),
												(this.elements = a);
										}
										var t, r;
										return (
											(t = e),
											(r = [
												{
													key: 'uint16',
													value: function (e, t) {
														if (
															((t = void 0 !== t ? t : 0),
															(e = this.elements[e]) && 0 !== e.length)
														)
															return v(e, this.byteArrayParser).readUint16(
																this.byteArray,
																e.dataOffset + 2 * t
															);
													},
												},
												{
													key: 'int16',
													value: function (e, t) {
														if (
															((t = void 0 !== t ? t : 0),
															(e = this.elements[e]) && 0 !== e.length)
														)
															return v(e, this.byteArrayParser).readInt16(
																this.byteArray,
																e.dataOffset + 2 * t
															);
													},
												},
												{
													key: 'uint32',
													value: function (e, t) {
														if (
															((t = void 0 !== t ? t : 0),
															(e = this.elements[e]) && 0 !== e.length)
														)
															return v(e, this.byteArrayParser).readUint32(
																this.byteArray,
																e.dataOffset + 4 * t
															);
													},
												},
												{
													key: 'int32',
													value: function (e, t) {
														if (
															((t = void 0 !== t ? t : 0),
															(e = this.elements[e]) && 0 !== e.length)
														)
															return v(e, this.byteArrayParser).readInt32(
																this.byteArray,
																e.dataOffset + 4 * t
															);
													},
												},
												{
													key: 'float',
													value: function (e, t) {
														if (
															((t = void 0 !== t ? t : 0),
															(e = this.elements[e]) && 0 !== e.length)
														)
															return v(e, this.byteArrayParser).readFloat(
																this.byteArray,
																e.dataOffset + 4 * t
															);
													},
												},
												{
													key: 'double',
													value: function (e, t) {
														if (
															((t = void 0 !== t ? t : 0),
															(e = this.elements[e]) && 0 !== e.length)
														)
															return v(e, this.byteArrayParser).readDouble(
																this.byteArray,
																e.dataOffset + 8 * t
															);
													},
												},
												{
													key: 'numStringValues',
													value: function (e) {
														if ((e = this.elements[e]) && 0 < e.length)
															return null ===
																(e = y(this.byteArray, e.dataOffset, e.length).match(
																	/\\/g
																))
																? 1
																: e.length + 1;
													},
												},
												{
													key: 'string',
													value: function (e, t) {
														return (e = this.elements[e]) && e.Value
															? e.Value
															: e && 0 < e.length
															? ((e = y(this.byteArray, e.dataOffset, e.length)),
															  0 <= t ? e.split('\\')[t].trim() : e.trim())
															: void 0;
													},
												},
												{
													key: 'text',
													value: function (e, t) {
														if ((e = this.elements[e]) && 0 < e.length)
															return (
																(e = y(this.byteArray, e.dataOffset, e.length)),
																0 <= t
																	? e.split('\\')[t].replace(/ +$/, '')
																	: e.replace(/ +$/, '')
															);
													},
												},
												{
													key: 'floatString',
													value: function (e, t) {
														var r = this.elements[e];
														if (
															r &&
															0 < r.length &&
															void 0 !== (t = this.string(e, (t = void 0 !== t ? t : 0)))
														)
															return parseFloat(t);
													},
												},
												{
													key: 'intString',
													value: function (e, t) {
														var r = this.elements[e];
														if (
															r &&
															0 < r.length &&
															void 0 !== (t = this.string(e, (t = void 0 !== t ? t : 0)))
														)
															return parseInt(t);
													},
												},
												{
													key: 'attributeTag',
													value: function (e) {
														if ((r = this.elements[e]) && 4 === r.length) {
															var t = v(r, this.byteArrayParser).readUint16,
																r = ((e = this.byteArray), r.dataOffset);
															return 'x'.concat(
																'00000000'
																	.concat(
																		(256 * t(e, r) * 256 + t(e, r + 2)).toString(16)
																	)
																	.substr(-8)
															);
														}
													},
												},
											]) && b(t.prototype, r),
											Object.defineProperty(t, 'prototype', { writable: !1 }),
											e
										);
									})();
									function P(e, t) {
										if (void 0 === e)
											throw "dicomParser.readDicomElementImplicit: missing required parameter 'byteStream'";
										for (var r = e.byteArray.length - 8; e.position <= r; )
											if (65534 === e.readUint16() && 57357 === e.readUint16())
												return (
													0 !== e.readUint32() &&
														e.warnings(
															'encountered non zero length following item delimiter at position '
																.concat(
																	e.position - 4,
																	' while reading element of undefined length with tag '
																)
																.concat(t.tag)
														),
													void (t.length = e.position - t.dataOffset)
												);
										(t.length = e.byteArray.length - t.dataOffset),
											e.seek(e.byteArray.length - e.position);
									}
									var w = function (e, t) {
										return void 0 !== e.vr
											? 'SQ' === e.vr
											: t.position + 4 <= t.byteArray.length
											? ((e = p(t)), t.seek(-4), 'xfffee000' === e || 'xfffee0dd' === e)
											: (t.warnings.push(
													'eof encountered before finding sequence item tag or sequence delimiter tag in peeking to determine VR'
											  ),
											  !1);
									};
									function x(e, t, r) {
										if (void 0 === e)
											throw "dicomParser.readDicomElementImplicit: missing required parameter 'byteStream'";
										var a;
										return (
											4294967295 ===
												(a = {
													tag: (a = p(e)),
													vr: void 0 !== r ? r(a) : void 0,
													length: e.readUint32(),
													dataOffset: e.position,
												}).length && (a.hadUndefinedLength = !0),
											a.tag === t ||
												(!w(a, e) || (i(a.tag) && !a.hadUndefinedLength)
													? a.hadUndefinedLength
														? P(e, a)
														: e.seek(a.length)
													: (S(e, a, r), i(a.tag) && (a.items = void 0))),
											a
										);
									}
									function D(e) {
										if (void 0 === e)
											throw "dicomParser.readSequenceItem: missing required parameter 'byteStream'";
										var t = { tag: p(e), length: e.readUint32(), dataOffset: e.position };
										if ('xfffee000' !== t.tag)
											throw 'dicomParser.readSequenceItem: item tag (FFFE,E000) not found at offset '.concat(
												e.position
											);
										return t;
									}
									function A(e, t) {
										var r = D(e);
										return (
											4294967295 === r.length
												? ((r.hadUndefinedLength = !0),
												  (r.dataSet = (function (e, t) {
														for (var r = {}; e.position < e.byteArray.length; ) {
															var a = x(e, void 0, t);
															if ('xfffee00d' === (r[a.tag] = a).tag)
																return new E(e.byteArrayParser, e.byteArray, r);
														}
														return (
															e.warnings.push(
																'eof encountered before finding sequence item delimiter in sequence item of undefined length'
															),
															new E(e.byteArrayParser, e.byteArray, r)
														);
												  })(e, t)),
												  (r.length = e.position - r.dataOffset))
												: ((r.dataSet = new E(e.byteArrayParser, e.byteArray, {})),
												  T(r.dataSet, e, e.position + r.length, { vrCallback: t })),
											r
										);
									}
									function S(e, t, r) {
										if (void 0 === e)
											throw "dicomParser.readSequenceItemsImplicit: missing required parameter 'byteStream'";
										if (void 0 === t)
											throw "dicomParser.readSequenceItemsImplicit: missing required parameter 'element'";
										(t.items = []),
											(4294967295 === t.length
												? function (e, t, r) {
														for (; e.position + 4 <= e.byteArray.length; ) {
															var a = p(e);
															if ((e.seek(-4), 'xfffee0dd' === a))
																return (
																	(t.length = e.position - t.dataOffset), e.seek(8)
																);
															(a = A(e, r)), t.items.push(a);
														}
														e.warnings.push(
															'eof encountered before finding sequence delimiter in sequence of undefined length'
														),
															(t.length = e.byteArray.length - t.dataOffset);
												  }
												: function (e, t, r) {
														for (var a = t.dataOffset + t.length; e.position < a; ) {
															var n = A(e, r);
															t.items.push(n);
														}
												  })(e, t, r);
									}
									function O(e, t) {
										var r = D(e);
										return (
											4294967295 === r.length
												? ((r.hadUndefinedLength = !0),
												  (r.dataSet = (function (e, t) {
														for (var r = {}; e.position < e.byteArray.length; ) {
															var a = N(e, t);
															if ('xfffee00d' === (r[a.tag] = a).tag)
																return new E(e.byteArrayParser, e.byteArray, r);
														}
														return (
															t.push(
																'eof encountered before finding item delimiter tag while reading sequence item of undefined length'
															),
															new E(e.byteArrayParser, e.byteArray, r)
														);
												  })(e, t)),
												  (r.length = e.position - r.dataOffset))
												: ((r.dataSet = new E(e.byteArrayParser, e.byteArray, {})),
												  F(r.dataSet, e, e.position + r.length)),
											r
										);
									}
									function I(e, t, r) {
										if (void 0 === e)
											throw "dicomParser.readSequenceItemsExplicit: missing required parameter 'byteStream'";
										if (void 0 === t)
											throw "dicomParser.readSequenceItemsExplicit: missing required parameter 'element'";
										(t.items = []),
											(4294967295 === t.length
												? function (e, t, r) {
														for (; e.position + 4 <= e.byteArray.length; ) {
															var a = p(e);
															if ((e.seek(-4), 'xfffee0dd' === a))
																return (
																	(t.length = e.position - t.dataOffset), e.seek(8)
																);
															(a = O(e, r)), t.items.push(a);
														}
														r.push(
															'eof encountered before finding sequence delimitation tag while reading sequence of undefined length'
														),
															(t.length = e.position - t.dataOffset);
												  }
												: function (e, t, r) {
														for (var a = t.dataOffset + t.length; e.position < a; ) {
															var n = O(e, r);
															t.items.push(n);
														}
												  })(e, t, r);
									}
									var U = function (e) {
										return 'OB' === e ||
											'OD' === e ||
											'OL' === e ||
											'OW' === e ||
											'SQ' === e ||
											'OF' === e ||
											'UC' === e ||
											'UR' === e ||
											'UT' === e ||
											'UN' === e
											? 4
											: 2;
									};
									function N(e, t, r) {
										if (void 0 === e)
											throw "dicomParser.readDicomElementExplicit: missing required parameter 'byteStream'";
										var a = { tag: p(e), vr: e.readFixedString(2) };
										return (
											2 === U(a.vr)
												? (a.length = e.readUint16())
												: (e.seek(2), (a.length = e.readUint32())),
											(a.dataOffset = e.position),
											4294967295 === a.length && (a.hadUndefinedLength = !0),
											a.tag === r ||
												('SQ' === a.vr
													? I(e, a, t)
													: 4294967295 === a.length
													? 'x7fe00010' === a.tag
														? h(e, a, t)
														: ('UN' === a.vr ? S : P)(e, a)
													: e.seek(a.length)),
											a
										);
									}
									function F(e, t, r) {
										var a = 3 < arguments.length && void 0 !== arguments[3] ? arguments[3] : {};
										if (((r = void 0 === r ? t.byteArray.length : r), void 0 === t))
											throw "dicomParser.parseDicomDataSetExplicit: missing required parameter 'byteStream'";
										if (r < t.position || r > t.byteArray.length)
											throw "dicomParser.parseDicomDataSetExplicit: invalid value for parameter 'maxP osition'";
										for (var n = e.elements; t.position < r; ) {
											var i = N(t, e.warnings, a.untilTag);
											if ((n[i.tag] = i).tag === a.untilTag) return;
										}
										if (t.position > r)
											throw 'dicomParser:parseDicomDataSetExplicit: buffer overrun';
									}
									function T(e, t, r) {
										var a = 3 < arguments.length && void 0 !== arguments[3] ? arguments[3] : {};
										if (((r = void 0 === r ? e.byteArray.length : r), void 0 === t))
											throw "dicomParser.parseDicomDataSetImplicit: missing required parameter 'byteStream'";
										if (r < t.position || r > t.byteArray.length)
											throw "dicomParser.parseDicomDataSetImplicit: invalid value for parameter 'maxPosition'";
										for (var n = e.elements; t.position < r; ) {
											var i = x(t, a.untilTag, a.vrCallback);
											if ((n[i.tag] = i).tag === a.untilTag) return;
										}
									}
									function B(e, t) {
										if ('undefined' != typeof Buffer && e instanceof Buffer) return Buffer.alloc(t);
										if (e instanceof Uint8Array) return new Uint8Array(t);
										throw 'dicomParser.alloc: unknown type for byteArray';
									}
									var _ = '1.8.12',
										L = {
											readUint16: function (e, t) {
												if (t < 0)
													throw 'bigEndianByteArrayParser.readUint16: position cannot be less than 0';
												if (t + 2 > e.length)
													throw 'bigEndianByteArrayParser.readUint16: attempt to read past end of buffer';
												return (e[t] << 8) + e[t + 1];
											},
											readInt16: function (e, t) {
												if (t < 0)
													throw 'bigEndianByteArrayParser.readInt16: position cannot be less than 0';
												if (t + 2 > e.length)
													throw 'bigEndianByteArrayParser.readInt16: attempt to read past end of buffer';
												return 32768 & (t = (e[t] << 8) + e[t + 1]) ? t - 65535 - 1 : t;
											},
											readUint32: function (e, t) {
												if (t < 0)
													throw 'bigEndianByteArrayParser.readUint32: position cannot be less than 0';
												if (t + 4 > e.length)
													throw 'bigEndianByteArrayParser.readUint32: attempt to read past end of buffer';
												return 256 * (256 * (256 * e[t] + e[t + 1]) + e[t + 2]) + e[t + 3];
											},
											readInt32: function (e, t) {
												if (t < 0)
													throw 'bigEndianByteArrayParser.readInt32: position cannot be less than 0';
												if (t + 4 > e.length)
													throw 'bigEndianByteArrayParser.readInt32: attempt to read past end of buffer';
												return (e[t] << 24) + (e[t + 1] << 16) + (e[t + 2] << 8) + e[t + 3];
											},
											readFloat: function (e, t) {
												if (t < 0)
													throw 'bigEndianByteArrayParser.readFloat: position cannot be less than 0';
												if (t + 4 > e.length)
													throw 'bigEndianByteArrayParser.readFloat: attempt to read past end of buffer';
												var r = new Uint8Array(4);
												return (
													(r[3] = e[t]),
													(r[2] = e[t + 1]),
													(r[1] = e[t + 2]),
													(r[0] = e[t + 3]),
													new Float32Array(r.buffer)[0]
												);
											},
											readDouble: function (e, t) {
												if (t < 0)
													throw 'bigEndianByteArrayParser.readDouble: position cannot be less than 0';
												if (t + 8 > e.length)
													throw 'bigEndianByteArrayParser.readDouble: attempt to read past end of buffer';
												var r = new Uint8Array(8);
												return (
													(r[7] = e[t]),
													(r[6] = e[t + 1]),
													(r[5] = e[t + 2]),
													(r[4] = e[t + 3]),
													(r[3] = e[t + 4]),
													(r[2] = e[t + 5]),
													(r[1] = e[t + 6]),
													(r[0] = e[t + 7]),
													new Float64Array(r.buffer)[0]
												);
											},
										};
									function q(e, t, r) {
										if ('undefined' != typeof Buffer && e instanceof Buffer)
											return e.slice(t, t + r);
										if (e instanceof Uint8Array)
											return new Uint8Array(e.buffer, e.byteOffset + t, r);
										throw 'dicomParser.from: unknown type for byteArray';
									}
									function M(e, t) {
										for (var r = 0; r < t.length; r++) {
											var a = t[r];
											(a.enumerable = a.enumerable || !1),
												(a.configurable = !0),
												'value' in a && (a.writable = !0),
												Object.defineProperty(e, a.key, a);
										}
									}
									var k = (function () {
											function e(t, r, a) {
												if (
													((function (e, t) {
														if (!(e instanceof t))
															throw new TypeError('Cannot call a class as a function');
													})(this, e),
													void 0 === t)
												)
													throw "dicomParser.ByteStream: missing required parameter 'byteArrayParser'";
												if (void 0 === r)
													throw "dicomParser.ByteStream: missing required parameter 'byteArray'";
												if (
													r instanceof Uint8Array == 0 &&
													('undefined' == typeof Buffer || r instanceof Buffer == 0)
												)
													throw 'dicomParser.ByteStream: parameter byteArray is not of type Uint8Array or Buffer';
												if (a < 0)
													throw "dicomParser.ByteStream: parameter 'position' cannot be less than 0";
												if (a >= r.length)
													throw "dicomParser.ByteStream: parameter 'position' cannot be greater than or equal to 'byteArray' length";
												(this.byteArrayParser = t),
													(this.byteArray = r),
													(this.position = a || 0),
													(this.warnings = []);
											}
											var t, r;
											return (
												(t = e),
												(r = [
													{
														key: 'seek',
														value: function (e) {
															if (this.position + e < 0)
																throw 'dicomParser.ByteStream.prototype.seek: cannot seek to position < 0';
															this.position += e;
														},
													},
													{
														key: 'readByteStream',
														value: function (t) {
															if (this.position + t > this.byteArray.length)
																throw 'dicomParser.ByteStream.prototype.readByteStream: readByteStream - buffer overread';
															var r = q(this.byteArray, this.position, t);
															return (this.position += t), new e(this.byteArrayParser, r);
														},
													},
													{
														key: 'getSize',
														value: function () {
															return this.byteArray.length;
														},
													},
													{
														key: 'readUint16',
														value: function () {
															var e = this.byteArrayParser.readUint16(
																this.byteArray,
																this.position
															);
															return (this.position += 2), e;
														},
													},
													{
														key: 'readUint32',
														value: function () {
															var e = this.byteArrayParser.readUint32(
																this.byteArray,
																this.position
															);
															return (this.position += 4), e;
														},
													},
													{
														key: 'readFixedString',
														value: function (e) {
															var t = y(this.byteArray, this.position, e);
															return (this.position += e), t;
														},
													},
												]) && M(t.prototype, r),
												Object.defineProperty(t, 'prototype', { writable: !1 }),
												e
											);
										})(),
										C = {
											readUint16: function (e, t) {
												if (t < 0)
													throw 'littleEndianByteArrayParser.readUint16: position cannot be less than 0';
												if (t + 2 > e.length)
													throw 'littleEndianByteArrayParser.readUint16: attempt to read past end of buffer';
												return e[t] + 256 * e[t + 1];
											},
											readInt16: function (e, t) {
												if (t < 0)
													throw 'littleEndianByteArrayParser.readInt16: position cannot be less than 0';
												if (t + 2 > e.length)
													throw 'littleEndianByteArrayParser.readInt16: attempt to read past end of buffer';
												return 32768 & (t = e[t] + (e[t + 1] << 8)) ? t - 65535 - 1 : t;
											},
											readUint32: function (e, t) {
												if (t < 0)
													throw 'littleEndianByteArrayParser.readUint32: position cannot be less than 0';
												if (t + 4 > e.length)
													throw 'littleEndianByteArrayParser.readUint32: attempt to read past end of buffer';
												return (
													e[t] +
													256 * e[t + 1] +
													256 * e[t + 2] * 256 +
													256 * e[t + 3] * 256 * 256
												);
											},
											readInt32: function (e, t) {
												if (t < 0)
													throw 'littleEndianByteArrayParser.readInt32: position cannot be less than 0';
												if (t + 4 > e.length)
													throw 'littleEndianByteArrayParser.readInt32: attempt to read past end of buffer';
												return e[t] + (e[t + 1] << 8) + (e[t + 2] << 16) + (e[t + 3] << 24);
											},
											readFloat: function (e, t) {
												if (t < 0)
													throw 'littleEndianByteArrayParser.readFloat: position cannot be less than 0';
												if (t + 4 > e.length)
													throw 'littleEndianByteArrayParser.readFloat: attempt to read past end of buffer';
												var r = new Uint8Array(4);
												return (
													(r[0] = e[t]),
													(r[1] = e[t + 1]),
													(r[2] = e[t + 2]),
													(r[3] = e[t + 3]),
													new Float32Array(r.buffer)[0]
												);
											},
											readDouble: function (e, t) {
												if (t < 0)
													throw 'littleEndianByteArrayParser.readDouble: position cannot be less than 0';
												if (t + 8 > e.length)
													throw 'littleEndianByteArrayParser.readDouble: attempt to read past end of buffer';
												var r = new Uint8Array(8);
												return (
													(r[0] = e[t]),
													(r[1] = e[t + 1]),
													(r[2] = e[t + 2]),
													(r[3] = e[t + 3]),
													(r[4] = e[t + 4]),
													(r[5] = e[t + 5]),
													(r[6] = e[t + 6]),
													(r[7] = e[t + 7]),
													new Float64Array(r.buffer)[0]
												);
											},
										};
									function R(e) {
										var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
										if (void 0 === e)
											throw "dicomParser.readPart10Header: missing required parameter 'byteArray'";
										var r = t.TransferSyntaxUID,
											a = new k(C, e);
										return (function () {
											var e = (function () {
													if (a.getSize() <= 132 && r) return !1;
													if ((a.seek(128), 'DICM' === a.readFixedString(4))) return !0;
													if (!(t || {}).TransferSyntaxUID)
														throw 'dicomParser.readPart10Header: DICM prefix not found at location 132 - this is not a valid DICOM P10 file.';
													return a.seek(0), !1;
												})(),
												n = [],
												i = {};
											if (!e)
												return (
													(a.position = 0),
													{
														elements: {
															x00020010: { tag: 'x00020010', vr: 'UI', Value: r },
														},
														warnings: n,
													}
												);
											for (; a.position < a.byteArray.length; ) {
												var o = a.position,
													s = N(a, n);
												if ('x0002ffff' < s.tag) {
													a.position = o;
													break;
												}
												(s.parser = C), (i[s.tag] = s);
											}
											return (
												((e = new E(a.byteArrayParser, a.byteArray, i)).warnings = a.warnings),
												(e.position = a.position),
												e
											);
										})();
									}
									var j = '1.2.840.10008.1.2.2';
									function V(e) {
										var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
										if (void 0 === e)
											throw new Error(
												"dicomParser.parseDicom: missing required parameter 'byteArray'"
											);
										var a;
										return (function (e, t) {
											for (var r in e.elements)
												e.elements.hasOwnProperty(r) && (t.elements[r] = e.elements[r]);
											return (
												void 0 !== e.warnings && (t.warnings = e.warnings.concat(t.warnings)), t
											);
										})(
											(a = R(e, t)),
											(function (a) {
												var n =
														'1.2.840.10008.1.2' !==
														(i = (function (t) {
															if (void 0 === t.elements.x00020010)
																throw new Error(
																	'dicomParser.parseDicom: missing required meta header attribute 0002,0010'
																);
															return (
																((t = t.elements.x00020010) && t.Value) ||
																y(e, t.dataOffset, t.length)
															);
														})(a)),
													i = (function (a, n) {
														var i =
															'[object process]' ===
															Object.prototype.toString.call(
																'undefined' != typeof process ? process : 0
															);
														if ('1.2.840.10008.1.2.1.99' !== a)
															return new k(a === j ? L : C, e, n);
														if (t && t.inflater)
															return (a = t.inflater(e, n)), new k(C, a, 0);
														if (1 == i) {
															var o = r(0),
																s = q(e, n, e.length - n);
															return (
																(o = o.inflateRawSync(s)),
																(s = B(e, o.length + n)),
																e.copy(s, 0, 0, n),
																o.copy(s, n),
																new k(C, s, 0)
															);
														}
														if ('undefined' == typeof pako)
															throw 'dicomParser.parseDicom: no inflater available to handle deflate transfer syntax';
														return (
															(o = e.slice(n)),
															(s = pako.inflateRaw(o)),
															(o = B(e, s.length + n)).set(e.slice(0, n), 0),
															o.set(s, n),
															new k(C, o, 0)
														);
													})(i, a.position);
												(a = new E(i.byteArrayParser, i.byteArray, {})).warnings = i.warnings;
												try {
													(n ? F : T)(a, i, i.byteArray.length, t);
												} catch (i) {
													throw { exception: i, dataSet: a };
												}
												return a;
											})(a)
										);
									}
									var Y = function (e, t, r) {
										for (var a = 0, n = t; n < t + r; n++) a += e[n].length;
										return a;
									};
									function G(e, t, r, a, n) {
										if (((n = n || t.fragments), void 0 === e))
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: missing required parameter 'dataSet'";
										if (void 0 === t)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: missing required parameter 'pixelDataElement'";
										if (void 0 === r)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: missing required parameter 'startFragmentIndex'";
										if (void 0 === (a = a || 1))
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: missing required parameter 'numFragments'";
										if ('x7fe00010' !== t.tag)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'pixelDataElement' refers to non pixel data tag (expected tag = x7fe00010";
										if (!0 !== t.encapsulatedPixelData)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (!0 !== t.hadUndefinedLength)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (void 0 === t.basicOffsetTable)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (void 0 === t.fragments)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (t.fragments.length <= 0)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (r < 0)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'startFragmentIndex' must be >= 0";
										if (r >= t.fragments.length)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'startFragmentIndex' must be < number of fragments";
										if (a < 1)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'numFragments' must be > 0";
										if (r + a > t.fragments.length)
											throw "dicomParser.readEncapsulatedPixelDataFromFragments: parameter 'startFragment' + 'numFragments' < number of fragments";
										var i = new k(e.byteArrayParser, e.byteArray, t.dataOffset);
										if ('xfffee000' !== (t = D(i)).tag)
											throw 'dicomParser.readEncapsulatedPixelData: missing basic offset table xfffee000';
										i.seek(t.length);
										var o = i.position;
										if (1 === a) return q(i.byteArray, o + n[r].offset + 8, n[r].length);
										t = Y(n, r, a);
										for (var s = B(i.byteArray, t), d = 0, f = r; f < r + a; f++)
											for (var l = o + n[f].offset + 8, u = 0; u < n[f].length; u++)
												s[d++] = i.byteArray[l++];
										return s;
									}
									var J = function (e, t) {
											for (var r = 0; r < e.length; r++) if (e[r].offset === t) return r;
										},
										K = function (e, t, r, a) {
											if (e === t.length - 1) return r.length - a;
											for (var n = t[e + 1], i = a + 1; i < r.length; i++)
												if (r[i].offset === n) return i - a;
											throw 'dicomParser.calculateNumberOfFragmentsForFrame: could not find fragment with offset matching basic offset table';
										};
									function $(e, t, r, a, n) {
										if (((a = a || t.basicOffsetTable), (n = n || t.fragments), void 0 === e))
											throw "dicomParser.readEncapsulatedImageFrame: missing required parameter 'dataSet'";
										if (void 0 === t)
											throw "dicomParser.readEncapsulatedImageFrame: missing required parameter 'pixelDataElement'";
										if (void 0 === r)
											throw "dicomParser.readEncapsulatedImageFrame: missing required parameter 'frameIndex'";
										if (void 0 === a)
											throw "dicomParser.readEncapsulatedImageFrame: parameter 'pixelDataElement' does not have basicOffsetTable";
										if ('x7fe00010' !== t.tag)
											throw "dicomParser.readEncapsulatedImageFrame: parameter 'pixelDataElement' refers to non pixel data tag (expected tag = x7fe00010)";
										if (!0 !== t.encapsulatedPixelData)
											throw "dicomParser.readEncapsulatedImageFrame: parameter 'pixelDataElement' refers to pixel data element that does not have encapsulated pixel data";
										if (!0 !== t.hadUndefinedLength)
											throw "dicomParser.readEncapsulatedImageFrame: parameter 'pixelDataElement' refers to pixel data element that does not have undefined length";
										if (void 0 === t.fragments)
											throw "dicomParser.readEncapsulatedImageFrame: parameter 'pixelDataElement' refers to pixel data element that does not have fragments";
										if (0 === a.length)
											throw 'dicomParser.readEncapsulatedImageFrame: basicOffsetTable has zero entries';
										if (r < 0)
											throw "dicomParser.readEncapsulatedImageFrame: parameter 'frameIndex' must be >= 0";
										if (r >= a.length)
											throw "dicomParser.readEncapsulatedImageFrame: parameter 'frameIndex' must be < basicOffsetTable.length";
										var i = a[r];
										if (void 0 === (i = J(n, i)))
											throw 'dicomParser.readEncapsulatedImageFrame: unable to find fragment that matches basic offset table entry';
										return G(e, t, i, K(r, a, n, i), n);
									}
									var z = !1;
									function H(e, t, r) {
										if (
											(z ||
												((z = !0),
												console &&
													console.log &&
													console.log(
														'WARNING: dicomParser.readEncapsulatedPixelData() has been deprecated'
													)),
											void 0 === e)
										)
											throw "dicomParser.readEncapsulatedPixelData: missing required parameter 'dataSet'";
										if (void 0 === t)
											throw "dicomParser.readEncapsulatedPixelData: missing required parameter 'element'";
										if (void 0 === r)
											throw "dicomParser.readEncapsulatedPixelData: missing required parameter 'frame'";
										if ('x7fe00010' !== t.tag)
											throw "dicomParser.readEncapsulatedPixelData: parameter 'element' refers to non pixel data tag (expected tag = x7fe00010)";
										if (!0 !== t.encapsulatedPixelData)
											throw "dicomParser.readEncapsulatedPixelData: parameter 'element' refers to pixel data element that does not have encapsulated pixel data";
										if (!0 !== t.hadUndefinedLength)
											throw "dicomParser.readEncapsulatedPixelData: parameter 'element' refers to pixel data element that does not have encapsulated pixel data";
										if (void 0 === t.basicOffsetTable)
											throw "dicomParser.readEncapsulatedPixelData: parameter 'element' refers to pixel data element that does not have encapsulated pixel data";
										if (void 0 === t.fragments)
											throw "dicomParser.readEncapsulatedPixelData: parameter 'element' refers to pixel data element that does not have encapsulated pixel data";
										if (r < 0)
											throw "dicomParser.readEncapsulatedPixelData: parameter 'frame' must be >= 0";
										return 0 !== t.basicOffsetTable.length
											? $(e, t, r)
											: G(e, t, 0, t.fragments.length);
									}
									t.default = {
										isStringVr: n,
										isPrivateTag: i,
										parsePN: o,
										parseTM: s,
										parseDA: d,
										explicitElementToString: f,
										explicitDataSetToJS: l,
										createJPEGBasicOffsetTable: m,
										parseDicomDataSetExplicit: F,
										parseDicomDataSetImplicit: T,
										readFixedString: y,
										alloc: B,
										version: _,
										bigEndianByteArrayParser: L,
										ByteStream: k,
										sharedCopy: q,
										DataSet: E,
										findAndSetUNElementLength: g,
										findEndOfEncapsulatedElement: h,
										findItemDelimitationItemAndSetElementLength: P,
										littleEndianByteArrayParser: C,
										parseDicom: V,
										readDicomElementExplicit: N,
										readDicomElementImplicit: x,
										readEncapsulatedImageFrame: $,
										readEncapsulatedPixelData: H,
										readEncapsulatedPixelDataFromFragments: G,
										readPart10Header: R,
										readSequenceItemsExplicit: I,
										readSequenceItemsImplicit: S,
										readSequenceItem: D,
										readTag: p,
										LEI: '1.2.840.10008.1.2',
										LEE: '1.2.840.10008.1.2.1',
									};
								},
							]),
							(a = {}),
							(t.m = r),
							(t.c = a),
							(t.d = function (e, r, a) {
								t.o(e, r) || Object.defineProperty(e, r, { enumerable: !0, get: a });
							}),
							(t.r = function (e) {
								'undefined' != typeof Symbol &&
									Symbol.toStringTag &&
									Object.defineProperty(e, Symbol.toStringTag, { value: 'Module' }),
									Object.defineProperty(e, '__esModule', { value: !0 });
							}),
							(t.t = function (e, r) {
								if ((1 & r && (e = t(e)), 8 & r)) return e;
								if (4 & r && 'object' == typeof e && e && e.__esModule) return e;
								var a = Object.create(null);
								if (
									(t.r(a),
									Object.defineProperty(a, 'default', { enumerable: !0, value: e }),
									2 & r && 'string' != typeof e)
								)
									for (var n in e)
										t.d(
											a,
											n,
											function (t) {
												return e[t];
											}.bind(null, n)
										);
								return a;
							}),
							(t.n = function (e) {
								var r =
									e && e.__esModule
										? function () {
												return e.default;
										  }
										: function () {
												return e;
										  };
								return t.d(r, 'a', r), r;
							}),
							(t.o = function (e, t) {
								return Object.prototype.hasOwnProperty.call(e, t);
							}),
							(t.p = ''),
							t((t.s = 1))
						);
						function t(e) {
							if (a[e]) return a[e].exports;
							var n = (a[e] = { i: e, l: !1, exports: {} });
							return r[e].call(n.exports, n, n.exports, t), (n.l = !0), n.exports;
						}
						var r, a;
					})(r(559));
				},
				559: () => {},
			},
			t = {};
		function r(a) {
			var n = t[a];
			if (void 0 !== n) return n.exports;
			var i = (t[a] = { exports: {} });
			return e[a].call(i.exports, i, i.exports, r), i.exports;
		}
		(r.d = (e, t) => {
			for (var a in t) r.o(t, a) && !r.o(e, a) && Object.defineProperty(e, a, { enumerable: !0, get: t[a] });
		}),
			(r.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t)),
			(r.r = e => {
				'undefined' != typeof Symbol &&
					Symbol.toStringTag &&
					Object.defineProperty(e, Symbol.toStringTag, { value: 'Module' }),
					Object.defineProperty(e, '__esModule', { value: !0 });
			});
		var a = {};
		return (
			(() => {
				'use strict';
				let e, t;
				function n(e) {
					if ('EncaptureMDBrowserConnectAPI' !== e?.data?.type) return;
					const t = e.data;
					if (t.apiVersion !== m)
						throw new Error(
							`EncaptureMD API versions do not match. This version: ${m}. Connected browser version: ${t.apiVersion}.`
						);
					if (t?.data?.dicomFileAsBase64) {
						let e = (function (e) {
							const t = atob(e),
								r = t.length,
								a = new Uint8Array(r);
							for (let e = 0; e < r; e++) a[e] = t.charCodeAt(e);
							return a;
						})(t?.data?.dicomFileAsBase64);
						t.data.rawDicomSRDataBlob = new Blob([e.buffer], { type: 'application/octet-stream' });
					}
					let r = !1;
					t.cmd === k.KEEP_ALIVE ? P && (r = !0) : b && (r = !0),
						r && console.log('Message Received:', e.data),
						V(e.data.cmd, e.data);
				}
				r.r(a),
					r.d(a, {
						EVENTS: () => k,
						addEventListener: () => C,
						apiVersion: () => m,
						closeStudy: () => q,
						connect: () => N,
						doMeasurement: () => I,
						emdConnect: () => x,
						emdDisconnected: () => S,
						emdLoaded: () => O,
						emdStudyFailedToOpened: () => A,
						emdStudyOpened: () => D,
						fireEvent: () => V,
						focusEncaptureMD: () => d,
						forceCloseEncaptureMD: () => l,
						isDebugging: () => b,
						isDebuggingKeepAlive: () => P,
						isEMDConnected: () => g,
						keepAlive: () => M,
						openPriorStudy: () => T,
						openStudy: () => F,
						removeAllEventListeners: () => j,
						removeEventListener: () => R,
						removeKeyImage: () => L,
						reportJustSigned: () => U,
						sendKeyImage: () => _,
						setIsDebugging: () => v,
						setIsDebuggingKeepAlive: () => w,
						submitMeasurements: () => B,
					});
				let i = !1;
				const o = (r = null) =>
						new Promise((a, o) => {
							if (null === r) {
								t = window.opener;
								let r = new URL(window.location.href),
									a = r.searchParams.get('viewerOrigin');
								r.searchParams.delete('viewerOrigin'),
									window.history.replaceState(null, null, r),
									a
										? sessionStorage.setItem('viewerOrigin', a)
										: (a = sessionStorage.getItem('viewerOrigin')),
									a || o(),
									(e = a);
							} else (i = !0), (e = r), f();
							window.addEventListener('message', n, !1), a();
						}),
					s = r => {
						if (i && !g())
							throw new Error('Cannot post message because the EncaptureMD window is not connected.');
						let a = !1;
						r.cmd === k.KEEP_ALIVE ? P && (a = !0) : b && (a = !0),
							a && console.log('Sending Message:', r),
							(r.type = 'EncaptureMDBrowserConnectAPI'),
							(r.apiVersion = m),
							t.postMessage(r, e);
					},
					d = () => {
						t && t.focus();
					},
					f = () => {
						let r = new URL(e);
						if (e.includes('localhost')) r.searchParams.append('viewerOrigin', window.location.origin);
						else {
							let e = new URL(r.origin);
							e.searchParams.append('viewerOrigin', window.location.origin),
								r.searchParams.append('redirect_uri', e.toString());
						}
						b && console.log('Opening EncaptureMD with URL: ' + r.toString()),
							(t = window.open(
								r.toString(),
								'_blank',
								'width=800,height=600,toolbar=no,menubar=no,status=no'
							));
					},
					l = () => {
						t && t.close();
					};
				var u = r(915);
				class c {
					constructor(e) {
						this.dicomFileAsUint8Array = e;
						const t = u.parseDicom(e);
						(this.patient = {}), (this.study = {}), (this.series = {});
						let r = t.string('x00100010') || '',
							a = c.parseFullName(r);
						(this.patient.firstName = a.firstName),
							(this.patient.lastName = a.lastName),
							(this.patient.middleName = a.middleName),
							(this.patient.title = a.title),
							(this.patient.suffix = a.suffix),
							(this.patient.patientID = t.string('x00100020') || ''),
							(this.patient.patientBirthDate = t.string('x00100030') || ''),
							(this.patient.patientSex = t.string('x00100040') || ''),
							(this.study.studyInstanceUID = t.string('x0020000d') || ''),
							(this.study.studyID = t.string('x00200010') || ''),
							(this.study.accessionNumber = t.string('x00080050') || ''),
							(this.study.studyDate = t.string('x00080020') || ''),
							(this.study.studyTime = t.string('x00080030') || ''),
							(this.study.patientHeight = t.string('x00101020') || ''),
							(this.study.weight = t.string('x00101030') || ''),
							(this.study.studyDescription = t.string('x00081030') || ''),
							(this.series.seriesInstanceUID = t.string('x0020000e') || ''),
							(this.series.seriesNumber = t.string('x00200011') || ''),
							(this.series.modality = t.string('x00080060') || ''),
							(this.series.seriesDate = t.string('x00080021') || ''),
							(this.series.seriesTime = t.string('x00080031') || ''),
							(this.series.seriesDescription = t.string('x0008103e') || '');
					}
					static parseFullName(e) {
						const t = {},
							r = e;
						if (r.includes('^')) {
							const e = r.split('^');
							return (
								(t.lastName = e[0]),
								(t.firstName = e[1]),
								(t.middleName = e.length >= 3 ? e[2] : void 0),
								(t.title = e.length >= 4 ? e[3] : void 0),
								(t.suffix = e.length >= 5 ? e[4] : void 0),
								t
							);
						}
						if (r.includes(',')) {
							const e = r.split(',');
							t.lastName = e[0];
							const a = e[1].trim().split(/\s/);
							1 === a.length
								? (t.firstName = a[0].trim())
								: 2 === a.length
								? ((t.firstName = a[0].trim()), (t.middleName = a[1].trim()))
								: 3 === a.length
								? ((t.firstName = a[0].trim()), (t.middleName = a[1].trim()), (t.title = a[2].trim()))
								: ((t.firstName = r), (t.lastName = r));
						} else {
							const e = r.split(' ');
							2 === e.length
								? ((t.firstName = e[0].trim()), (t.lastName = e[1].trim()))
								: 3 === e.length
								? ((t.firstName = e[0].trim()),
								  (t.middleName = e[1].trim()),
								  (t.lastName = e[2].trim()))
								: (1 === e.length || (t.firstName = r), (t.lastName = r));
						}
						return t;
					}
					static toISODateTime(e, t) {
						if (!e || !t) return null;
						let r = e.substring(0, 4),
							a = e.substring(4, 6),
							n = e.substring(6, 8),
							i = t.substring(0, 2),
							o = t.substring(2, 4),
							s = t.substring(4, 6);
						return new Date(r, a - 1, n, i, o, s).toISOString();
					}
					toOpenStudyPayload() {
						let e = { headerInfo: {}, rawDicomSRDataBlob: null };
						if (
							((e.headerInfo.patientID = this.patient.patientID),
							(e.headerInfo.firstName = this.patient.firstName),
							(e.headerInfo.lastName = this.patient.lastName),
							(e.headerInfo.patientDOB = this.patient.patientBirthDate),
							(e.headerInfo.gender = this.patient.patientSex),
							(e.headerInfo.studyUID = this.study.studyInstanceUID),
							(e.headerInfo.studyDateTime = c.toISODateTime(this.study.studyDate, this.study.studyTime)),
							(e.headerInfo.modality = this.series.modality),
							(e.headerInfo.accessionNumber = this.study.accessionNumber),
							this.dicomFileAsUint8Array && !(this.dicomFileAsUint8Array instanceof Uint8Array))
						)
							throw new Error('dicom file is not a Uint8Array');
						return (
							(e.dicomFileAsBase64 = (function (e) {
								let t = '';
								for (let r = 0; r < e.length; r++) t += String.fromCharCode(e[r]);
								return btoa(t);
							})(this.dicomFileAsUint8Array)),
							e
						);
					}
				}
				const m = '1.1.1';
				let p = [],
					h = !1;
				function g() {
					return h;
				}
				function y(e) {
					b && console.log('setIsEMDConnected:', e), (h = e);
				}
				let b = !1;
				function v(e) {
					console.log('setIsDebugging:', e), (b = e);
				}
				let E,
					P = !1;
				function w(e) {
					console.log('setIsDebuggingKeepAlive:', e), (P = e);
				}
				async function x() {
					return o().then(() => {
						O();
					});
				}
				function D() {
					let e = { cmd: k.STUDY_OPENED };
					s(e);
				}
				function A(e) {
					let t = { cmd: k.STUDY_FAILED_TO_OPEN, reason: e };
					s(t);
				}
				function S() {
					let e = { cmd: k.UNLOADED };
					s(e);
				}
				function O() {
					let e = { cmd: k.LOADED };
					s(e);
				}
				async function I(e, t) {
					s({ cmd: k.DO_MEASUREMENT, fieldMapSkeleton: e, measurementType: t });
				}
				function U(e) {
					E = e;
				}
				async function N(e) {
					o(e),
						window.addEventListener('beforeunload', () => {
							let e = { cmd: k.DISCONNECT };
							s(e);
						});
				}
				async function F(e) {
					if (((E = null), !g()))
						throw new Error(
							'Cannot open study because EncaptureMD is not connected. connect() should be called before openStudy().'
						);
					let t;
					(t = 'string' == typeof e ? { headerInfo: { studyUID: e } } : new c(e).toOpenStudyPayload()), d();
					let r = { cmd: k.OPEN_STUDY, data: t };
					s(r);
				}
				async function T(e, t = !0) {
					if (!g())
						throw new Error(
							'Cannot open prior study because EncaptureMD is not connected. connect() should be called before openStudy().'
						);
					t && d();
					let r = { study: { studyInstanceUID: e } },
						a = { cmd: k.OPEN_PRIOR_STUDY, data: r };
					s(a);
				}
				async function B(e) {
					s({ cmd: k.ON_MEASUREMENT, measurementObj: JSON.parse(JSON.stringify(e)) });
				}
				async function _(e, t = 'keyImage', r = 'jpg') {
					const a = `${t}.${r}`;
					s({ cmd: k.ON_KEY_IMAGE, imageAsBase64: e, fileType: r, fileName: a });
				}
				async function L(e, t) {
					const r = `${e}.${t}`;
					s({ cmd: k.ON_REMOVE_KEY_IMAGE, fileName: r });
				}
				async function q(e = null) {
					const t = { cmd: k.CLOSE_STUDY };
					if (e && !i) {
						const r = E === e;
						(E = null), (t.reportJustSigned = r);
					}
					s(t);
				}
				function M() {
					s({ cmd: k.KEEP_ALIVE });
				}
				const k = {
					LOADED: 'LOADED',
					UNLOADED: 'UNLOADED',
					STUDY_FAILED_TO_OPEN: 'STUDY_FAILED_TO_OPEN',
					STUDY_OPENED: 'STUDY_OPENED',
					DO_MEASUREMENT: 'DO_MEASUREMENT',
					OPEN_STUDY: 'OPEN_STUDY',
					OPEN_PRIOR_STUDY: 'OPEN_PRIOR_STUDY',
					ON_KEY_IMAGE: 'ON_KEY_IMAGE',
					ON_REMOVE_KEY_IMAGE: 'ON_REMOVE_KEY_IMAGE',
					ON_MEASUREMENT: 'ON_MEASUREMENT',
					DISCONNECT: 'DISCONNECT',
					CLOSE_STUDY: 'CLOSE_STUDY',
					KEEP_ALIVE: 'KEEP_ALIVE',
				};
				function C(e, t) {
					p[e] || (p[e] = []), p[e].push(t);
				}
				function R(e) {
					p[e] && (p[e] = []);
				}
				function j() {
					p = [];
				}
				function V(e, t) {
					e === k.UNLOADED && y(!1),
						e === k.LOADED && y(!0),
						p[e] &&
							p[e].forEach(function (e) {
								e(t);
							});
				}
			})(),
			a
		);
	})()
);
