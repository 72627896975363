import { fhirExtensionUrls } from '@worklist-2/core/src';
import {
	NON_SMOKING_CODE,
	NON_DRINKER_CODE,
	OBSERVATION_TYPES,
	SYSTOLIC_BLOOD_CODE,
	DIASTOLIC_BLOOD_CODE,
} from '../consts/consts';
import { isArray } from 'lodash';

export const patientObservation = (observation, type) => {
	if (!observation) return null;
	let res = '';
	if (observation[type]?.valueQuantity) {
		const toFixed = (num, fixed) => {
			const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
			return num.toString().match(re)[0];
		};

		const { value, unit } = observation[type]?.valueQuantity;
		res = `${toFixed(value, 2)} ${unit}`;
	}
	return res;
};

const social_status_definer = (code, type) => {
	let status = false;
	switch (type) {
		case OBSERVATION_TYPES.smoking_status:
			status = code == NON_SMOKING_CODE;
			break;
		case OBSERVATION_TYPES.alcohol_status:
			status = code == NON_DRINKER_CODE;
			break;
	}
	return !status;
};

export const patientSocialStatus = (observation, type) => {
	if (!observation) return null;
	let res = false;
	if (observation[type]?.valueCodeableConcept) {
		const value = observation[type]?.valueCodeableConcept?.coding?.find(item => item.code);
		if (value) {
			const { code } = value;
			res = social_status_definer(code, type);
		}
	}
	return res;
};

export const patientBloodPressure = (observations, type) => {
	if (!observations) return null;
	let res = '';
	const systolicBlood = observations[type]?.component?.find(item =>
		item?.code?.coding.find(c => c.code === SYSTOLIC_BLOOD_CODE)
	)?.valueQuantity?.value;
	const diastolicBlood = observations[type]?.component?.find(item =>
		item?.code?.coding.find(c => c.code === DIASTOLIC_BLOOD_CODE)
	)?.valueQuantity?.value;
	if (systolicBlood && diastolicBlood) {
		res = `${systolicBlood}/${diastolicBlood}`;
	}
	return res;
};

export const patientBirthSex = patient =>
	patient?.extension?.find(ext => ext.url === fhirExtensionUrls.patient.birthSex);

export const patientEthnicity = patient => {
	const patientData = patient?.extension?.find(ext => ext.url === fhirExtensionUrls.patient.ethnicity);
	return patientData?.extension[0]?.valueCoding;
};

export const patientRace = patient => {
	const patientData = patient?.extension?.find(ext => ext.url === fhirExtensionUrls.patient.race);
	return patientData?.extension[0]?.valueCoding;
};

export const patientCountry = patient =>
	patient?.address && isArray(patient.address) && patient.address.length > 0 ? patient.address[0]?.country || '' : '';
