import React from 'react';

import {
	getInstanceAcquisitionMatrix,
	getInstanceAge,
	getInstanceBodyPartThickness,
	getInstanceCompressionForce,
	getInstanceContrastAgent,
	getInstanceConvolutionKernel,
	getInstanceDateOfBirth,
	getInstanceEchoTime,
	getInstanceEchoTrainLength,
	getInstanceExposure,
	getInstanceExposureTime,
	getInstanceFlipAngle,
	getInstanceInversionTime,
	getInstanceKVP,
	getInstanceLossyImageCompression,
	getInstanceLossyImageCompressionMethod,
	getInstanceLossyImageCompressionRatio,
	getInstanceMRAcquisitionType,
	getInstanceNumberOfAverages,
	getInstanceOrganDose,
	getInstancePixelBandwidth,
	getInstancePositionerPrimaryAngle,
	getInstanceReconstructionDiameter,
	getInstanceRepetitionTime,
	getInstanceSequenceName,
	getInstanceSequenceVariant,
	getInstanceSeriesNumber,
	getInstanceSex,
	getInstanceSharpness,
	getInstanceSliceThickness,
	getInstanceSpacingBetweenSlices,
	getInstanceSyntax,
	getInstanceTechName,
	getInstanceTriggerTime,
	getInstanceUnsharpMask,
	getSeriesContentDate,
	getSeriesContentTime,
	getSeriesDate,
	getSeriesInstanceNumber,
	getSeriesNumberOfFrames,
	getSeriesTime,
	getStudyDateRaw,
	getStudyID,
	getStudyPatientName,
	getStudyPatientID,
	getStudyTimeRaw,
	getSeriesAcquisitionDateTime,
	getSeriesAcquisitionDate,
	getSeriesAcquisitionTime,
	getCurrent,
} from '../../utils/utils';
import { parseDate, parseTime, roundToTwoDecimals } from '../../utils/parsers';
import GetCalculatedSliceLocationString, { CalculateSliceLocation } from './GetCalculatedSliceLocationString';
import { WindowLevelTag } from './components/tags/WindowLevelTag';
import getSeriesOrientationPlane, { DICOM_IMAGE_PLANE_ANNOTATIONS } from '../../utils/getSeriesOrientationPlane';
import { STUDY_INDICATOR } from './consts/consts';
import { FrameNumberTag } from './components/tags/FrameNumberTag';
import { parseName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils/getUserFullName';

export const getAgeDisplay = metadata => {
	const age = getInstanceAge(metadata);
	const instanceDateOfBirth = getInstanceDateOfBirth(metadata);

	if (age) return age;

	if (!age && instanceDateOfBirth) {
		const yearOfBirth = parseInt(instanceDateOfBirth.substring(0, 4));
		const monthOfBirth = parseInt(instanceDateOfBirth.substring(4, 6));
		const dayOfBirth = parseInt(instanceDateOfBirth.substring(6, 8));

		const birthDate = new Date(`${yearOfBirth}/${monthOfBirth}/${dayOfBirth}`);

		const today = new Date();
		const diffTime = today.getTime() - birthDate.getTime();
		const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

		const years = Math.floor(diffDays / 365);
		const months = Math.floor((diffDays % 365) / 30);
		const days = diffDays - years * 365 - months * 30;

		// Format age
		if (years >= 2) return `${years}Y ${months}M ${days}D`;

		if (years === 1) return `${years}Y, ${months}M`;

		if (months >= 1) return `${months}M`;
		else return `${days}D`;
	}
};

export const getAcquisitionDisplay = metadata => {
	const acquisitionDateTime = getSeriesAcquisitionDateTime(metadata);
	const acquisitionDate = getSeriesAcquisitionDate(metadata);
	const acquisitionTime = getSeriesAcquisitionTime(metadata);

	const contentDate = getSeriesContentDate(metadata);
	const contentTime = getSeriesContentTime(metadata);

	if (acquisitionDateTime) {
		let acquisitionDateTimeDisplay = acquisitionDateTime;
		if (acquisitionDateTime.length >= 14) {
			acquisitionDateTimeDisplay =
				parseDate(acquisitionDateTime.substr(0, 8)) + ' ' + parseTime(acquisitionDateTime.substr(8, 6));
		}
		return acquisitionDateTimeDisplay;
	}

	if (acquisitionDate && acquisitionTime)
		return parseDate(acquisitionDate) + ' ' + parseTime(acquisitionTime.substr(0, 6));

	if (contentDate && contentTime) return parseDate(contentDate) + ' ' + parseTime(contentTime.substr(0, 6));
};

export const getStudyDateDisplay = metadata => {
	const studyDate = getStudyDateRaw(metadata);
	const studyTime = getStudyTimeRaw(metadata);
	const seriesDate = getSeriesDate(metadata);
	const seriesTime = getSeriesTime(metadata);

	if (studyDate && studyTime) return `${parseDate(studyDate)} ${parseTime(studyTime)}`;

	if (seriesDate && seriesTime) return `${parseDate(seriesDate)} ${parseTime(seriesTime)}`;
};

export const getImageCompressionDisplay = metadata => {
	const lossyImageCompression = getInstanceLossyImageCompression(metadata);
	const lossyImageCompressionRatio = getInstanceLossyImageCompressionRatio(metadata);
	const lossyImageCompressionMethod = getInstanceLossyImageCompressionMethod(metadata);
	const transferSyntaxUID = getInstanceSyntax(metadata);

	if (lossyImageCompression == '00') {
		return 'Lossless';
	}
	if (lossyImageCompression == '01' && lossyImageCompressionRatio) {
		return 'Lossy ' + roundToTwoDecimals(lossyImageCompressionRatio) + ':1 ' + lossyImageCompressionMethod;
	}
	if (!lossyImageCompression && transferSyntaxUID) {
		if (
			transferSyntaxUID == '1.2.840.10008.1.2' ||
			transferSyntaxUID == '1.2.840.10008.1.2.1' ||
			transferSyntaxUID == '1.2.840.10008.1.2.1.99' ||
			transferSyntaxUID == '1.2.840.10008.1.2.2'
		) {
			return 'Uncompressed';
		}
		if (
			transferSyntaxUID == '1.2.840.10008.1.2.4.50' ||
			transferSyntaxUID == '1.2.840.10008.1.2.4.51' ||
			transferSyntaxUID == '1.2.840.10008.1.2.4.52'
		) {
			return 'JPEG Lossy';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.4.57' || transferSyntaxUID == '1.2.840.10008.1.2.4.70') {
			return 'JPEG Lossless';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.4.80') {
			return 'JPEG-LS (Lossless)';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.4.81') {
			return 'JPEG-LS Lossy';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.4.90' || transferSyntaxUID == '1.2.840.10008.1.2.4.92') {
			return 'JPEG 2K (Lossless)';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.4.91' || transferSyntaxUID == '1.2.840.10008.1.2.4.93') {
			return 'JPEG 2K Lossy';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.5') {
			return 'RLE Lossless';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.4.100' || transferSyntaxUID == '1.2.840.10008.1.2.4.101') {
			return 'MPEG-2';
		}
		if (transferSyntaxUID == '1.2.840.10008.1.2.4.102' || transferSyntaxUID == '1.2.840.10008.1.2.4.103') {
			return ' MPEG-4';
		}
	}
};

const getSliceLocationDisplay = (metadata, imageIndex) => {
	const slPos = metadata?.['00201041']?.Value?.at(0);
	const currentSliceLocation = slPos && roundToTwoDecimals(slPos);
	const tPos = metadata?.['00189327']?.Value?.at(0);
	const tablePosition = tPos && roundToTwoDecimals(tPos);
	const imagePositionPatient =
		metadata?.['52009230']?.Value[imageIndex]?.['00209113']?.Value?.[0]?.['00200032']?.Value ||
		metadata?.['00200032']?.Value;
	const imageOrientationPatient =
		metadata?.['52009230']?.Value?.[imageIndex]?.['00209116']?.Value?.[0]?.['00200037']?.Value ||
		metadata?.['00200037']?.Value;

	if (currentSliceLocation !== undefined) {
		return `SL: ${currentSliceLocation}mm`;
	}

	if (tablePosition !== undefined) {
		return `SL: ${tablePosition}mm`;
	}

	try {
		if (parseInt(getSeriesNumberOfFrames(metadata)) > 1) {
			const calculatedSliceLocationStr = GetCalculatedSliceLocationString(metadata, imageIndex);
			if (calculatedSliceLocationStr) {
				return `SL: ${calculatedSliceLocationStr}mm`;
			}
		}

		if (imagePositionPatient && imageOrientationPatient) {
			const calculatedSliceLocation = CalculateSliceLocation(imagePositionPatient, imageOrientationPatient);
			if (calculatedSliceLocation && calculatedSliceLocation.slicePositionProjection) {
				const calcResult = roundToTwoDecimals(calculatedSliceLocation.slicePositionProjection);
				if (calcResult != null) {
					return `SL: ${calcResult}mm`;
				}
			}
		}
	} catch (error) {
		console.error('An error occurred during CalculateSliceLocation:', error);
	}
};

export const getOverlayInstanceNumber = metadata => {
	const instanceNumber = getSeriesInstanceNumber(metadata);

	if (!instanceNumber) return null;

	return instanceNumber;
};

export const techName = metadata => {
	return getInstanceTechName(metadata)?.Alphabetic;
};

export const sharpnessUnsharpMaskDegree = metadata => {
	const sharpness = getInstanceSharpness(metadata);
	const unsharpMask = getInstanceUnsharpMask(metadata);
	if (sharpness && unsharpMask) return `S:${sharpness} - U:${unsharpMask}`;
};

export const convolutionKernel = metadata => {
	const instanceConvolutionKernel = getInstanceConvolutionKernel(metadata);
	if (instanceConvolutionKernel) return `CK: ${instanceConvolutionKernel}`;
};

export const acquisitionMatrix = metadata => {
	const instanceAcquisitionMatrix = getInstanceAcquisitionMatrix(metadata);
	if (instanceAcquisitionMatrix) return `ACQ Matrix: ${instanceAcquisitionMatrix}`;
};

export const numberOfAverages = metadata => {
	const instanceNumberOfAverages = getInstanceNumberOfAverages(metadata);
	if (instanceNumberOfAverages) return `NEX: ${instanceNumberOfAverages}`;
};

export const echoTrainLength = metadata => {
	const instanceEchoTrainLength = getInstanceEchoTrainLength(metadata);
	if (instanceEchoTrainLength) return `ETL: ${instanceEchoTrainLength}`;
};

export const mrAcquisitionType = metadata => {
	const instanceMRacquisitionType = getInstanceMRAcquisitionType(metadata);
	return instanceMRacquisitionType && `MAT: ${instanceMRacquisitionType}`;
};

export const sequenceName = metadata => {
	const instanceSequenceName = getInstanceSequenceName(metadata);
	return instanceSequenceName && `SEQ: ${instanceSequenceName}`;
};

export const sequenceVariant = metadata => {
	const instanceSequenceVariant = getInstanceSequenceVariant(metadata);
	return instanceSequenceVariant && `SV: ${instanceSequenceVariant}`;
};

export const patientName = metadata => {
	const instancePatientName = getStudyPatientName(metadata);
	const fullName = parseName(instancePatientName, false, 'DICOM');
	return instancePatientName && `NAME: ${fullName}`;
};

export const patientID = metadata => {
	const instancePatientID = getStudyPatientID(metadata);
	return instancePatientID && `PID: ${instancePatientID}`;
};

export const dateOfBirth = metadata => {
	const instanceDateOfBirth = getInstanceDateOfBirth(metadata);
	return instanceDateOfBirth && `DOB: ${instanceDateOfBirth}`;
};

export const studyID = metadata => {
	const instanceStudyID = getStudyID(metadata);
	return instanceStudyID && `SID: ${instanceStudyID}`;
};

export const seriesNumber = metadata => {
	const instanceSeriesNumber = getInstanceSeriesNumber(metadata);
	return instanceSeriesNumber && `S#: ${instanceSeriesNumber}`;
};

export const sex = metadata => {
	const instanceSex = getInstanceSex(metadata);
	return instanceSex && `Sex: ${instanceSex}`;
};

export const sliceThickness = metadata => {
	const instanceSliceThickness = getInstanceSliceThickness(metadata);
	return instanceSliceThickness && `ST: ${roundToTwoDecimals(instanceSliceThickness)}mm`;
};

export const spacingBetweenSlices = metadata => {
	const instanceSpacingBetweenSlices = getInstanceSpacingBetweenSlices(metadata);
	return instanceSpacingBetweenSlices && `SBS: ${roundToTwoDecimals(instanceSpacingBetweenSlices)}mm`;
};

export const contrastAgent = metadata => {
	return getInstanceContrastAgent(metadata)?.substr(0, 30);
};

export const kvp = metadata => {
	const instanceKVP = getInstanceKVP(metadata);
	return instanceKVP && `KVP: ${roundToTwoDecimals(instanceKVP)}`;
};

export const current = metadata => {
	const instanceCurrent = getCurrent(metadata);
	return instanceCurrent && `Current: ${roundToTwoDecimals(instanceCurrent)}mA`;
};

export const exposure = metadata => {
	const instanceExposure = getInstanceExposure(metadata);
	return instanceExposure && `Exp: ${roundToTwoDecimals(instanceExposure)}mAs`;
};

export const exposureTime = metadata => {
	const instanceExposureTime = getInstanceExposureTime(metadata);
	return instanceExposureTime && `ET: ${roundToTwoDecimals(instanceExposureTime)}ms`;
};

export const compressionForce = metadata => {
	const instanceCompressionForce = getInstanceCompressionForce(metadata);
	return instanceCompressionForce && `CF: ${roundToTwoDecimals(instanceCompressionForce)}N`;
};

export const reconstructionDiameter = metadata => {
	const instanceReconstructionDiameter = getInstanceReconstructionDiameter(metadata);
	return instanceReconstructionDiameter && `RD: ${roundToTwoDecimals(instanceReconstructionDiameter)}mm`;
};

export const organDose = metadata => {
	const instanceOrganDose = getInstanceOrganDose(metadata);
	return instanceOrganDose && `OD: ${roundToTwoDecimals(instanceOrganDose)}dGy`;
};

export const positionerPrimaryAngle = metadata => {
	const instancePositionerPrimaryAngle = getInstancePositionerPrimaryAngle(metadata);
	return instancePositionerPrimaryAngle && `PPA: ${roundToTwoDecimals(instancePositionerPrimaryAngle)}°`;
};

export const bodyPartThickness = metadata => {
	const instanceBodyPartThickness = getInstanceBodyPartThickness(metadata);
	return instanceBodyPartThickness && `Thk: ${roundToTwoDecimals(instanceBodyPartThickness)}mm`;
};

export const repetitionTime = metadata => {
	const instanceRepetitionTime = getInstanceRepetitionTime(metadata);
	return instanceRepetitionTime && `TR: ${roundToTwoDecimals(instanceRepetitionTime)}ms`;
};

export const echoTime = metadata => {
	const instanceEchoTime = getInstanceEchoTime(metadata);
	return instanceEchoTime && `TE: ${roundToTwoDecimals(instanceEchoTime)}ms`;
};

export const inversionTime = metadata => {
	const instanceInversionTime = getInstanceInversionTime(metadata);
	return instanceInversionTime && `TI: ${roundToTwoDecimals(instanceInversionTime)}ms`;
};

export const triggerTime = metadata => {
	const instanceTriggerTime = getInstanceTriggerTime(metadata);
	return instanceTriggerTime && `TD: ${roundToTwoDecimals(instanceTriggerTime)}ms`;
};

export const flipAngle = metadata => {
	const instanceFlipAngle = getInstanceFlipAngle(metadata);
	return instanceFlipAngle && `Flip: ${roundToTwoDecimals(instanceFlipAngle)}°`;
};

export const pixelBandwidth = metadata => {
	const instancePixelBandwidth = getInstancePixelBandwidth(metadata);
	return instancePixelBandwidth && `BW: ${roundToTwoDecimals(instancePixelBandwidth)}Hz`;
};

export const smartImageCompression = metadata => {
	const lossyImageCompression = getInstanceLossyImageCompression(metadata);
	const lossyImageCompressionRatio = getInstanceLossyImageCompressionRatio(metadata);
	const lossyImageCompressionMethod = getInstanceLossyImageCompressionMethod(metadata);
	const transferSyntaxUID = getInstanceSyntax(metadata);

	if (
		// lossy conditions
		transferSyntaxUID == '1.2.840.10008.1.2.4.50' ||
		transferSyntaxUID == '1.2.840.10008.1.2.4.51' ||
		transferSyntaxUID == '1.2.840.10008.1.2.4.52' ||
		transferSyntaxUID == '1.2.840.10008.1.2.4.81' ||
		transferSyntaxUID == '1.2.840.10008.1.2.4.91' ||
		transferSyntaxUID == '1.2.840.10008.1.2.4.93' ||
		lossyImageCompression == '01' ||
		lossyImageCompressionRatio ||
		lossyImageCompressionMethod
	) {
		if (lossyImageCompressionRatio) {
			return 'Lossy ' + Math.round(lossyImageCompressionRatio) + ':1';
		}

		return 'Lossy';
	}

	return undefined;
};

export const sliceLocation = (metadata, viewport) => {
	try {
		if (viewport.type === 'stack') {
			const imageIdIndex = viewport.getCurrentImageIdIndex();
			if (imageIdIndex >= 0) {
				return getSliceLocationDisplay(metadata, imageIdIndex);
			}
		}
	} catch (error) {
		console.log('sliceLocation: ', error);
	}
};

export const currentOrPriorIndicator = (metadata, viewport, isCurrent) =>
	isCurrent ? STUDY_INDICATOR.Current : STUDY_INDICATOR.Prior;

export const WindowCenterWidth = viewportId => (
	<WindowLevelTag key={`${viewportId}-window-level`} viewportId={viewportId} />
);

export const frameNumber = (_, viewport) => {
	try {
		if (viewport.type === 'stack') {
			const currentIndex = viewport.getCurrentImageIdIndex();
			if (currentIndex >= 0) {
				return currentIndex + 1;
			}
		} else if (viewport.type === 'orthographic') {
			return <FrameNumberTag viewport={viewport} />;
		}
	} catch (error) {
		console.log('frameNumber: ', error);
	}
};

export const frameNumberTotalFrames = (_, viewport) => {
	try {
		if (viewport.type === 'stack') {
			const currentIndex = viewport.getCurrentImageIdIndex();
			const averageCount = viewport.getImageIds()?.length;
			if (currentIndex >= 0 && averageCount) {
				return `${currentIndex + 1}/${averageCount}`;
			}
		} else if (viewport.type === 'orthographic') {
			return <FrameNumberTag viewport={viewport} totalFrames={true} />;
		}
	} catch (error) {
		console.log('frameNumberTotalFrames: ', error);
	}
};

export const viewcodeTag = (metadata, viewport) => {
	if (viewport.modality === 'MG') {
		const mammographyViewCode = viewport.options?.mammographyViewCode;
		return mammographyViewCode?.split(': ')?.[1];
	} else {
		const imageId = viewport.getCurrentImageId();
		if (imageId && metadata) {
			const imageOrientationPlanIndex = getSeriesOrientationPlane(metadata);
			if (imageOrientationPlanIndex in DICOM_IMAGE_PLANE_ANNOTATIONS) {
				return `View: ${DICOM_IMAGE_PLANE_ANNOTATIONS[imageOrientationPlanIndex]}`;
			}
		}
	}
};
