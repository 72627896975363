import React, { useMemo } from 'react';
import { HelpPopOverButton } from './HelpCenter';
import { loadOmegaMarkdown } from '../../../../../../markdown/omega-ai/help-center/categories/markdownLoader';
import { Container } from './WhatsNew/whatsNewStyles';

const HelpCenterContent = ({ md }) => {
	const Content = useMemo(() => loadOmegaMarkdown(md)?.content, [md]);

	return (
		<Container className="content-container">
			<HelpPopOverButton />
			{Content}
		</Container>
	);
};

export default HelpCenterContent;
