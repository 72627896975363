import axios from 'axios';
import handleApiResponse from '@worklist-2/worklist/src/DocumentViewerV3/api/utils';

const getGlobalNotes = async ({ __config, resourceType, studyInstanceUID, internalManagingOrganizationID }) => {
	let response = await axios.get(
		`${__config.data_sources.dicom_web}/StudyResource?resourceType=${resourceType}&studyInstanceUID=${studyInstanceUID}&internalManagingOrganizationId=${internalManagingOrganizationID}`
	);
	response = handleApiResponse(response);
	if (response.data?.length > 0) {
		return response.data;
	}
	return [];
};

export default getGlobalNotes;
