export const BIRTH_SEX_CODES = ['FEMALE', 'MALE', 'OTHER', 'UNKNOW'];

export const MARITAL_STATUS_CODES = ['A', 'D', 'I', 'L', 'M', 'P', 'S', 'T', 'W', 'U', 'UNK'];

export const GENDER_CODES = ['female', 'male', 'other', 'unknown'];

export const OBSERVATION_TYPES = {
	smoking_status: 'smoking_status',
	alcohol_status: 'alcohol_status',
	body_weight: 'body_weight',
	body_height: 'body_height',
	blood_pressure: 'blood_pressure',
	heart_rate: 'heart_rate',
	social_status: 'social_status',
	gender: 'gender',
	birth_sex: 'birth_sex',
	marital_status: 'marital_status',
	ethnicity: 'ethnicity',
	race: 'race',
	country: 'country',
	state_province: 'state_province',
	zip_postal_code: 'zip_postal_code',
	city: 'city',
	address_1: 'address_1',
	address_2: 'address_2',
};

export const SYSTOLIC_BLOOD_CODE = '8480-6';

export const DIASTOLIC_BLOOD_CODE = '8462-4';

export const NON_DRINKER_CODE = 105542008;

export const NON_SMOKING_CODE = 266919005;
