import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Collapse,
	Checkbox,
	FormControlLabel,
	Grid,
	CircularProgress,
	Typography,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { ImportDialogItem } from './components/ImportDialogItem/ImportDialogItem';
import { IMG_TYPES } from '../../features/Import/consts/consts';
import { useTranslation } from 'react-i18next';

export const ImportDialog = ({
	showImportDialog,
	onSubmit,
	onDeleteItem,
	onUngroup,
	onChangeImportCapabilities,
	fileList = [],
	onClose,
}) => {
	const { t } = useTranslation('imageViewer', { keyPrefix: 'importModal' });

	return (
		<Dialog
			aria-describedby="alert-dialog-description"
			aria-labelledby="alert-dialog-title"
			open={showImportDialog}
		>
			<DialogTitle>{t('You are trying to upload multiple images')}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{fileList.length === 0 ? (
						<Grid container alignItems="center" direction="column" justifyContent="center">
							<Grid item>
								<CircularProgress />
							</Grid>
							<Grid item>
								<Typography variant="h6">Processing. Please wait</Typography>
							</Grid>
						</Grid>
					) : (
						<TransitionGroup>
							{fileList.map(item => (
								<Collapse key={item.id}>
									<FormControlLabel
										checked={item.isGrouped}
										control={
											<Checkbox
												disableRipple
												checkedIcon={<></>}
												icon={<></>}
												sx={{
													position: 'absolute',
													top: 0,
													right: 0,
													bottom: 0,
													left: 0,
													p: 0,
													borderRadius: '10px',
													pointerEvents: 'none',
													'&:after': {
														content: "''",
														position: 'absolute',
														top: 0,
														right: 0,
														bottom: 0,
														left: 0,
														background: 'var(--rsprimary-main)',
														opacity: 0,
														transition: '.2s opacity',
													},

													'&:before': {
														content: "''",
														position: 'absolute',
														top: 0,
														right: 0,
														bottom: 0,
														left: 0,
														border: '1px solid var(--rsprimary-main)',
														borderRadius: '10px',
														opacity: 0,
														transition: '.2s opacity',
													},

													'&.Mui-checked:before': {
														opacity: 0.6,
													},

													'&.Mui-checked:after': {
														opacity: 0.2,
													},

													'&:hover': {},
												}}
											/>
										}
										label={
											<ImportDialogItem
												badgeContent="Grouped"
												encapsulate={item.encapsulate}
												file={item.file}
												isBadgeVisible={item.isGrouped}
												thumbnailAvailable={IMG_TYPES.includes(item.fileType)}
												type={item.fileType}
												value={item.id}
												onChangeImportCapabilities={v => onChangeImportCapabilities(item.id, v)}
												onDelete={() => onDeleteItem(item.id)}
											/>
										}
										role="model-item-option"
										sx={{
											m: 0,
											mt: 2,
											p: 0,
											borderRadius: '10px',
											position: 'relative',
											overflow: 'hidden',
											width: '100%',
											background: 'rgba(65, 65, 65, 1)',

											'& .MuiTypography-root.MuiFormControlLabel-label': {
												width: '100%',
												px: 2,
												py: 1,
												pl: 1,
											},
										}}
										value={item.id}
										onChange={e => {
											IMG_TYPES.includes(item.fileType) && onUngroup(item.id, e.target.checked);
										}}
									/>
								</Collapse>
							))}
						</TransitionGroup>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="rsPrimary" onClick={onClose}>
					Cancel
				</Button>
				<Button color="rsPrimary" onClick={() => onSubmit()}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};
