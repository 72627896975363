import { useState } from 'react';

const useTwilioSync = ({ twilioSyncClient, twilioConnectionStatus }) => {
	const addParticipant = (participantsMapKey, identity, data) => {
		let cleanupFuncs = [];
		let map;

		twilioSyncClient.map(participantsMapKey).then(participantMap => {
			map = participantMap;

			// Since Sync Map has pagination we need to navigate through all the pages
			async function getAllItems(map) {
				const result = [];
				let page = await map.getItems();
				result.push(...page.items);

				while (page.hasNextPage) {
					page = await page.nextPage();
					result.push(...page.items);
				}
				return result;
			}

			function refreshParticipants() {
				getAllItems(map).then(items => {
					const newParticipants = items.map(item => item.data);
				});
			}

			map.addListener('itemAdded', refreshParticipants)
				.addListener('itemUpdated', refreshParticipants)
				.addListener('itemRemoved', refreshParticipants)
				.set(identity, data)
				.then(item => {
					console.log(`User ${item.key} added.`);
				})
				.catch(err => {
					console.error(err);
				});
		});

		cleanupFuncs.push(() => {
			map.removeAllListeners()
				.remove(identity)
				.then(() => {
					console.log(`User ${identity} removed.`);
				})
				.catch(error => {
					console.error('Error removing: ' + identity, error);
				});
		});

		return cleanupFuncs;
	};

	return { addParticipant };
};

export default useTwilioSync;
