import React, { createContext, useContext, useState } from 'react';

const ImageViewerPatientContext = createContext({});

const ImageViewerPatientContextProvider = ({ children }) => {
	const [internalPatientId, setInternalPatientId] = useState(null);
	const [patientId, setPatientId] = useState(null);
	const [issuerOfPatientID, setIssuerOfPatientID] = useState(null);
	const [patient, setPatient] = useState({});

	return (
		<ImageViewerPatientContext.Provider
			value={{
				internalPatientId,
				setInternalPatientId,
				patientId,
				setPatientId,
				patient,
				setPatient,
				issuerOfPatientID,
				setIssuerOfPatientID,
			}}
		>
			{children}
		</ImageViewerPatientContext.Provider>
	);
};

const useImageViewerPatientContext = () => useContext(ImageViewerPatientContext);

export { useImageViewerPatientContext, ImageViewerPatientContextProvider };
