import React, { createContext, useContext, useState } from 'react';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import {
	DOCUMENT_VIEWER_PERMISSIONS,
	PRELIMINARY_REPORT,
	FINAL_REPORT,
	STUDY_REPORT,
	TEMPLATE_MANAGER,
	MISC,
	VR_SETTING,
	RADPAIR_REPORT,
} from '../consts/documentViewerPermissionTags';

const DocumentViewerPermissionsContext = createContext({});

const DocumentViewerPermissionsContextProvider = ({ children }) => {
	const galaxyDvUacEnableNewPermissions = useBooleanFlagValue('galaxy-dv-uac-enable-new-permissions');

	const cacheDvPermission = localStorage.getItem('DVPermissions');
	const previousPermission = JSON.parse(cacheDvPermission)?.permission;

	const [documentViewerPermissions, setDocumentViewerPermissions] = useState(previousPermission);

	const getPermission = (permission, permissionType) => {
		const defaultPermission = { ...permission };
		if (!galaxyDvUacEnableNewPermissions || !documentViewerPermissions) {
			for (const [key] of Object.entries(defaultPermission)) {
				defaultPermission[key] = true;
			}
			return defaultPermission;
		}

		if (permissionType) {
			for (const [key] of Object.entries(defaultPermission)) {
				defaultPermission[key] = documentViewerPermissions?.[permissionType]?.[permission?.[key]]
					? documentViewerPermissions?.[permissionType]?.[permission?.[key]]?.read
					: true;
			}
		} else {
			for (const [key] of Object.entries(defaultPermission)) {
				defaultPermission[key] = documentViewerPermissions?.[permission?.[key]]
					? documentViewerPermissions?.[permission?.[key]]?.read
					: true;
			}
		}
		return defaultPermission;
	};
	return (
		<DocumentViewerPermissionsContext.Provider
			value={{
				// To store permissions in context
				documentViewerPermissions,
				setDocumentViewerPermissions,
				// All types for permissions
				PRELIMINARY_REPORT_PERMISSION: getPermission(
					PRELIMINARY_REPORT,
					DOCUMENT_VIEWER_PERMISSIONS.PRELIMINARY_REPORTS
				),
				FINAL_REPORT_PERMISSION: getPermission(FINAL_REPORT, DOCUMENT_VIEWER_PERMISSIONS.FINAL_REPORTS),
				STUDY_REPORT_PERMISSION: getPermission(STUDY_REPORT, DOCUMENT_VIEWER_PERMISSIONS.STUDY_REPORTS),
				TEMPLATE_MANAGER_PERMISSION: getPermission(
					TEMPLATE_MANAGER,
					DOCUMENT_VIEWER_PERMISSIONS.TEMPLATE_MANAGER
				),
				MISC_PERMISSION: getPermission(MISC, DOCUMENT_VIEWER_PERMISSIONS.MISC),
				DOCUMENT_VIEWER_PERMISSION: getPermission(DOCUMENT_VIEWER_PERMISSIONS),
				VR_SETTING_PERMISSION: getPermission(VR_SETTING, DOCUMENT_VIEWER_PERMISSIONS.VR_SETTING),
				RADPAIR_REPORT_PERMISSION: getPermission(RADPAIR_REPORT, DOCUMENT_VIEWER_PERMISSIONS.RADPAIR_REPORT),
			}}
		>
			{children}
		</DocumentViewerPermissionsContext.Provider>
	);
};

const useDocumentViewerPermissionsContext = () => useContext(DocumentViewerPermissionsContext);

export {
	DocumentViewerPermissionsContext,
	useDocumentViewerPermissionsContext,
	DocumentViewerPermissionsContextProvider as default,
};
