import { useAuth } from '@worklist-2/core/src';
import { useGlobalContext } from '../contexts/GlobalContextProvider';
import useGetSearchParams from './useGetSearchParams';
import { useDocumentViewerPermissionsContext } from '../contexts/DocumentViewerPermissionsContextProvider';
import getUserRoleByOrganization from '../utils/getUserRoleByOrganization';
import { ROLE_USER_TYPE } from '../consts/roles';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useCheckAccess = () => {
	const { proxyUser, practitionerRoles } = useGlobalContext();
	const galaxyDvProxySign = useBooleanFlagValue('galaxy-dv-proxy-sign');
	const galaxyDvUacEnableNewPermissions = useBooleanFlagValue('galaxy-dv-uac-enable-new-permissions');
	const { getGuiRoleByOrganizationAndResource, loggedIn, accessToken, loggedInUser } = useAuth();
	const { internalManagingOrganizationID, isSharedStudy } = useGetSearchParams();
	const { DOCUMENT_VIEWER_PERMISSION, PRELIMINARY_REPORT_PERMISSION } = useDocumentViewerPermissionsContext();

	const isSharedStudyFromContext = accessToken && !loggedInUser;

	let hasUpdateAccess = false;
	let hasProxyAssigneeAccess = false;
	let hasProxyUserAccess = false;
	let hasTranscribeAccess = false;
	let hasReferringPhysicianAccess = false;

	if (loggedIn && !isSharedStudy && !isSharedStudyFromContext) {
		const isReadingPhysician = getUserRoleByOrganization(
			practitionerRoles,
			internalManagingOrganizationID,
			ROLE_USER_TYPE.READING_PHYSICIAN
		);

		const isTranscriptionist = getUserRoleByOrganization(
			practitionerRoles,
			internalManagingOrganizationID,
			ROLE_USER_TYPE.TRANSCRIPTIONIST
		);

		const isReferringPhysician = getUserRoleByOrganization(
			practitionerRoles,
			internalManagingOrganizationID,
			ROLE_USER_TYPE.REFERRING_PHYSICIAN
		);

		const hasProxyAccess = galaxyDvUacEnableNewPermissions
			? DOCUMENT_VIEWER_PERMISSION?.PROXY
			: getGuiRoleByOrganizationAndResource(internalManagingOrganizationID, 'Home')?.['sign study']?.[
					'proxy sign'
			  ]?.read;

		const hasDvSignAccess = galaxyDvUacEnableNewPermissions
			? PRELIMINARY_REPORT_PERMISSION?.SIGN_STUDY
			: getGuiRoleByOrganizationAndResource(internalManagingOrganizationID, 'Home')?.['document viewer']?.[
					'report editor'
			  ]?.['sign study']?.read;

		const hasSignAccess = galaxyDvUacEnableNewPermissions
			? true
			: getGuiRoleByOrganizationAndResource(internalManagingOrganizationID, 'Home')?.['sign study']?.read;

		// If the user is a reading physican and hasProxyAccess
		hasProxyAssigneeAccess =
			galaxyDvProxySign && isReadingPhysician && hasDvSignAccess && hasSignAccess && hasProxyAccess;

		// If the user has proxy sign access
		hasProxyUserAccess = galaxyDvProxySign && hasDvSignAccess && proxyUser;

		hasUpdateAccess = galaxyDvUacEnableNewPermissions
			? true
			: getGuiRoleByOrganizationAndResource(internalManagingOrganizationID, 'Home')?.['document viewer']?.read;

		const hasTranscriberControl = galaxyDvUacEnableNewPermissions
			? DOCUMENT_VIEWER_PERMISSION?.VOICE_RECORDING
			: getGuiRoleByOrganizationAndResource(internalManagingOrganizationID, 'Home')?.['sign study']?.transcribe
					?.read;

		hasTranscribeAccess = isTranscriptionist && hasTranscriberControl;

		hasReferringPhysicianAccess = isReferringPhysician;
	}

	return {
		hasFullAccess: hasUpdateAccess,
		hasProxyAccess: hasProxyAssigneeAccess,
		hasProxyUserAccess,
		hasTranscribeAccess,
		hasReadOnlyAccess: hasReferringPhysicianAccess,
	};
};

export default useCheckAccess;
