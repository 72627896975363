import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { ListItem, MoreLink, ResultContent, resultsStyle } from './helpStyles';
import LiveSupportButton from './LiveSupportButton';
import { useHelpCenter } from '@worklist-2/core/src';

const SearchResult = ({ setView }) => {
	const { Questions, searchKey } = useHelpCenter();
	const [results, setResults] = useState(Questions);
	useEffect(() => {
		if (searchKey && searchKey.trim().length > 0) {
			const resultFilter = _.filter(Questions, item =>
				item.question.toLowerCase().includes(searchKey.toLowerCase())
			);
			setResults(resultFilter);
		}
	}, [searchKey]);

	return (
		<>
			<Box
				sx={{
					...resultsStyle,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<ResultContent
					style={{
						flex: 1,
						borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
					}}
				>
					<p className="title">Top Results</p>
					{results.map((res, i) => (
						<ListItem style={{ border: 0 }}>
							<p>{res.question}</p>
						</ListItem>
					))}
				</ResultContent>
				<MoreLink onClick={() => setView('questionInput')}>
					Can’t find what you’re looking for? Submit your question.
				</MoreLink>
			</Box>
			<LiveSupportButton onClick={() => setView('support')} />
		</>
	);
};

export default SearchResult;
