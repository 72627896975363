import { useContext } from 'react';

const useZustandStore = Context => {
	const store = useContext(Context);

	if (!store) {
		throw new Error('Missing Context.Provider in the tree');
	}

	return store;
};

export default useZustandStore;
