import { useImageViewerMultiMonitorContext } from '../contexts/ImageViewerMultiMonitorContext';
import { BROADCAST_EVENTS } from '../contexts/consts/consts';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';

export const useOnSetLayoutItems = () => {
	const { postMessage } = useImageViewerMultiMonitorContext();
	const { setLayoutItems } = useImageViewerLayoutContext();

	const onSetLayoutItems = async (value, options = { shouldBroadcastEvent: true }) => {
		const result = await setLayoutItems(value);

		if (!options.shouldBroadcastEvent) {
			return;
		}

		postMessage({
			event: BROADCAST_EVENTS.LAYOUT_ITEMS_UPDATE,
			value: result,
		});
	};

	return {
		onSetLayoutItems,
	};
};
