import _ from 'lodash';

export const COVERAGE_LEVELS = [1, 2, 3];
export const COVERAGE_LEVEL_DISPLAYS = ['PRIMARY', 'SECONDARY', 'TERTIARY'];

/**
 * @param {Array} assignedCoverageLevels - Array of coverage levels that have been assigned
 * @param {number[]} coverageLevelOptions - Array of coverage levels that can be assigned
 * @returns {number} the first available coverage level that has not been assigned
 */
export const findNextAvailableCoverageLevel = (assignedCoverageLevels, coverageLevelOptions) => {
	let seen = {};
	if (Array.isArray(assignedCoverageLevels)) {
		assignedCoverageLevels.forEach((covLvl) => {
			if (covLvl) {
				seen[covLvl] = true;
			}
		});
	}
	return coverageLevelOptions.find((lvl) => {
		return !seen[lvl];
	});
};

/**
 * This function takes an array of coverage levels and returns the lowest
 * consecutive coverage levels. The lowest coverage returned coverage levels
 * starts from 1.
 * Example: [2, 3, 4] -> [1, 2, 3]
 * Example: [5, null, 2] -> [2, null, 1]
 * Example: [4, 10, 7] -> [1, 3, 2]
 * @param {Array} assignedCoverageLevels - an array of coverage levels, can contain null
 * @returns {Array} an array that contains the lowest consecutive coverage levels
 */
export const calculateLowestConsecutiveCoverageLevels = (assignedCoverageLevels) => {
	const lowestConsecutiveCoverageLevels = assignedCoverageLevels.map(() => null);

	const originalIndexArr = assignedCoverageLevels
		.map((covLvl, ind) => {
			return { value: covLvl, originalIndex: ind };
		})
		.filter((obj) => obj.value != null);

	originalIndexArr.sort((a, b) => {
		if (a.value < b.value) {
			return -1;
		} else if (a.value > b.value) {
			return 1;
		} else {
			return 0;
		}
	});

	originalIndexArr.forEach((el, ind) => {
		lowestConsecutiveCoverageLevels[el.originalIndex] = ind + 1;
	});
	return lowestConsecutiveCoverageLevels;
};

/**
 * When changing the coverage level of a coverage resource, other coverages for a patient must
 * also have their coverage levels updated
 * @param {Object} param0 - param object
 * @param {number} param0.newCoverageLevel - the newly selected coverage level for the target coverage
 * @param {number} param0.prevCoverageLevel - the old coverage level for the target coverage
 * @param {number[]} param0.currentCoverageLevels - the coverage levels for all coverages for a patient
 * @param {number} param0.currentCoverageIndex - the index of the current coverage
 * @param {function} param0.updateCoverage - a function to update the coverage model
 * @param {number[]} param0.coverageLevelOptions - the available coverage options that can be selected
 * @returns
 */
export const updateCoveragesModelOnCoverageLevelChange = ({
	newCoverageLevel,
	previousCoverageLevel,
	currentCoverageLevels,
	currentCoverageIndex,
	updateCoverage,
	coverageLevelOptions,
}) => {
	let prevCoverageLevel = previousCoverageLevel;
	if (!coverageLevelOptions.includes(prevCoverageLevel)) {
		// If the previous coverage level is not included in the available coverage options, then that means there is a coverage option that is not yet selected.
		// Find the first available unselected option and set it to prevCoverageLevel.
		prevCoverageLevel = findNextAvailableCoverageLevel(currentCoverageLevels, coverageLevelOptions);
	}

	// When a coverage level is selected, check to see if it
	// is unique. If not, then shift other coverage levels up
	// or down to accomodate.
	if (newCoverageLevel === prevCoverageLevel || !currentCoverageLevels.includes(newCoverageLevel)) {
		return;
	} else if (newCoverageLevel > prevCoverageLevel) {
		// If the new coverage level is greater than the
		// original, then shift the others up.
		// e.g. Primary -> Tertiary. Shift all other Tertiary
		// to Secondary and shift all other Secondary to Primary
		const coverageLevelRange = _.range(prevCoverageLevel + 1, newCoverageLevel + 1);

		currentCoverageLevels?.forEach((covLvl, ind) => {
			if (coverageLevelRange.includes(covLvl) && ind !== currentCoverageIndex) {
				updateCoverage(covLvl - 1, ind);
			}
		});
	} else if (newCoverageLevel < prevCoverageLevel) {
		// If the new coverage level is less than the
		// original, then shift the others down.
		// e.g. Tertiary -> Secondary. Shift all other Secondary
		// to Tertiary
		const coverageLevelRange = _.range(newCoverageLevel, prevCoverageLevel);

		currentCoverageLevels?.forEach((covLvl, ind) => {
			if (coverageLevelRange.includes(covLvl) && ind !== currentCoverageIndex) {
				updateCoverage(covLvl + 1, ind);
			}
		});
	}
};
