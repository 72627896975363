import { COORDINATOR_WORKER_URL, WORKER_MESSAGES } from './consts/consts.jsx';
import { generateDicomJsons } from './utils/importUtils.jsx';

export class ImportDispatcher {
	coordinatorWorker = null;
	instance;
	numOfWorkers = navigator.hardwareConcurrency || 1;
	sessionId;
	accessToken;
	scopes;

	constructor(accessToken, updateAccessToken, sessionId, instance, scopes) {
		this.instance = instance;
		this.accessToken = accessToken;
		this.sessionId = sessionId;
		this.updateAccessToken = updateAccessToken;
		this.scopes = scopes;
		this.initialSetup(COORDINATOR_WORKER_URL);
	}

	initialSetup = (script) => {
		this.coordinatorWorker = new Worker(script, { type: 'module' });
		this.coordinatorWorker.onmessage = this.messageObserver;
		this.coordinatorWorker.postMessage({
			type: WORKER_MESSAGES.number_of_workers,
			payload: { numOfWorkers: this.numOfWorkers },
		});
		this.coordinatorWorker.postMessage({
			type: WORKER_MESSAGES.access_token,
			payload: { accessToken: this.accessToken },
		});
		this.coordinatorWorker.postMessage({ type: WORKER_MESSAGES.sessionId, payload: { sessionId: this.sessionId } });
	};

	getOrRenewWorkerAccessToken = async () => {
		let token = this.accessToken;
		try {
			let data = await this.instance?.acquireTokenSilent({
				scopes: this.scopes,
				account: this.instance.getActiveAccount(),
			});

			if (!!data && `${data.tokenType} ${data.accessToken}` !== this.accessToken) {
				token = `${data.tokenType} ${data.accessToken}`;
			}
			this.accessToken = token
		} catch (errors) {
			console.error('getOrRenewWorkerAccessToken' + errors);
		}
		return token;
	};

	importFiles = ({ files, studyProps, patientProps, internalmanagingorganizationid, url }) => {
		const filesArray = generateDicomJsons(files, studyProps, patientProps, internalmanagingorganizationid, url);
		this.startImport(filesArray);
	};

	messageObserver = (e) => {
		if (e.data) {
			const { type, payload } = e.data;
			switch (type) {
				case WORKER_MESSAGES.post_success:
					postMessage(e.data);
					break;
				case WORKER_MESSAGES.post_error:
					postMessage(e.data);
					break;
				case WORKER_MESSAGES.import_completed:
					postMessage(e.data);
					break;
				case WORKER_MESSAGES.import_in_progress:
					postMessage(e.data);
					break;
				case WORKER_MESSAGES.upload_progress:
					const { uploadProgress, uploadingFileId } = payload;
					postMessage({
						type: WORKER_MESSAGES.upload_progress,
						payload: { id: uploadingFileId, uploadProgress },
					});
					break;
				default:
					break;
			}
		}
	};

	startImport = async (records) => {
		const token = await this.getOrRenewWorkerAccessToken();
		if (token) {
			this.updateAccessToken(token);
			this.coordinatorWorker.postMessage({
				type: WORKER_MESSAGES.access_token,
				payload: { accessToken: token },
			});
			this.coordinatorWorker.postMessage({ type: WORKER_MESSAGES.start_import, payload: { records } });
		} else {
			console.error('invalid token');
		}
	};

	retryImportFiles = async (fileIds) => {
		const token = await this.getOrRenewWorkerAccessToken();
		if (token) {
			this.updateAccessToken(token);
			this.coordinatorWorker.postMessage({
				type: WORKER_MESSAGES.access_token,
				payload: { accessToken: token },
			});
			this.coordinatorWorker.postMessage({ type: WORKER_MESSAGES.retry_upload, payload: { fileIds } });
		} else {
			console.error('invalid token');
		}
	};

	stopImportFiles = (fileIds) => {
		this.coordinatorWorker.postMessage({ type: WORKER_MESSAGES.stop_import_files, payload: { fileIds } });
	};

	stopImport = () => {
		this.coordinatorWorker.postMessage({ type: WORKER_MESSAGES.stop_import_all });
	};
}
