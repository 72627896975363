// @flow

import {
	AlcoholStatuses,
	AlcoholUseCodes,
	SmokingCodes,
	SmokingStatuses,
} from '@rs-ui/views/PatientInformationView/utils/constants';

export const getSmokingLabelText = (smokingCode: string) => {
	if (!smokingCode) {
		return null;
	}

	if (smokingCode === SmokingCodes.lightSmoker) {
		return SmokingStatuses.light;
	}

	if (smokingCode === SmokingCodes.heavySmoker) {
		return SmokingStatuses.heavy;
	}

	if (smokingCode === SmokingCodes.everydaySmoker) {
		return SmokingStatuses.daily;
	}

	return null;
};

export const getAlcoholLabelText = (alcoholCode: string) => {
	if (!alcoholCode) {
		return null;
	}

	if (alcoholCode === AlcoholUseCodes.social) {
		return AlcoholStatuses.social;
	}

	if (alcoholCode === AlcoholUseCodes.daily) {
		return AlcoholStatuses.daily;
	}

	if (alcoholCode === AlcoholUseCodes.heavyDrinker) {
		return AlcoholStatuses.heavy;
	}

	return null;
};
