import React from 'react';
import _ from 'lodash';

const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
const MS_PER_MINUTES = 60000;
const MS_PER_HOURE = 3600000;
const MS_PER_DAY = 86400000;
const MS_PER_WEEK = 604800000;
const MS_PER_MONTH = 2592000000;
const MS_PER_YEAR = 31536000000;

const relative = {
	relative_Blume: {
		minutes: count => (count > 1 ? 'mins' : 'min'),
		hours: count => (count > 1 ? 'hours' : 'hour'),
		days: count => (count > 1 ? 'days' : 'day'),
		weeks: count => (count > 1 ? 'weeks' : 'week'),
		months: count => (count > 1 ? 'months' : 'month'),
		years: count => (count > 1 ? 'years' : 'year'),
	},
	relative_OmegaAI: {
		minutes: () => 'M',
		hours: () => 'H',
		days: () => 'D',
		weeks: () => 'W',
		months: () => 'M',
		years: () => 'Y',
	},
};

function useDateTimeFormatter(datetime, type, format) {
	let currentDateTime = new Date();
	const getTimein12HourFormat = () => {
		let hours = _.padStart(datetime.getHours() > 12 ? datetime.getHours() - 12 : datetime.getHours(), 2, '0');
		let am_pm = datetime.getHours() >= 12 ? 'PM' : 'AM';
		let minutes = _.padStart(datetime.getMinutes(), 2, '0');

		return `${hours}: ${minutes} ${am_pm}`;
	};
	const getFormattedDateTime = () => {
		let formattedDateTime = '';
		const timeDifference = Math.abs(currentDateTime.getTime() - datetime.getTime());
		if (type === 'relative_Blume' || type === 'relative_OmegaAI') {
			if (timeDifference < MS_PER_DAY && datetime < currentDateTime) {
				let hour = Math.round(timeDifference / MS_PER_HOURE);
				let minutes = Math.round(timeDifference / MS_PER_MINUTES);

				if (hour <= 0 && minutes > 0) {
					formattedDateTime = `${minutes} ${relative[type].minutes(minutes)} ago`;
				} else if (minutes > 60) {
					formattedDateTime = `${hour} ${relative[type].hours(hour)} ago`;
				} else if (minutes < 60) {
					formattedDateTime = 'Just Now';
				}
			} else if (timeDifference < MS_PER_WEEK && datetime < currentDateTime) {
				let noOfDays = Math.round(timeDifference / MS_PER_DAY);
				if (noOfDays == 0) {
					formattedDateTime = 'Today';
				} else if (noOfDays == 1) {
					formattedDateTime = 'Yesterday';
				} else {
					formattedDateTime = `${noOfDays} ${relative[type].days(noOfDays)} ago`;
				}
			} else if (timeDifference < MS_PER_WEEK && datetime > currentDateTime) {
				let noOfDays = Math.round(timeDifference / MS_PER_DAY);
				if (noOfDays == 0) {
					formattedDateTime = 'Today at ' + getTimein12HourFormat();
				} else if (noOfDays == 1) {
					formattedDateTime = 'Tomorrow at ' + getTimein12HourFormat();
				} else {
					formattedDateTime = `in ${noOfDays} ${relative[type].days(noOfDays)}`;
				}
			} else if (timeDifference < MS_PER_MONTH && datetime < currentDateTime) {
				formattedDateTime = `${Math.round(timeDifference / MS_PER_WEEK)} ${relative[type].weeks(
					Math.round(timeDifference / MS_PER_WEEK)
				)} ago`;
			} else if (timeDifference < MS_PER_MONTH && datetime > currentDateTime) {
				formattedDateTime = `in ${Math.round(timeDifference / MS_PER_WEEK)} ${relative[type].weeks(
					Math.round(timeDifference / MS_PER_WEEK)
				)}`;
			} else if (timeDifference < MS_PER_YEAR && datetime < currentDateTime) {
				formattedDateTime = `${Math.round(timeDifference / MS_PER_MONTH)} ${relative[type].months(
					Math.round(timeDifference / MS_PER_MONTH)
				)} ago`;
			} else if (timeDifference < MS_PER_YEAR && datetime > currentDateTime) {
				formattedDateTime = `in ${Math.round(timeDifference / MS_PER_MONTH)} ${relative[type].months(
					Math.round(timeDifference / MS_PER_MONTH)
				)}`;
			} else if (timeDifference > MS_PER_YEAR && datetime > currentDateTime) {
				formattedDateTime = `in ${datetime.getUTCFullYear() - currentDateTime.getUTCFullYear()} ${relative[
					type
				].years()}`;
			} else if (timeDifference > MS_PER_YEAR && datetime < currentDateTime) {
				formattedDateTime = `${currentDateTime.getUTCFullYear() - datetime.getUTCFullYear()} ${relative[
					type
				].years()} ago`;
			}
		} else {
			formattedDateTime =
				format === 'short'
					? weekday[datetime.getDay()].slice(0, 3) +
					  ', ' +
					  months[datetime.getMonth()].slice(0, 3) +
					  ' ' +
					  datetime.getDate()
					: weekday[datetime.getDay()] + ', ' + months[datetime.getMonth()] + ' ' + datetime.getDate();
			if (datetime > currentDateTime) {
				formattedDateTime = formattedDateTime + ' - ' + getTimein12HourFormat();
			}
		}
		return formattedDateTime;
	};
	return getFormattedDateTime();
}

export default useDateTimeFormatter;
