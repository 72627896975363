import React, { createContext, useContext, useState, useRef, useCallback, useMemo } from 'react';
import { STUDY_FILTERS } from '../consts/consts';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { isDvInMultiMonitor } from '../utils';

const DocumentViewerContext = createContext({});

const DocumentViewerContextProvider = ({ children }) => {
	const galaxyDvEnableDvFullScreen = useBooleanFlagValue('galaxy-dv-enable-dv-full-screen');
	const [searchParams] = useSearchParams();
	const screenNumber = searchParams.get('screenNumber');
	const initialShouldExpand = useMemo(() => {
		const isMultiMonitorMode = isDvInMultiMonitor(screenNumber);
		const shouldCollapseInMultiMonitor = galaxyDvEnableDvFullScreen && isMultiMonitorMode;
		// Expand by default, unless in multi-monitor mode with full screen enabled
		return !shouldCollapseInMultiMonitor;
	}, [galaxyDvEnableDvFullScreen, screenNumber]);

	const [viewports, setViewports] = useState([{ contentLoader: true }]);
	const [studyFilter, setStudyFilter] = useState(STUDY_FILTERS.ALL);
	const [historyData, setHistoryData] = useState([]);
	const [isStudyNavigatorExpanded, setIsStudyNavigatorExpanded] = useState(initialShouldExpand);
	const [applyTemplate, setApplyTemplate] = useState(null);
	const [appliedPageSetup, setAppliedPageSetup] = useState(null);
	const [isSignRequestGoing, setIsSignRequestGoing] = useState(false);
	const [selectedDocs, setSelectedDocs] = useState([]);
	const [selectedTemplates, setSelectedTemplates] = useState([]);
	const [sharedUserList, setSharedUserList] = useState([]);
	const [isStartDocumentOpened, setIsStartDocumentOpened] = useState(false);
	const [saveIsProcessing, setSaveIsProcessing] = useState(false);
	const [blankReport, setBlankReport] = useState(null);
	const [isKeyImagesLoading, setIsKeyImagesLoading] = useState(false);
	const [masterOrg, setMasterOrg] = useState(null);
	const [isSubmitAmend, setIsSubmitAmend] = useState(false);
	const [isSavedHeaderFooterDrawerOpen, setIsSavedHeaderFooterDrawerOpen] = useState(true);
	const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
	const [viewerContainerWidth, setViewerContainerWidth] = useState(0);
	const [preliminaryReportZoom, setPreliminaryReportZoom] = useState(0);

	const [templateIconClicked, setTemplateIconClicked] = useState(false);
	const [isDocumentInPopupApplied, setIsDocumentInPopupApplied] = useState(false);
	const saveTimerRef = useRef();

	//For check which history is set on viewport
	const [isAmendmentHistoryDrawer, setIsAmendmentHistoryDrawer] = useState(false);
	const [isAmendmentDrawerSubmitted, setIsAmendmentDrawerSubmitted] = useState(false);

	const [isMoreOptionsShown, setIsMoreOptionsShown] = useState(false);
	const [documentViewerPermissions, setDocumentViewerPermissions] = useState({});

	const [saveIsScheduled, setSaveIsScheduled] = useState(false);

	const debouncedSetSaveIsScheduled = useCallback(
		debounce(bool => setSaveIsScheduled(bool), 1000),
		[setSaveIsScheduled]
	);

	const toggleStudyNavigator = event => {
		event?.stopPropagation();
		setIsStudyNavigatorExpanded(s => !s);
	};

	const toggleSavedHeaderFooterDrawer = useCallback(() => {
		setIsSavedHeaderFooterDrawerOpen(bool => !bool);
	}, []);

	return (
		<DocumentViewerContext.Provider
			value={{
				viewports,
				setViewports,
				studyFilter,
				setStudyFilter,
				historyData,
				setHistoryData,
				isStudyNavigatorExpanded,
				setIsStudyNavigatorExpanded,
				toggleStudyNavigator,
				applyTemplate,
				setApplyTemplate,
				isSignRequestGoing,
				setIsSignRequestGoing,
				selectedDocs,
				setSelectedDocs,
				selectedTemplates,
				setSelectedTemplates,
				sharedUserList,
				setSharedUserList,
				isStartDocumentOpened,
				setIsStartDocumentOpened,
				saveIsProcessing,
				setSaveIsProcessing,
				blankReport,
				setBlankReport,
				isDocumentInPopupApplied,
				setIsDocumentInPopupApplied,
				saveTimerRef,
				isAmendmentHistoryDrawer,
				setIsAmendmentHistoryDrawer,
				isMoreOptionsShown,
				setIsMoreOptionsShown,
				appliedPageSetup,
				setAppliedPageSetup,
				isKeyImagesLoading,
				setIsKeyImagesLoading,
				documentViewerPermissions,
				setDocumentViewerPermissions,
				masterOrg,
				setMasterOrg,
				isSubmitAmend,
				setIsSubmitAmend,
				saveIsScheduled,
				setSaveIsScheduled,
				debouncedSetSaveIsScheduled,
				isSavedHeaderFooterDrawerOpen,
				setIsSavedHeaderFooterDrawerOpen,
				toggleSavedHeaderFooterDrawer,
				isDocumentLoaded,
				setIsDocumentLoaded,
				viewerContainerWidth,
				setViewerContainerWidth,
				preliminaryReportZoom,
				setPreliminaryReportZoom,
				templateIconClicked,
				setTemplateIconClicked,
				isAmendmentDrawerSubmitted,
				setIsAmendmentDrawerSubmitted,
				screenNumber,
			}}
		>
			{children}
		</DocumentViewerContext.Provider>
	);
};

const useDocumentViewerContext = () => useContext(DocumentViewerContext);

export { DocumentViewerContext, useDocumentViewerContext, DocumentViewerContextProvider as default };
