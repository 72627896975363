import { vec3 } from 'gl-matrix';
import { utilities as toolUtilities } from '@cornerstonejs/tools';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';

const GetPositionalIndicator = enabledElement => {
	const { getOrientationStringLPS, invertOrientationStringLPS } = toolUtilities.orientation;
	const { viewport } = enabledElement;
	const imageId = viewport.getCurrentImageId();
	const ImageOrientationPatientTag = '00200037';
	const PatientOrientationTag = '00200020';
	if (!imageId) return null;
	const curMetaData = cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);

	if (curMetaData?.[ImageOrientationPatientTag]?.Value) {
		const { viewUp, viewPlaneNormal } = viewport.getCamera();

		const viewRight = vec3.create();
		vec3.cross(viewRight, viewUp, viewPlaneNormal);

		const columnCosines = [-viewUp[0], -viewUp[1], -viewUp[2]];
		const rowCosines = viewRight;

		if (!rowCosines || !columnCosines) {
			return null;
		}

		const rowString = getOrientationStringLPS(rowCosines);
		const columnString = getOrientationStringLPS(columnCosines);
		const oppositeRowString = invertOrientationStringLPS(rowString);
		const oppositeColumnString = invertOrientationStringLPS(columnString);

		return {
			top: oppositeColumnString,
			left: oppositeRowString,
			right: rowString,
			bottom: columnString,
		};
	}

	const patientOrientationValue = curMetaData?.[PatientOrientationTag]?.Value;
	if (patientOrientationValue && patientOrientationValue.length === 2) {
		const rowString = patientOrientationValue[0];
		const columnString = patientOrientationValue[1];
		const { fixedRowStr, fixedColumnStr } = fixPositionalIndicator(rowString, columnString, viewport);
		const oppositeRowString = invertOrientationStringLPS(fixedRowStr);
		const oppositeColumnString = invertOrientationStringLPS(fixedColumnStr);

		return {
			top: oppositeColumnString,
			left: oppositeRowString,
			right: fixedRowStr,
			bottom: fixedColumnStr,
		};
	}

	return null;
};

export const getPositionalIndicatorV2 = (viewport, metadata) => {
	const { getOrientationStringLPS, invertOrientationStringLPS } = toolUtilities.orientation;
	if (!viewport || !metadata) return;
	const ImageOrientationPatientTag = '00200037';
	const PatientOrientationTag = '00200020';

	if (metadata?.[ImageOrientationPatientTag]?.Value) {
		const { viewUp, viewPlaneNormal } = viewport.getCamera();

		const viewRight = vec3.create();
		vec3.cross(viewRight, viewUp, viewPlaneNormal);

		const columnCosines = [-viewUp[0], -viewUp[1], -viewUp[2]];
		const rowCosines = viewRight;

		if (!rowCosines || !columnCosines) {
			return;
		}

		const rowString = getOrientationStringLPS(rowCosines);
		const columnString = getOrientationStringLPS(columnCosines);
		const oppositeRowString = invertOrientationStringLPS(rowString);
		const oppositeColumnString = invertOrientationStringLPS(columnString);

		return {
			top: oppositeColumnString,
			left: oppositeRowString,
			right: rowString,
			bottom: columnString,
		};
	}

	const patientOrientationValue = metadata?.[PatientOrientationTag]?.Value;
	if (patientOrientationValue && patientOrientationValue.length === 2) {
		const rowString = patientOrientationValue[0];
		const columnString = patientOrientationValue[1];
		const { fixedRowStr, fixedColumnStr } = fixPositionalIndicator(rowString, columnString, viewport);
		const oppositeRowString = invertOrientationStringLPS(fixedRowStr);
		const oppositeColumnString = invertOrientationStringLPS(fixedColumnStr);

		return {
			top: oppositeColumnString,
			left: oppositeRowString,
			right: fixedRowStr,
			bottom: fixedColumnStr,
		};
	}

	return null;
};

const fixPositionalIndicator = (rowString, columnString, viewport) => {
	const { invertOrientationStringLPS } = toolUtilities.orientation;

	let fixedRowStr = rowString,
		fixedColumnStr = columnString;

	try {
		const rotation = viewport.getRotation();
		const { flipHorizontal, flipVertical } = viewport;

		if (flipHorizontal) {
			fixedRowStr = invertOrientationStringLPS(fixedRowStr);
		}

		if (flipVertical) {
			fixedColumnStr = invertOrientationStringLPS(fixedColumnStr);
		}

		if (rotation >= 90 && rotation < 180) {
			const _tempColumnStr = fixedColumnStr;
			fixedColumnStr = fixedRowStr;
			fixedRowStr = invertOrientationStringLPS(_tempColumnStr);
		} else if (rotation >= 180 && rotation < 270) {
			fixedRowStr = invertOrientationStringLPS(fixedRowStr);
			fixedColumnStr = invertOrientationStringLPS(fixedColumnStr);
		} else if (rotation >= 270 && rotation < 360) {
			const _tempRowStr = fixedRowStr;
			fixedRowStr = fixedColumnStr;
			fixedColumnStr = invertOrientationStringLPS(_tempRowStr);
		}
	} catch (error) {
		console.log('fixPositionalIndicator error:', error);
	}

	return { fixedRowStr, fixedColumnStr };
};

export default GetPositionalIndicator;
