import React from 'react';
import { Box } from '@mui/material';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ORIGINAL_THUMBNAIL_SIZE } from '../../consts/dragDropRules';

export const EmptyThumbnail = () => (
	<Box
		style={{
			width: `${ORIGINAL_THUMBNAIL_SIZE}px`,
			height: `${ORIGINAL_THUMBNAIL_SIZE}px`,
			position: 'relative',
			background: 'var(--background)',
		}}
	>
		<NotInterestedIcon
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate3d(-50%, -50%, 0)',
				fontSize: 60,
				color: 'rgba(255,255,255, 0.2)',
				zIndex: 2,
			}}
		/>
	</Box>
);
