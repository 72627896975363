import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import RsGrid from '@rs-ui/components/Grid';
import { searchScopes } from '@worklist-2/core/src/context/consts/searchScopes';
import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';
import { useTranslation } from 'react-i18next';

const EmailLogGrid = ({ className, name, columns, filters, setFilters, sort, setSort }) => {
	const { updateAppMode } = useAppModeContext();
	const { t } = useTranslation('task');

	useEffect(() => {
		updateAppMode('worklist');
	}, []);

	const extraParam = useMemo(
		() => ({
			reasoncode: 'Email',
		}),
		[]
	);

	return (
		<RsGrid
			isSingleSort
			className={classnames(className)}
			columns={columns}
			enableRowClick={false}
			enableRowDnd={false}
			fetchInterval={15000}
			filters={filters}
			name={name}
			scope={searchScopes.task}
			searchExtra={extraParam}
			setFilters={setFilters}
			setSort={setSort}
			sort={sort}
			title={t(name)}
		/>
	);
};

export default EmailLogGrid;
