import { useSearchParams, useNavigate } from 'react-router-dom';
import { useConfig, useCache, logDebug } from '@worklist-2/core/src';
import { getStudyInstanceUID, getStudyDate } from '../utils/utils';
import { useImageViewerStudiesContext } from '../contexts/ImageViewerStudiesContext';
import { SynchronousElasticSearchDicomJsonSyncEvent } from '../consts/customImportHeaders';
import axios from 'axios';
import { useImageViewerPatientContext } from '../contexts/ImageViewerPatientContext';
import loadSeriesWithMetadata from '../api/loadSeriesWithMetadata';
import { useImageViewerView3DContext } from '../contexts/ImageViewerView3DContext';
import { useImageViewerMeasurementContext } from '../contexts/ImageViewerMeasurementContext';
import { useBooleanFlagValue, useNumberFlagValue } from '@rs-core/hooks/useFlags';
import { useLoadStudyData } from './useLoadStudyData';

const MERGED_STUDIES = 'ramsoft-mergedstudyids';
const STUDY_MANAGING_ORGANIZATION_IDS = 'ramsoft-studymanagingorganizationids';
const INTERNAL_STUDY_IDS = 'ramsoft-internalstudyids';
const INTERNAL_PATIENT_IDS = 'ramsoft-internalpatientids';

export const useStudies = () => {
	const __config = useConfig();
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const wonIvDisablexasplit = useBooleanFlagValue('WON-IV-DISABLEXASPLIT');
	const wonIvSeriesSplitterUpperLimit = useNumberFlagValue('won-iv-series-splitter-upper-limit');
	const wonIvEnhancedcurvedsort = useBooleanFlagValue('WON-IV-ENHANCEDCURVEDSORT');
	const wonIvStillsfirst = useBooleanFlagValue('WON-IV-STILLSFIRST');
	const wonIvRefactorsorting = useBooleanFlagValue('WON-IV-REFACTORSORTING');
	const wonMergestudybyidV2 = useBooleanFlagValue('WON-MERGESTUDYBYID-V2');
	const { cacheLinks } = useCache();
	const { loadingManagerRef } = useImageViewerView3DContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const { loadStudiesKeyImages } = useLoadStudyData();
	const navigate = useNavigate();
	const { internalPatientId, patientId, issuerOfPatientID } = useImageViewerPatientContext();
	const { currentStudy, setCurrentStudy, setStudies, studiesRef, seriesRef, setSeries, keyImages, setKeyImages } =
		useImageViewerStudiesContext();
	const { setMeasurementData, measurementData, deleteMeasurementFromAnnotationManager } =
		useImageViewerMeasurementContext();
	const internalManagingOrganizationID = searchParams.get('internalManagingOrganizationID');
	const internalStudyId = searchParams.get('StudyId');
	let studyInstanceUID = searchParams.get('StudyInstanceUIDs');
	const paramPatientID = patientId ?? searchParams.get('PatientID');
	const paramIssuerOfPatientID = issuerOfPatientID ?? searchParams.get('IssuerOfPatientID');
	const paramInternalPatientId = internalPatientId ?? searchParams.get('PatientInfo');
	const isEmergencySearch = searchParams.get('isEmergencySearch') === 'true';

	const loadStudies = async ({
		isCurrentOnly,
		isLinkedPatient,
		inPatientId,
		inIssuerOfPatientID,
		inInternalPatientId,
	}) => {
		try {
			let URL = `${__config.data_sources.dicom_web}/studies?includefield=00080020&includefield=0032000a&includefield=00081032&includefield=00200062&includefield=00200010&includefield=00081030&includefield=00100021`;

			if (isLinkedPatient) {
				URL = `${URL}&internalpatientid=${
					inInternalPatientId ?? paramInternalPatientId
				}&_isLinkedPatient=${isLinkedPatient}`;
			} else if (isCurrentOnly) {
				URL = `${URL}&StudyInstanceUID=${studyInstanceUID}`;
			} else {
				const patientID = inPatientId ?? paramPatientID ?? '';
				URL = `${URL}&patientID=${patientID.replaceAll(',', '\\,')}`;
				inIssuerOfPatientID = inIssuerOfPatientID ?? paramIssuerOfPatientID;
				URL = inIssuerOfPatientID ? `${URL}&IssuerOfPatientID=${inIssuerOfPatientID}` : URL;
			}

			logDebug('IV::loadStudies', 'Studies api - Start', { timestamp: Date.now(), URL });
			const response = await axios.get(URL, {
				headers: {
					'ramsoft-internalmanagingorganizationid': internalManagingOrganizationID,
					'ramsoft-internalstudyid': isCurrentOnly ? internalStudyId : null,
					IsEmergencySearch: isEmergencySearch,
				},
			});
			logDebug('IV::loadStudies', 'Studies api - Returned', { timestamp: Date.now(), URL });

			if (response?.data) {
				let nestedArray = [];
				let organizationIdArry = [];
				let internalStudyIdArry = [];
				let internalPatientIdArry = [];

				if (response.headers) {
					if (response.headers[MERGED_STUDIES]) {
						try {
							nestedArray = JSON.parse(`[${response.headers[MERGED_STUDIES]}]`);
						} catch (error) {}
					}

					if (response.headers[STUDY_MANAGING_ORGANIZATION_IDS]) {
						organizationIdArry = response.headers[STUDY_MANAGING_ORGANIZATION_IDS].split(',');
					}

					if (response.headers[INTERNAL_STUDY_IDS]) {
						internalStudyIdArry = response.headers[INTERNAL_STUDY_IDS].split(',');
					}

					if (response.headers[INTERNAL_PATIENT_IDS]) {
						internalPatientIdArry = response.headers[INTERNAL_PATIENT_IDS].split(',');
					}
				}

				const mapHeaderIds = index => {
					if (nestedArray.length >= index + 1) {
						return nestedArray[index];
					}
					return 0;
				};

				const mapOrgIds = index => {
					if (organizationIdArry.length >= index + 1) {
						return organizationIdArry[index];
					}
					return internalManagingOrganizationID;
				};

				const mapInternalStudyIds = index => {
					if (internalStudyIdArry.length >= index + 1) {
						return internalStudyIdArry[index];
					}
					return null;
				};

				const mapInternalPatientIds = index => {
					if (internalPatientIdArry.length >= index + 1) {
						return internalPatientIdArry[index];
					}
					return null;
				};

				return response.data.map((item, index) => ({
					...item,
					nestedStudyUids: mapHeaderIds(index),
					managingOrganizationId: mapOrgIds(index),
					internalStudyId: mapInternalStudyIds(index),
					internalPatientId: mapInternalPatientIds(index),
				}));
			}
			return [];
		} catch (error) {
			return [];
		}
	};

	const deleteStudies = async (studyId, studyOrganizationId) => {
		try {
			const URL = `${__config.data_sources.dicom_web}/study/${studyId}?InternalOrganizationID=${
				studyOrganizationId ?? internalManagingOrganizationID
			}`;

			await axios.delete(URL);
			if (studyId === getStudyInstanceUID(currentStudy)) {
				navigate('/home');
				return;
			}
			const filterStudies = studiesRef.current.filter(item => studyId !== getStudyInstanceUID(item));
			setStudies(filterStudies);

			const linkedKeyImages = keyImages.filter(
				keyImage =>
					keyImage.StudyInstanceUID == studyId && keyImage.Managingorganizationid == studyOrganizationId
			);
			setKeyImages(prev => prev.filter(keyImage => !linkedKeyImages.includes(keyImage)));

			const linkedMeasurements = measurementData.filter(
				measurement =>
					measurement.studyId == studyId && measurement.managingOrganizationId == studyOrganizationId
			);
			setMeasurementData(prev => prev.filter(measurement => !linkedMeasurements.includes(measurement)));
			linkedMeasurements.forEach(item => deleteMeasurementFromAnnotationManager(item));
		} catch {}
	};

	const mergeStudies = async (targetStudyUid, studyUids, studyOrganizationId, targeStudyId, sourceStudyIds) => {
		try {
			const studyUidsParams = studyUids.toString();

			const URL = !wonMergestudybyidV2
				? `${
						__config.data_sources.dicom_web
				  }/merge/${studyUidsParams}?destStudy=${targetStudyUid}&internalManagingOrganizationId=${
						studyOrganizationId ?? internalManagingOrganizationID
				  }`
				: `${
						__config.data_sources.dicom_web
				  }/mergeById/${sourceStudyIds.toString()}?destStudy=${targeStudyId}&internalManagingOrganizationId=${
						studyOrganizationId ?? internalManagingOrganizationID
				  }`;

			const postResult = await axios.post(URL, null, {
				headers: {
					...SynchronousElasticSearchDicomJsonSyncEvent,
				},
			});

			if (postResult.status !== 200) {
				throw new Error('Failed to merge studies');
			}

			//If current study has been merged, target Study should be the current
			if (studyUids.includes(studyInstanceUID)) {
				studyInstanceUID = targetStudyUid;
				searchParams.set('StudyInstanceUIDs', targetStudyUid);
				setSearchParams(searchParams, { replace: true });
			}

			//Reload studies and linked studies
			const updatedStudies = await loadStudies({}).then(async studies => {
				const linkedStudies = await loadStudies({ isLinkedPatient: true });

				const studiesArr = [...studies, ...linkedStudies];
				studiesArr?.sort((a, b) => new Date(getStudyDate(b)) - new Date(getStudyDate(a)));
				return studiesArr;
			});
			const updatedCurrentStudy = updatedStudies.find(s => getStudyInstanceUID(s) === studyInstanceUID);
			updatedCurrentStudy && setCurrentStudy(updatedCurrentStudy);
			setStudies(updatedStudies);

			// Reload series
			loadingManagerRef.current.studySeries[targetStudyUid] = true;
			const updatedSeries = await loadSeriesWithMetadata({
				__config,
				managingOrganizationId: studyOrganizationId ?? internalManagingOrganizationID,
				studyInstanceUID: targetStudyUid,
				wonIvSeriesSplitter,
				wonIvEnhancedcurvedsort,
				wonIvRefactorsorting,
				cacheLinks,
				wonIvPlaneSplitter,
				wonIvDisablexasplit,
				wonIvSeriesSplitterUpperLimit,
				wonIvStillsfirst,
				is3D: true,
				internalStudyId: targeStudyId,
			});

			const filterSeries = [
				...seriesRef.current.filter(
					item =>
						!(
							[...studyUids, targetStudyUid].includes(item.studyInstanceUID) &&
							studyOrganizationId === item.managingOrganizationId
						)
				),
				...updatedSeries,
			];
			setSeries(filterSeries);

			loadingManagerRef.current.studyKeyImages[targetStudyUid] = false;
			await loadStudiesKeyImages({
				studies: [
					{
						'0020000D': { Value: [targetStudyUid], vr: 'UI' },
						studyInstanceUID: targetStudyUid,
						managingOrganizationId: studyOrganizationId ?? internalManagingOrganizationID,
					},
				],
			});
		} catch (e) {
			console.error('merge', e);
			throw e;
		}
	};

	const splitStudy = async (studyUid, nestedStudyUids, studyOrganizationId, internalStudyId) => {
		const URL = !wonMergestudybyidV2
			? `${__config.data_sources.dicom_web}/unmerge/${studyUid}?internalManagingOrganizationId=${
					studyOrganizationId ?? internalManagingOrganizationID
			  }`
			: `${__config.data_sources.dicom_web}/unmergeById/${internalStudyId}?internalManagingOrganizationId=${
					studyOrganizationId ?? internalManagingOrganizationID
			  }`;

		try {
			const postResult = await axios.post(URL, null, {
				headers: {
					...SynchronousElasticSearchDicomJsonSyncEvent,
				},
			});

			if (postResult.status !== 200) {
				throw new Error('Failed to split studies');
			}

			//Reload studies and linked studies
			const updatedStudies = await loadStudies({}).then(async studies => {
				const linkedStudies = await loadStudies({ isLinkedPatient: true });

				const studiesArr = [...studies, ...linkedStudies];
				studiesArr?.sort((a, b) => new Date(getStudyDate(b)) - new Date(getStudyDate(a)));
				return studiesArr;
			});
			const updatedCurrentStudy = updatedStudies.find(s => getStudyInstanceUID(s) === studyInstanceUID);
			updatedCurrentStudy && setCurrentStudy(updatedCurrentStudy);
			setStudies(updatedStudies);

			// Reload series
			const seriesBatch = await Promise.all(
				[...nestedStudyUids, studyUid].map(async uid => {
					loadingManagerRef.current.studySeries[uid] = true;

					return await loadSeriesWithMetadata({
						__config,
						managingOrganizationId: studyOrganizationId ?? internalManagingOrganizationID,
						studyInstanceUID: uid,
						wonIvSeriesSplitter,
						cacheLinks,
						wonIvPlaneSplitter,
						wonIvDisablexasplit,
						wonIvSeriesSplitterUpperLimit,
						wonIvEnhancedcurvedsort,
						wonIvRefactorsorting,
						wonIvStillsfirst,
						is3D: true,
					});
				})
			);

			const updatedSeries = [];
			seriesBatch.forEach(item => updatedSeries.push(...item));

			const filterSeries = [
				...seriesRef.current.filter(
					item =>
						!(
							[...nestedStudyUids, studyUid].includes(item.studyInstanceUID) &&
							studyOrganizationId === item.managingOrganizationId
						)
				),
				...updatedSeries,
			];
			setSeries(filterSeries);
		} catch (e) {
			console.error('split', e);
			throw e;
		}
	};

	return {
		deleteStudies,
		mergeStudies,
		splitStudy,
		loadStudies,
	};
};
