const updateSynchronizedScreen = ({ synchronizedScreens, updatedScreen }) => {
	synchronizedScreens.current = synchronizedScreens.current.map((synchronizedScreen) => {
		if (synchronizedScreen.screenNumber === updatedScreen.screenNumber) {
			return {
				...synchronizedScreen,
				...updatedScreen,
			};
		}

		return synchronizedScreen;
	});
};

export default updateSynchronizedScreen;
