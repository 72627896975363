export const groupTemplatesWithAllModalityLast = templates => {
	const grouped = templates.reduce(
		(acc, template) => {
			const recordModalities = JSON.parse(JSON.parse(template.template).modality);
			// Distribute templates into 'templateWithModalityAll' or 'others'.
			recordModalities.includes('ALL') ? acc.templateWithModalityAll.push(template) : acc.others.push(template);
			return acc;
		},
		{ templateWithModalityAll: [], others: [] }
	);

	return grouped.others.concat(grouped.templateWithModalityAll);
};
