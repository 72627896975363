import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTabContext, useSearchScope, useAuth, useRouter } from '@worklist-2/core/src';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { HelpPopOverButton, CustomStyledTabs } from '../HelpCenter';
import { boxStyle, Scrollable, ArticlesHeader, Articles } from '../helpCenterStyles';
import { getItemPageUrl, getItemPageNavigation } from '../../../../components/SearchResults/SearchResultItem';
import { LoadingCallout } from '../../../../components/SearchResults/LoadingCallout';

import { getPrimaryText, getSecondaryText } from '../../../../components/SearchResults/searchResultItemTextHelper';
import { NoResultsCallout } from '../../../../components/SearchResults/NoResultsCallout';

const ArticlesSearchResult = () => {
	const { goTo } = useRouter();
	const __searchScopeContext = useSearchScope();
	const { searchData } = useTabContext();
	const [searchText, setSearchText] = useState('');
	const [displayData, setDisplayData] = useState([]);
	const { t } = useTranslation('helpCenter');
	const { selectedLanguage } = useAuth();

	const onGoToClick = article => {
		const url = getItemPageUrl(article, null, true, selectedLanguage);
		if (url) goTo.any(url);
	};

	const getNavigation = article => {
		const nav = getItemPageNavigation(article, true);
		return nav || '';
	};

	useEffect(() => {
		localStorage.setItem('markdownPath', 'articles');
		const keyword = document.getElementById('top-search')?.value;

		if (keyword) {
			setSearchText(keyword);
		}

		setDisplayData(searchData.filter(item => (item.resourceType ?? item.ResourceType) === 'helpcenter'));
	}, [searchData]);

	return (
		<Box sx={boxStyle}>
			<Box sx={{ bgcolor: '#2f2f2f' }}>
				<CustomStyledTabs tabs={['Search Results']} value={0} />
			</Box>
			<Scrollable className="content-container" style={{ padding: '20px 70px' }}>
				{__searchScopeContext.loading ? (
					<LoadingCallout />
				) : displayData?.length > 0 ? (
					<Box>
						<HelpPopOverButton />
						<Box>
							<ArticlesHeader id="ArticlesSearchResultResultsHeader">
								{displayData?.length || 0} {t('entries found for')} “{searchText || ''}”
							</ArticlesHeader>
							<Articles>
								{_.map(displayData, (entry, i) => (
									<div className="articleEntry">
										<span
											dangerouslySetInnerHTML={{
												__html: getPrimaryText(entry, null, true) || '',
											}}
											className="articles-primary"
											data-testid="ArticlesSearchResultEntry"
											underline="none"
											onClick={() => onGoToClick(entry)}
										/>
										<span className="navigation">{getNavigation(entry)}</span>
										<span className="content">{getSecondaryText(entry, null, true) || ''}</span>
									</div>
								))}
							</Articles>
						</Box>
					</Box>
				) : (
					<NoResultsCallout />
				)}
			</Scrollable>
		</Box>
	);
};

export default ArticlesSearchResult;
