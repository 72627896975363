import { getStudyInstanceUID } from '../utils/utils';
import { useImageViewerMeasurementContext } from '../contexts/ImageViewerMeasurementContext';
import loadSeriesWithMetadata from '../api/loadSeriesWithMetadata';
import { useCache, useConfig, logDebug, logError } from '@worklist-2/core/src';
import { useImageViewerStudiesContext } from '../contexts/ImageViewerStudiesContext';
import { getKeyImages } from '../api/keyImage';
import { useImageViewerView3DContext } from '../contexts/ImageViewerView3DContext';
import { BROADCAST_EVENTS } from '../contexts/consts/consts';
import { useImageViewerMultiMonitorContext } from '../contexts/ImageViewerMultiMonitorContext';
import { useSearchParams } from 'react-router-dom';
import { useBooleanFlagValue, useNumberFlagValue } from '@rs-core/hooks/useFlags';

export const useLoadStudyData = () => {
	const __config = useConfig();
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const wonIvDisablexasplit = useBooleanFlagValue('WON-IV-DISABLEXASPLIT');
	const wonIvSeriesSplitterUpperLimit = useNumberFlagValue('won-iv-series-splitter-upper-limit');
	const wonIvEnhancedcurvedsort = useBooleanFlagValue('WON-IV-ENHANCEDCURVEDSORT');
	const wonIvStillsfirst = useBooleanFlagValue('WON-IV-STILLSFIRST');
	const wonIvRefactorsorting = useBooleanFlagValue('WON-IV-REFACTORSORTING');
	const { cacheLinks } = useCache();

	const { loadingManagerRef } = useImageViewerView3DContext();
	const { addNewSeries, onSetKeyImages } = useImageViewerStudiesContext();
	const { measurementLoader, loadMeasurements } = useImageViewerMeasurementContext();
	const { postMessage } = useImageViewerMultiMonitorContext();

	const [searchParams] = useSearchParams();
	const creatorUserId = searchParams.get('creatorBlumeId') || null;

	const loadStudiesSeries = async ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				internalStudyId: study.internalStudyId,
				managingOrganizationId: study.managingOrganizationId,
			}));

		const promises = studiesObjArr
			.filter(study => !loadingManagerRef.current.studySeries[study.studyInstanceUID])
			.map(async study => {
				loadingManagerRef.current.studySeries[study.studyInstanceUID] = true;

				try {
					const loadedSeries = await loadSeriesWithMetadata({
						__config,
						managingOrganizationId: study.managingOrganizationId,
						studyInstanceUID: study.studyInstanceUID,
						wonIvSeriesSplitter,
						wonIvDisablexasplit,
						wonIvSeriesSplitterUpperLimit,
						wonIvEnhancedcurvedsort,
						wonIvRefactorsorting,
						wonIvStillsfirst,
						cacheLinks,
						wonIvPlaneSplitter,
						is3D: true,
						creatorUserId,
						internalStudyId: study.internalStudyId,
					});
					return loadedSeries;
				} catch (error) {
					logError('IV::', 'Error loading series for study', {
						studyInstanceUID: study.studyInstanceUID,
						error,
					});
					loadingManagerRef.current.studySeries[study.studyInstanceUID] = false;
					return []; // Return an empty array on error to avoid breaking Promise.all
				}
			});

		const seriesBatch = await Promise.all(promises);
		const series = [];

		seriesBatch.forEach(item => series.push(...item));

		if (!series.length) {
			return;
		}

		logDebug('IV::', `Total Series Loaded`, { num: series.length });

		addNewSeries(series);
		postMessage({
			event: BROADCAST_EVENTS.STUDY_SERIES_UPDATE,
			value: series,
		});
	};

	const loadStudiesMeasurements = ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				managingOrganizationId: study.managingOrganizationId,
			}));
		studiesObjArr
			.filter(study => !loadingManagerRef.current.studyMeasurements[study.studyInstanceUID])
			.forEach(study => {
				loadingManagerRef.current.studyMeasurements[study.studyInstanceUID] = true;

				loadMeasurements(measurementLoader, {
					StudyInstanceUID: study.studyInstanceUID,
					InternalManagingOrgId: study.managingOrganizationId,
				});
			});
	};

	const loadStudiesKeyImages = async ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				managingOrganizationId: study.managingOrganizationId,
			}));
		const promises = studiesObjArr
			.filter(study => !loadingManagerRef.current.studyKeyImages[study.studyInstanceUID])
			.map(async study => {
				loadingManagerRef.current.studyKeyImages[study.studyInstanceUID] = true;

				return getKeyImages({
					__config,
					studyInstanceUID: study.studyInstanceUID,
					internalManagingId: study.managingOrganizationId,
				});
			});

		const keyImages = [];
		const keyImagesBatch = await Promise.all(promises);

		keyImagesBatch.forEach(item => keyImages.push(...item));

		if (!keyImages.length) {
			return;
		}

		await onSetKeyImages(prevState => [...prevState, ...keyImages]);
	};

	const loadStudiesData = async ({ studies, studiesObjArr }) => {
		await loadStudiesSeries({ studies, studiesObjArr });
		loadStudiesMeasurements({ studies, studiesObjArr });
		await loadStudiesKeyImages({ studies, studiesObjArr });
	};

	return {
		loadStudiesData,
		loadStudiesSeries,
		loadStudiesMeasurements,
		loadStudiesKeyImages,
	};
};

export default useLoadStudyData;
