import { v4 as uuid } from 'uuid';

const createViewport = (options = {}) => ({
	documentId: options.documentId,
	documentResourceType: options.documentResourceType,
	isReadonly: false,
	...options,
	id: uuid(),
});

export default createViewport;
