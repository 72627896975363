import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import React, { useState } from 'react';
import { DrawerContent, DrawerHeader } from '../../../../components/Help/helpStyles';
import Collapse from '@mui/material/Collapse';
import screen_share_1 from '../../../../assets/img/helpAssets/screen-share1.png';
import screen_share_2 from '../../../../assets/img/helpAssets/screen-share2.png';
import landing_page from '../../../../assets/img/helpAssets/landing_page1.png';
import { useTranslation } from 'react-i18next';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ScreenShare = ({ show, dismiss }) => {
	const { t } = useTranslation('helpCenter');
	const [showMore, setShowMore] = useState(false);

	const windows = [
		{ name: 'Screen 1', url: screen_share_1 },
		{ name: 'Screen 2', url: screen_share_2 },
		{ name: 'Screen 3', url: landing_page },
		{ name: 'Screen 4', url: screen_share_1 },
		{ name: 'Screen 5', url: screen_share_2 },
	];

	return (
		<Drawer PaperProps={{ sx: { background: 'transparent' } }} anchor="bottom" open={show}>
			<Paper
				style={{
					backgroundColor: '#1c1c1c',
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					flexDirection: 'column',
					display: 'flex',
					padding: '20px',
					overflowX: 'hidden',
				}}
			>
				<DrawerHeader>
					<div className="left">
						<Switch {...label} defaultChecked />
						<span className="label"> {t('Include computer sound')} </span>{' '}
					</div>{' '}
					<div className="line" onClick={() => setShowMore(!showMore)} />{' '}
					<IconButton onClick={dismiss}>
						<CloseIcon style={{ color: '#FFF' }} />{' '}
					</IconButton>{' '}
				</DrawerHeader>{' '}
				<DrawerContent>
					<div className="left">
						<h5> {t('Desktop')} </h5> <img src={windows[2].url} /> <p> {t('Your Screen')} </p>{' '}
					</div>{' '}
					<div className="right">
						<h5> {t('Window')} </h5>{' '}
						<div className="row">
							{' '}
							{windows.slice(0, 3).map((screen, i) => (
								<div key={i} className="screen" style={{ marginLeft: 10, marginRight: 10 }}>
									<img src={screen.url} /> <p> {screen.name} </p>{' '}
								</div>
							))}{' '}
						</div>{' '}
						<Collapse in={showMore}>
							<div className="row">
								{' '}
								{windows.slice(3, windows.length).map((screen, i) => (
									<div
										key={i}
										className="screen"
										style={{
											marginLeft: 10,
											marginRight: 10,
										}}
									>
										<img src={screen.url} /> <p> {screen.name} </p>{' '}
									</div>
								))}{' '}
							</div>{' '}
						</Collapse>{' '}
					</div>{' '}
				</DrawerContent>{' '}
			</Paper>{' '}
		</Drawer>
	);
};

export default ScreenShare;
