import axios from 'axios';
import handleApiResponse from '@worklist-2/worklist/src/DocumentViewerV3/api/utils';

const updateGlobalNote = async ({ __config, params, payload }) => {
	let response = await axios.put(`${__config.data_sources.dicom_web}/StudyResource?${params.toString()}`, payload);
	response = handleApiResponse(response);
	return response ? response.data : [];
};

export default updateGlobalNote;
