import axios from 'axios';
import handleApiResponse from './utils';
import { Base64 } from 'js-base64';

const getBestMatchTemplateWithContent = async (_config, internalManagingOrganizationID, payload) => {
	let response = await axios.get(
		`${
			_config.data_sources.fhir
		}/Organization/${internalManagingOrganizationID}/bestmatchtemplate/Content?_count=1&criteria=${
			payload.criteria
		}&page=1&_dc=${new Date().getTime()}`
	);
	let bestMatchTemplate = [];

	response = handleApiResponse(response);
	if (response?.headers?.['x-template-metadata']) {
		const templateHtml = response?.data;
		const templateMetadata = JSON.parse(Base64.decode(response?.headers?.['x-template-metadata']));

		bestMatchTemplate = [
			{
				...templateMetadata,
				template: JSON.stringify({ ...JSON.parse(templateMetadata?.template), htmlData: templateHtml }),
			},
		];
	}

	return bestMatchTemplate;
};

export default getBestMatchTemplateWithContent;
