export const drinkingStatus = {
	code: '70200004',
};

export const smokingStatus = {
	code: '428071000124103',
};

export const vitalObservationInfo = {
	bmi: '',
	bmiValue: '22',
	diastolicBlood: '120',
	heartRate: '66',
	height: '180',
	systolicBlood: '90',
	weight: '91',
};

export const mockPatientObservationData = {
	resourceType: 'Bundle',
	type: 'searchset',
	total: 6,
	entry: [
		{
			fullUrl: '158c2ae8-2f60-4d9f-8615-80385acd0824',
			resource: {
				resourceType: 'Observation',
				id: '2683136',
				meta: {
					extension: [
						{
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateUser',
							valueReference: {
								id: '631217',
								reference: 'practitioner/631217',
								display: 'LINGAM^SAKTHISIVAPRAKASH',
							},
						},
					],
					versionId: '343934706',
					lastUpdated: '2024-07-11T13:04:36.7524312+00:00',
				},
				extension: [
					{
						url: 'EffectiveUTC',
						valueDateTime: '2024-06-26T23:22:08+00:00',
					},
					{
						url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
						valueString: 'Central Standard Time',
					},
				],
				status: 'final',
				category: [
					{
						coding: [
							{
								system: 'http://terminology.hl7.org/CodeSystem/observation-category',
								code: 'social-history',
								display: 'Social History',
							},
						],
						text: 'Social History',
					},
				],
				code: {
					coding: [
						{
							system: 'http://loinc.org',
							code: '72166-2',
							display: 'Tobacco smoking status',
						},
					],
					text: 'Tobacco smoking status',
				},
				subject: {
					id: '5427543',
					reference: 'Patient/5427543',
					display: 'PRAKASH^SAKTHISIVA',
				},
				effectiveDateTime: '2024-06-26T18:22:08.000000+00:00',
				issued: '2024-06-26T18:22:08+00:00',
				valueCodeableConcept: {
					coding: [
						{
							system: 'http://snomed.info/sct',
							code: '428061000124105',
							display: 'Light Smoker',
						},
					],
					text: 'Light Smoker',
				},
			},
			search: {
				mode: 'match',
			},
		},
		{
			fullUrl: '5aa974d7-1c68-4c51-af8b-379afc8680d8',
			resource: {
				resourceType: 'Observation',
				id: '2683135',
				meta: {
					extension: [
						{
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateUser',
							valueReference: {
								id: '631217',
								reference: 'practitioner/631217',
								display: 'LINGAM^SAKTHISIVAPRAKASH',
							},
						},
					],
					versionId: '343934703',
					lastUpdated: '2024-07-11T13:04:36.7524312+00:00',
				},
				extension: [
					{
						url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
						valueString: 'Central Standard Time',
					},
				],
				status: 'final',
				code: {
					coding: [
						{
							system: 'http://loinc.org',
							code: '69643-7',
							display: 'Alcohol use',
						},
					],
					text: 'Alcohol use',
				},
				subject: {
					id: '5427543',
					reference: 'Patient/5427543',
					display: 'PRAKASH^SAKTHISIVA',
				},
				issued: '2024-06-26T18:22:08+00:00',
				valueCodeableConcept: {
					coding: [
						{
							system: 'http://snomed.info/sct',
							code: '160443006',
							display: 'Non-drinker',
						},
					],
					text: 'Non-drinker',
				},
			},
			search: {
				mode: 'match',
			},
		},
		{
			fullUrl: '9d1475c3-23d7-42d5-aca6-95c1a10dfd2e',
			resource: {
				resourceType: 'Observation',
				id: '2683134',
				meta: {
					extension: [
						{
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateUser',
							valueReference: {
								id: '631217',
								reference: 'practitioner/631217',
								display: 'LINGAM^SAKTHISIVAPRAKASH',
							},
						},
					],
					versionId: '337127195',
					lastUpdated: '2024-06-26T18:22:09.1398628+00:00',
				},
				extension: [
					{
						url: 'EffectiveUTC',
						valueDateTime: '2024-06-26T22:22:08+00:00',
					},
					{
						url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
						valueString: 'Central Standard Time',
					},
				],
				status: 'final',
				category: [
					{
						coding: [
							{
								system: 'http://terminology.hl7.org/CodeSystem/observation-category',
								code: 'vital-signs',
								display: 'Vital Signs',
							},
						],
						text: 'Vital Signs',
					},
				],
				code: {
					coding: [
						{
							system: 'http://loinc.org',
							code: '85354-9',
							display: 'Blood pressure',
						},
					],
					text: 'Blood pressure systolic & diastolic',
				},
				subject: {
					id: '5427543',
					reference: 'Patient/5427543',
					display: 'PRAKASH^SAKTHISIVA',
				},
				effectiveDateTime: '2024-06-26T18:22:08.000000+00:00',
				issued: '2024-06-26T18:22:08+00:00',
				component: [
					{
						code: {
							coding: [
								{
									system: 'http://loinc.org',
									code: '8480-6',
									display: 'Systolic blood pressure',
								},
							],
						},
						valueQuantity: {
							value: 120,
							unit: 'mmHg',
							system: 'http://unitsofmeasure.org',
							code: 'mm[Hg]',
						},
					},
					{
						code: {
							coding: [
								{
									system: 'http://loinc.org',
									code: '8462-4',
									display: 'Diastolic blood pressure',
								},
							],
						},
						valueQuantity: {
							value: 70,
							unit: 'mmHg',
							system: 'http://unitsofmeasure.org',
							code: 'mm[Hg]',
						},
					},
				],
			},
			search: {
				mode: 'match',
			},
		},
		{
			fullUrl: '1cf26538-4ad3-4511-9ff5-5d15a66baa90',
			resource: {
				resourceType: 'Observation',
				id: '2683133',
				meta: {
					extension: [
						{
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateUser',
							valueReference: {
								id: '631217',
								reference: 'practitioner/631217',
								display: 'LINGAM^SAKTHISIVAPRAKASH',
							},
						},
					],
					versionId: '337127190',
					lastUpdated: '2024-06-26T18:22:09.1398628+00:00',
				},
				extension: [
					{
						url: 'EffectiveUTC',
						valueDateTime: '2024-06-26T22:22:08+00:00',
					},
					{
						url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
						valueString: 'Central Standard Time',
					},
				],
				status: 'final',
				category: [
					{
						coding: [
							{
								system: 'http://terminology.hl7.org/CodeSystem/observation-category',
								code: 'vital-signs',
								display: 'Vital Signs',
							},
						],
						text: 'Vital Signs',
					},
				],
				code: {
					coding: [
						{
							system: 'http://loinc.org',
							code: '8867-4',
							display: 'Heart rate',
						},
					],
					text: 'Heart rate',
				},
				subject: {
					id: '5427543',
					reference: 'Patient/5427543',
					display: 'PRAKASH^SAKTHISIVA',
				},
				effectiveDateTime: '2024-06-26T18:22:08.000000+00:00',
				issued: '2024-06-26T18:22:08+00:00',
				valueQuantity: {
					value: 90,
					unit: 'beats/minute',
					system: 'http://unitsofmeasure.org',
					code: '/min',
				},
			},
			search: {
				mode: 'match',
			},
		},
		{
			fullUrl: 'e5409d4e-0795-493f-b925-cf80fd67f161',
			resource: {
				resourceType: 'Observation',
				id: '2673134',
				meta: {
					extension: [
						{
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateDevice',
							valueReference: {
								id: '676533',
								reference: 'device/676533',
								display: 'pre-us01-AZURE-FUNCTION-676533-RAMSOFT',
							},
						},
					],
					versionId: '337121648',
					lastUpdated: '2024-06-26T18:08:11.434578+00:00',
				},
				extension: [
					{
						url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
						valueString: 'Central Standard Time',
					},
				],
				status: 'final',
				category: [
					{
						coding: [
							{
								system: 'http://terminology.hl7.org/CodeSystem/observation-category',
								code: 'vital-signs',
								display: 'Vital Signs',
							},
						],
						text: 'Vital Signs',
					},
				],
				code: {
					coding: [
						{
							system: 'http://loinc.org',
							code: '8302-2',
							display: 'Body height',
						},
					],
					text: 'Body height',
				},
				subject: {
					id: '5427543',
					reference: 'Patient/5427543',
					display: 'PRAKASH^SAKTHISIVA',
				},
				valueQuantity: {
					value: 178,
					unit: 'cm',
					system: 'http://unitsofmeasure.org',
					code: 'cm',
				},
			},
			search: {
				mode: 'match',
			},
		},
		{
			fullUrl: 'bf241ad9-e3a1-493a-b7f0-dcb61b4853a8',
			resource: {
				resourceType: 'Observation',
				id: '2673133',
				meta: {
					extension: [
						{
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateDevice',
							valueReference: {
								id: '676533',
								reference: 'device/676533',
								display: 'pre-us01-AZURE-FUNCTION-676533-RAMSOFT',
							},
						},
					],
					versionId: '337121651',
					lastUpdated: '2024-06-26T18:08:11.5595713+00:00',
				},
				extension: [
					{
						url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
						valueString: 'Central Standard Time',
					},
				],
				status: 'final',
				category: [
					{
						coding: [
							{
								system: 'http://terminology.hl7.org/CodeSystem/observation-category',
								code: 'vital-signs',
								display: 'Vital Signs',
							},
						],
						text: 'Vital Signs',
					},
				],
				code: {
					coding: [
						{
							system: 'http://loinc.org',
							code: '29463-7',
							display: 'Body Weight',
						},
					],
					text: 'Body Weight',
				},
				subject: {
					id: '5427543',
					reference: 'Patient/5427543',
					display: 'PRAKASH^SAKTHISIVA',
				},
				valueQuantity: {
					value: 102,
					unit: 'kg',
					system: 'http://unitsofmeasure.org',
					code: 'kg',
				},
			},
			search: {
				mode: 'match',
			},
		},
	],
};
