import axios from 'axios';

const saveWheelConfig = async ({ __config, wheelConfig }) => {
	axios.post(`${__config.data_sources.fhir}/config`, {
		item: 'ImageViewer3DWheelSetting',
		valueType: 1,
		value: JSON.stringify({
			...wheelConfig,
			version: 1,
		}),
	});
};

export default saveWheelConfig;
