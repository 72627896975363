import { utilities as toolUtilities } from '@cornerstonejs/tools';

function GetCalculatedSliceLocationString(instance, currentSliceIndex) {
	const { getOrientationStringLPS, invertOrientationStringLPS } = toolUtilities.orientation;
	const numberOfFrames = parseInt(instance?.['00280008']?.Value?.[0]);

	if (numberOfFrames != instance?.['52009230']?.Value?.length) {
		return null;
	}

	const currentImagePositionPatientValue = getImagePositionPatientValue(instance, currentSliceIndex);
	const currentImageOrientationPatientValue = getImageOrientationPatientValue(instance, currentSliceIndex);
	const lastImagePositionPatientValue = getImagePositionPatientValue(instance, numberOfFrames - 1);
	const lastImageOrientationPatientValue = getImageOrientationPatientValue(instance, numberOfFrames - 1);

	if (currentImagePositionPatientValue && currentImageOrientationPatientValue) {
		const {
			slicePositionProjection: currentSliceLocation,
			imageOrientationPatientNormal: currentImageOrientationPatientNormal,
		} = CalculateSliceLocation(currentImagePositionPatientValue, currentImageOrientationPatientValue);

		const {
			slicePositionProjection: lastSliceLocation,
			imageOrientationPatientNormal: lastImageOrientationPatientNormal,
		} = CalculateSliceLocation(lastImagePositionPatientValue, lastImageOrientationPatientValue);

		const midSliceLocation = lastSliceLocation / 2;

		const calculatedSliceLoacation = roundToTwoDecimals(currentSliceLocation - midSliceLocation);

		const orientationString = getOrientationStringLPS(lastImageOrientationPatientNormal);

		// if SliceLocation < 0, that means in opposite direction to the ImageOrientationPatientNormal vector.
		const sliceLocationDPirectionLabel =
			lastSliceLocation >= 0
				? getSliceDirectionLabel(orientationString)
				: getSliceDirectionLabel(invertOrientationStringLPS(orientationString));
		const sliceLocationOppositeDirectionLabel =
			lastSliceLocation < 0
				? getSliceDirectionLabel(orientationString)
				: getSliceDirectionLabel(invertOrientationStringLPS(orientationString));

		if (calculatedSliceLoacation < 0) {
			return sliceLocationDPirectionLabel + ' ' + Math.abs(calculatedSliceLoacation);
		}

		if (calculatedSliceLoacation > 0) {
			return sliceLocationOppositeDirectionLabel + ' ' + calculatedSliceLoacation;
		}

		if (calculatedSliceLoacation === 0) {
			return '0';
		}
	}

	return null;
}

function getImagePositionPatientValue(instance, index) {
	return instance?.['52009230']?.Value[index]?.['00209113']?.Value?.[0]?.['00200032']?.Value;
}

function getImageOrientationPatientValue(instance, index) {
	return (
		instance?.['52009230']?.Value?.[index]?.['00209116']?.Value?.[0]?.['00200037']?.Value ||
		instance?.['52009229']?.Value?.[0]?.['00209116']?.Value?.[0]?.['00200037']?.Value ||
		instance?.['00200037']?.Value
	);
}

function CalculateSliceLocation(imagePositionPatientValue, imageOrientationPatientValue) {
	if (imagePositionPatientValue?.length === 3 && imageOrientationPatientValue?.length === 6) {
		const imageOrientationPatientNormal = GetImageOrientationPatientNormal(imageOrientationPatientValue);

		const imagePositionPatientResult = GetValidImagePositionPatient(
			imageOrientationPatientValue,
			imageOrientationPatientNormal,
			imagePositionPatientValue
		);

		// get slice position projection on normal vector
		const slicePositionProjection = projectVector(imagePositionPatientResult, imageOrientationPatientNormal);
		return {
			slicePositionProjection,
			imageOrientationPatientNormal,
			validImagePositionPatient: imagePositionPatientResult,
		};
	}

	return null;
}

const roundToTwoDecimals = num => {
	return Math.round(num * 100) / 100;
};

function GetImageOrientationPatientNormal(imageOrientationPatientValue) {
	let imageOrientationPatientNormal = new Array(3);

	if (imageOrientationPatientValue.length == 6) {
		// Compute normal vector to row and column (cross product of row and column unit vectors)
		imageOrientationPatientNormal = [
			// compute nrm to row and col (i.e. cross product of row and col
			// unit vectors)
			// nrm_dircox_x = row_dircos_y * col_dircos_z
			//   - row_dircos_z * col_dircos_y
			imageOrientationPatientValue[1] * imageOrientationPatientValue[5] -
				imageOrientationPatientValue[2] * imageOrientationPatientValue[4],
			//nrm_dircos_y = row_dircos_z * col_dircos_x - row_dircos_x *
			//  col_dircos_z;
			imageOrientationPatientValue[2] * imageOrientationPatientValue[3] -
				imageOrientationPatientValue[0] * imageOrientationPatientValue[5],
			//nrm_dircos_z = row_dircos_x * col_dircos_y - row_dircos_y *
			//  col_dircos_x;
			imageOrientationPatientValue[0] * imageOrientationPatientValue[4] -
				imageOrientationPatientValue[1] * imageOrientationPatientValue[3],
		];

		return imageOrientationPatientNormal;
	}

	return null;
}

function GetValidImagePositionPatient(
	imageOrientationPatientValue,
	imageOrientationPatientNormal,
	imagePositionPatientValue,
	unitVectorTol = 0.1
) {
	// check are unit vectors ...
	if (
		Math.abs(
			Math.pow(imageOrientationPatientValue[0], 2) +
				Math.pow(imageOrientationPatientValue[1], 2) +
				Math.pow(imageOrientationPatientValue[2], 2) -
				1.0
		) < unitVectorTol &&
		Math.abs(
			Math.pow(imageOrientationPatientValue[3], 2) +
				Math.pow(imageOrientationPatientValue[4], 2) +
				Math.pow(imageOrientationPatientValue[5], 2) -
				1.0
		) < unitVectorTol &&
		Math.abs(
			Math.pow(imageOrientationPatientNormal[0], 2) +
				Math.pow(imageOrientationPatientNormal[1], 2) +
				Math.pow(imageOrientationPatientNormal[2], 2) -
				1.0
		) < unitVectorTol
	) {
		// check are orthogonal (dot product is zero, i.e. cos 90)
		if (
			Math.abs(
				imageOrientationPatientValue[0] * imageOrientationPatientValue[3] +
					imageOrientationPatientValue[1] * imageOrientationPatientValue[4] +
					imageOrientationPatientValue[2] * imageOrientationPatientValue[5]
			) < 0.1 &&
			Math.abs(
				imageOrientationPatientValue[0] * imageOrientationPatientNormal[0] +
					imageOrientationPatientValue[1] * imageOrientationPatientNormal[1] +
					imageOrientationPatientValue[2] * imageOrientationPatientNormal[2]
			) < 0.1 &&
			Math.abs(
				imageOrientationPatientValue[3] * imageOrientationPatientNormal[0] +
					imageOrientationPatientValue[4] * imageOrientationPatientNormal[1] +
					imageOrientationPatientValue[5] * imageOrientationPatientNormal[2]
			) < 0.1
		) {
			return imagePositionPatientValue;
		}
	}

	return [0, 0, 0];
}

function projectVector(u, v) {
	const magV = Math.sqrt(Math.pow(v[0], 2) + Math.pow(v[1], 2) + Math.pow(v[2], 2));

	if (magV === 0) {
		return 0;
	} else {
		return dotProduct(u, v) / magV;
	}
}

function dotProduct(u, v) {
	return u[0] * v[0] + u[1] * v[1] + u[2] * v[2];
}

// We must display M or L instead of LH and RF.
// Medial is toward the middle of the body and Lateral is away from the body.
function getSliceDirectionLabel(calculatedSliceDirectionLabel) {
	const HEAD_LABELS = ['RH', 'HR', 'LH', 'HL'];
	const FOOT_LABELS = ['RF', 'FR', 'LF', 'FL'];

	if (HEAD_LABELS.includes(calculatedSliceDirectionLabel)) {
		return 'M'; // Medial
	} else if (FOOT_LABELS.includes(calculatedSliceDirectionLabel)) {
		return 'L'; // Lateral
	} else {
		return calculatedSliceDirectionLabel;
	}
}

export { CalculateSliceLocation };
export default GetCalculatedSliceLocationString;
