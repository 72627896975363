export default [
	{
		label: 'AffectedSOPClassUID (00000002)',
		code: 'DICOMObject["00000002"]',
	},
	{
		label: 'RequestedSOPClassUID (00000003)',
		code: 'DICOMObject["00000003"]',
	},
	{
		label: 'CommandField (00000100)',
		code: 'DICOMObject["00000100"]',
	},
	{
		label: 'MessageID (00000110)',
		code: 'DICOMObject["00000110"]',
	},
	{
		label: 'MessageIDBeingRespondedTo (00000120)',
		code: 'DICOMObject["00000120"]',
	},
	{
		label: 'MoveDestination (00000600)',
		code: 'DICOMObject["00000600"]',
	},
	{
		label: 'Priority (00000700)',
		code: 'DICOMObject["00000700"]',
	},
	{
		label: 'CommandDataSetType (00000800)',
		code: 'DICOMObject["00000800"]',
	},
	{
		label: 'Status (00000900)',
		code: 'DICOMObject["00000900"]',
	},
	{
		label: 'OffendingElement (00000901)',
		code: 'DICOMObject["00000901"]',
	},
	{
		label: 'ErrorComment (00000902)',
		code: 'DICOMObject["00000902"]',
	},
	{
		label: 'ErrorID (00000903)',
		code: 'DICOMObject["00000903"]',
	},
	{
		label: 'AffectedSOPInstanceUID (00001000)',
		code: 'DICOMObject["00001000"]',
	},
	{
		label: 'RequestedSOPInstanceUID (00001001)',
		code: 'DICOMObject["00001001"]',
	},
	{
		label: 'EventTypeID (00001002)',
		code: 'DICOMObject["00001002"]',
	},
	{
		label: 'AttributeIdentifierList (00001005)',
		code: 'DICOMObject["00001005"]',
	},
	{
		label: 'ActionTypeID (00001008)',
		code: 'DICOMObject["00001008"]',
	},
	{
		label: 'NumberOfRemainingSuboperations (00001020)',
		code: 'DICOMObject["00001020"]',
	},
	{
		label: 'NumberOfCompletedSuboperations (00001021)',
		code: 'DICOMObject["00001021"]',
	},
	{
		label: 'NumberOfFailedSuboperations (00001022)',
		code: 'DICOMObject["00001022"]',
	},
	{
		label: 'NumberOfWarningSuboperations (00001023)',
		code: 'DICOMObject["00001023"]',
	},
	{
		label: 'MoveOriginatorApplicationEntityTitle (00001030)',
		code: 'DICOMObject["00001030"]',
	},
	{
		label: 'MoveOriginatorMessageID (00001031)',
		code: 'DICOMObject["00001031"]',
	},
	{
		label: 'FileMetaInformationGroupLength (00020000)',
		code: 'DICOMObject["00020000"]',
	},
	{
		label: 'FileMetaInformationVersion (00020001)',
		code: 'DICOMObject["00020001"]',
	},
	{
		label: 'MediaStorageSOPClassUID (00020002)',
		code: 'DICOMObject["00020002"]',
	},
	{
		label: 'MediaStorageSOPInstanceUID (00020003)',
		code: 'DICOMObject["00020003"]',
	},
	{
		label: 'TransferSyntaxUID (00020010)',
		code: 'DICOMObject["00020010"]',
	},
	{
		label: 'ImplementationClassUID (00020012)',
		code: 'DICOMObject["00020012"]',
	},
	{
		label: 'ImplementationVersionName (00020013)',
		code: 'DICOMObject["00020013"]',
	},
	{
		label: 'SourceApplicationEntityTitle (00020016)',
		code: 'DICOMObject["00020016"]',
	},
	{
		label: 'SendingApplicationEntityTitle (00020017)',
		code: 'DICOMObject["00020017"]',
	},
	{
		label: 'ReceivingApplicationEntityTitle (00020018)',
		code: 'DICOMObject["00020018"]',
	},
	{
		label: 'PrivateInformationCreatorUID (00020100)',
		code: 'DICOMObject["00020100"]',
	},
	{
		label: 'PrivateInformation (00020102)',
		code: 'DICOMObject["00020102"]',
	},
	{
		label: 'FileSetID (00041130)',
		code: 'DICOMObject["00041130"]',
	},
	{
		label: 'FileSetDescriptorFileID (00041141)',
		code: 'DICOMObject["00041141"]',
	},
	{
		label: 'SpecificCharacterSetOfFileSetDescriptorFile (00041142)',
		code: 'DICOMObject["00041142"]',
	},
	{
		label: 'OffsetOfTheFirstDirectoryRecordOfTheRootDirectoryEntity (00041200)',
		code: 'DICOMObject["00041200"]',
	},
	{
		label: 'OffsetOfTheLastDirectoryRecordOfTheRootDirectoryEntity (00041202)',
		code: 'DICOMObject["00041202"]',
	},
	{
		label: 'FileSetConsistencyFlag (00041212)',
		code: 'DICOMObject["00041212"]',
	},
	{
		label: 'DirectoryRecordSequence (00041220)',
		code: 'DICOMObject["00041220"]',
	},
	{
		label: 'OffsetOfTheNextDirectoryRecord (00041400)',
		code: 'DICOMObject["00041400"]',
	},
	{
		label: 'RecordInUseFlag (00041410)',
		code: 'DICOMObject["00041410"]',
	},
	{
		label: 'OffsetOfReferencedLowerLevelDirectoryEntity (00041420)',
		code: 'DICOMObject["00041420"]',
	},
	{
		label: 'DirectoryRecordType (00041430)',
		code: 'DICOMObject["00041430"]',
	},
	{
		label: 'PrivateRecordUID (00041432)',
		code: 'DICOMObject["00041432"]',
	},
	{
		label: 'ReferencedFileID (00041500)',
		code: 'DICOMObject["00041500"]',
	},
	{
		label: 'ReferencedSOPClassUIDInFile (00041510)',
		code: 'DICOMObject["00041510"]',
	},
	{
		label: 'ReferencedSOPInstanceUIDInFile (00041511)',
		code: 'DICOMObject["00041511"]',
	},
	{
		label: 'ReferencedTransferSyntaxUIDInFile (00041512)',
		code: 'DICOMObject["00041512"]',
	},
	{
		label: 'ReferencedRelatedGeneralSOPClassUIDInFile (0004151A)',
		code: 'DICOMObject["0004151A"]',
	},
	{
		label: 'SpecificCharacterSet (00080005)',
		code: 'DICOMObject["00080005"]',
	},
	{
		label: 'LanguageCodeSequence (00080006)',
		code: 'DICOMObject["00080006"]',
	},
	{
		label: 'ImageType (00080008)',
		code: 'DICOMObject["00080008"]',
	},
	{
		label: 'InstanceCreationDate (00080012)',
		code: 'DICOMObject["00080012"]',
	},
	{
		label: 'InstanceCreationTime (00080013)',
		code: 'DICOMObject["00080013"]',
	},
	{
		label: 'InstanceCreatorUID (00080014)',
		code: 'DICOMObject["00080014"]',
	},
	{
		label: 'InstanceCoercionDateTime (00080015)',
		code: 'DICOMObject["00080015"]',
	},
	{
		label: 'SOPClassUID (00080016)',
		code: 'DICOMObject["00080016"]',
	},
	{
		label: 'SOPInstanceUID (00080018)',
		code: 'DICOMObject["00080018"]',
	},
	{
		label: 'RelatedGeneralSOPClassUID (0008001A)',
		code: 'DICOMObject["0008001A"]',
	},
	{
		label: 'OriginalSpecializedSOPClassUID (0008001B)',
		code: 'DICOMObject["0008001B"]',
	},
	{
		label: 'StudyDate (00080020)',
		code: 'DICOMObject["00080020"]',
	},
	{
		label: 'SeriesDate (00080021)',
		code: 'DICOMObject["00080021"]',
	},
	{
		label: 'AcquisitionDate (00080022)',
		code: 'DICOMObject["00080022"]',
	},
	{
		label: 'ContentDate (00080023)',
		code: 'DICOMObject["00080023"]',
	},
	{
		label: 'AcquisitionDateTime (0008002A)',
		code: 'DICOMObject["0008002A"]',
	},
	{
		label: 'StudyTime (00080030)',
		code: 'DICOMObject["00080030"]',
	},
	{
		label: 'SeriesTime (00080031)',
		code: 'DICOMObject["00080031"]',
	},
	{
		label: 'AcquisitionTime (00080032)',
		code: 'DICOMObject["00080032"]',
	},
	{
		label: 'ContentTime (00080033)',
		code: 'DICOMObject["00080033"]',
	},
	{
		label: 'AccessionNumber (00080050)',
		code: 'DICOMObject["00080050"]',
	},
	{
		label: 'IssuerOfAccessionNumberSequence (00080051)',
		code: 'DICOMObject["00080051"]',
	},
	{
		label: 'QueryRetrieveLevel (00080052)',
		code: 'DICOMObject["00080052"]',
	},
	{
		label: 'QueryRetrieveView (00080053)',
		code: 'DICOMObject["00080053"]',
	},
	{
		label: 'RetrieveAETitle (00080054)',
		code: 'DICOMObject["00080054"]',
	},
	{
		label: 'StationAETitle (00080055)',
		code: 'DICOMObject["00080055"]',
	},
	{
		label: 'InstanceAvailability (00080056)',
		code: 'DICOMObject["00080056"]',
	},
	{
		label: 'FailedSOPInstanceUIDList (00080058)',
		code: 'DICOMObject["00080058"]',
	},
	{
		label: 'Modality (00080060)',
		code: 'DICOMObject["00080060"]',
	},
	{
		label: 'ModalitiesInStudy (00080061)',
		code: 'DICOMObject["00080061"]',
	},
	{
		label: 'SOPClassesInStudy (00080062)',
		code: 'DICOMObject["00080062"]',
	},
	{
		label: 'ConversionType (00080064)',
		code: 'DICOMObject["00080064"]',
	},
	{
		label: 'PresentationIntentType (00080068)',
		code: 'DICOMObject["00080068"]',
	},
	{
		label: 'Manufacturer (00080070)',
		code: 'DICOMObject["00080070"]',
	},
	{
		label: 'InstitutionName (00080080)',
		code: 'DICOMObject["00080080"]',
	},
	{
		label: 'InstitutionAddress (00080081)',
		code: 'DICOMObject["00080081"]',
	},
	{
		label: 'InstitutionCodeSequence (00080082)',
		code: 'DICOMObject["00080082"]',
	},
	{
		label: 'AnatomicRegions (00080063)',
		code: 'DICOMObject["00080063"]',
	},
	{
		label: 'ReferringPhysicianName (00080090)',
		code: 'DICOMObject["00080090"]',
	},
	{
		label: 'ReferringPhysicianAddress (00080092)',
		code: 'DICOMObject["00080092"]',
	},
	{
		label: 'ReferringPhysicianTelephoneNumbers (00080094)',
		code: 'DICOMObject["00080094"]',
	},
	{
		label: 'ReferringPhysicianIdentificationSequence (00080096)',
		code: 'DICOMObject["00080096"]',
	},
	{
		label: 'ConsultingPhysicianName (0008009C)',
		code: 'DICOMObject["0008009C"]',
	},
	{
		label: 'ConsultingPhysicianIdentificationSequence (0008009D)',
		code: 'DICOMObject["0008009D"]',
	},
	{
		label: 'CodeValue (00080100)',
		code: 'DICOMObject["00080100"]',
	},
	{
		label: 'ExtendedCodeValue (00080101)',
		code: 'DICOMObject["00080101"]',
	},
	{
		label: 'CodingSchemeDesignator (00080102)',
		code: 'DICOMObject["00080102"]',
	},
	{
		label: 'CodingSchemeVersion (00080103)',
		code: 'DICOMObject["00080103"]',
	},
	{
		label: 'CodeMeaning (00080104)',
		code: 'DICOMObject["00080104"]',
	},
	{
		label: 'MappingResource (00080105)',
		code: 'DICOMObject["00080105"]',
	},
	{
		label: 'ContextGroupVersion (00080106)',
		code: 'DICOMObject["00080106"]',
	},
	{
		label: 'ContextGroupLocalVersion (00080107)',
		code: 'DICOMObject["00080107"]',
	},
	{
		label: 'ExtendedCodeMeaning (00080108)',
		code: 'DICOMObject["00080108"]',
	},
	{
		label: 'ContextGroupExtensionFlag (0008010B)',
		code: 'DICOMObject["0008010B"]',
	},
	{
		label: 'CodingSchemeUID (0008010C)',
		code: 'DICOMObject["0008010C"]',
	},
	{
		label: 'ContextGroupExtensionCreatorUID (0008010D)',
		code: 'DICOMObject["0008010D"]',
	},
	{
		label: 'ContextIdentifier (0008010F)',
		code: 'DICOMObject["0008010F"]',
	},
	{
		label: 'CodingSchemeIdentificationSequence (00080110)',
		code: 'DICOMObject["00080110"]',
	},
	{
		label: 'CodingSchemeRegistry (00080112)',
		code: 'DICOMObject["00080112"]',
	},
	{
		label: 'CodingSchemeExternalID (00080114)',
		code: 'DICOMObject["00080114"]',
	},
	{
		label: 'CodingSchemeName (00080115)',
		code: 'DICOMObject["00080115"]',
	},
	{
		label: 'CodingSchemeResponsibleOrganization (00080116)',
		code: 'DICOMObject["00080116"]',
	},
	{
		label: 'ContextUID (00080117)',
		code: 'DICOMObject["00080117"]',
	},
	{
		label: 'MappingResourceUID (00080118)',
		code: 'DICOMObject["00080118"]',
	},
	{
		label: 'LongCodeValue (00080119)',
		code: 'DICOMObject["00080119"]',
	},
	{
		label: 'URNCodeValue (00080120)',
		code: 'DICOMObject["00080120"]',
	},
	{
		label: 'EquivalentCodeSequence (00080121)',
		code: 'DICOMObject["00080121"]',
	},
	{
		label: 'MappingResourceName (00080122)',
		code: 'DICOMObject["00080122"]',
	},
	{
		label: 'ContextGroupIdentificationSequence (00080123)',
		code: 'DICOMObject["00080123"]',
	},
	{
		label: 'MappingResourceIdentificationSequence (00080124)',
		code: 'DICOMObject["00080124"]',
	},
	{
		label: 'TimezoneOffsetFromUTC (00080201)',
		code: 'DICOMObject["00080201"]',
	},
	{
		label: 'ResponsibleGroupCodeSequence (00080220)',
		code: 'DICOMObject["00080220"]',
	},
	{
		label: 'EquipmentModality (00080221)',
		code: 'DICOMObject["00080221"]',
	},
	{
		label: 'ManufacturerRelatedModelGroup (00080222)',
		code: 'DICOMObject["00080222"]',
	},
	{
		label: 'PrivateDataElementCharacteristicsSequence (00080300)',
		code: 'DICOMObject["00080300"]',
	},
	{
		label: 'PrivateGroupReference (00080301)',
		code: 'DICOMObject["00080301"]',
	},
	{
		label: 'PrivateCreatorReference (00080302)',
		code: 'DICOMObject["00080302"]',
	},
	{
		label: 'BlockIdentifyingInformationStatus (00080303)',
		code: 'DICOMObject["00080303"]',
	},
	{
		label: 'NonidentifyingPrivateElements (00080304)',
		code: 'DICOMObject["00080304"]',
	},
	{
		label: 'DeidentificationActionSequence (00080305)',
		code: 'DICOMObject["00080305"]',
	},
	{
		label: 'IdentifyingPrivateElements (00080306)',
		code: 'DICOMObject["00080306"]',
	},
	{
		label: 'DeidentificationAction (00080307)',
		code: 'DICOMObject["00080307"]',
	},
	{
		label: 'PrivateDataElement (00080308)',
		code: 'DICOMObject["00080308"]',
	},
	{
		label: 'PrivateDataElementValueMultiplicity (00080309)',
		code: 'DICOMObject["00080309"]',
	},
	{
		label: 'PrivateDataElementValueRepresentation (0008030A)',
		code: 'DICOMObject["0008030A"]',
	},
	{
		label: 'PrivateDataElementNumberOfItems (0008030B)',
		code: 'DICOMObject["0008030B"]',
	},
	{
		label: 'PrivateDataElementName (0008030C)',
		code: 'DICOMObject["0008030C"]',
	},
	{
		label: 'PrivateDataElementKeyword (0008030D)',
		code: 'DICOMObject["0008030D"]',
	},
	{
		label: 'PrivateDataElementDescription (0008030E)',
		code: 'DICOMObject["0008030E"]',
	},
	{
		label: 'PrivateDataElementEncoding (0008030F)',
		code: 'DICOMObject["0008030F"]',
	},
	{
		label: 'PrivateDataElementDefinitionSequence (00080310)',
		code: 'DICOMObject["00080310"]',
	},
	{
		label: 'StationName (00081010)',
		code: 'DICOMObject["00081010"]',
	},
	{
		label: 'StudyDescription (00081030)',
		code: 'DICOMObject["00081030"]',
	},
	{
		label: 'ProcedureCodeSequence (00081032)',
		code: 'DICOMObject["00081032"]',
	},
	{
		label: 'SeriesDescription (0008103E)',
		code: 'DICOMObject["0008103E"]',
	},
	{
		label: 'SeriesDescriptionCodeSequence (0008103F)',
		code: 'DICOMObject["0008103F"]',
	},
	{
		label: 'InstitutionalDepartmentName (00081040)',
		code: 'DICOMObject["00081040"]',
	},
	{
		label: 'PhysiciansOfRecord (00081048)',
		code: 'DICOMObject["00081048"]',
	},
	{
		label: 'PhysiciansOfRecordIdentificationSequence (00081049)',
		code: 'DICOMObject["00081049"]',
	},
	{
		label: 'PerformingPhysicianName (00081050)',
		code: 'DICOMObject["00081050"]',
	},
	{
		label: 'PerformingPhysicianIdentificationSequence (00081052)',
		code: 'DICOMObject["00081052"]',
	},
	{
		label: 'NameOfPhysiciansReadingStudy (00081060)',
		code: 'DICOMObject["00081060"]',
	},
	{
		label: 'PhysiciansReadingStudyIdentificationSequence (00081062)',
		code: 'DICOMObject["00081062"]',
	},
	{
		label: 'OperatorsName (00081070)',
		code: 'DICOMObject["00081070"]',
	},
	{
		label: 'OperatorIdentificationSequence (00081072)',
		code: 'DICOMObject["00081072"]',
	},
	{
		label: 'AdmittingDiagnosesDescription (00081080)',
		code: 'DICOMObject["00081080"]',
	},
	{
		label: 'AdmittingDiagnosesCodeSequence (00081084)',
		code: 'DICOMObject["00081084"]',
	},
	{
		label: 'ManufacturerModelName (00081090)',
		code: 'DICOMObject["00081090"]',
	},
	{
		label: 'ReferencedStudySequence (00081110)',
		code: 'DICOMObject["00081110"]',
	},
	{
		label: 'ReferencedPerformedProcedureStepSequence (00081111)',
		code: 'DICOMObject["00081111"]',
	},
	{
		label: 'ReferencedSeriesSequence (00081115)',
		code: 'DICOMObject["00081115"]',
	},
	{
		label: 'ReferencedPatientSequence (00081120)',
		code: 'DICOMObject["00081120"]',
	},
	{
		label: 'ReferencedVisitSequence (00081125)',
		code: 'DICOMObject["00081125"]',
	},
	{
		label: 'ReferencedStereometricInstanceSequence (00081134)',
		code: 'DICOMObject["00081134"]',
	},
	{
		label: 'ReferencedWaveformSequence (0008113A)',
		code: 'DICOMObject["0008113A"]',
	},
	{
		label: 'ReferencedImageSequence (00081140)',
		code: 'DICOMObject["00081140"]',
	},
	{
		label: 'ReferencedInstanceSequence (0008114A)',
		code: 'DICOMObject["0008114A"]',
	},
	{
		label: 'ReferencedRealWorldValueMappingInstanceSequence (0008114B)',
		code: 'DICOMObject["0008114B"]',
	},
	{
		label: 'ReferencedSOPClassUID (00081150)',
		code: 'DICOMObject["00081150"]',
	},
	{
		label: 'ReferencedSOPInstanceUID (00081155)',
		code: 'DICOMObject["00081155"]',
	},
	{
		label: 'SOPClassesSupported (0008115A)',
		code: 'DICOMObject["0008115A"]',
	},
	{
		label: 'ReferencedFrameNumber (00081160)',
		code: 'DICOMObject["00081160"]',
	},
	{
		label: 'SimpleFrameList (00081161)',
		code: 'DICOMObject["00081161"]',
	},
	{
		label: 'CalculatedFrameList (00081162)',
		code: 'DICOMObject["00081162"]',
	},
	{
		label: 'TimeRange (00081163)',
		code: 'DICOMObject["00081163"]',
	},
	{
		label: 'FrameExtractionSequence (00081164)',
		code: 'DICOMObject["00081164"]',
	},
	{
		label: 'MultiFrameSourceSOPInstanceUID (00081167)',
		code: 'DICOMObject["00081167"]',
	},
	{
		label: 'RetrieveURL (00081190)',
		code: 'DICOMObject["00081190"]',
	},
	{
		label: 'TransactionUID (00081195)',
		code: 'DICOMObject["00081195"]',
	},
	{
		label: 'WarningReason (00081196)',
		code: 'DICOMObject["00081196"]',
	},
	{
		label: 'FailureReason (00081197)',
		code: 'DICOMObject["00081197"]',
	},
	{
		label: 'FailedSOPSequence (00081198)',
		code: 'DICOMObject["00081198"]',
	},
	{
		label: 'ReferencedSOPSequence (00081199)',
		code: 'DICOMObject["00081199"]',
	},
	{
		label: 'OtherFailuresSequence (0008119A)',
		code: 'DICOMObject["0008119A"]',
	},
	{
		label: 'StudiesContainingOtherReferencedInstancesSequence (00081200)',
		code: 'DICOMObject["00081200"]',
	},
	{
		label: 'RelatedSeriesSequence (00081250)',
		code: 'DICOMObject["00081250"]',
	},
	{
		label: 'DerivationDescription (00082111)',
		code: 'DICOMObject["00082111"]',
	},
	{
		label: 'SourceImageSequence (00082112)',
		code: 'DICOMObject["00082112"]',
	},
	{
		label: 'StageName (00082120)',
		code: 'DICOMObject["00082120"]',
	},
	{
		label: 'StageNumber (00082122)',
		code: 'DICOMObject["00082122"]',
	},
	{
		label: 'NumberOfStages (00082124)',
		code: 'DICOMObject["00082124"]',
	},
	{
		label: 'ViewName (00082127)',
		code: 'DICOMObject["00082127"]',
	},
	{
		label: 'ViewNumber (00082128)',
		code: 'DICOMObject["00082128"]',
	},
	{
		label: 'NumberOfEventTimers (00082129)',
		code: 'DICOMObject["00082129"]',
	},
	{
		label: 'NumberOfViewsInStage (0008212A)',
		code: 'DICOMObject["0008212A"]',
	},
	{
		label: 'EventElapsedTimes (00082130)',
		code: 'DICOMObject["00082130"]',
	},
	{
		label: 'EventTimerNames (00082132)',
		code: 'DICOMObject["00082132"]',
	},
	{
		label: 'EventTimerSequence (00082133)',
		code: 'DICOMObject["00082133"]',
	},
	{
		label: 'EventTimeOffset (00082134)',
		code: 'DICOMObject["00082134"]',
	},
	{
		label: 'EventCodeSequence (00082135)',
		code: 'DICOMObject["00082135"]',
	},
	{
		label: 'StartTrim (00082142)',
		code: 'DICOMObject["00082142"]',
	},
	{
		label: 'StopTrim (00082143)',
		code: 'DICOMObject["00082143"]',
	},
	{
		label: 'RecommendedDisplayFrameRate (00082144)',
		code: 'DICOMObject["00082144"]',
	},
	{
		label: 'AnatomicRegionSequence (00082218)',
		code: 'DICOMObject["00082218"]',
	},
	{
		label: 'AnatomicRegionModifierSequence (00082220)',
		code: 'DICOMObject["00082220"]',
	},
	{
		label: 'PrimaryAnatomicStructureSequence (00082228)',
		code: 'DICOMObject["00082228"]',
	},
	{
		label: 'AnatomicStructureSpaceOrRegionSequence (00082229)',
		code: 'DICOMObject["00082229"]',
	},
	{
		label: 'PrimaryAnatomicStructureModifierSequence (00082230)',
		code: 'DICOMObject["00082230"]',
	},
	{
		label: 'AlternateRepresentationSequence (00083001)',
		code: 'DICOMObject["00083001"]',
	},
	{
		label: 'IrradiationEventUID (00083010)',
		code: 'DICOMObject["00083010"]',
	},
	{
		label: 'SourceIrradiationEventSequence (00083011)',
		code: 'DICOMObject["00083011"]',
	},
	{
		label: 'RadiopharmaceuticalAdministrationEventUID (00083012)',
		code: 'DICOMObject["00083012"]',
	},
	{
		label: 'FrameType (00089007)',
		code: 'DICOMObject["00089007"]',
	},
	{
		label: 'ReferencedImageEvidenceSequence (00089092)',
		code: 'DICOMObject["00089092"]',
	},
	{
		label: 'ReferencedRawDataSequence (00089121)',
		code: 'DICOMObject["00089121"]',
	},
	{
		label: 'CreatorVersionUID (00089123)',
		code: 'DICOMObject["00089123"]',
	},
	{
		label: 'DerivationImageSequence (00089124)',
		code: 'DICOMObject["00089124"]',
	},
	{
		label: 'SourceImageEvidenceSequence (00089154)',
		code: 'DICOMObject["00089154"]',
	},
	{
		label: 'PixelPresentation (00089205)',
		code: 'DICOMObject["00089205"]',
	},
	{
		label: 'VolumetricProperties (00089206)',
		code: 'DICOMObject["00089206"]',
	},
	{
		label: 'VolumeBasedCalculationTechnique (00089207)',
		code: 'DICOMObject["00089207"]',
	},
	{
		label: 'ComplexImageComponent (00089208)',
		code: 'DICOMObject["00089208"]',
	},
	{
		label: 'AcquisitionContrast (00089209)',
		code: 'DICOMObject["00089209"]',
	},
	{
		label: 'DerivationCodeSequence (00089215)',
		code: 'DICOMObject["00089215"]',
	},
	{
		label: 'ReferencedPresentationStateSequence (00089237)',
		code: 'DICOMObject["00089237"]',
	},
	{
		label: 'ReferencedOtherPlaneSequence (00089410)',
		code: 'DICOMObject["00089410"]',
	},
	{
		label: 'FrameDisplaySequence (00089458)',
		code: 'DICOMObject["00089458"]',
	},
	{
		label: 'RecommendedDisplayFrameRateInFloat (00089459)',
		code: 'DICOMObject["00089459"]',
	},
	{
		label: 'SkipFrameRangeFlag (00089460)',
		code: 'DICOMObject["00089460"]',
	},
	{
		label: 'PatientName (00100010)',
		code: 'DICOMObject["00100010"]',
	},
	{
		label: 'PatientID (00100020)',
		code: 'DICOMObject["00100020"]',
	},
	{
		label: 'IssuerOfPatientID (00100021)',
		code: 'DICOMObject["00100021"]',
	},
	{
		label: 'TypeOfPatientID (00100022)',
		code: 'DICOMObject["00100022"]',
	},
	{
		label: 'IssuerOfPatientIDQualifiersSequence (00100024)',
		code: 'DICOMObject["00100024"]',
	},
	{
		label: 'SourcePatientGroupIdentificationSequence (00100026)',
		code: 'DICOMObject["00100026"]',
	},
	{
		label: 'GroupOfPatientsIdentificationSequence (00100027)',
		code: 'DICOMObject["00100027"]',
	},
	{
		label: 'SubjectRelativePositionInImage (00100028)',
		code: 'DICOMObject["00100028"]',
	},
	{
		label: 'PatientBirthDate (00100030)',
		code: 'DICOMObject["00100030"]',
	},
	{
		label: 'PatientBirthTime (00100032)',
		code: 'DICOMObject["00100032"]',
	},
	{
		label: 'PatientBirthDateInAlternativeCalendar (00100033)',
		code: 'DICOMObject["00100033"]',
	},
	{
		label: 'PatientDeathDateInAlternativeCalendar (00100034)',
		code: 'DICOMObject["00100034"]',
	},
	{
		label: 'PatientAlternativeCalendar (00100035)',
		code: 'DICOMObject["00100035"]',
	},
	{
		label: 'PatientSex (00100040)',
		code: 'DICOMObject["00100040"]',
	},
	{
		label: 'PatientInsurancePlanCodeSequence (00100050)',
		code: 'DICOMObject["00100050"]',
	},
	{
		label: 'PatientPrimaryLanguageCodeSequence (00100101)',
		code: 'DICOMObject["00100101"]',
	},
	{
		label: 'PatientPrimaryLanguageModifierCodeSequence (00100102)',
		code: 'DICOMObject["00100102"]',
	},
	{
		label: 'QualityControlSubject (00100200)',
		code: 'DICOMObject["00100200"]',
	},
	{
		label: 'QualityControlSubjectTypeCodeSequence (00100201)',
		code: 'DICOMObject["00100201"]',
	},
	{
		label: 'StrainDescription (00100212)',
		code: 'DICOMObject["00100212"]',
	},
	{
		label: 'StrainNomenclature (00100213)',
		code: 'DICOMObject["00100213"]',
	},
	{
		label: 'StrainStockNumber (00100214)',
		code: 'DICOMObject["00100214"]',
	},
	{
		label: 'StrainSourceRegistryCodeSequence (00100215)',
		code: 'DICOMObject["00100215"]',
	},
	{
		label: 'StrainStockSequence (00100216)',
		code: 'DICOMObject["00100216"]',
	},
	{
		label: 'StrainSource (00100217)',
		code: 'DICOMObject["00100217"]',
	},
	{
		label: 'StrainAdditionalInformation (00100218)',
		code: 'DICOMObject["00100218"]',
	},
	{
		label: 'StrainCodeSequence (00100219)',
		code: 'DICOMObject["00100219"]',
	},
	{
		label: 'GeneticModificationsSequence (00100221)',
		code: 'DICOMObject["00100221"]',
	},
	{
		label: 'GeneticModificationsDescription (00100222)',
		code: 'DICOMObject["00100222"]',
	},
	{
		label: 'GeneticModificationsNomenclature (00100223)',
		code: 'DICOMObject["00100223"]',
	},
	{
		label: 'GeneticModificationsCodeSequence (00100229)',
		code: 'DICOMObject["00100229"]',
	},
	{
		label: 'OtherPatientNames (00101001)',
		code: 'DICOMObject["00101001"]',
	},
	{
		label: 'OtherPatientIDsSequence (00101002)',
		code: 'DICOMObject["00101002"]',
	},
	{
		label: 'PatientBirthName (00101005)',
		code: 'DICOMObject["00101005"]',
	},
	{
		label: 'PatientAge (00101010)',
		code: 'DICOMObject["00101010"]',
	},
	{
		label: 'PatientSize (00101020)',
		code: 'DICOMObject["00101020"]',
	},
	{
		label: 'PatientSizeCodeSequence (00101021)',
		code: 'DICOMObject["00101021"]',
	},
	{
		label: 'PatientBodyMassIndex (00101022)',
		code: 'DICOMObject["00101022"]',
	},
	{
		label: 'MeasuredAPDimension (00101023)',
		code: 'DICOMObject["00101023"]',
	},
	{
		label: 'MeasuredLateralDimension (00101024)',
		code: 'DICOMObject["00101024"]',
	},
	{
		label: 'PatientWeight (00101030)',
		code: 'DICOMObject["00101030"]',
	},
	{
		label: 'PatientAddress (00101040)',
		code: 'DICOMObject["00101040"]',
	},
	{
		label: 'PatientMotherBirthName (00101060)',
		code: 'DICOMObject["00101060"]',
	},
	{
		label: 'MilitaryRank (00101080)',
		code: 'DICOMObject["00101080"]',
	},
	{
		label: 'BranchOfService (00101081)',
		code: 'DICOMObject["00101081"]',
	},
	{
		label: 'ReferencedPatientPhotoSequence (00101100)',
		code: 'DICOMObject["00101100"]',
	},
	{
		label: 'MedicalAlerts (00102000)',
		code: 'DICOMObject["00102000"]',
	},
	{
		label: 'Allergies (00102110)',
		code: 'DICOMObject["00102110"]',
	},
	{
		label: 'CountryOfResidence (00102150)',
		code: 'DICOMObject["00102150"]',
	},
	{
		label: 'RegionOfResidence (00102152)',
		code: 'DICOMObject["00102152"]',
	},
	{
		label: 'PatientTelephoneNumbers (00102154)',
		code: 'DICOMObject["00102154"]',
	},
	{
		label: 'PatientTelecomInformation (00102155)',
		code: 'DICOMObject["00102155"]',
	},
	{
		label: 'EthnicGroup (00102160)',
		code: 'DICOMObject["00102160"]',
	},
	{
		label: 'Occupation (00102180)',
		code: 'DICOMObject["00102180"]',
	},
	{
		label: 'SmokingStatus (001021A0)',
		code: 'DICOMObject["001021A0"]',
	},
	{
		label: 'AdditionalPatientHistory (001021B0)',
		code: 'DICOMObject["001021B0"]',
	},
	{
		label: 'PregnancyStatus (001021C0)',
		code: 'DICOMObject["001021C0"]',
	},
	{
		label: 'LastMenstrualDate (001021D0)',
		code: 'DICOMObject["001021D0"]',
	},
	{
		label: 'PatientReligiousPreference (001021F0)',
		code: 'DICOMObject["001021F0"]',
	},
	{
		label: 'PatientSpeciesDescription (00102201)',
		code: 'DICOMObject["00102201"]',
	},
	{
		label: 'PatientSpeciesCodeSequence (00102202)',
		code: 'DICOMObject["00102202"]',
	},
	{
		label: 'PatientSexNeutered (00102203)',
		code: 'DICOMObject["00102203"]',
	},
	{
		label: 'AnatomicalOrientationType (00102210)',
		code: 'DICOMObject["00102210"]',
	},
	{
		label: 'PatientBreedDescription (00102292)',
		code: 'DICOMObject["00102292"]',
	},
	{
		label: 'PatientBreedCodeSequence (00102293)',
		code: 'DICOMObject["00102293"]',
	},
	{
		label: 'BreedRegistrationSequence (00102294)',
		code: 'DICOMObject["00102294"]',
	},
	{
		label: 'BreedRegistrationNumber (00102295)',
		code: 'DICOMObject["00102295"]',
	},
	{
		label: 'BreedRegistryCodeSequence (00102296)',
		code: 'DICOMObject["00102296"]',
	},
	{
		label: 'ResponsiblePerson (00102297)',
		code: 'DICOMObject["00102297"]',
	},
	{
		label: 'ResponsiblePersonRole (00102298)',
		code: 'DICOMObject["00102298"]',
	},
	{
		label: 'ResponsibleOrganization (00102299)',
		code: 'DICOMObject["00102299"]',
	},
	{
		label: 'PatientComments (00104000)',
		code: 'DICOMObject["00104000"]',
	},
	{
		label: 'ExaminedBodyThickness (00109431)',
		code: 'DICOMObject["00109431"]',
	},
	{
		label: 'ClinicalTrialSponsorName (00120010)',
		code: 'DICOMObject["00120010"]',
	},
	{
		label: 'ClinicalTrialProtocolID (00120020)',
		code: 'DICOMObject["00120020"]',
	},
	{
		label: 'ClinicalTrialProtocolName (00120021)',
		code: 'DICOMObject["00120021"]',
	},
	{
		label: 'ClinicalTrialSiteID (00120030)',
		code: 'DICOMObject["00120030"]',
	},
	{
		label: 'ClinicalTrialSiteName (00120031)',
		code: 'DICOMObject["00120031"]',
	},
	{
		label: 'ClinicalTrialSubjectID (00120040)',
		code: 'DICOMObject["00120040"]',
	},
	{
		label: 'ClinicalTrialSubjectReadingID (00120042)',
		code: 'DICOMObject["00120042"]',
	},
	{
		label: 'ClinicalTrialTimePointID (00120050)',
		code: 'DICOMObject["00120050"]',
	},
	{
		label: 'ClinicalTrialTimePointDescription (00120051)',
		code: 'DICOMObject["00120051"]',
	},
	{
		label: 'ClinicalTrialCoordinatingCenterName (00120060)',
		code: 'DICOMObject["00120060"]',
	},
	{
		label: 'PatientIdentityRemoved (00120062)',
		code: 'DICOMObject["00120062"]',
	},
	{
		label: 'DeidentificationMethod (00120063)',
		code: 'DICOMObject["00120063"]',
	},
	{
		label: 'DeidentificationMethodCodeSequence (00120064)',
		code: 'DICOMObject["00120064"]',
	},
	{
		label: 'ClinicalTrialSeriesID (00120071)',
		code: 'DICOMObject["00120071"]',
	},
	{
		label: 'ClinicalTrialSeriesDescription (00120072)',
		code: 'DICOMObject["00120072"]',
	},
	{
		label: 'ClinicalTrialProtocolEthicsCommitteeName (00120081)',
		code: 'DICOMObject["00120081"]',
	},
	{
		label: 'ClinicalTrialProtocolEthicsCommitteeApprovalNumber (00120082)',
		code: 'DICOMObject["00120082"]',
	},
	{
		label: 'ConsentForClinicalTrialUseSequence (00120083)',
		code: 'DICOMObject["00120083"]',
	},
	{
		label: 'DistributionType (00120084)',
		code: 'DICOMObject["00120084"]',
	},
	{
		label: 'ConsentForDistributionFlag (00120085)',
		code: 'DICOMObject["00120085"]',
	},
	{
		label: 'EthicsCommitteeApprovalEffectivenessStartDate (00120086)',
		code: 'DICOMObject["00120086"]',
	},
	{
		label: 'EthicsCommitteeApprovalEffectivenessEndDate (00120087)',
		code: 'DICOMObject["00120087"]',
	},
	{
		label: 'ComponentManufacturingProcedure (00140025)',
		code: 'DICOMObject["00140025"]',
	},
	{
		label: 'ComponentManufacturer (00140028)',
		code: 'DICOMObject["00140028"]',
	},
	{
		label: 'MaterialThickness (00140030)',
		code: 'DICOMObject["00140030"]',
	},
	{
		label: 'MaterialPipeDiameter (00140032)',
		code: 'DICOMObject["00140032"]',
	},
	{
		label: 'MaterialIsolationDiameter (00140034)',
		code: 'DICOMObject["00140034"]',
	},
	{
		label: 'MaterialGrade (00140042)',
		code: 'DICOMObject["00140042"]',
	},
	{
		label: 'MaterialPropertiesDescription (00140044)',
		code: 'DICOMObject["00140044"]',
	},
	{
		label: 'MaterialNotes (00140046)',
		code: 'DICOMObject["00140046"]',
	},
	{
		label: 'ComponentShape (00140050)',
		code: 'DICOMObject["00140050"]',
	},
	{
		label: 'CurvatureType (00140052)',
		code: 'DICOMObject["00140052"]',
	},
	{
		label: 'OuterDiameter (00140054)',
		code: 'DICOMObject["00140054"]',
	},
	{
		label: 'InnerDiameter (00140056)',
		code: 'DICOMObject["00140056"]',
	},
	{
		label: 'ComponentWelderIDs (00140100)',
		code: 'DICOMObject["00140100"]',
	},
	{
		label: 'SecondaryApprovalStatus (00140101)',
		code: 'DICOMObject["00140101"]',
	},
	{
		label: 'SecondaryReviewDate (00140102)',
		code: 'DICOMObject["00140102"]',
	},
	{
		label: 'SecondaryReviewTime (00140103)',
		code: 'DICOMObject["00140103"]',
	},
	{
		label: 'SecondaryReviewerName (00140104)',
		code: 'DICOMObject["00140104"]',
	},
	{
		label: 'RepairID (00140105)',
		code: 'DICOMObject["00140105"]',
	},
	{
		label: 'MultipleComponentApprovalSequence (00140106)',
		code: 'DICOMObject["00140106"]',
	},
	{
		label: 'OtherApprovalStatus (00140107)',
		code: 'DICOMObject["00140107"]',
	},
	{
		label: 'OtherSecondaryApprovalStatus (00140108)',
		code: 'DICOMObject["00140108"]',
	},
	{
		label: 'ActualEnvironmentalConditions (00141010)',
		code: 'DICOMObject["00141010"]',
	},
	{
		label: 'ExpiryDate (00141020)',
		code: 'DICOMObject["00141020"]',
	},
	{
		label: 'EnvironmentalConditions (00141040)',
		code: 'DICOMObject["00141040"]',
	},
	{
		label: 'EvaluatorSequence (00142002)',
		code: 'DICOMObject["00142002"]',
	},
	{
		label: 'EvaluatorNumber (00142004)',
		code: 'DICOMObject["00142004"]',
	},
	{
		label: 'EvaluatorName (00142006)',
		code: 'DICOMObject["00142006"]',
	},
	{
		label: 'EvaluationAttempt (00142008)',
		code: 'DICOMObject["00142008"]',
	},
	{
		label: 'IndicationSequence (00142012)',
		code: 'DICOMObject["00142012"]',
	},
	{
		label: 'IndicationNumber (00142014)',
		code: 'DICOMObject["00142014"]',
	},
	{
		label: 'IndicationLabel (00142016)',
		code: 'DICOMObject["00142016"]',
	},
	{
		label: 'IndicationDescription (00142018)',
		code: 'DICOMObject["00142018"]',
	},
	{
		label: 'IndicationType (0014201A)',
		code: 'DICOMObject["0014201A"]',
	},
	{
		label: 'IndicationDisposition (0014201C)',
		code: 'DICOMObject["0014201C"]',
	},
	{
		label: 'IndicationROISequence (0014201E)',
		code: 'DICOMObject["0014201E"]',
	},
	{
		label: 'IndicationPhysicalPropertySequence (00142030)',
		code: 'DICOMObject["00142030"]',
	},
	{
		label: 'PropertyLabel (00142032)',
		code: 'DICOMObject["00142032"]',
	},
	{
		label: 'CoordinateSystemNumberOfAxes (00142202)',
		code: 'DICOMObject["00142202"]',
	},
	{
		label: 'CoordinateSystemAxesSequence (00142204)',
		code: 'DICOMObject["00142204"]',
	},
	{
		label: 'CoordinateSystemAxisDescription (00142206)',
		code: 'DICOMObject["00142206"]',
	},
	{
		label: 'CoordinateSystemDataSetMapping (00142208)',
		code: 'DICOMObject["00142208"]',
	},
	{
		label: 'CoordinateSystemAxisNumber (0014220A)',
		code: 'DICOMObject["0014220A"]',
	},
	{
		label: 'CoordinateSystemAxisType (0014220C)',
		code: 'DICOMObject["0014220C"]',
	},
	{
		label: 'CoordinateSystemAxisUnits (0014220E)',
		code: 'DICOMObject["0014220E"]',
	},
	{
		label: 'CoordinateSystemAxisValues (00142210)',
		code: 'DICOMObject["00142210"]',
	},
	{
		label: 'CoordinateSystemTransformSequence (00142220)',
		code: 'DICOMObject["00142220"]',
	},
	{
		label: 'TransformDescription (00142222)',
		code: 'DICOMObject["00142222"]',
	},
	{
		label: 'TransformNumberOfAxes (00142224)',
		code: 'DICOMObject["00142224"]',
	},
	{
		label: 'TransformOrderOfAxes (00142226)',
		code: 'DICOMObject["00142226"]',
	},
	{
		label: 'TransformedAxisUnits (00142228)',
		code: 'DICOMObject["00142228"]',
	},
	{
		label: 'CoordinateSystemTransformRotationAndScaleMatrix (0014222A)',
		code: 'DICOMObject["0014222A"]',
	},
	{
		label: 'CoordinateSystemTransformTranslationMatrix (0014222C)',
		code: 'DICOMObject["0014222C"]',
	},
	{
		label: 'InternalDetectorFrameTime (00143011)',
		code: 'DICOMObject["00143011"]',
	},
	{
		label: 'NumberOfFramesIntegrated (00143012)',
		code: 'DICOMObject["00143012"]',
	},
	{
		label: 'DetectorTemperatureSequence (00143020)',
		code: 'DICOMObject["00143020"]',
	},
	{
		label: 'SensorName (00143022)',
		code: 'DICOMObject["00143022"]',
	},
	{
		label: 'HorizontalOffsetOfSensor (00143024)',
		code: 'DICOMObject["00143024"]',
	},
	{
		label: 'VerticalOffsetOfSensor (00143026)',
		code: 'DICOMObject["00143026"]',
	},
	{
		label: 'SensorTemperature (00143028)',
		code: 'DICOMObject["00143028"]',
	},
	{
		label: 'DarkCurrentSequence (00143040)',
		code: 'DICOMObject["00143040"]',
	},
	{
		label: 'DarkCurrentCounts (00143050)',
		code: 'DICOMObject["00143050"]',
	},
	{
		label: 'GainCorrectionReferenceSequence (00143060)',
		code: 'DICOMObject["00143060"]',
	},
	{
		label: 'AirCounts (00143070)',
		code: 'DICOMObject["00143070"]',
	},
	{
		label: 'KVUsedInGainCalibration (00143071)',
		code: 'DICOMObject["00143071"]',
	},
	{
		label: 'MAUsedInGainCalibration (00143072)',
		code: 'DICOMObject["00143072"]',
	},
	{
		label: 'NumberOfFramesUsedForIntegration (00143073)',
		code: 'DICOMObject["00143073"]',
	},
	{
		label: 'FilterMaterialUsedInGainCalibration (00143074)',
		code: 'DICOMObject["00143074"]',
	},
	{
		label: 'FilterThicknessUsedInGainCalibration (00143075)',
		code: 'DICOMObject["00143075"]',
	},
	{
		label: 'DateOfGainCalibration (00143076)',
		code: 'DICOMObject["00143076"]',
	},
	{
		label: 'TimeOfGainCalibration (00143077)',
		code: 'DICOMObject["00143077"]',
	},
	{
		label: 'BadPixelImage (00143080)',
		code: 'DICOMObject["00143080"]',
	},
	{
		label: 'CalibrationNotes (00143099)',
		code: 'DICOMObject["00143099"]',
	},
	{
		label: 'PulserEquipmentSequence (00144002)',
		code: 'DICOMObject["00144002"]',
	},
	{
		label: 'PulserType (00144004)',
		code: 'DICOMObject["00144004"]',
	},
	{
		label: 'PulserNotes (00144006)',
		code: 'DICOMObject["00144006"]',
	},
	{
		label: 'ReceiverEquipmentSequence (00144008)',
		code: 'DICOMObject["00144008"]',
	},
	{
		label: 'AmplifierType (0014400A)',
		code: 'DICOMObject["0014400A"]',
	},
	{
		label: 'ReceiverNotes (0014400C)',
		code: 'DICOMObject["0014400C"]',
	},
	{
		label: 'PreAmplifierEquipmentSequence (0014400E)',
		code: 'DICOMObject["0014400E"]',
	},
	{
		label: 'PreAmplifierNotes (0014400F)',
		code: 'DICOMObject["0014400F"]',
	},
	{
		label: 'TransmitTransducerSequence (00144010)',
		code: 'DICOMObject["00144010"]',
	},
	{
		label: 'ReceiveTransducerSequence (00144011)',
		code: 'DICOMObject["00144011"]',
	},
	{
		label: 'NumberOfElements (00144012)',
		code: 'DICOMObject["00144012"]',
	},
	{
		label: 'ElementShape (00144013)',
		code: 'DICOMObject["00144013"]',
	},
	{
		label: 'ElementDimensionA (00144014)',
		code: 'DICOMObject["00144014"]',
	},
	{
		label: 'ElementDimensionB (00144015)',
		code: 'DICOMObject["00144015"]',
	},
	{
		label: 'ElementPitchA (00144016)',
		code: 'DICOMObject["00144016"]',
	},
	{
		label: 'MeasuredBeamDimensionA (00144017)',
		code: 'DICOMObject["00144017"]',
	},
	{
		label: 'MeasuredBeamDimensionB (00144018)',
		code: 'DICOMObject["00144018"]',
	},
	{
		label: 'LocationOfMeasuredBeamDiameter (00144019)',
		code: 'DICOMObject["00144019"]',
	},
	{
		label: 'NominalFrequency (0014401A)',
		code: 'DICOMObject["0014401A"]',
	},
	{
		label: 'MeasuredCenterFrequency (0014401B)',
		code: 'DICOMObject["0014401B"]',
	},
	{
		label: 'MeasuredBandwidth (0014401C)',
		code: 'DICOMObject["0014401C"]',
	},
	{
		label: 'ElementPitchB (0014401D)',
		code: 'DICOMObject["0014401D"]',
	},
	{
		label: 'PulserSettingsSequence (00144020)',
		code: 'DICOMObject["00144020"]',
	},
	{
		label: 'PulseWidth (00144022)',
		code: 'DICOMObject["00144022"]',
	},
	{
		label: 'ExcitationFrequency (00144024)',
		code: 'DICOMObject["00144024"]',
	},
	{
		label: 'ModulationType (00144026)',
		code: 'DICOMObject["00144026"]',
	},
	{
		label: 'Damping (00144028)',
		code: 'DICOMObject["00144028"]',
	},
	{
		label: 'ReceiverSettingsSequence (00144030)',
		code: 'DICOMObject["00144030"]',
	},
	{
		label: 'AcquiredSoundpathLength (00144031)',
		code: 'DICOMObject["00144031"]',
	},
	{
		label: 'AcquisitionCompressionType (00144032)',
		code: 'DICOMObject["00144032"]',
	},
	{
		label: 'AcquisitionSampleSize (00144033)',
		code: 'DICOMObject["00144033"]',
	},
	{
		label: 'RectifierSmoothing (00144034)',
		code: 'DICOMObject["00144034"]',
	},
	{
		label: 'DACSequence (00144035)',
		code: 'DICOMObject["00144035"]',
	},
	{
		label: 'DACType (00144036)',
		code: 'DICOMObject["00144036"]',
	},
	{
		label: 'DACGainPoints (00144038)',
		code: 'DICOMObject["00144038"]',
	},
	{
		label: 'DACTimePoints (0014403A)',
		code: 'DICOMObject["0014403A"]',
	},
	{
		label: 'DACAmplitude (0014403C)',
		code: 'DICOMObject["0014403C"]',
	},
	{
		label: 'PreAmplifierSettingsSequence (00144040)',
		code: 'DICOMObject["00144040"]',
	},
	{
		label: 'TransmitTransducerSettingsSequence (00144050)',
		code: 'DICOMObject["00144050"]',
	},
	{
		label: 'ReceiveTransducerSettingsSequence (00144051)',
		code: 'DICOMObject["00144051"]',
	},
	{
		label: 'IncidentAngle (00144052)',
		code: 'DICOMObject["00144052"]',
	},
	{
		label: 'CouplingTechnique (00144054)',
		code: 'DICOMObject["00144054"]',
	},
	{
		label: 'CouplingMedium (00144056)',
		code: 'DICOMObject["00144056"]',
	},
	{
		label: 'CouplingVelocity (00144057)',
		code: 'DICOMObject["00144057"]',
	},
	{
		label: 'ProbeCenterLocationX (00144058)',
		code: 'DICOMObject["00144058"]',
	},
	{
		label: 'ProbeCenterLocationZ (00144059)',
		code: 'DICOMObject["00144059"]',
	},
	{
		label: 'SoundPathLength (0014405A)',
		code: 'DICOMObject["0014405A"]',
	},
	{
		label: 'DelayLawIdentifier (0014405C)',
		code: 'DICOMObject["0014405C"]',
	},
	{
		label: 'GateSettingsSequence (00144060)',
		code: 'DICOMObject["00144060"]',
	},
	{
		label: 'GateThreshold (00144062)',
		code: 'DICOMObject["00144062"]',
	},
	{
		label: 'VelocityOfSound (00144064)',
		code: 'DICOMObject["00144064"]',
	},
	{
		label: 'CalibrationSettingsSequence (00144070)',
		code: 'DICOMObject["00144070"]',
	},
	{
		label: 'CalibrationProcedure (00144072)',
		code: 'DICOMObject["00144072"]',
	},
	{
		label: 'ProcedureVersion (00144074)',
		code: 'DICOMObject["00144074"]',
	},
	{
		label: 'ProcedureCreationDate (00144076)',
		code: 'DICOMObject["00144076"]',
	},
	{
		label: 'ProcedureExpirationDate (00144078)',
		code: 'DICOMObject["00144078"]',
	},
	{
		label: 'ProcedureLastModifiedDate (0014407A)',
		code: 'DICOMObject["0014407A"]',
	},
	{
		label: 'CalibrationTime (0014407C)',
		code: 'DICOMObject["0014407C"]',
	},
	{
		label: 'CalibrationDate (0014407E)',
		code: 'DICOMObject["0014407E"]',
	},
	{
		label: 'ProbeDriveEquipmentSequence (00144080)',
		code: 'DICOMObject["00144080"]',
	},
	{
		label: 'DriveType (00144081)',
		code: 'DICOMObject["00144081"]',
	},
	{
		label: 'ProbeDriveNotes (00144082)',
		code: 'DICOMObject["00144082"]',
	},
	{
		label: 'DriveProbeSequence (00144083)',
		code: 'DICOMObject["00144083"]',
	},
	{
		label: 'ProbeInductance (00144084)',
		code: 'DICOMObject["00144084"]',
	},
	{
		label: 'ProbeResistance (00144085)',
		code: 'DICOMObject["00144085"]',
	},
	{
		label: 'ReceiveProbeSequence (00144086)',
		code: 'DICOMObject["00144086"]',
	},
	{
		label: 'ProbeDriveSettingsSequence (00144087)',
		code: 'DICOMObject["00144087"]',
	},
	{
		label: 'BridgeResistors (00144088)',
		code: 'DICOMObject["00144088"]',
	},
	{
		label: 'ProbeOrientationAngle (00144089)',
		code: 'DICOMObject["00144089"]',
	},
	{
		label: 'UserSelectedGainY (0014408B)',
		code: 'DICOMObject["0014408B"]',
	},
	{
		label: 'UserSelectedPhase (0014408C)',
		code: 'DICOMObject["0014408C"]',
	},
	{
		label: 'UserSelectedOffsetX (0014408D)',
		code: 'DICOMObject["0014408D"]',
	},
	{
		label: 'UserSelectedOffsetY (0014408E)',
		code: 'DICOMObject["0014408E"]',
	},
	{
		label: 'ChannelSettingsSequence (00144091)',
		code: 'DICOMObject["00144091"]',
	},
	{
		label: 'ChannelThreshold (00144092)',
		code: 'DICOMObject["00144092"]',
	},
	{
		label: 'ScannerSettingsSequence (0014409A)',
		code: 'DICOMObject["0014409A"]',
	},
	{
		label: 'ScanProcedure (0014409B)',
		code: 'DICOMObject["0014409B"]',
	},
	{
		label: 'TranslationRateX (0014409C)',
		code: 'DICOMObject["0014409C"]',
	},
	{
		label: 'TranslationRateY (0014409D)',
		code: 'DICOMObject["0014409D"]',
	},
	{
		label: 'ChannelOverlap (0014409F)',
		code: 'DICOMObject["0014409F"]',
	},
	{
		label: 'ImageQualityIndicatorType (001440A0)',
		code: 'DICOMObject["001440A0"]',
	},
	{
		label: 'ImageQualityIndicatorMaterial (001440A1)',
		code: 'DICOMObject["001440A1"]',
	},
	{
		label: 'ImageQualityIndicatorSize (001440A2)',
		code: 'DICOMObject["001440A2"]',
	},
	{
		label: 'LINACEnergy (00145002)',
		code: 'DICOMObject["00145002"]',
	},
	{
		label: 'LINACOutput (00145004)',
		code: 'DICOMObject["00145004"]',
	},
	{
		label: 'ActiveAperture (00145100)',
		code: 'DICOMObject["00145100"]',
	},
	{
		label: 'TotalAperture (00145101)',
		code: 'DICOMObject["00145101"]',
	},
	{
		label: 'ApertureElevation (00145102)',
		code: 'DICOMObject["00145102"]',
	},
	{
		label: 'MainLobeAngle (00145103)',
		code: 'DICOMObject["00145103"]',
	},
	{
		label: 'MainRoofAngle (00145104)',
		code: 'DICOMObject["00145104"]',
	},
	{
		label: 'ConnectorType (00145105)',
		code: 'DICOMObject["00145105"]',
	},
	{
		label: 'WedgeModelNumber (00145106)',
		code: 'DICOMObject["00145106"]',
	},
	{
		label: 'WedgeAngleFloat (00145107)',
		code: 'DICOMObject["00145107"]',
	},
	{
		label: 'WedgeRoofAngle (00145108)',
		code: 'DICOMObject["00145108"]',
	},
	{
		label: 'WedgeElement1Position (00145109)',
		code: 'DICOMObject["00145109"]',
	},
	{
		label: 'WedgeMaterialVelocity (0014510A)',
		code: 'DICOMObject["0014510A"]',
	},
	{
		label: 'WedgeMaterial (0014510B)',
		code: 'DICOMObject["0014510B"]',
	},
	{
		label: 'WedgeOffsetZ (0014510C)',
		code: 'DICOMObject["0014510C"]',
	},
	{
		label: 'WedgeOriginOffsetX (0014510D)',
		code: 'DICOMObject["0014510D"]',
	},
	{
		label: 'WedgeTimeDelay (0014510E)',
		code: 'DICOMObject["0014510E"]',
	},
	{
		label: 'WedgeName (0014510F)',
		code: 'DICOMObject["0014510F"]',
	},
	{
		label: 'WedgeManufacturerName (00145110)',
		code: 'DICOMObject["00145110"]',
	},
	{
		label: 'WedgeDescription (00145111)',
		code: 'DICOMObject["00145111"]',
	},
	{
		label: 'NominalBeamAngle (00145112)',
		code: 'DICOMObject["00145112"]',
	},
	{
		label: 'WedgeOffsetX (00145113)',
		code: 'DICOMObject["00145113"]',
	},
	{
		label: 'WedgeOffsetY (00145114)',
		code: 'DICOMObject["00145114"]',
	},
	{
		label: 'WedgeTotalLength (00145115)',
		code: 'DICOMObject["00145115"]',
	},
	{
		label: 'WedgeInContactLength (00145116)',
		code: 'DICOMObject["00145116"]',
	},
	{
		label: 'WedgeFrontGap (00145117)',
		code: 'DICOMObject["00145117"]',
	},
	{
		label: 'WedgeTotalHeight (00145118)',
		code: 'DICOMObject["00145118"]',
	},
	{
		label: 'WedgeFrontHeight (00145119)',
		code: 'DICOMObject["00145119"]',
	},
	{
		label: 'WedgeRearHeight (0014511A)',
		code: 'DICOMObject["0014511A"]',
	},
	{
		label: 'WedgeTotalWidth (0014511B)',
		code: 'DICOMObject["0014511B"]',
	},
	{
		label: 'WedgeInContactWidth (0014511C)',
		code: 'DICOMObject["0014511C"]',
	},
	{
		label: 'WedgeChamferHeight (0014511D)',
		code: 'DICOMObject["0014511D"]',
	},
	{
		label: 'WedgeCurve (0014511E)',
		code: 'DICOMObject["0014511E"]',
	},
	{
		label: 'RadiusAlongWedge (0014511F)',
		code: 'DICOMObject["0014511F"]',
	},
	{
		label: 'ContrastBolusAgent (00180010)',
		code: 'DICOMObject["00180010"]',
	},
	{
		label: 'ContrastBolusAgentSequence (00180012)',
		code: 'DICOMObject["00180012"]',
	},
	{
		label: 'ContrastBolusT1Relaxivity (00180013)',
		code: 'DICOMObject["00180013"]',
	},
	{
		label: 'ContrastBolusAdministrationRouteSequence (00180014)',
		code: 'DICOMObject["00180014"]',
	},
	{
		label: 'BodyPartExamined (00180015)',
		code: 'DICOMObject["00180015"]',
	},
	{
		label: 'ScanningSequence (00180020)',
		code: 'DICOMObject["00180020"]',
	},
	{
		label: 'SequenceVariant (00180021)',
		code: 'DICOMObject["00180021"]',
	},
	{
		label: 'ScanOptions (00180022)',
		code: 'DICOMObject["00180022"]',
	},
	{
		label: 'MRAcquisitionType (00180023)',
		code: 'DICOMObject["00180023"]',
	},
	{
		label: 'SequenceName (00180024)',
		code: 'DICOMObject["00180024"]',
	},
	{
		label: 'AngioFlag (00180025)',
		code: 'DICOMObject["00180025"]',
	},
	{
		label: 'InterventionDrugInformationSequence (00180026)',
		code: 'DICOMObject["00180026"]',
	},
	{
		label: 'InterventionDrugStopTime (00180027)',
		code: 'DICOMObject["00180027"]',
	},
	{
		label: 'InterventionDrugDose (00180028)',
		code: 'DICOMObject["00180028"]',
	},
	{
		label: 'InterventionDrugCodeSequence (00180029)',
		code: 'DICOMObject["00180029"]',
	},
	{
		label: 'AdditionalDrugSequence (0018002A)',
		code: 'DICOMObject["0018002A"]',
	},
	{
		label: 'Radiopharmaceutical (00180031)',
		code: 'DICOMObject["00180031"]',
	},
	{
		label: 'InterventionDrugName (00180034)',
		code: 'DICOMObject["00180034"]',
	},
	{
		label: 'InterventionDrugStartTime (00180035)',
		code: 'DICOMObject["00180035"]',
	},
	{
		label: 'InterventionSequence (00180036)',
		code: 'DICOMObject["00180036"]',
	},
	{
		label: 'InterventionStatus (00180038)',
		code: 'DICOMObject["00180038"]',
	},
	{
		label: 'InterventionDescription (0018003A)',
		code: 'DICOMObject["0018003A"]',
	},
	{
		label: 'CineRate (00180040)',
		code: 'DICOMObject["00180040"]',
	},
	{
		label: 'InitialCineRunState (00180042)',
		code: 'DICOMObject["00180042"]',
	},
	{
		label: 'SliceThickness (00180050)',
		code: 'DICOMObject["00180050"]',
	},
	{
		label: 'KVP (00180060)',
		code: 'DICOMObject["00180060"]',
	},
	{
		label: 'CountsAccumulated (00180070)',
		code: 'DICOMObject["00180070"]',
	},
	{
		label: 'AcquisitionTerminationCondition (00180071)',
		code: 'DICOMObject["00180071"]',
	},
	{
		label: 'EffectiveDuration (00180072)',
		code: 'DICOMObject["00180072"]',
	},
	{
		label: 'AcquisitionStartCondition (00180073)',
		code: 'DICOMObject["00180073"]',
	},
	{
		label: 'AcquisitionStartConditionData (00180074)',
		code: 'DICOMObject["00180074"]',
	},
	{
		label: 'AcquisitionTerminationConditionData (00180075)',
		code: 'DICOMObject["00180075"]',
	},
	{
		label: 'RepetitionTime (00180080)',
		code: 'DICOMObject["00180080"]',
	},
	{
		label: 'EchoTime (00180081)',
		code: 'DICOMObject["00180081"]',
	},
	{
		label: 'InversionTime (00180082)',
		code: 'DICOMObject["00180082"]',
	},
	{
		label: 'NumberOfAverages (00180083)',
		code: 'DICOMObject["00180083"]',
	},
	{
		label: 'ImagingFrequency (00180084)',
		code: 'DICOMObject["00180084"]',
	},
	{
		label: 'ImagedNucleus (00180085)',
		code: 'DICOMObject["00180085"]',
	},
	{
		label: 'EchoNumbers (00180086)',
		code: 'DICOMObject["00180086"]',
	},
	{
		label: 'MagneticFieldStrength (00180087)',
		code: 'DICOMObject["00180087"]',
	},
	{
		label: 'SpacingBetweenSlices (00180088)',
		code: 'DICOMObject["00180088"]',
	},
	{
		label: 'NumberOfPhaseEncodingSteps (00180089)',
		code: 'DICOMObject["00180089"]',
	},
	{
		label: 'DataCollectionDiameter (00180090)',
		code: 'DICOMObject["00180090"]',
	},
	{
		label: 'EchoTrainLength (00180091)',
		code: 'DICOMObject["00180091"]',
	},
	{
		label: 'PercentSampling (00180093)',
		code: 'DICOMObject["00180093"]',
	},
	{
		label: 'PercentPhaseFieldOfView (00180094)',
		code: 'DICOMObject["00180094"]',
	},
	{
		label: 'PixelBandwidth (00180095)',
		code: 'DICOMObject["00180095"]',
	},
	{
		label: 'DeviceSerialNumber (00181000)',
		code: 'DICOMObject["00181000"]',
	},
	{
		label: 'DeviceUID (00181002)',
		code: 'DICOMObject["00181002"]',
	},
	{
		label: 'DeviceID (00181003)',
		code: 'DICOMObject["00181003"]',
	},
	{
		label: 'PlateID (00181004)',
		code: 'DICOMObject["00181004"]',
	},
	{
		label: 'GeneratorID (00181005)',
		code: 'DICOMObject["00181005"]',
	},
	{
		label: 'GridID (00181006)',
		code: 'DICOMObject["00181006"]',
	},
	{
		label: 'CassetteID (00181007)',
		code: 'DICOMObject["00181007"]',
	},
	{
		label: 'GantryID (00181008)',
		code: 'DICOMObject["00181008"]',
	},
	{
		label: 'UniqueDeviceIdentifier (00181009)',
		code: 'DICOMObject["00181009"]',
	},
	{
		label: 'UDISequence (0018100A)',
		code: 'DICOMObject["0018100A"]',
	},
	{
		label: 'SecondaryCaptureDeviceID (00181010)',
		code: 'DICOMObject["00181010"]',
	},
	{
		label: 'DateOfSecondaryCapture (00181012)',
		code: 'DICOMObject["00181012"]',
	},
	{
		label: 'TimeOfSecondaryCapture (00181014)',
		code: 'DICOMObject["00181014"]',
	},
	{
		label: 'SecondaryCaptureDeviceManufacturer (00181016)',
		code: 'DICOMObject["00181016"]',
	},
	{
		label: 'SecondaryCaptureDeviceManufacturerModelName (00181018)',
		code: 'DICOMObject["00181018"]',
	},
	{
		label: 'SecondaryCaptureDeviceSoftwareVersions (00181019)',
		code: 'DICOMObject["00181019"]',
	},
	{
		label: 'SoftwareVersions (00181020)',
		code: 'DICOMObject["00181020"]',
	},
	{
		label: 'VideoImageFormatAcquired (00181022)',
		code: 'DICOMObject["00181022"]',
	},
	{
		label: 'DigitalImageFormatAcquired (00181023)',
		code: 'DICOMObject["00181023"]',
	},
	{
		label: 'ProtocolName (00181030)',
		code: 'DICOMObject["00181030"]',
	},
	{
		label: 'ContrastBolusRoute (00181040)',
		code: 'DICOMObject["00181040"]',
	},
	{
		label: 'ContrastBolusVolume (00181041)',
		code: 'DICOMObject["00181041"]',
	},
	{
		label: 'ContrastBolusStartTime (00181042)',
		code: 'DICOMObject["00181042"]',
	},
	{
		label: 'ContrastBolusStopTime (00181043)',
		code: 'DICOMObject["00181043"]',
	},
	{
		label: 'ContrastBolusTotalDose (00181044)',
		code: 'DICOMObject["00181044"]',
	},
	{
		label: 'SyringeCounts (00181045)',
		code: 'DICOMObject["00181045"]',
	},
	{
		label: 'ContrastFlowRate (00181046)',
		code: 'DICOMObject["00181046"]',
	},
	{
		label: 'ContrastFlowDuration (00181047)',
		code: 'DICOMObject["00181047"]',
	},
	{
		label: 'ContrastBolusIngredient (00181048)',
		code: 'DICOMObject["00181048"]',
	},
	{
		label: 'ContrastBolusIngredientConcentration (00181049)',
		code: 'DICOMObject["00181049"]',
	},
	{
		label: 'SpatialResolution (00181050)',
		code: 'DICOMObject["00181050"]',
	},
	{
		label: 'TriggerTime (00181060)',
		code: 'DICOMObject["00181060"]',
	},
	{
		label: 'TriggerSourceOrType (00181061)',
		code: 'DICOMObject["00181061"]',
	},
	{
		label: 'NominalInterval (00181062)',
		code: 'DICOMObject["00181062"]',
	},
	{
		label: 'FrameTime (00181063)',
		code: 'DICOMObject["00181063"]',
	},
	{
		label: 'CardiacFramingType (00181064)',
		code: 'DICOMObject["00181064"]',
	},
	{
		label: 'FrameTimeVector (00181065)',
		code: 'DICOMObject["00181065"]',
	},
	{
		label: 'FrameDelay (00181066)',
		code: 'DICOMObject["00181066"]',
	},
	{
		label: 'ImageTriggerDelay (00181067)',
		code: 'DICOMObject["00181067"]',
	},
	{
		label: 'MultiplexGroupTimeOffset (00181068)',
		code: 'DICOMObject["00181068"]',
	},
	{
		label: 'TriggerTimeOffset (00181069)',
		code: 'DICOMObject["00181069"]',
	},
	{
		label: 'SynchronizationTrigger (0018106A)',
		code: 'DICOMObject["0018106A"]',
	},
	{
		label: 'SynchronizationChannel (0018106C)',
		code: 'DICOMObject["0018106C"]',
	},
	{
		label: 'TriggerSamplePosition (0018106E)',
		code: 'DICOMObject["0018106E"]',
	},
	{
		label: 'RadiopharmaceuticalRoute (00181070)',
		code: 'DICOMObject["00181070"]',
	},
	{
		label: 'RadiopharmaceuticalVolume (00181071)',
		code: 'DICOMObject["00181071"]',
	},
	{
		label: 'RadiopharmaceuticalStartTime (00181072)',
		code: 'DICOMObject["00181072"]',
	},
	{
		label: 'RadiopharmaceuticalStopTime (00181073)',
		code: 'DICOMObject["00181073"]',
	},
	{
		label: 'RadionuclideTotalDose (00181074)',
		code: 'DICOMObject["00181074"]',
	},
	{
		label: 'RadionuclideHalfLife (00181075)',
		code: 'DICOMObject["00181075"]',
	},
	{
		label: 'RadionuclidePositronFraction (00181076)',
		code: 'DICOMObject["00181076"]',
	},
	{
		label: 'RadiopharmaceuticalSpecificActivity (00181077)',
		code: 'DICOMObject["00181077"]',
	},
	{
		label: 'RadiopharmaceuticalStartDateTime (00181078)',
		code: 'DICOMObject["00181078"]',
	},
	{
		label: 'RadiopharmaceuticalStopDateTime (00181079)',
		code: 'DICOMObject["00181079"]',
	},
	{
		label: 'BeatRejectionFlag (00181080)',
		code: 'DICOMObject["00181080"]',
	},
	{
		label: 'LowRRValue (00181081)',
		code: 'DICOMObject["00181081"]',
	},
	{
		label: 'HighRRValue (00181082)',
		code: 'DICOMObject["00181082"]',
	},
	{
		label: 'IntervalsAcquired (00181083)',
		code: 'DICOMObject["00181083"]',
	},
	{
		label: 'IntervalsRejected (00181084)',
		code: 'DICOMObject["00181084"]',
	},
	{
		label: 'PVCRejection (00181085)',
		code: 'DICOMObject["00181085"]',
	},
	{
		label: 'SkipBeats (00181086)',
		code: 'DICOMObject["00181086"]',
	},
	{
		label: 'HeartRate (00181088)',
		code: 'DICOMObject["00181088"]',
	},
	{
		label: 'CardiacNumberOfImages (00181090)',
		code: 'DICOMObject["00181090"]',
	},
	{
		label: 'TriggerWindow (00181094)',
		code: 'DICOMObject["00181094"]',
	},
	{
		label: 'ReconstructionDiameter (00181100)',
		code: 'DICOMObject["00181100"]',
	},
	{
		label: 'DistanceSourceToDetector (00181110)',
		code: 'DICOMObject["00181110"]',
	},
	{
		label: 'DistanceSourceToPatient (00181111)',
		code: 'DICOMObject["00181111"]',
	},
	{
		label: 'EstimatedRadiographicMagnificationFactor (00181114)',
		code: 'DICOMObject["00181114"]',
	},
	{
		label: 'GantryDetectorTilt (00181120)',
		code: 'DICOMObject["00181120"]',
	},
	{
		label: 'GantryDetectorSlew (00181121)',
		code: 'DICOMObject["00181121"]',
	},
	{
		label: 'TableHeight (00181130)',
		code: 'DICOMObject["00181130"]',
	},
	{
		label: 'TableTraverse (00181131)',
		code: 'DICOMObject["00181131"]',
	},
	{
		label: 'TableMotion (00181134)',
		code: 'DICOMObject["00181134"]',
	},
	{
		label: 'TableVerticalIncrement (00181135)',
		code: 'DICOMObject["00181135"]',
	},
	{
		label: 'TableLateralIncrement (00181136)',
		code: 'DICOMObject["00181136"]',
	},
	{
		label: 'TableLongitudinalIncrement (00181137)',
		code: 'DICOMObject["00181137"]',
	},
	{
		label: 'TableAngle (00181138)',
		code: 'DICOMObject["00181138"]',
	},
	{
		label: 'TableType (0018113A)',
		code: 'DICOMObject["0018113A"]',
	},
	{
		label: 'RotationDirection (00181140)',
		code: 'DICOMObject["00181140"]',
	},
	{
		label: 'RadialPosition (00181142)',
		code: 'DICOMObject["00181142"]',
	},
	{
		label: 'ScanArc (00181143)',
		code: 'DICOMObject["00181143"]',
	},
	{
		label: 'AngularStep (00181144)',
		code: 'DICOMObject["00181144"]',
	},
	{
		label: 'CenterOfRotationOffset (00181145)',
		code: 'DICOMObject["00181145"]',
	},
	{
		label: 'FieldOfViewShape (00181147)',
		code: 'DICOMObject["00181147"]',
	},
	{
		label: 'FieldOfViewDimensions (00181149)',
		code: 'DICOMObject["00181149"]',
	},
	{
		label: 'ExposureTime (00181150)',
		code: 'DICOMObject["00181150"]',
	},
	{
		label: 'XRayTubeCurrent (00181151)',
		code: 'DICOMObject["00181151"]',
	},
	{
		label: 'Exposure (00181152)',
		code: 'DICOMObject["00181152"]',
	},
	{
		label: 'ExposureInuAs (00181153)',
		code: 'DICOMObject["00181153"]',
	},
	{
		label: 'AveragePulseWidth (00181154)',
		code: 'DICOMObject["00181154"]',
	},
	{
		label: 'RadiationSetting (00181155)',
		code: 'DICOMObject["00181155"]',
	},
	{
		label: 'RectificationType (00181156)',
		code: 'DICOMObject["00181156"]',
	},
	{
		label: 'RadiationMode (0018115A)',
		code: 'DICOMObject["0018115A"]',
	},
	{
		label: 'ImageAndFluoroscopyAreaDoseProduct (0018115E)',
		code: 'DICOMObject["0018115E"]',
	},
	{
		label: 'FilterType (00181160)',
		code: 'DICOMObject["00181160"]',
	},
	{
		label: 'TypeOfFilters (00181161)',
		code: 'DICOMObject["00181161"]',
	},
	{
		label: 'IntensifierSize (00181162)',
		code: 'DICOMObject["00181162"]',
	},
	{
		label: 'ImagerPixelSpacing (00181164)',
		code: 'DICOMObject["00181164"]',
	},
	{
		label: 'Grid (00181166)',
		code: 'DICOMObject["00181166"]',
	},
	{
		label: 'GeneratorPower (00181170)',
		code: 'DICOMObject["00181170"]',
	},
	{
		label: 'CollimatorGridName (00181180)',
		code: 'DICOMObject["00181180"]',
	},
	{
		label: 'CollimatorType (00181181)',
		code: 'DICOMObject["00181181"]',
	},
	{
		label: 'FocalDistance (00181182)',
		code: 'DICOMObject["00181182"]',
	},
	{
		label: 'XFocusCenter (00181183)',
		code: 'DICOMObject["00181183"]',
	},
	{
		label: 'YFocusCenter (00181184)',
		code: 'DICOMObject["00181184"]',
	},
	{
		label: 'FocalSpots (00181190)',
		code: 'DICOMObject["00181190"]',
	},
	{
		label: 'AnodeTargetMaterial (00181191)',
		code: 'DICOMObject["00181191"]',
	},
	{
		label: 'BodyPartThickness (001811A0)',
		code: 'DICOMObject["001811A0"]',
	},
	{
		label: 'CompressionForce (001811A2)',
		code: 'DICOMObject["001811A2"]',
	},
	{
		label: 'PaddleDescription (001811A4)',
		code: 'DICOMObject["001811A4"]',
	},
	{
		label: 'DateOfLastCalibration (00181200)',
		code: 'DICOMObject["00181200"]',
	},
	{
		label: 'TimeOfLastCalibration (00181201)',
		code: 'DICOMObject["00181201"]',
	},
	{
		label: 'DateTimeOfLastCalibration (00181202)',
		code: 'DICOMObject["00181202"]',
	},
	{
		label: 'ConvolutionKernel (00181210)',
		code: 'DICOMObject["00181210"]',
	},
	{
		label: 'ActualFrameDuration (00181242)',
		code: 'DICOMObject["00181242"]',
	},
	{
		label: 'CountRate (00181243)',
		code: 'DICOMObject["00181243"]',
	},
	{
		label: 'PreferredPlaybackSequencing (00181244)',
		code: 'DICOMObject["00181244"]',
	},
	{
		label: 'ReceiveCoilName (00181250)',
		code: 'DICOMObject["00181250"]',
	},
	{
		label: 'TransmitCoilName (00181251)',
		code: 'DICOMObject["00181251"]',
	},
	{
		label: 'PlateType (00181260)',
		code: 'DICOMObject["00181260"]',
	},
	{
		label: 'PhosphorType (00181261)',
		code: 'DICOMObject["00181261"]',
	},
	{
		label: 'WaterEquivalentDiameter (00181271)',
		code: 'DICOMObject["00181271"]',
	},
	{
		label: 'WaterEquivalentDiameterCalculationMethodCodeSequence (00181272)',
		code: 'DICOMObject["00181272"]',
	},
	{
		label: 'ScanVelocity (00181300)',
		code: 'DICOMObject["00181300"]',
	},
	{
		label: 'WholeBodyTechnique (00181301)',
		code: 'DICOMObject["00181301"]',
	},
	{
		label: 'ScanLength (00181302)',
		code: 'DICOMObject["00181302"]',
	},
	{
		label: 'AcquisitionMatrix (00181310)',
		code: 'DICOMObject["00181310"]',
	},
	{
		label: 'InPlanePhaseEncodingDirection (00181312)',
		code: 'DICOMObject["00181312"]',
	},
	{
		label: 'FlipAngle (00181314)',
		code: 'DICOMObject["00181314"]',
	},
	{
		label: 'VariableFlipAngleFlag (00181315)',
		code: 'DICOMObject["00181315"]',
	},
	{
		label: 'SAR (00181316)',
		code: 'DICOMObject["00181316"]',
	},
	{
		label: 'dBdt (00181318)',
		code: 'DICOMObject["00181318"]',
	},
	{
		label: 'B1rms (00181320)',
		code: 'DICOMObject["00181320"]',
	},
	{
		label: 'AcquisitionDeviceProcessingDescription (00181400)',
		code: 'DICOMObject["00181400"]',
	},
	{
		label: 'AcquisitionDeviceProcessingCode (00181401)',
		code: 'DICOMObject["00181401"]',
	},
	{
		label: 'CassetteOrientation (00181402)',
		code: 'DICOMObject["00181402"]',
	},
	{
		label: 'CassetteSize (00181403)',
		code: 'DICOMObject["00181403"]',
	},
	{
		label: 'ExposuresOnPlate (00181404)',
		code: 'DICOMObject["00181404"]',
	},
	{
		label: 'RelativeXRayExposure (00181405)',
		code: 'DICOMObject["00181405"]',
	},
	{
		label: 'ExposureIndex (00181411)',
		code: 'DICOMObject["00181411"]',
	},
	{
		label: 'TargetExposureIndex (00181412)',
		code: 'DICOMObject["00181412"]',
	},
	{
		label: 'DeviationIndex (00181413)',
		code: 'DICOMObject["00181413"]',
	},
	{
		label: 'ColumnAngulation (00181450)',
		code: 'DICOMObject["00181450"]',
	},
	{
		label: 'TomoLayerHeight (00181460)',
		code: 'DICOMObject["00181460"]',
	},
	{
		label: 'TomoAngle (00181470)',
		code: 'DICOMObject["00181470"]',
	},
	{
		label: 'TomoTime (00181480)',
		code: 'DICOMObject["00181480"]',
	},
	{
		label: 'TomoType (00181490)',
		code: 'DICOMObject["00181490"]',
	},
	{
		label: 'TomoClass (00181491)',
		code: 'DICOMObject["00181491"]',
	},
	{
		label: 'NumberOfTomosynthesisSourceImages (00181495)',
		code: 'DICOMObject["00181495"]',
	},
	{
		label: 'PositionerMotion (00181500)',
		code: 'DICOMObject["00181500"]',
	},
	{
		label: 'PositionerType (00181508)',
		code: 'DICOMObject["00181508"]',
	},
	{
		label: 'PositionerPrimaryAngle (00181510)',
		code: 'DICOMObject["00181510"]',
	},
	{
		label: 'PositionerSecondaryAngle (00181511)',
		code: 'DICOMObject["00181511"]',
	},
	{
		label: 'PositionerPrimaryAngleIncrement (00181520)',
		code: 'DICOMObject["00181520"]',
	},
	{
		label: 'PositionerSecondaryAngleIncrement (00181521)',
		code: 'DICOMObject["00181521"]',
	},
	{
		label: 'DetectorPrimaryAngle (00181530)',
		code: 'DICOMObject["00181530"]',
	},
	{
		label: 'DetectorSecondaryAngle (00181531)',
		code: 'DICOMObject["00181531"]',
	},
	{
		label: 'ShutterShape (00181600)',
		code: 'DICOMObject["00181600"]',
	},
	{
		label: 'ShutterLeftVerticalEdge (00181602)',
		code: 'DICOMObject["00181602"]',
	},
	{
		label: 'ShutterRightVerticalEdge (00181604)',
		code: 'DICOMObject["00181604"]',
	},
	{
		label: 'ShutterUpperHorizontalEdge (00181606)',
		code: 'DICOMObject["00181606"]',
	},
	{
		label: 'ShutterLowerHorizontalEdge (00181608)',
		code: 'DICOMObject["00181608"]',
	},
	{
		label: 'CenterOfCircularShutter (00181610)',
		code: 'DICOMObject["00181610"]',
	},
	{
		label: 'RadiusOfCircularShutter (00181612)',
		code: 'DICOMObject["00181612"]',
	},
	{
		label: 'VerticesOfThePolygonalShutter (00181620)',
		code: 'DICOMObject["00181620"]',
	},
	{
		label: 'ShutterPresentationValue (00181622)',
		code: 'DICOMObject["00181622"]',
	},
	{
		label: 'ShutterOverlayGroup (00181623)',
		code: 'DICOMObject["00181623"]',
	},
	{
		label: 'ShutterPresentationColorCIELabValue (00181624)',
		code: 'DICOMObject["00181624"]',
	},
	{
		label: 'CollimatorShape (00181700)',
		code: 'DICOMObject["00181700"]',
	},
	{
		label: 'CollimatorLeftVerticalEdge (00181702)',
		code: 'DICOMObject["00181702"]',
	},
	{
		label: 'CollimatorRightVerticalEdge (00181704)',
		code: 'DICOMObject["00181704"]',
	},
	{
		label: 'CollimatorUpperHorizontalEdge (00181706)',
		code: 'DICOMObject["00181706"]',
	},
	{
		label: 'CollimatorLowerHorizontalEdge (00181708)',
		code: 'DICOMObject["00181708"]',
	},
	{
		label: 'CenterOfCircularCollimator (00181710)',
		code: 'DICOMObject["00181710"]',
	},
	{
		label: 'RadiusOfCircularCollimator (00181712)',
		code: 'DICOMObject["00181712"]',
	},
	{
		label: 'VerticesOfThePolygonalCollimator (00181720)',
		code: 'DICOMObject["00181720"]',
	},
	{
		label: 'AcquisitionTimeSynchronized (00181800)',
		code: 'DICOMObject["00181800"]',
	},
	{
		label: 'TimeSource (00181801)',
		code: 'DICOMObject["00181801"]',
	},
	{
		label: 'TimeDistributionProtocol (00181802)',
		code: 'DICOMObject["00181802"]',
	},
	{
		label: 'NTPSourceAddress (00181803)',
		code: 'DICOMObject["00181803"]',
	},
	{
		label: 'PageNumberVector (00182001)',
		code: 'DICOMObject["00182001"]',
	},
	{
		label: 'FrameLabelVector (00182002)',
		code: 'DICOMObject["00182002"]',
	},
	{
		label: 'FramePrimaryAngleVector (00182003)',
		code: 'DICOMObject["00182003"]',
	},
	{
		label: 'FrameSecondaryAngleVector (00182004)',
		code: 'DICOMObject["00182004"]',
	},
	{
		label: 'SliceLocationVector (00182005)',
		code: 'DICOMObject["00182005"]',
	},
	{
		label: 'DisplayWindowLabelVector (00182006)',
		code: 'DICOMObject["00182006"]',
	},
	{
		label: 'NominalScannedPixelSpacing (00182010)',
		code: 'DICOMObject["00182010"]',
	},
	{
		label: 'DigitizingDeviceTransportDirection (00182020)',
		code: 'DICOMObject["00182020"]',
	},
	{
		label: 'RotationOfScannedFilm (00182030)',
		code: 'DICOMObject["00182030"]',
	},
	{
		label: 'BiopsyTargetSequence (00182041)',
		code: 'DICOMObject["00182041"]',
	},
	{
		label: 'TargetUID (00182042)',
		code: 'DICOMObject["00182042"]',
	},
	{
		label: 'LocalizingCursorPosition (00182043)',
		code: 'DICOMObject["00182043"]',
	},
	{
		label: 'CalculatedTargetPosition (00182044)',
		code: 'DICOMObject["00182044"]',
	},
	{
		label: 'TargetLabel (00182045)',
		code: 'DICOMObject["00182045"]',
	},
	{
		label: 'DisplayedZValue (00182046)',
		code: 'DICOMObject["00182046"]',
	},
	{
		label: 'IVUSAcquisition (00183100)',
		code: 'DICOMObject["00183100"]',
	},
	{
		label: 'IVUSPullbackRate (00183101)',
		code: 'DICOMObject["00183101"]',
	},
	{
		label: 'IVUSGatedRate (00183102)',
		code: 'DICOMObject["00183102"]',
	},
	{
		label: 'IVUSPullbackStartFrameNumber (00183103)',
		code: 'DICOMObject["00183103"]',
	},
	{
		label: 'IVUSPullbackStopFrameNumber (00183104)',
		code: 'DICOMObject["00183104"]',
	},
	{
		label: 'LesionNumber (00183105)',
		code: 'DICOMObject["00183105"]',
	},
	{
		label: 'OutputPower (00185000)',
		code: 'DICOMObject["00185000"]',
	},
	{
		label: 'TransducerData (00185010)',
		code: 'DICOMObject["00185010"]',
	},
	{
		label: 'FocusDepth (00185012)',
		code: 'DICOMObject["00185012"]',
	},
	{
		label: 'ProcessingFunction (00185020)',
		code: 'DICOMObject["00185020"]',
	},
	{
		label: 'MechanicalIndex (00185022)',
		code: 'DICOMObject["00185022"]',
	},
	{
		label: 'BoneThermalIndex (00185024)',
		code: 'DICOMObject["00185024"]',
	},
	{
		label: 'CranialThermalIndex (00185026)',
		code: 'DICOMObject["00185026"]',
	},
	{
		label: 'SoftTissueThermalIndex (00185027)',
		code: 'DICOMObject["00185027"]',
	},
	{
		label: 'SoftTissueFocusThermalIndex (00185028)',
		code: 'DICOMObject["00185028"]',
	},
	{
		label: 'SoftTissueSurfaceThermalIndex (00185029)',
		code: 'DICOMObject["00185029"]',
	},
	{
		label: 'DepthOfScanField (00185050)',
		code: 'DICOMObject["00185050"]',
	},
	{
		label: 'PatientPosition (00185100)',
		code: 'DICOMObject["00185100"]',
	},
	{
		label: 'ViewPosition (00185101)',
		code: 'DICOMObject["00185101"]',
	},
	{
		label: 'ProjectionEponymousNameCodeSequence (00185104)',
		code: 'DICOMObject["00185104"]',
	},
	{
		label: 'Sensitivity (00186000)',
		code: 'DICOMObject["00186000"]',
	},
	{
		label: 'SequenceOfUltrasoundRegions (00186011)',
		code: 'DICOMObject["00186011"]',
	},
	{
		label: 'RegionSpatialFormat (00186012)',
		code: 'DICOMObject["00186012"]',
	},
	{
		label: 'RegionDataType (00186014)',
		code: 'DICOMObject["00186014"]',
	},
	{
		label: 'RegionFlags (00186016)',
		code: 'DICOMObject["00186016"]',
	},
	{
		label: 'RegionLocationMinX0 (00186018)',
		code: 'DICOMObject["00186018"]',
	},
	{
		label: 'RegionLocationMinY0 (0018601A)',
		code: 'DICOMObject["0018601A"]',
	},
	{
		label: 'RegionLocationMaxX1 (0018601C)',
		code: 'DICOMObject["0018601C"]',
	},
	{
		label: 'RegionLocationMaxY1 (0018601E)',
		code: 'DICOMObject["0018601E"]',
	},
	{
		label: 'ReferencePixelX0 (00186020)',
		code: 'DICOMObject["00186020"]',
	},
	{
		label: 'ReferencePixelY0 (00186022)',
		code: 'DICOMObject["00186022"]',
	},
	{
		label: 'PhysicalUnitsXDirection (00186024)',
		code: 'DICOMObject["00186024"]',
	},
	{
		label: 'PhysicalUnitsYDirection (00186026)',
		code: 'DICOMObject["00186026"]',
	},
	{
		label: 'ReferencePixelPhysicalValueX (00186028)',
		code: 'DICOMObject["00186028"]',
	},
	{
		label: 'ReferencePixelPhysicalValueY (0018602A)',
		code: 'DICOMObject["0018602A"]',
	},
	{
		label: 'PhysicalDeltaX (0018602C)',
		code: 'DICOMObject["0018602C"]',
	},
	{
		label: 'PhysicalDeltaY (0018602E)',
		code: 'DICOMObject["0018602E"]',
	},
	{
		label: 'TransducerFrequency (00186030)',
		code: 'DICOMObject["00186030"]',
	},
	{
		label: 'TransducerType (00186031)',
		code: 'DICOMObject["00186031"]',
	},
	{
		label: 'PulseRepetitionFrequency (00186032)',
		code: 'DICOMObject["00186032"]',
	},
	{
		label: 'DopplerCorrectionAngle (00186034)',
		code: 'DICOMObject["00186034"]',
	},
	{
		label: 'SteeringAngle (00186036)',
		code: 'DICOMObject["00186036"]',
	},
	{
		label: 'DopplerSampleVolumeXPosition (00186039)',
		code: 'DICOMObject["00186039"]',
	},
	{
		label: 'DopplerSampleVolumeYPosition (0018603B)',
		code: 'DICOMObject["0018603B"]',
	},
	{
		label: 'TMLinePositionX0 (0018603D)',
		code: 'DICOMObject["0018603D"]',
	},
	{
		label: 'TMLinePositionY0 (0018603F)',
		code: 'DICOMObject["0018603F"]',
	},
	{
		label: 'TMLinePositionX1 (00186041)',
		code: 'DICOMObject["00186041"]',
	},
	{
		label: 'TMLinePositionY1 (00186043)',
		code: 'DICOMObject["00186043"]',
	},
	{
		label: 'PixelComponentOrganization (00186044)',
		code: 'DICOMObject["00186044"]',
	},
	{
		label: 'PixelComponentMask (00186046)',
		code: 'DICOMObject["00186046"]',
	},
	{
		label: 'PixelComponentRangeStart (00186048)',
		code: 'DICOMObject["00186048"]',
	},
	{
		label: 'PixelComponentRangeStop (0018604A)',
		code: 'DICOMObject["0018604A"]',
	},
	{
		label: 'PixelComponentPhysicalUnits (0018604C)',
		code: 'DICOMObject["0018604C"]',
	},
	{
		label: 'PixelComponentDataType (0018604E)',
		code: 'DICOMObject["0018604E"]',
	},
	{
		label: 'NumberOfTableBreakPoints (00186050)',
		code: 'DICOMObject["00186050"]',
	},
	{
		label: 'TableOfXBreakPoints (00186052)',
		code: 'DICOMObject["00186052"]',
	},
	{
		label: 'TableOfYBreakPoints (00186054)',
		code: 'DICOMObject["00186054"]',
	},
	{
		label: 'NumberOfTableEntries (00186056)',
		code: 'DICOMObject["00186056"]',
	},
	{
		label: 'TableOfPixelValues (00186058)',
		code: 'DICOMObject["00186058"]',
	},
	{
		label: 'TableOfParameterValues (0018605A)',
		code: 'DICOMObject["0018605A"]',
	},
	{
		label: 'RWaveTimeVector (00186060)',
		code: 'DICOMObject["00186060"]',
	},
	{
		label: 'DetectorConditionsNominalFlag (00187000)',
		code: 'DICOMObject["00187000"]',
	},
	{
		label: 'DetectorTemperature (00187001)',
		code: 'DICOMObject["00187001"]',
	},
	{
		label: 'DetectorType (00187004)',
		code: 'DICOMObject["00187004"]',
	},
	{
		label: 'DetectorConfiguration (00187005)',
		code: 'DICOMObject["00187005"]',
	},
	{
		label: 'DetectorDescription (00187006)',
		code: 'DICOMObject["00187006"]',
	},
	{
		label: 'DetectorMode (00187008)',
		code: 'DICOMObject["00187008"]',
	},
	{
		label: 'DetectorID (0018700A)',
		code: 'DICOMObject["0018700A"]',
	},
	{
		label: 'DateOfLastDetectorCalibration (0018700C)',
		code: 'DICOMObject["0018700C"]',
	},
	{
		label: 'TimeOfLastDetectorCalibration (0018700E)',
		code: 'DICOMObject["0018700E"]',
	},
	{
		label: 'ExposuresOnDetectorSinceLastCalibration (00187010)',
		code: 'DICOMObject["00187010"]',
	},
	{
		label: 'ExposuresOnDetectorSinceManufactured (00187011)',
		code: 'DICOMObject["00187011"]',
	},
	{
		label: 'DetectorTimeSinceLastExposure (00187012)',
		code: 'DICOMObject["00187012"]',
	},
	{
		label: 'DetectorActiveTime (00187014)',
		code: 'DICOMObject["00187014"]',
	},
	{
		label: 'DetectorActivationOffsetFromExposure (00187016)',
		code: 'DICOMObject["00187016"]',
	},
	{
		label: 'DetectorBinning (0018701A)',
		code: 'DICOMObject["0018701A"]',
	},
	{
		label: 'DetectorElementPhysicalSize (00187020)',
		code: 'DICOMObject["00187020"]',
	},
	{
		label: 'DetectorElementSpacing (00187022)',
		code: 'DICOMObject["00187022"]',
	},
	{
		label: 'DetectorActiveShape (00187024)',
		code: 'DICOMObject["00187024"]',
	},
	{
		label: 'DetectorActiveDimensions (00187026)',
		code: 'DICOMObject["00187026"]',
	},
	{
		label: 'DetectorActiveOrigin (00187028)',
		code: 'DICOMObject["00187028"]',
	},
	{
		label: 'DetectorManufacturerName (0018702A)',
		code: 'DICOMObject["0018702A"]',
	},
	{
		label: 'DetectorManufacturerModelName (0018702B)',
		code: 'DICOMObject["0018702B"]',
	},
	{
		label: 'FieldOfViewOrigin (00187030)',
		code: 'DICOMObject["00187030"]',
	},
	{
		label: 'FieldOfViewRotation (00187032)',
		code: 'DICOMObject["00187032"]',
	},
	{
		label: 'FieldOfViewHorizontalFlip (00187034)',
		code: 'DICOMObject["00187034"]',
	},
	{
		label: 'PixelDataAreaOriginRelativeToFOV (00187036)',
		code: 'DICOMObject["00187036"]',
	},
	{
		label: 'PixelDataAreaRotationAngleRelativeToFOV (00187038)',
		code: 'DICOMObject["00187038"]',
	},
	{
		label: 'GridAbsorbingMaterial (00187040)',
		code: 'DICOMObject["00187040"]',
	},
	{
		label: 'GridSpacingMaterial (00187041)',
		code: 'DICOMObject["00187041"]',
	},
	{
		label: 'GridThickness (00187042)',
		code: 'DICOMObject["00187042"]',
	},
	{
		label: 'GridPitch (00187044)',
		code: 'DICOMObject["00187044"]',
	},
	{
		label: 'GridAspectRatio (00187046)',
		code: 'DICOMObject["00187046"]',
	},
	{
		label: 'GridPeriod (00187048)',
		code: 'DICOMObject["00187048"]',
	},
	{
		label: 'GridFocalDistance (0018704C)',
		code: 'DICOMObject["0018704C"]',
	},
	{
		label: 'FilterMaterial (00187050)',
		code: 'DICOMObject["00187050"]',
	},
	{
		label: 'FilterThicknessMinimum (00187052)',
		code: 'DICOMObject["00187052"]',
	},
	{
		label: 'FilterThicknessMaximum (00187054)',
		code: 'DICOMObject["00187054"]',
	},
	{
		label: 'FilterBeamPathLengthMinimum (00187056)',
		code: 'DICOMObject["00187056"]',
	},
	{
		label: 'FilterBeamPathLengthMaximum (00187058)',
		code: 'DICOMObject["00187058"]',
	},
	{
		label: 'ExposureControlMode (00187060)',
		code: 'DICOMObject["00187060"]',
	},
	{
		label: 'ExposureControlModeDescription (00187062)',
		code: 'DICOMObject["00187062"]',
	},
	{
		label: 'ExposureStatus (00187064)',
		code: 'DICOMObject["00187064"]',
	},
	{
		label: 'PhototimerSetting (00187065)',
		code: 'DICOMObject["00187065"]',
	},
	{
		label: 'ExposureTimeInuS (00188150)',
		code: 'DICOMObject["00188150"]',
	},
	{
		label: 'XRayTubeCurrentInuA (00188151)',
		code: 'DICOMObject["00188151"]',
	},
	{
		label: 'ContentQualification (00189004)',
		code: 'DICOMObject["00189004"]',
	},
	{
		label: 'PulseSequenceName (00189005)',
		code: 'DICOMObject["00189005"]',
	},
	{
		label: 'MRImagingModifierSequence (00189006)',
		code: 'DICOMObject["00189006"]',
	},
	{
		label: 'EchoPulseSequence (00189008)',
		code: 'DICOMObject["00189008"]',
	},
	{
		label: 'InversionRecovery (00189009)',
		code: 'DICOMObject["00189009"]',
	},
	{
		label: 'FlowCompensation (00189010)',
		code: 'DICOMObject["00189010"]',
	},
	{
		label: 'MultipleSpinEcho (00189011)',
		code: 'DICOMObject["00189011"]',
	},
	{
		label: 'MultiPlanarExcitation (00189012)',
		code: 'DICOMObject["00189012"]',
	},
	{
		label: 'PhaseContrast (00189014)',
		code: 'DICOMObject["00189014"]',
	},
	{
		label: 'TimeOfFlightContrast (00189015)',
		code: 'DICOMObject["00189015"]',
	},
	{
		label: 'Spoiling (00189016)',
		code: 'DICOMObject["00189016"]',
	},
	{
		label: 'SteadyStatePulseSequence (00189017)',
		code: 'DICOMObject["00189017"]',
	},
	{
		label: 'EchoPlanarPulseSequence (00189018)',
		code: 'DICOMObject["00189018"]',
	},
	{
		label: 'TagAngleFirstAxis (00189019)',
		code: 'DICOMObject["00189019"]',
	},
	{
		label: 'MagnetizationTransfer (00189020)',
		code: 'DICOMObject["00189020"]',
	},
	{
		label: 'T2Preparation (00189021)',
		code: 'DICOMObject["00189021"]',
	},
	{
		label: 'BloodSignalNulling (00189022)',
		code: 'DICOMObject["00189022"]',
	},
	{
		label: 'SaturationRecovery (00189024)',
		code: 'DICOMObject["00189024"]',
	},
	{
		label: 'SpectrallySelectedSuppression (00189025)',
		code: 'DICOMObject["00189025"]',
	},
	{
		label: 'SpectrallySelectedExcitation (00189026)',
		code: 'DICOMObject["00189026"]',
	},
	{
		label: 'SpatialPresaturation (00189027)',
		code: 'DICOMObject["00189027"]',
	},
	{
		label: 'Tagging (00189028)',
		code: 'DICOMObject["00189028"]',
	},
	{
		label: 'OversamplingPhase (00189029)',
		code: 'DICOMObject["00189029"]',
	},
	{
		label: 'TagSpacingFirstDimension (00189030)',
		code: 'DICOMObject["00189030"]',
	},
	{
		label: 'GeometryOfKSpaceTraversal (00189032)',
		code: 'DICOMObject["00189032"]',
	},
	{
		label: 'SegmentedKSpaceTraversal (00189033)',
		code: 'DICOMObject["00189033"]',
	},
	{
		label: 'RectilinearPhaseEncodeReordering (00189034)',
		code: 'DICOMObject["00189034"]',
	},
	{
		label: 'TagThickness (00189035)',
		code: 'DICOMObject["00189035"]',
	},
	{
		label: 'PartialFourierDirection (00189036)',
		code: 'DICOMObject["00189036"]',
	},
	{
		label: 'CardiacSynchronizationTechnique (00189037)',
		code: 'DICOMObject["00189037"]',
	},
	{
		label: 'ReceiveCoilManufacturerName (00189041)',
		code: 'DICOMObject["00189041"]',
	},
	{
		label: 'MRReceiveCoilSequence (00189042)',
		code: 'DICOMObject["00189042"]',
	},
	{
		label: 'ReceiveCoilType (00189043)',
		code: 'DICOMObject["00189043"]',
	},
	{
		label: 'QuadratureReceiveCoil (00189044)',
		code: 'DICOMObject["00189044"]',
	},
	{
		label: 'MultiCoilDefinitionSequence (00189045)',
		code: 'DICOMObject["00189045"]',
	},
	{
		label: 'MultiCoilConfiguration (00189046)',
		code: 'DICOMObject["00189046"]',
	},
	{
		label: 'MultiCoilElementName (00189047)',
		code: 'DICOMObject["00189047"]',
	},
	{
		label: 'MultiCoilElementUsed (00189048)',
		code: 'DICOMObject["00189048"]',
	},
	{
		label: 'MRTransmitCoilSequence (00189049)',
		code: 'DICOMObject["00189049"]',
	},
	{
		label: 'TransmitCoilManufacturerName (00189050)',
		code: 'DICOMObject["00189050"]',
	},
	{
		label: 'TransmitCoilType (00189051)',
		code: 'DICOMObject["00189051"]',
	},
	{
		label: 'SpectralWidth (00189052)',
		code: 'DICOMObject["00189052"]',
	},
	{
		label: 'ChemicalShiftReference (00189053)',
		code: 'DICOMObject["00189053"]',
	},
	{
		label: 'VolumeLocalizationTechnique (00189054)',
		code: 'DICOMObject["00189054"]',
	},
	{
		label: 'MRAcquisitionFrequencyEncodingSteps (00189058)',
		code: 'DICOMObject["00189058"]',
	},
	{
		label: 'Decoupling (00189059)',
		code: 'DICOMObject["00189059"]',
	},
	{
		label: 'DecoupledNucleus (00189060)',
		code: 'DICOMObject["00189060"]',
	},
	{
		label: 'DecouplingFrequency (00189061)',
		code: 'DICOMObject["00189061"]',
	},
	{
		label: 'DecouplingMethod (00189062)',
		code: 'DICOMObject["00189062"]',
	},
	{
		label: 'DecouplingChemicalShiftReference (00189063)',
		code: 'DICOMObject["00189063"]',
	},
	{
		label: 'KSpaceFiltering (00189064)',
		code: 'DICOMObject["00189064"]',
	},
	{
		label: 'TimeDomainFiltering (00189065)',
		code: 'DICOMObject["00189065"]',
	},
	{
		label: 'NumberOfZeroFills (00189066)',
		code: 'DICOMObject["00189066"]',
	},
	{
		label: 'BaselineCorrection (00189067)',
		code: 'DICOMObject["00189067"]',
	},
	{
		label: 'ParallelReductionFactorInPlane (00189069)',
		code: 'DICOMObject["00189069"]',
	},
	{
		label: 'CardiacRRIntervalSpecified (00189070)',
		code: 'DICOMObject["00189070"]',
	},
	{
		label: 'AcquisitionDuration (00189073)',
		code: 'DICOMObject["00189073"]',
	},
	{
		label: 'FrameAcquisitionDateTime (00189074)',
		code: 'DICOMObject["00189074"]',
	},
	{
		label: 'DiffusionDirectionality (00189075)',
		code: 'DICOMObject["00189075"]',
	},
	{
		label: 'DiffusionGradientDirectionSequence (00189076)',
		code: 'DICOMObject["00189076"]',
	},
	{
		label: 'ParallelAcquisition (00189077)',
		code: 'DICOMObject["00189077"]',
	},
	{
		label: 'ParallelAcquisitionTechnique (00189078)',
		code: 'DICOMObject["00189078"]',
	},
	{
		label: 'InversionTimes (00189079)',
		code: 'DICOMObject["00189079"]',
	},
	{
		label: 'MetaboliteMapDescription (00189080)',
		code: 'DICOMObject["00189080"]',
	},
	{
		label: 'PartialFourier (00189081)',
		code: 'DICOMObject["00189081"]',
	},
	{
		label: 'EffectiveEchoTime (00189082)',
		code: 'DICOMObject["00189082"]',
	},
	{
		label: 'MetaboliteMapCodeSequence (00189083)',
		code: 'DICOMObject["00189083"]',
	},
	{
		label: 'ChemicalShiftSequence (00189084)',
		code: 'DICOMObject["00189084"]',
	},
	{
		label: 'CardiacSignalSource (00189085)',
		code: 'DICOMObject["00189085"]',
	},
	{
		label: 'DiffusionBValue (00189087)',
		code: 'DICOMObject["00189087"]',
	},
	{
		label: 'DiffusionGradientOrientation (00189089)',
		code: 'DICOMObject["00189089"]',
	},
	{
		label: 'VelocityEncodingDirection (00189090)',
		code: 'DICOMObject["00189090"]',
	},
	{
		label: 'VelocityEncodingMinimumValue (00189091)',
		code: 'DICOMObject["00189091"]',
	},
	{
		label: 'VelocityEncodingAcquisitionSequence (00189092)',
		code: 'DICOMObject["00189092"]',
	},
	{
		label: 'NumberOfKSpaceTrajectories (00189093)',
		code: 'DICOMObject["00189093"]',
	},
	{
		label: 'CoverageOfKSpace (00189094)',
		code: 'DICOMObject["00189094"]',
	},
	{
		label: 'SpectroscopyAcquisitionPhaseRows (00189095)',
		code: 'DICOMObject["00189095"]',
	},
	{
		label: 'TransmitterFrequency (00189098)',
		code: 'DICOMObject["00189098"]',
	},
	{
		label: 'ResonantNucleus (00189100)',
		code: 'DICOMObject["00189100"]',
	},
	{
		label: 'FrequencyCorrection (00189101)',
		code: 'DICOMObject["00189101"]',
	},
	{
		label: 'MRSpectroscopyFOVGeometrySequence (00189103)',
		code: 'DICOMObject["00189103"]',
	},
	{
		label: 'SlabThickness (00189104)',
		code: 'DICOMObject["00189104"]',
	},
	{
		label: 'SlabOrientation (00189105)',
		code: 'DICOMObject["00189105"]',
	},
	{
		label: 'MidSlabPosition (00189106)',
		code: 'DICOMObject["00189106"]',
	},
	{
		label: 'MRSpatialSaturationSequence (00189107)',
		code: 'DICOMObject["00189107"]',
	},
	{
		label: 'MRTimingAndRelatedParametersSequence (00189112)',
		code: 'DICOMObject["00189112"]',
	},
	{
		label: 'MREchoSequence (00189114)',
		code: 'DICOMObject["00189114"]',
	},
	{
		label: 'MRModifierSequence (00189115)',
		code: 'DICOMObject["00189115"]',
	},
	{
		label: 'MRDiffusionSequence (00189117)',
		code: 'DICOMObject["00189117"]',
	},
	{
		label: 'CardiacSynchronizationSequence (00189118)',
		code: 'DICOMObject["00189118"]',
	},
	{
		label: 'MRAveragesSequence (00189119)',
		code: 'DICOMObject["00189119"]',
	},
	{
		label: 'MRFOVGeometrySequence (00189125)',
		code: 'DICOMObject["00189125"]',
	},
	{
		label: 'VolumeLocalizationSequence (00189126)',
		code: 'DICOMObject["00189126"]',
	},
	{
		label: 'SpectroscopyAcquisitionDataColumns (00189127)',
		code: 'DICOMObject["00189127"]',
	},
	{
		label: 'DiffusionAnisotropyType (00189147)',
		code: 'DICOMObject["00189147"]',
	},
	{
		label: 'FrameReferenceDateTime (00189151)',
		code: 'DICOMObject["00189151"]',
	},
	{
		label: 'MRMetaboliteMapSequence (00189152)',
		code: 'DICOMObject["00189152"]',
	},
	{
		label: 'ParallelReductionFactorOutOfPlane (00189155)',
		code: 'DICOMObject["00189155"]',
	},
	{
		label: 'SpectroscopyAcquisitionOutOfPlanePhaseSteps (00189159)',
		code: 'DICOMObject["00189159"]',
	},
	{
		label: 'ParallelReductionFactorSecondInPlane (00189168)',
		code: 'DICOMObject["00189168"]',
	},
	{
		label: 'CardiacBeatRejectionTechnique (00189169)',
		code: 'DICOMObject["00189169"]',
	},
	{
		label: 'RespiratoryMotionCompensationTechnique (00189170)',
		code: 'DICOMObject["00189170"]',
	},
	{
		label: 'RespiratorySignalSource (00189171)',
		code: 'DICOMObject["00189171"]',
	},
	{
		label: 'BulkMotionCompensationTechnique (00189172)',
		code: 'DICOMObject["00189172"]',
	},
	{
		label: 'BulkMotionSignalSource (00189173)',
		code: 'DICOMObject["00189173"]',
	},
	{
		label: 'ApplicableSafetyStandardAgency (00189174)',
		code: 'DICOMObject["00189174"]',
	},
	{
		label: 'ApplicableSafetyStandardDescription (00189175)',
		code: 'DICOMObject["00189175"]',
	},
	{
		label: 'OperatingModeSequence (00189176)',
		code: 'DICOMObject["00189176"]',
	},
	{
		label: 'OperatingModeType (00189177)',
		code: 'DICOMObject["00189177"]',
	},
	{
		label: 'OperatingMode (00189178)',
		code: 'DICOMObject["00189178"]',
	},
	{
		label: 'SpecificAbsorptionRateDefinition (00189179)',
		code: 'DICOMObject["00189179"]',
	},
	{
		label: 'GradientOutputType (00189180)',
		code: 'DICOMObject["00189180"]',
	},
	{
		label: 'SpecificAbsorptionRateValue (00189181)',
		code: 'DICOMObject["00189181"]',
	},
	{
		label: 'GradientOutput (00189182)',
		code: 'DICOMObject["00189182"]',
	},
	{
		label: 'FlowCompensationDirection (00189183)',
		code: 'DICOMObject["00189183"]',
	},
	{
		label: 'TaggingDelay (00189184)',
		code: 'DICOMObject["00189184"]',
	},
	{
		label: 'RespiratoryMotionCompensationTechniqueDescription (00189185)',
		code: 'DICOMObject["00189185"]',
	},
	{
		label: 'RespiratorySignalSourceID (00189186)',
		code: 'DICOMObject["00189186"]',
	},
	{
		label: 'MRVelocityEncodingSequence (00189197)',
		code: 'DICOMObject["00189197"]',
	},
	{
		label: 'FirstOrderPhaseCorrection (00189198)',
		code: 'DICOMObject["00189198"]',
	},
	{
		label: 'WaterReferencedPhaseCorrection (00189199)',
		code: 'DICOMObject["00189199"]',
	},
	{
		label: 'MRSpectroscopyAcquisitionType (00189200)',
		code: 'DICOMObject["00189200"]',
	},
	{
		label: 'RespiratoryCyclePosition (00189214)',
		code: 'DICOMObject["00189214"]',
	},
	{
		label: 'VelocityEncodingMaximumValue (00189217)',
		code: 'DICOMObject["00189217"]',
	},
	{
		label: 'TagSpacingSecondDimension (00189218)',
		code: 'DICOMObject["00189218"]',
	},
	{
		label: 'TagAngleSecondAxis (00189219)',
		code: 'DICOMObject["00189219"]',
	},
	{
		label: 'FrameAcquisitionDuration (00189220)',
		code: 'DICOMObject["00189220"]',
	},
	{
		label: 'MRImageFrameTypeSequence (00189226)',
		code: 'DICOMObject["00189226"]',
	},
	{
		label: 'MRSpectroscopyFrameTypeSequence (00189227)',
		code: 'DICOMObject["00189227"]',
	},
	{
		label: 'MRAcquisitionPhaseEncodingStepsInPlane (00189231)',
		code: 'DICOMObject["00189231"]',
	},
	{
		label: 'MRAcquisitionPhaseEncodingStepsOutOfPlane (00189232)',
		code: 'DICOMObject["00189232"]',
	},
	{
		label: 'SpectroscopyAcquisitionPhaseColumns (00189234)',
		code: 'DICOMObject["00189234"]',
	},
	{
		label: 'CardiacCyclePosition (00189236)',
		code: 'DICOMObject["00189236"]',
	},
	{
		label: 'SpecificAbsorptionRateSequence (00189239)',
		code: 'DICOMObject["00189239"]',
	},
	{
		label: 'RFEchoTrainLength (00189240)',
		code: 'DICOMObject["00189240"]',
	},
	{
		label: 'GradientEchoTrainLength (00189241)',
		code: 'DICOMObject["00189241"]',
	},
	{
		label: 'ArterialSpinLabelingContrast (00189250)',
		code: 'DICOMObject["00189250"]',
	},
	{
		label: 'MRArterialSpinLabelingSequence (00189251)',
		code: 'DICOMObject["00189251"]',
	},
	{
		label: 'ASLTechniqueDescription (00189252)',
		code: 'DICOMObject["00189252"]',
	},
	{
		label: 'ASLSlabNumber (00189253)',
		code: 'DICOMObject["00189253"]',
	},
	{
		label: 'ASLSlabThickness (00189254)',
		code: 'DICOMObject["00189254"]',
	},
	{
		label: 'ASLSlabOrientation (00189255)',
		code: 'DICOMObject["00189255"]',
	},
	{
		label: 'ASLMidSlabPosition (00189256)',
		code: 'DICOMObject["00189256"]',
	},
	{
		label: 'ASLContext (00189257)',
		code: 'DICOMObject["00189257"]',
	},
	{
		label: 'ASLPulseTrainDuration (00189258)',
		code: 'DICOMObject["00189258"]',
	},
	{
		label: 'ASLCrusherFlag (00189259)',
		code: 'DICOMObject["00189259"]',
	},
	{
		label: 'ASLCrusherFlowLimit (0018925A)',
		code: 'DICOMObject["0018925A"]',
	},
	{
		label: 'ASLCrusherDescription (0018925B)',
		code: 'DICOMObject["0018925B"]',
	},
	{
		label: 'ASLBolusCutoffFlag (0018925C)',
		code: 'DICOMObject["0018925C"]',
	},
	{
		label: 'ASLBolusCutoffTimingSequence (0018925D)',
		code: 'DICOMObject["0018925D"]',
	},
	{
		label: 'ASLBolusCutoffTechnique (0018925E)',
		code: 'DICOMObject["0018925E"]',
	},
	{
		label: 'ASLBolusCutoffDelayTime (0018925F)',
		code: 'DICOMObject["0018925F"]',
	},
	{
		label: 'ASLSlabSequence (00189260)',
		code: 'DICOMObject["00189260"]',
	},
	{
		label: 'ChemicalShiftMinimumIntegrationLimitInppm (00189295)',
		code: 'DICOMObject["00189295"]',
	},
	{
		label: 'ChemicalShiftMaximumIntegrationLimitInppm (00189296)',
		code: 'DICOMObject["00189296"]',
	},
	{
		label: 'WaterReferenceAcquisition (00189297)',
		code: 'DICOMObject["00189297"]',
	},
	{
		label: 'EchoPeakPosition (00189298)',
		code: 'DICOMObject["00189298"]',
	},
	{
		label: 'CTAcquisitionTypeSequence (00189301)',
		code: 'DICOMObject["00189301"]',
	},
	{
		label: 'AcquisitionType (00189302)',
		code: 'DICOMObject["00189302"]',
	},
	{
		label: 'TubeAngle (00189303)',
		code: 'DICOMObject["00189303"]',
	},
	{
		label: 'CTAcquisitionDetailsSequence (00189304)',
		code: 'DICOMObject["00189304"]',
	},
	{
		label: 'RevolutionTime (00189305)',
		code: 'DICOMObject["00189305"]',
	},
	{
		label: 'SingleCollimationWidth (00189306)',
		code: 'DICOMObject["00189306"]',
	},
	{
		label: 'TotalCollimationWidth (00189307)',
		code: 'DICOMObject["00189307"]',
	},
	{
		label: 'CTTableDynamicsSequence (00189308)',
		code: 'DICOMObject["00189308"]',
	},
	{
		label: 'TableSpeed (00189309)',
		code: 'DICOMObject["00189309"]',
	},
	{
		label: 'TableFeedPerRotation (00189310)',
		code: 'DICOMObject["00189310"]',
	},
	{
		label: 'SpiralPitchFactor (00189311)',
		code: 'DICOMObject["00189311"]',
	},
	{
		label: 'CTGeometrySequence (00189312)',
		code: 'DICOMObject["00189312"]',
	},
	{
		label: 'DataCollectionCenterPatient (00189313)',
		code: 'DICOMObject["00189313"]',
	},
	{
		label: 'CTReconstructionSequence (00189314)',
		code: 'DICOMObject["00189314"]',
	},
	{
		label: 'ReconstructionAlgorithm (00189315)',
		code: 'DICOMObject["00189315"]',
	},
	{
		label: 'ConvolutionKernelGroup (00189316)',
		code: 'DICOMObject["00189316"]',
	},
	{
		label: 'ReconstructionFieldOfView (00189317)',
		code: 'DICOMObject["00189317"]',
	},
	{
		label: 'ReconstructionTargetCenterPatient (00189318)',
		code: 'DICOMObject["00189318"]',
	},
	{
		label: 'ReconstructionAngle (00189319)',
		code: 'DICOMObject["00189319"]',
	},
	{
		label: 'ImageFilter (00189320)',
		code: 'DICOMObject["00189320"]',
	},
	{
		label: 'CTExposureSequence (00189321)',
		code: 'DICOMObject["00189321"]',
	},
	{
		label: 'ReconstructionPixelSpacing (00189322)',
		code: 'DICOMObject["00189322"]',
	},
	{
		label: 'ExposureModulationType (00189323)',
		code: 'DICOMObject["00189323"]',
	},
	{
		label: 'EstimatedDoseSaving (00189324)',
		code: 'DICOMObject["00189324"]',
	},
	{
		label: 'CTXRayDetailsSequence (00189325)',
		code: 'DICOMObject["00189325"]',
	},
	{
		label: 'CTPositionSequence (00189326)',
		code: 'DICOMObject["00189326"]',
	},
	{
		label: 'TablePosition (00189327)',
		code: 'DICOMObject["00189327"]',
	},
	{
		label: 'ExposureTimeInms (00189328)',
		code: 'DICOMObject["00189328"]',
	},
	{
		label: 'CTImageFrameTypeSequence (00189329)',
		code: 'DICOMObject["00189329"]',
	},
	{
		label: 'XRayTubeCurrentInmA (00189330)',
		code: 'DICOMObject["00189330"]',
	},
	{
		label: 'ExposureInmAs (00189332)',
		code: 'DICOMObject["00189332"]',
	},
	{
		label: 'ConstantVolumeFlag (00189333)',
		code: 'DICOMObject["00189333"]',
	},
	{
		label: 'FluoroscopyFlag (00189334)',
		code: 'DICOMObject["00189334"]',
	},
	{
		label: 'DistanceSourceToDataCollectionCenter (00189335)',
		code: 'DICOMObject["00189335"]',
	},
	{
		label: 'ContrastBolusAgentNumber (00189337)',
		code: 'DICOMObject["00189337"]',
	},
	{
		label: 'ContrastBolusIngredientCodeSequence (00189338)',
		code: 'DICOMObject["00189338"]',
	},
	{
		label: 'ContrastAdministrationProfileSequence (00189340)',
		code: 'DICOMObject["00189340"]',
	},
	{
		label: 'ContrastBolusUsageSequence (00189341)',
		code: 'DICOMObject["00189341"]',
	},
	{
		label: 'ContrastBolusAgentAdministered (00189342)',
		code: 'DICOMObject["00189342"]',
	},
	{
		label: 'ContrastBolusAgentDetected (00189343)',
		code: 'DICOMObject["00189343"]',
	},
	{
		label: 'ContrastBolusAgentPhase (00189344)',
		code: 'DICOMObject["00189344"]',
	},
	{
		label: 'CTDIvol (00189345)',
		code: 'DICOMObject["00189345"]',
	},
	{
		label: 'CTDIPhantomTypeCodeSequence (00189346)',
		code: 'DICOMObject["00189346"]',
	},
	{
		label: 'CalciumScoringMassFactorPatient (00189351)',
		code: 'DICOMObject["00189351"]',
	},
	{
		label: 'CalciumScoringMassFactorDevice (00189352)',
		code: 'DICOMObject["00189352"]',
	},
	{
		label: 'EnergyWeightingFactor (00189353)',
		code: 'DICOMObject["00189353"]',
	},
	{
		label: 'CTAdditionalXRaySourceSequence (00189360)',
		code: 'DICOMObject["00189360"]',
	},
	{
		label: 'ProjectionPixelCalibrationSequence (00189401)',
		code: 'DICOMObject["00189401"]',
	},
	{
		label: 'DistanceSourceToIsocenter (00189402)',
		code: 'DICOMObject["00189402"]',
	},
	{
		label: 'DistanceObjectToTableTop (00189403)',
		code: 'DICOMObject["00189403"]',
	},
	{
		label: 'ObjectPixelSpacingInCenterOfBeam (00189404)',
		code: 'DICOMObject["00189404"]',
	},
	{
		label: 'PositionerPositionSequence (00189405)',
		code: 'DICOMObject["00189405"]',
	},
	{
		label: 'TablePositionSequence (00189406)',
		code: 'DICOMObject["00189406"]',
	},
	{
		label: 'CollimatorShapeSequence (00189407)',
		code: 'DICOMObject["00189407"]',
	},
	{
		label: 'PlanesInAcquisition (00189410)',
		code: 'DICOMObject["00189410"]',
	},
	{
		label: 'XAXRFFrameCharacteristicsSequence (00189412)',
		code: 'DICOMObject["00189412"]',
	},
	{
		label: 'FrameAcquisitionSequence (00189417)',
		code: 'DICOMObject["00189417"]',
	},
	{
		label: 'XRayReceptorType (00189420)',
		code: 'DICOMObject["00189420"]',
	},
	{
		label: 'AcquisitionProtocolName (00189423)',
		code: 'DICOMObject["00189423"]',
	},
	{
		label: 'AcquisitionProtocolDescription (00189424)',
		code: 'DICOMObject["00189424"]',
	},
	{
		label: 'ContrastBolusIngredientOpaque (00189425)',
		code: 'DICOMObject["00189425"]',
	},
	{
		label: 'DistanceReceptorPlaneToDetectorHousing (00189426)',
		code: 'DICOMObject["00189426"]',
	},
	{
		label: 'IntensifierActiveShape (00189427)',
		code: 'DICOMObject["00189427"]',
	},
	{
		label: 'IntensifierActiveDimensions (00189428)',
		code: 'DICOMObject["00189428"]',
	},
	{
		label: 'PhysicalDetectorSize (00189429)',
		code: 'DICOMObject["00189429"]',
	},
	{
		label: 'PositionOfIsocenterProjection (00189430)',
		code: 'DICOMObject["00189430"]',
	},
	{
		label: 'FieldOfViewSequence (00189432)',
		code: 'DICOMObject["00189432"]',
	},
	{
		label: 'FieldOfViewDescription (00189433)',
		code: 'DICOMObject["00189433"]',
	},
	{
		label: 'ExposureControlSensingRegionsSequence (00189434)',
		code: 'DICOMObject["00189434"]',
	},
	{
		label: 'ExposureControlSensingRegionShape (00189435)',
		code: 'DICOMObject["00189435"]',
	},
	{
		label: 'ExposureControlSensingRegionLeftVerticalEdge (00189436)',
		code: 'DICOMObject["00189436"]',
	},
	{
		label: 'ExposureControlSensingRegionRightVerticalEdge (00189437)',
		code: 'DICOMObject["00189437"]',
	},
	{
		label: 'ExposureControlSensingRegionUpperHorizontalEdge (00189438)',
		code: 'DICOMObject["00189438"]',
	},
	{
		label: 'ExposureControlSensingRegionLowerHorizontalEdge (00189439)',
		code: 'DICOMObject["00189439"]',
	},
	{
		label: 'CenterOfCircularExposureControlSensingRegion (00189440)',
		code: 'DICOMObject["00189440"]',
	},
	{
		label: 'RadiusOfCircularExposureControlSensingRegion (00189441)',
		code: 'DICOMObject["00189441"]',
	},
	{
		label: 'VerticesOfThePolygonalExposureControlSensingRegion (00189442)',
		code: 'DICOMObject["00189442"]',
	},
	{
		label: 'ColumnAngulationPatient (00189447)',
		code: 'DICOMObject["00189447"]',
	},
	{
		label: 'BeamAngle (00189449)',
		code: 'DICOMObject["00189449"]',
	},
	{
		label: 'FrameDetectorParametersSequence (00189451)',
		code: 'DICOMObject["00189451"]',
	},
	{
		label: 'CalculatedAnatomyThickness (00189452)',
		code: 'DICOMObject["00189452"]',
	},
	{
		label: 'CalibrationSequence (00189455)',
		code: 'DICOMObject["00189455"]',
	},
	{
		label: 'ObjectThicknessSequence (00189456)',
		code: 'DICOMObject["00189456"]',
	},
	{
		label: 'PlaneIdentification (00189457)',
		code: 'DICOMObject["00189457"]',
	},
	{
		label: 'FieldOfViewDimensionsInFloat (00189461)',
		code: 'DICOMObject["00189461"]',
	},
	{
		label: 'IsocenterReferenceSystemSequence (00189462)',
		code: 'DICOMObject["00189462"]',
	},
	{
		label: 'PositionerIsocenterPrimaryAngle (00189463)',
		code: 'DICOMObject["00189463"]',
	},
	{
		label: 'PositionerIsocenterSecondaryAngle (00189464)',
		code: 'DICOMObject["00189464"]',
	},
	{
		label: 'PositionerIsocenterDetectorRotationAngle (00189465)',
		code: 'DICOMObject["00189465"]',
	},
	{
		label: 'TableXPositionToIsocenter (00189466)',
		code: 'DICOMObject["00189466"]',
	},
	{
		label: 'TableYPositionToIsocenter (00189467)',
		code: 'DICOMObject["00189467"]',
	},
	{
		label: 'TableZPositionToIsocenter (00189468)',
		code: 'DICOMObject["00189468"]',
	},
	{
		label: 'TableHorizontalRotationAngle (00189469)',
		code: 'DICOMObject["00189469"]',
	},
	{
		label: 'TableHeadTiltAngle (00189470)',
		code: 'DICOMObject["00189470"]',
	},
	{
		label: 'TableCradleTiltAngle (00189471)',
		code: 'DICOMObject["00189471"]',
	},
	{
		label: 'FrameDisplayShutterSequence (00189472)',
		code: 'DICOMObject["00189472"]',
	},
	{
		label: 'AcquiredImageAreaDoseProduct (00189473)',
		code: 'DICOMObject["00189473"]',
	},
	{
		label: 'CArmPositionerTabletopRelationship (00189474)',
		code: 'DICOMObject["00189474"]',
	},
	{
		label: 'XRayGeometrySequence (00189476)',
		code: 'DICOMObject["00189476"]',
	},
	{
		label: 'IrradiationEventIdentificationSequence (00189477)',
		code: 'DICOMObject["00189477"]',
	},
	{
		label: 'XRay3DFrameTypeSequence (00189504)',
		code: 'DICOMObject["00189504"]',
	},
	{
		label: 'ContributingSourcesSequence (00189506)',
		code: 'DICOMObject["00189506"]',
	},
	{
		label: 'XRay3DAcquisitionSequence (00189507)',
		code: 'DICOMObject["00189507"]',
	},
	{
		label: 'PrimaryPositionerScanArc (00189508)',
		code: 'DICOMObject["00189508"]',
	},
	{
		label: 'SecondaryPositionerScanArc (00189509)',
		code: 'DICOMObject["00189509"]',
	},
	{
		label: 'PrimaryPositionerScanStartAngle (00189510)',
		code: 'DICOMObject["00189510"]',
	},
	{
		label: 'SecondaryPositionerScanStartAngle (00189511)',
		code: 'DICOMObject["00189511"]',
	},
	{
		label: 'PrimaryPositionerIncrement (00189514)',
		code: 'DICOMObject["00189514"]',
	},
	{
		label: 'SecondaryPositionerIncrement (00189515)',
		code: 'DICOMObject["00189515"]',
	},
	{
		label: 'StartAcquisitionDateTime (00189516)',
		code: 'DICOMObject["00189516"]',
	},
	{
		label: 'EndAcquisitionDateTime (00189517)',
		code: 'DICOMObject["00189517"]',
	},
	{
		label: 'PrimaryPositionerIncrementSign (00189518)',
		code: 'DICOMObject["00189518"]',
	},
	{
		label: 'SecondaryPositionerIncrementSign (00189519)',
		code: 'DICOMObject["00189519"]',
	},
	{
		label: 'ApplicationName (00189524)',
		code: 'DICOMObject["00189524"]',
	},
	{
		label: 'ApplicationVersion (00189525)',
		code: 'DICOMObject["00189525"]',
	},
	{
		label: 'ApplicationManufacturer (00189526)',
		code: 'DICOMObject["00189526"]',
	},
	{
		label: 'AlgorithmType (00189527)',
		code: 'DICOMObject["00189527"]',
	},
	{
		label: 'AlgorithmDescription (00189528)',
		code: 'DICOMObject["00189528"]',
	},
	{
		label: 'XRay3DReconstructionSequence (00189530)',
		code: 'DICOMObject["00189530"]',
	},
	{
		label: 'ReconstructionDescription (00189531)',
		code: 'DICOMObject["00189531"]',
	},
	{
		label: 'PerProjectionAcquisitionSequence (00189538)',
		code: 'DICOMObject["00189538"]',
	},
	{
		label: 'DetectorPositionSequence (00189541)',
		code: 'DICOMObject["00189541"]',
	},
	{
		label: 'XRayAcquisitionDoseSequence (00189542)',
		code: 'DICOMObject["00189542"]',
	},
	{
		label: 'XRaySourceIsocenterPrimaryAngle (00189543)',
		code: 'DICOMObject["00189543"]',
	},
	{
		label: 'XRaySourceIsocenterSecondaryAngle (00189544)',
		code: 'DICOMObject["00189544"]',
	},
	{
		label: 'BreastSupportIsocenterPrimaryAngle (00189545)',
		code: 'DICOMObject["00189545"]',
	},
	{
		label: 'BreastSupportIsocenterSecondaryAngle (00189546)',
		code: 'DICOMObject["00189546"]',
	},
	{
		label: 'BreastSupportXPositionToIsocenter (00189547)',
		code: 'DICOMObject["00189547"]',
	},
	{
		label: 'BreastSupportYPositionToIsocenter (00189548)',
		code: 'DICOMObject["00189548"]',
	},
	{
		label: 'BreastSupportZPositionToIsocenter (00189549)',
		code: 'DICOMObject["00189549"]',
	},
	{
		label: 'DetectorIsocenterPrimaryAngle (00189550)',
		code: 'DICOMObject["00189550"]',
	},
	{
		label: 'DetectorIsocenterSecondaryAngle (00189551)',
		code: 'DICOMObject["00189551"]',
	},
	{
		label: 'DetectorXPositionToIsocenter (00189552)',
		code: 'DICOMObject["00189552"]',
	},
	{
		label: 'DetectorYPositionToIsocenter (00189553)',
		code: 'DICOMObject["00189553"]',
	},
	{
		label: 'DetectorZPositionToIsocenter (00189554)',
		code: 'DICOMObject["00189554"]',
	},
	{
		label: 'XRayGridSequence (00189555)',
		code: 'DICOMObject["00189555"]',
	},
	{
		label: 'XRayFilterSequence (00189556)',
		code: 'DICOMObject["00189556"]',
	},
	{
		label: 'DetectorActiveAreaTLHCPosition (00189557)',
		code: 'DICOMObject["00189557"]',
	},
	{
		label: 'DetectorActiveAreaOrientation (00189558)',
		code: 'DICOMObject["00189558"]',
	},
	{
		label: 'PositionerPrimaryAngleDirection (00189559)',
		code: 'DICOMObject["00189559"]',
	},
	{
		label: 'DiffusionBMatrixSequence (00189601)',
		code: 'DICOMObject["00189601"]',
	},
	{
		label: 'DiffusionBValueXX (00189602)',
		code: 'DICOMObject["00189602"]',
	},
	{
		label: 'DiffusionBValueXY (00189603)',
		code: 'DICOMObject["00189603"]',
	},
	{
		label: 'DiffusionBValueXZ (00189604)',
		code: 'DICOMObject["00189604"]',
	},
	{
		label: 'DiffusionBValueYY (00189605)',
		code: 'DICOMObject["00189605"]',
	},
	{
		label: 'DiffusionBValueYZ (00189606)',
		code: 'DICOMObject["00189606"]',
	},
	{
		label: 'DiffusionBValueZZ (00189607)',
		code: 'DICOMObject["00189607"]',
	},
	{
		label: 'FunctionalMRSequence (00189621)',
		code: 'DICOMObject["00189621"]',
	},
	{
		label: 'FunctionalSettlingPhaseFramesPresent (00189622)',
		code: 'DICOMObject["00189622"]',
	},
	{
		label: 'FunctionalSyncPulse (00189623)',
		code: 'DICOMObject["00189623"]',
	},
	{
		label: 'SettlingPhaseFrame (00189624)',
		code: 'DICOMObject["00189624"]',
	},
	{
		label: 'DecayCorrectionDateTime (00189701)',
		code: 'DICOMObject["00189701"]',
	},
	{
		label: 'StartDensityThreshold (00189715)',
		code: 'DICOMObject["00189715"]',
	},
	{
		label: 'StartRelativeDensityDifferenceThreshold (00189716)',
		code: 'DICOMObject["00189716"]',
	},
	{
		label: 'StartCardiacTriggerCountThreshold (00189717)',
		code: 'DICOMObject["00189717"]',
	},
	{
		label: 'StartRespiratoryTriggerCountThreshold (00189718)',
		code: 'DICOMObject["00189718"]',
	},
	{
		label: 'TerminationCountsThreshold (00189719)',
		code: 'DICOMObject["00189719"]',
	},
	{
		label: 'TerminationDensityThreshold (00189720)',
		code: 'DICOMObject["00189720"]',
	},
	{
		label: 'TerminationRelativeDensityThreshold (00189721)',
		code: 'DICOMObject["00189721"]',
	},
	{
		label: 'TerminationTimeThreshold (00189722)',
		code: 'DICOMObject["00189722"]',
	},
	{
		label: 'TerminationCardiacTriggerCountThreshold (00189723)',
		code: 'DICOMObject["00189723"]',
	},
	{
		label: 'TerminationRespiratoryTriggerCountThreshold (00189724)',
		code: 'DICOMObject["00189724"]',
	},
	{
		label: 'DetectorGeometry (00189725)',
		code: 'DICOMObject["00189725"]',
	},
	{
		label: 'TransverseDetectorSeparation (00189726)',
		code: 'DICOMObject["00189726"]',
	},
	{
		label: 'AxialDetectorDimension (00189727)',
		code: 'DICOMObject["00189727"]',
	},
	{
		label: 'RadiopharmaceuticalAgentNumber (00189729)',
		code: 'DICOMObject["00189729"]',
	},
	{
		label: 'PETFrameAcquisitionSequence (00189732)',
		code: 'DICOMObject["00189732"]',
	},
	{
		label: 'PETDetectorMotionDetailsSequence (00189733)',
		code: 'DICOMObject["00189733"]',
	},
	{
		label: 'PETTableDynamicsSequence (00189734)',
		code: 'DICOMObject["00189734"]',
	},
	{
		label: 'PETPositionSequence (00189735)',
		code: 'DICOMObject["00189735"]',
	},
	{
		label: 'PETFrameCorrectionFactorsSequence (00189736)',
		code: 'DICOMObject["00189736"]',
	},
	{
		label: 'RadiopharmaceuticalUsageSequence (00189737)',
		code: 'DICOMObject["00189737"]',
	},
	{
		label: 'AttenuationCorrectionSource (00189738)',
		code: 'DICOMObject["00189738"]',
	},
	{
		label: 'NumberOfIterations (00189739)',
		code: 'DICOMObject["00189739"]',
	},
	{
		label: 'NumberOfSubsets (00189740)',
		code: 'DICOMObject["00189740"]',
	},
	{
		label: 'PETReconstructionSequence (00189749)',
		code: 'DICOMObject["00189749"]',
	},
	{
		label: 'PETFrameTypeSequence (00189751)',
		code: 'DICOMObject["00189751"]',
	},
	{
		label: 'TimeOfFlightInformationUsed (00189755)',
		code: 'DICOMObject["00189755"]',
	},
	{
		label: 'ReconstructionType (00189756)',
		code: 'DICOMObject["00189756"]',
	},
	{
		label: 'DecayCorrected (00189758)',
		code: 'DICOMObject["00189758"]',
	},
	{
		label: 'AttenuationCorrected (00189759)',
		code: 'DICOMObject["00189759"]',
	},
	{
		label: 'ScatterCorrected (00189760)',
		code: 'DICOMObject["00189760"]',
	},
	{
		label: 'DeadTimeCorrected (00189761)',
		code: 'DICOMObject["00189761"]',
	},
	{
		label: 'GantryMotionCorrected (00189762)',
		code: 'DICOMObject["00189762"]',
	},
	{
		label: 'PatientMotionCorrected (00189763)',
		code: 'DICOMObject["00189763"]',
	},
	{
		label: 'CountLossNormalizationCorrected (00189764)',
		code: 'DICOMObject["00189764"]',
	},
	{
		label: 'RandomsCorrected (00189765)',
		code: 'DICOMObject["00189765"]',
	},
	{
		label: 'NonUniformRadialSamplingCorrected (00189766)',
		code: 'DICOMObject["00189766"]',
	},
	{
		label: 'SensitivityCalibrated (00189767)',
		code: 'DICOMObject["00189767"]',
	},
	{
		label: 'DetectorNormalizationCorrection (00189768)',
		code: 'DICOMObject["00189768"]',
	},
	{
		label: 'IterativeReconstructionMethod (00189769)',
		code: 'DICOMObject["00189769"]',
	},
	{
		label: 'AttenuationCorrectionTemporalRelationship (00189770)',
		code: 'DICOMObject["00189770"]',
	},
	{
		label: 'PatientPhysiologicalStateSequence (00189771)',
		code: 'DICOMObject["00189771"]',
	},
	{
		label: 'PatientPhysiologicalStateCodeSequence (00189772)',
		code: 'DICOMObject["00189772"]',
	},
	{
		label: 'DepthsOfFocus (00189801)',
		code: 'DICOMObject["00189801"]',
	},
	{
		label: 'ExcludedIntervalsSequence (00189803)',
		code: 'DICOMObject["00189803"]',
	},
	{
		label: 'ExclusionStartDateTime (00189804)',
		code: 'DICOMObject["00189804"]',
	},
	{
		label: 'ExclusionDuration (00189805)',
		code: 'DICOMObject["00189805"]',
	},
	{
		label: 'USImageDescriptionSequence (00189806)',
		code: 'DICOMObject["00189806"]',
	},
	{
		label: 'ImageDataTypeSequence (00189807)',
		code: 'DICOMObject["00189807"]',
	},
	{
		label: 'DataType (00189808)',
		code: 'DICOMObject["00189808"]',
	},
	{
		label: 'TransducerScanPatternCodeSequence (00189809)',
		code: 'DICOMObject["00189809"]',
	},
	{
		label: 'AliasedDataType (0018980B)',
		code: 'DICOMObject["0018980B"]',
	},
	{
		label: 'PositionMeasuringDeviceUsed (0018980C)',
		code: 'DICOMObject["0018980C"]',
	},
	{
		label: 'TransducerGeometryCodeSequence (0018980D)',
		code: 'DICOMObject["0018980D"]',
	},
	{
		label: 'TransducerBeamSteeringCodeSequence (0018980E)',
		code: 'DICOMObject["0018980E"]',
	},
	{
		label: 'TransducerApplicationCodeSequence (0018980F)',
		code: 'DICOMObject["0018980F"]',
	},
	{
		label: 'ZeroVelocityPixelValue (00189810)',
		code: 'DICOMObject["00189810"]',
	},
	{
		label: 'ReferenceLocationLabel (00189900)',
		code: 'DICOMObject["00189900"]',
	},
	{
		label: 'ReferenceLocationDescription (00189901)',
		code: 'DICOMObject["00189901"]',
	},
	{
		label: 'ReferenceBasisCodeSequence (00189902)',
		code: 'DICOMObject["00189902"]',
	},
	{
		label: 'ReferenceGeometryCodeSequence (00189903)',
		code: 'DICOMObject["00189903"]',
	},
	{
		label: 'OffsetDistance (00189904)',
		code: 'DICOMObject["00189904"]',
	},
	{
		label: 'OffsetDirection (00189905)',
		code: 'DICOMObject["00189905"]',
	},
	{
		label: 'PotentialScheduledProtocolCodeSequence (00189906)',
		code: 'DICOMObject["00189906"]',
	},
	{
		label: 'PotentialRequestedProcedureCodeSequence (00189907)',
		code: 'DICOMObject["00189907"]',
	},
	{
		label: 'PotentialReasonsForProcedure (00189908)',
		code: 'DICOMObject["00189908"]',
	},
	{
		label: 'PotentialReasonsForProcedureCodeSequence (00189909)',
		code: 'DICOMObject["00189909"]',
	},
	{
		label: 'PotentialDiagnosticTasks (0018990A)',
		code: 'DICOMObject["0018990A"]',
	},
	{
		label: 'ContraindicationsCodeSequence (0018990B)',
		code: 'DICOMObject["0018990B"]',
	},
	{
		label: 'ReferencedDefinedProtocolSequence (0018990C)',
		code: 'DICOMObject["0018990C"]',
	},
	{
		label: 'ReferencedPerformedProtocolSequence (0018990D)',
		code: 'DICOMObject["0018990D"]',
	},
	{
		label: 'PredecessorProtocolSequence (0018990E)',
		code: 'DICOMObject["0018990E"]',
	},
	{
		label: 'ProtocolPlanningInformation (0018990F)',
		code: 'DICOMObject["0018990F"]',
	},
	{
		label: 'ProtocolDesignRationale (00189910)',
		code: 'DICOMObject["00189910"]',
	},
	{
		label: 'PatientSpecificationSequence (00189911)',
		code: 'DICOMObject["00189911"]',
	},
	{
		label: 'ModelSpecificationSequence (00189912)',
		code: 'DICOMObject["00189912"]',
	},
	{
		label: 'ParametersSpecificationSequence (00189913)',
		code: 'DICOMObject["00189913"]',
	},
	{
		label: 'InstructionSequence (00189914)',
		code: 'DICOMObject["00189914"]',
	},
	{
		label: 'InstructionIndex (00189915)',
		code: 'DICOMObject["00189915"]',
	},
	{
		label: 'InstructionText (00189916)',
		code: 'DICOMObject["00189916"]',
	},
	{
		label: 'InstructionDescription (00189917)',
		code: 'DICOMObject["00189917"]',
	},
	{
		label: 'InstructionPerformedFlag (00189918)',
		code: 'DICOMObject["00189918"]',
	},
	{
		label: 'InstructionPerformedDateTime (00189919)',
		code: 'DICOMObject["00189919"]',
	},
	{
		label: 'InstructionPerformanceComment (0018991A)',
		code: 'DICOMObject["0018991A"]',
	},
	{
		label: 'PatientPositioningInstructionSequence (0018991B)',
		code: 'DICOMObject["0018991B"]',
	},
	{
		label: 'PositioningMethodCodeSequence (0018991C)',
		code: 'DICOMObject["0018991C"]',
	},
	{
		label: 'PositioningLandmarkSequence (0018991D)',
		code: 'DICOMObject["0018991D"]',
	},
	{
		label: 'TargetFrameOfReferenceUID (0018991E)',
		code: 'DICOMObject["0018991E"]',
	},
	{
		label: 'AcquisitionProtocolElementSpecificationSequence (0018991F)',
		code: 'DICOMObject["0018991F"]',
	},
	{
		label: 'AcquisitionProtocolElementSequence (00189920)',
		code: 'DICOMObject["00189920"]',
	},
	{
		label: 'ProtocolElementNumber (00189921)',
		code: 'DICOMObject["00189921"]',
	},
	{
		label: 'ProtocolElementName (00189922)',
		code: 'DICOMObject["00189922"]',
	},
	{
		label: 'ProtocolElementCharacteristicsSummary (00189923)',
		code: 'DICOMObject["00189923"]',
	},
	{
		label: 'ProtocolElementPurpose (00189924)',
		code: 'DICOMObject["00189924"]',
	},
	{
		label: 'AcquisitionMotion (00189930)',
		code: 'DICOMObject["00189930"]',
	},
	{
		label: 'AcquisitionStartLocationSequence (00189931)',
		code: 'DICOMObject["00189931"]',
	},
	{
		label: 'AcquisitionEndLocationSequence (00189932)',
		code: 'DICOMObject["00189932"]',
	},
	{
		label: 'ReconstructionProtocolElementSpecificationSequence (00189933)',
		code: 'DICOMObject["00189933"]',
	},
	{
		label: 'ReconstructionProtocolElementSequence (00189934)',
		code: 'DICOMObject["00189934"]',
	},
	{
		label: 'StorageProtocolElementSpecificationSequence (00189935)',
		code: 'DICOMObject["00189935"]',
	},
	{
		label: 'StorageProtocolElementSequence (00189936)',
		code: 'DICOMObject["00189936"]',
	},
	{
		label: 'RequestedSeriesDescription (00189937)',
		code: 'DICOMObject["00189937"]',
	},
	{
		label: 'SourceAcquisitionProtocolElementNumber (00189938)',
		code: 'DICOMObject["00189938"]',
	},
	{
		label: 'SourceAcquisitionBeamNumber (00189939)',
		code: 'DICOMObject["00189939"]',
	},
	{
		label: 'SourceReconstructionProtocolElementNumber (0018993A)',
		code: 'DICOMObject["0018993A"]',
	},
	{
		label: 'ReconstructionStartLocationSequence (0018993B)',
		code: 'DICOMObject["0018993B"]',
	},
	{
		label: 'ReconstructionEndLocationSequence (0018993C)',
		code: 'DICOMObject["0018993C"]',
	},
	{
		label: 'ReconstructionAlgorithmSequence (0018993D)',
		code: 'DICOMObject["0018993D"]',
	},
	{
		label: 'ReconstructionTargetCenterLocationSequence (0018993E)',
		code: 'DICOMObject["0018993E"]',
	},
	{
		label: 'ImageFilterDescription (00189941)',
		code: 'DICOMObject["00189941"]',
	},
	{
		label: 'CTDIvolNotificationTrigger (00189942)',
		code: 'DICOMObject["00189942"]',
	},
	{
		label: 'DLPNotificationTrigger (00189943)',
		code: 'DICOMObject["00189943"]',
	},
	{
		label: 'AutoKVPSelectionType (00189944)',
		code: 'DICOMObject["00189944"]',
	},
	{
		label: 'AutoKVPUpperBound (00189945)',
		code: 'DICOMObject["00189945"]',
	},
	{
		label: 'AutoKVPLowerBound (00189946)',
		code: 'DICOMObject["00189946"]',
	},
	{
		label: 'ProtocolDefinedPatientPosition (00189947)',
		code: 'DICOMObject["00189947"]',
	},
	{
		label: 'ContributingEquipmentSequence (0018A001)',
		code: 'DICOMObject["0018A001"]',
	},
	{
		label: 'ContributionDateTime (0018A002)',
		code: 'DICOMObject["0018A002"]',
	},
	{
		label: 'ContributionDescription (0018A003)',
		code: 'DICOMObject["0018A003"]',
	},
	{
		label: 'StudyInstanceUID (0020000D)',
		code: 'DICOMObject["0020000D"]',
	},
	{
		label: 'SeriesInstanceUID (0020000E)',
		code: 'DICOMObject["0020000E"]',
	},
	{
		label: 'StudyID (00200010)',
		code: 'DICOMObject["00200010"]',
	},
	{
		label: 'SeriesNumber (00200011)',
		code: 'DICOMObject["00200011"]',
	},
	{
		label: 'AcquisitionNumber (00200012)',
		code: 'DICOMObject["00200012"]',
	},
	{
		label: 'InstanceNumber (00200013)',
		code: 'DICOMObject["00200013"]',
	},
	{
		label: 'ItemNumber (00200019)',
		code: 'DICOMObject["00200019"]',
	},
	{
		label: 'PatientOrientation (00200020)',
		code: 'DICOMObject["00200020"]',
	},
	{
		label: 'ImagePositionPatient (00200032)',
		code: 'DICOMObject["00200032"]',
	},
	{
		label: 'ImageOrientationPatient (00200037)',
		code: 'DICOMObject["00200037"]',
	},
	{
		label: 'FrameOfReferenceUID (00200052)',
		code: 'DICOMObject["00200052"]',
	},
	{
		label: 'Laterality (00200060)',
		code: 'DICOMObject["00200060"]',
	},
	{
		label: 'ImageLaterality (00200062)',
		code: 'DICOMObject["00200062"]',
	},
	{
		label: 'TemporalPositionIdentifier (00200100)',
		code: 'DICOMObject["00200100"]',
	},
	{
		label: 'NumberOfTemporalPositions (00200105)',
		code: 'DICOMObject["00200105"]',
	},
	{
		label: 'TemporalResolution (00200110)',
		code: 'DICOMObject["00200110"]',
	},
	{
		label: 'SynchronizationFrameOfReferenceUID (00200200)',
		code: 'DICOMObject["00200200"]',
	},
	{
		label: 'SOPInstanceUIDOfConcatenationSource (00200242)',
		code: 'DICOMObject["00200242"]',
	},
	{
		label: 'ImagesInAcquisition (00201002)',
		code: 'DICOMObject["00201002"]',
	},
	{
		label: 'TargetPositionReferenceIndicator (0020103F)',
		code: 'DICOMObject["0020103F"]',
	},
	{
		label: 'PositionReferenceIndicator (00201040)',
		code: 'DICOMObject["00201040"]',
	},
	{
		label: 'SliceLocation (00201041)',
		code: 'DICOMObject["00201041"]',
	},
	{
		label: 'NumberOfPatientRelatedStudies (00201200)',
		code: 'DICOMObject["00201200"]',
	},
	{
		label: 'NumberOfPatientRelatedSeries (00201202)',
		code: 'DICOMObject["00201202"]',
	},
	{
		label: 'NumberOfPatientRelatedInstances (00201204)',
		code: 'DICOMObject["00201204"]',
	},
	{
		label: 'NumberOfStudyRelatedSeries (00201206)',
		code: 'DICOMObject["00201206"]',
	},
	{
		label: 'NumberOfStudyRelatedInstances (00201208)',
		code: 'DICOMObject["00201208"]',
	},
	{
		label: 'NumberOfSeriesRelatedInstances (00201209)',
		code: 'DICOMObject["00201209"]',
	},
	{
		label: 'ImageComments (00204000)',
		code: 'DICOMObject["00204000"]',
	},
	{
		label: 'StackID (00209056)',
		code: 'DICOMObject["00209056"]',
	},
	{
		label: 'InStackPositionNumber (00209057)',
		code: 'DICOMObject["00209057"]',
	},
	{
		label: 'FrameAnatomySequence (00209071)',
		code: 'DICOMObject["00209071"]',
	},
	{
		label: 'FrameLaterality (00209072)',
		code: 'DICOMObject["00209072"]',
	},
	{
		label: 'FrameContentSequence (00209111)',
		code: 'DICOMObject["00209111"]',
	},
	{
		label: 'PlanePositionSequence (00209113)',
		code: 'DICOMObject["00209113"]',
	},
	{
		label: 'PlaneOrientationSequence (00209116)',
		code: 'DICOMObject["00209116"]',
	},
	{
		label: 'TemporalPositionIndex (00209128)',
		code: 'DICOMObject["00209128"]',
	},
	{
		label: 'NominalCardiacTriggerDelayTime (00209153)',
		code: 'DICOMObject["00209153"]',
	},
	{
		label: 'NominalCardiacTriggerTimePriorToRPeak (00209154)',
		code: 'DICOMObject["00209154"]',
	},
	{
		label: 'ActualCardiacTriggerTimePriorToRPeak (00209155)',
		code: 'DICOMObject["00209155"]',
	},
	{
		label: 'FrameAcquisitionNumber (00209156)',
		code: 'DICOMObject["00209156"]',
	},
	{
		label: 'DimensionIndexValues (00209157)',
		code: 'DICOMObject["00209157"]',
	},
	{
		label: 'FrameComments (00209158)',
		code: 'DICOMObject["00209158"]',
	},
	{
		label: 'ConcatenationUID (00209161)',
		code: 'DICOMObject["00209161"]',
	},
	{
		label: 'InConcatenationNumber (00209162)',
		code: 'DICOMObject["00209162"]',
	},
	{
		label: 'InConcatenationTotalNumber (00209163)',
		code: 'DICOMObject["00209163"]',
	},
	{
		label: 'DimensionOrganizationUID (00209164)',
		code: 'DICOMObject["00209164"]',
	},
	{
		label: 'DimensionIndexPointer (00209165)',
		code: 'DICOMObject["00209165"]',
	},
	{
		label: 'FunctionalGroupPointer (00209167)',
		code: 'DICOMObject["00209167"]',
	},
	{
		label: 'UnassignedSharedConvertedAttributesSequence (00209170)',
		code: 'DICOMObject["00209170"]',
	},
	{
		label: 'UnassignedPerFrameConvertedAttributesSequence (00209171)',
		code: 'DICOMObject["00209171"]',
	},
	{
		label: 'ConversionSourceAttributesSequence (00209172)',
		code: 'DICOMObject["00209172"]',
	},
	{
		label: 'DimensionIndexPrivateCreator (00209213)',
		code: 'DICOMObject["00209213"]',
	},
	{
		label: 'DimensionOrganizationSequence (00209221)',
		code: 'DICOMObject["00209221"]',
	},
	{
		label: 'DimensionIndexSequence (00209222)',
		code: 'DICOMObject["00209222"]',
	},
	{
		label: 'ConcatenationFrameOffsetNumber (00209228)',
		code: 'DICOMObject["00209228"]',
	},
	{
		label: 'FunctionalGroupPrivateCreator (00209238)',
		code: 'DICOMObject["00209238"]',
	},
	{
		label: 'NominalPercentageOfCardiacPhase (00209241)',
		code: 'DICOMObject["00209241"]',
	},
	{
		label: 'NominalPercentageOfRespiratoryPhase (00209245)',
		code: 'DICOMObject["00209245"]',
	},
	{
		label: 'StartingRespiratoryAmplitude (00209246)',
		code: 'DICOMObject["00209246"]',
	},
	{
		label: 'StartingRespiratoryPhase (00209247)',
		code: 'DICOMObject["00209247"]',
	},
	{
		label: 'EndingRespiratoryAmplitude (00209248)',
		code: 'DICOMObject["00209248"]',
	},
	{
		label: 'EndingRespiratoryPhase (00209249)',
		code: 'DICOMObject["00209249"]',
	},
	{
		label: 'RespiratoryTriggerType (00209250)',
		code: 'DICOMObject["00209250"]',
	},
	{
		label: 'RRIntervalTimeNominal (00209251)',
		code: 'DICOMObject["00209251"]',
	},
	{
		label: 'ActualCardiacTriggerDelayTime (00209252)',
		code: 'DICOMObject["00209252"]',
	},
	{
		label: 'RespiratorySynchronizationSequence (00209253)',
		code: 'DICOMObject["00209253"]',
	},
	{
		label: 'RespiratoryIntervalTime (00209254)',
		code: 'DICOMObject["00209254"]',
	},
	{
		label: 'NominalRespiratoryTriggerDelayTime (00209255)',
		code: 'DICOMObject["00209255"]',
	},
	{
		label: 'RespiratoryTriggerDelayThreshold (00209256)',
		code: 'DICOMObject["00209256"]',
	},
	{
		label: 'ActualRespiratoryTriggerDelayTime (00209257)',
		code: 'DICOMObject["00209257"]',
	},
	{
		label: 'ImagePositionVolume (00209301)',
		code: 'DICOMObject["00209301"]',
	},
	{
		label: 'ImageOrientationVolume (00209302)',
		code: 'DICOMObject["00209302"]',
	},
	{
		label: 'UltrasoundAcquisitionGeometry (00209307)',
		code: 'DICOMObject["00209307"]',
	},
	{
		label: 'ApexPosition (00209308)',
		code: 'DICOMObject["00209308"]',
	},
	{
		label: 'VolumeToTransducerMappingMatrix (00209309)',
		code: 'DICOMObject["00209309"]',
	},
	{
		label: 'VolumeToTableMappingMatrix (0020930A)',
		code: 'DICOMObject["0020930A"]',
	},
	{
		label: 'VolumeToTransducerRelationship (0020930B)',
		code: 'DICOMObject["0020930B"]',
	},
	{
		label: 'PatientFrameOfReferenceSource (0020930C)',
		code: 'DICOMObject["0020930C"]',
	},
	{
		label: 'TemporalPositionTimeOffset (0020930D)',
		code: 'DICOMObject["0020930D"]',
	},
	{
		label: 'PlanePositionVolumeSequence (0020930E)',
		code: 'DICOMObject["0020930E"]',
	},
	{
		label: 'PlaneOrientationVolumeSequence (0020930F)',
		code: 'DICOMObject["0020930F"]',
	},
	{
		label: 'TemporalPositionSequence (00209310)',
		code: 'DICOMObject["00209310"]',
	},
	{
		label: 'DimensionOrganizationType (00209311)',
		code: 'DICOMObject["00209311"]',
	},
	{
		label: 'VolumeFrameOfReferenceUID (00209312)',
		code: 'DICOMObject["00209312"]',
	},
	{
		label: 'TableFrameOfReferenceUID (00209313)',
		code: 'DICOMObject["00209313"]',
	},
	{
		label: 'DimensionDescriptionLabel (00209421)',
		code: 'DICOMObject["00209421"]',
	},
	{
		label: 'PatientOrientationInFrameSequence (00209450)',
		code: 'DICOMObject["00209450"]',
	},
	{
		label: 'FrameLabel (00209453)',
		code: 'DICOMObject["00209453"]',
	},
	{
		label: 'AcquisitionIndex (00209518)',
		code: 'DICOMObject["00209518"]',
	},
	{
		label: 'ContributingSOPInstancesReferenceSequence (00209529)',
		code: 'DICOMObject["00209529"]',
	},
	{
		label: 'ReconstructionIndex (00209536)',
		code: 'DICOMObject["00209536"]',
	},
	{
		label: 'LightPathFilterPassThroughWavelength (00220001)',
		code: 'DICOMObject["00220001"]',
	},
	{
		label: 'LightPathFilterPassBand (00220002)',
		code: 'DICOMObject["00220002"]',
	},
	{
		label: 'ImagePathFilterPassThroughWavelength (00220003)',
		code: 'DICOMObject["00220003"]',
	},
	{
		label: 'ImagePathFilterPassBand (00220004)',
		code: 'DICOMObject["00220004"]',
	},
	{
		label: 'PatientEyeMovementCommanded (00220005)',
		code: 'DICOMObject["00220005"]',
	},
	{
		label: 'PatientEyeMovementCommandCodeSequence (00220006)',
		code: 'DICOMObject["00220006"]',
	},
	{
		label: 'SphericalLensPower (00220007)',
		code: 'DICOMObject["00220007"]',
	},
	{
		label: 'CylinderLensPower (00220008)',
		code: 'DICOMObject["00220008"]',
	},
	{
		label: 'CylinderAxis (00220009)',
		code: 'DICOMObject["00220009"]',
	},
	{
		label: 'EmmetropicMagnification (0022000A)',
		code: 'DICOMObject["0022000A"]',
	},
	{
		label: 'IntraOcularPressure (0022000B)',
		code: 'DICOMObject["0022000B"]',
	},
	{
		label: 'HorizontalFieldOfView (0022000C)',
		code: 'DICOMObject["0022000C"]',
	},
	{
		label: 'PupilDilated (0022000D)',
		code: 'DICOMObject["0022000D"]',
	},
	{
		label: 'DegreeOfDilation (0022000E)',
		code: 'DICOMObject["0022000E"]',
	},
	{
		label: 'StereoBaselineAngle (00220010)',
		code: 'DICOMObject["00220010"]',
	},
	{
		label: 'StereoBaselineDisplacement (00220011)',
		code: 'DICOMObject["00220011"]',
	},
	{
		label: 'StereoHorizontalPixelOffset (00220012)',
		code: 'DICOMObject["00220012"]',
	},
	{
		label: 'StereoVerticalPixelOffset (00220013)',
		code: 'DICOMObject["00220013"]',
	},
	{
		label: 'StereoRotation (00220014)',
		code: 'DICOMObject["00220014"]',
	},
	{
		label: 'AcquisitionDeviceTypeCodeSequence (00220015)',
		code: 'DICOMObject["00220015"]',
	},
	{
		label: 'IlluminationTypeCodeSequence (00220016)',
		code: 'DICOMObject["00220016"]',
	},
	{
		label: 'LightPathFilterTypeStackCodeSequence (00220017)',
		code: 'DICOMObject["00220017"]',
	},
	{
		label: 'ImagePathFilterTypeStackCodeSequence (00220018)',
		code: 'DICOMObject["00220018"]',
	},
	{
		label: 'LensesCodeSequence (00220019)',
		code: 'DICOMObject["00220019"]',
	},
	{
		label: 'ChannelDescriptionCodeSequence (0022001A)',
		code: 'DICOMObject["0022001A"]',
	},
	{
		label: 'RefractiveStateSequence (0022001B)',
		code: 'DICOMObject["0022001B"]',
	},
	{
		label: 'MydriaticAgentCodeSequence (0022001C)',
		code: 'DICOMObject["0022001C"]',
	},
	{
		label: 'RelativeImagePositionCodeSequence (0022001D)',
		code: 'DICOMObject["0022001D"]',
	},
	{
		label: 'CameraAngleOfView (0022001E)',
		code: 'DICOMObject["0022001E"]',
	},
	{
		label: 'StereoPairsSequence (00220020)',
		code: 'DICOMObject["00220020"]',
	},
	{
		label: 'LeftImageSequence (00220021)',
		code: 'DICOMObject["00220021"]',
	},
	{
		label: 'RightImageSequence (00220022)',
		code: 'DICOMObject["00220022"]',
	},
	{
		label: 'StereoPairsPresent (00220028)',
		code: 'DICOMObject["00220028"]',
	},
	{
		label: 'AxialLengthOfTheEye (00220030)',
		code: 'DICOMObject["00220030"]',
	},
	{
		label: 'OphthalmicFrameLocationSequence (00220031)',
		code: 'DICOMObject["00220031"]',
	},
	{
		label: 'ReferenceCoordinates (00220032)',
		code: 'DICOMObject["00220032"]',
	},
	{
		label: 'DepthSpatialResolution (00220035)',
		code: 'DICOMObject["00220035"]',
	},
	{
		label: 'MaximumDepthDistortion (00220036)',
		code: 'DICOMObject["00220036"]',
	},
	{
		label: 'AlongScanSpatialResolution (00220037)',
		code: 'DICOMObject["00220037"]',
	},
	{
		label: 'MaximumAlongScanDistortion (00220038)',
		code: 'DICOMObject["00220038"]',
	},
	{
		label: 'OphthalmicImageOrientation (00220039)',
		code: 'DICOMObject["00220039"]',
	},
	{
		label: 'DepthOfTransverseImage (00220041)',
		code: 'DICOMObject["00220041"]',
	},
	{
		label: 'MydriaticAgentConcentrationUnitsSequence (00220042)',
		code: 'DICOMObject["00220042"]',
	},
	{
		label: 'AcrossScanSpatialResolution (00220048)',
		code: 'DICOMObject["00220048"]',
	},
	{
		label: 'MaximumAcrossScanDistortion (00220049)',
		code: 'DICOMObject["00220049"]',
	},
	{
		label: 'MydriaticAgentConcentration (0022004E)',
		code: 'DICOMObject["0022004E"]',
	},
	{
		label: 'IlluminationWaveLength (00220055)',
		code: 'DICOMObject["00220055"]',
	},
	{
		label: 'IlluminationPower (00220056)',
		code: 'DICOMObject["00220056"]',
	},
	{
		label: 'IlluminationBandwidth (00220057)',
		code: 'DICOMObject["00220057"]',
	},
	{
		label: 'MydriaticAgentSequence (00220058)',
		code: 'DICOMObject["00220058"]',
	},
	{
		label: 'OphthalmicAxialMeasurementsRightEyeSequence (00221007)',
		code: 'DICOMObject["00221007"]',
	},
	{
		label: 'OphthalmicAxialMeasurementsLeftEyeSequence (00221008)',
		code: 'DICOMObject["00221008"]',
	},
	{
		label: 'OphthalmicAxialMeasurementsDeviceType (00221009)',
		code: 'DICOMObject["00221009"]',
	},
	{
		label: 'OphthalmicAxialLengthMeasurementsType (00221010)',
		code: 'DICOMObject["00221010"]',
	},
	{
		label: 'OphthalmicAxialLengthSequence (00221012)',
		code: 'DICOMObject["00221012"]',
	},
	{
		label: 'OphthalmicAxialLength (00221019)',
		code: 'DICOMObject["00221019"]',
	},
	{
		label: 'LensStatusCodeSequence (00221024)',
		code: 'DICOMObject["00221024"]',
	},
	{
		label: 'VitreousStatusCodeSequence (00221025)',
		code: 'DICOMObject["00221025"]',
	},
	{
		label: 'IOLFormulaCodeSequence (00221028)',
		code: 'DICOMObject["00221028"]',
	},
	{
		label: 'IOLFormulaDetail (00221029)',
		code: 'DICOMObject["00221029"]',
	},
	{
		label: 'KeratometerIndex (00221033)',
		code: 'DICOMObject["00221033"]',
	},
	{
		label: 'SourceOfOphthalmicAxialLengthCodeSequence (00221035)',
		code: 'DICOMObject["00221035"]',
	},
	{
		label: 'TargetRefraction (00221037)',
		code: 'DICOMObject["00221037"]',
	},
	{
		label: 'RefractiveProcedureOccurred (00221039)',
		code: 'DICOMObject["00221039"]',
	},
	{
		label: 'RefractiveSurgeryTypeCodeSequence (00221040)',
		code: 'DICOMObject["00221040"]',
	},
	{
		label: 'OphthalmicUltrasoundMethodCodeSequence (00221044)',
		code: 'DICOMObject["00221044"]',
	},
	{
		label: 'OphthalmicAxialLengthMeasurementsSequence (00221050)',
		code: 'DICOMObject["00221050"]',
	},
	{
		label: 'IOLPower (00221053)',
		code: 'DICOMObject["00221053"]',
	},
	{
		label: 'PredictedRefractiveError (00221054)',
		code: 'DICOMObject["00221054"]',
	},
	{
		label: 'OphthalmicAxialLengthVelocity (00221059)',
		code: 'DICOMObject["00221059"]',
	},
	{
		label: 'LensStatusDescription (00221065)',
		code: 'DICOMObject["00221065"]',
	},
	{
		label: 'VitreousStatusDescription (00221066)',
		code: 'DICOMObject["00221066"]',
	},
	{
		label: 'IOLPowerSequence (00221090)',
		code: 'DICOMObject["00221090"]',
	},
	{
		label: 'LensConstantSequence (00221092)',
		code: 'DICOMObject["00221092"]',
	},
	{
		label: 'IOLManufacturer (00221093)',
		code: 'DICOMObject["00221093"]',
	},
	{
		label: 'ImplantName (00221095)',
		code: 'DICOMObject["00221095"]',
	},
	{
		label: 'KeratometryMeasurementTypeCodeSequence (00221096)',
		code: 'DICOMObject["00221096"]',
	},
	{
		label: 'ImplantPartNumber (00221097)',
		code: 'DICOMObject["00221097"]',
	},
	{
		label: 'ReferencedOphthalmicAxialMeasurementsSequence (00221100)',
		code: 'DICOMObject["00221100"]',
	},
	{
		label: 'OphthalmicAxialLengthMeasurementsSegmentNameCodeSequence (00221101)',
		code: 'DICOMObject["00221101"]',
	},
	{
		label: 'RefractiveErrorBeforeRefractiveSurgeryCodeSequence (00221103)',
		code: 'DICOMObject["00221103"]',
	},
	{
		label: 'IOLPowerForExactEmmetropia (00221121)',
		code: 'DICOMObject["00221121"]',
	},
	{
		label: 'IOLPowerForExactTargetRefraction (00221122)',
		code: 'DICOMObject["00221122"]',
	},
	{
		label: 'AnteriorChamberDepthDefinitionCodeSequence (00221125)',
		code: 'DICOMObject["00221125"]',
	},
	{
		label: 'LensThicknessSequence (00221127)',
		code: 'DICOMObject["00221127"]',
	},
	{
		label: 'AnteriorChamberDepthSequence (00221128)',
		code: 'DICOMObject["00221128"]',
	},
	{
		label: 'LensThickness (00221130)',
		code: 'DICOMObject["00221130"]',
	},
	{
		label: 'AnteriorChamberDepth (00221131)',
		code: 'DICOMObject["00221131"]',
	},
	{
		label: 'SourceOfLensThicknessDataCodeSequence (00221132)',
		code: 'DICOMObject["00221132"]',
	},
	{
		label: 'SourceOfAnteriorChamberDepthDataCodeSequence (00221133)',
		code: 'DICOMObject["00221133"]',
	},
	{
		label: 'SourceOfRefractiveMeasurementsSequence (00221134)',
		code: 'DICOMObject["00221134"]',
	},
	{
		label: 'SourceOfRefractiveMeasurementsCodeSequence (00221135)',
		code: 'DICOMObject["00221135"]',
	},
	{
		label: 'OphthalmicAxialLengthMeasurementModified (00221140)',
		code: 'DICOMObject["00221140"]',
	},
	{
		label: 'OphthalmicAxialLengthDataSourceCodeSequence (00221150)',
		code: 'DICOMObject["00221150"]',
	},
	{
		label: 'SignalToNoiseRatio (00221155)',
		code: 'DICOMObject["00221155"]',
	},
	{
		label: 'OphthalmicAxialLengthDataSourceDescription (00221159)',
		code: 'DICOMObject["00221159"]',
	},
	{
		label: 'OphthalmicAxialLengthMeasurementsTotalLengthSequence (00221210)',
		code: 'DICOMObject["00221210"]',
	},
	{
		label: 'OphthalmicAxialLengthMeasurementsSegmentalLengthSequence (00221211)',
		code: 'DICOMObject["00221211"]',
	},
	{
		label: 'OphthalmicAxialLengthMeasurementsLengthSummationSequence (00221212)',
		code: 'DICOMObject["00221212"]',
	},
	{
		label: 'UltrasoundOphthalmicAxialLengthMeasurementsSequence (00221220)',
		code: 'DICOMObject["00221220"]',
	},
	{
		label: 'OpticalOphthalmicAxialLengthMeasurementsSequence (00221225)',
		code: 'DICOMObject["00221225"]',
	},
	{
		label: 'UltrasoundSelectedOphthalmicAxialLengthSequence (00221230)',
		code: 'DICOMObject["00221230"]',
	},
	{
		label: 'OphthalmicAxialLengthSelectionMethodCodeSequence (00221250)',
		code: 'DICOMObject["00221250"]',
	},
	{
		label: 'OpticalSelectedOphthalmicAxialLengthSequence (00221255)',
		code: 'DICOMObject["00221255"]',
	},
	{
		label: 'SelectedSegmentalOphthalmicAxialLengthSequence (00221257)',
		code: 'DICOMObject["00221257"]',
	},
	{
		label: 'SelectedTotalOphthalmicAxialLengthSequence (00221260)',
		code: 'DICOMObject["00221260"]',
	},
	{
		label: 'OphthalmicAxialLengthQualityMetricSequence (00221262)',
		code: 'DICOMObject["00221262"]',
	},
	{
		label: 'IntraocularLensCalculationsRightEyeSequence (00221300)',
		code: 'DICOMObject["00221300"]',
	},
	{
		label: 'IntraocularLensCalculationsLeftEyeSequence (00221310)',
		code: 'DICOMObject["00221310"]',
	},
	{
		label: 'ReferencedOphthalmicAxialLengthMeasurementQCImageSequence (00221330)',
		code: 'DICOMObject["00221330"]',
	},
	{
		label: 'OphthalmicMappingDeviceType (00221415)',
		code: 'DICOMObject["00221415"]',
	},
	{
		label: 'AcquisitionMethodCodeSequence (00221420)',
		code: 'DICOMObject["00221420"]',
	},
	{
		label: 'AcquisitionMethodAlgorithmSequence (00221423)',
		code: 'DICOMObject["00221423"]',
	},
	{
		label: 'OphthalmicThicknessMapTypeCodeSequence (00221436)',
		code: 'DICOMObject["00221436"]',
	},
	{
		label: 'OphthalmicThicknessMappingNormalsSequence (00221443)',
		code: 'DICOMObject["00221443"]',
	},
	{
		label: 'RetinalThicknessDefinitionCodeSequence (00221445)',
		code: 'DICOMObject["00221445"]',
	},
	{
		label: 'PixelValueMappingToCodedConceptSequence (00221450)',
		code: 'DICOMObject["00221450"]',
	},
	{
		label: 'MappedPixelValue (00221452)',
		code: 'DICOMObject["00221452"]',
	},
	{
		label: 'PixelValueMappingExplanation (00221454)',
		code: 'DICOMObject["00221454"]',
	},
	{
		label: 'OphthalmicThicknessMapQualityThresholdSequence (00221458)',
		code: 'DICOMObject["00221458"]',
	},
	{
		label: 'OphthalmicThicknessMapThresholdQualityRating (00221460)',
		code: 'DICOMObject["00221460"]',
	},
	{
		label: 'AnatomicStructureReferencePoint (00221463)',
		code: 'DICOMObject["00221463"]',
	},
	{
		label: 'RegistrationToLocalizerSequence (00221465)',
		code: 'DICOMObject["00221465"]',
	},
	{
		label: 'RegisteredLocalizerUnits (00221466)',
		code: 'DICOMObject["00221466"]',
	},
	{
		label: 'RegisteredLocalizerTopLeftHandCorner (00221467)',
		code: 'DICOMObject["00221467"]',
	},
	{
		label: 'RegisteredLocalizerBottomRightHandCorner (00221468)',
		code: 'DICOMObject["00221468"]',
	},
	{
		label: 'OphthalmicThicknessMapQualityRatingSequence (00221470)',
		code: 'DICOMObject["00221470"]',
	},
	{
		label: 'RelevantOPTAttributesSequence (00221472)',
		code: 'DICOMObject["00221472"]',
	},
	{
		label: 'TransformationMethodCodeSequence (00221512)',
		code: 'DICOMObject["00221512"]',
	},
	{
		label: 'TransformationAlgorithmSequence (00221513)',
		code: 'DICOMObject["00221513"]',
	},
	{
		label: 'OphthalmicAxialLengthMethod (00221515)',
		code: 'DICOMObject["00221515"]',
	},
	{
		label: 'OphthalmicFOV (00221517)',
		code: 'DICOMObject["00221517"]',
	},
	{
		label: 'TwoDimensionalToThreeDimensionalMapSequence (00221518)',
		code: 'DICOMObject["00221518"]',
	},
	{
		label: 'WideFieldOphthalmicPhotographyQualityRatingSequence (00221525)',
		code: 'DICOMObject["00221525"]',
	},
	{
		label: 'WideFieldOphthalmicPhotographyQualityThresholdSequence (00221526)',
		code: 'DICOMObject["00221526"]',
	},
	{
		label: 'WideFieldOphthalmicPhotographyThresholdQualityRating (00221527)',
		code: 'DICOMObject["00221527"]',
	},
	{
		label: 'XCoordinatesCenterPixelViewAngle (00221528)',
		code: 'DICOMObject["00221528"]',
	},
	{
		label: 'YCoordinatesCenterPixelViewAngle (00221529)',
		code: 'DICOMObject["00221529"]',
	},
	{
		label: 'NumberOfMapPoints (00221530)',
		code: 'DICOMObject["00221530"]',
	},
	{
		label: 'TwoDimensionalToThreeDimensionalMapData (00221531)',
		code: 'DICOMObject["00221531"]',
	},
	{
		label: 'DerivationAlgorithmSequence (00221612)',
		code: 'DICOMObject["00221612"]',
	},
	{
		label: 'OphthalmicImageTypeCodeSequence (00221615)',
		code: 'DICOMObject["00221615"]',
	},
	{
		label: 'OphthalmicImageTypeDescription (00221616)',
		code: 'DICOMObject["00221616"]',
	},
	{
		label: 'ScanPatternTypeCodeSequence (00221618)',
		code: 'DICOMObject["00221618"]',
	},
	{
		label: 'ReferencedSurfaceMeshIdentificationSequence (00221620)',
		code: 'DICOMObject["00221620"]',
	},
	{
		label: 'OphthalmicVolumetricPropertiesFlag (00221622)',
		code: 'DICOMObject["00221622"]',
	},
	{
		label: 'OphthalmicAnatomicReferencePointXCoordinate (00221624)',
		code: 'DICOMObject["00221624"]',
	},
	{
		label: 'OphthalmicAnatomicReferencePointYCoordinate (00221626)',
		code: 'DICOMObject["00221626"]',
	},
	{
		label: 'OphthalmicEnFaceImageQualityRatingSequence (00221628)',
		code: 'DICOMObject["00221628"]',
	},
	{
		label: 'QualityThreshold (00221630)',
		code: 'DICOMObject["00221630"]',
	},
	{
		label: 'OCTBscanAnalysisAcquisitionParametersSequence (00221640)',
		code: 'DICOMObject["00221640"]',
	},
	{
		label: 'NumberofBscansPerFrame (00221642)',
		code: 'DICOMObject["00221642"]',
	},
	{
		label: 'BscanSlabThickness (00221643)',
		code: 'DICOMObject["00221643"]',
	},
	{
		label: 'DistanceBetweenBscanSlabs (00221644)',
		code: 'DICOMObject["00221644"]',
	},
	{
		label: 'BscanCycleTime (00221645)',
		code: 'DICOMObject["00221645"]',
	},
	{
		label: 'BscanCycleTimeVector (00221646)',
		code: 'DICOMObject["00221646"]',
	},
	{
		label: 'AscanRate (00221649)',
		code: 'DICOMObject["00221649"]',
	},
	{
		label: 'BscanRate (00221650)',
		code: 'DICOMObject["00221650"]',
	},
	{
		label: 'SurfaceMeshZPixelOffset (00221658)',
		code: 'DICOMObject["00221658"]',
	},
	{
		label: 'VisualFieldHorizontalExtent (00240010)',
		code: 'DICOMObject["00240010"]',
	},
	{
		label: 'VisualFieldVerticalExtent (00240011)',
		code: 'DICOMObject["00240011"]',
	},
	{
		label: 'VisualFieldShape (00240012)',
		code: 'DICOMObject["00240012"]',
	},
	{
		label: 'ScreeningTestModeCodeSequence (00240016)',
		code: 'DICOMObject["00240016"]',
	},
	{
		label: 'MaximumStimulusLuminance (00240018)',
		code: 'DICOMObject["00240018"]',
	},
	{
		label: 'BackgroundLuminance (00240020)',
		code: 'DICOMObject["00240020"]',
	},
	{
		label: 'StimulusColorCodeSequence (00240021)',
		code: 'DICOMObject["00240021"]',
	},
	{
		label: 'BackgroundIlluminationColorCodeSequence (00240024)',
		code: 'DICOMObject["00240024"]',
	},
	{
		label: 'StimulusArea (00240025)',
		code: 'DICOMObject["00240025"]',
	},
	{
		label: 'StimulusPresentationTime (00240028)',
		code: 'DICOMObject["00240028"]',
	},
	{
		label: 'FixationSequence (00240032)',
		code: 'DICOMObject["00240032"]',
	},
	{
		label: 'FixationMonitoringCodeSequence (00240033)',
		code: 'DICOMObject["00240033"]',
	},
	{
		label: 'VisualFieldCatchTrialSequence (00240034)',
		code: 'DICOMObject["00240034"]',
	},
	{
		label: 'FixationCheckedQuantity (00240035)',
		code: 'DICOMObject["00240035"]',
	},
	{
		label: 'PatientNotProperlyFixatedQuantity (00240036)',
		code: 'DICOMObject["00240036"]',
	},
	{
		label: 'PresentedVisualStimuliDataFlag (00240037)',
		code: 'DICOMObject["00240037"]',
	},
	{
		label: 'NumberOfVisualStimuli (00240038)',
		code: 'DICOMObject["00240038"]',
	},
	{
		label: 'ExcessiveFixationLossesDataFlag (00240039)',
		code: 'DICOMObject["00240039"]',
	},
	{
		label: 'ExcessiveFixationLosses (00240040)',
		code: 'DICOMObject["00240040"]',
	},
	{
		label: 'StimuliRetestingQuantity (00240042)',
		code: 'DICOMObject["00240042"]',
	},
	{
		label: 'CommentsOnPatientPerformanceOfVisualField (00240044)',
		code: 'DICOMObject["00240044"]',
	},
	{
		label: 'FalseNegativesEstimateFlag (00240045)',
		code: 'DICOMObject["00240045"]',
	},
	{
		label: 'FalseNegativesEstimate (00240046)',
		code: 'DICOMObject["00240046"]',
	},
	{
		label: 'NegativeCatchTrialsQuantity (00240048)',
		code: 'DICOMObject["00240048"]',
	},
	{
		label: 'FalseNegativesQuantity (00240050)',
		code: 'DICOMObject["00240050"]',
	},
	{
		label: 'ExcessiveFalseNegativesDataFlag (00240051)',
		code: 'DICOMObject["00240051"]',
	},
	{
		label: 'ExcessiveFalseNegatives (00240052)',
		code: 'DICOMObject["00240052"]',
	},
	{
		label: 'FalsePositivesEstimateFlag (00240053)',
		code: 'DICOMObject["00240053"]',
	},
	{
		label: 'FalsePositivesEstimate (00240054)',
		code: 'DICOMObject["00240054"]',
	},
	{
		label: 'CatchTrialsDataFlag (00240055)',
		code: 'DICOMObject["00240055"]',
	},
	{
		label: 'PositiveCatchTrialsQuantity (00240056)',
		code: 'DICOMObject["00240056"]',
	},
	{
		label: 'TestPointNormalsDataFlag (00240057)',
		code: 'DICOMObject["00240057"]',
	},
	{
		label: 'TestPointNormalsSequence (00240058)',
		code: 'DICOMObject["00240058"]',
	},
	{
		label: 'GlobalDeviationProbabilityNormalsFlag (00240059)',
		code: 'DICOMObject["00240059"]',
	},
	{
		label: 'FalsePositivesQuantity (00240060)',
		code: 'DICOMObject["00240060"]',
	},
	{
		label: 'ExcessiveFalsePositivesDataFlag (00240061)',
		code: 'DICOMObject["00240061"]',
	},
	{
		label: 'ExcessiveFalsePositives (00240062)',
		code: 'DICOMObject["00240062"]',
	},
	{
		label: 'VisualFieldTestNormalsFlag (00240063)',
		code: 'DICOMObject["00240063"]',
	},
	{
		label: 'ResultsNormalsSequence (00240064)',
		code: 'DICOMObject["00240064"]',
	},
	{
		label: 'AgeCorrectedSensitivityDeviationAlgorithmSequence (00240065)',
		code: 'DICOMObject["00240065"]',
	},
	{
		label: 'GlobalDeviationFromNormal (00240066)',
		code: 'DICOMObject["00240066"]',
	},
	{
		label: 'GeneralizedDefectSensitivityDeviationAlgorithmSequence (00240067)',
		code: 'DICOMObject["00240067"]',
	},
	{
		label: 'LocalizedDeviationFromNormal (00240068)',
		code: 'DICOMObject["00240068"]',
	},
	{
		label: 'PatientReliabilityIndicator (00240069)',
		code: 'DICOMObject["00240069"]',
	},
	{
		label: 'VisualFieldMeanSensitivity (00240070)',
		code: 'DICOMObject["00240070"]',
	},
	{
		label: 'GlobalDeviationProbability (00240071)',
		code: 'DICOMObject["00240071"]',
	},
	{
		label: 'LocalDeviationProbabilityNormalsFlag (00240072)',
		code: 'DICOMObject["00240072"]',
	},
	{
		label: 'LocalizedDeviationProbability (00240073)',
		code: 'DICOMObject["00240073"]',
	},
	{
		label: 'ShortTermFluctuationCalculated (00240074)',
		code: 'DICOMObject["00240074"]',
	},
	{
		label: 'ShortTermFluctuation (00240075)',
		code: 'DICOMObject["00240075"]',
	},
	{
		label: 'ShortTermFluctuationProbabilityCalculated (00240076)',
		code: 'DICOMObject["00240076"]',
	},
	{
		label: 'ShortTermFluctuationProbability (00240077)',
		code: 'DICOMObject["00240077"]',
	},
	{
		label: 'CorrectedLocalizedDeviationFromNormalCalculated (00240078)',
		code: 'DICOMObject["00240078"]',
	},
	{
		label: 'CorrectedLocalizedDeviationFromNormal (00240079)',
		code: 'DICOMObject["00240079"]',
	},
	{
		label: 'CorrectedLocalizedDeviationFromNormalProbabilityCalculated (00240080)',
		code: 'DICOMObject["00240080"]',
	},
	{
		label: 'CorrectedLocalizedDeviationFromNormalProbability (00240081)',
		code: 'DICOMObject["00240081"]',
	},
	{
		label: 'GlobalDeviationProbabilitySequence (00240083)',
		code: 'DICOMObject["00240083"]',
	},
	{
		label: 'LocalizedDeviationProbabilitySequence (00240085)',
		code: 'DICOMObject["00240085"]',
	},
	{
		label: 'FovealSensitivityMeasured (00240086)',
		code: 'DICOMObject["00240086"]',
	},
	{
		label: 'FovealSensitivity (00240087)',
		code: 'DICOMObject["00240087"]',
	},
	{
		label: 'VisualFieldTestDuration (00240088)',
		code: 'DICOMObject["00240088"]',
	},
	{
		label: 'VisualFieldTestPointSequence (00240089)',
		code: 'DICOMObject["00240089"]',
	},
	{
		label: 'VisualFieldTestPointXCoordinate (00240090)',
		code: 'DICOMObject["00240090"]',
	},
	{
		label: 'VisualFieldTestPointYCoordinate (00240091)',
		code: 'DICOMObject["00240091"]',
	},
	{
		label: 'AgeCorrectedSensitivityDeviationValue (00240092)',
		code: 'DICOMObject["00240092"]',
	},
	{
		label: 'StimulusResults (00240093)',
		code: 'DICOMObject["00240093"]',
	},
	{
		label: 'SensitivityValue (00240094)',
		code: 'DICOMObject["00240094"]',
	},
	{
		label: 'RetestStimulusSeen (00240095)',
		code: 'DICOMObject["00240095"]',
	},
	{
		label: 'RetestSensitivityValue (00240096)',
		code: 'DICOMObject["00240096"]',
	},
	{
		label: 'VisualFieldTestPointNormalsSequence (00240097)',
		code: 'DICOMObject["00240097"]',
	},
	{
		label: 'QuantifiedDefect (00240098)',
		code: 'DICOMObject["00240098"]',
	},
	{
		label: 'AgeCorrectedSensitivityDeviationProbabilityValue (00240100)',
		code: 'DICOMObject["00240100"]',
	},
	{
		label: 'GeneralizedDefectCorrectedSensitivityDeviationFlag (00240102)',
		code: 'DICOMObject["00240102"]',
	},
	{
		label: 'GeneralizedDefectCorrectedSensitivityDeviationValue (00240103)',
		code: 'DICOMObject["00240103"]',
	},
	{
		label: 'GeneralizedDefectCorrectedSensitivityDeviationProbabilityValue (00240104)',
		code: 'DICOMObject["00240104"]',
	},
	{
		label: 'MinimumSensitivityValue (00240105)',
		code: 'DICOMObject["00240105"]',
	},
	{
		label: 'BlindSpotLocalized (00240106)',
		code: 'DICOMObject["00240106"]',
	},
	{
		label: 'BlindSpotXCoordinate (00240107)',
		code: 'DICOMObject["00240107"]',
	},
	{
		label: 'BlindSpotYCoordinate (00240108)',
		code: 'DICOMObject["00240108"]',
	},
	{
		label: 'VisualAcuityMeasurementSequence (00240110)',
		code: 'DICOMObject["00240110"]',
	},
	{
		label: 'RefractiveParametersUsedOnPatientSequence (00240112)',
		code: 'DICOMObject["00240112"]',
	},
	{
		label: 'MeasurementLaterality (00240113)',
		code: 'DICOMObject["00240113"]',
	},
	{
		label: 'OphthalmicPatientClinicalInformationLeftEyeSequence (00240114)',
		code: 'DICOMObject["00240114"]',
	},
	{
		label: 'OphthalmicPatientClinicalInformationRightEyeSequence (00240115)',
		code: 'DICOMObject["00240115"]',
	},
	{
		label: 'FovealPointNormativeDataFlag (00240117)',
		code: 'DICOMObject["00240117"]',
	},
	{
		label: 'FovealPointProbabilityValue (00240118)',
		code: 'DICOMObject["00240118"]',
	},
	{
		label: 'ScreeningBaselineMeasured (00240120)',
		code: 'DICOMObject["00240120"]',
	},
	{
		label: 'ScreeningBaselineMeasuredSequence (00240122)',
		code: 'DICOMObject["00240122"]',
	},
	{
		label: 'ScreeningBaselineType (00240124)',
		code: 'DICOMObject["00240124"]',
	},
	{
		label: 'ScreeningBaselineValue (00240126)',
		code: 'DICOMObject["00240126"]',
	},
	{
		label: 'AlgorithmSource (00240202)',
		code: 'DICOMObject["00240202"]',
	},
	{
		label: 'DataSetName (00240306)',
		code: 'DICOMObject["00240306"]',
	},
	{
		label: 'DataSetVersion (00240307)',
		code: 'DICOMObject["00240307"]',
	},
	{
		label: 'DataSetSource (00240308)',
		code: 'DICOMObject["00240308"]',
	},
	{
		label: 'DataSetDescription (00240309)',
		code: 'DICOMObject["00240309"]',
	},
	{
		label: 'VisualFieldTestReliabilityGlobalIndexSequence (00240317)',
		code: 'DICOMObject["00240317"]',
	},
	{
		label: 'VisualFieldGlobalResultsIndexSequence (00240320)',
		code: 'DICOMObject["00240320"]',
	},
	{
		label: 'DataObservationSequence (00240325)',
		code: 'DICOMObject["00240325"]',
	},
	{
		label: 'IndexNormalsFlag (00240338)',
		code: 'DICOMObject["00240338"]',
	},
	{
		label: 'IndexProbability (00240341)',
		code: 'DICOMObject["00240341"]',
	},
	{
		label: 'IndexProbabilitySequence (00240344)',
		code: 'DICOMObject["00240344"]',
	},
	{
		label: 'SamplesPerPixel (00280002)',
		code: 'DICOMObject["00280002"]',
	},
	{
		label: 'SamplesPerPixelUsed (00280003)',
		code: 'DICOMObject["00280003"]',
	},
	{
		label: 'PhotometricInterpretation (00280004)',
		code: 'DICOMObject["00280004"]',
	},
	{
		label: 'PlanarConfiguration (00280006)',
		code: 'DICOMObject["00280006"]',
	},
	{
		label: 'NumberOfFrames (00280008)',
		code: 'DICOMObject["00280008"]',
	},
	{
		label: 'FrameIncrementPointer (00280009)',
		code: 'DICOMObject["00280009"]',
	},
	{
		label: 'FrameDimensionPointer (0028000A)',
		code: 'DICOMObject["0028000A"]',
	},
	{
		label: 'Rows (00280010)',
		code: 'DICOMObject["00280010"]',
	},
	{
		label: 'Columns (00280011)',
		code: 'DICOMObject["00280011"]',
	},
	{
		label: 'UltrasoundColorDataPresent (00280014)',
		code: 'DICOMObject["00280014"]',
	},
	{
		label: 'PixelSpacing (00280030)',
		code: 'DICOMObject["00280030"]',
	},
	{
		label: 'ZoomFactor (00280031)',
		code: 'DICOMObject["00280031"]',
	},
	{
		label: 'ZoomCenter (00280032)',
		code: 'DICOMObject["00280032"]',
	},
	{
		label: 'PixelAspectRatio (00280034)',
		code: 'DICOMObject["00280034"]',
	},
	{
		label: 'CorrectedImage (00280051)',
		code: 'DICOMObject["00280051"]',
	},
	{
		label: 'BitsAllocated (00280100)',
		code: 'DICOMObject["00280100"]',
	},
	{
		label: 'BitsStored (00280101)',
		code: 'DICOMObject["00280101"]',
	},
	{
		label: 'HighBit (00280102)',
		code: 'DICOMObject["00280102"]',
	},
	{
		label: 'PixelRepresentation (00280103)',
		code: 'DICOMObject["00280103"]',
	},
	{
		label: 'SmallestImagePixelValue (00280106)',
		code: 'DICOMObject["00280106"]',
	},
	{
		label: 'LargestImagePixelValue (00280107)',
		code: 'DICOMObject["00280107"]',
	},
	{
		label: 'SmallestPixelValueInSeries (00280108)',
		code: 'DICOMObject["00280108"]',
	},
	{
		label: 'LargestPixelValueInSeries (00280109)',
		code: 'DICOMObject["00280109"]',
	},
	{
		label: 'PixelPaddingValue (00280120)',
		code: 'DICOMObject["00280120"]',
	},
	{
		label: 'PixelPaddingRangeLimit (00280121)',
		code: 'DICOMObject["00280121"]',
	},
	{
		label: 'FloatPixelPaddingValue (00280122)',
		code: 'DICOMObject["00280122"]',
	},
	{
		label: 'DoubleFloatPixelPaddingValue (00280123)',
		code: 'DICOMObject["00280123"]',
	},
	{
		label: 'FloatPixelPaddingRangeLimit (00280124)',
		code: 'DICOMObject["00280124"]',
	},
	{
		label: 'DoubleFloatPixelPaddingRangeLimit (00280125)',
		code: 'DICOMObject["00280125"]',
	},
	{
		label: 'QualityControlImage (00280300)',
		code: 'DICOMObject["00280300"]',
	},
	{
		label: 'BurnedInAnnotation (00280301)',
		code: 'DICOMObject["00280301"]',
	},
	{
		label: 'RecognizableVisualFeatures (00280302)',
		code: 'DICOMObject["00280302"]',
	},
	{
		label: 'LongitudinalTemporalInformationModified (00280303)',
		code: 'DICOMObject["00280303"]',
	},
	{
		label: 'ReferencedColorPaletteInstanceUID (00280304)',
		code: 'DICOMObject["00280304"]',
	},
	{
		label: 'PixelSpacingCalibrationType (00280A02)',
		code: 'DICOMObject["00280A02"]',
	},
	{
		label: 'PixelSpacingCalibrationDescription (00280A04)',
		code: 'DICOMObject["00280A04"]',
	},
	{
		label: 'PixelIntensityRelationship (00281040)',
		code: 'DICOMObject["00281040"]',
	},
	{
		label: 'PixelIntensityRelationshipSign (00281041)',
		code: 'DICOMObject["00281041"]',
	},
	{
		label: 'WindowCenter (00281050)',
		code: 'DICOMObject["00281050"]',
	},
	{
		label: 'WindowWidth (00281051)',
		code: 'DICOMObject["00281051"]',
	},
	{
		label: 'RescaleIntercept (00281052)',
		code: 'DICOMObject["00281052"]',
	},
	{
		label: 'RescaleSlope (00281053)',
		code: 'DICOMObject["00281053"]',
	},
	{
		label: 'RescaleType (00281054)',
		code: 'DICOMObject["00281054"]',
	},
	{
		label: 'WindowCenterWidthExplanation (00281055)',
		code: 'DICOMObject["00281055"]',
	},
	{
		label: 'VOILUTFunction (00281056)',
		code: 'DICOMObject["00281056"]',
	},
	{
		label: 'RecommendedViewingMode (00281090)',
		code: 'DICOMObject["00281090"]',
	},
	{
		label: 'RedPaletteColorLookupTableDescriptor (00281101)',
		code: 'DICOMObject["00281101"]',
	},
	{
		label: 'GreenPaletteColorLookupTableDescriptor (00281102)',
		code: 'DICOMObject["00281102"]',
	},
	{
		label: 'BluePaletteColorLookupTableDescriptor (00281103)',
		code: 'DICOMObject["00281103"]',
	},
	{
		label: 'AlphaPaletteColorLookupTableDescriptor (00281104)',
		code: 'DICOMObject["00281104"]',
	},
	{
		label: 'PaletteColorLookupTableUID (00281199)',
		code: 'DICOMObject["00281199"]',
	},
	{
		label: 'RedPaletteColorLookupTableData (00281201)',
		code: 'DICOMObject["00281201"]',
	},
	{
		label: 'GreenPaletteColorLookupTableData (00281202)',
		code: 'DICOMObject["00281202"]',
	},
	{
		label: 'BluePaletteColorLookupTableData (00281203)',
		code: 'DICOMObject["00281203"]',
	},
	{
		label: 'AlphaPaletteColorLookupTableData (00281204)',
		code: 'DICOMObject["00281204"]',
	},
	{
		label: 'SegmentedRedPaletteColorLookupTableData (00281221)',
		code: 'DICOMObject["00281221"]',
	},
	{
		label: 'SegmentedGreenPaletteColorLookupTableData (00281222)',
		code: 'DICOMObject["00281222"]',
	},
	{
		label: 'SegmentedBluePaletteColorLookupTableData (00281223)',
		code: 'DICOMObject["00281223"]',
	},
	{
		label: 'SegmentedAlphaPaletteColorLookupTableData (00281224)',
		code: 'DICOMObject["00281224"]',
	},
	{
		label: 'StoredValueColorRangeSequence (00281230)',
		code: 'DICOMObject["00281230"]',
	},
	{
		label: 'MinimumStoredValueMapped (00281231)',
		code: 'DICOMObject["00281231"]',
	},
	{
		label: 'MaximumStoredValueMapped (00281232)',
		code: 'DICOMObject["00281232"]',
	},
	{
		label: 'BreastImplantPresent (00281300)',
		code: 'DICOMObject["00281300"]',
	},
	{
		label: 'PartialView (00281350)',
		code: 'DICOMObject["00281350"]',
	},
	{
		label: 'PartialViewDescription (00281351)',
		code: 'DICOMObject["00281351"]',
	},
	{
		label: 'PartialViewCodeSequence (00281352)',
		code: 'DICOMObject["00281352"]',
	},
	{
		label: 'SpatialLocationsPreserved (0028135A)',
		code: 'DICOMObject["0028135A"]',
	},
	{
		label: 'DataFrameAssignmentSequence (00281401)',
		code: 'DICOMObject["00281401"]',
	},
	{
		label: 'DataPathAssignment (00281402)',
		code: 'DICOMObject["00281402"]',
	},
	{
		label: 'BitsMappedToColorLookupTable (00281403)',
		code: 'DICOMObject["00281403"]',
	},
	{
		label: 'BlendingLUT1Sequence (00281404)',
		code: 'DICOMObject["00281404"]',
	},
	{
		label: 'BlendingLUT1TransferFunction (00281405)',
		code: 'DICOMObject["00281405"]',
	},
	{
		label: 'BlendingWeightConstant (00281406)',
		code: 'DICOMObject["00281406"]',
	},
	{
		label: 'BlendingLookupTableDescriptor (00281407)',
		code: 'DICOMObject["00281407"]',
	},
	{
		label: 'BlendingLookupTableData (00281408)',
		code: 'DICOMObject["00281408"]',
	},
	{
		label: 'EnhancedPaletteColorLookupTableSequence (0028140B)',
		code: 'DICOMObject["0028140B"]',
	},
	{
		label: 'BlendingLUT2Sequence (0028140C)',
		code: 'DICOMObject["0028140C"]',
	},
	{
		label: 'BlendingLUT2TransferFunction (0028140D)',
		code: 'DICOMObject["0028140D"]',
	},
	{
		label: 'DataPathID (0028140E)',
		code: 'DICOMObject["0028140E"]',
	},
	{
		label: 'RGBLUTTransferFunction (0028140F)',
		code: 'DICOMObject["0028140F"]',
	},
	{
		label: 'AlphaLUTTransferFunction (00281410)',
		code: 'DICOMObject["00281410"]',
	},
	{
		label: 'ICCProfile (00282000)',
		code: 'DICOMObject["00282000"]',
	},
	{
		label: 'ColorSpace (00282002)',
		code: 'DICOMObject["00282002"]',
	},
	{
		label: 'LossyImageCompression (00282110)',
		code: 'DICOMObject["00282110"]',
	},
	{
		label: 'LossyImageCompressionRatio (00282112)',
		code: 'DICOMObject["00282112"]',
	},
	{
		label: 'LossyImageCompressionMethod (00282114)',
		code: 'DICOMObject["00282114"]',
	},
	{
		label: 'ModalityLUTSequence (00283000)',
		code: 'DICOMObject["00283000"]',
	},
	{
		label: 'LUTDescriptor (00283002)',
		code: 'DICOMObject["00283002"]',
	},
	{
		label: 'LUTExplanation (00283003)',
		code: 'DICOMObject["00283003"]',
	},
	{
		label: 'ModalityLUTType (00283004)',
		code: 'DICOMObject["00283004"]',
	},
	{
		label: 'LUTData (00283006)',
		code: 'DICOMObject["00283006"]',
	},
	{
		label: 'VOILUTSequence (00283010)',
		code: 'DICOMObject["00283010"]',
	},
	{
		label: 'SoftcopyVOILUTSequence (00283110)',
		code: 'DICOMObject["00283110"]',
	},
	{
		label: 'RepresentativeFrameNumber (00286010)',
		code: 'DICOMObject["00286010"]',
	},
	{
		label: 'FrameNumbersOfInterest (00286020)',
		code: 'DICOMObject["00286020"]',
	},
	{
		label: 'FrameOfInterestDescription (00286022)',
		code: 'DICOMObject["00286022"]',
	},
	{
		label: 'FrameOfInterestType (00286023)',
		code: 'DICOMObject["00286023"]',
	},
	{
		label: 'RWavePointer (00286040)',
		code: 'DICOMObject["00286040"]',
	},
	{
		label: 'MaskSubtractionSequence (00286100)',
		code: 'DICOMObject["00286100"]',
	},
	{
		label: 'MaskOperation (00286101)',
		code: 'DICOMObject["00286101"]',
	},
	{
		label: 'ApplicableFrameRange (00286102)',
		code: 'DICOMObject["00286102"]',
	},
	{
		label: 'MaskFrameNumbers (00286110)',
		code: 'DICOMObject["00286110"]',
	},
	{
		label: 'ContrastFrameAveraging (00286112)',
		code: 'DICOMObject["00286112"]',
	},
	{
		label: 'MaskSubPixelShift (00286114)',
		code: 'DICOMObject["00286114"]',
	},
	{
		label: 'TIDOffset (00286120)',
		code: 'DICOMObject["00286120"]',
	},
	{
		label: 'MaskOperationExplanation (00286190)',
		code: 'DICOMObject["00286190"]',
	},
	{
		label: 'EquipmentAdministratorSequence (00287000)',
		code: 'DICOMObject["00287000"]',
	},
	{
		label: 'NumberOfDisplaySubsystems (00287001)',
		code: 'DICOMObject["00287001"]',
	},
	{
		label: 'CurrentConfigurationID (00287002)',
		code: 'DICOMObject["00287002"]',
	},
	{
		label: 'DisplaySubsystemID (00287003)',
		code: 'DICOMObject["00287003"]',
	},
	{
		label: 'DisplaySubsystemName (00287004)',
		code: 'DICOMObject["00287004"]',
	},
	{
		label: 'DisplaySubsystemDescription (00287005)',
		code: 'DICOMObject["00287005"]',
	},
	{
		label: 'SystemStatus (00287006)',
		code: 'DICOMObject["00287006"]',
	},
	{
		label: 'SystemStatusComment (00287007)',
		code: 'DICOMObject["00287007"]',
	},
	{
		label: 'TargetLuminanceCharacteristicsSequence (00287008)',
		code: 'DICOMObject["00287008"]',
	},
	{
		label: 'LuminanceCharacteristicsID (00287009)',
		code: 'DICOMObject["00287009"]',
	},
	{
		label: 'DisplaySubsystemConfigurationSequence (0028700A)',
		code: 'DICOMObject["0028700A"]',
	},
	{
		label: 'ConfigurationID (0028700B)',
		code: 'DICOMObject["0028700B"]',
	},
	{
		label: 'ConfigurationName (0028700C)',
		code: 'DICOMObject["0028700C"]',
	},
	{
		label: 'ConfigurationDescription (0028700D)',
		code: 'DICOMObject["0028700D"]',
	},
	{
		label: 'ReferencedTargetLuminanceCharacteristicsID (0028700E)',
		code: 'DICOMObject["0028700E"]',
	},
	{
		label: 'QAResultsSequence (0028700F)',
		code: 'DICOMObject["0028700F"]',
	},
	{
		label: 'DisplaySubsystemQAResultsSequence (00287010)',
		code: 'DICOMObject["00287010"]',
	},
	{
		label: 'ConfigurationQAResultsSequence (00287011)',
		code: 'DICOMObject["00287011"]',
	},
	{
		label: 'MeasurementEquipmentSequence (00287012)',
		code: 'DICOMObject["00287012"]',
	},
	{
		label: 'MeasurementFunctions (00287013)',
		code: 'DICOMObject["00287013"]',
	},
	{
		label: 'MeasurementEquipmentType (00287014)',
		code: 'DICOMObject["00287014"]',
	},
	{
		label: 'VisualEvaluationResultSequence (00287015)',
		code: 'DICOMObject["00287015"]',
	},
	{
		label: 'DisplayCalibrationResultSequence (00287016)',
		code: 'DICOMObject["00287016"]',
	},
	{
		label: 'DDLValue (00287017)',
		code: 'DICOMObject["00287017"]',
	},
	{
		label: 'CIExyWhitePoint (00287018)',
		code: 'DICOMObject["00287018"]',
	},
	{
		label: 'DisplayFunctionType (00287019)',
		code: 'DICOMObject["00287019"]',
	},
	{
		label: 'GammaValue (0028701A)',
		code: 'DICOMObject["0028701A"]',
	},
	{
		label: 'NumberOfLuminancePoints (0028701B)',
		code: 'DICOMObject["0028701B"]',
	},
	{
		label: 'LuminanceResponseSequence (0028701C)',
		code: 'DICOMObject["0028701C"]',
	},
	{
		label: 'TargetMinimumLuminance (0028701D)',
		code: 'DICOMObject["0028701D"]',
	},
	{
		label: 'TargetMaximumLuminance (0028701E)',
		code: 'DICOMObject["0028701E"]',
	},
	{
		label: 'LuminanceValue (0028701F)',
		code: 'DICOMObject["0028701F"]',
	},
	{
		label: 'LuminanceResponseDescription (00287020)',
		code: 'DICOMObject["00287020"]',
	},
	{
		label: 'WhitePointFlag (00287021)',
		code: 'DICOMObject["00287021"]',
	},
	{
		label: 'DisplayDeviceTypeCodeSequence (00287022)',
		code: 'DICOMObject["00287022"]',
	},
	{
		label: 'DisplaySubsystemSequence (00287023)',
		code: 'DICOMObject["00287023"]',
	},
	{
		label: 'LuminanceResultSequence (00287024)',
		code: 'DICOMObject["00287024"]',
	},
	{
		label: 'AmbientLightValueSource (00287025)',
		code: 'DICOMObject["00287025"]',
	},
	{
		label: 'MeasuredCharacteristics (00287026)',
		code: 'DICOMObject["00287026"]',
	},
	{
		label: 'LuminanceUniformityResultSequence (00287027)',
		code: 'DICOMObject["00287027"]',
	},
	{
		label: 'VisualEvaluationTestSequence (00287028)',
		code: 'DICOMObject["00287028"]',
	},
	{
		label: 'TestResult (00287029)',
		code: 'DICOMObject["00287029"]',
	},
	{
		label: 'TestResultComment (0028702A)',
		code: 'DICOMObject["0028702A"]',
	},
	{
		label: 'TestImageValidation (0028702B)',
		code: 'DICOMObject["0028702B"]',
	},
	{
		label: 'TestPatternCodeSequence (0028702C)',
		code: 'DICOMObject["0028702C"]',
	},
	{
		label: 'MeasurementPatternCodeSequence (0028702D)',
		code: 'DICOMObject["0028702D"]',
	},
	{
		label: 'VisualEvaluationMethodCodeSequence (0028702E)',
		code: 'DICOMObject["0028702E"]',
	},
	{
		label: 'PixelDataProviderURL (00287FE0)',
		code: 'DICOMObject["00287FE0"]',
	},
	{
		label: 'DataPointRows (00289001)',
		code: 'DICOMObject["00289001"]',
	},
	{
		label: 'DataPointColumns (00289002)',
		code: 'DICOMObject["00289002"]',
	},
	{
		label: 'SignalDomainColumns (00289003)',
		code: 'DICOMObject["00289003"]',
	},
	{
		label: 'DataRepresentation (00289108)',
		code: 'DICOMObject["00289108"]',
	},
	{
		label: 'PixelMeasuresSequence (00289110)',
		code: 'DICOMObject["00289110"]',
	},
	{
		label: 'FrameVOILUTSequence (00289132)',
		code: 'DICOMObject["00289132"]',
	},
	{
		label: 'PixelValueTransformationSequence (00289145)',
		code: 'DICOMObject["00289145"]',
	},
	{
		label: 'SignalDomainRows (00289235)',
		code: 'DICOMObject["00289235"]',
	},
	{
		label: 'DisplayFilterPercentage (00289411)',
		code: 'DICOMObject["00289411"]',
	},
	{
		label: 'FramePixelShiftSequence (00289415)',
		code: 'DICOMObject["00289415"]',
	},
	{
		label: 'SubtractionItemID (00289416)',
		code: 'DICOMObject["00289416"]',
	},
	{
		label: 'PixelIntensityRelationshipLUTSequence (00289422)',
		code: 'DICOMObject["00289422"]',
	},
	{
		label: 'FramePixelDataPropertiesSequence (00289443)',
		code: 'DICOMObject["00289443"]',
	},
	{
		label: 'GeometricalProperties (00289444)',
		code: 'DICOMObject["00289444"]',
	},
	{
		label: 'GeometricMaximumDistortion (00289445)',
		code: 'DICOMObject["00289445"]',
	},
	{
		label: 'ImageProcessingApplied (00289446)',
		code: 'DICOMObject["00289446"]',
	},
	{
		label: 'MaskSelectionMode (00289454)',
		code: 'DICOMObject["00289454"]',
	},
	{
		label: 'LUTFunction (00289474)',
		code: 'DICOMObject["00289474"]',
	},
	{
		label: 'MaskVisibilityPercentage (00289478)',
		code: 'DICOMObject["00289478"]',
	},
	{
		label: 'PixelShiftSequence (00289501)',
		code: 'DICOMObject["00289501"]',
	},
	{
		label: 'RegionPixelShiftSequence (00289502)',
		code: 'DICOMObject["00289502"]',
	},
	{
		label: 'VerticesOfTheRegion (00289503)',
		code: 'DICOMObject["00289503"]',
	},
	{
		label: 'MultiFramePresentationSequence (00289505)',
		code: 'DICOMObject["00289505"]',
	},
	{
		label: 'PixelShiftFrameRange (00289506)',
		code: 'DICOMObject["00289506"]',
	},
	{
		label: 'LUTFrameRange (00289507)',
		code: 'DICOMObject["00289507"]',
	},
	{
		label: 'ImageToEquipmentMappingMatrix (00289520)',
		code: 'DICOMObject["00289520"]',
	},
	{
		label: 'EquipmentCoordinateSystemIdentification (00289537)',
		code: 'DICOMObject["00289537"]',
	},
	{
		label: 'RequestingPhysicianIdentificationSequence (00321031)',
		code: 'DICOMObject["00321031"]',
	},
	{
		label: 'RequestingPhysician (00321032)',
		code: 'DICOMObject["00321032"]',
	},
	{
		label: 'RequestingService (00321033)',
		code: 'DICOMObject["00321033"]',
	},
	{
		label: 'RequestingServiceCodeSequence (00321034)',
		code: 'DICOMObject["00321034"]',
	},
	{
		label: 'RequestedProcedureDescription (00321060)',
		code: 'DICOMObject["00321060"]',
	},
	{
		label: 'RequestedProcedureCodeSequence (00321064)',
		code: 'DICOMObject["00321064"]',
	},
	{
		label: 'RequestedContrastAgent (00321070)',
		code: 'DICOMObject["00321070"]',
	},
	{
		label: 'ReferencedPatientAliasSequence (00380004)',
		code: 'DICOMObject["00380004"]',
	},
	{
		label: 'VisitStatusID (00380008)',
		code: 'DICOMObject["00380008"]',
	},
	{
		label: 'AdmissionID (00380010)',
		code: 'DICOMObject["00380010"]',
	},
	{
		label: 'IssuerOfAdmissionIDSequence (00380014)',
		code: 'DICOMObject["00380014"]',
	},
	{
		label: 'RouteOfAdmissions (00380016)',
		code: 'DICOMObject["00380016"]',
	},
	{
		label: 'AdmittingDate (00380020)',
		code: 'DICOMObject["00380020"]',
	},
	{
		label: 'AdmittingTime (00380021)',
		code: 'DICOMObject["00380021"]',
	},
	{
		label: 'SpecialNeeds (00380050)',
		code: 'DICOMObject["00380050"]',
	},
	{
		label: 'ServiceEpisodeID (00380060)',
		code: 'DICOMObject["00380060"]',
	},
	{
		label: 'ServiceEpisodeDescription (00380062)',
		code: 'DICOMObject["00380062"]',
	},
	{
		label: 'IssuerOfServiceEpisodeIDSequence (00380064)',
		code: 'DICOMObject["00380064"]',
	},
	{
		label: 'PertinentDocumentsSequence (00380100)',
		code: 'DICOMObject["00380100"]',
	},
	{
		label: 'PertinentResourcesSequence (00380101)',
		code: 'DICOMObject["00380101"]',
	},
	{
		label: 'ResourceDescription (00380102)',
		code: 'DICOMObject["00380102"]',
	},
	{
		label: 'CurrentPatientLocation (00380300)',
		code: 'DICOMObject["00380300"]',
	},
	{
		label: 'PatientInstitutionResidence (00380400)',
		code: 'DICOMObject["00380400"]',
	},
	{
		label: 'PatientState (00380500)',
		code: 'DICOMObject["00380500"]',
	},
	{
		label: 'PatientClinicalTrialParticipationSequence (00380502)',
		code: 'DICOMObject["00380502"]',
	},
	{
		label: 'VisitComments (00384000)',
		code: 'DICOMObject["00384000"]',
	},
	{
		label: 'WaveformOriginality (003A0004)',
		code: 'DICOMObject["003A0004"]',
	},
	{
		label: 'NumberOfWaveformChannels (003A0005)',
		code: 'DICOMObject["003A0005"]',
	},
	{
		label: 'NumberOfWaveformSamples (003A0010)',
		code: 'DICOMObject["003A0010"]',
	},
	{
		label: 'SamplingFrequency (003A001A)',
		code: 'DICOMObject["003A001A"]',
	},
	{
		label: 'MultiplexGroupLabel (003A0020)',
		code: 'DICOMObject["003A0020"]',
	},
	{
		label: 'ChannelDefinitionSequence (003A0200)',
		code: 'DICOMObject["003A0200"]',
	},
	{
		label: 'WaveformChannelNumber (003A0202)',
		code: 'DICOMObject["003A0202"]',
	},
	{
		label: 'ChannelLabel (003A0203)',
		code: 'DICOMObject["003A0203"]',
	},
	{
		label: 'ChannelStatus (003A0205)',
		code: 'DICOMObject["003A0205"]',
	},
	{
		label: 'ChannelSourceSequence (003A0208)',
		code: 'DICOMObject["003A0208"]',
	},
	{
		label: 'ChannelSourceModifiersSequence (003A0209)',
		code: 'DICOMObject["003A0209"]',
	},
	{
		label: 'SourceWaveformSequence (003A020A)',
		code: 'DICOMObject["003A020A"]',
	},
	{
		label: 'ChannelDerivationDescription (003A020C)',
		code: 'DICOMObject["003A020C"]',
	},
	{
		label: 'ChannelSensitivity (003A0210)',
		code: 'DICOMObject["003A0210"]',
	},
	{
		label: 'ChannelSensitivityUnitsSequence (003A0211)',
		code: 'DICOMObject["003A0211"]',
	},
	{
		label: 'ChannelSensitivityCorrectionFactor (003A0212)',
		code: 'DICOMObject["003A0212"]',
	},
	{
		label: 'ChannelBaseline (003A0213)',
		code: 'DICOMObject["003A0213"]',
	},
	{
		label: 'ChannelTimeSkew (003A0214)',
		code: 'DICOMObject["003A0214"]',
	},
	{
		label: 'ChannelSampleSkew (003A0215)',
		code: 'DICOMObject["003A0215"]',
	},
	{
		label: 'ChannelOffset (003A0218)',
		code: 'DICOMObject["003A0218"]',
	},
	{
		label: 'WaveformBitsStored (003A021A)',
		code: 'DICOMObject["003A021A"]',
	},
	{
		label: 'FilterLowFrequency (003A0220)',
		code: 'DICOMObject["003A0220"]',
	},
	{
		label: 'FilterHighFrequency (003A0221)',
		code: 'DICOMObject["003A0221"]',
	},
	{
		label: 'NotchFilterFrequency (003A0222)',
		code: 'DICOMObject["003A0222"]',
	},
	{
		label: 'NotchFilterBandwidth (003A0223)',
		code: 'DICOMObject["003A0223"]',
	},
	{
		label: 'WaveformDataDisplayScale (003A0230)',
		code: 'DICOMObject["003A0230"]',
	},
	{
		label: 'WaveformDisplayBackgroundCIELabValue (003A0231)',
		code: 'DICOMObject["003A0231"]',
	},
	{
		label: 'WaveformPresentationGroupSequence (003A0240)',
		code: 'DICOMObject["003A0240"]',
	},
	{
		label: 'PresentationGroupNumber (003A0241)',
		code: 'DICOMObject["003A0241"]',
	},
	{
		label: 'ChannelDisplaySequence (003A0242)',
		code: 'DICOMObject["003A0242"]',
	},
	{
		label: 'ChannelRecommendedDisplayCIELabValue (003A0244)',
		code: 'DICOMObject["003A0244"]',
	},
	{
		label: 'ChannelPosition (003A0245)',
		code: 'DICOMObject["003A0245"]',
	},
	{
		label: 'DisplayShadingFlag (003A0246)',
		code: 'DICOMObject["003A0246"]',
	},
	{
		label: 'FractionalChannelDisplayScale (003A0247)',
		code: 'DICOMObject["003A0247"]',
	},
	{
		label: 'AbsoluteChannelDisplayScale (003A0248)',
		code: 'DICOMObject["003A0248"]',
	},
	{
		label: 'MultiplexedAudioChannelsDescriptionCodeSequence (003A0300)',
		code: 'DICOMObject["003A0300"]',
	},
	{
		label: 'ChannelIdentificationCode (003A0301)',
		code: 'DICOMObject["003A0301"]',
	},
	{
		label: 'ChannelMode (003A0302)',
		code: 'DICOMObject["003A0302"]',
	},
	{
		label: 'ScheduledStationAETitle (00400001)',
		code: 'DICOMObject["00400001"]',
	},
	{
		label: 'ScheduledProcedureStepStartDate (00400002)',
		code: 'DICOMObject["00400002"]',
	},
	{
		label: 'ScheduledProcedureStepStartTime (00400003)',
		code: 'DICOMObject["00400003"]',
	},
	{
		label: 'ScheduledProcedureStepEndDate (00400004)',
		code: 'DICOMObject["00400004"]',
	},
	{
		label: 'ScheduledProcedureStepEndTime (00400005)',
		code: 'DICOMObject["00400005"]',
	},
	{
		label: 'ScheduledPerformingPhysicianName (00400006)',
		code: 'DICOMObject["00400006"]',
	},
	{
		label: 'ScheduledProcedureStepDescription (00400007)',
		code: 'DICOMObject["00400007"]',
	},
	{
		label: 'ScheduledProtocolCodeSequence (00400008)',
		code: 'DICOMObject["00400008"]',
	},
	{
		label: 'ScheduledProcedureStepID (00400009)',
		code: 'DICOMObject["00400009"]',
	},
	{
		label: 'StageCodeSequence (0040000A)',
		code: 'DICOMObject["0040000A"]',
	},
	{
		label: 'ScheduledPerformingPhysicianIdentificationSequence (0040000B)',
		code: 'DICOMObject["0040000B"]',
	},
	{
		label: 'ScheduledStationName (00400010)',
		code: 'DICOMObject["00400010"]',
	},
	{
		label: 'ScheduledProcedureStepLocation (00400011)',
		code: 'DICOMObject["00400011"]',
	},
	{
		label: 'PreMedication (00400012)',
		code: 'DICOMObject["00400012"]',
	},
	{
		label: 'ScheduledProcedureStepStatus (00400020)',
		code: 'DICOMObject["00400020"]',
	},
	{
		label: 'OrderPlacerIdentifierSequence (00400026)',
		code: 'DICOMObject["00400026"]',
	},
	{
		label: 'OrderFillerIdentifierSequence (00400027)',
		code: 'DICOMObject["00400027"]',
	},
	{
		label: 'LocalNamespaceEntityID (00400031)',
		code: 'DICOMObject["00400031"]',
	},
	{
		label: 'UniversalEntityID (00400032)',
		code: 'DICOMObject["00400032"]',
	},
	{
		label: 'UniversalEntityIDType (00400033)',
		code: 'DICOMObject["00400033"]',
	},
	{
		label: 'IdentifierTypeCode (00400035)',
		code: 'DICOMObject["00400035"]',
	},
	{
		label: 'AssigningFacilitySequence (00400036)',
		code: 'DICOMObject["00400036"]',
	},
	{
		label: 'AssigningJurisdictionCodeSequence (00400039)',
		code: 'DICOMObject["00400039"]',
	},
	{
		label: 'AssigningAgencyOrDepartmentCodeSequence (0040003A)',
		code: 'DICOMObject["0040003A"]',
	},
	{
		label: 'ScheduledProcedureStepSequence (00400100)',
		code: 'DICOMObject["00400100"]',
	},
	{
		label: 'ReferencedNonImageCompositeSOPInstanceSequence (00400220)',
		code: 'DICOMObject["00400220"]',
	},
	{
		label: 'PerformedStationAETitle (00400241)',
		code: 'DICOMObject["00400241"]',
	},
	{
		label: 'PerformedStationName (00400242)',
		code: 'DICOMObject["00400242"]',
	},
	{
		label: 'PerformedLocation (00400243)',
		code: 'DICOMObject["00400243"]',
	},
	{
		label: 'PerformedProcedureStepStartDate (00400244)',
		code: 'DICOMObject["00400244"]',
	},
	{
		label: 'PerformedProcedureStepStartTime (00400245)',
		code: 'DICOMObject["00400245"]',
	},
	{
		label: 'PerformedProcedureStepEndDate (00400250)',
		code: 'DICOMObject["00400250"]',
	},
	{
		label: 'PerformedProcedureStepEndTime (00400251)',
		code: 'DICOMObject["00400251"]',
	},
	{
		label: 'PerformedProcedureStepStatus (00400252)',
		code: 'DICOMObject["00400252"]',
	},
	{
		label: 'PerformedProcedureStepID (00400253)',
		code: 'DICOMObject["00400253"]',
	},
	{
		label: 'PerformedProcedureStepDescription (00400254)',
		code: 'DICOMObject["00400254"]',
	},
	{
		label: 'PerformedProcedureTypeDescription (00400255)',
		code: 'DICOMObject["00400255"]',
	},
	{
		label: 'PerformedProtocolCodeSequence (00400260)',
		code: 'DICOMObject["00400260"]',
	},
	{
		label: 'PerformedProtocolType (00400261)',
		code: 'DICOMObject["00400261"]',
	},
	{
		label: 'ScheduledStepAttributesSequence (00400270)',
		code: 'DICOMObject["00400270"]',
	},
	{
		label: 'RequestAttributesSequence (00400275)',
		code: 'DICOMObject["00400275"]',
	},
	{
		label: 'CommentsOnThePerformedProcedureStep (00400280)',
		code: 'DICOMObject["00400280"]',
	},
	{
		label: 'PerformedProcedureStepDiscontinuationReasonCodeSequence (00400281)',
		code: 'DICOMObject["00400281"]',
	},
	{
		label: 'QuantitySequence (00400293)',
		code: 'DICOMObject["00400293"]',
	},
	{
		label: 'Quantity (00400294)',
		code: 'DICOMObject["00400294"]',
	},
	{
		label: 'MeasuringUnitsSequence (00400295)',
		code: 'DICOMObject["00400295"]',
	},
	{
		label: 'BillingItemSequence (00400296)',
		code: 'DICOMObject["00400296"]',
	},
	{
		label: 'TotalTimeOfFluoroscopy (00400300)',
		code: 'DICOMObject["00400300"]',
	},
	{
		label: 'TotalNumberOfExposures (00400301)',
		code: 'DICOMObject["00400301"]',
	},
	{
		label: 'EntranceDose (00400302)',
		code: 'DICOMObject["00400302"]',
	},
	{
		label: 'ExposedArea (00400303)',
		code: 'DICOMObject["00400303"]',
	},
	{
		label: 'DistanceSourceToEntrance (00400306)',
		code: 'DICOMObject["00400306"]',
	},
	{
		label: 'ExposureDoseSequence (0040030E)',
		code: 'DICOMObject["0040030E"]',
	},
	{
		label: 'CommentsOnRadiationDose (00400310)',
		code: 'DICOMObject["00400310"]',
	},
	{
		label: 'XRayOutput (00400312)',
		code: 'DICOMObject["00400312"]',
	},
	{
		label: 'HalfValueLayer (00400314)',
		code: 'DICOMObject["00400314"]',
	},
	{
		label: 'OrganDose (00400316)',
		code: 'DICOMObject["00400316"]',
	},
	{
		label: 'OrganExposed (00400318)',
		code: 'DICOMObject["00400318"]',
	},
	{
		label: 'BillingProcedureStepSequence (00400320)',
		code: 'DICOMObject["00400320"]',
	},
	{
		label: 'FilmConsumptionSequence (00400321)',
		code: 'DICOMObject["00400321"]',
	},
	{
		label: 'BillingSuppliesAndDevicesSequence (00400324)',
		code: 'DICOMObject["00400324"]',
	},
	{
		label: 'PerformedSeriesSequence (00400340)',
		code: 'DICOMObject["00400340"]',
	},
	{
		label: 'CommentsOnTheScheduledProcedureStep (00400400)',
		code: 'DICOMObject["00400400"]',
	},
	{
		label: 'ProtocolContextSequence (00400440)',
		code: 'DICOMObject["00400440"]',
	},
	{
		label: 'ContentItemModifierSequence (00400441)',
		code: 'DICOMObject["00400441"]',
	},
	{
		label: 'ScheduledSpecimenSequence (00400500)',
		code: 'DICOMObject["00400500"]',
	},
	{
		label: 'ContainerIdentifier (00400512)',
		code: 'DICOMObject["00400512"]',
	},
	{
		label: 'IssuerOfTheContainerIdentifierSequence (00400513)',
		code: 'DICOMObject["00400513"]',
	},
	{
		label: 'AlternateContainerIdentifierSequence (00400515)',
		code: 'DICOMObject["00400515"]',
	},
	{
		label: 'ContainerTypeCodeSequence (00400518)',
		code: 'DICOMObject["00400518"]',
	},
	{
		label: 'ContainerDescription (0040051A)',
		code: 'DICOMObject["0040051A"]',
	},
	{
		label: 'ContainerComponentSequence (00400520)',
		code: 'DICOMObject["00400520"]',
	},
	{
		label: 'SpecimenIdentifier (00400551)',
		code: 'DICOMObject["00400551"]',
	},
	{
		label: 'SpecimenUID (00400554)',
		code: 'DICOMObject["00400554"]',
	},
	{
		label: 'AcquisitionContextSequence (00400555)',
		code: 'DICOMObject["00400555"]',
	},
	{
		label: 'AcquisitionContextDescription (00400556)',
		code: 'DICOMObject["00400556"]',
	},
	{
		label: 'SpecimenDescriptionSequence (00400560)',
		code: 'DICOMObject["00400560"]',
	},
	{
		label: 'IssuerOfTheSpecimenIdentifierSequence (00400562)',
		code: 'DICOMObject["00400562"]',
	},
	{
		label: 'SpecimenTypeCodeSequence (0040059A)',
		code: 'DICOMObject["0040059A"]',
	},
	{
		label: 'SpecimenShortDescription (00400600)',
		code: 'DICOMObject["00400600"]',
	},
	{
		label: 'SpecimenDetailedDescription (00400602)',
		code: 'DICOMObject["00400602"]',
	},
	{
		label: 'SpecimenPreparationSequence (00400610)',
		code: 'DICOMObject["00400610"]',
	},
	{
		label: 'SpecimenPreparationStepContentItemSequence (00400612)',
		code: 'DICOMObject["00400612"]',
	},
	{
		label: 'SpecimenLocalizationContentItemSequence (00400620)',
		code: 'DICOMObject["00400620"]',
	},
	{
		label: 'ImageCenterPointCoordinatesSequence (0040071A)',
		code: 'DICOMObject["0040071A"]',
	},
	{
		label: 'XOffsetInSlideCoordinateSystem (0040072A)',
		code: 'DICOMObject["0040072A"]',
	},
	{
		label: 'YOffsetInSlideCoordinateSystem (0040073A)',
		code: 'DICOMObject["0040073A"]',
	},
	{
		label: 'ZOffsetInSlideCoordinateSystem (0040074A)',
		code: 'DICOMObject["0040074A"]',
	},
	{
		label: 'MeasurementUnitsCodeSequence (004008EA)',
		code: 'DICOMObject["004008EA"]',
	},
	{
		label: 'RequestedProcedureID (00401001)',
		code: 'DICOMObject["00401001"]',
	},
	{
		label: 'ReasonForTheRequestedProcedure (00401002)',
		code: 'DICOMObject["00401002"]',
	},
	{
		label: 'RequestedProcedurePriority (00401003)',
		code: 'DICOMObject["00401003"]',
	},
	{
		label: 'PatientTransportArrangements (00401004)',
		code: 'DICOMObject["00401004"]',
	},
	{
		label: 'RequestedProcedureLocation (00401005)',
		code: 'DICOMObject["00401005"]',
	},
	{
		label: 'ConfidentialityCode (00401008)',
		code: 'DICOMObject["00401008"]',
	},
	{
		label: 'ReportingPriority (00401009)',
		code: 'DICOMObject["00401009"]',
	},
	{
		label: 'ReasonForRequestedProcedureCodeSequence (0040100A)',
		code: 'DICOMObject["0040100A"]',
	},
	{
		label: 'NamesOfIntendedRecipientsOfResults (00401010)',
		code: 'DICOMObject["00401010"]',
	},
	{
		label: 'IntendedRecipientsOfResultsIdentificationSequence (00401011)',
		code: 'DICOMObject["00401011"]',
	},
	{
		label: 'ReasonForPerformedProcedureCodeSequence (00401012)',
		code: 'DICOMObject["00401012"]',
	},
	{
		label: 'PersonIdentificationCodeSequence (00401101)',
		code: 'DICOMObject["00401101"]',
	},
	{
		label: 'PersonAddress (00401102)',
		code: 'DICOMObject["00401102"]',
	},
	{
		label: 'PersonTelephoneNumbers (00401103)',
		code: 'DICOMObject["00401103"]',
	},
	{
		label: 'PersonTelecomInformation (00401104)',
		code: 'DICOMObject["00401104"]',
	},
	{
		label: 'RequestedProcedureComments (00401400)',
		code: 'DICOMObject["00401400"]',
	},
	{
		label: 'IssueDateOfImagingServiceRequest (00402004)',
		code: 'DICOMObject["00402004"]',
	},
	{
		label: 'IssueTimeOfImagingServiceRequest (00402005)',
		code: 'DICOMObject["00402005"]',
	},
	{
		label: 'OrderEnteredBy (00402008)',
		code: 'DICOMObject["00402008"]',
	},
	{
		label: 'OrderEntererLocation (00402009)',
		code: 'DICOMObject["00402009"]',
	},
	{
		label: 'OrderCallbackPhoneNumber (00402010)',
		code: 'DICOMObject["00402010"]',
	},
	{
		label: 'OrderCallbackTelecomInformation (00402011)',
		code: 'DICOMObject["00402011"]',
	},
	{
		label: 'PlacerOrderNumberImagingServiceRequest (00402016)',
		code: 'DICOMObject["00402016"]',
	},
	{
		label: 'FillerOrderNumberImagingServiceRequest (00402017)',
		code: 'DICOMObject["00402017"]',
	},
	{
		label: 'ImagingServiceRequestComments (00402400)',
		code: 'DICOMObject["00402400"]',
	},
	{
		label: 'ConfidentialityConstraintOnPatientDataDescription (00403001)',
		code: 'DICOMObject["00403001"]',
	},
	{
		label: 'ScheduledProcedureStepStartDateTime (00404005)',
		code: 'DICOMObject["00404005"]',
	},
	{
		label: 'PerformedProcessingApplicationsCodeSequence (00404007)',
		code: 'DICOMObject["00404007"]',
	},
	{
		label: 'HumanPerformerCodeSequence (00404009)',
		code: 'DICOMObject["00404009"]',
	},
	{
		label: 'ScheduledProcedureStepModificationDateTime (00404010)',
		code: 'DICOMObject["00404010"]',
	},
	{
		label: 'ExpectedCompletionDateTime (00404011)',
		code: 'DICOMObject["00404011"]',
	},
	{
		label: 'ScheduledWorkitemCodeSequence (00404018)',
		code: 'DICOMObject["00404018"]',
	},
	{
		label: 'PerformedWorkitemCodeSequence (00404019)',
		code: 'DICOMObject["00404019"]',
	},
	{
		label: 'InputAvailabilityFlag (00404020)',
		code: 'DICOMObject["00404020"]',
	},
	{
		label: 'InputInformationSequence (00404021)',
		code: 'DICOMObject["00404021"]',
	},
	{
		label: 'ScheduledStationNameCodeSequence (00404025)',
		code: 'DICOMObject["00404025"]',
	},
	{
		label: 'ScheduledStationClassCodeSequence (00404026)',
		code: 'DICOMObject["00404026"]',
	},
	{
		label: 'ScheduledStationGeographicLocationCodeSequence (00404027)',
		code: 'DICOMObject["00404027"]',
	},
	{
		label: 'PerformedStationNameCodeSequence (00404028)',
		code: 'DICOMObject["00404028"]',
	},
	{
		label: 'PerformedStationClassCodeSequence (00404029)',
		code: 'DICOMObject["00404029"]',
	},
	{
		label: 'PerformedStationGeographicLocationCodeSequence (00404030)',
		code: 'DICOMObject["00404030"]',
	},
	{
		label: 'OutputInformationSequence (00404033)',
		code: 'DICOMObject["00404033"]',
	},
	{
		label: 'ScheduledHumanPerformersSequence (00404034)',
		code: 'DICOMObject["00404034"]',
	},
	{
		label: 'ActualHumanPerformersSequence (00404035)',
		code: 'DICOMObject["00404035"]',
	},
	{
		label: 'HumanPerformerOrganization (00404036)',
		code: 'DICOMObject["00404036"]',
	},
	{
		label: 'HumanPerformerName (00404037)',
		code: 'DICOMObject["00404037"]',
	},
	{
		label: 'RawDataHandling (00404040)',
		code: 'DICOMObject["00404040"]',
	},
	{
		label: 'InputReadinessState (00404041)',
		code: 'DICOMObject["00404041"]',
	},
	{
		label: 'PerformedProcedureStepStartDateTime (00404050)',
		code: 'DICOMObject["00404050"]',
	},
	{
		label: 'PerformedProcedureStepEndDateTime (00404051)',
		code: 'DICOMObject["00404051"]',
	},
	{
		label: 'ProcedureStepCancellationDateTime (00404052)',
		code: 'DICOMObject["00404052"]',
	},
	{
		label: 'OutputDestinationSequence (00404070)',
		code: 'DICOMObject["00404070"]',
	},
	{
		label: 'DICOMStorageSequence (00404071)',
		code: 'DICOMObject["00404071"]',
	},
	{
		label: 'STOWRSStorageSequence (00404072)',
		code: 'DICOMObject["00404072"]',
	},
	{
		label: 'StorageURL (00404073)',
		code: 'DICOMObject["00404073"]',
	},
	{
		label: 'XDSStorageSequence (00404074)',
		code: 'DICOMObject["00404074"]',
	},
	{
		label: 'EntranceDoseInmGy (00408302)',
		code: 'DICOMObject["00408302"]',
	},
	{
		label: 'EntranceDoseDerivation (00408303)',
		code: 'DICOMObject["00408303"]',
	},
	{
		label: 'ParametricMapFrameTypeSequence (00409092)',
		code: 'DICOMObject["00409092"]',
	},
	{
		label: 'ReferencedImageRealWorldValueMappingSequence (00409094)',
		code: 'DICOMObject["00409094"]',
	},
	{
		label: 'RealWorldValueMappingSequence (00409096)',
		code: 'DICOMObject["00409096"]',
	},
	{
		label: 'PixelValueMappingCodeSequence (00409098)',
		code: 'DICOMObject["00409098"]',
	},
	{
		label: 'LUTLabel (00409210)',
		code: 'DICOMObject["00409210"]',
	},
	{
		label: 'RealWorldValueLastValueMapped (00409211)',
		code: 'DICOMObject["00409211"]',
	},
	{
		label: 'RealWorldValueLUTData (00409212)',
		code: 'DICOMObject["00409212"]',
	},
	{
		label: 'DoubleFloatRealWorldValueLastValueMapped (00409213)',
		code: 'DICOMObject["00409213"]',
	},
	{
		label: 'DoubleFloatRealWorldValueFirstValueMapped (00409214)',
		code: 'DICOMObject["00409214"]',
	},
	{
		label: 'RealWorldValueFirstValueMapped (00409216)',
		code: 'DICOMObject["00409216"]',
	},
	{
		label: 'QuantityDefinitionSequence (00409220)',
		code: 'DICOMObject["00409220"]',
	},
	{
		label: 'RealWorldValueIntercept (00409224)',
		code: 'DICOMObject["00409224"]',
	},
	{
		label: 'RealWorldValueSlope (00409225)',
		code: 'DICOMObject["00409225"]',
	},
	{
		label: 'RelationshipType (0040A010)',
		code: 'DICOMObject["0040A010"]',
	},
	{
		label: 'VerifyingOrganization (0040A027)',
		code: 'DICOMObject["0040A027"]',
	},
	{
		label: 'VerificationDateTime (0040A030)',
		code: 'DICOMObject["0040A030"]',
	},
	{
		label: 'ObservationDateTime (0040A032)',
		code: 'DICOMObject["0040A032"]',
	},
	{
		label: 'ValueType (0040A040)',
		code: 'DICOMObject["0040A040"]',
	},
	{
		label: 'ConceptNameCodeSequence (0040A043)',
		code: 'DICOMObject["0040A043"]',
	},
	{
		label: 'ContinuityOfContent (0040A050)',
		code: 'DICOMObject["0040A050"]',
	},
	{
		label: 'VerifyingObserverSequence (0040A073)',
		code: 'DICOMObject["0040A073"]',
	},
	{
		label: 'VerifyingObserverName (0040A075)',
		code: 'DICOMObject["0040A075"]',
	},
	{
		label: 'AuthorObserverSequence (0040A078)',
		code: 'DICOMObject["0040A078"]',
	},
	{
		label: 'ParticipantSequence (0040A07A)',
		code: 'DICOMObject["0040A07A"]',
	},
	{
		label: 'CustodialOrganizationSequence (0040A07C)',
		code: 'DICOMObject["0040A07C"]',
	},
	{
		label: 'ParticipationType (0040A080)',
		code: 'DICOMObject["0040A080"]',
	},
	{
		label: 'ParticipationDateTime (0040A082)',
		code: 'DICOMObject["0040A082"]',
	},
	{
		label: 'ObserverType (0040A084)',
		code: 'DICOMObject["0040A084"]',
	},
	{
		label: 'VerifyingObserverIdentificationCodeSequence (0040A088)',
		code: 'DICOMObject["0040A088"]',
	},
	{
		label: 'ReferencedWaveformChannels (0040A0B0)',
		code: 'DICOMObject["0040A0B0"]',
	},
	{
		label: 'DateTime (0040A120)',
		code: 'DICOMObject["0040A120"]',
	},
	{
		label: 'Date (0040A121)',
		code: 'DICOMObject["0040A121"]',
	},
	{
		label: 'Time (0040A122)',
		code: 'DICOMObject["0040A122"]',
	},
	{
		label: 'PersonName (0040A123)',
		code: 'DICOMObject["0040A123"]',
	},
	{
		label: 'UID (0040A124)',
		code: 'DICOMObject["0040A124"]',
	},
	{
		label: 'TemporalRangeType (0040A130)',
		code: 'DICOMObject["0040A130"]',
	},
	{
		label: 'ReferencedSamplePositions (0040A132)',
		code: 'DICOMObject["0040A132"]',
	},
	{
		label: 'ReferencedFrameNumbers (0040A136)',
		code: 'DICOMObject["0040A136"]',
	},
	{
		label: 'ReferencedTimeOffsets (0040A138)',
		code: 'DICOMObject["0040A138"]',
	},
	{
		label: 'ReferencedDateTime (0040A13A)',
		code: 'DICOMObject["0040A13A"]',
	},
	{
		label: 'TextValue (0040A160)',
		code: 'DICOMObject["0040A160"]',
	},
	{
		label: 'FloatingPointValue (0040A161)',
		code: 'DICOMObject["0040A161"]',
	},
	{
		label: 'RationalNumeratorValue (0040A162)',
		code: 'DICOMObject["0040A162"]',
	},
	{
		label: 'RationalDenominatorValue (0040A163)',
		code: 'DICOMObject["0040A163"]',
	},
	{
		label: 'ConceptCodeSequence (0040A168)',
		code: 'DICOMObject["0040A168"]',
	},
	{
		label: 'PurposeOfReferenceCodeSequence (0040A170)',
		code: 'DICOMObject["0040A170"]',
	},
	{
		label: 'ObservationUID (0040A171)',
		code: 'DICOMObject["0040A171"]',
	},
	{
		label: 'AnnotationGroupNumber (0040A180)',
		code: 'DICOMObject["0040A180"]',
	},
	{
		label: 'ModifierCodeSequence (0040A195)',
		code: 'DICOMObject["0040A195"]',
	},
	{
		label: 'MeasuredValueSequence (0040A300)',
		code: 'DICOMObject["0040A300"]',
	},
	{
		label: 'NumericValueQualifierCodeSequence (0040A301)',
		code: 'DICOMObject["0040A301"]',
	},
	{
		label: 'NumericValue (0040A30A)',
		code: 'DICOMObject["0040A30A"]',
	},
	{
		label: 'PredecessorDocumentsSequence (0040A360)',
		code: 'DICOMObject["0040A360"]',
	},
	{
		label: 'ReferencedRequestSequence (0040A370)',
		code: 'DICOMObject["0040A370"]',
	},
	{
		label: 'PerformedProcedureCodeSequence (0040A372)',
		code: 'DICOMObject["0040A372"]',
	},
	{
		label: 'CurrentRequestedProcedureEvidenceSequence (0040A375)',
		code: 'DICOMObject["0040A375"]',
	},
	{
		label: 'PertinentOtherEvidenceSequence (0040A385)',
		code: 'DICOMObject["0040A385"]',
	},
	{
		label: 'HL7StructuredDocumentReferenceSequence (0040A390)',
		code: 'DICOMObject["0040A390"]',
	},
	{
		label: 'CompletionFlag (0040A491)',
		code: 'DICOMObject["0040A491"]',
	},
	{
		label: 'CompletionFlagDescription (0040A492)',
		code: 'DICOMObject["0040A492"]',
	},
	{
		label: 'VerificationFlag (0040A493)',
		code: 'DICOMObject["0040A493"]',
	},
	{
		label: 'ArchiveRequested (0040A494)',
		code: 'DICOMObject["0040A494"]',
	},
	{
		label: 'PreliminaryFlag (0040A496)',
		code: 'DICOMObject["0040A496"]',
	},
	{
		label: 'ContentTemplateSequence (0040A504)',
		code: 'DICOMObject["0040A504"]',
	},
	{
		label: 'IdenticalDocumentsSequence (0040A525)',
		code: 'DICOMObject["0040A525"]',
	},
	{
		label: 'ContentSequence (0040A730)',
		code: 'DICOMObject["0040A730"]',
	},
	{
		label: 'WaveformAnnotationSequence (0040B020)',
		code: 'DICOMObject["0040B020"]',
	},
	{
		label: 'TemplateIdentifier (0040DB00)',
		code: 'DICOMObject["0040DB00"]',
	},
	{
		label: 'ReferencedContentItemIdentifier (0040DB73)',
		code: 'DICOMObject["0040DB73"]',
	},
	{
		label: 'HL7InstanceIdentifier (0040E001)',
		code: 'DICOMObject["0040E001"]',
	},
	{
		label: 'HL7DocumentEffectiveTime (0040E004)',
		code: 'DICOMObject["0040E004"]',
	},
	{
		label: 'HL7DocumentTypeCodeSequence (0040E006)',
		code: 'DICOMObject["0040E006"]',
	},
	{
		label: 'DocumentClassCodeSequence (0040E008)',
		code: 'DICOMObject["0040E008"]',
	},
	{
		label: 'RetrieveURI (0040E010)',
		code: 'DICOMObject["0040E010"]',
	},
	{
		label: 'RetrieveLocationUID (0040E011)',
		code: 'DICOMObject["0040E011"]',
	},
	{
		label: 'TypeOfInstances (0040E020)',
		code: 'DICOMObject["0040E020"]',
	},
	{
		label: 'DICOMRetrievalSequence (0040E021)',
		code: 'DICOMObject["0040E021"]',
	},
	{
		label: 'DICOMMediaRetrievalSequence (0040E022)',
		code: 'DICOMObject["0040E022"]',
	},
	{
		label: 'WADORetrievalSequence (0040E023)',
		code: 'DICOMObject["0040E023"]',
	},
	{
		label: 'XDSRetrievalSequence (0040E024)',
		code: 'DICOMObject["0040E024"]',
	},
	{
		label: 'WADORSRetrievalSequence (0040E025)',
		code: 'DICOMObject["0040E025"]',
	},
	{
		label: 'RepositoryUniqueID (0040E030)',
		code: 'DICOMObject["0040E030"]',
	},
	{
		label: 'HomeCommunityID (0040E031)',
		code: 'DICOMObject["0040E031"]',
	},
	{
		label: 'DocumentTitle (00420010)',
		code: 'DICOMObject["00420010"]',
	},
	{
		label: 'EncapsulatedDocument (00420011)',
		code: 'DICOMObject["00420011"]',
	},
	{
		label: 'MIMETypeOfEncapsulatedDocument (00420012)',
		code: 'DICOMObject["00420012"]',
	},
	{
		label: 'SourceInstanceSequence (00420013)',
		code: 'DICOMObject["00420013"]',
	},
	{
		label: 'ListOfMIMETypes (00420014)',
		code: 'DICOMObject["00420014"]',
	},
	{
		label: 'ProductPackageIdentifier (00440001)',
		code: 'DICOMObject["00440001"]',
	},
	{
		label: 'SubstanceAdministrationApproval (00440002)',
		code: 'DICOMObject["00440002"]',
	},
	{
		label: 'ApprovalStatusFurtherDescription (00440003)',
		code: 'DICOMObject["00440003"]',
	},
	{
		label: 'ApprovalStatusDateTime (00440004)',
		code: 'DICOMObject["00440004"]',
	},
	{
		label: 'ProductTypeCodeSequence (00440007)',
		code: 'DICOMObject["00440007"]',
	},
	{
		label: 'ProductName (00440008)',
		code: 'DICOMObject["00440008"]',
	},
	{
		label: 'ProductDescription (00440009)',
		code: 'DICOMObject["00440009"]',
	},
	{
		label: 'ProductLotIdentifier (0044000A)',
		code: 'DICOMObject["0044000A"]',
	},
	{
		label: 'ProductExpirationDateTime (0044000B)',
		code: 'DICOMObject["0044000B"]',
	},
	{
		label: 'SubstanceAdministrationDateTime (00440010)',
		code: 'DICOMObject["00440010"]',
	},
	{
		label: 'SubstanceAdministrationNotes (00440011)',
		code: 'DICOMObject["00440011"]',
	},
	{
		label: 'SubstanceAdministrationDeviceID (00440012)',
		code: 'DICOMObject["00440012"]',
	},
	{
		label: 'ProductParameterSequence (00440013)',
		code: 'DICOMObject["00440013"]',
	},
	{
		label: 'SubstanceAdministrationParameterSequence (00440019)',
		code: 'DICOMObject["00440019"]',
	},
	{
		label: 'LensDescription (00460012)',
		code: 'DICOMObject["00460012"]',
	},
	{
		label: 'RightLensSequence (00460014)',
		code: 'DICOMObject["00460014"]',
	},
	{
		label: 'LeftLensSequence (00460015)',
		code: 'DICOMObject["00460015"]',
	},
	{
		label: 'UnspecifiedLateralityLensSequence (00460016)',
		code: 'DICOMObject["00460016"]',
	},
	{
		label: 'CylinderSequence (00460018)',
		code: 'DICOMObject["00460018"]',
	},
	{
		label: 'PrismSequence (00460028)',
		code: 'DICOMObject["00460028"]',
	},
	{
		label: 'HorizontalPrismPower (00460030)',
		code: 'DICOMObject["00460030"]',
	},
	{
		label: 'HorizontalPrismBase (00460032)',
		code: 'DICOMObject["00460032"]',
	},
	{
		label: 'VerticalPrismPower (00460034)',
		code: 'DICOMObject["00460034"]',
	},
	{
		label: 'VerticalPrismBase (00460036)',
		code: 'DICOMObject["00460036"]',
	},
	{
		label: 'LensSegmentType (00460038)',
		code: 'DICOMObject["00460038"]',
	},
	{
		label: 'OpticalTransmittance (00460040)',
		code: 'DICOMObject["00460040"]',
	},
	{
		label: 'ChannelWidth (00460042)',
		code: 'DICOMObject["00460042"]',
	},
	{
		label: 'PupilSize (00460044)',
		code: 'DICOMObject["00460044"]',
	},
	{
		label: 'CornealSize (00460046)',
		code: 'DICOMObject["00460046"]',
	},
	{
		label: 'AutorefractionRightEyeSequence (00460050)',
		code: 'DICOMObject["00460050"]',
	},
	{
		label: 'AutorefractionLeftEyeSequence (00460052)',
		code: 'DICOMObject["00460052"]',
	},
	{
		label: 'DistancePupillaryDistance (00460060)',
		code: 'DICOMObject["00460060"]',
	},
	{
		label: 'NearPupillaryDistance (00460062)',
		code: 'DICOMObject["00460062"]',
	},
	{
		label: 'IntermediatePupillaryDistance (00460063)',
		code: 'DICOMObject["00460063"]',
	},
	{
		label: 'OtherPupillaryDistance (00460064)',
		code: 'DICOMObject["00460064"]',
	},
	{
		label: 'KeratometryRightEyeSequence (00460070)',
		code: 'DICOMObject["00460070"]',
	},
	{
		label: 'KeratometryLeftEyeSequence (00460071)',
		code: 'DICOMObject["00460071"]',
	},
	{
		label: 'SteepKeratometricAxisSequence (00460074)',
		code: 'DICOMObject["00460074"]',
	},
	{
		label: 'RadiusOfCurvature (00460075)',
		code: 'DICOMObject["00460075"]',
	},
	{
		label: 'KeratometricPower (00460076)',
		code: 'DICOMObject["00460076"]',
	},
	{
		label: 'KeratometricAxis (00460077)',
		code: 'DICOMObject["00460077"]',
	},
	{
		label: 'FlatKeratometricAxisSequence (00460080)',
		code: 'DICOMObject["00460080"]',
	},
	{
		label: 'BackgroundColor (00460092)',
		code: 'DICOMObject["00460092"]',
	},
	{
		label: 'Optotype (00460094)',
		code: 'DICOMObject["00460094"]',
	},
	{
		label: 'OptotypePresentation (00460095)',
		code: 'DICOMObject["00460095"]',
	},
	{
		label: 'SubjectiveRefractionRightEyeSequence (00460097)',
		code: 'DICOMObject["00460097"]',
	},
	{
		label: 'SubjectiveRefractionLeftEyeSequence (00460098)',
		code: 'DICOMObject["00460098"]',
	},
	{
		label: 'AddNearSequence (00460100)',
		code: 'DICOMObject["00460100"]',
	},
	{
		label: 'AddIntermediateSequence (00460101)',
		code: 'DICOMObject["00460101"]',
	},
	{
		label: 'AddOtherSequence (00460102)',
		code: 'DICOMObject["00460102"]',
	},
	{
		label: 'AddPower (00460104)',
		code: 'DICOMObject["00460104"]',
	},
	{
		label: 'ViewingDistance (00460106)',
		code: 'DICOMObject["00460106"]',
	},
	{
		label: 'VisualAcuityTypeCodeSequence (00460121)',
		code: 'DICOMObject["00460121"]',
	},
	{
		label: 'VisualAcuityRightEyeSequence (00460122)',
		code: 'DICOMObject["00460122"]',
	},
	{
		label: 'VisualAcuityLeftEyeSequence (00460123)',
		code: 'DICOMObject["00460123"]',
	},
	{
		label: 'VisualAcuityBothEyesOpenSequence (00460124)',
		code: 'DICOMObject["00460124"]',
	},
	{
		label: 'ViewingDistanceType (00460125)',
		code: 'DICOMObject["00460125"]',
	},
	{
		label: 'VisualAcuityModifiers (00460135)',
		code: 'DICOMObject["00460135"]',
	},
	{
		label: 'DecimalVisualAcuity (00460137)',
		code: 'DICOMObject["00460137"]',
	},
	{
		label: 'OptotypeDetailedDefinition (00460139)',
		code: 'DICOMObject["00460139"]',
	},
	{
		label: 'ReferencedRefractiveMeasurementsSequence (00460145)',
		code: 'DICOMObject["00460145"]',
	},
	{
		label: 'SpherePower (00460146)',
		code: 'DICOMObject["00460146"]',
	},
	{
		label: 'CylinderPower (00460147)',
		code: 'DICOMObject["00460147"]',
	},
	{
		label: 'CornealTopographySurface (00460201)',
		code: 'DICOMObject["00460201"]',
	},
	{
		label: 'CornealVertexLocation (00460202)',
		code: 'DICOMObject["00460202"]',
	},
	{
		label: 'PupilCentroidXCoordinate (00460203)',
		code: 'DICOMObject["00460203"]',
	},
	{
		label: 'PupilCentroidYCoordinate (00460204)',
		code: 'DICOMObject["00460204"]',
	},
	{
		label: 'EquivalentPupilRadius (00460205)',
		code: 'DICOMObject["00460205"]',
	},
	{
		label: 'CornealTopographyMapTypeCodeSequence (00460207)',
		code: 'DICOMObject["00460207"]',
	},
	{
		label: 'VerticesOfTheOutlineOfPupil (00460208)',
		code: 'DICOMObject["00460208"]',
	},
	{
		label: 'CornealTopographyMappingNormalsSequence (00460210)',
		code: 'DICOMObject["00460210"]',
	},
	{
		label: 'MaximumCornealCurvatureSequence (00460211)',
		code: 'DICOMObject["00460211"]',
	},
	{
		label: 'MaximumCornealCurvature (00460212)',
		code: 'DICOMObject["00460212"]',
	},
	{
		label: 'MaximumCornealCurvatureLocation (00460213)',
		code: 'DICOMObject["00460213"]',
	},
	{
		label: 'MinimumKeratometricSequence (00460215)',
		code: 'DICOMObject["00460215"]',
	},
	{
		label: 'SimulatedKeratometricCylinderSequence (00460218)',
		code: 'DICOMObject["00460218"]',
	},
	{
		label: 'AverageCornealPower (00460220)',
		code: 'DICOMObject["00460220"]',
	},
	{
		label: 'CornealISValue (00460224)',
		code: 'DICOMObject["00460224"]',
	},
	{
		label: 'AnalyzedArea (00460227)',
		code: 'DICOMObject["00460227"]',
	},
	{
		label: 'SurfaceRegularityIndex (00460230)',
		code: 'DICOMObject["00460230"]',
	},
	{
		label: 'SurfaceAsymmetryIndex (00460232)',
		code: 'DICOMObject["00460232"]',
	},
	{
		label: 'CornealEccentricityIndex (00460234)',
		code: 'DICOMObject["00460234"]',
	},
	{
		label: 'KeratoconusPredictionIndex (00460236)',
		code: 'DICOMObject["00460236"]',
	},
	{
		label: 'DecimalPotentialVisualAcuity (00460238)',
		code: 'DICOMObject["00460238"]',
	},
	{
		label: 'CornealTopographyMapQualityEvaluation (00460242)',
		code: 'DICOMObject["00460242"]',
	},
	{
		label: 'SourceImageCornealProcessedDataSequence (00460244)',
		code: 'DICOMObject["00460244"]',
	},
	{
		label: 'CornealPointLocation (00460247)',
		code: 'DICOMObject["00460247"]',
	},
	{
		label: 'CornealPointEstimated (00460248)',
		code: 'DICOMObject["00460248"]',
	},
	{
		label: 'AxialPower (00460249)',
		code: 'DICOMObject["00460249"]',
	},
	{
		label: 'TangentialPower (00460250)',
		code: 'DICOMObject["00460250"]',
	},
	{
		label: 'RefractivePower (00460251)',
		code: 'DICOMObject["00460251"]',
	},
	{
		label: 'RelativeElevation (00460252)',
		code: 'DICOMObject["00460252"]',
	},
	{
		label: 'CornealWavefront (00460253)',
		code: 'DICOMObject["00460253"]',
	},
	{
		label: 'ImagedVolumeWidth (00480001)',
		code: 'DICOMObject["00480001"]',
	},
	{
		label: 'ImagedVolumeHeight (00480002)',
		code: 'DICOMObject["00480002"]',
	},
	{
		label: 'ImagedVolumeDepth (00480003)',
		code: 'DICOMObject["00480003"]',
	},
	{
		label: 'TotalPixelMatrixColumns (00480006)',
		code: 'DICOMObject["00480006"]',
	},
	{
		label: 'TotalPixelMatrixRows (00480007)',
		code: 'DICOMObject["00480007"]',
	},
	{
		label: 'TotalPixelMatrixOriginSequence (00480008)',
		code: 'DICOMObject["00480008"]',
	},
	{
		label: 'SpecimenLabelInImage (00480010)',
		code: 'DICOMObject["00480010"]',
	},
	{
		label: 'FocusMethod (00480011)',
		code: 'DICOMObject["00480011"]',
	},
	{
		label: 'ExtendedDepthOfField (00480012)',
		code: 'DICOMObject["00480012"]',
	},
	{
		label: 'NumberOfFocalPlanes (00480013)',
		code: 'DICOMObject["00480013"]',
	},
	{
		label: 'DistanceBetweenFocalPlanes (00480014)',
		code: 'DICOMObject["00480014"]',
	},
	{
		label: 'RecommendedAbsentPixelCIELabValue (00480015)',
		code: 'DICOMObject["00480015"]',
	},
	{
		label: 'IlluminatorTypeCodeSequence (00480100)',
		code: 'DICOMObject["00480100"]',
	},
	{
		label: 'ImageOrientationSlide (00480102)',
		code: 'DICOMObject["00480102"]',
	},
	{
		label: 'OpticalPathSequence (00480105)',
		code: 'DICOMObject["00480105"]',
	},
	{
		label: 'OpticalPathIdentifier (00480106)',
		code: 'DICOMObject["00480106"]',
	},
	{
		label: 'OpticalPathDescription (00480107)',
		code: 'DICOMObject["00480107"]',
	},
	{
		label: 'IlluminationColorCodeSequence (00480108)',
		code: 'DICOMObject["00480108"]',
	},
	{
		label: 'SpecimenReferenceSequence (00480110)',
		code: 'DICOMObject["00480110"]',
	},
	{
		label: 'CondenserLensPower (00480111)',
		code: 'DICOMObject["00480111"]',
	},
	{
		label: 'ObjectiveLensPower (00480112)',
		code: 'DICOMObject["00480112"]',
	},
	{
		label: 'ObjectiveLensNumericalAperture (00480113)',
		code: 'DICOMObject["00480113"]',
	},
	{
		label: 'PaletteColorLookupTableSequence (00480120)',
		code: 'DICOMObject["00480120"]',
	},
	{
		label: 'ReferencedImageNavigationSequence (00480200)',
		code: 'DICOMObject["00480200"]',
	},
	{
		label: 'TopLeftHandCornerOfLocalizerArea (00480201)',
		code: 'DICOMObject["00480201"]',
	},
	{
		label: 'BottomRightHandCornerOfLocalizerArea (00480202)',
		code: 'DICOMObject["00480202"]',
	},
	{
		label: 'OpticalPathIdentificationSequence (00480207)',
		code: 'DICOMObject["00480207"]',
	},
	{
		label: 'PlanePositionSlideSequence (0048021A)',
		code: 'DICOMObject["0048021A"]',
	},
	{
		label: 'ColumnPositionInTotalImagePixelMatrix (0048021E)',
		code: 'DICOMObject["0048021E"]',
	},
	{
		label: 'RowPositionInTotalImagePixelMatrix (0048021F)',
		code: 'DICOMObject["0048021F"]',
	},
	{
		label: 'PixelOriginInterpretation (00480301)',
		code: 'DICOMObject["00480301"]',
	},
	{
		label: 'CalibrationImage (00500004)',
		code: 'DICOMObject["00500004"]',
	},
	{
		label: 'DeviceSequence (00500010)',
		code: 'DICOMObject["00500010"]',
	},
	{
		label: 'ContainerComponentTypeCodeSequence (00500012)',
		code: 'DICOMObject["00500012"]',
	},
	{
		label: 'ContainerComponentThickness (00500013)',
		code: 'DICOMObject["00500013"]',
	},
	{
		label: 'DeviceLength (00500014)',
		code: 'DICOMObject["00500014"]',
	},
	{
		label: 'ContainerComponentWidth (00500015)',
		code: 'DICOMObject["00500015"]',
	},
	{
		label: 'DeviceDiameter (00500016)',
		code: 'DICOMObject["00500016"]',
	},
	{
		label: 'DeviceDiameterUnits (00500017)',
		code: 'DICOMObject["00500017"]',
	},
	{
		label: 'DeviceVolume (00500018)',
		code: 'DICOMObject["00500018"]',
	},
	{
		label: 'InterMarkerDistance (00500019)',
		code: 'DICOMObject["00500019"]',
	},
	{
		label: 'ContainerComponentMaterial (0050001A)',
		code: 'DICOMObject["0050001A"]',
	},
	{
		label: 'ContainerComponentID (0050001B)',
		code: 'DICOMObject["0050001B"]',
	},
	{
		label: 'ContainerComponentLength (0050001C)',
		code: 'DICOMObject["0050001C"]',
	},
	{
		label: 'ContainerComponentDiameter (0050001D)',
		code: 'DICOMObject["0050001D"]',
	},
	{
		label: 'ContainerComponentDescription (0050001E)',
		code: 'DICOMObject["0050001E"]',
	},
	{
		label: 'DeviceDescription (00500020)',
		code: 'DICOMObject["00500020"]',
	},
	{
		label: 'ContrastBolusIngredientPercentByVolume (00520001)',
		code: 'DICOMObject["00520001"]',
	},
	{
		label: 'OCTFocalDistance (00520002)',
		code: 'DICOMObject["00520002"]',
	},
	{
		label: 'BeamSpotSize (00520003)',
		code: 'DICOMObject["00520003"]',
	},
	{
		label: 'EffectiveRefractiveIndex (00520004)',
		code: 'DICOMObject["00520004"]',
	},
	{
		label: 'OCTAcquisitionDomain (00520006)',
		code: 'DICOMObject["00520006"]',
	},
	{
		label: 'OCTOpticalCenterWavelength (00520007)',
		code: 'DICOMObject["00520007"]',
	},
	{
		label: 'AxialResolution (00520008)',
		code: 'DICOMObject["00520008"]',
	},
	{
		label: 'RangingDepth (00520009)',
		code: 'DICOMObject["00520009"]',
	},
	{
		label: 'ALineRate (00520011)',
		code: 'DICOMObject["00520011"]',
	},
	{
		label: 'ALinesPerFrame (00520012)',
		code: 'DICOMObject["00520012"]',
	},
	{
		label: 'CatheterRotationalRate (00520013)',
		code: 'DICOMObject["00520013"]',
	},
	{
		label: 'ALinePixelSpacing (00520014)',
		code: 'DICOMObject["00520014"]',
	},
	{
		label: 'ModeOfPercutaneousAccessSequence (00520016)',
		code: 'DICOMObject["00520016"]',
	},
	{
		label: 'IntravascularOCTFrameTypeSequence (00520025)',
		code: 'DICOMObject["00520025"]',
	},
	{
		label: 'OCTZOffsetApplied (00520026)',
		code: 'DICOMObject["00520026"]',
	},
	{
		label: 'IntravascularFrameContentSequence (00520027)',
		code: 'DICOMObject["00520027"]',
	},
	{
		label: 'IntravascularLongitudinalDistance (00520028)',
		code: 'DICOMObject["00520028"]',
	},
	{
		label: 'IntravascularOCTFrameContentSequence (00520029)',
		code: 'DICOMObject["00520029"]',
	},
	{
		label: 'OCTZOffsetCorrection (00520030)',
		code: 'DICOMObject["00520030"]',
	},
	{
		label: 'CatheterDirectionOfRotation (00520031)',
		code: 'DICOMObject["00520031"]',
	},
	{
		label: 'SeamLineLocation (00520033)',
		code: 'DICOMObject["00520033"]',
	},
	{
		label: 'FirstALineLocation (00520034)',
		code: 'DICOMObject["00520034"]',
	},
	{
		label: 'SeamLineIndex (00520036)',
		code: 'DICOMObject["00520036"]',
	},
	{
		label: 'NumberOfPaddedALines (00520038)',
		code: 'DICOMObject["00520038"]',
	},
	{
		label: 'InterpolationType (00520039)',
		code: 'DICOMObject["00520039"]',
	},
	{
		label: 'RefractiveIndexApplied (0052003A)',
		code: 'DICOMObject["0052003A"]',
	},
	{
		label: 'EnergyWindowVector (00540010)',
		code: 'DICOMObject["00540010"]',
	},
	{
		label: 'NumberOfEnergyWindows (00540011)',
		code: 'DICOMObject["00540011"]',
	},
	{
		label: 'EnergyWindowInformationSequence (00540012)',
		code: 'DICOMObject["00540012"]',
	},
	{
		label: 'EnergyWindowRangeSequence (00540013)',
		code: 'DICOMObject["00540013"]',
	},
	{
		label: 'EnergyWindowLowerLimit (00540014)',
		code: 'DICOMObject["00540014"]',
	},
	{
		label: 'EnergyWindowUpperLimit (00540015)',
		code: 'DICOMObject["00540015"]',
	},
	{
		label: 'RadiopharmaceuticalInformationSequence (00540016)',
		code: 'DICOMObject["00540016"]',
	},
	{
		label: 'ResidualSyringeCounts (00540017)',
		code: 'DICOMObject["00540017"]',
	},
	{
		label: 'EnergyWindowName (00540018)',
		code: 'DICOMObject["00540018"]',
	},
	{
		label: 'DetectorVector (00540020)',
		code: 'DICOMObject["00540020"]',
	},
	{
		label: 'NumberOfDetectors (00540021)',
		code: 'DICOMObject["00540021"]',
	},
	{
		label: 'DetectorInformationSequence (00540022)',
		code: 'DICOMObject["00540022"]',
	},
	{
		label: 'PhaseVector (00540030)',
		code: 'DICOMObject["00540030"]',
	},
	{
		label: 'NumberOfPhases (00540031)',
		code: 'DICOMObject["00540031"]',
	},
	{
		label: 'PhaseInformationSequence (00540032)',
		code: 'DICOMObject["00540032"]',
	},
	{
		label: 'NumberOfFramesInPhase (00540033)',
		code: 'DICOMObject["00540033"]',
	},
	{
		label: 'PhaseDelay (00540036)',
		code: 'DICOMObject["00540036"]',
	},
	{
		label: 'PauseBetweenFrames (00540038)',
		code: 'DICOMObject["00540038"]',
	},
	{
		label: 'PhaseDescription (00540039)',
		code: 'DICOMObject["00540039"]',
	},
	{
		label: 'RotationVector (00540050)',
		code: 'DICOMObject["00540050"]',
	},
	{
		label: 'NumberOfRotations (00540051)',
		code: 'DICOMObject["00540051"]',
	},
	{
		label: 'RotationInformationSequence (00540052)',
		code: 'DICOMObject["00540052"]',
	},
	{
		label: 'NumberOfFramesInRotation (00540053)',
		code: 'DICOMObject["00540053"]',
	},
	{
		label: 'RRIntervalVector (00540060)',
		code: 'DICOMObject["00540060"]',
	},
	{
		label: 'NumberOfRRIntervals (00540061)',
		code: 'DICOMObject["00540061"]',
	},
	{
		label: 'GatedInformationSequence (00540062)',
		code: 'DICOMObject["00540062"]',
	},
	{
		label: 'DataInformationSequence (00540063)',
		code: 'DICOMObject["00540063"]',
	},
	{
		label: 'TimeSlotVector (00540070)',
		code: 'DICOMObject["00540070"]',
	},
	{
		label: 'NumberOfTimeSlots (00540071)',
		code: 'DICOMObject["00540071"]',
	},
	{
		label: 'TimeSlotInformationSequence (00540072)',
		code: 'DICOMObject["00540072"]',
	},
	{
		label: 'TimeSlotTime (00540073)',
		code: 'DICOMObject["00540073"]',
	},
	{
		label: 'SliceVector (00540080)',
		code: 'DICOMObject["00540080"]',
	},
	{
		label: 'NumberOfSlices (00540081)',
		code: 'DICOMObject["00540081"]',
	},
	{
		label: 'AngularViewVector (00540090)',
		code: 'DICOMObject["00540090"]',
	},
	{
		label: 'TimeSliceVector (00540100)',
		code: 'DICOMObject["00540100"]',
	},
	{
		label: 'NumberOfTimeSlices (00540101)',
		code: 'DICOMObject["00540101"]',
	},
	{
		label: 'StartAngle (00540200)',
		code: 'DICOMObject["00540200"]',
	},
	{
		label: 'TypeOfDetectorMotion (00540202)',
		code: 'DICOMObject["00540202"]',
	},
	{
		label: 'TriggerVector (00540210)',
		code: 'DICOMObject["00540210"]',
	},
	{
		label: 'NumberOfTriggersInPhase (00540211)',
		code: 'DICOMObject["00540211"]',
	},
	{
		label: 'ViewCodeSequence (00540220)',
		code: 'DICOMObject["00540220"]',
	},
	{
		label: 'ViewModifierCodeSequence (00540222)',
		code: 'DICOMObject["00540222"]',
	},
	{
		label: 'RadionuclideCodeSequence (00540300)',
		code: 'DICOMObject["00540300"]',
	},
	{
		label: 'AdministrationRouteCodeSequence (00540302)',
		code: 'DICOMObject["00540302"]',
	},
	{
		label: 'RadiopharmaceuticalCodeSequence (00540304)',
		code: 'DICOMObject["00540304"]',
	},
	{
		label: 'CalibrationDataSequence (00540306)',
		code: 'DICOMObject["00540306"]',
	},
	{
		label: 'EnergyWindowNumber (00540308)',
		code: 'DICOMObject["00540308"]',
	},
	{
		label: 'ImageID (00540400)',
		code: 'DICOMObject["00540400"]',
	},
	{
		label: 'PatientOrientationCodeSequence (00540410)',
		code: 'DICOMObject["00540410"]',
	},
	{
		label: 'PatientOrientationModifierCodeSequence (00540412)',
		code: 'DICOMObject["00540412"]',
	},
	{
		label: 'PatientGantryRelationshipCodeSequence (00540414)',
		code: 'DICOMObject["00540414"]',
	},
	{
		label: 'SliceProgressionDirection (00540500)',
		code: 'DICOMObject["00540500"]',
	},
	{
		label: 'ScanProgressionDirection (00540501)',
		code: 'DICOMObject["00540501"]',
	},
	{
		label: 'SeriesType (00541000)',
		code: 'DICOMObject["00541000"]',
	},
	{
		label: 'Units (00541001)',
		code: 'DICOMObject["00541001"]',
	},
	{
		label: 'CountsSource (00541002)',
		code: 'DICOMObject["00541002"]',
	},
	{
		label: 'ReprojectionMethod (00541004)',
		code: 'DICOMObject["00541004"]',
	},
	{
		label: 'SUVType (00541006)',
		code: 'DICOMObject["00541006"]',
	},
	{
		label: 'RandomsCorrectionMethod (00541100)',
		code: 'DICOMObject["00541100"]',
	},
	{
		label: 'AttenuationCorrectionMethod (00541101)',
		code: 'DICOMObject["00541101"]',
	},
	{
		label: 'DecayCorrection (00541102)',
		code: 'DICOMObject["00541102"]',
	},
	{
		label: 'ReconstructionMethod (00541103)',
		code: 'DICOMObject["00541103"]',
	},
	{
		label: 'DetectorLinesOfResponseUsed (00541104)',
		code: 'DICOMObject["00541104"]',
	},
	{
		label: 'ScatterCorrectionMethod (00541105)',
		code: 'DICOMObject["00541105"]',
	},
	{
		label: 'AxialAcceptance (00541200)',
		code: 'DICOMObject["00541200"]',
	},
	{
		label: 'AxialMash (00541201)',
		code: 'DICOMObject["00541201"]',
	},
	{
		label: 'TransverseMash (00541202)',
		code: 'DICOMObject["00541202"]',
	},
	{
		label: 'DetectorElementSize (00541203)',
		code: 'DICOMObject["00541203"]',
	},
	{
		label: 'CoincidenceWindowWidth (00541210)',
		code: 'DICOMObject["00541210"]',
	},
	{
		label: 'SecondaryCountsType (00541220)',
		code: 'DICOMObject["00541220"]',
	},
	{
		label: 'FrameReferenceTime (00541300)',
		code: 'DICOMObject["00541300"]',
	},
	{
		label: 'PrimaryPromptsCountsAccumulated (00541310)',
		code: 'DICOMObject["00541310"]',
	},
	{
		label: 'SecondaryCountsAccumulated (00541311)',
		code: 'DICOMObject["00541311"]',
	},
	{
		label: 'SliceSensitivityFactor (00541320)',
		code: 'DICOMObject["00541320"]',
	},
	{
		label: 'DecayFactor (00541321)',
		code: 'DICOMObject["00541321"]',
	},
	{
		label: 'DoseCalibrationFactor (00541322)',
		code: 'DICOMObject["00541322"]',
	},
	{
		label: 'ScatterFractionFactor (00541323)',
		code: 'DICOMObject["00541323"]',
	},
	{
		label: 'DeadTimeFactor (00541324)',
		code: 'DICOMObject["00541324"]',
	},
	{
		label: 'ImageIndex (00541330)',
		code: 'DICOMObject["00541330"]',
	},
	{
		label: 'HistogramSequence (00603000)',
		code: 'DICOMObject["00603000"]',
	},
	{
		label: 'HistogramNumberOfBins (00603002)',
		code: 'DICOMObject["00603002"]',
	},
	{
		label: 'HistogramFirstBinValue (00603004)',
		code: 'DICOMObject["00603004"]',
	},
	{
		label: 'HistogramLastBinValue (00603006)',
		code: 'DICOMObject["00603006"]',
	},
	{
		label: 'HistogramBinWidth (00603008)',
		code: 'DICOMObject["00603008"]',
	},
	{
		label: 'HistogramExplanation (00603010)',
		code: 'DICOMObject["00603010"]',
	},
	{
		label: 'HistogramData (00603020)',
		code: 'DICOMObject["00603020"]',
	},
	{
		label: 'SegmentationType (00620001)',
		code: 'DICOMObject["00620001"]',
	},
	{
		label: 'SegmentSequence (00620002)',
		code: 'DICOMObject["00620002"]',
	},
	{
		label: 'SegmentedPropertyCategoryCodeSequence (00620003)',
		code: 'DICOMObject["00620003"]',
	},
	{
		label: 'SegmentNumber (00620004)',
		code: 'DICOMObject["00620004"]',
	},
	{
		label: 'SegmentLabel (00620005)',
		code: 'DICOMObject["00620005"]',
	},
	{
		label: 'SegmentDescription (00620006)',
		code: 'DICOMObject["00620006"]',
	},
	{
		label: 'SegmentationAlgorithmIdentificationSequence (00620007)',
		code: 'DICOMObject["00620007"]',
	},
	{
		label: 'SegmentAlgorithmType (00620008)',
		code: 'DICOMObject["00620008"]',
	},
	{
		label: 'SegmentAlgorithmName (00620009)',
		code: 'DICOMObject["00620009"]',
	},
	{
		label: 'SegmentIdentificationSequence (0062000A)',
		code: 'DICOMObject["0062000A"]',
	},
	{
		label: 'ReferencedSegmentNumber (0062000B)',
		code: 'DICOMObject["0062000B"]',
	},
	{
		label: 'RecommendedDisplayGrayscaleValue (0062000C)',
		code: 'DICOMObject["0062000C"]',
	},
	{
		label: 'RecommendedDisplayCIELabValue (0062000D)',
		code: 'DICOMObject["0062000D"]',
	},
	{
		label: 'MaximumFractionalValue (0062000E)',
		code: 'DICOMObject["0062000E"]',
	},
	{
		label: 'SegmentedPropertyTypeCodeSequence (0062000F)',
		code: 'DICOMObject["0062000F"]',
	},
	{
		label: 'SegmentationFractionalType (00620010)',
		code: 'DICOMObject["00620010"]',
	},
	{
		label: 'SegmentedPropertyTypeModifierCodeSequence (00620011)',
		code: 'DICOMObject["00620011"]',
	},
	{
		label: 'UsedSegmentsSequence (00620012)',
		code: 'DICOMObject["00620012"]',
	},
	{
		label: 'TrackingID (00620020)',
		code: 'DICOMObject["00620020"]',
	},
	{
		label: 'TrackingUID (00620021)',
		code: 'DICOMObject["00620021"]',
	},
	{
		label: 'DeformableRegistrationSequence (00640002)',
		code: 'DICOMObject["00640002"]',
	},
	{
		label: 'SourceFrameOfReferenceUID (00640003)',
		code: 'DICOMObject["00640003"]',
	},
	{
		label: 'DeformableRegistrationGridSequence (00640005)',
		code: 'DICOMObject["00640005"]',
	},
	{
		label: 'GridDimensions (00640007)',
		code: 'DICOMObject["00640007"]',
	},
	{
		label: 'GridResolution (00640008)',
		code: 'DICOMObject["00640008"]',
	},
	{
		label: 'VectorGridData (00640009)',
		code: 'DICOMObject["00640009"]',
	},
	{
		label: 'PreDeformationMatrixRegistrationSequence (0064000F)',
		code: 'DICOMObject["0064000F"]',
	},
	{
		label: 'PostDeformationMatrixRegistrationSequence (00640010)',
		code: 'DICOMObject["00640010"]',
	},
	{
		label: 'NumberOfSurfaces (00660001)',
		code: 'DICOMObject["00660001"]',
	},
	{
		label: 'SurfaceSequence (00660002)',
		code: 'DICOMObject["00660002"]',
	},
	{
		label: 'SurfaceNumber (00660003)',
		code: 'DICOMObject["00660003"]',
	},
	{
		label: 'SurfaceComments (00660004)',
		code: 'DICOMObject["00660004"]',
	},
	{
		label: 'SurfaceProcessing (00660009)',
		code: 'DICOMObject["00660009"]',
	},
	{
		label: 'SurfaceProcessingRatio (0066000A)',
		code: 'DICOMObject["0066000A"]',
	},
	{
		label: 'SurfaceProcessingDescription (0066000B)',
		code: 'DICOMObject["0066000B"]',
	},
	{
		label: 'RecommendedPresentationOpacity (0066000C)',
		code: 'DICOMObject["0066000C"]',
	},
	{
		label: 'RecommendedPresentationType (0066000D)',
		code: 'DICOMObject["0066000D"]',
	},
	{
		label: 'FiniteVolume (0066000E)',
		code: 'DICOMObject["0066000E"]',
	},
	{
		label: 'Manifold (00660010)',
		code: 'DICOMObject["00660010"]',
	},
	{
		label: 'SurfacePointsSequence (00660011)',
		code: 'DICOMObject["00660011"]',
	},
	{
		label: 'SurfacePointsNormalsSequence (00660012)',
		code: 'DICOMObject["00660012"]',
	},
	{
		label: 'SurfaceMeshPrimitivesSequence (00660013)',
		code: 'DICOMObject["00660013"]',
	},
	{
		label: 'NumberOfSurfacePoints (00660015)',
		code: 'DICOMObject["00660015"]',
	},
	{
		label: 'PointCoordinatesData (00660016)',
		code: 'DICOMObject["00660016"]',
	},
	{
		label: 'PointPositionAccuracy (00660017)',
		code: 'DICOMObject["00660017"]',
	},
	{
		label: 'MeanPointDistance (00660018)',
		code: 'DICOMObject["00660018"]',
	},
	{
		label: 'MaximumPointDistance (00660019)',
		code: 'DICOMObject["00660019"]',
	},
	{
		label: 'PointsBoundingBoxCoordinates (0066001A)',
		code: 'DICOMObject["0066001A"]',
	},
	{
		label: 'AxisOfRotation (0066001B)',
		code: 'DICOMObject["0066001B"]',
	},
	{
		label: 'CenterOfRotation (0066001C)',
		code: 'DICOMObject["0066001C"]',
	},
	{
		label: 'NumberOfVectors (0066001E)',
		code: 'DICOMObject["0066001E"]',
	},
	{
		label: 'VectorDimensionality (0066001F)',
		code: 'DICOMObject["0066001F"]',
	},
	{
		label: 'VectorAccuracy (00660020)',
		code: 'DICOMObject["00660020"]',
	},
	{
		label: 'VectorCoordinateData (00660021)',
		code: 'DICOMObject["00660021"]',
	},
	{
		label: 'TriangleStripSequence (00660026)',
		code: 'DICOMObject["00660026"]',
	},
	{
		label: 'TriangleFanSequence (00660027)',
		code: 'DICOMObject["00660027"]',
	},
	{
		label: 'LineSequence (00660028)',
		code: 'DICOMObject["00660028"]',
	},
	{
		label: 'SurfaceCount (0066002A)',
		code: 'DICOMObject["0066002A"]',
	},
	{
		label: 'ReferencedSurfaceSequence (0066002B)',
		code: 'DICOMObject["0066002B"]',
	},
	{
		label: 'ReferencedSurfaceNumber (0066002C)',
		code: 'DICOMObject["0066002C"]',
	},
	{
		label: 'SegmentSurfaceGenerationAlgorithmIdentificationSequence (0066002D)',
		code: 'DICOMObject["0066002D"]',
	},
	{
		label: 'SegmentSurfaceSourceInstanceSequence (0066002E)',
		code: 'DICOMObject["0066002E"]',
	},
	{
		label: 'AlgorithmFamilyCodeSequence (0066002F)',
		code: 'DICOMObject["0066002F"]',
	},
	{
		label: 'AlgorithmNameCodeSequence (00660030)',
		code: 'DICOMObject["00660030"]',
	},
	{
		label: 'AlgorithmVersion (00660031)',
		code: 'DICOMObject["00660031"]',
	},
	{
		label: 'AlgorithmParameters (00660032)',
		code: 'DICOMObject["00660032"]',
	},
	{
		label: 'FacetSequence (00660034)',
		code: 'DICOMObject["00660034"]',
	},
	{
		label: 'SurfaceProcessingAlgorithmIdentificationSequence (00660035)',
		code: 'DICOMObject["00660035"]',
	},
	{
		label: 'AlgorithmName (00660036)',
		code: 'DICOMObject["00660036"]',
	},
	{
		label: 'RecommendedPointRadius (00660037)',
		code: 'DICOMObject["00660037"]',
	},
	{
		label: 'RecommendedLineThickness (00660038)',
		code: 'DICOMObject["00660038"]',
	},
	{
		label: 'LongPrimitivePointIndexList (00660040)',
		code: 'DICOMObject["00660040"]',
	},
	{
		label: 'LongTrianglePointIndexList (00660041)',
		code: 'DICOMObject["00660041"]',
	},
	{
		label: 'LongEdgePointIndexList (00660042)',
		code: 'DICOMObject["00660042"]',
	},
	{
		label: 'LongVertexPointIndexList (00660043)',
		code: 'DICOMObject["00660043"]',
	},
	{
		label: 'TrackSetSequence (00660101)',
		code: 'DICOMObject["00660101"]',
	},
	{
		label: 'TrackSequence (00660102)',
		code: 'DICOMObject["00660102"]',
	},
	{
		label: 'RecommendedDisplayCIELabValueList (00660103)',
		code: 'DICOMObject["00660103"]',
	},
	{
		label: 'TrackingAlgorithmIdentificationSequence (00660104)',
		code: 'DICOMObject["00660104"]',
	},
	{
		label: 'TrackSetNumber (00660105)',
		code: 'DICOMObject["00660105"]',
	},
	{
		label: 'TrackSetLabel (00660106)',
		code: 'DICOMObject["00660106"]',
	},
	{
		label: 'TrackSetDescription (00660107)',
		code: 'DICOMObject["00660107"]',
	},
	{
		label: 'TrackSetAnatomicalTypeCodeSequence (00660108)',
		code: 'DICOMObject["00660108"]',
	},
	{
		label: 'MeasurementsSequence (00660121)',
		code: 'DICOMObject["00660121"]',
	},
	{
		label: 'TrackSetStatisticsSequence (00660124)',
		code: 'DICOMObject["00660124"]',
	},
	{
		label: 'FloatingPointValues (00660125)',
		code: 'DICOMObject["00660125"]',
	},
	{
		label: 'TrackPointIndexList (00660129)',
		code: 'DICOMObject["00660129"]',
	},
	{
		label: 'TrackStatisticsSequence (00660130)',
		code: 'DICOMObject["00660130"]',
	},
	{
		label: 'MeasurementValuesSequence (00660132)',
		code: 'DICOMObject["00660132"]',
	},
	{
		label: 'DiffusionAcquisitionCodeSequence (00660133)',
		code: 'DICOMObject["00660133"]',
	},
	{
		label: 'DiffusionModelCodeSequence (00660134)',
		code: 'DICOMObject["00660134"]',
	},
	{
		label: 'ImplantSize (00686210)',
		code: 'DICOMObject["00686210"]',
	},
	{
		label: 'ImplantTemplateVersion (00686221)',
		code: 'DICOMObject["00686221"]',
	},
	{
		label: 'ReplacedImplantTemplateSequence (00686222)',
		code: 'DICOMObject["00686222"]',
	},
	{
		label: 'ImplantType (00686223)',
		code: 'DICOMObject["00686223"]',
	},
	{
		label: 'DerivationImplantTemplateSequence (00686224)',
		code: 'DICOMObject["00686224"]',
	},
	{
		label: 'OriginalImplantTemplateSequence (00686225)',
		code: 'DICOMObject["00686225"]',
	},
	{
		label: 'EffectiveDateTime (00686226)',
		code: 'DICOMObject["00686226"]',
	},
	{
		label: 'ImplantTargetAnatomySequence (00686230)',
		code: 'DICOMObject["00686230"]',
	},
	{
		label: 'InformationFromManufacturerSequence (00686260)',
		code: 'DICOMObject["00686260"]',
	},
	{
		label: 'NotificationFromManufacturerSequence (00686265)',
		code: 'DICOMObject["00686265"]',
	},
	{
		label: 'InformationIssueDateTime (00686270)',
		code: 'DICOMObject["00686270"]',
	},
	{
		label: 'InformationSummary (00686280)',
		code: 'DICOMObject["00686280"]',
	},
	{
		label: 'ImplantRegulatoryDisapprovalCodeSequence (006862A0)',
		code: 'DICOMObject["006862A0"]',
	},
	{
		label: 'OverallTemplateSpatialTolerance (006862A5)',
		code: 'DICOMObject["006862A5"]',
	},
	{
		label: 'HPGLDocumentSequence (006862C0)',
		code: 'DICOMObject["006862C0"]',
	},
	{
		label: 'HPGLDocumentID (006862D0)',
		code: 'DICOMObject["006862D0"]',
	},
	{
		label: 'HPGLDocumentLabel (006862D5)',
		code: 'DICOMObject["006862D5"]',
	},
	{
		label: 'ViewOrientationCodeSequence (006862E0)',
		code: 'DICOMObject["006862E0"]',
	},
	{
		label: 'ViewOrientationModifierCodeSequence (006862F0)',
		code: 'DICOMObject["006862F0"]',
	},
	{
		label: 'HPGLDocumentScaling (006862F2)',
		code: 'DICOMObject["006862F2"]',
	},
	{
		label: 'HPGLDocument (00686300)',
		code: 'DICOMObject["00686300"]',
	},
	{
		label: 'HPGLContourPenNumber (00686310)',
		code: 'DICOMObject["00686310"]',
	},
	{
		label: 'HPGLPenSequence (00686320)',
		code: 'DICOMObject["00686320"]',
	},
	{
		label: 'HPGLPenNumber (00686330)',
		code: 'DICOMObject["00686330"]',
	},
	{
		label: 'HPGLPenLabel (00686340)',
		code: 'DICOMObject["00686340"]',
	},
	{
		label: 'HPGLPenDescription (00686345)',
		code: 'DICOMObject["00686345"]',
	},
	{
		label: 'RecommendedRotationPoint (00686346)',
		code: 'DICOMObject["00686346"]',
	},
	{
		label: 'BoundingRectangle (00686347)',
		code: 'DICOMObject["00686347"]',
	},
	{
		label: 'ImplantTemplate3DModelSurfaceNumber (00686350)',
		code: 'DICOMObject["00686350"]',
	},
	{
		label: 'SurfaceModelDescriptionSequence (00686360)',
		code: 'DICOMObject["00686360"]',
	},
	{
		label: 'SurfaceModelLabel (00686380)',
		code: 'DICOMObject["00686380"]',
	},
	{
		label: 'SurfaceModelScalingFactor (00686390)',
		code: 'DICOMObject["00686390"]',
	},
	{
		label: 'MaterialsCodeSequence (006863A0)',
		code: 'DICOMObject["006863A0"]',
	},
	{
		label: 'CoatingMaterialsCodeSequence (006863A4)',
		code: 'DICOMObject["006863A4"]',
	},
	{
		label: 'ImplantTypeCodeSequence (006863A8)',
		code: 'DICOMObject["006863A8"]',
	},
	{
		label: 'FixationMethodCodeSequence (006863AC)',
		code: 'DICOMObject["006863AC"]',
	},
	{
		label: 'MatingFeatureSetsSequence (006863B0)',
		code: 'DICOMObject["006863B0"]',
	},
	{
		label: 'MatingFeatureSetID (006863C0)',
		code: 'DICOMObject["006863C0"]',
	},
	{
		label: 'MatingFeatureSetLabel (006863D0)',
		code: 'DICOMObject["006863D0"]',
	},
	{
		label: 'MatingFeatureSequence (006863E0)',
		code: 'DICOMObject["006863E0"]',
	},
	{
		label: 'MatingFeatureID (006863F0)',
		code: 'DICOMObject["006863F0"]',
	},
	{
		label: 'MatingFeatureDegreeOfFreedomSequence (00686400)',
		code: 'DICOMObject["00686400"]',
	},
	{
		label: 'DegreeOfFreedomID (00686410)',
		code: 'DICOMObject["00686410"]',
	},
	{
		label: 'DegreeOfFreedomType (00686420)',
		code: 'DICOMObject["00686420"]',
	},
	{
		label: 'TwoDMatingFeatureCoordinatesSequence (00686430)',
		code: 'DICOMObject["00686430"]',
	},
	{
		label: 'ReferencedHPGLDocumentID (00686440)',
		code: 'DICOMObject["00686440"]',
	},
	{
		label: 'TwoDMatingPoint (00686450)',
		code: 'DICOMObject["00686450"]',
	},
	{
		label: 'TwoDMatingAxes (00686460)',
		code: 'DICOMObject["00686460"]',
	},
	{
		label: 'TwoDDegreeOfFreedomSequence (00686470)',
		code: 'DICOMObject["00686470"]',
	},
	{
		label: 'ThreeDDegreeOfFreedomAxis (00686490)',
		code: 'DICOMObject["00686490"]',
	},
	{
		label: 'RangeOfFreedom (006864A0)',
		code: 'DICOMObject["006864A0"]',
	},
	{
		label: 'ThreeDMatingPoint (006864C0)',
		code: 'DICOMObject["006864C0"]',
	},
	{
		label: 'ThreeDMatingAxes (006864D0)',
		code: 'DICOMObject["006864D0"]',
	},
	{
		label: 'TwoDDegreeOfFreedomAxis (006864F0)',
		code: 'DICOMObject["006864F0"]',
	},
	{
		label: 'PlanningLandmarkPointSequence (00686500)',
		code: 'DICOMObject["00686500"]',
	},
	{
		label: 'PlanningLandmarkLineSequence (00686510)',
		code: 'DICOMObject["00686510"]',
	},
	{
		label: 'PlanningLandmarkPlaneSequence (00686520)',
		code: 'DICOMObject["00686520"]',
	},
	{
		label: 'PlanningLandmarkID (00686530)',
		code: 'DICOMObject["00686530"]',
	},
	{
		label: 'PlanningLandmarkDescription (00686540)',
		code: 'DICOMObject["00686540"]',
	},
	{
		label: 'PlanningLandmarkIdentificationCodeSequence (00686545)',
		code: 'DICOMObject["00686545"]',
	},
	{
		label: 'TwoDPointCoordinatesSequence (00686550)',
		code: 'DICOMObject["00686550"]',
	},
	{
		label: 'TwoDPointCoordinates (00686560)',
		code: 'DICOMObject["00686560"]',
	},
	{
		label: 'ThreeDPointCoordinates (00686590)',
		code: 'DICOMObject["00686590"]',
	},
	{
		label: 'TwoDLineCoordinatesSequence (006865A0)',
		code: 'DICOMObject["006865A0"]',
	},
	{
		label: 'TwoDLineCoordinates (006865B0)',
		code: 'DICOMObject["006865B0"]',
	},
	{
		label: 'ThreeDLineCoordinates (006865D0)',
		code: 'DICOMObject["006865D0"]',
	},
	{
		label: 'TwoDPlaneCoordinatesSequence (006865E0)',
		code: 'DICOMObject["006865E0"]',
	},
	{
		label: 'TwoDPlaneIntersection (006865F0)',
		code: 'DICOMObject["006865F0"]',
	},
	{
		label: 'ThreeDPlaneOrigin (00686610)',
		code: 'DICOMObject["00686610"]',
	},
	{
		label: 'ThreeDPlaneNormal (00686620)',
		code: 'DICOMObject["00686620"]',
	},
	{
		label: 'GraphicAnnotationSequence (00700001)',
		code: 'DICOMObject["00700001"]',
	},
	{
		label: 'GraphicLayer (00700002)',
		code: 'DICOMObject["00700002"]',
	},
	{
		label: 'BoundingBoxAnnotationUnits (00700003)',
		code: 'DICOMObject["00700003"]',
	},
	{
		label: 'AnchorPointAnnotationUnits (00700004)',
		code: 'DICOMObject["00700004"]',
	},
	{
		label: 'GraphicAnnotationUnits (00700005)',
		code: 'DICOMObject["00700005"]',
	},
	{
		label: 'UnformattedTextValue (00700006)',
		code: 'DICOMObject["00700006"]',
	},
	{
		label: 'TextObjectSequence (00700008)',
		code: 'DICOMObject["00700008"]',
	},
	{
		label: 'GraphicObjectSequence (00700009)',
		code: 'DICOMObject["00700009"]',
	},
	{
		label: 'BoundingBoxTopLeftHandCorner (00700010)',
		code: 'DICOMObject["00700010"]',
	},
	{
		label: 'BoundingBoxBottomRightHandCorner (00700011)',
		code: 'DICOMObject["00700011"]',
	},
	{
		label: 'BoundingBoxTextHorizontalJustification (00700012)',
		code: 'DICOMObject["00700012"]',
	},
	{
		label: 'AnchorPoint (00700014)',
		code: 'DICOMObject["00700014"]',
	},
	{
		label: 'AnchorPointVisibility (00700015)',
		code: 'DICOMObject["00700015"]',
	},
	{
		label: 'GraphicDimensions (00700020)',
		code: 'DICOMObject["00700020"]',
	},
	{
		label: 'NumberOfGraphicPoints (00700021)',
		code: 'DICOMObject["00700021"]',
	},
	{
		label: 'GraphicData (00700022)',
		code: 'DICOMObject["00700022"]',
	},
	{
		label: 'GraphicType (00700023)',
		code: 'DICOMObject["00700023"]',
	},
	{
		label: 'GraphicFilled (00700024)',
		code: 'DICOMObject["00700024"]',
	},
	{
		label: 'ImageHorizontalFlip (00700041)',
		code: 'DICOMObject["00700041"]',
	},
	{
		label: 'ImageRotation (00700042)',
		code: 'DICOMObject["00700042"]',
	},
	{
		label: 'DisplayedAreaTopLeftHandCorner (00700052)',
		code: 'DICOMObject["00700052"]',
	},
	{
		label: 'DisplayedAreaBottomRightHandCorner (00700053)',
		code: 'DICOMObject["00700053"]',
	},
	{
		label: 'DisplayedAreaSelectionSequence (0070005A)',
		code: 'DICOMObject["0070005A"]',
	},
	{
		label: 'GraphicLayerSequence (00700060)',
		code: 'DICOMObject["00700060"]',
	},
	{
		label: 'GraphicLayerOrder (00700062)',
		code: 'DICOMObject["00700062"]',
	},
	{
		label: 'GraphicLayerRecommendedDisplayGrayscaleValue (00700066)',
		code: 'DICOMObject["00700066"]',
	},
	{
		label: 'GraphicLayerDescription (00700068)',
		code: 'DICOMObject["00700068"]',
	},
	{
		label: 'ContentLabel (00700080)',
		code: 'DICOMObject["00700080"]',
	},
	{
		label: 'ContentDescription (00700081)',
		code: 'DICOMObject["00700081"]',
	},
	{
		label: 'PresentationCreationDate (00700082)',
		code: 'DICOMObject["00700082"]',
	},
	{
		label: 'PresentationCreationTime (00700083)',
		code: 'DICOMObject["00700083"]',
	},
	{
		label: 'ContentCreatorName (00700084)',
		code: 'DICOMObject["00700084"]',
	},
	{
		label: 'ContentCreatorIdentificationCodeSequence (00700086)',
		code: 'DICOMObject["00700086"]',
	},
	{
		label: 'AlternateContentDescriptionSequence (00700087)',
		code: 'DICOMObject["00700087"]',
	},
	{
		label: 'PresentationSizeMode (00700100)',
		code: 'DICOMObject["00700100"]',
	},
	{
		label: 'PresentationPixelSpacing (00700101)',
		code: 'DICOMObject["00700101"]',
	},
	{
		label: 'PresentationPixelAspectRatio (00700102)',
		code: 'DICOMObject["00700102"]',
	},
	{
		label: 'PresentationPixelMagnificationRatio (00700103)',
		code: 'DICOMObject["00700103"]',
	},
	{
		label: 'GraphicGroupLabel (00700207)',
		code: 'DICOMObject["00700207"]',
	},
	{
		label: 'GraphicGroupDescription (00700208)',
		code: 'DICOMObject["00700208"]',
	},
	{
		label: 'CompoundGraphicSequence (00700209)',
		code: 'DICOMObject["00700209"]',
	},
	{
		label: 'CompoundGraphicInstanceID (00700226)',
		code: 'DICOMObject["00700226"]',
	},
	{
		label: 'FontName (00700227)',
		code: 'DICOMObject["00700227"]',
	},
	{
		label: 'FontNameType (00700228)',
		code: 'DICOMObject["00700228"]',
	},
	{
		label: 'CSSFontName (00700229)',
		code: 'DICOMObject["00700229"]',
	},
	{
		label: 'RotationAngle (00700230)',
		code: 'DICOMObject["00700230"]',
	},
	{
		label: 'TextStyleSequence (00700231)',
		code: 'DICOMObject["00700231"]',
	},
	{
		label: 'LineStyleSequence (00700232)',
		code: 'DICOMObject["00700232"]',
	},
	{
		label: 'FillStyleSequence (00700233)',
		code: 'DICOMObject["00700233"]',
	},
	{
		label: 'GraphicGroupSequence (00700234)',
		code: 'DICOMObject["00700234"]',
	},
	{
		label: 'TextColorCIELabValue (00700241)',
		code: 'DICOMObject["00700241"]',
	},
	{
		label: 'HorizontalAlignment (00700242)',
		code: 'DICOMObject["00700242"]',
	},
	{
		label: 'VerticalAlignment (00700243)',
		code: 'DICOMObject["00700243"]',
	},
	{
		label: 'ShadowStyle (00700244)',
		code: 'DICOMObject["00700244"]',
	},
	{
		label: 'ShadowOffsetX (00700245)',
		code: 'DICOMObject["00700245"]',
	},
	{
		label: 'ShadowOffsetY (00700246)',
		code: 'DICOMObject["00700246"]',
	},
	{
		label: 'ShadowColorCIELabValue (00700247)',
		code: 'DICOMObject["00700247"]',
	},
	{
		label: 'Underlined (00700248)',
		code: 'DICOMObject["00700248"]',
	},
	{
		label: 'Bold (00700249)',
		code: 'DICOMObject["00700249"]',
	},
	{
		label: 'Italic (00700250)',
		code: 'DICOMObject["00700250"]',
	},
	{
		label: 'PatternOnColorCIELabValue (00700251)',
		code: 'DICOMObject["00700251"]',
	},
	{
		label: 'PatternOffColorCIELabValue (00700252)',
		code: 'DICOMObject["00700252"]',
	},
	{
		label: 'LineThickness (00700253)',
		code: 'DICOMObject["00700253"]',
	},
	{
		label: 'LineDashingStyle (00700254)',
		code: 'DICOMObject["00700254"]',
	},
	{
		label: 'LinePattern (00700255)',
		code: 'DICOMObject["00700255"]',
	},
	{
		label: 'FillPattern (00700256)',
		code: 'DICOMObject["00700256"]',
	},
	{
		label: 'FillMode (00700257)',
		code: 'DICOMObject["00700257"]',
	},
	{
		label: 'ShadowOpacity (00700258)',
		code: 'DICOMObject["00700258"]',
	},
	{
		label: 'GapLength (00700261)',
		code: 'DICOMObject["00700261"]',
	},
	{
		label: 'DiameterOfVisibility (00700262)',
		code: 'DICOMObject["00700262"]',
	},
	{
		label: 'RotationPoint (00700273)',
		code: 'DICOMObject["00700273"]',
	},
	{
		label: 'TickAlignment (00700274)',
		code: 'DICOMObject["00700274"]',
	},
	{
		label: 'ShowTickLabel (00700278)',
		code: 'DICOMObject["00700278"]',
	},
	{
		label: 'TickLabelAlignment (00700279)',
		code: 'DICOMObject["00700279"]',
	},
	{
		label: 'CompoundGraphicUnits (00700282)',
		code: 'DICOMObject["00700282"]',
	},
	{
		label: 'PatternOnOpacity (00700284)',
		code: 'DICOMObject["00700284"]',
	},
	{
		label: 'PatternOffOpacity (00700285)',
		code: 'DICOMObject["00700285"]',
	},
	{
		label: 'MajorTicksSequence (00700287)',
		code: 'DICOMObject["00700287"]',
	},
	{
		label: 'TickPosition (00700288)',
		code: 'DICOMObject["00700288"]',
	},
	{
		label: 'TickLabel (00700289)',
		code: 'DICOMObject["00700289"]',
	},
	{
		label: 'CompoundGraphicType (00700294)',
		code: 'DICOMObject["00700294"]',
	},
	{
		label: 'GraphicGroupID (00700295)',
		code: 'DICOMObject["00700295"]',
	},
	{
		label: 'ShapeType (00700306)',
		code: 'DICOMObject["00700306"]',
	},
	{
		label: 'RegistrationSequence (00700308)',
		code: 'DICOMObject["00700308"]',
	},
	{
		label: 'MatrixRegistrationSequence (00700309)',
		code: 'DICOMObject["00700309"]',
	},
	{
		label: 'MatrixSequence (0070030A)',
		code: 'DICOMObject["0070030A"]',
	},
	{
		label: 'FrameOfReferenceToDisplayedCoordinateSystemTransformationMatrix (0070030B)',
		code: 'DICOMObject["0070030B"]',
	},
	{
		label: 'FrameOfReferenceTransformationMatrixType (0070030C)',
		code: 'DICOMObject["0070030C"]',
	},
	{
		label: 'RegistrationTypeCodeSequence (0070030D)',
		code: 'DICOMObject["0070030D"]',
	},
	{
		label: 'FiducialDescription (0070030F)',
		code: 'DICOMObject["0070030F"]',
	},
	{
		label: 'FiducialIdentifier (00700310)',
		code: 'DICOMObject["00700310"]',
	},
	{
		label: 'FiducialIdentifierCodeSequence (00700311)',
		code: 'DICOMObject["00700311"]',
	},
	{
		label: 'ContourUncertaintyRadius (00700312)',
		code: 'DICOMObject["00700312"]',
	},
	{
		label: 'UsedFiducialsSequence (00700314)',
		code: 'DICOMObject["00700314"]',
	},
	{
		label: 'GraphicCoordinatesDataSequence (00700318)',
		code: 'DICOMObject["00700318"]',
	},
	{
		label: 'FiducialUID (0070031A)',
		code: 'DICOMObject["0070031A"]',
	},
	{
		label: 'FiducialSetSequence (0070031C)',
		code: 'DICOMObject["0070031C"]',
	},
	{
		label: 'FiducialSequence (0070031E)',
		code: 'DICOMObject["0070031E"]',
	},
	{
		label: 'FiducialsPropertyCategoryCodeSequence (0070031F)',
		code: 'DICOMObject["0070031F"]',
	},
	{
		label: 'GraphicLayerRecommendedDisplayCIELabValue (00700401)',
		code: 'DICOMObject["00700401"]',
	},
	{
		label: 'BlendingSequence (00700402)',
		code: 'DICOMObject["00700402"]',
	},
	{
		label: 'RelativeOpacity (00700403)',
		code: 'DICOMObject["00700403"]',
	},
	{
		label: 'ReferencedSpatialRegistrationSequence (00700404)',
		code: 'DICOMObject["00700404"]',
	},
	{
		label: 'BlendingPosition (00700405)',
		code: 'DICOMObject["00700405"]',
	},
	{
		label: 'PresentationDisplayCollectionUID (00701101)',
		code: 'DICOMObject["00701101"]',
	},
	{
		label: 'PresentationSequenceCollectionUID (00701102)',
		code: 'DICOMObject["00701102"]',
	},
	{
		label: 'PresentationSequencePositionIndex (00701103)',
		code: 'DICOMObject["00701103"]',
	},
	{
		label: 'RenderedImageReferenceSequence (00701104)',
		code: 'DICOMObject["00701104"]',
	},
	{
		label: 'VolumetricPresentationStateInputSequence (00701201)',
		code: 'DICOMObject["00701201"]',
	},
	{
		label: 'PresentationInputType (00701202)',
		code: 'DICOMObject["00701202"]',
	},
	{
		label: 'InputSequencePositionIndex (00701203)',
		code: 'DICOMObject["00701203"]',
	},
	{
		label: 'Crop (00701204)',
		code: 'DICOMObject["00701204"]',
	},
	{
		label: 'CroppingSpecificationIndex (00701205)',
		code: 'DICOMObject["00701205"]',
	},
	{
		label: 'VolumetricPresentationInputNumber (00701207)',
		code: 'DICOMObject["00701207"]',
	},
	{
		label: 'ImageVolumeGeometry (00701208)',
		code: 'DICOMObject["00701208"]',
	},
	{
		label: 'VolumetricPresentationInputSetUID (00701209)',
		code: 'DICOMObject["00701209"]',
	},
	{
		label: 'VolumetricPresentationInputSetSequence (0070120A)',
		code: 'DICOMObject["0070120A"]',
	},
	{
		label: 'GlobalCrop (0070120B)',
		code: 'DICOMObject["0070120B"]',
	},
	{
		label: 'GlobalCroppingSpecificationIndex (0070120C)',
		code: 'DICOMObject["0070120C"]',
	},
	{
		label: 'RenderingMethod (0070120D)',
		code: 'DICOMObject["0070120D"]',
	},
	{
		label: 'VolumeCroppingSequence (00701301)',
		code: 'DICOMObject["00701301"]',
	},
	{
		label: 'VolumeCroppingMethod (00701302)',
		code: 'DICOMObject["00701302"]',
	},
	{
		label: 'BoundingBoxCrop (00701303)',
		code: 'DICOMObject["00701303"]',
	},
	{
		label: 'ObliqueCroppingPlaneSequence (00701304)',
		code: 'DICOMObject["00701304"]',
	},
	{
		label: 'Plane (00701305)',
		code: 'DICOMObject["00701305"]',
	},
	{
		label: 'PlaneNormal (00701306)',
		code: 'DICOMObject["00701306"]',
	},
	{
		label: 'CroppingSpecificationNumber (00701309)',
		code: 'DICOMObject["00701309"]',
	},
	{
		label: 'MultiPlanarReconstructionStyle (00701501)',
		code: 'DICOMObject["00701501"]',
	},
	{
		label: 'MPRThicknessType (00701502)',
		code: 'DICOMObject["00701502"]',
	},
	{
		label: 'MPRSlabThickness (00701503)',
		code: 'DICOMObject["00701503"]',
	},
	{
		label: 'MPRTopLeftHandCorner (00701505)',
		code: 'DICOMObject["00701505"]',
	},
	{
		label: 'MPRViewWidthDirection (00701507)',
		code: 'DICOMObject["00701507"]',
	},
	{
		label: 'MPRViewWidth (00701508)',
		code: 'DICOMObject["00701508"]',
	},
	{
		label: 'NumberOfVolumetricCurvePoints (0070150C)',
		code: 'DICOMObject["0070150C"]',
	},
	{
		label: 'VolumetricCurvePoints (0070150D)',
		code: 'DICOMObject["0070150D"]',
	},
	{
		label: 'MPRViewHeightDirection (00701511)',
		code: 'DICOMObject["00701511"]',
	},
	{
		label: 'MPRViewHeight (00701512)',
		code: 'DICOMObject["00701512"]',
	},
	{
		label: 'RenderProjection (00701602)',
		code: 'DICOMObject["00701602"]',
	},
	{
		label: 'ViewpointPosition (00701603)',
		code: 'DICOMObject["00701603"]',
	},
	{
		label: 'ViewpointLookAtPoint (00701604)',
		code: 'DICOMObject["00701604"]',
	},
	{
		label: 'ViewpointUpDirection (00701605)',
		code: 'DICOMObject["00701605"]',
	},
	{
		label: 'RenderFieldOfView (00701606)',
		code: 'DICOMObject["00701606"]',
	},
	{
		label: 'SamplingStepSize (00701607)',
		code: 'DICOMObject["00701607"]',
	},
	{
		label: 'ShadingStyle (00701701)',
		code: 'DICOMObject["00701701"]',
	},
	{
		label: 'AmbientReflectionIntensity (00701702)',
		code: 'DICOMObject["00701702"]',
	},
	{
		label: 'LightDirection (00701703)',
		code: 'DICOMObject["00701703"]',
	},
	{
		label: 'DiffuseReflectionIntensity (00701704)',
		code: 'DICOMObject["00701704"]',
	},
	{
		label: 'SpecularReflectionIntensity (00701705)',
		code: 'DICOMObject["00701705"]',
	},
	{
		label: 'Shininess (00701706)',
		code: 'DICOMObject["00701706"]',
	},
	{
		label: 'PresentationStateClassificationComponentSequence (00701801)',
		code: 'DICOMObject["00701801"]',
	},
	{
		label: 'ComponentType (00701802)',
		code: 'DICOMObject["00701802"]',
	},
	{
		label: 'ComponentInputSequence (00701803)',
		code: 'DICOMObject["00701803"]',
	},
	{
		label: 'VolumetricPresentationInputIndex (00701804)',
		code: 'DICOMObject["00701804"]',
	},
	{
		label: 'PresentationStateCompositorComponentSequence (00701805)',
		code: 'DICOMObject["00701805"]',
	},
	{
		label: 'WeightingTransferFunctionSequence (00701806)',
		code: 'DICOMObject["00701806"]',
	},
	{
		label: 'WeightingLookupTableDescriptor (00701807)',
		code: 'DICOMObject["00701807"]',
	},
	{
		label: 'WeightingLookupTableData (00701808)',
		code: 'DICOMObject["00701808"]',
	},
	{
		label: 'VolumetricAnnotationSequence (00701901)',
		code: 'DICOMObject["00701901"]',
	},
	{
		label: 'ReferencedStructuredContextSequence (00701903)',
		code: 'DICOMObject["00701903"]',
	},
	{
		label: 'ReferencedContentItem (00701904)',
		code: 'DICOMObject["00701904"]',
	},
	{
		label: 'VolumetricPresentationInputAnnotationSequence (00701905)',
		code: 'DICOMObject["00701905"]',
	},
	{
		label: 'AnnotationClipping (00701907)',
		code: 'DICOMObject["00701907"]',
	},
	{
		label: 'PresentationAnimationStyle (00701A01)',
		code: 'DICOMObject["00701A01"]',
	},
	{
		label: 'RecommendedAnimationRate (00701A03)',
		code: 'DICOMObject["00701A03"]',
	},
	{
		label: 'AnimationCurveSequence (00701A04)',
		code: 'DICOMObject["00701A04"]',
	},
	{
		label: 'AnimationStepSize (00701A05)',
		code: 'DICOMObject["00701A05"]',
	},
	{
		label: 'SwivelRange (00701A06)',
		code: 'DICOMObject["00701A06"]',
	},
	{
		label: 'VolumetricCurveUpDirections (00701A07)',
		code: 'DICOMObject["00701A07"]',
	},
	{
		label: 'VolumeStreamSequence (00701A08)',
		code: 'DICOMObject["00701A08"]',
	},
	{
		label: 'RGBATransferFunctionDescription (00701A09)',
		code: 'DICOMObject["00701A09"]',
	},
	{
		label: 'AdvancedBlendingSequence (00701B01)',
		code: 'DICOMObject["00701B01"]',
	},
	{
		label: 'BlendingInputNumber (00701B02)',
		code: 'DICOMObject["00701B02"]',
	},
	{
		label: 'BlendingDisplayInputSequence (00701B03)',
		code: 'DICOMObject["00701B03"]',
	},
	{
		label: 'BlendingDisplaySequence (00701B04)',
		code: 'DICOMObject["00701B04"]',
	},
	{
		label: 'BlendingMode (00701B06)',
		code: 'DICOMObject["00701B06"]',
	},
	{
		label: 'TimeSeriesBlending (00701B07)',
		code: 'DICOMObject["00701B07"]',
	},
	{
		label: 'GeometryForDisplay (00701B08)',
		code: 'DICOMObject["00701B08"]',
	},
	{
		label: 'ThresholdSequence (00701B11)',
		code: 'DICOMObject["00701B11"]',
	},
	{
		label: 'ThresholdValueSequence (00701B12)',
		code: 'DICOMObject["00701B12"]',
	},
	{
		label: 'ThresholdType (00701B13)',
		code: 'DICOMObject["00701B13"]',
	},
	{
		label: 'ThresholdValue (00701B14)',
		code: 'DICOMObject["00701B14"]',
	},
	{
		label: 'HangingProtocolName (00720002)',
		code: 'DICOMObject["00720002"]',
	},
	{
		label: 'HangingProtocolDescription (00720004)',
		code: 'DICOMObject["00720004"]',
	},
	{
		label: 'HangingProtocolLevel (00720006)',
		code: 'DICOMObject["00720006"]',
	},
	{
		label: 'HangingProtocolCreator (00720008)',
		code: 'DICOMObject["00720008"]',
	},
	{
		label: 'HangingProtocolCreationDateTime (0072000A)',
		code: 'DICOMObject["0072000A"]',
	},
	{
		label: 'HangingProtocolDefinitionSequence (0072000C)',
		code: 'DICOMObject["0072000C"]',
	},
	{
		label: 'HangingProtocolUserIdentificationCodeSequence (0072000E)',
		code: 'DICOMObject["0072000E"]',
	},
	{
		label: 'HangingProtocolUserGroupName (00720010)',
		code: 'DICOMObject["00720010"]',
	},
	{
		label: 'SourceHangingProtocolSequence (00720012)',
		code: 'DICOMObject["00720012"]',
	},
	{
		label: 'NumberOfPriorsReferenced (00720014)',
		code: 'DICOMObject["00720014"]',
	},
	{
		label: 'ImageSetsSequence (00720020)',
		code: 'DICOMObject["00720020"]',
	},
	{
		label: 'ImageSetSelectorSequence (00720022)',
		code: 'DICOMObject["00720022"]',
	},
	{
		label: 'ImageSetSelectorUsageFlag (00720024)',
		code: 'DICOMObject["00720024"]',
	},
	{
		label: 'SelectorAttribute (00720026)',
		code: 'DICOMObject["00720026"]',
	},
	{
		label: 'SelectorValueNumber (00720028)',
		code: 'DICOMObject["00720028"]',
	},
	{
		label: 'TimeBasedImageSetsSequence (00720030)',
		code: 'DICOMObject["00720030"]',
	},
	{
		label: 'ImageSetNumber (00720032)',
		code: 'DICOMObject["00720032"]',
	},
	{
		label: 'ImageSetSelectorCategory (00720034)',
		code: 'DICOMObject["00720034"]',
	},
	{
		label: 'RelativeTime (00720038)',
		code: 'DICOMObject["00720038"]',
	},
	{
		label: 'RelativeTimeUnits (0072003A)',
		code: 'DICOMObject["0072003A"]',
	},
	{
		label: 'AbstractPriorValue (0072003C)',
		code: 'DICOMObject["0072003C"]',
	},
	{
		label: 'AbstractPriorCodeSequence (0072003E)',
		code: 'DICOMObject["0072003E"]',
	},
	{
		label: 'ImageSetLabel (00720040)',
		code: 'DICOMObject["00720040"]',
	},
	{
		label: 'SelectorAttributeVR (00720050)',
		code: 'DICOMObject["00720050"]',
	},
	{
		label: 'SelectorSequencePointer (00720052)',
		code: 'DICOMObject["00720052"]',
	},
	{
		label: 'SelectorSequencePointerPrivateCreator (00720054)',
		code: 'DICOMObject["00720054"]',
	},
	{
		label: 'SelectorAttributePrivateCreator (00720056)',
		code: 'DICOMObject["00720056"]',
	},
	{
		label: 'SelectorAEValue (0072005E)',
		code: 'DICOMObject["0072005E"]',
	},
	{
		label: 'SelectorASValue (0072005F)',
		code: 'DICOMObject["0072005F"]',
	},
	{
		label: 'SelectorATValue (00720060)',
		code: 'DICOMObject["00720060"]',
	},
	{
		label: 'SelectorDAValue (00720061)',
		code: 'DICOMObject["00720061"]',
	},
	{
		label: 'SelectorCSValue (00720062)',
		code: 'DICOMObject["00720062"]',
	},
	{
		label: 'SelectorDTValue (00720063)',
		code: 'DICOMObject["00720063"]',
	},
	{
		label: 'SelectorISValue (00720064)',
		code: 'DICOMObject["00720064"]',
	},
	{
		label: 'SelectorOBValue (00720065)',
		code: 'DICOMObject["00720065"]',
	},
	{
		label: 'SelectorLOValue (00720066)',
		code: 'DICOMObject["00720066"]',
	},
	{
		label: 'SelectorOFValue (00720067)',
		code: 'DICOMObject["00720067"]',
	},
	{
		label: 'SelectorLTValue (00720068)',
		code: 'DICOMObject["00720068"]',
	},
	{
		label: 'SelectorOWValue (00720069)',
		code: 'DICOMObject["00720069"]',
	},
	{
		label: 'SelectorPNValue (0072006A)',
		code: 'DICOMObject["0072006A"]',
	},
	{
		label: 'SelectorTMValue (0072006B)',
		code: 'DICOMObject["0072006B"]',
	},
	{
		label: 'SelectorSHValue (0072006C)',
		code: 'DICOMObject["0072006C"]',
	},
	{
		label: 'SelectorUNValue (0072006D)',
		code: 'DICOMObject["0072006D"]',
	},
	{
		label: 'SelectorSTValue (0072006E)',
		code: 'DICOMObject["0072006E"]',
	},
	{
		label: 'SelectorUCValue (0072006F)',
		code: 'DICOMObject["0072006F"]',
	},
	{
		label: 'SelectorUTValue (00720070)',
		code: 'DICOMObject["00720070"]',
	},
	{
		label: 'SelectorURValue (00720071)',
		code: 'DICOMObject["00720071"]',
	},
	{
		label: 'SelectorDSValue (00720072)',
		code: 'DICOMObject["00720072"]',
	},
	{
		label: 'SelectorODValue (00720073)',
		code: 'DICOMObject["00720073"]',
	},
	{
		label: 'SelectorFDValue (00720074)',
		code: 'DICOMObject["00720074"]',
	},
	{
		label: 'SelectorOLValue (00720075)',
		code: 'DICOMObject["00720075"]',
	},
	{
		label: 'SelectorFLValue (00720076)',
		code: 'DICOMObject["00720076"]',
	},
	{
		label: 'SelectorULValue (00720078)',
		code: 'DICOMObject["00720078"]',
	},
	{
		label: 'SelectorUSValue (0072007A)',
		code: 'DICOMObject["0072007A"]',
	},
	{
		label: 'SelectorSLValue (0072007C)',
		code: 'DICOMObject["0072007C"]',
	},
	{
		label: 'SelectorSSValue (0072007E)',
		code: 'DICOMObject["0072007E"]',
	},
	{
		label: 'SelectorUIValue (0072007F)',
		code: 'DICOMObject["0072007F"]',
	},
	{
		label: 'SelectorCodeSequenceValue (00720080)',
		code: 'DICOMObject["00720080"]',
	},
	{
		label: 'NumberOfScreens (00720100)',
		code: 'DICOMObject["00720100"]',
	},
	{
		label: 'NominalScreenDefinitionSequence (00720102)',
		code: 'DICOMObject["00720102"]',
	},
	{
		label: 'NumberOfVerticalPixels (00720104)',
		code: 'DICOMObject["00720104"]',
	},
	{
		label: 'NumberOfHorizontalPixels (00720106)',
		code: 'DICOMObject["00720106"]',
	},
	{
		label: 'DisplayEnvironmentSpatialPosition (00720108)',
		code: 'DICOMObject["00720108"]',
	},
	{
		label: 'ScreenMinimumGrayscaleBitDepth (0072010A)',
		code: 'DICOMObject["0072010A"]',
	},
	{
		label: 'ScreenMinimumColorBitDepth (0072010C)',
		code: 'DICOMObject["0072010C"]',
	},
	{
		label: 'ApplicationMaximumRepaintTime (0072010E)',
		code: 'DICOMObject["0072010E"]',
	},
	{
		label: 'DisplaySetsSequence (00720200)',
		code: 'DICOMObject["00720200"]',
	},
	{
		label: 'DisplaySetNumber (00720202)',
		code: 'DICOMObject["00720202"]',
	},
	{
		label: 'DisplaySetLabel (00720203)',
		code: 'DICOMObject["00720203"]',
	},
	{
		label: 'DisplaySetPresentationGroup (00720204)',
		code: 'DICOMObject["00720204"]',
	},
	{
		label: 'DisplaySetPresentationGroupDescription (00720206)',
		code: 'DICOMObject["00720206"]',
	},
	{
		label: 'PartialDataDisplayHandling (00720208)',
		code: 'DICOMObject["00720208"]',
	},
	{
		label: 'SynchronizedScrollingSequence (00720210)',
		code: 'DICOMObject["00720210"]',
	},
	{
		label: 'DisplaySetScrollingGroup (00720212)',
		code: 'DICOMObject["00720212"]',
	},
	{
		label: 'NavigationIndicatorSequence (00720214)',
		code: 'DICOMObject["00720214"]',
	},
	{
		label: 'NavigationDisplaySet (00720216)',
		code: 'DICOMObject["00720216"]',
	},
	{
		label: 'ReferenceDisplaySets (00720218)',
		code: 'DICOMObject["00720218"]',
	},
	{
		label: 'ImageBoxesSequence (00720300)',
		code: 'DICOMObject["00720300"]',
	},
	{
		label: 'ImageBoxNumber (00720302)',
		code: 'DICOMObject["00720302"]',
	},
	{
		label: 'ImageBoxLayoutType (00720304)',
		code: 'DICOMObject["00720304"]',
	},
	{
		label: 'ImageBoxTileHorizontalDimension (00720306)',
		code: 'DICOMObject["00720306"]',
	},
	{
		label: 'ImageBoxTileVerticalDimension (00720308)',
		code: 'DICOMObject["00720308"]',
	},
	{
		label: 'ImageBoxScrollDirection (00720310)',
		code: 'DICOMObject["00720310"]',
	},
	{
		label: 'ImageBoxSmallScrollType (00720312)',
		code: 'DICOMObject["00720312"]',
	},
	{
		label: 'ImageBoxSmallScrollAmount (00720314)',
		code: 'DICOMObject["00720314"]',
	},
	{
		label: 'ImageBoxLargeScrollType (00720316)',
		code: 'DICOMObject["00720316"]',
	},
	{
		label: 'ImageBoxLargeScrollAmount (00720318)',
		code: 'DICOMObject["00720318"]',
	},
	{
		label: 'ImageBoxOverlapPriority (00720320)',
		code: 'DICOMObject["00720320"]',
	},
	{
		label: 'CineRelativeToRealTime (00720330)',
		code: 'DICOMObject["00720330"]',
	},
	{
		label: 'FilterOperationsSequence (00720400)',
		code: 'DICOMObject["00720400"]',
	},
	{
		label: 'FilterByCategory (00720402)',
		code: 'DICOMObject["00720402"]',
	},
	{
		label: 'FilterByAttributePresence (00720404)',
		code: 'DICOMObject["00720404"]',
	},
	{
		label: 'FilterByOperator (00720406)',
		code: 'DICOMObject["00720406"]',
	},
	{
		label: 'StructuredDisplayBackgroundCIELabValue (00720420)',
		code: 'DICOMObject["00720420"]',
	},
	{
		label: 'EmptyImageBoxCIELabValue (00720421)',
		code: 'DICOMObject["00720421"]',
	},
	{
		label: 'StructuredDisplayImageBoxSequence (00720422)',
		code: 'DICOMObject["00720422"]',
	},
	{
		label: 'StructuredDisplayTextBoxSequence (00720424)',
		code: 'DICOMObject["00720424"]',
	},
	{
		label: 'ReferencedFirstFrameSequence (00720427)',
		code: 'DICOMObject["00720427"]',
	},
	{
		label: 'ImageBoxSynchronizationSequence (00720430)',
		code: 'DICOMObject["00720430"]',
	},
	{
		label: 'SynchronizedImageBoxList (00720432)',
		code: 'DICOMObject["00720432"]',
	},
	{
		label: 'TypeOfSynchronization (00720434)',
		code: 'DICOMObject["00720434"]',
	},
	{
		label: 'BlendingOperationType (00720500)',
		code: 'DICOMObject["00720500"]',
	},
	{
		label: 'ReformattingOperationType (00720510)',
		code: 'DICOMObject["00720510"]',
	},
	{
		label: 'ReformattingThickness (00720512)',
		code: 'DICOMObject["00720512"]',
	},
	{
		label: 'ReformattingInterval (00720514)',
		code: 'DICOMObject["00720514"]',
	},
	{
		label: 'ReformattingOperationInitialViewDirection (00720516)',
		code: 'DICOMObject["00720516"]',
	},
	{
		label: 'ThreeDRenderingType (00720520)',
		code: 'DICOMObject["00720520"]',
	},
	{
		label: 'SortingOperationsSequence (00720600)',
		code: 'DICOMObject["00720600"]',
	},
	{
		label: 'SortByCategory (00720602)',
		code: 'DICOMObject["00720602"]',
	},
	{
		label: 'SortingDirection (00720604)',
		code: 'DICOMObject["00720604"]',
	},
	{
		label: 'DisplaySetPatientOrientation (00720700)',
		code: 'DICOMObject["00720700"]',
	},
	{
		label: 'VOIType (00720702)',
		code: 'DICOMObject["00720702"]',
	},
	{
		label: 'PseudoColorType (00720704)',
		code: 'DICOMObject["00720704"]',
	},
	{
		label: 'PseudoColorPaletteInstanceReferenceSequence (00720705)',
		code: 'DICOMObject["00720705"]',
	},
	{
		label: 'ShowGrayscaleInverted (00720706)',
		code: 'DICOMObject["00720706"]',
	},
	{
		label: 'ShowImageTrueSizeFlag (00720710)',
		code: 'DICOMObject["00720710"]',
	},
	{
		label: 'ShowGraphicAnnotationFlag (00720712)',
		code: 'DICOMObject["00720712"]',
	},
	{
		label: 'ShowPatientDemographicsFlag (00720714)',
		code: 'DICOMObject["00720714"]',
	},
	{
		label: 'ShowAcquisitionTechniquesFlag (00720716)',
		code: 'DICOMObject["00720716"]',
	},
	{
		label: 'DisplaySetHorizontalJustification (00720717)',
		code: 'DICOMObject["00720717"]',
	},
	{
		label: 'DisplaySetVerticalJustification (00720718)',
		code: 'DICOMObject["00720718"]',
	},
	{
		label: 'ContinuationStartMeterset (00740120)',
		code: 'DICOMObject["00740120"]',
	},
	{
		label: 'ContinuationEndMeterset (00740121)',
		code: 'DICOMObject["00740121"]',
	},
	{
		label: 'ProcedureStepState (00741000)',
		code: 'DICOMObject["00741000"]',
	},
	{
		label: 'ProcedureStepProgressInformationSequence (00741002)',
		code: 'DICOMObject["00741002"]',
	},
	{
		label: 'ProcedureStepProgress (00741004)',
		code: 'DICOMObject["00741004"]',
	},
	{
		label: 'ProcedureStepProgressDescription (00741006)',
		code: 'DICOMObject["00741006"]',
	},
	{
		label: 'ProcedureStepCommunicationsURISequence (00741008)',
		code: 'DICOMObject["00741008"]',
	},
	{
		label: 'ContactURI (0074100A)',
		code: 'DICOMObject["0074100A"]',
	},
	{
		label: 'ContactDisplayName (0074100C)',
		code: 'DICOMObject["0074100C"]',
	},
	{
		label: 'ProcedureStepDiscontinuationReasonCodeSequence (0074100E)',
		code: 'DICOMObject["0074100E"]',
	},
	{
		label: 'BeamTaskSequence (00741020)',
		code: 'DICOMObject["00741020"]',
	},
	{
		label: 'BeamTaskType (00741022)',
		code: 'DICOMObject["00741022"]',
	},
	{
		label: 'AutosequenceFlag (00741025)',
		code: 'DICOMObject["00741025"]',
	},
	{
		label: 'TableTopVerticalAdjustedPosition (00741026)',
		code: 'DICOMObject["00741026"]',
	},
	{
		label: 'TableTopLongitudinalAdjustedPosition (00741027)',
		code: 'DICOMObject["00741027"]',
	},
	{
		label: 'TableTopLateralAdjustedPosition (00741028)',
		code: 'DICOMObject["00741028"]',
	},
	{
		label: 'PatientSupportAdjustedAngle (0074102A)',
		code: 'DICOMObject["0074102A"]',
	},
	{
		label: 'TableTopEccentricAdjustedAngle (0074102B)',
		code: 'DICOMObject["0074102B"]',
	},
	{
		label: 'TableTopPitchAdjustedAngle (0074102C)',
		code: 'DICOMObject["0074102C"]',
	},
	{
		label: 'TableTopRollAdjustedAngle (0074102D)',
		code: 'DICOMObject["0074102D"]',
	},
	{
		label: 'DeliveryVerificationImageSequence (00741030)',
		code: 'DICOMObject["00741030"]',
	},
	{
		label: 'VerificationImageTiming (00741032)',
		code: 'DICOMObject["00741032"]',
	},
	{
		label: 'DoubleExposureFlag (00741034)',
		code: 'DICOMObject["00741034"]',
	},
	{
		label: 'DoubleExposureOrdering (00741036)',
		code: 'DICOMObject["00741036"]',
	},
	{
		label: 'RelatedReferenceRTImageSequence (00741040)',
		code: 'DICOMObject["00741040"]',
	},
	{
		label: 'GeneralMachineVerificationSequence (00741042)',
		code: 'DICOMObject["00741042"]',
	},
	{
		label: 'ConventionalMachineVerificationSequence (00741044)',
		code: 'DICOMObject["00741044"]',
	},
	{
		label: 'IonMachineVerificationSequence (00741046)',
		code: 'DICOMObject["00741046"]',
	},
	{
		label: 'FailedAttributesSequence (00741048)',
		code: 'DICOMObject["00741048"]',
	},
	{
		label: 'OverriddenAttributesSequence (0074104A)',
		code: 'DICOMObject["0074104A"]',
	},
	{
		label: 'ConventionalControlPointVerificationSequence (0074104C)',
		code: 'DICOMObject["0074104C"]',
	},
	{
		label: 'IonControlPointVerificationSequence (0074104E)',
		code: 'DICOMObject["0074104E"]',
	},
	{
		label: 'AttributeOccurrenceSequence (00741050)',
		code: 'DICOMObject["00741050"]',
	},
	{
		label: 'AttributeOccurrencePointer (00741052)',
		code: 'DICOMObject["00741052"]',
	},
	{
		label: 'AttributeItemSelector (00741054)',
		code: 'DICOMObject["00741054"]',
	},
	{
		label: 'AttributeOccurrencePrivateCreator (00741056)',
		code: 'DICOMObject["00741056"]',
	},
	{
		label: 'SelectorSequencePointerItems (00741057)',
		code: 'DICOMObject["00741057"]',
	},
	{
		label: 'ScheduledProcedureStepPriority (00741200)',
		code: 'DICOMObject["00741200"]',
	},
	{
		label: 'WorklistLabel (00741202)',
		code: 'DICOMObject["00741202"]',
	},
	{
		label: 'ProcedureStepLabel (00741204)',
		code: 'DICOMObject["00741204"]',
	},
	{
		label: 'ScheduledProcessingParametersSequence (00741210)',
		code: 'DICOMObject["00741210"]',
	},
	{
		label: 'PerformedProcessingParametersSequence (00741212)',
		code: 'DICOMObject["00741212"]',
	},
	{
		label: 'UnifiedProcedureStepPerformedProcedureSequence (00741216)',
		code: 'DICOMObject["00741216"]',
	},
	{
		label: 'ReplacedProcedureStepSequence (00741224)',
		code: 'DICOMObject["00741224"]',
	},
	{
		label: 'DeletionLock (00741230)',
		code: 'DICOMObject["00741230"]',
	},
	{
		label: 'ReceivingAE (00741234)',
		code: 'DICOMObject["00741234"]',
	},
	{
		label: 'RequestingAE (00741236)',
		code: 'DICOMObject["00741236"]',
	},
	{
		label: 'ReasonForCancellation (00741238)',
		code: 'DICOMObject["00741238"]',
	},
	{
		label: 'SCPStatus (00741242)',
		code: 'DICOMObject["00741242"]',
	},
	{
		label: 'SubscriptionListStatus (00741244)',
		code: 'DICOMObject["00741244"]',
	},
	{
		label: 'UnifiedProcedureStepListStatus (00741246)',
		code: 'DICOMObject["00741246"]',
	},
	{
		label: 'BeamOrderIndex (00741324)',
		code: 'DICOMObject["00741324"]',
	},
	{
		label: 'DoubleExposureMeterset (00741338)',
		code: 'DICOMObject["00741338"]',
	},
	{
		label: 'DoubleExposureFieldDelta (0074133A)',
		code: 'DICOMObject["0074133A"]',
	},
	{
		label: 'BrachyTaskSequence (00741401)',
		code: 'DICOMObject["00741401"]',
	},
	{
		label: 'ContinuationStartTotalReferenceAirKerma (00741402)',
		code: 'DICOMObject["00741402"]',
	},
	{
		label: 'ContinuationEndTotalReferenceAirKerma (00741403)',
		code: 'DICOMObject["00741403"]',
	},
	{
		label: 'ContinuationPulseNumber (00741404)',
		code: 'DICOMObject["00741404"]',
	},
	{
		label: 'ChannelDeliveryOrderSequence (00741405)',
		code: 'DICOMObject["00741405"]',
	},
	{
		label: 'ReferencedChannelNumber (00741406)',
		code: 'DICOMObject["00741406"]',
	},
	{
		label: 'StartCumulativeTimeWeight (00741407)',
		code: 'DICOMObject["00741407"]',
	},
	{
		label: 'EndCumulativeTimeWeight (00741408)',
		code: 'DICOMObject["00741408"]',
	},
	{
		label: 'OmittedChannelSequence (00741409)',
		code: 'DICOMObject["00741409"]',
	},
	{
		label: 'ReasonForChannelOmission (0074140A)',
		code: 'DICOMObject["0074140A"]',
	},
	{
		label: 'ReasonForChannelOmissionDescription (0074140B)',
		code: 'DICOMObject["0074140B"]',
	},
	{
		label: 'ChannelDeliveryOrderIndex (0074140C)',
		code: 'DICOMObject["0074140C"]',
	},
	{
		label: 'ChannelDeliveryContinuationSequence (0074140D)',
		code: 'DICOMObject["0074140D"]',
	},
	{
		label: 'OmittedApplicationSetupSequence (0074140E)',
		code: 'DICOMObject["0074140E"]',
	},
	{
		label: 'ImplantAssemblyTemplateName (00760001)',
		code: 'DICOMObject["00760001"]',
	},
	{
		label: 'ImplantAssemblyTemplateIssuer (00760003)',
		code: 'DICOMObject["00760003"]',
	},
	{
		label: 'ImplantAssemblyTemplateVersion (00760006)',
		code: 'DICOMObject["00760006"]',
	},
	{
		label: 'ReplacedImplantAssemblyTemplateSequence (00760008)',
		code: 'DICOMObject["00760008"]',
	},
	{
		label: 'ImplantAssemblyTemplateType (0076000A)',
		code: 'DICOMObject["0076000A"]',
	},
	{
		label: 'OriginalImplantAssemblyTemplateSequence (0076000C)',
		code: 'DICOMObject["0076000C"]',
	},
	{
		label: 'DerivationImplantAssemblyTemplateSequence (0076000E)',
		code: 'DICOMObject["0076000E"]',
	},
	{
		label: 'ImplantAssemblyTemplateTargetAnatomySequence (00760010)',
		code: 'DICOMObject["00760010"]',
	},
	{
		label: 'ProcedureTypeCodeSequence (00760020)',
		code: 'DICOMObject["00760020"]',
	},
	{
		label: 'SurgicalTechnique (00760030)',
		code: 'DICOMObject["00760030"]',
	},
	{
		label: 'ComponentTypesSequence (00760032)',
		code: 'DICOMObject["00760032"]',
	},
	{
		label: 'ComponentTypeCodeSequence (00760034)',
		code: 'DICOMObject["00760034"]',
	},
	{
		label: 'ExclusiveComponentType (00760036)',
		code: 'DICOMObject["00760036"]',
	},
	{
		label: 'MandatoryComponentType (00760038)',
		code: 'DICOMObject["00760038"]',
	},
	{
		label: 'ComponentSequence (00760040)',
		code: 'DICOMObject["00760040"]',
	},
	{
		label: 'ComponentID (00760055)',
		code: 'DICOMObject["00760055"]',
	},
	{
		label: 'ComponentAssemblySequence (00760060)',
		code: 'DICOMObject["00760060"]',
	},
	{
		label: 'Component1ReferencedID (00760070)',
		code: 'DICOMObject["00760070"]',
	},
	{
		label: 'Component1ReferencedMatingFeatureSetID (00760080)',
		code: 'DICOMObject["00760080"]',
	},
	{
		label: 'Component1ReferencedMatingFeatureID (00760090)',
		code: 'DICOMObject["00760090"]',
	},
	{
		label: 'Component2ReferencedID (007600A0)',
		code: 'DICOMObject["007600A0"]',
	},
	{
		label: 'Component2ReferencedMatingFeatureSetID (007600B0)',
		code: 'DICOMObject["007600B0"]',
	},
	{
		label: 'Component2ReferencedMatingFeatureID (007600C0)',
		code: 'DICOMObject["007600C0"]',
	},
	{
		label: 'ImplantTemplateGroupName (00780001)',
		code: 'DICOMObject["00780001"]',
	},
	{
		label: 'ImplantTemplateGroupDescription (00780010)',
		code: 'DICOMObject["00780010"]',
	},
	{
		label: 'ImplantTemplateGroupIssuer (00780020)',
		code: 'DICOMObject["00780020"]',
	},
	{
		label: 'ImplantTemplateGroupVersion (00780024)',
		code: 'DICOMObject["00780024"]',
	},
	{
		label: 'ReplacedImplantTemplateGroupSequence (00780026)',
		code: 'DICOMObject["00780026"]',
	},
	{
		label: 'ImplantTemplateGroupTargetAnatomySequence (00780028)',
		code: 'DICOMObject["00780028"]',
	},
	{
		label: 'ImplantTemplateGroupMembersSequence (0078002A)',
		code: 'DICOMObject["0078002A"]',
	},
	{
		label: 'ImplantTemplateGroupMemberID (0078002E)',
		code: 'DICOMObject["0078002E"]',
	},
	{
		label: 'ThreeDImplantTemplateGroupMemberMatchingPoint (00780050)',
		code: 'DICOMObject["00780050"]',
	},
	{
		label: 'ThreeDImplantTemplateGroupMemberMatchingAxes (00780060)',
		code: 'DICOMObject["00780060"]',
	},
	{
		label: 'ImplantTemplateGroupMemberMatching2DCoordinatesSequence (00780070)',
		code: 'DICOMObject["00780070"]',
	},
	{
		label: 'TwoDImplantTemplateGroupMemberMatchingPoint (00780090)',
		code: 'DICOMObject["00780090"]',
	},
	{
		label: 'TwoDImplantTemplateGroupMemberMatchingAxes (007800A0)',
		code: 'DICOMObject["007800A0"]',
	},
	{
		label: 'ImplantTemplateGroupVariationDimensionSequence (007800B0)',
		code: 'DICOMObject["007800B0"]',
	},
	{
		label: 'ImplantTemplateGroupVariationDimensionName (007800B2)',
		code: 'DICOMObject["007800B2"]',
	},
	{
		label: 'ImplantTemplateGroupVariationDimensionRankSequence (007800B4)',
		code: 'DICOMObject["007800B4"]',
	},
	{
		label: 'ReferencedImplantTemplateGroupMemberID (007800B6)',
		code: 'DICOMObject["007800B6"]',
	},
	{
		label: 'ImplantTemplateGroupVariationDimensionRank (007800B8)',
		code: 'DICOMObject["007800B8"]',
	},
	{
		label: 'SurfaceScanAcquisitionTypeCodeSequence (00800001)',
		code: 'DICOMObject["00800001"]',
	},
	{
		label: 'SurfaceScanModeCodeSequence (00800002)',
		code: 'DICOMObject["00800002"]',
	},
	{
		label: 'RegistrationMethodCodeSequence (00800003)',
		code: 'DICOMObject["00800003"]',
	},
	{
		label: 'ShotDurationTime (00800004)',
		code: 'DICOMObject["00800004"]',
	},
	{
		label: 'ShotOffsetTime (00800005)',
		code: 'DICOMObject["00800005"]',
	},
	{
		label: 'SurfacePointPresentationValueData (00800006)',
		code: 'DICOMObject["00800006"]',
	},
	{
		label: 'SurfacePointColorCIELabValueData (00800007)',
		code: 'DICOMObject["00800007"]',
	},
	{
		label: 'UVMappingSequence (00800008)',
		code: 'DICOMObject["00800008"]',
	},
	{
		label: 'TextureLabel (00800009)',
		code: 'DICOMObject["00800009"]',
	},
	{
		label: 'UValueData (00800010)',
		code: 'DICOMObject["00800010"]',
	},
	{
		label: 'VValueData (00800011)',
		code: 'DICOMObject["00800011"]',
	},
	{
		label: 'ReferencedTextureSequence (00800012)',
		code: 'DICOMObject["00800012"]',
	},
	{
		label: 'ReferencedSurfaceDataSequence (00800013)',
		code: 'DICOMObject["00800013"]',
	},
	{
		label: 'AssessmentSummary (00820001)',
		code: 'DICOMObject["00820001"]',
	},
	{
		label: 'AssessmentSummaryDescription (00820003)',
		code: 'DICOMObject["00820003"]',
	},
	{
		label: 'AssessedSOPInstanceSequence (00820004)',
		code: 'DICOMObject["00820004"]',
	},
	{
		label: 'ReferencedComparisonSOPInstanceSequence (00820005)',
		code: 'DICOMObject["00820005"]',
	},
	{
		label: 'NumberOfAssessmentObservations (00820006)',
		code: 'DICOMObject["00820006"]',
	},
	{
		label: 'AssessmentObservationsSequence (00820007)',
		code: 'DICOMObject["00820007"]',
	},
	{
		label: 'ObservationSignificance (00820008)',
		code: 'DICOMObject["00820008"]',
	},
	{
		label: 'ObservationDescription (0082000A)',
		code: 'DICOMObject["0082000A"]',
	},
	{
		label: 'StructuredContraintObservationSequence (0082000C)',
		code: 'DICOMObject["0082000C"]',
	},
	{
		label: 'AssessedAttributeValueSequence (00820010)',
		code: 'DICOMObject["00820010"]',
	},
	{
		label: 'AssessmentSetID (00820016)',
		code: 'DICOMObject["00820016"]',
	},
	{
		label: 'AssessmentRequesterSequence (00820017)',
		code: 'DICOMObject["00820017"]',
	},
	{
		label: 'SelectorAttributeName (00820018)',
		code: 'DICOMObject["00820018"]',
	},
	{
		label: 'SelectorAttributeKeyword (00820019)',
		code: 'DICOMObject["00820019"]',
	},
	{
		label: 'AssessmentTypeCodeSequence (00820021)',
		code: 'DICOMObject["00820021"]',
	},
	{
		label: 'ObservationBasisCodeSequence (00820022)',
		code: 'DICOMObject["00820022"]',
	},
	{
		label: 'AssessmentLabel (00820023)',
		code: 'DICOMObject["00820023"]',
	},
	{
		label: 'ConstraintType (00820032)',
		code: 'DICOMObject["00820032"]',
	},
	{
		label: 'SpecificationSelectionGuidance (00820033)',
		code: 'DICOMObject["00820033"]',
	},
	{
		label: 'ConstraintValueSequence (00820034)',
		code: 'DICOMObject["00820034"]',
	},
	{
		label: 'RecommendedDefaultValueSequence (00820035)',
		code: 'DICOMObject["00820035"]',
	},
	{
		label: 'ConstraintViolationSignificance (00820036)',
		code: 'DICOMObject["00820036"]',
	},
	{
		label: 'ConstraintViolationCondition (00820037)',
		code: 'DICOMObject["00820037"]',
	},
	{
		label: 'ModifiableConstraintFlag (00820038)',
		code: 'DICOMObject["00820038"]',
	},
	{
		label: 'StorageMediaFileSetID (00880130)',
		code: 'DICOMObject["00880130"]',
	},
	{
		label: 'StorageMediaFileSetUID (00880140)',
		code: 'DICOMObject["00880140"]',
	},
	{
		label: 'IconImageSequence (00880200)',
		code: 'DICOMObject["00880200"]',
	},
	{
		label: 'SOPInstanceStatus (01000410)',
		code: 'DICOMObject["01000410"]',
	},
	{
		label: 'SOPAuthorizationDateTime (01000420)',
		code: 'DICOMObject["01000420"]',
	},
	{
		label: 'SOPAuthorizationComment (01000424)',
		code: 'DICOMObject["01000424"]',
	},
	{
		label: 'AuthorizationEquipmentCertificationNumber (01000426)',
		code: 'DICOMObject["01000426"]',
	},
	{
		label: 'MACIDNumber (04000005)',
		code: 'DICOMObject["04000005"]',
	},
	{
		label: 'MACCalculationTransferSyntaxUID (04000010)',
		code: 'DICOMObject["04000010"]',
	},
	{
		label: 'MACAlgorithm (04000015)',
		code: 'DICOMObject["04000015"]',
	},
	{
		label: 'DataElementsSigned (04000020)',
		code: 'DICOMObject["04000020"]',
	},
	{
		label: 'DigitalSignatureUID (04000100)',
		code: 'DICOMObject["04000100"]',
	},
	{
		label: 'DigitalSignatureDateTime (04000105)',
		code: 'DICOMObject["04000105"]',
	},
	{
		label: 'CertificateType (04000110)',
		code: 'DICOMObject["04000110"]',
	},
	{
		label: 'CertificateOfSigner (04000115)',
		code: 'DICOMObject["04000115"]',
	},
	{
		label: 'Signature (04000120)',
		code: 'DICOMObject["04000120"]',
	},
	{
		label: 'CertifiedTimestampType (04000305)',
		code: 'DICOMObject["04000305"]',
	},
	{
		label: 'CertifiedTimestamp (04000310)',
		code: 'DICOMObject["04000310"]',
	},
	{
		label: 'DigitalSignaturePurposeCodeSequence (04000401)',
		code: 'DICOMObject["04000401"]',
	},
	{
		label: 'ReferencedDigitalSignatureSequence (04000402)',
		code: 'DICOMObject["04000402"]',
	},
	{
		label: 'ReferencedSOPInstanceMACSequence (04000403)',
		code: 'DICOMObject["04000403"]',
	},
	{
		label: 'MAC (04000404)',
		code: 'DICOMObject["04000404"]',
	},
	{
		label: 'EncryptedAttributesSequence (04000500)',
		code: 'DICOMObject["04000500"]',
	},
	{
		label: 'EncryptedContentTransferSyntaxUID (04000510)',
		code: 'DICOMObject["04000510"]',
	},
	{
		label: 'EncryptedContent (04000520)',
		code: 'DICOMObject["04000520"]',
	},
	{
		label: 'ModifiedAttributesSequence (04000550)',
		code: 'DICOMObject["04000550"]',
	},
	{
		label: 'OriginalAttributesSequence (04000561)',
		code: 'DICOMObject["04000561"]',
	},
	{
		label: 'AttributeModificationDateTime (04000562)',
		code: 'DICOMObject["04000562"]',
	},
	{
		label: 'ModifyingSystem (04000563)',
		code: 'DICOMObject["04000563"]',
	},
	{
		label: 'SourceOfPreviousValues (04000564)',
		code: 'DICOMObject["04000564"]',
	},
	{
		label: 'ReasonForTheAttributeModification (04000565)',
		code: 'DICOMObject["04000565"]',
	},
	{
		label: 'NumberOfCopies (20000010)',
		code: 'DICOMObject["20000010"]',
	},
	{
		label: 'PrinterConfigurationSequence (2000001E)',
		code: 'DICOMObject["2000001E"]',
	},
	{
		label: 'PrintPriority (20000020)',
		code: 'DICOMObject["20000020"]',
	},
	{
		label: 'MediumType (20000030)',
		code: 'DICOMObject["20000030"]',
	},
	{
		label: 'FilmDestination (20000040)',
		code: 'DICOMObject["20000040"]',
	},
	{
		label: 'FilmSessionLabel (20000050)',
		code: 'DICOMObject["20000050"]',
	},
	{
		label: 'MemoryAllocation (20000060)',
		code: 'DICOMObject["20000060"]',
	},
	{
		label: 'MaximumMemoryAllocation (20000061)',
		code: 'DICOMObject["20000061"]',
	},
	{
		label: 'MemoryBitDepth (200000A0)',
		code: 'DICOMObject["200000A0"]',
	},
	{
		label: 'PrintingBitDepth (200000A1)',
		code: 'DICOMObject["200000A1"]',
	},
	{
		label: 'MediaInstalledSequence (200000A2)',
		code: 'DICOMObject["200000A2"]',
	},
	{
		label: 'OtherMediaAvailableSequence (200000A4)',
		code: 'DICOMObject["200000A4"]',
	},
	{
		label: 'SupportedImageDisplayFormatsSequence (200000A8)',
		code: 'DICOMObject["200000A8"]',
	},
	{
		label: 'ReferencedFilmBoxSequence (20000500)',
		code: 'DICOMObject["20000500"]',
	},
	{
		label: 'ImageDisplayFormat (20100010)',
		code: 'DICOMObject["20100010"]',
	},
	{
		label: 'AnnotationDisplayFormatID (20100030)',
		code: 'DICOMObject["20100030"]',
	},
	{
		label: 'FilmOrientation (20100040)',
		code: 'DICOMObject["20100040"]',
	},
	{
		label: 'FilmSizeID (20100050)',
		code: 'DICOMObject["20100050"]',
	},
	{
		label: 'PrinterResolutionID (20100052)',
		code: 'DICOMObject["20100052"]',
	},
	{
		label: 'DefaultPrinterResolutionID (20100054)',
		code: 'DICOMObject["20100054"]',
	},
	{
		label: 'MagnificationType (20100060)',
		code: 'DICOMObject["20100060"]',
	},
	{
		label: 'SmoothingType (20100080)',
		code: 'DICOMObject["20100080"]',
	},
	{
		label: 'DefaultMagnificationType (201000A6)',
		code: 'DICOMObject["201000A6"]',
	},
	{
		label: 'OtherMagnificationTypesAvailable (201000A7)',
		code: 'DICOMObject["201000A7"]',
	},
	{
		label: 'DefaultSmoothingType (201000A8)',
		code: 'DICOMObject["201000A8"]',
	},
	{
		label: 'OtherSmoothingTypesAvailable (201000A9)',
		code: 'DICOMObject["201000A9"]',
	},
	{
		label: 'BorderDensity (20100100)',
		code: 'DICOMObject["20100100"]',
	},
	{
		label: 'EmptyImageDensity (20100110)',
		code: 'DICOMObject["20100110"]',
	},
	{
		label: 'MinDensity (20100120)',
		code: 'DICOMObject["20100120"]',
	},
	{
		label: 'MaxDensity (20100130)',
		code: 'DICOMObject["20100130"]',
	},
	{
		label: 'Trim (20100140)',
		code: 'DICOMObject["20100140"]',
	},
	{
		label: 'ConfigurationInformation (20100150)',
		code: 'DICOMObject["20100150"]',
	},
	{
		label: 'ConfigurationInformationDescription (20100152)',
		code: 'DICOMObject["20100152"]',
	},
	{
		label: 'MaximumCollatedFilms (20100154)',
		code: 'DICOMObject["20100154"]',
	},
	{
		label: 'Illumination (2010015E)',
		code: 'DICOMObject["2010015E"]',
	},
	{
		label: 'ReflectedAmbientLight (20100160)',
		code: 'DICOMObject["20100160"]',
	},
	{
		label: 'PrinterPixelSpacing (20100376)',
		code: 'DICOMObject["20100376"]',
	},
	{
		label: 'ReferencedFilmSessionSequence (20100500)',
		code: 'DICOMObject["20100500"]',
	},
	{
		label: 'ReferencedImageBoxSequence (20100510)',
		code: 'DICOMObject["20100510"]',
	},
	{
		label: 'ReferencedBasicAnnotationBoxSequence (20100520)',
		code: 'DICOMObject["20100520"]',
	},
	{
		label: 'ImageBoxPosition (20200010)',
		code: 'DICOMObject["20200010"]',
	},
	{
		label: 'Polarity (20200020)',
		code: 'DICOMObject["20200020"]',
	},
	{
		label: 'RequestedImageSize (20200030)',
		code: 'DICOMObject["20200030"]',
	},
	{
		label: 'RequestedDecimateCropBehavior (20200040)',
		code: 'DICOMObject["20200040"]',
	},
	{
		label: 'RequestedResolutionID (20200050)',
		code: 'DICOMObject["20200050"]',
	},
	{
		label: 'RequestedImageSizeFlag (202000A0)',
		code: 'DICOMObject["202000A0"]',
	},
	{
		label: 'DecimateCropResult (202000A2)',
		code: 'DICOMObject["202000A2"]',
	},
	{
		label: 'BasicGrayscaleImageSequence (20200110)',
		code: 'DICOMObject["20200110"]',
	},
	{
		label: 'BasicColorImageSequence (20200111)',
		code: 'DICOMObject["20200111"]',
	},
	{
		label: 'AnnotationPosition (20300010)',
		code: 'DICOMObject["20300010"]',
	},
	{
		label: 'TextString (20300020)',
		code: 'DICOMObject["20300020"]',
	},
	{
		label: 'PresentationLUTSequence (20500010)',
		code: 'DICOMObject["20500010"]',
	},
	{
		label: 'PresentationLUTShape (20500020)',
		code: 'DICOMObject["20500020"]',
	},
	{
		label: 'ReferencedPresentationLUTSequence (20500500)',
		code: 'DICOMObject["20500500"]',
	},
	{
		label: 'ExecutionStatus (21000020)',
		code: 'DICOMObject["21000020"]',
	},
	{
		label: 'ExecutionStatusInfo (21000030)',
		code: 'DICOMObject["21000030"]',
	},
	{
		label: 'CreationDate (21000040)',
		code: 'DICOMObject["21000040"]',
	},
	{
		label: 'CreationTime (21000050)',
		code: 'DICOMObject["21000050"]',
	},
	{
		label: 'Originator (21000070)',
		code: 'DICOMObject["21000070"]',
	},
	{
		label: 'DestinationAE (21000140)',
		code: 'DICOMObject["21000140"]',
	},
	{
		label: 'OwnerID (21000160)',
		code: 'DICOMObject["21000160"]',
	},
	{
		label: 'NumberOfFilms (21000170)',
		code: 'DICOMObject["21000170"]',
	},
	{
		label: 'PrinterStatus (21100010)',
		code: 'DICOMObject["21100010"]',
	},
	{
		label: 'PrinterStatusInfo (21100020)',
		code: 'DICOMObject["21100020"]',
	},
	{
		label: 'PrinterName (21100030)',
		code: 'DICOMObject["21100030"]',
	},
	{
		label: 'LabelUsingInformationExtractedFromInstances (22000001)',
		code: 'DICOMObject["22000001"]',
	},
	{
		label: 'LabelText (22000002)',
		code: 'DICOMObject["22000002"]',
	},
	{
		label: 'LabelStyleSelection (22000003)',
		code: 'DICOMObject["22000003"]',
	},
	{
		label: 'MediaDisposition (22000004)',
		code: 'DICOMObject["22000004"]',
	},
	{
		label: 'BarcodeValue (22000005)',
		code: 'DICOMObject["22000005"]',
	},
	{
		label: 'BarcodeSymbology (22000006)',
		code: 'DICOMObject["22000006"]',
	},
	{
		label: 'AllowMediaSplitting (22000007)',
		code: 'DICOMObject["22000007"]',
	},
	{
		label: 'IncludeNonDICOMObjects (22000008)',
		code: 'DICOMObject["22000008"]',
	},
	{
		label: 'IncludeDisplayApplication (22000009)',
		code: 'DICOMObject["22000009"]',
	},
	{
		label: 'PreserveCompositeInstancesAfterMediaCreation (2200000A)',
		code: 'DICOMObject["2200000A"]',
	},
	{
		label: 'TotalNumberOfPiecesOfMediaCreated (2200000B)',
		code: 'DICOMObject["2200000B"]',
	},
	{
		label: 'RequestedMediaApplicationProfile (2200000C)',
		code: 'DICOMObject["2200000C"]',
	},
	{
		label: 'ReferencedStorageMediaSequence (2200000D)',
		code: 'DICOMObject["2200000D"]',
	},
	{
		label: 'FailureAttributes (2200000E)',
		code: 'DICOMObject["2200000E"]',
	},
	{
		label: 'AllowLossyCompression (2200000F)',
		code: 'DICOMObject["2200000F"]',
	},
	{
		label: 'RequestPriority (22000020)',
		code: 'DICOMObject["22000020"]',
	},
	{
		label: 'RTImageLabel (30020002)',
		code: 'DICOMObject["30020002"]',
	},
	{
		label: 'RTImageName (30020003)',
		code: 'DICOMObject["30020003"]',
	},
	{
		label: 'RTImageDescription (30020004)',
		code: 'DICOMObject["30020004"]',
	},
	{
		label: 'ReportedValuesOrigin (3002000A)',
		code: 'DICOMObject["3002000A"]',
	},
	{
		label: 'RTImagePlane (3002000C)',
		code: 'DICOMObject["3002000C"]',
	},
	{
		label: 'XRayImageReceptorTranslation (3002000D)',
		code: 'DICOMObject["3002000D"]',
	},
	{
		label: 'XRayImageReceptorAngle (3002000E)',
		code: 'DICOMObject["3002000E"]',
	},
	{
		label: 'RTImageOrientation (30020010)',
		code: 'DICOMObject["30020010"]',
	},
	{
		label: 'ImagePlanePixelSpacing (30020011)',
		code: 'DICOMObject["30020011"]',
	},
	{
		label: 'RTImagePosition (30020012)',
		code: 'DICOMObject["30020012"]',
	},
	{
		label: 'RadiationMachineName (30020020)',
		code: 'DICOMObject["30020020"]',
	},
	{
		label: 'RadiationMachineSAD (30020022)',
		code: 'DICOMObject["30020022"]',
	},
	{
		label: 'RadiationMachineSSD (30020024)',
		code: 'DICOMObject["30020024"]',
	},
	{
		label: 'RTImageSID (30020026)',
		code: 'DICOMObject["30020026"]',
	},
	{
		label: 'SourceToReferenceObjectDistance (30020028)',
		code: 'DICOMObject["30020028"]',
	},
	{
		label: 'FractionNumber (30020029)',
		code: 'DICOMObject["30020029"]',
	},
	{
		label: 'ExposureSequence (30020030)',
		code: 'DICOMObject["30020030"]',
	},
	{
		label: 'MetersetExposure (30020032)',
		code: 'DICOMObject["30020032"]',
	},
	{
		label: 'DiaphragmPosition (30020034)',
		code: 'DICOMObject["30020034"]',
	},
	{
		label: 'FluenceMapSequence (30020040)',
		code: 'DICOMObject["30020040"]',
	},
	{
		label: 'FluenceDataSource (30020041)',
		code: 'DICOMObject["30020041"]',
	},
	{
		label: 'FluenceDataScale (30020042)',
		code: 'DICOMObject["30020042"]',
	},
	{
		label: 'PrimaryFluenceModeSequence (30020050)',
		code: 'DICOMObject["30020050"]',
	},
	{
		label: 'FluenceMode (30020051)',
		code: 'DICOMObject["30020051"]',
	},
	{
		label: 'FluenceModeID (30020052)',
		code: 'DICOMObject["30020052"]',
	},
	{
		label: 'DVHType (30040001)',
		code: 'DICOMObject["30040001"]',
	},
	{
		label: 'DoseUnits (30040002)',
		code: 'DICOMObject["30040002"]',
	},
	{
		label: 'DoseType (30040004)',
		code: 'DICOMObject["30040004"]',
	},
	{
		label: 'SpatialTransformOfDose (30040005)',
		code: 'DICOMObject["30040005"]',
	},
	{
		label: 'DoseComment (30040006)',
		code: 'DICOMObject["30040006"]',
	},
	{
		label: 'NormalizationPoint (30040008)',
		code: 'DICOMObject["30040008"]',
	},
	{
		label: 'DoseSummationType (3004000A)',
		code: 'DICOMObject["3004000A"]',
	},
	{
		label: 'GridFrameOffsetVector (3004000C)',
		code: 'DICOMObject["3004000C"]',
	},
	{
		label: 'DoseGridScaling (3004000E)',
		code: 'DICOMObject["3004000E"]',
	},
	{
		label: 'RTDoseROISequence (30040010)',
		code: 'DICOMObject["30040010"]',
	},
	{
		label: 'DoseValue (30040012)',
		code: 'DICOMObject["30040012"]',
	},
	{
		label: 'TissueHeterogeneityCorrection (30040014)',
		code: 'DICOMObject["30040014"]',
	},
	{
		label: 'DVHNormalizationPoint (30040040)',
		code: 'DICOMObject["30040040"]',
	},
	{
		label: 'DVHNormalizationDoseValue (30040042)',
		code: 'DICOMObject["30040042"]',
	},
	{
		label: 'DVHSequence (30040050)',
		code: 'DICOMObject["30040050"]',
	},
	{
		label: 'DVHDoseScaling (30040052)',
		code: 'DICOMObject["30040052"]',
	},
	{
		label: 'DVHVolumeUnits (30040054)',
		code: 'DICOMObject["30040054"]',
	},
	{
		label: 'DVHNumberOfBins (30040056)',
		code: 'DICOMObject["30040056"]',
	},
	{
		label: 'DVHData (30040058)',
		code: 'DICOMObject["30040058"]',
	},
	{
		label: 'DVHReferencedROISequence (30040060)',
		code: 'DICOMObject["30040060"]',
	},
	{
		label: 'DVHROIContributionType (30040062)',
		code: 'DICOMObject["30040062"]',
	},
	{
		label: 'DVHMinimumDose (30040070)',
		code: 'DICOMObject["30040070"]',
	},
	{
		label: 'DVHMaximumDose (30040072)',
		code: 'DICOMObject["30040072"]',
	},
	{
		label: 'DVHMeanDose (30040074)',
		code: 'DICOMObject["30040074"]',
	},
	{
		label: 'StructureSetLabel (30060002)',
		code: 'DICOMObject["30060002"]',
	},
	{
		label: 'StructureSetName (30060004)',
		code: 'DICOMObject["30060004"]',
	},
	{
		label: 'StructureSetDescription (30060006)',
		code: 'DICOMObject["30060006"]',
	},
	{
		label: 'StructureSetDate (30060008)',
		code: 'DICOMObject["30060008"]',
	},
	{
		label: 'StructureSetTime (30060009)',
		code: 'DICOMObject["30060009"]',
	},
	{
		label: 'ReferencedFrameOfReferenceSequence (30060010)',
		code: 'DICOMObject["30060010"]',
	},
	{
		label: 'RTReferencedStudySequence (30060012)',
		code: 'DICOMObject["30060012"]',
	},
	{
		label: 'RTReferencedSeriesSequence (30060014)',
		code: 'DICOMObject["30060014"]',
	},
	{
		label: 'ContourImageSequence (30060016)',
		code: 'DICOMObject["30060016"]',
	},
	{
		label: 'PredecessorStructureSetSequence (30060018)',
		code: 'DICOMObject["30060018"]',
	},
	{
		label: 'StructureSetROISequence (30060020)',
		code: 'DICOMObject["30060020"]',
	},
	{
		label: 'ROINumber (30060022)',
		code: 'DICOMObject["30060022"]',
	},
	{
		label: 'ReferencedFrameOfReferenceUID (30060024)',
		code: 'DICOMObject["30060024"]',
	},
	{
		label: 'ROIName (30060026)',
		code: 'DICOMObject["30060026"]',
	},
	{
		label: 'ROIDescription (30060028)',
		code: 'DICOMObject["30060028"]',
	},
	{
		label: 'ROIDisplayColor (3006002A)',
		code: 'DICOMObject["3006002A"]',
	},
	{
		label: 'ROIVolume (3006002C)',
		code: 'DICOMObject["3006002C"]',
	},
	{
		label: 'RTRelatedROISequence (30060030)',
		code: 'DICOMObject["30060030"]',
	},
	{
		label: 'RTROIRelationship (30060033)',
		code: 'DICOMObject["30060033"]',
	},
	{
		label: 'ROIGenerationAlgorithm (30060036)',
		code: 'DICOMObject["30060036"]',
	},
	{
		label: 'ROIGenerationDescription (30060038)',
		code: 'DICOMObject["30060038"]',
	},
	{
		label: 'ROIContourSequence (30060039)',
		code: 'DICOMObject["30060039"]',
	},
	{
		label: 'ContourSequence (30060040)',
		code: 'DICOMObject["30060040"]',
	},
	{
		label: 'ContourGeometricType (30060042)',
		code: 'DICOMObject["30060042"]',
	},
	{
		label: 'ContourSlabThickness (30060044)',
		code: 'DICOMObject["30060044"]',
	},
	{
		label: 'ContourOffsetVector (30060045)',
		code: 'DICOMObject["30060045"]',
	},
	{
		label: 'NumberOfContourPoints (30060046)',
		code: 'DICOMObject["30060046"]',
	},
	{
		label: 'ContourNumber (30060048)',
		code: 'DICOMObject["30060048"]',
	},
	{
		label: 'AttachedContours (30060049)',
		code: 'DICOMObject["30060049"]',
	},
	{
		label: 'ContourData (30060050)',
		code: 'DICOMObject["30060050"]',
	},
	{
		label: 'RTROIObservationsSequence (30060080)',
		code: 'DICOMObject["30060080"]',
	},
	{
		label: 'ObservationNumber (30060082)',
		code: 'DICOMObject["30060082"]',
	},
	{
		label: 'ReferencedROINumber (30060084)',
		code: 'DICOMObject["30060084"]',
	},
	{
		label: 'ROIObservationLabel (30060085)',
		code: 'DICOMObject["30060085"]',
	},
	{
		label: 'RTROIIdentificationCodeSequence (30060086)',
		code: 'DICOMObject["30060086"]',
	},
	{
		label: 'ROIObservationDescription (30060088)',
		code: 'DICOMObject["30060088"]',
	},
	{
		label: 'RelatedRTROIObservationsSequence (300600A0)',
		code: 'DICOMObject["300600A0"]',
	},
	{
		label: 'RTROIInterpretedType (300600A4)',
		code: 'DICOMObject["300600A4"]',
	},
	{
		label: 'ROIInterpreter (300600A6)',
		code: 'DICOMObject["300600A6"]',
	},
	{
		label: 'ROIPhysicalPropertiesSequence (300600B0)',
		code: 'DICOMObject["300600B0"]',
	},
	{
		label: 'ROIPhysicalProperty (300600B2)',
		code: 'DICOMObject["300600B2"]',
	},
	{
		label: 'ROIPhysicalPropertyValue (300600B4)',
		code: 'DICOMObject["300600B4"]',
	},
	{
		label: 'ROIElementalCompositionSequence (300600B6)',
		code: 'DICOMObject["300600B6"]',
	},
	{
		label: 'ROIElementalCompositionAtomicNumber (300600B7)',
		code: 'DICOMObject["300600B7"]',
	},
	{
		label: 'ROIElementalCompositionAtomicMassFraction (300600B8)',
		code: 'DICOMObject["300600B8"]',
	},
	{
		label: 'FrameOfReferenceTransformationMatrix (300600C6)',
		code: 'DICOMObject["300600C6"]',
	},
	{
		label: 'FrameOfReferenceTransformationComment (300600C8)',
		code: 'DICOMObject["300600C8"]',
	},
	{
		label: 'MeasuredDoseReferenceSequence (30080010)',
		code: 'DICOMObject["30080010"]',
	},
	{
		label: 'MeasuredDoseDescription (30080012)',
		code: 'DICOMObject["30080012"]',
	},
	{
		label: 'MeasuredDoseType (30080014)',
		code: 'DICOMObject["30080014"]',
	},
	{
		label: 'MeasuredDoseValue (30080016)',
		code: 'DICOMObject["30080016"]',
	},
	{
		label: 'TreatmentSessionBeamSequence (30080020)',
		code: 'DICOMObject["30080020"]',
	},
	{
		label: 'TreatmentSessionIonBeamSequence (30080021)',
		code: 'DICOMObject["30080021"]',
	},
	{
		label: 'CurrentFractionNumber (30080022)',
		code: 'DICOMObject["30080022"]',
	},
	{
		label: 'TreatmentControlPointDate (30080024)',
		code: 'DICOMObject["30080024"]',
	},
	{
		label: 'TreatmentControlPointTime (30080025)',
		code: 'DICOMObject["30080025"]',
	},
	{
		label: 'TreatmentTerminationStatus (3008002A)',
		code: 'DICOMObject["3008002A"]',
	},
	{
		label: 'TreatmentTerminationCode (3008002B)',
		code: 'DICOMObject["3008002B"]',
	},
	{
		label: 'TreatmentVerificationStatus (3008002C)',
		code: 'DICOMObject["3008002C"]',
	},
	{
		label: 'ReferencedTreatmentRecordSequence (30080030)',
		code: 'DICOMObject["30080030"]',
	},
	{
		label: 'SpecifiedPrimaryMeterset (30080032)',
		code: 'DICOMObject["30080032"]',
	},
	{
		label: 'SpecifiedSecondaryMeterset (30080033)',
		code: 'DICOMObject["30080033"]',
	},
	{
		label: 'DeliveredPrimaryMeterset (30080036)',
		code: 'DICOMObject["30080036"]',
	},
	{
		label: 'DeliveredSecondaryMeterset (30080037)',
		code: 'DICOMObject["30080037"]',
	},
	{
		label: 'SpecifiedTreatmentTime (3008003A)',
		code: 'DICOMObject["3008003A"]',
	},
	{
		label: 'DeliveredTreatmentTime (3008003B)',
		code: 'DICOMObject["3008003B"]',
	},
	{
		label: 'ControlPointDeliverySequence (30080040)',
		code: 'DICOMObject["30080040"]',
	},
	{
		label: 'IonControlPointDeliverySequence (30080041)',
		code: 'DICOMObject["30080041"]',
	},
	{
		label: 'SpecifiedMeterset (30080042)',
		code: 'DICOMObject["30080042"]',
	},
	{
		label: 'DeliveredMeterset (30080044)',
		code: 'DICOMObject["30080044"]',
	},
	{
		label: 'MetersetRateSet (30080045)',
		code: 'DICOMObject["30080045"]',
	},
	{
		label: 'MetersetRateDelivered (30080046)',
		code: 'DICOMObject["30080046"]',
	},
	{
		label: 'ScanSpotMetersetsDelivered (30080047)',
		code: 'DICOMObject["30080047"]',
	},
	{
		label: 'DoseRateDelivered (30080048)',
		code: 'DICOMObject["30080048"]',
	},
	{
		label: 'TreatmentSummaryCalculatedDoseReferenceSequence (30080050)',
		code: 'DICOMObject["30080050"]',
	},
	{
		label: 'CumulativeDoseToDoseReference (30080052)',
		code: 'DICOMObject["30080052"]',
	},
	{
		label: 'FirstTreatmentDate (30080054)',
		code: 'DICOMObject["30080054"]',
	},
	{
		label: 'MostRecentTreatmentDate (30080056)',
		code: 'DICOMObject["30080056"]',
	},
	{
		label: 'NumberOfFractionsDelivered (3008005A)',
		code: 'DICOMObject["3008005A"]',
	},
	{
		label: 'OverrideSequence (30080060)',
		code: 'DICOMObject["30080060"]',
	},
	{
		label: 'ParameterSequencePointer (30080061)',
		code: 'DICOMObject["30080061"]',
	},
	{
		label: 'OverrideParameterPointer (30080062)',
		code: 'DICOMObject["30080062"]',
	},
	{
		label: 'ParameterItemIndex (30080063)',
		code: 'DICOMObject["30080063"]',
	},
	{
		label: 'MeasuredDoseReferenceNumber (30080064)',
		code: 'DICOMObject["30080064"]',
	},
	{
		label: 'ParameterPointer (30080065)',
		code: 'DICOMObject["30080065"]',
	},
	{
		label: 'OverrideReason (30080066)',
		code: 'DICOMObject["30080066"]',
	},
	{
		label: 'ParameterValueNumber (30080067)',
		code: 'DICOMObject["30080067"]',
	},
	{
		label: 'CorrectedParameterSequence (30080068)',
		code: 'DICOMObject["30080068"]',
	},
	{
		label: 'CorrectionValue (3008006A)',
		code: 'DICOMObject["3008006A"]',
	},
	{
		label: 'CalculatedDoseReferenceSequence (30080070)',
		code: 'DICOMObject["30080070"]',
	},
	{
		label: 'CalculatedDoseReferenceNumber (30080072)',
		code: 'DICOMObject["30080072"]',
	},
	{
		label: 'CalculatedDoseReferenceDescription (30080074)',
		code: 'DICOMObject["30080074"]',
	},
	{
		label: 'CalculatedDoseReferenceDoseValue (30080076)',
		code: 'DICOMObject["30080076"]',
	},
	{
		label: 'StartMeterset (30080078)',
		code: 'DICOMObject["30080078"]',
	},
	{
		label: 'EndMeterset (3008007A)',
		code: 'DICOMObject["3008007A"]',
	},
	{
		label: 'ReferencedMeasuredDoseReferenceSequence (30080080)',
		code: 'DICOMObject["30080080"]',
	},
	{
		label: 'ReferencedMeasuredDoseReferenceNumber (30080082)',
		code: 'DICOMObject["30080082"]',
	},
	{
		label: 'ReferencedCalculatedDoseReferenceSequence (30080090)',
		code: 'DICOMObject["30080090"]',
	},
	{
		label: 'ReferencedCalculatedDoseReferenceNumber (30080092)',
		code: 'DICOMObject["30080092"]',
	},
	{
		label: 'BeamLimitingDeviceLeafPairsSequence (300800A0)',
		code: 'DICOMObject["300800A0"]',
	},
	{
		label: 'RecordedWedgeSequence (300800B0)',
		code: 'DICOMObject["300800B0"]',
	},
	{
		label: 'RecordedCompensatorSequence (300800C0)',
		code: 'DICOMObject["300800C0"]',
	},
	{
		label: 'RecordedBlockSequence (300800D0)',
		code: 'DICOMObject["300800D0"]',
	},
	{
		label: 'TreatmentSummaryMeasuredDoseReferenceSequence (300800E0)',
		code: 'DICOMObject["300800E0"]',
	},
	{
		label: 'RecordedSnoutSequence (300800F0)',
		code: 'DICOMObject["300800F0"]',
	},
	{
		label: 'RecordedRangeShifterSequence (300800F2)',
		code: 'DICOMObject["300800F2"]',
	},
	{
		label: 'RecordedLateralSpreadingDeviceSequence (300800F4)',
		code: 'DICOMObject["300800F4"]',
	},
	{
		label: 'RecordedRangeModulatorSequence (300800F6)',
		code: 'DICOMObject["300800F6"]',
	},
	{
		label: 'RecordedSourceSequence (30080100)',
		code: 'DICOMObject["30080100"]',
	},
	{
		label: 'SourceSerialNumber (30080105)',
		code: 'DICOMObject["30080105"]',
	},
	{
		label: 'TreatmentSessionApplicationSetupSequence (30080110)',
		code: 'DICOMObject["30080110"]',
	},
	{
		label: 'ApplicationSetupCheck (30080116)',
		code: 'DICOMObject["30080116"]',
	},
	{
		label: 'RecordedBrachyAccessoryDeviceSequence (30080120)',
		code: 'DICOMObject["30080120"]',
	},
	{
		label: 'ReferencedBrachyAccessoryDeviceNumber (30080122)',
		code: 'DICOMObject["30080122"]',
	},
	{
		label: 'RecordedChannelSequence (30080130)',
		code: 'DICOMObject["30080130"]',
	},
	{
		label: 'SpecifiedChannelTotalTime (30080132)',
		code: 'DICOMObject["30080132"]',
	},
	{
		label: 'DeliveredChannelTotalTime (30080134)',
		code: 'DICOMObject["30080134"]',
	},
	{
		label: 'SpecifiedNumberOfPulses (30080136)',
		code: 'DICOMObject["30080136"]',
	},
	{
		label: 'DeliveredNumberOfPulses (30080138)',
		code: 'DICOMObject["30080138"]',
	},
	{
		label: 'SpecifiedPulseRepetitionInterval (3008013A)',
		code: 'DICOMObject["3008013A"]',
	},
	{
		label: 'DeliveredPulseRepetitionInterval (3008013C)',
		code: 'DICOMObject["3008013C"]',
	},
	{
		label: 'RecordedSourceApplicatorSequence (30080140)',
		code: 'DICOMObject["30080140"]',
	},
	{
		label: 'ReferencedSourceApplicatorNumber (30080142)',
		code: 'DICOMObject["30080142"]',
	},
	{
		label: 'RecordedChannelShieldSequence (30080150)',
		code: 'DICOMObject["30080150"]',
	},
	{
		label: 'ReferencedChannelShieldNumber (30080152)',
		code: 'DICOMObject["30080152"]',
	},
	{
		label: 'BrachyControlPointDeliveredSequence (30080160)',
		code: 'DICOMObject["30080160"]',
	},
	{
		label: 'SafePositionExitDate (30080162)',
		code: 'DICOMObject["30080162"]',
	},
	{
		label: 'SafePositionExitTime (30080164)',
		code: 'DICOMObject["30080164"]',
	},
	{
		label: 'SafePositionReturnDate (30080166)',
		code: 'DICOMObject["30080166"]',
	},
	{
		label: 'SafePositionReturnTime (30080168)',
		code: 'DICOMObject["30080168"]',
	},
	{
		label: 'PulseSpecificBrachyControlPointDeliveredSequence (30080171)',
		code: 'DICOMObject["30080171"]',
	},
	{
		label: 'PulseNumber (30080172)',
		code: 'DICOMObject["30080172"]',
	},
	{
		label: 'BrachyPulseControlPointDeliveredSequence (30080173)',
		code: 'DICOMObject["30080173"]',
	},
	{
		label: 'CurrentTreatmentStatus (30080200)',
		code: 'DICOMObject["30080200"]',
	},
	{
		label: 'TreatmentStatusComment (30080202)',
		code: 'DICOMObject["30080202"]',
	},
	{
		label: 'FractionGroupSummarySequence (30080220)',
		code: 'DICOMObject["30080220"]',
	},
	{
		label: 'ReferencedFractionNumber (30080223)',
		code: 'DICOMObject["30080223"]',
	},
	{
		label: 'FractionGroupType (30080224)',
		code: 'DICOMObject["30080224"]',
	},
	{
		label: 'BeamStopperPosition (30080230)',
		code: 'DICOMObject["30080230"]',
	},
	{
		label: 'FractionStatusSummarySequence (30080240)',
		code: 'DICOMObject["30080240"]',
	},
	{
		label: 'TreatmentDate (30080250)',
		code: 'DICOMObject["30080250"]',
	},
	{
		label: 'TreatmentTime (30080251)',
		code: 'DICOMObject["30080251"]',
	},
	{
		label: 'RTPlanLabel (300A0002)',
		code: 'DICOMObject["300A0002"]',
	},
	{
		label: 'RTPlanName (300A0003)',
		code: 'DICOMObject["300A0003"]',
	},
	{
		label: 'RTPlanDescription (300A0004)',
		code: 'DICOMObject["300A0004"]',
	},
	{
		label: 'RTPlanDate (300A0006)',
		code: 'DICOMObject["300A0006"]',
	},
	{
		label: 'RTPlanTime (300A0007)',
		code: 'DICOMObject["300A0007"]',
	},
	{
		label: 'TreatmentProtocols (300A0009)',
		code: 'DICOMObject["300A0009"]',
	},
	{
		label: 'PlanIntent (300A000A)',
		code: 'DICOMObject["300A000A"]',
	},
	{
		label: 'TreatmentSites (300A000B)',
		code: 'DICOMObject["300A000B"]',
	},
	{
		label: 'RTPlanGeometry (300A000C)',
		code: 'DICOMObject["300A000C"]',
	},
	{
		label: 'PrescriptionDescription (300A000E)',
		code: 'DICOMObject["300A000E"]',
	},
	{
		label: 'DoseReferenceSequence (300A0010)',
		code: 'DICOMObject["300A0010"]',
	},
	{
		label: 'DoseReferenceNumber (300A0012)',
		code: 'DICOMObject["300A0012"]',
	},
	{
		label: 'DoseReferenceUID (300A0013)',
		code: 'DICOMObject["300A0013"]',
	},
	{
		label: 'DoseReferenceStructureType (300A0014)',
		code: 'DICOMObject["300A0014"]',
	},
	{
		label: 'NominalBeamEnergyUnit (300A0015)',
		code: 'DICOMObject["300A0015"]',
	},
	{
		label: 'DoseReferenceDescription (300A0016)',
		code: 'DICOMObject["300A0016"]',
	},
	{
		label: 'DoseReferencePointCoordinates (300A0018)',
		code: 'DICOMObject["300A0018"]',
	},
	{
		label: 'NominalPriorDose (300A001A)',
		code: 'DICOMObject["300A001A"]',
	},
	{
		label: 'DoseReferenceType (300A0020)',
		code: 'DICOMObject["300A0020"]',
	},
	{
		label: 'ConstraintWeight (300A0021)',
		code: 'DICOMObject["300A0021"]',
	},
	{
		label: 'DeliveryWarningDose (300A0022)',
		code: 'DICOMObject["300A0022"]',
	},
	{
		label: 'DeliveryMaximumDose (300A0023)',
		code: 'DICOMObject["300A0023"]',
	},
	{
		label: 'TargetMinimumDose (300A0025)',
		code: 'DICOMObject["300A0025"]',
	},
	{
		label: 'TargetPrescriptionDose (300A0026)',
		code: 'DICOMObject["300A0026"]',
	},
	{
		label: 'TargetMaximumDose (300A0027)',
		code: 'DICOMObject["300A0027"]',
	},
	{
		label: 'TargetUnderdoseVolumeFraction (300A0028)',
		code: 'DICOMObject["300A0028"]',
	},
	{
		label: 'OrganAtRiskFullVolumeDose (300A002A)',
		code: 'DICOMObject["300A002A"]',
	},
	{
		label: 'OrganAtRiskLimitDose (300A002B)',
		code: 'DICOMObject["300A002B"]',
	},
	{
		label: 'OrganAtRiskMaximumDose (300A002C)',
		code: 'DICOMObject["300A002C"]',
	},
	{
		label: 'OrganAtRiskOverdoseVolumeFraction (300A002D)',
		code: 'DICOMObject["300A002D"]',
	},
	{
		label: 'ToleranceTableSequence (300A0040)',
		code: 'DICOMObject["300A0040"]',
	},
	{
		label: 'ToleranceTableNumber (300A0042)',
		code: 'DICOMObject["300A0042"]',
	},
	{
		label: 'ToleranceTableLabel (300A0043)',
		code: 'DICOMObject["300A0043"]',
	},
	{
		label: 'GantryAngleTolerance (300A0044)',
		code: 'DICOMObject["300A0044"]',
	},
	{
		label: 'BeamLimitingDeviceAngleTolerance (300A0046)',
		code: 'DICOMObject["300A0046"]',
	},
	{
		label: 'BeamLimitingDeviceToleranceSequence (300A0048)',
		code: 'DICOMObject["300A0048"]',
	},
	{
		label: 'BeamLimitingDevicePositionTolerance (300A004A)',
		code: 'DICOMObject["300A004A"]',
	},
	{
		label: 'SnoutPositionTolerance (300A004B)',
		code: 'DICOMObject["300A004B"]',
	},
	{
		label: 'PatientSupportAngleTolerance (300A004C)',
		code: 'DICOMObject["300A004C"]',
	},
	{
		label: 'TableTopEccentricAngleTolerance (300A004E)',
		code: 'DICOMObject["300A004E"]',
	},
	{
		label: 'TableTopPitchAngleTolerance (300A004F)',
		code: 'DICOMObject["300A004F"]',
	},
	{
		label: 'TableTopRollAngleTolerance (300A0050)',
		code: 'DICOMObject["300A0050"]',
	},
	{
		label: 'TableTopVerticalPositionTolerance (300A0051)',
		code: 'DICOMObject["300A0051"]',
	},
	{
		label: 'TableTopLongitudinalPositionTolerance (300A0052)',
		code: 'DICOMObject["300A0052"]',
	},
	{
		label: 'TableTopLateralPositionTolerance (300A0053)',
		code: 'DICOMObject["300A0053"]',
	},
	{
		label: 'RTPlanRelationship (300A0055)',
		code: 'DICOMObject["300A0055"]',
	},
	{
		label: 'FractionGroupSequence (300A0070)',
		code: 'DICOMObject["300A0070"]',
	},
	{
		label: 'FractionGroupNumber (300A0071)',
		code: 'DICOMObject["300A0071"]',
	},
	{
		label: 'FractionGroupDescription (300A0072)',
		code: 'DICOMObject["300A0072"]',
	},
	{
		label: 'NumberOfFractionsPlanned (300A0078)',
		code: 'DICOMObject["300A0078"]',
	},
	{
		label: 'NumberOfFractionPatternDigitsPerDay (300A0079)',
		code: 'DICOMObject["300A0079"]',
	},
	{
		label: 'RepeatFractionCycleLength (300A007A)',
		code: 'DICOMObject["300A007A"]',
	},
	{
		label: 'FractionPattern (300A007B)',
		code: 'DICOMObject["300A007B"]',
	},
	{
		label: 'NumberOfBeams (300A0080)',
		code: 'DICOMObject["300A0080"]',
	},
	{
		label: 'BeamDoseSpecificationPoint (300A0082)',
		code: 'DICOMObject["300A0082"]',
	},
	{
		label: 'ReferencedDoseReferenceUID (300A0083)',
		code: 'DICOMObject["300A0083"]',
	},
	{
		label: 'BeamDose (300A0084)',
		code: 'DICOMObject["300A0084"]',
	},
	{
		label: 'BeamMeterset (300A0086)',
		code: 'DICOMObject["300A0086"]',
	},
	{
		label: 'BeamDoseMeaning (300A008B)',
		code: 'DICOMObject["300A008B"]',
	},
	{
		label: 'BeamDoseVerificationControlPointSequence (300A008C)',
		code: 'DICOMObject["300A008C"]',
	},
	{
		label: 'AverageBeamDosePointDepth (300A008D)',
		code: 'DICOMObject["300A008D"]',
	},
	{
		label: 'AverageBeamDosePointEquivalentDepth (300A008E)',
		code: 'DICOMObject["300A008E"]',
	},
	{
		label: 'AverageBeamDosePointSSD (300A008F)',
		code: 'DICOMObject["300A008F"]',
	},
	{
		label: 'BeamDoseType (300A0090)',
		code: 'DICOMObject["300A0090"]',
	},
	{
		label: 'AlternateBeamDose (300A0091)',
		code: 'DICOMObject["300A0091"]',
	},
	{
		label: 'AlternateBeamDoseType (300A0092)',
		code: 'DICOMObject["300A0092"]',
	},
	{
		label: 'NumberOfBrachyApplicationSetups (300A00A0)',
		code: 'DICOMObject["300A00A0"]',
	},
	{
		label: 'BrachyApplicationSetupDoseSpecificationPoint (300A00A2)',
		code: 'DICOMObject["300A00A2"]',
	},
	{
		label: 'BrachyApplicationSetupDose (300A00A4)',
		code: 'DICOMObject["300A00A4"]',
	},
	{
		label: 'BeamSequence (300A00B0)',
		code: 'DICOMObject["300A00B0"]',
	},
	{
		label: 'TreatmentMachineName (300A00B2)',
		code: 'DICOMObject["300A00B2"]',
	},
	{
		label: 'PrimaryDosimeterUnit (300A00B3)',
		code: 'DICOMObject["300A00B3"]',
	},
	{
		label: 'SourceAxisDistance (300A00B4)',
		code: 'DICOMObject["300A00B4"]',
	},
	{
		label: 'BeamLimitingDeviceSequence (300A00B6)',
		code: 'DICOMObject["300A00B6"]',
	},
	{
		label: 'RTBeamLimitingDeviceType (300A00B8)',
		code: 'DICOMObject["300A00B8"]',
	},
	{
		label: 'SourceToBeamLimitingDeviceDistance (300A00BA)',
		code: 'DICOMObject["300A00BA"]',
	},
	{
		label: 'IsocenterToBeamLimitingDeviceDistance (300A00BB)',
		code: 'DICOMObject["300A00BB"]',
	},
	{
		label: 'NumberOfLeafJawPairs (300A00BC)',
		code: 'DICOMObject["300A00BC"]',
	},
	{
		label: 'LeafPositionBoundaries (300A00BE)',
		code: 'DICOMObject["300A00BE"]',
	},
	{
		label: 'BeamNumber (300A00C0)',
		code: 'DICOMObject["300A00C0"]',
	},
	{
		label: 'BeamName (300A00C2)',
		code: 'DICOMObject["300A00C2"]',
	},
	{
		label: 'BeamDescription (300A00C3)',
		code: 'DICOMObject["300A00C3"]',
	},
	{
		label: 'BeamType (300A00C4)',
		code: 'DICOMObject["300A00C4"]',
	},
	{
		label: 'BeamDeliveryDurationLimit (300A00C5)',
		code: 'DICOMObject["300A00C5"]',
	},
	{
		label: 'RadiationType (300A00C6)',
		code: 'DICOMObject["300A00C6"]',
	},
	{
		label: 'HighDoseTechniqueType (300A00C7)',
		code: 'DICOMObject["300A00C7"]',
	},
	{
		label: 'ReferenceImageNumber (300A00C8)',
		code: 'DICOMObject["300A00C8"]',
	},
	{
		label: 'PlannedVerificationImageSequence (300A00CA)',
		code: 'DICOMObject["300A00CA"]',
	},
	{
		label: 'ImagingDeviceSpecificAcquisitionParameters (300A00CC)',
		code: 'DICOMObject["300A00CC"]',
	},
	{
		label: 'TreatmentDeliveryType (300A00CE)',
		code: 'DICOMObject["300A00CE"]',
	},
	{
		label: 'NumberOfWedges (300A00D0)',
		code: 'DICOMObject["300A00D0"]',
	},
	{
		label: 'WedgeSequence (300A00D1)',
		code: 'DICOMObject["300A00D1"]',
	},
	{
		label: 'WedgeNumber (300A00D2)',
		code: 'DICOMObject["300A00D2"]',
	},
	{
		label: 'WedgeType (300A00D3)',
		code: 'DICOMObject["300A00D3"]',
	},
	{
		label: 'WedgeID (300A00D4)',
		code: 'DICOMObject["300A00D4"]',
	},
	{
		label: 'WedgeAngle (300A00D5)',
		code: 'DICOMObject["300A00D5"]',
	},
	{
		label: 'WedgeFactor (300A00D6)',
		code: 'DICOMObject["300A00D6"]',
	},
	{
		label: 'TotalWedgeTrayWaterEquivalentThickness (300A00D7)',
		code: 'DICOMObject["300A00D7"]',
	},
	{
		label: 'WedgeOrientation (300A00D8)',
		code: 'DICOMObject["300A00D8"]',
	},
	{
		label: 'IsocenterToWedgeTrayDistance (300A00D9)',
		code: 'DICOMObject["300A00D9"]',
	},
	{
		label: 'SourceToWedgeTrayDistance (300A00DA)',
		code: 'DICOMObject["300A00DA"]',
	},
	{
		label: 'WedgeThinEdgePosition (300A00DB)',
		code: 'DICOMObject["300A00DB"]',
	},
	{
		label: 'BolusID (300A00DC)',
		code: 'DICOMObject["300A00DC"]',
	},
	{
		label: 'BolusDescription (300A00DD)',
		code: 'DICOMObject["300A00DD"]',
	},
	{
		label: 'EffectiveWedgeAngle (300A00DE)',
		code: 'DICOMObject["300A00DE"]',
	},
	{
		label: 'NumberOfCompensators (300A00E0)',
		code: 'DICOMObject["300A00E0"]',
	},
	{
		label: 'MaterialID (300A00E1)',
		code: 'DICOMObject["300A00E1"]',
	},
	{
		label: 'TotalCompensatorTrayFactor (300A00E2)',
		code: 'DICOMObject["300A00E2"]',
	},
	{
		label: 'CompensatorSequence (300A00E3)',
		code: 'DICOMObject["300A00E3"]',
	},
	{
		label: 'CompensatorNumber (300A00E4)',
		code: 'DICOMObject["300A00E4"]',
	},
	{
		label: 'CompensatorID (300A00E5)',
		code: 'DICOMObject["300A00E5"]',
	},
	{
		label: 'SourceToCompensatorTrayDistance (300A00E6)',
		code: 'DICOMObject["300A00E6"]',
	},
	{
		label: 'CompensatorRows (300A00E7)',
		code: 'DICOMObject["300A00E7"]',
	},
	{
		label: 'CompensatorColumns (300A00E8)',
		code: 'DICOMObject["300A00E8"]',
	},
	{
		label: 'CompensatorPixelSpacing (300A00E9)',
		code: 'DICOMObject["300A00E9"]',
	},
	{
		label: 'CompensatorPosition (300A00EA)',
		code: 'DICOMObject["300A00EA"]',
	},
	{
		label: 'CompensatorTransmissionData (300A00EB)',
		code: 'DICOMObject["300A00EB"]',
	},
	{
		label: 'CompensatorThicknessData (300A00EC)',
		code: 'DICOMObject["300A00EC"]',
	},
	{
		label: 'NumberOfBoli (300A00ED)',
		code: 'DICOMObject["300A00ED"]',
	},
	{
		label: 'CompensatorType (300A00EE)',
		code: 'DICOMObject["300A00EE"]',
	},
	{
		label: 'CompensatorTrayID (300A00EF)',
		code: 'DICOMObject["300A00EF"]',
	},
	{
		label: 'NumberOfBlocks (300A00F0)',
		code: 'DICOMObject["300A00F0"]',
	},
	{
		label: 'TotalBlockTrayFactor (300A00F2)',
		code: 'DICOMObject["300A00F2"]',
	},
	{
		label: 'TotalBlockTrayWaterEquivalentThickness (300A00F3)',
		code: 'DICOMObject["300A00F3"]',
	},
	{
		label: 'BlockSequence (300A00F4)',
		code: 'DICOMObject["300A00F4"]',
	},
	{
		label: 'BlockTrayID (300A00F5)',
		code: 'DICOMObject["300A00F5"]',
	},
	{
		label: 'SourceToBlockTrayDistance (300A00F6)',
		code: 'DICOMObject["300A00F6"]',
	},
	{
		label: 'IsocenterToBlockTrayDistance (300A00F7)',
		code: 'DICOMObject["300A00F7"]',
	},
	{
		label: 'BlockType (300A00F8)',
		code: 'DICOMObject["300A00F8"]',
	},
	{
		label: 'AccessoryCode (300A00F9)',
		code: 'DICOMObject["300A00F9"]',
	},
	{
		label: 'BlockDivergence (300A00FA)',
		code: 'DICOMObject["300A00FA"]',
	},
	{
		label: 'BlockMountingPosition (300A00FB)',
		code: 'DICOMObject["300A00FB"]',
	},
	{
		label: 'BlockNumber (300A00FC)',
		code: 'DICOMObject["300A00FC"]',
	},
	{
		label: 'BlockName (300A00FE)',
		code: 'DICOMObject["300A00FE"]',
	},
	{
		label: 'BlockThickness (300A0100)',
		code: 'DICOMObject["300A0100"]',
	},
	{
		label: 'BlockTransmission (300A0102)',
		code: 'DICOMObject["300A0102"]',
	},
	{
		label: 'BlockNumberOfPoints (300A0104)',
		code: 'DICOMObject["300A0104"]',
	},
	{
		label: 'BlockData (300A0106)',
		code: 'DICOMObject["300A0106"]',
	},
	{
		label: 'ApplicatorSequence (300A0107)',
		code: 'DICOMObject["300A0107"]',
	},
	{
		label: 'ApplicatorID (300A0108)',
		code: 'DICOMObject["300A0108"]',
	},
	{
		label: 'ApplicatorType (300A0109)',
		code: 'DICOMObject["300A0109"]',
	},
	{
		label: 'ApplicatorDescription (300A010A)',
		code: 'DICOMObject["300A010A"]',
	},
	{
		label: 'CumulativeDoseReferenceCoefficient (300A010C)',
		code: 'DICOMObject["300A010C"]',
	},
	{
		label: 'FinalCumulativeMetersetWeight (300A010E)',
		code: 'DICOMObject["300A010E"]',
	},
	{
		label: 'NumberOfControlPoints (300A0110)',
		code: 'DICOMObject["300A0110"]',
	},
	{
		label: 'ControlPointSequence (300A0111)',
		code: 'DICOMObject["300A0111"]',
	},
	{
		label: 'ControlPointIndex (300A0112)',
		code: 'DICOMObject["300A0112"]',
	},
	{
		label: 'NominalBeamEnergy (300A0114)',
		code: 'DICOMObject["300A0114"]',
	},
	{
		label: 'DoseRateSet (300A0115)',
		code: 'DICOMObject["300A0115"]',
	},
	{
		label: 'WedgePositionSequence (300A0116)',
		code: 'DICOMObject["300A0116"]',
	},
	{
		label: 'WedgePosition (300A0118)',
		code: 'DICOMObject["300A0118"]',
	},
	{
		label: 'BeamLimitingDevicePositionSequence (300A011A)',
		code: 'DICOMObject["300A011A"]',
	},
	{
		label: 'LeafJawPositions (300A011C)',
		code: 'DICOMObject["300A011C"]',
	},
	{
		label: 'GantryAngle (300A011E)',
		code: 'DICOMObject["300A011E"]',
	},
	{
		label: 'GantryRotationDirection (300A011F)',
		code: 'DICOMObject["300A011F"]',
	},
	{
		label: 'BeamLimitingDeviceAngle (300A0120)',
		code: 'DICOMObject["300A0120"]',
	},
	{
		label: 'BeamLimitingDeviceRotationDirection (300A0121)',
		code: 'DICOMObject["300A0121"]',
	},
	{
		label: 'PatientSupportAngle (300A0122)',
		code: 'DICOMObject["300A0122"]',
	},
	{
		label: 'PatientSupportRotationDirection (300A0123)',
		code: 'DICOMObject["300A0123"]',
	},
	{
		label: 'TableTopEccentricAxisDistance (300A0124)',
		code: 'DICOMObject["300A0124"]',
	},
	{
		label: 'TableTopEccentricAngle (300A0125)',
		code: 'DICOMObject["300A0125"]',
	},
	{
		label: 'TableTopEccentricRotationDirection (300A0126)',
		code: 'DICOMObject["300A0126"]',
	},
	{
		label: 'TableTopVerticalPosition (300A0128)',
		code: 'DICOMObject["300A0128"]',
	},
	{
		label: 'TableTopLongitudinalPosition (300A0129)',
		code: 'DICOMObject["300A0129"]',
	},
	{
		label: 'TableTopLateralPosition (300A012A)',
		code: 'DICOMObject["300A012A"]',
	},
	{
		label: 'IsocenterPosition (300A012C)',
		code: 'DICOMObject["300A012C"]',
	},
	{
		label: 'SurfaceEntryPoint (300A012E)',
		code: 'DICOMObject["300A012E"]',
	},
	{
		label: 'SourceToSurfaceDistance (300A0130)',
		code: 'DICOMObject["300A0130"]',
	},
	{
		label: 'AverageBeamDosePointSourceToExternalContourDistance (300A0131)',
		code: 'DICOMObject["300A0131"]',
	},
	{
		label: 'SourceToExternalContourDistance (300A0132)',
		code: 'DICOMObject["300A0132"]',
	},
	{
		label: 'ExternalContourEntryPoint (300A0133)',
		code: 'DICOMObject["300A0133"]',
	},
	{
		label: 'CumulativeMetersetWeight (300A0134)',
		code: 'DICOMObject["300A0134"]',
	},
	{
		label: 'TableTopPitchAngle (300A0140)',
		code: 'DICOMObject["300A0140"]',
	},
	{
		label: 'TableTopPitchRotationDirection (300A0142)',
		code: 'DICOMObject["300A0142"]',
	},
	{
		label: 'TableTopRollAngle (300A0144)',
		code: 'DICOMObject["300A0144"]',
	},
	{
		label: 'TableTopRollRotationDirection (300A0146)',
		code: 'DICOMObject["300A0146"]',
	},
	{
		label: 'HeadFixationAngle (300A0148)',
		code: 'DICOMObject["300A0148"]',
	},
	{
		label: 'GantryPitchAngle (300A014A)',
		code: 'DICOMObject["300A014A"]',
	},
	{
		label: 'GantryPitchRotationDirection (300A014C)',
		code: 'DICOMObject["300A014C"]',
	},
	{
		label: 'GantryPitchAngleTolerance (300A014E)',
		code: 'DICOMObject["300A014E"]',
	},
	{
		label: 'FixationEye (300A0150)',
		code: 'DICOMObject["300A0150"]',
	},
	{
		label: 'ChairHeadFramePosition (300A0151)',
		code: 'DICOMObject["300A0151"]',
	},
	{
		label: 'HeadFixationAngleTolerance (300A0152)',
		code: 'DICOMObject["300A0152"]',
	},
	{
		label: 'ChairHeadFramePositionTolerance (300A0153)',
		code: 'DICOMObject["300A0153"]',
	},
	{
		label: 'FixationLightAzimuthalAngleTolerance (300A0154)',
		code: 'DICOMObject["300A0154"]',
	},
	{
		label: 'FixationLightPolarAngleTolerance (300A0155)',
		code: 'DICOMObject["300A0155"]',
	},
	{
		label: 'PatientSetupSequence (300A0180)',
		code: 'DICOMObject["300A0180"]',
	},
	{
		label: 'PatientSetupNumber (300A0182)',
		code: 'DICOMObject["300A0182"]',
	},
	{
		label: 'PatientSetupLabel (300A0183)',
		code: 'DICOMObject["300A0183"]',
	},
	{
		label: 'PatientAdditionalPosition (300A0184)',
		code: 'DICOMObject["300A0184"]',
	},
	{
		label: 'FixationDeviceSequence (300A0190)',
		code: 'DICOMObject["300A0190"]',
	},
	{
		label: 'FixationDeviceType (300A0192)',
		code: 'DICOMObject["300A0192"]',
	},
	{
		label: 'FixationDeviceLabel (300A0194)',
		code: 'DICOMObject["300A0194"]',
	},
	{
		label: 'FixationDeviceDescription (300A0196)',
		code: 'DICOMObject["300A0196"]',
	},
	{
		label: 'FixationDevicePosition (300A0198)',
		code: 'DICOMObject["300A0198"]',
	},
	{
		label: 'FixationDevicePitchAngle (300A0199)',
		code: 'DICOMObject["300A0199"]',
	},
	{
		label: 'FixationDeviceRollAngle (300A019A)',
		code: 'DICOMObject["300A019A"]',
	},
	{
		label: 'ShieldingDeviceSequence (300A01A0)',
		code: 'DICOMObject["300A01A0"]',
	},
	{
		label: 'ShieldingDeviceType (300A01A2)',
		code: 'DICOMObject["300A01A2"]',
	},
	{
		label: 'ShieldingDeviceLabel (300A01A4)',
		code: 'DICOMObject["300A01A4"]',
	},
	{
		label: 'ShieldingDeviceDescription (300A01A6)',
		code: 'DICOMObject["300A01A6"]',
	},
	{
		label: 'ShieldingDevicePosition (300A01A8)',
		code: 'DICOMObject["300A01A8"]',
	},
	{
		label: 'SetupTechnique (300A01B0)',
		code: 'DICOMObject["300A01B0"]',
	},
	{
		label: 'SetupTechniqueDescription (300A01B2)',
		code: 'DICOMObject["300A01B2"]',
	},
	{
		label: 'SetupDeviceSequence (300A01B4)',
		code: 'DICOMObject["300A01B4"]',
	},
	{
		label: 'SetupDeviceType (300A01B6)',
		code: 'DICOMObject["300A01B6"]',
	},
	{
		label: 'SetupDeviceLabel (300A01B8)',
		code: 'DICOMObject["300A01B8"]',
	},
	{
		label: 'SetupDeviceDescription (300A01BA)',
		code: 'DICOMObject["300A01BA"]',
	},
	{
		label: 'SetupDeviceParameter (300A01BC)',
		code: 'DICOMObject["300A01BC"]',
	},
	{
		label: 'SetupReferenceDescription (300A01D0)',
		code: 'DICOMObject["300A01D0"]',
	},
	{
		label: 'TableTopVerticalSetupDisplacement (300A01D2)',
		code: 'DICOMObject["300A01D2"]',
	},
	{
		label: 'TableTopLongitudinalSetupDisplacement (300A01D4)',
		code: 'DICOMObject["300A01D4"]',
	},
	{
		label: 'TableTopLateralSetupDisplacement (300A01D6)',
		code: 'DICOMObject["300A01D6"]',
	},
	{
		label: 'BrachyTreatmentTechnique (300A0200)',
		code: 'DICOMObject["300A0200"]',
	},
	{
		label: 'BrachyTreatmentType (300A0202)',
		code: 'DICOMObject["300A0202"]',
	},
	{
		label: 'TreatmentMachineSequence (300A0206)',
		code: 'DICOMObject["300A0206"]',
	},
	{
		label: 'SourceSequence (300A0210)',
		code: 'DICOMObject["300A0210"]',
	},
	{
		label: 'SourceNumber (300A0212)',
		code: 'DICOMObject["300A0212"]',
	},
	{
		label: 'SourceType (300A0214)',
		code: 'DICOMObject["300A0214"]',
	},
	{
		label: 'SourceManufacturer (300A0216)',
		code: 'DICOMObject["300A0216"]',
	},
	{
		label: 'ActiveSourceDiameter (300A0218)',
		code: 'DICOMObject["300A0218"]',
	},
	{
		label: 'ActiveSourceLength (300A021A)',
		code: 'DICOMObject["300A021A"]',
	},
	{
		label: 'SourceModelID (300A021B)',
		code: 'DICOMObject["300A021B"]',
	},
	{
		label: 'SourceDescription (300A021C)',
		code: 'DICOMObject["300A021C"]',
	},
	{
		label: 'SourceEncapsulationNominalThickness (300A0222)',
		code: 'DICOMObject["300A0222"]',
	},
	{
		label: 'SourceEncapsulationNominalTransmission (300A0224)',
		code: 'DICOMObject["300A0224"]',
	},
	{
		label: 'SourceIsotopeName (300A0226)',
		code: 'DICOMObject["300A0226"]',
	},
	{
		label: 'SourceIsotopeHalfLife (300A0228)',
		code: 'DICOMObject["300A0228"]',
	},
	{
		label: 'SourceStrengthUnits (300A0229)',
		code: 'DICOMObject["300A0229"]',
	},
	{
		label: 'ReferenceAirKermaRate (300A022A)',
		code: 'DICOMObject["300A022A"]',
	},
	{
		label: 'SourceStrength (300A022B)',
		code: 'DICOMObject["300A022B"]',
	},
	{
		label: 'SourceStrengthReferenceDate (300A022C)',
		code: 'DICOMObject["300A022C"]',
	},
	{
		label: 'SourceStrengthReferenceTime (300A022E)',
		code: 'DICOMObject["300A022E"]',
	},
	{
		label: 'ApplicationSetupSequence (300A0230)',
		code: 'DICOMObject["300A0230"]',
	},
	{
		label: 'ApplicationSetupType (300A0232)',
		code: 'DICOMObject["300A0232"]',
	},
	{
		label: 'ApplicationSetupNumber (300A0234)',
		code: 'DICOMObject["300A0234"]',
	},
	{
		label: 'ApplicationSetupName (300A0236)',
		code: 'DICOMObject["300A0236"]',
	},
	{
		label: 'ApplicationSetupManufacturer (300A0238)',
		code: 'DICOMObject["300A0238"]',
	},
	{
		label: 'TemplateNumber (300A0240)',
		code: 'DICOMObject["300A0240"]',
	},
	{
		label: 'TemplateType (300A0242)',
		code: 'DICOMObject["300A0242"]',
	},
	{
		label: 'TemplateName (300A0244)',
		code: 'DICOMObject["300A0244"]',
	},
	{
		label: 'TotalReferenceAirKerma (300A0250)',
		code: 'DICOMObject["300A0250"]',
	},
	{
		label: 'BrachyAccessoryDeviceSequence (300A0260)',
		code: 'DICOMObject["300A0260"]',
	},
	{
		label: 'BrachyAccessoryDeviceNumber (300A0262)',
		code: 'DICOMObject["300A0262"]',
	},
	{
		label: 'BrachyAccessoryDeviceID (300A0263)',
		code: 'DICOMObject["300A0263"]',
	},
	{
		label: 'BrachyAccessoryDeviceType (300A0264)',
		code: 'DICOMObject["300A0264"]',
	},
	{
		label: 'BrachyAccessoryDeviceName (300A0266)',
		code: 'DICOMObject["300A0266"]',
	},
	{
		label: 'BrachyAccessoryDeviceNominalThickness (300A026A)',
		code: 'DICOMObject["300A026A"]',
	},
	{
		label: 'BrachyAccessoryDeviceNominalTransmission (300A026C)',
		code: 'DICOMObject["300A026C"]',
	},
	{
		label: 'ChannelSequence (300A0280)',
		code: 'DICOMObject["300A0280"]',
	},
	{
		label: 'ChannelNumber (300A0282)',
		code: 'DICOMObject["300A0282"]',
	},
	{
		label: 'ChannelLength (300A0284)',
		code: 'DICOMObject["300A0284"]',
	},
	{
		label: 'ChannelTotalTime (300A0286)',
		code: 'DICOMObject["300A0286"]',
	},
	{
		label: 'SourceMovementType (300A0288)',
		code: 'DICOMObject["300A0288"]',
	},
	{
		label: 'NumberOfPulses (300A028A)',
		code: 'DICOMObject["300A028A"]',
	},
	{
		label: 'PulseRepetitionInterval (300A028C)',
		code: 'DICOMObject["300A028C"]',
	},
	{
		label: 'SourceApplicatorNumber (300A0290)',
		code: 'DICOMObject["300A0290"]',
	},
	{
		label: 'SourceApplicatorID (300A0291)',
		code: 'DICOMObject["300A0291"]',
	},
	{
		label: 'SourceApplicatorType (300A0292)',
		code: 'DICOMObject["300A0292"]',
	},
	{
		label: 'SourceApplicatorName (300A0294)',
		code: 'DICOMObject["300A0294"]',
	},
	{
		label: 'SourceApplicatorLength (300A0296)',
		code: 'DICOMObject["300A0296"]',
	},
	{
		label: 'SourceApplicatorManufacturer (300A0298)',
		code: 'DICOMObject["300A0298"]',
	},
	{
		label: 'SourceApplicatorWallNominalThickness (300A029C)',
		code: 'DICOMObject["300A029C"]',
	},
	{
		label: 'SourceApplicatorWallNominalTransmission (300A029E)',
		code: 'DICOMObject["300A029E"]',
	},
	{
		label: 'SourceApplicatorStepSize (300A02A0)',
		code: 'DICOMObject["300A02A0"]',
	},
	{
		label: 'TransferTubeNumber (300A02A2)',
		code: 'DICOMObject["300A02A2"]',
	},
	{
		label: 'TransferTubeLength (300A02A4)',
		code: 'DICOMObject["300A02A4"]',
	},
	{
		label: 'ChannelShieldSequence (300A02B0)',
		code: 'DICOMObject["300A02B0"]',
	},
	{
		label: 'ChannelShieldNumber (300A02B2)',
		code: 'DICOMObject["300A02B2"]',
	},
	{
		label: 'ChannelShieldID (300A02B3)',
		code: 'DICOMObject["300A02B3"]',
	},
	{
		label: 'ChannelShieldName (300A02B4)',
		code: 'DICOMObject["300A02B4"]',
	},
	{
		label: 'ChannelShieldNominalThickness (300A02B8)',
		code: 'DICOMObject["300A02B8"]',
	},
	{
		label: 'ChannelShieldNominalTransmission (300A02BA)',
		code: 'DICOMObject["300A02BA"]',
	},
	{
		label: 'FinalCumulativeTimeWeight (300A02C8)',
		code: 'DICOMObject["300A02C8"]',
	},
	{
		label: 'BrachyControlPointSequence (300A02D0)',
		code: 'DICOMObject["300A02D0"]',
	},
	{
		label: 'ControlPointRelativePosition (300A02D2)',
		code: 'DICOMObject["300A02D2"]',
	},
	{
		label: 'ControlPoint3DPosition (300A02D4)',
		code: 'DICOMObject["300A02D4"]',
	},
	{
		label: 'CumulativeTimeWeight (300A02D6)',
		code: 'DICOMObject["300A02D6"]',
	},
	{
		label: 'CompensatorDivergence (300A02E0)',
		code: 'DICOMObject["300A02E0"]',
	},
	{
		label: 'CompensatorMountingPosition (300A02E1)',
		code: 'DICOMObject["300A02E1"]',
	},
	{
		label: 'SourceToCompensatorDistance (300A02E2)',
		code: 'DICOMObject["300A02E2"]',
	},
	{
		label: 'TotalCompensatorTrayWaterEquivalentThickness (300A02E3)',
		code: 'DICOMObject["300A02E3"]',
	},
	{
		label: 'IsocenterToCompensatorTrayDistance (300A02E4)',
		code: 'DICOMObject["300A02E4"]',
	},
	{
		label: 'CompensatorColumnOffset (300A02E5)',
		code: 'DICOMObject["300A02E5"]',
	},
	{
		label: 'IsocenterToCompensatorDistances (300A02E6)',
		code: 'DICOMObject["300A02E6"]',
	},
	{
		label: 'CompensatorRelativeStoppingPowerRatio (300A02E7)',
		code: 'DICOMObject["300A02E7"]',
	},
	{
		label: 'CompensatorMillingToolDiameter (300A02E8)',
		code: 'DICOMObject["300A02E8"]',
	},
	{
		label: 'IonRangeCompensatorSequence (300A02EA)',
		code: 'DICOMObject["300A02EA"]',
	},
	{
		label: 'CompensatorDescription (300A02EB)',
		code: 'DICOMObject["300A02EB"]',
	},
	{
		label: 'RadiationMassNumber (300A0302)',
		code: 'DICOMObject["300A0302"]',
	},
	{
		label: 'RadiationAtomicNumber (300A0304)',
		code: 'DICOMObject["300A0304"]',
	},
	{
		label: 'RadiationChargeState (300A0306)',
		code: 'DICOMObject["300A0306"]',
	},
	{
		label: 'ScanMode (300A0308)',
		code: 'DICOMObject["300A0308"]',
	},
	{
		label: 'ModulatedScanModeType (300A0309)',
		code: 'DICOMObject["300A0309"]',
	},
	{
		label: 'VirtualSourceAxisDistances (300A030A)',
		code: 'DICOMObject["300A030A"]',
	},
	{
		label: 'SnoutSequence (300A030C)',
		code: 'DICOMObject["300A030C"]',
	},
	{
		label: 'SnoutPosition (300A030D)',
		code: 'DICOMObject["300A030D"]',
	},
	{
		label: 'SnoutID (300A030F)',
		code: 'DICOMObject["300A030F"]',
	},
	{
		label: 'NumberOfRangeShifters (300A0312)',
		code: 'DICOMObject["300A0312"]',
	},
	{
		label: 'RangeShifterSequence (300A0314)',
		code: 'DICOMObject["300A0314"]',
	},
	{
		label: 'RangeShifterNumber (300A0316)',
		code: 'DICOMObject["300A0316"]',
	},
	{
		label: 'RangeShifterID (300A0318)',
		code: 'DICOMObject["300A0318"]',
	},
	{
		label: 'RangeShifterType (300A0320)',
		code: 'DICOMObject["300A0320"]',
	},
	{
		label: 'RangeShifterDescription (300A0322)',
		code: 'DICOMObject["300A0322"]',
	},
	{
		label: 'NumberOfLateralSpreadingDevices (300A0330)',
		code: 'DICOMObject["300A0330"]',
	},
	{
		label: 'LateralSpreadingDeviceSequence (300A0332)',
		code: 'DICOMObject["300A0332"]',
	},
	{
		label: 'LateralSpreadingDeviceNumber (300A0334)',
		code: 'DICOMObject["300A0334"]',
	},
	{
		label: 'LateralSpreadingDeviceID (300A0336)',
		code: 'DICOMObject["300A0336"]',
	},
	{
		label: 'LateralSpreadingDeviceType (300A0338)',
		code: 'DICOMObject["300A0338"]',
	},
	{
		label: 'LateralSpreadingDeviceDescription (300A033A)',
		code: 'DICOMObject["300A033A"]',
	},
	{
		label: 'LateralSpreadingDeviceWaterEquivalentThickness (300A033C)',
		code: 'DICOMObject["300A033C"]',
	},
	{
		label: 'NumberOfRangeModulators (300A0340)',
		code: 'DICOMObject["300A0340"]',
	},
	{
		label: 'RangeModulatorSequence (300A0342)',
		code: 'DICOMObject["300A0342"]',
	},
	{
		label: 'RangeModulatorNumber (300A0344)',
		code: 'DICOMObject["300A0344"]',
	},
	{
		label: 'RangeModulatorID (300A0346)',
		code: 'DICOMObject["300A0346"]',
	},
	{
		label: 'RangeModulatorType (300A0348)',
		code: 'DICOMObject["300A0348"]',
	},
	{
		label: 'RangeModulatorDescription (300A034A)',
		code: 'DICOMObject["300A034A"]',
	},
	{
		label: 'BeamCurrentModulationID (300A034C)',
		code: 'DICOMObject["300A034C"]',
	},
	{
		label: 'PatientSupportType (300A0350)',
		code: 'DICOMObject["300A0350"]',
	},
	{
		label: 'PatientSupportID (300A0352)',
		code: 'DICOMObject["300A0352"]',
	},
	{
		label: 'PatientSupportAccessoryCode (300A0354)',
		code: 'DICOMObject["300A0354"]',
	},
	{
		label: 'TrayAccessoryCode (300A0355)',
		code: 'DICOMObject["300A0355"]',
	},
	{
		label: 'FixationLightAzimuthalAngle (300A0356)',
		code: 'DICOMObject["300A0356"]',
	},
	{
		label: 'FixationLightPolarAngle (300A0358)',
		code: 'DICOMObject["300A0358"]',
	},
	{
		label: 'MetersetRate (300A035A)',
		code: 'DICOMObject["300A035A"]',
	},
	{
		label: 'RangeShifterSettingsSequence (300A0360)',
		code: 'DICOMObject["300A0360"]',
	},
	{
		label: 'RangeShifterSetting (300A0362)',
		code: 'DICOMObject["300A0362"]',
	},
	{
		label: 'IsocenterToRangeShifterDistance (300A0364)',
		code: 'DICOMObject["300A0364"]',
	},
	{
		label: 'RangeShifterWaterEquivalentThickness (300A0366)',
		code: 'DICOMObject["300A0366"]',
	},
	{
		label: 'LateralSpreadingDeviceSettingsSequence (300A0370)',
		code: 'DICOMObject["300A0370"]',
	},
	{
		label: 'LateralSpreadingDeviceSetting (300A0372)',
		code: 'DICOMObject["300A0372"]',
	},
	{
		label: 'IsocenterToLateralSpreadingDeviceDistance (300A0374)',
		code: 'DICOMObject["300A0374"]',
	},
	{
		label: 'RangeModulatorSettingsSequence (300A0380)',
		code: 'DICOMObject["300A0380"]',
	},
	{
		label: 'RangeModulatorGatingStartValue (300A0382)',
		code: 'DICOMObject["300A0382"]',
	},
	{
		label: 'RangeModulatorGatingStopValue (300A0384)',
		code: 'DICOMObject["300A0384"]',
	},
	{
		label: 'RangeModulatorGatingStartWaterEquivalentThickness (300A0386)',
		code: 'DICOMObject["300A0386"]',
	},
	{
		label: 'RangeModulatorGatingStopWaterEquivalentThickness (300A0388)',
		code: 'DICOMObject["300A0388"]',
	},
	{
		label: 'IsocenterToRangeModulatorDistance (300A038A)',
		code: 'DICOMObject["300A038A"]',
	},
	{
		label: 'ScanSpotTimeOffset (300A038F)',
		code: 'DICOMObject["300A038F"]',
	},
	{
		label: 'ScanSpotTuneID (300A0390)',
		code: 'DICOMObject["300A0390"]',
	},
	{
		label: 'ScanSpotPrescribedIndices (300A0391)',
		code: 'DICOMObject["300A0391"]',
	},
	{
		label: 'NumberOfScanSpotPositions (300A0392)',
		code: 'DICOMObject["300A0392"]',
	},
	{
		label: 'ScanSpotReordered (300A0393)',
		code: 'DICOMObject["300A0393"]',
	},
	{
		label: 'ScanSpotPositionMap (300A0394)',
		code: 'DICOMObject["300A0394"]',
	},
	{
		label: 'ScanSpotReorderingAllowed (300A0395)',
		code: 'DICOMObject["300A0395"]',
	},
	{
		label: 'ScanSpotMetersetWeights (300A0396)',
		code: 'DICOMObject["300A0396"]',
	},
	{
		label: 'ScanningSpotSize (300A0398)',
		code: 'DICOMObject["300A0398"]',
	},
	{
		label: 'NumberOfPaintings (300A039A)',
		code: 'DICOMObject["300A039A"]',
	},
	{
		label: 'IonToleranceTableSequence (300A03A0)',
		code: 'DICOMObject["300A03A0"]',
	},
	{
		label: 'IonBeamSequence (300A03A2)',
		code: 'DICOMObject["300A03A2"]',
	},
	{
		label: 'IonBeamLimitingDeviceSequence (300A03A4)',
		code: 'DICOMObject["300A03A4"]',
	},
	{
		label: 'IonBlockSequence (300A03A6)',
		code: 'DICOMObject["300A03A6"]',
	},
	{
		label: 'IonControlPointSequence (300A03A8)',
		code: 'DICOMObject["300A03A8"]',
	},
	{
		label: 'IonWedgeSequence (300A03AA)',
		code: 'DICOMObject["300A03AA"]',
	},
	{
		label: 'IonWedgePositionSequence (300A03AC)',
		code: 'DICOMObject["300A03AC"]',
	},
	{
		label: 'ReferencedSetupImageSequence (300A0401)',
		code: 'DICOMObject["300A0401"]',
	},
	{
		label: 'SetupImageComment (300A0402)',
		code: 'DICOMObject["300A0402"]',
	},
	{
		label: 'MotionSynchronizationSequence (300A0410)',
		code: 'DICOMObject["300A0410"]',
	},
	{
		label: 'ControlPointOrientation (300A0412)',
		code: 'DICOMObject["300A0412"]',
	},
	{
		label: 'GeneralAccessorySequence (300A0420)',
		code: 'DICOMObject["300A0420"]',
	},
	{
		label: 'GeneralAccessoryID (300A0421)',
		code: 'DICOMObject["300A0421"]',
	},
	{
		label: 'GeneralAccessoryDescription (300A0422)',
		code: 'DICOMObject["300A0422"]',
	},
	{
		label: 'GeneralAccessoryType (300A0423)',
		code: 'DICOMObject["300A0423"]',
	},
	{
		label: 'GeneralAccessoryNumber (300A0424)',
		code: 'DICOMObject["300A0424"]',
	},
	{
		label: 'SourceToGeneralAccessoryDistance (300A0425)',
		code: 'DICOMObject["300A0425"]',
	},
	{
		label: 'ApplicatorGeometrySequence (300A0431)',
		code: 'DICOMObject["300A0431"]',
	},
	{
		label: 'ApplicatorApertureShape (300A0432)',
		code: 'DICOMObject["300A0432"]',
	},
	{
		label: 'ApplicatorOpening (300A0433)',
		code: 'DICOMObject["300A0433"]',
	},
	{
		label: 'ApplicatorOpeningX (300A0434)',
		code: 'DICOMObject["300A0434"]',
	},
	{
		label: 'ApplicatorOpeningY (300A0435)',
		code: 'DICOMObject["300A0435"]',
	},
	{
		label: 'SourceToApplicatorMountingPositionDistance (300A0436)',
		code: 'DICOMObject["300A0436"]',
	},
	{
		label: 'NumberOfBlockSlabItems (300A0440)',
		code: 'DICOMObject["300A0440"]',
	},
	{
		label: 'BlockSlabSequence (300A0441)',
		code: 'DICOMObject["300A0441"]',
	},
	{
		label: 'BlockSlabThickness (300A0442)',
		code: 'DICOMObject["300A0442"]',
	},
	{
		label: 'BlockSlabNumber (300A0443)',
		code: 'DICOMObject["300A0443"]',
	},
	{
		label: 'DeviceMotionControlSequence (300A0450)',
		code: 'DICOMObject["300A0450"]',
	},
	{
		label: 'DeviceMotionExecutionMode (300A0451)',
		code: 'DICOMObject["300A0451"]',
	},
	{
		label: 'DeviceMotionObservationMode (300A0452)',
		code: 'DICOMObject["300A0452"]',
	},
	{
		label: 'DeviceMotionParameterCodeSequence (300A0453)',
		code: 'DICOMObject["300A0453"]',
	},
	{
		label: 'DistalDepthFraction (300A0501)',
		code: 'DICOMObject["300A0501"]',
	},
	{
		label: 'DistalDepth (300A0502)',
		code: 'DICOMObject["300A0502"]',
	},
	{
		label: 'NominalRangeModulationFractions (300A0503)',
		code: 'DICOMObject["300A0503"]',
	},
	{
		label: 'NominalRangeModulatedRegionDepths (300A0504)',
		code: 'DICOMObject["300A0504"]',
	},
	{
		label: 'DepthDoseParametersSequence (300A0505)',
		code: 'DICOMObject["300A0505"]',
	},
	{
		label: 'DeliveredDepthDoseParametersSequence (300A0506)',
		code: 'DICOMObject["300A0506"]',
	},
	{
		label: 'DeliveredDistalDepthFraction (300A0507)',
		code: 'DICOMObject["300A0507"]',
	},
	{
		label: 'DeliveredDistalDepth (300A0508)',
		code: 'DICOMObject["300A0508"]',
	},
	{
		label: 'DeliveredNominalRangeModulationFractions (300A0509)',
		code: 'DICOMObject["300A0509"]',
	},
	{
		label: 'DeliveredNominalRangeModulatedRegionDepths (300A0510)',
		code: 'DICOMObject["300A0510"]',
	},
	{
		label: 'DeliveredReferenceDoseDefinition (300A0511)',
		code: 'DICOMObject["300A0511"]',
	},
	{
		label: 'ReferenceDoseDefinition (300A0512)',
		code: 'DICOMObject["300A0512"]',
	},
	{
		label: 'ReferencedRTPlanSequence (300C0002)',
		code: 'DICOMObject["300C0002"]',
	},
	{
		label: 'ReferencedBeamSequence (300C0004)',
		code: 'DICOMObject["300C0004"]',
	},
	{
		label: 'ReferencedBeamNumber (300C0006)',
		code: 'DICOMObject["300C0006"]',
	},
	{
		label: 'ReferencedReferenceImageNumber (300C0007)',
		code: 'DICOMObject["300C0007"]',
	},
	{
		label: 'StartCumulativeMetersetWeight (300C0008)',
		code: 'DICOMObject["300C0008"]',
	},
	{
		label: 'EndCumulativeMetersetWeight (300C0009)',
		code: 'DICOMObject["300C0009"]',
	},
	{
		label: 'ReferencedBrachyApplicationSetupSequence (300C000A)',
		code: 'DICOMObject["300C000A"]',
	},
	{
		label: 'ReferencedBrachyApplicationSetupNumber (300C000C)',
		code: 'DICOMObject["300C000C"]',
	},
	{
		label: 'ReferencedSourceNumber (300C000E)',
		code: 'DICOMObject["300C000E"]',
	},
	{
		label: 'ReferencedFractionGroupSequence (300C0020)',
		code: 'DICOMObject["300C0020"]',
	},
	{
		label: 'ReferencedFractionGroupNumber (300C0022)',
		code: 'DICOMObject["300C0022"]',
	},
	{
		label: 'ReferencedVerificationImageSequence (300C0040)',
		code: 'DICOMObject["300C0040"]',
	},
	{
		label: 'ReferencedReferenceImageSequence (300C0042)',
		code: 'DICOMObject["300C0042"]',
	},
	{
		label: 'ReferencedDoseReferenceSequence (300C0050)',
		code: 'DICOMObject["300C0050"]',
	},
	{
		label: 'ReferencedDoseReferenceNumber (300C0051)',
		code: 'DICOMObject["300C0051"]',
	},
	{
		label: 'BrachyReferencedDoseReferenceSequence (300C0055)',
		code: 'DICOMObject["300C0055"]',
	},
	{
		label: 'ReferencedStructureSetSequence (300C0060)',
		code: 'DICOMObject["300C0060"]',
	},
	{
		label: 'ReferencedPatientSetupNumber (300C006A)',
		code: 'DICOMObject["300C006A"]',
	},
	{
		label: 'ReferencedDoseSequence (300C0080)',
		code: 'DICOMObject["300C0080"]',
	},
	{
		label: 'ReferencedToleranceTableNumber (300C00A0)',
		code: 'DICOMObject["300C00A0"]',
	},
	{
		label: 'ReferencedBolusSequence (300C00B0)',
		code: 'DICOMObject["300C00B0"]',
	},
	{
		label: 'ReferencedWedgeNumber (300C00C0)',
		code: 'DICOMObject["300C00C0"]',
	},
	{
		label: 'ReferencedCompensatorNumber (300C00D0)',
		code: 'DICOMObject["300C00D0"]',
	},
	{
		label: 'ReferencedBlockNumber (300C00E0)',
		code: 'DICOMObject["300C00E0"]',
	},
	{
		label: 'ReferencedControlPointIndex (300C00F0)',
		code: 'DICOMObject["300C00F0"]',
	},
	{
		label: 'ReferencedControlPointSequence (300C00F2)',
		code: 'DICOMObject["300C00F2"]',
	},
	{
		label: 'ReferencedStartControlPointIndex (300C00F4)',
		code: 'DICOMObject["300C00F4"]',
	},
	{
		label: 'ReferencedStopControlPointIndex (300C00F6)',
		code: 'DICOMObject["300C00F6"]',
	},
	{
		label: 'ReferencedRangeShifterNumber (300C0100)',
		code: 'DICOMObject["300C0100"]',
	},
	{
		label: 'ReferencedLateralSpreadingDeviceNumber (300C0102)',
		code: 'DICOMObject["300C0102"]',
	},
	{
		label: 'ReferencedRangeModulatorNumber (300C0104)',
		code: 'DICOMObject["300C0104"]',
	},
	{
		label: 'OmittedBeamTaskSequence (300C0111)',
		code: 'DICOMObject["300C0111"]',
	},
	{
		label: 'ReasonForOmission (300C0112)',
		code: 'DICOMObject["300C0112"]',
	},
	{
		label: 'ReasonForOmissionDescription (300C0113)',
		code: 'DICOMObject["300C0113"]',
	},
	{
		label: 'ApprovalStatus (300E0002)',
		code: 'DICOMObject["300E0002"]',
	},
	{
		label: 'ReviewDate (300E0004)',
		code: 'DICOMObject["300E0004"]',
	},
	{
		label: 'ReviewTime (300E0005)',
		code: 'DICOMObject["300E0005"]',
	},
	{
		label: 'ReviewerName (300E0008)',
		code: 'DICOMObject["300E0008"]',
	},
	{
		label: 'LowEnergyDetectors (40100001)',
		code: 'DICOMObject["40100001"]',
	},
	{
		label: 'HighEnergyDetectors (40100002)',
		code: 'DICOMObject["40100002"]',
	},
	{
		label: 'DetectorGeometrySequence (40100004)',
		code: 'DICOMObject["40100004"]',
	},
	{
		label: 'ThreatROIVoxelSequence (40101001)',
		code: 'DICOMObject["40101001"]',
	},
	{
		label: 'ThreatROIBase (40101004)',
		code: 'DICOMObject["40101004"]',
	},
	{
		label: 'ThreatROIExtents (40101005)',
		code: 'DICOMObject["40101005"]',
	},
	{
		label: 'ThreatROIBitmap (40101006)',
		code: 'DICOMObject["40101006"]',
	},
	{
		label: 'RouteSegmentID (40101007)',
		code: 'DICOMObject["40101007"]',
	},
	{
		label: 'GantryType (40101008)',
		code: 'DICOMObject["40101008"]',
	},
	{
		label: 'OOIOwnerType (40101009)',
		code: 'DICOMObject["40101009"]',
	},
	{
		label: 'RouteSegmentSequence (4010100A)',
		code: 'DICOMObject["4010100A"]',
	},
	{
		label: 'PotentialThreatObjectID (40101010)',
		code: 'DICOMObject["40101010"]',
	},
	{
		label: 'ThreatSequence (40101011)',
		code: 'DICOMObject["40101011"]',
	},
	{
		label: 'ThreatCategory (40101012)',
		code: 'DICOMObject["40101012"]',
	},
	{
		label: 'ThreatCategoryDescription (40101013)',
		code: 'DICOMObject["40101013"]',
	},
	{
		label: 'ATDAbilityAssessment (40101014)',
		code: 'DICOMObject["40101014"]',
	},
	{
		label: 'ATDAssessmentFlag (40101015)',
		code: 'DICOMObject["40101015"]',
	},
	{
		label: 'ATDAssessmentProbability (40101016)',
		code: 'DICOMObject["40101016"]',
	},
	{
		label: 'Mass (40101017)',
		code: 'DICOMObject["40101017"]',
	},
	{
		label: 'Density (40101018)',
		code: 'DICOMObject["40101018"]',
	},
	{
		label: 'ZEffective (40101019)',
		code: 'DICOMObject["40101019"]',
	},
	{
		label: 'BoardingPassID (4010101A)',
		code: 'DICOMObject["4010101A"]',
	},
	{
		label: 'CenterOfMass (4010101B)',
		code: 'DICOMObject["4010101B"]',
	},
	{
		label: 'CenterOfPTO (4010101C)',
		code: 'DICOMObject["4010101C"]',
	},
	{
		label: 'BoundingPolygon (4010101D)',
		code: 'DICOMObject["4010101D"]',
	},
	{
		label: 'RouteSegmentStartLocationID (4010101E)',
		code: 'DICOMObject["4010101E"]',
	},
	{
		label: 'RouteSegmentEndLocationID (4010101F)',
		code: 'DICOMObject["4010101F"]',
	},
	{
		label: 'RouteSegmentLocationIDType (40101020)',
		code: 'DICOMObject["40101020"]',
	},
	{
		label: 'AbortReason (40101021)',
		code: 'DICOMObject["40101021"]',
	},
	{
		label: 'VolumeOfPTO (40101023)',
		code: 'DICOMObject["40101023"]',
	},
	{
		label: 'AbortFlag (40101024)',
		code: 'DICOMObject["40101024"]',
	},
	{
		label: 'RouteSegmentStartTime (40101025)',
		code: 'DICOMObject["40101025"]',
	},
	{
		label: 'RouteSegmentEndTime (40101026)',
		code: 'DICOMObject["40101026"]',
	},
	{
		label: 'TDRType (40101027)',
		code: 'DICOMObject["40101027"]',
	},
	{
		label: 'InternationalRouteSegment (40101028)',
		code: 'DICOMObject["40101028"]',
	},
	{
		label: 'ThreatDetectionAlgorithmandVersion (40101029)',
		code: 'DICOMObject["40101029"]',
	},
	{
		label: 'AssignedLocation (4010102A)',
		code: 'DICOMObject["4010102A"]',
	},
	{
		label: 'AlarmDecisionTime (4010102B)',
		code: 'DICOMObject["4010102B"]',
	},
	{
		label: 'AlarmDecision (40101031)',
		code: 'DICOMObject["40101031"]',
	},
	{
		label: 'NumberOfTotalObjects (40101033)',
		code: 'DICOMObject["40101033"]',
	},
	{
		label: 'NumberOfAlarmObjects (40101034)',
		code: 'DICOMObject["40101034"]',
	},
	{
		label: 'PTORepresentationSequence (40101037)',
		code: 'DICOMObject["40101037"]',
	},
	{
		label: 'ATDAssessmentSequence (40101038)',
		code: 'DICOMObject["40101038"]',
	},
	{
		label: 'TIPType (40101039)',
		code: 'DICOMObject["40101039"]',
	},
	{
		label: 'DICOSVersion (4010103A)',
		code: 'DICOMObject["4010103A"]',
	},
	{
		label: 'OOIOwnerCreationTime (40101041)',
		code: 'DICOMObject["40101041"]',
	},
	{
		label: 'OOIType (40101042)',
		code: 'DICOMObject["40101042"]',
	},
	{
		label: 'OOISize (40101043)',
		code: 'DICOMObject["40101043"]',
	},
	{
		label: 'AcquisitionStatus (40101044)',
		code: 'DICOMObject["40101044"]',
	},
	{
		label: 'BasisMaterialsCodeSequence (40101045)',
		code: 'DICOMObject["40101045"]',
	},
	{
		label: 'PhantomType (40101046)',
		code: 'DICOMObject["40101046"]',
	},
	{
		label: 'OOIOwnerSequence (40101047)',
		code: 'DICOMObject["40101047"]',
	},
	{
		label: 'ScanType (40101048)',
		code: 'DICOMObject["40101048"]',
	},
	{
		label: 'ItineraryID (40101051)',
		code: 'DICOMObject["40101051"]',
	},
	{
		label: 'ItineraryIDType (40101052)',
		code: 'DICOMObject["40101052"]',
	},
	{
		label: 'ItineraryIDAssigningAuthority (40101053)',
		code: 'DICOMObject["40101053"]',
	},
	{
		label: 'RouteID (40101054)',
		code: 'DICOMObject["40101054"]',
	},
	{
		label: 'RouteIDAssigningAuthority (40101055)',
		code: 'DICOMObject["40101055"]',
	},
	{
		label: 'InboundArrivalType (40101056)',
		code: 'DICOMObject["40101056"]',
	},
	{
		label: 'CarrierID (40101058)',
		code: 'DICOMObject["40101058"]',
	},
	{
		label: 'CarrierIDAssigningAuthority (40101059)',
		code: 'DICOMObject["40101059"]',
	},
	{
		label: 'SourceOrientation (40101060)',
		code: 'DICOMObject["40101060"]',
	},
	{
		label: 'SourcePosition (40101061)',
		code: 'DICOMObject["40101061"]',
	},
	{
		label: 'BeltHeight (40101062)',
		code: 'DICOMObject["40101062"]',
	},
	{
		label: 'AlgorithmRoutingCodeSequence (40101064)',
		code: 'DICOMObject["40101064"]',
	},
	{
		label: 'TransportClassification (40101067)',
		code: 'DICOMObject["40101067"]',
	},
	{
		label: 'OOITypeDescriptor (40101068)',
		code: 'DICOMObject["40101068"]',
	},
	{
		label: 'TotalProcessingTime (40101069)',
		code: 'DICOMObject["40101069"]',
	},
	{
		label: 'DetectorCalibrationData (4010106C)',
		code: 'DICOMObject["4010106C"]',
	},
	{
		label: 'AdditionalScreeningPerformed (4010106D)',
		code: 'DICOMObject["4010106D"]',
	},
	{
		label: 'AdditionalInspectionSelectionCriteria (4010106E)',
		code: 'DICOMObject["4010106E"]',
	},
	{
		label: 'AdditionalInspectionMethodSequence (4010106F)',
		code: 'DICOMObject["4010106F"]',
	},
	{
		label: 'AITDeviceType (40101070)',
		code: 'DICOMObject["40101070"]',
	},
	{
		label: 'QRMeasurementsSequence (40101071)',
		code: 'DICOMObject["40101071"]',
	},
	{
		label: 'TargetMaterialSequence (40101072)',
		code: 'DICOMObject["40101072"]',
	},
	{
		label: 'SNRThreshold (40101073)',
		code: 'DICOMObject["40101073"]',
	},
	{
		label: 'ImageScaleRepresentation (40101075)',
		code: 'DICOMObject["40101075"]',
	},
	{
		label: 'ReferencedPTOSequence (40101076)',
		code: 'DICOMObject["40101076"]',
	},
	{
		label: 'ReferencedTDRInstanceSequence (40101077)',
		code: 'DICOMObject["40101077"]',
	},
	{
		label: 'PTOLocationDescription (40101078)',
		code: 'DICOMObject["40101078"]',
	},
	{
		label: 'AnomalyLocatorIndicatorSequence (40101079)',
		code: 'DICOMObject["40101079"]',
	},
	{
		label: 'AnomalyLocatorIndicator (4010107A)',
		code: 'DICOMObject["4010107A"]',
	},
	{
		label: 'PTORegionSequence (4010107B)',
		code: 'DICOMObject["4010107B"]',
	},
	{
		label: 'InspectionSelectionCriteria (4010107C)',
		code: 'DICOMObject["4010107C"]',
	},
	{
		label: 'SecondaryInspectionMethodSequence (4010107D)',
		code: 'DICOMObject["4010107D"]',
	},
	{
		label: 'PRCSToRCSOrientation (4010107E)',
		code: 'DICOMObject["4010107E"]',
	},
	{
		label: 'MACParametersSequence (4FFE0001)',
		code: 'DICOMObject["4FFE0001"]',
	},
	{
		label: 'SharedFunctionalGroupsSequence (52009229)',
		code: 'DICOMObject["52009229"]',
	},
	{
		label: 'PerFrameFunctionalGroupsSequence (52009230)',
		code: 'DICOMObject["52009230"]',
	},
	{
		label: 'WaveformSequence (54000100)',
		code: 'DICOMObject["54000100"]',
	},
	{
		label: 'ChannelMinimumValue (54000110)',
		code: 'DICOMObject["54000110"]',
	},
	{
		label: 'ChannelMaximumValue (54000112)',
		code: 'DICOMObject["54000112"]',
	},
	{
		label: 'WaveformBitsAllocated (54001004)',
		code: 'DICOMObject["54001004"]',
	},
	{
		label: 'WaveformSampleInterpretation (54001006)',
		code: 'DICOMObject["54001006"]',
	},
	{
		label: 'WaveformPaddingValue (5400100A)',
		code: 'DICOMObject["5400100A"]',
	},
	{
		label: 'WaveformData (54001010)',
		code: 'DICOMObject["54001010"]',
	},
	{
		label: 'FirstOrderPhaseCorrectionAngle (56000010)',
		code: 'DICOMObject["56000010"]',
	},
	{
		label: 'SpectroscopyData (56000020)',
		code: 'DICOMObject["56000020"]',
	},
	{
		label: 'OverlayRows (60000010)',
		code: 'DICOMObject["60000010"]',
	},
	{
		label: 'OverlayColumns (60000011)',
		code: 'DICOMObject["60000011"]',
	},
	{
		label: 'NumberOfFramesInOverlay (60000015)',
		code: 'DICOMObject["60000015"]',
	},
	{
		label: 'OverlayDescription (60000022)',
		code: 'DICOMObject["60000022"]',
	},
	{
		label: 'OverlayType (60000040)',
		code: 'DICOMObject["60000040"]',
	},
	{
		label: 'OverlaySubtype (60000045)',
		code: 'DICOMObject["60000045"]',
	},
	{
		label: 'OverlayOrigin (60000050)',
		code: 'DICOMObject["60000050"]',
	},
	{
		label: 'ImageFrameOrigin (60000051)',
		code: 'DICOMObject["60000051"]',
	},
	{
		label: 'OverlayBitsAllocated (60000100)',
		code: 'DICOMObject["60000100"]',
	},
	{
		label: 'OverlayBitPosition (60000102)',
		code: 'DICOMObject["60000102"]',
	},
	{
		label: 'OverlayActivationLayer (60001001)',
		code: 'DICOMObject["60001001"]',
	},
	{
		label: 'ROIArea (60001301)',
		code: 'DICOMObject["60001301"]',
	},
	{
		label: 'ROIMean (60001302)',
		code: 'DICOMObject["60001302"]',
	},
	{
		label: 'ROIStandardDeviation (60001303)',
		code: 'DICOMObject["60001303"]',
	},
	{
		label: 'OverlayLabel (60001500)',
		code: 'DICOMObject["60001500"]',
	},
	{
		label: 'OverlayData (60003000)',
		code: 'DICOMObject["60003000"]',
	},
	{
		label: 'FloatPixelData (7FE00008)',
		code: 'DICOMObject["7FE00008"]',
	},
	{
		label: 'DoubleFloatPixelData (7FE00009)',
		code: 'DICOMObject["7FE00009"]',
	},
	{
		label: 'PixelData (7FE00010)',
		code: 'DICOMObject["7FE00010"]',
	},
	{
		label: 'DigitalSignaturesSequence (FFFAFFFA)',
		code: 'DICOMObject["FFFAFFFA"]',
	},
	{
		label: 'DataSetTrailingPadding (FFFCFFFC)',
		code: 'DICOMObject["FFFCFFFC"]',
	},
	{
		label: 'Item (FFFEE000)',
		code: 'DICOMObject["FFFEE000"]',
	},
	{
		label: 'ItemDelimitationItem (FFFEE00D)',
		code: 'DICOMObject["FFFEE00D"]',
	},
	{
		label: 'SequenceDelimitationItem (FFFEE0DD)',
		code: 'DICOMObject["FFFEE0DD"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'undefined (# Retired generated automatically)',
		code: 'DICOMObject["# Retired generated automatically"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'RETIRED_CommandLengthToEnd (00000001)',
		code: 'DICOMObject["00000001"]',
	},
	{
		label: 'RETIRED_CommandRecognitionCode (00000010)',
		code: 'DICOMObject["00000010"]',
	},
	{
		label: 'RETIRED_Initiator (00000200)',
		code: 'DICOMObject["00000200"]',
	},
	{
		label: 'RETIRED_Receiver (00000300)',
		code: 'DICOMObject["00000300"]',
	},
	{
		label: 'RETIRED_FindLocation (00000400)',
		code: 'DICOMObject["00000400"]',
	},
	{
		label: 'RETIRED_NumberOfMatches (00000850)',
		code: 'DICOMObject["00000850"]',
	},
	{
		label: 'RETIRED_ResponseSequenceNumber (00000860)',
		code: 'DICOMObject["00000860"]',
	},
	{
		label: 'RETIRED_DialogReceiver (00004000)',
		code: 'DICOMObject["00004000"]',
	},
	{
		label: 'RETIRED_TerminalType (00004010)',
		code: 'DICOMObject["00004010"]',
	},
	{
		label: 'RETIRED_MessageSetID (00005010)',
		code: 'DICOMObject["00005010"]',
	},
	{
		label: 'RETIRED_EndMessageID (00005020)',
		code: 'DICOMObject["00005020"]',
	},
	{
		label: 'RETIRED_DisplayFormat (00005110)',
		code: 'DICOMObject["00005110"]',
	},
	{
		label: 'RETIRED_PagePositionID (00005120)',
		code: 'DICOMObject["00005120"]',
	},
	{
		label: 'RETIRED_TextFormatID (00005130)',
		code: 'DICOMObject["00005130"]',
	},
	{
		label: 'RETIRED_NormalReverse (00005140)',
		code: 'DICOMObject["00005140"]',
	},
	{
		label: 'RETIRED_AddGrayScale (00005150)',
		code: 'DICOMObject["00005150"]',
	},
	{
		label: 'RETIRED_Borders (00005160)',
		code: 'DICOMObject["00005160"]',
	},
	{
		label: 'RETIRED_Copies (00005170)',
		code: 'DICOMObject["00005170"]',
	},
	{
		label: 'RETIRED_CommandMagnificationType (00005180)',
		code: 'DICOMObject["00005180"]',
	},
	{
		label: 'RETIRED_Erase (00005190)',
		code: 'DICOMObject["00005190"]',
	},
	{
		label: 'RETIRED_Print (000051A0)',
		code: 'DICOMObject["000051A0"]',
	},
	{
		label: 'RETIRED_Overlays (000051B0)',
		code: 'DICOMObject["000051B0"]',
	},
	{
		label: 'RETIRED_MRDRDirectoryRecordOffset (00041504)',
		code: 'DICOMObject["00041504"]',
	},
	{
		label: 'RETIRED_NumberOfReferences (00041600)',
		code: 'DICOMObject["00041600"]',
	},
	{
		label: 'RETIRED_LengthToEnd (00080001)',
		code: 'DICOMObject["00080001"]',
	},
	{
		label: 'RETIRED_RecognitionCode (00080010)',
		code: 'DICOMObject["00080010"]',
	},
	{
		label: 'RETIRED_OverlayDate (00080024)',
		code: 'DICOMObject["00080024"]',
	},
	{
		label: 'RETIRED_CurveDate (00080025)',
		code: 'DICOMObject["00080025"]',
	},
	{
		label: 'RETIRED_OverlayTime (00080034)',
		code: 'DICOMObject["00080034"]',
	},
	{
		label: 'RETIRED_CurveTime (00080035)',
		code: 'DICOMObject["00080035"]',
	},
	{
		label: 'RETIRED_DataSetType (00080040)',
		code: 'DICOMObject["00080040"]',
	},
	{
		label: 'RETIRED_DataSetSubtype (00080041)',
		code: 'DICOMObject["00080041"]',
	},
	{
		label: 'RETIRED_NuclearMedicineSeriesType (00080042)',
		code: 'DICOMObject["00080042"]',
	},
	{
		label: 'RETIRED_NetworkID (00081000)',
		code: 'DICOMObject["00081000"]',
	},
	{
		label: 'RETIRED_ReferencedResultsSequence (00081100)',
		code: 'DICOMObject["00081100"]',
	},
	{
		label: 'RETIRED_ReferencedOverlaySequence (00081130)',
		code: 'DICOMObject["00081130"]',
	},
	{
		label: 'RETIRED_ReferencedCurveSequence (00081145)',
		code: 'DICOMObject["00081145"]',
	},
	{
		label: 'RETIRED_LossyImageCompressionRetired (00082110)',
		code: 'DICOMObject["00082110"]',
	},
	{
		label: 'RETIRED_TransducerPosition (00082200)',
		code: 'DICOMObject["00082200"]',
	},
	{
		label: 'RETIRED_TransducerOrientation (00082204)',
		code: 'DICOMObject["00082204"]',
	},
	{
		label: 'RETIRED_AnatomicStructure (00082208)',
		code: 'DICOMObject["00082208"]',
	},
	{
		label: 'RETIRED_TransducerPositionSequence (00082240)',
		code: 'DICOMObject["00082240"]',
	},
	{
		label: 'RETIRED_TransducerPositionModifierSequence (00082242)',
		code: 'DICOMObject["00082242"]',
	},
	{
		label: 'RETIRED_TransducerOrientationSequence (00082244)',
		code: 'DICOMObject["00082244"]',
	},
	{
		label: 'RETIRED_TransducerOrientationModifierSequence (00082246)',
		code: 'DICOMObject["00082246"]',
	},
	{
		label: 'RETIRED_AnatomicStructureSpaceOrRegionCodeSequenceTrial (00082251)',
		code: 'DICOMObject["00082251"]',
	},
	{
		label: 'RETIRED_AnatomicPortalOfEntranceCodeSequenceTrial (00082253)',
		code: 'DICOMObject["00082253"]',
	},
	{
		label: 'RETIRED_AnatomicApproachDirectionCodeSequenceTrial (00082255)',
		code: 'DICOMObject["00082255"]',
	},
	{
		label: 'RETIRED_AnatomicPerspectiveDescriptionTrial (00082256)',
		code: 'DICOMObject["00082256"]',
	},
	{
		label: 'RETIRED_AnatomicPerspectiveCodeSequenceTrial (00082257)',
		code: 'DICOMObject["00082257"]',
	},
	{
		label: 'RETIRED_AnatomicLocationOfExaminingInstrumentDescriptionTrial (00082258)',
		code: 'DICOMObject["00082258"]',
	},
	{
		label: 'RETIRED_AnatomicLocationOfExaminingInstrumentCodeSequenceTrial (00082259)',
		code: 'DICOMObject["00082259"]',
	},
	{
		label: 'RETIRED_AnatomicStructureSpaceOrRegionModifierCodeSequenceTrial (0008225A)',
		code: 'DICOMObject["0008225A"]',
	},
	{
		label: 'RETIRED_OnAxisBackgroundAnatomicStructureCodeSequenceTrial (0008225C)',
		code: 'DICOMObject["0008225C"]',
	},
	{
		label: 'RETIRED_IdentifyingComments (00084000)',
		code: 'DICOMObject["00084000"]',
	},
	{
		label: 'RETIRED_OtherPatientIDs (00101000)',
		code: 'DICOMObject["00101000"]',
	},
	{
		label: 'RETIRED_InsurancePlanIdentification (00101050)',
		code: 'DICOMObject["00101050"]',
	},
	{
		label: 'RETIRED_MedicalRecordLocator (00101090)',
		code: 'DICOMObject["00101090"]',
	},
	{
		label: 'RETIRED_CADFileFormat (00140023)',
		code: 'DICOMObject["00140023"]',
	},
	{
		label: 'RETIRED_ComponentReferenceSystem (00140024)',
		code: 'DICOMObject["00140024"]',
	},
	{
		label: 'RETIRED_MaterialPropertiesFileFormatRetired (00140045)',
		code: 'DICOMObject["00140045"]',
	},
	{
		label: 'RETIRED_Radionuclide (00180030)',
		code: 'DICOMObject["00180030"]',
	},
	{
		label: 'RETIRED_EnergyWindowCenterline (00180032)',
		code: 'DICOMObject["00180032"]',
	},
	{
		label: 'RETIRED_EnergyWindowTotalWidth (00180033)',
		code: 'DICOMObject["00180033"]',
	},
	{
		label: 'RETIRED_TherapyType (00180037)',
		code: 'DICOMObject["00180037"]',
	},
	{
		label: 'RETIRED_TherapyDescription (00180039)',
		code: 'DICOMObject["00180039"]',
	},
	{
		label: 'RETIRED_HardcopyCreationDeviceID (00181011)',
		code: 'DICOMObject["00181011"]',
	},
	{
		label: 'RETIRED_HardcopyDeviceManufacturer (00181017)',
		code: 'DICOMObject["00181017"]',
	},
	{
		label: 'RETIRED_HardcopyDeviceSoftwareVersion (0018101A)',
		code: 'DICOMObject["0018101A"]',
	},
	{
		label: 'RETIRED_HardcopyDeviceManufacturerModelName (0018101B)',
		code: 'DICOMObject["0018101B"]',
	},
	{
		label: 'RETIRED_AngularPosition (00181141)',
		code: 'DICOMObject["00181141"]',
	},
	{
		label: 'RETIRED_RotationOffset (00181146)',
		code: 'DICOMObject["00181146"]',
	},
	{
		label: 'RETIRED_UpperLowerPixelValues (00181240)',
		code: 'DICOMObject["00181240"]',
	},
	{
		label: 'RETIRED_AcquisitionComments (00184000)',
		code: 'DICOMObject["00184000"]',
	},
	{
		label: 'RETIRED_PostprocessingFunction (00185021)',
		code: 'DICOMObject["00185021"]',
	},
	{
		label: 'RETIRED_DynamicRange (00185030)',
		code: 'DICOMObject["00185030"]',
	},
	{
		label: 'RETIRED_TotalGain (00185040)',
		code: 'DICOMObject["00185040"]',
	},
	{
		label: 'RETIRED_ImageTransformationMatrix (00185210)',
		code: 'DICOMObject["00185210"]',
	},
	{
		label: 'RETIRED_ImageTranslationVector (00185212)',
		code: 'DICOMObject["00185212"]',
	},
	{
		label: 'RETIRED_DopplerSampleVolumeXPositionRetired (00186038)',
		code: 'DICOMObject["00186038"]',
	},
	{
		label: 'RETIRED_DopplerSampleVolumeYPositionRetired (0018603A)',
		code: 'DICOMObject["0018603A"]',
	},
	{
		label: 'RETIRED_TMLinePositionX0Retired (0018603C)',
		code: 'DICOMObject["0018603C"]',
	},
	{
		label: 'RETIRED_TMLinePositionY0Retired (0018603E)',
		code: 'DICOMObject["0018603E"]',
	},
	{
		label: 'RETIRED_TMLinePositionX1Retired (00186040)',
		code: 'DICOMObject["00186040"]',
	},
	{
		label: 'RETIRED_TMLinePositionY1Retired (00186042)',
		code: 'DICOMObject["00186042"]',
	},
	{
		label: 'RETIRED_ParallelReductionFactorInPlaneRetired (00189096)',
		code: 'DICOMObject["00189096"]',
	},
	{
		label: 'RETIRED_BulkMotionStatus (00189166)',
		code: 'DICOMObject["00189166"]',
	},
	{
		label: 'RETIRED_ChemicalShiftMinimumIntegrationLimitInHz (00189195)',
		code: 'DICOMObject["00189195"]',
	},
	{
		label: 'RETIRED_ChemicalShiftMaximumIntegrationLimitInHz (00189196)',
		code: 'DICOMObject["00189196"]',
	},
	{
		label: 'RETIRED_IsotopeNumber (00200014)',
		code: 'DICOMObject["00200014"]',
	},
	{
		label: 'RETIRED_PhaseNumber (00200015)',
		code: 'DICOMObject["00200015"]',
	},
	{
		label: 'RETIRED_IntervalNumber (00200016)',
		code: 'DICOMObject["00200016"]',
	},
	{
		label: 'RETIRED_TimeSlotNumber (00200017)',
		code: 'DICOMObject["00200017"]',
	},
	{
		label: 'RETIRED_AngleNumber (00200018)',
		code: 'DICOMObject["00200018"]',
	},
	{
		label: 'RETIRED_OverlayNumber (00200022)',
		code: 'DICOMObject["00200022"]',
	},
	{
		label: 'RETIRED_CurveNumber (00200024)',
		code: 'DICOMObject["00200024"]',
	},
	{
		label: 'RETIRED_LUTNumber (00200026)',
		code: 'DICOMObject["00200026"]',
	},
	{
		label: 'RETIRED_ImagePosition (00200030)',
		code: 'DICOMObject["00200030"]',
	},
	{
		label: 'RETIRED_ImageOrientation (00200035)',
		code: 'DICOMObject["00200035"]',
	},
	{
		label: 'RETIRED_Location (00200050)',
		code: 'DICOMObject["00200050"]',
	},
	{
		label: 'RETIRED_ImageGeometryType (00200070)',
		code: 'DICOMObject["00200070"]',
	},
	{
		label: 'RETIRED_MaskingImage (00200080)',
		code: 'DICOMObject["00200080"]',
	},
	{
		label: 'RETIRED_ReportNumber (002000AA)',
		code: 'DICOMObject["002000AA"]',
	},
	{
		label: 'RETIRED_SeriesInStudy (00201000)',
		code: 'DICOMObject["00201000"]',
	},
	{
		label: 'RETIRED_AcquisitionsInSeries (00201001)',
		code: 'DICOMObject["00201001"]',
	},
	{
		label: 'RETIRED_ImagesInSeries (00201003)',
		code: 'DICOMObject["00201003"]',
	},
	{
		label: 'RETIRED_AcquisitionsInStudy (00201004)',
		code: 'DICOMObject["00201004"]',
	},
	{
		label: 'RETIRED_ImagesInStudy (00201005)',
		code: 'DICOMObject["00201005"]',
	},
	{
		label: 'RETIRED_Reference (00201020)',
		code: 'DICOMObject["00201020"]',
	},
	{
		label: 'RETIRED_OtherStudyNumbers (00201070)',
		code: 'DICOMObject["00201070"]',
	},
	{
		label: 'RETIRED_SourceImageIDs (00203100-31FF)',
		code: 'DICOMObject["00203100-31FF"]',
	},
	{
		label: 'RETIRED_ModifyingDeviceID (00203401)',
		code: 'DICOMObject["00203401"]',
	},
	{
		label: 'RETIRED_ModifiedImageID (00203402)',
		code: 'DICOMObject["00203402"]',
	},
	{
		label: 'RETIRED_ModifiedImageDate (00203403)',
		code: 'DICOMObject["00203403"]',
	},
	{
		label: 'RETIRED_ModifyingDeviceManufacturer (00203404)',
		code: 'DICOMObject["00203404"]',
	},
	{
		label: 'RETIRED_ModifiedImageTime (00203405)',
		code: 'DICOMObject["00203405"]',
	},
	{
		label: 'RETIRED_ModifiedImageDescription (00203406)',
		code: 'DICOMObject["00203406"]',
	},
	{
		label: 'RETIRED_OriginalImageIdentification (00205000)',
		code: 'DICOMObject["00205000"]',
	},
	{
		label: 'RETIRED_OriginalImageIdentificationNomenclature (00205002)',
		code: 'DICOMObject["00205002"]',
	},
	{
		label: 'RETIRED_LensConstantDescription (00221094)',
		code: 'DICOMObject["00221094"]',
	},
	{
		label: 'RETIRED_OphthalmicAxialLengthAcquisitionMethodCodeSequence (00221153)',
		code: 'DICOMObject["00221153"]',
	},
	{
		label: 'RETIRED_OphthalmicAxialLengthQualityMetricTypeCodeSequence (00221265)',
		code: 'DICOMObject["00221265"]',
	},
	{
		label: 'RETIRED_OphthalmicAxialLengthQualityMetricTypeDescription (00221273)',
		code: 'DICOMObject["00221273"]',
	},
	{
		label: 'RETIRED_ImageDimensions (00280005)',
		code: 'DICOMObject["00280005"]',
	},
	{
		label: 'RETIRED_Planes (00280012)',
		code: 'DICOMObject["00280012"]',
	},
	{
		label: 'RETIRED_ImageFormat (00280040)',
		code: 'DICOMObject["00280040"]',
	},
	{
		label: 'RETIRED_ManipulatedImage (00280050)',
		code: 'DICOMObject["00280050"]',
	},
	{
		label: 'RETIRED_CompressionRecognitionCode (0028005F)',
		code: 'DICOMObject["0028005F"]',
	},
	{
		label: 'RETIRED_CompressionCode (00280060)',
		code: 'DICOMObject["00280060"]',
	},
	{
		label: 'RETIRED_CompressionOriginator (00280061)',
		code: 'DICOMObject["00280061"]',
	},
	{
		label: 'RETIRED_CompressionLabel (00280062)',
		code: 'DICOMObject["00280062"]',
	},
	{
		label: 'RETIRED_CompressionDescription (00280063)',
		code: 'DICOMObject["00280063"]',
	},
	{
		label: 'RETIRED_CompressionSequence (00280065)',
		code: 'DICOMObject["00280065"]',
	},
	{
		label: 'RETIRED_CompressionStepPointers (00280066)',
		code: 'DICOMObject["00280066"]',
	},
	{
		label: 'RETIRED_RepeatInterval (00280068)',
		code: 'DICOMObject["00280068"]',
	},
	{
		label: 'RETIRED_BitsGrouped (00280069)',
		code: 'DICOMObject["00280069"]',
	},
	{
		label: 'RETIRED_PerimeterTable (00280070)',
		code: 'DICOMObject["00280070"]',
	},
	{
		label: 'RETIRED_PerimeterValue (00280071)',
		code: 'DICOMObject["00280071"]',
	},
	{
		label: 'RETIRED_PredictorRows (00280080)',
		code: 'DICOMObject["00280080"]',
	},
	{
		label: 'RETIRED_PredictorColumns (00280081)',
		code: 'DICOMObject["00280081"]',
	},
	{
		label: 'RETIRED_PredictorConstants (00280082)',
		code: 'DICOMObject["00280082"]',
	},
	{
		label: 'RETIRED_BlockedPixels (00280090)',
		code: 'DICOMObject["00280090"]',
	},
	{
		label: 'RETIRED_BlockRows (00280091)',
		code: 'DICOMObject["00280091"]',
	},
	{
		label: 'RETIRED_BlockColumns (00280092)',
		code: 'DICOMObject["00280092"]',
	},
	{
		label: 'RETIRED_RowOverlap (00280093)',
		code: 'DICOMObject["00280093"]',
	},
	{
		label: 'RETIRED_ColumnOverlap (00280094)',
		code: 'DICOMObject["00280094"]',
	},
	{
		label: 'RETIRED_SmallestValidPixelValue (00280104)',
		code: 'DICOMObject["00280104"]',
	},
	{
		label: 'RETIRED_LargestValidPixelValue (00280105)',
		code: 'DICOMObject["00280105"]',
	},
	{
		label: 'RETIRED_SmallestImagePixelValueInPlane (00280110)',
		code: 'DICOMObject["00280110"]',
	},
	{
		label: 'RETIRED_LargestImagePixelValueInPlane (00280111)',
		code: 'DICOMObject["00280111"]',
	},
	{
		label: 'RETIRED_ImageLocation (00280200)',
		code: 'DICOMObject["00280200"]',
	},
	{
		label: 'RETIRED_TransformLabel (00280400)',
		code: 'DICOMObject["00280400"]',
	},
	{
		label: 'RETIRED_TransformVersionNumber (00280401)',
		code: 'DICOMObject["00280401"]',
	},
	{
		label: 'RETIRED_NumberOfTransformSteps (00280402)',
		code: 'DICOMObject["00280402"]',
	},
	{
		label: 'RETIRED_SequenceOfCompressedData (00280403)',
		code: 'DICOMObject["00280403"]',
	},
	{
		label: 'RETIRED_DetailsOfCoefficients (00280404)',
		code: 'DICOMObject["00280404"]',
	},
	{
		label: 'RETIRED_RowsForNthOrderCoefficients (#002804X0)',
		code: 'DICOMObject["#002804X0"]',
	},
	{
		label: 'RETIRED_ColumnsForNthOrderCoefficients (#002804X1)',
		code: 'DICOMObject["#002804X1"]',
	},
	{
		label: 'RETIRED_CoefficientCoding (#002804X2)',
		code: 'DICOMObject["#002804X2"]',
	},
	{
		label: 'RETIRED_CoefficientCodingPointers (#002804X3)',
		code: 'DICOMObject["#002804X3"]',
	},
	{
		label: 'RETIRED_DCTLabel (00280700)',
		code: 'DICOMObject["00280700"]',
	},
	{
		label: 'RETIRED_DataBlockDescription (00280701)',
		code: 'DICOMObject["00280701"]',
	},
	{
		label: 'RETIRED_DataBlock (00280702)',
		code: 'DICOMObject["00280702"]',
	},
	{
		label: 'RETIRED_NormalizationFactorFormat (00280710)',
		code: 'DICOMObject["00280710"]',
	},
	{
		label: 'RETIRED_ZonalMapNumberFormat (00280720)',
		code: 'DICOMObject["00280720"]',
	},
	{
		label: 'RETIRED_ZonalMapLocation (00280721)',
		code: 'DICOMObject["00280721"]',
	},
	{
		label: 'RETIRED_ZonalMapFormat (00280722)',
		code: 'DICOMObject["00280722"]',
	},
	{
		label: 'RETIRED_AdaptiveMapFormat (00280730)',
		code: 'DICOMObject["00280730"]',
	},
	{
		label: 'RETIRED_CodeNumberFormat (00280740)',
		code: 'DICOMObject["00280740"]',
	},
	{
		label: 'RETIRED_CodeLabel (#002808X0)',
		code: 'DICOMObject["#002808X0"]',
	},
	{
		label: 'RETIRED_NumberOfTables (#002808X2)',
		code: 'DICOMObject["#002808X2"]',
	},
	{
		label: 'RETIRED_CodeTableLocation (#002808X3)',
		code: 'DICOMObject["#002808X3"]',
	},
	{
		label: 'RETIRED_BitsForCodeWord (#002808X4)',
		code: 'DICOMObject["#002808X4"]',
	},
	{
		label: 'RETIRED_ImageDataLocation (#002808X8)',
		code: 'DICOMObject["#002808X8"]',
	},
	{
		label: 'RETIRED_GrayScale (00281080)',
		code: 'DICOMObject["00281080"]',
	},
	{
		label: 'RETIRED_GrayLookupTableDescriptor (00281100)',
		code: 'DICOMObject["00281100"]',
	},
	{
		label: 'RETIRED_LargeRedPaletteColorLookupTableDescriptor (00281111)',
		code: 'DICOMObject["00281111"]',
	},
	{
		label: 'RETIRED_LargeGreenPaletteColorLookupTableDescriptor (00281112)',
		code: 'DICOMObject["00281112"]',
	},
	{
		label: 'RETIRED_LargeBluePaletteColorLookupTableDescriptor (00281113)',
		code: 'DICOMObject["00281113"]',
	},
	{
		label: 'RETIRED_GrayLookupTableData (00281200)',
		code: 'DICOMObject["00281200"]',
	},
	{
		label: 'RETIRED_LargeRedPaletteColorLookupTableData (00281211)',
		code: 'DICOMObject["00281211"]',
	},
	{
		label: 'RETIRED_LargeGreenPaletteColorLookupTableData (00281212)',
		code: 'DICOMObject["00281212"]',
	},
	{
		label: 'RETIRED_LargeBluePaletteColorLookupTableData (00281213)',
		code: 'DICOMObject["00281213"]',
	},
	{
		label: 'RETIRED_LargePaletteColorLookupTableUID (00281214)',
		code: 'DICOMObject["00281214"]',
	},
	{
		label: 'RETIRED_ImagePresentationComments (00284000)',
		code: 'DICOMObject["00284000"]',
	},
	{
		label: 'RETIRED_BiPlaneAcquisitionSequence (00285000)',
		code: 'DICOMObject["00285000"]',
	},
	{
		label: 'RETIRED_MaskPointers (00286030)',
		code: 'DICOMObject["00286030"]',
	},
	{
		label: 'RETIRED_LargestMonochromePixelValue (00289099)',
		code: 'DICOMObject["00289099"]',
	},
	{
		label: 'RETIRED_StudyStatusID (0032000A)',
		code: 'DICOMObject["0032000A"]',
	},
	{
		label: 'RETIRED_StudyPriorityID (0032000C)',
		code: 'DICOMObject["0032000C"]',
	},
	{
		label: 'RETIRED_StudyIDIssuer (00320012)',
		code: 'DICOMObject["00320012"]',
	},
	{
		label: 'RETIRED_StudyVerifiedDate (00320032)',
		code: 'DICOMObject["00320032"]',
	},
	{
		label: 'RETIRED_StudyVerifiedTime (00320033)',
		code: 'DICOMObject["00320033"]',
	},
	{
		label: 'RETIRED_StudyReadDate (00320034)',
		code: 'DICOMObject["00320034"]',
	},
	{
		label: 'RETIRED_StudyReadTime (00320035)',
		code: 'DICOMObject["00320035"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStartDate (00321000)',
		code: 'DICOMObject["00321000"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStartTime (00321001)',
		code: 'DICOMObject["00321001"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStopDate (00321010)',
		code: 'DICOMObject["00321010"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStopTime (00321011)',
		code: 'DICOMObject["00321011"]',
	},
	{
		label: 'RETIRED_ScheduledStudyLocation (00321020)',
		code: 'DICOMObject["00321020"]',
	},
	{
		label: 'RETIRED_ScheduledStudyLocationAETitle (00321021)',
		code: 'DICOMObject["00321021"]',
	},
	{
		label: 'RETIRED_ReasonForStudy (00321030)',
		code: 'DICOMObject["00321030"]',
	},
	{
		label: 'RETIRED_StudyArrivalDate (00321040)',
		code: 'DICOMObject["00321040"]',
	},
	{
		label: 'RETIRED_StudyArrivalTime (00321041)',
		code: 'DICOMObject["00321041"]',
	},
	{
		label: 'RETIRED_StudyCompletionDate (00321050)',
		code: 'DICOMObject["00321050"]',
	},
	{
		label: 'RETIRED_StudyCompletionTime (00321051)',
		code: 'DICOMObject["00321051"]',
	},
	{
		label: 'RETIRED_StudyComponentStatusID (00321055)',
		code: 'DICOMObject["00321055"]',
	},
	{
		label: 'RETIRED_StudyComments (00324000)',
		code: 'DICOMObject["00324000"]',
	},
	{
		label: 'RETIRED_IssuerOfAdmissionID (00380011)',
		code: 'DICOMObject["00380011"]',
	},
	{
		label: 'RETIRED_ScheduledAdmissionDate (0038001A)',
		code: 'DICOMObject["0038001A"]',
	},
	{
		label: 'RETIRED_ScheduledAdmissionTime (0038001B)',
		code: 'DICOMObject["0038001B"]',
	},
	{
		label: 'RETIRED_ScheduledDischargeDate (0038001C)',
		code: 'DICOMObject["0038001C"]',
	},
	{
		label: 'RETIRED_ScheduledDischargeTime (0038001D)',
		code: 'DICOMObject["0038001D"]',
	},
	{
		label: 'RETIRED_ScheduledPatientInstitutionResidence (0038001E)',
		code: 'DICOMObject["0038001E"]',
	},
	{
		label: 'RETIRED_DischargeDate (00380030)',
		code: 'DICOMObject["00380030"]',
	},
	{
		label: 'RETIRED_DischargeTime (00380032)',
		code: 'DICOMObject["00380032"]',
	},
	{
		label: 'RETIRED_DischargeDiagnosisDescription (00380040)',
		code: 'DICOMObject["00380040"]',
	},
	{
		label: 'RETIRED_DischargeDiagnosisCodeSequence (00380044)',
		code: 'DICOMObject["00380044"]',
	},
	{
		label: 'RETIRED_IssuerOfServiceEpisodeID (00380061)',
		code: 'DICOMObject["00380061"]',
	},
	{
		label: 'RETIRED_DistanceSourceToSupport (00400307)',
		code: 'DICOMObject["00400307"]',
	},
	{
		label: 'RETIRED_ReferencedProcedureStepSequence (00400330)',
		code: 'DICOMObject["00400330"]',
	},
	{
		label: 'RETIRED_SpecimenAccessionNumber (0040050A)',
		code: 'DICOMObject["0040050A"]',
	},
	{
		label: 'RETIRED_SpecimenSequence (00400550)',
		code: 'DICOMObject["00400550"]',
	},
	{
		label: 'RETIRED_SpecimenDescriptionSequenceTrial (00400552)',
		code: 'DICOMObject["00400552"]',
	},
	{
		label: 'RETIRED_SpecimenDescriptionTrial (00400553)',
		code: 'DICOMObject["00400553"]',
	},
	{
		label: 'RETIRED_SlideIdentifier (004006FA)',
		code: 'DICOMObject["004006FA"]',
	},
	{
		label: 'RETIRED_PixelSpacingSequence (004008D8)',
		code: 'DICOMObject["004008D8"]',
	},
	{
		label: 'RETIRED_CoordinateSystemAxisCodeSequence (004008DA)',
		code: 'DICOMObject["004008DA"]',
	},
	{
		label: 'RETIRED_VitalStainCodeSequenceTrial (004009F8)',
		code: 'DICOMObject["004009F8"]',
	},
	{
		label: 'RETIRED_PlacerOrderNumberProcedure (00401006)',
		code: 'DICOMObject["00401006"]',
	},
	{
		label: 'RETIRED_FillerOrderNumberProcedure (00401007)',
		code: 'DICOMObject["00401007"]',
	},
	{
		label: 'RETIRED_RequestedProcedureDescriptionTrial (00401060)',
		code: 'DICOMObject["00401060"]',
	},
	{
		label: 'RETIRED_ReasonForTheImagingServiceRequest (00402001)',
		code: 'DICOMObject["00402001"]',
	},
	{
		label: 'RETIRED_PlacerOrderNumberImagingServiceRequestRetired (00402006)',
		code: 'DICOMObject["00402006"]',
	},
	{
		label: 'RETIRED_FillerOrderNumberImagingServiceRequestRetired (00402007)',
		code: 'DICOMObject["00402007"]',
	},
	{
		label: 'RETIRED_GeneralPurposeScheduledProcedureStepStatus (00404001)',
		code: 'DICOMObject["00404001"]',
	},
	{
		label: 'RETIRED_GeneralPurposePerformedProcedureStepStatus (00404002)',
		code: 'DICOMObject["00404002"]',
	},
	{
		label: 'RETIRED_GeneralPurposeScheduledProcedureStepPriority (00404003)',
		code: 'DICOMObject["00404003"]',
	},
	{
		label: 'RETIRED_ScheduledProcessingApplicationsCodeSequence (00404004)',
		code: 'DICOMObject["00404004"]',
	},
	{
		label: 'RETIRED_MultipleCopiesFlag (00404006)',
		code: 'DICOMObject["00404006"]',
	},
	{
		label: 'RETIRED_ResultingGeneralPurposePerformedProcedureStepsSequence (00404015)',
		code: 'DICOMObject["00404015"]',
	},
	{
		label: 'RETIRED_ReferencedGeneralPurposeScheduledProcedureStepSequence (00404016)',
		code: 'DICOMObject["00404016"]',
	},
	{
		label: 'RETIRED_RelevantInformationSequence (00404022)',
		code: 'DICOMObject["00404022"]',
	},
	{
		label: 'RETIRED_ReferencedGeneralPurposeScheduledProcedureStepTransactionUID (00404023)',
		code: 'DICOMObject["00404023"]',
	},
	{
		label: 'RETIRED_RequestedSubsequentWorkitemCodeSequence (00404031)',
		code: 'DICOMObject["00404031"]',
	},
	{
		label: 'RETIRED_NonDICOMOutputCodeSequence (00404032)',
		code: 'DICOMObject["00404032"]',
	},
	{
		label: 'RETIRED_FindingsFlagTrial (0040A007)',
		code: 'DICOMObject["0040A007"]',
	},
	{
		label: 'RETIRED_FindingsSequenceTrial (0040A020)',
		code: 'DICOMObject["0040A020"]',
	},
	{
		label: 'RETIRED_FindingsGroupUIDTrial (0040A021)',
		code: 'DICOMObject["0040A021"]',
	},
	{
		label: 'RETIRED_ReferencedFindingsGroupUIDTrial (0040A022)',
		code: 'DICOMObject["0040A022"]',
	},
	{
		label: 'RETIRED_FindingsGroupRecordingDateTrial (0040A023)',
		code: 'DICOMObject["0040A023"]',
	},
	{
		label: 'RETIRED_FindingsGroupRecordingTimeTrial (0040A024)',
		code: 'DICOMObject["0040A024"]',
	},
	{
		label: 'RETIRED_FindingsSourceCategoryCodeSequenceTrial (0040A026)',
		code: 'DICOMObject["0040A026"]',
	},
	{
		label: 'RETIRED_DocumentingOrganizationIdentifierCodeSequenceTrial (0040A028)',
		code: 'DICOMObject["0040A028"]',
	},
	{
		label: 'RETIRED_MeasurementPrecisionDescriptionTrial (0040A047)',
		code: 'DICOMObject["0040A047"]',
	},
	{
		label: 'RETIRED_UrgencyOrPriorityAlertsTrial (0040A057)',
		code: 'DICOMObject["0040A057"]',
	},
	{
		label: 'RETIRED_SequencingIndicatorTrial (0040A060)',
		code: 'DICOMObject["0040A060"]',
	},
	{
		label: 'RETIRED_DocumentIdentifierCodeSequenceTrial (0040A066)',
		code: 'DICOMObject["0040A066"]',
	},
	{
		label: 'RETIRED_DocumentAuthorTrial (0040A067)',
		code: 'DICOMObject["0040A067"]',
	},
	{
		label: 'RETIRED_DocumentAuthorIdentifierCodeSequenceTrial (0040A068)',
		code: 'DICOMObject["0040A068"]',
	},
	{
		label: 'RETIRED_IdentifierCodeSequenceTrial (0040A070)',
		code: 'DICOMObject["0040A070"]',
	},
	{
		label: 'RETIRED_ObjectBinaryIdentifierTrial (0040A074)',
		code: 'DICOMObject["0040A074"]',
	},
	{
		label: 'RETIRED_DocumentingObserverIdentifierCodeSequenceTrial (0040A076)',
		code: 'DICOMObject["0040A076"]',
	},
	{
		label: 'RETIRED_ProcedureIdentifierCodeSequenceTrial (0040A085)',
		code: 'DICOMObject["0040A085"]',
	},
	{
		label: 'RETIRED_ObjectDirectoryBinaryIdentifierTrial (0040A089)',
		code: 'DICOMObject["0040A089"]',
	},
	{
		label: 'RETIRED_EquivalentCDADocumentSequence (0040A090)',
		code: 'DICOMObject["0040A090"]',
	},
	{
		label: 'RETIRED_DateOfDocumentOrVerbalTransactionTrial (0040A110)',
		code: 'DICOMObject["0040A110"]',
	},
	{
		label: 'RETIRED_TimeOfDocumentCreationOrVerbalTransactionTrial (0040A112)',
		code: 'DICOMObject["0040A112"]',
	},
	{
		label: 'RETIRED_ReportStatusIDTrial (0040A125)',
		code: 'DICOMObject["0040A125"]',
	},
	{
		label: 'RETIRED_ObservationCategoryCodeSequenceTrial (0040A167)',
		code: 'DICOMObject["0040A167"]',
	},
	{
		label: 'RETIRED_BibliographicCitationTrial (0040A16A)',
		code: 'DICOMObject["0040A16A"]',
	},
	{
		label: 'RETIRED_ReferencedObservationUIDTrial (0040A172)',
		code: 'DICOMObject["0040A172"]',
	},
	{
		label: 'RETIRED_ReferencedObservationClassTrial (0040A173)',
		code: 'DICOMObject["0040A173"]',
	},
	{
		label: 'RETIRED_ReferencedObjectObservationClassTrial (0040A174)',
		code: 'DICOMObject["0040A174"]',
	},
	{
		label: 'RETIRED_ObservationDateTrial (0040A192)',
		code: 'DICOMObject["0040A192"]',
	},
	{
		label: 'RETIRED_ObservationTimeTrial (0040A193)',
		code: 'DICOMObject["0040A193"]',
	},
	{
		label: 'RETIRED_MeasurementAutomationTrial (0040A194)',
		code: 'DICOMObject["0040A194"]',
	},
	{
		label: 'RETIRED_IdentificationDescriptionTrial (0040A224)',
		code: 'DICOMObject["0040A224"]',
	},
	{
		label: 'RETIRED_CoordinatesSetGeometricTypeTrial (0040A290)',
		code: 'DICOMObject["0040A290"]',
	},
	{
		label: 'RETIRED_AlgorithmCodeSequenceTrial (0040A296)',
		code: 'DICOMObject["0040A296"]',
	},
	{
		label: 'RETIRED_AlgorithmDescriptionTrial (0040A297)',
		code: 'DICOMObject["0040A297"]',
	},
	{
		label: 'RETIRED_PixelCoordinatesSetTrial (0040A29A)',
		code: 'DICOMObject["0040A29A"]',
	},
	{
		label: 'RETIRED_CurrentObserverTrial (0040A307)',
		code: 'DICOMObject["0040A307"]',
	},
	{
		label: 'RETIRED_ReferencedAccessionSequenceTrial (0040A313)',
		code: 'DICOMObject["0040A313"]',
	},
	{
		label: 'RETIRED_ReportStatusCommentTrial (0040A33A)',
		code: 'DICOMObject["0040A33A"]',
	},
	{
		label: 'RETIRED_ProcedureContextSequenceTrial (0040A340)',
		code: 'DICOMObject["0040A340"]',
	},
	{
		label: 'RETIRED_VerbalSourceTrial (0040A352)',
		code: 'DICOMObject["0040A352"]',
	},
	{
		label: 'RETIRED_AddressTrial (0040A353)',
		code: 'DICOMObject["0040A353"]',
	},
	{
		label: 'RETIRED_TelephoneNumberTrial (0040A354)',
		code: 'DICOMObject["0040A354"]',
	},
	{
		label: 'RETIRED_VerbalSourceIdentifierCodeSequenceTrial (0040A358)',
		code: 'DICOMObject["0040A358"]',
	},
	{
		label: 'RETIRED_ReportDetailSequenceTrial (0040A380)',
		code: 'DICOMObject["0040A380"]',
	},
	{
		label: 'RETIRED_ObservationSubjectUIDTrial (0040A402)',
		code: 'DICOMObject["0040A402"]',
	},
	{
		label: 'RETIRED_ObservationSubjectClassTrial (0040A403)',
		code: 'DICOMObject["0040A403"]',
	},
	{
		label: 'RETIRED_ObservationSubjectTypeCodeSequenceTrial (0040A404)',
		code: 'DICOMObject["0040A404"]',
	},
	{
		label: 'RETIRED_ObservationSubjectContextFlagTrial (0040A600)',
		code: 'DICOMObject["0040A600"]',
	},
	{
		label: 'RETIRED_ObserverContextFlagTrial (0040A601)',
		code: 'DICOMObject["0040A601"]',
	},
	{
		label: 'RETIRED_ProcedureContextFlagTrial (0040A603)',
		code: 'DICOMObject["0040A603"]',
	},
	{
		label: 'RETIRED_RelationshipSequenceTrial (0040A731)',
		code: 'DICOMObject["0040A731"]',
	},
	{
		label: 'RETIRED_RelationshipTypeCodeSequenceTrial (0040A732)',
		code: 'DICOMObject["0040A732"]',
	},
	{
		label: 'RETIRED_LanguageCodeSequenceTrial (0040A744)',
		code: 'DICOMObject["0040A744"]',
	},
	{
		label: 'RETIRED_UniformResourceLocatorTrial (0040A992)',
		code: 'DICOMObject["0040A992"]',
	},
	{
		label: 'RETIRED_TemplateVersion (0040DB06)',
		code: 'DICOMObject["0040DB06"]',
	},
	{
		label: 'RETIRED_TemplateLocalVersion (0040DB07)',
		code: 'DICOMObject["0040DB07"]',
	},
	{
		label: 'RETIRED_TemplateExtensionFlag (0040DB0B)',
		code: 'DICOMObject["0040DB0B"]',
	},
	{
		label: 'RETIRED_TemplateExtensionOrganizationUID (0040DB0C)',
		code: 'DICOMObject["0040DB0C"]',
	},
	{
		label: 'RETIRED_TemplateExtensionCreatorUID (0040DB0D)',
		code: 'DICOMObject["0040DB0D"]',
	},
	{
		label: 'RETIRED_CountsIncluded (00541400)',
		code: 'DICOMObject["00541400"]',
	},
	{
		label: 'RETIRED_DeadTimeCorrectionFlag (00541401)',
		code: 'DICOMObject["00541401"]',
	},
	{
		label: 'RETIRED_TrianglePointIndexList (00660023)',
		code: 'DICOMObject["00660023"]',
	},
	{
		label: 'RETIRED_EdgePointIndexList (00660024)',
		code: 'DICOMObject["00660024"]',
	},
	{
		label: 'RETIRED_VertexPointIndexList (00660025)',
		code: 'DICOMObject["00660025"]',
	},
	{
		label: 'RETIRED_PrimitivePointIndexList (00660029)',
		code: 'DICOMObject["00660029"]',
	},
	{
		label: 'RETIRED_ImageRotationRetired (00700040)',
		code: 'DICOMObject["00700040"]',
	},
	{
		label: 'RETIRED_DisplayedAreaTopLeftHandCornerTrial (00700050)',
		code: 'DICOMObject["00700050"]',
	},
	{
		label: 'RETIRED_DisplayedAreaBottomRightHandCornerTrial (00700051)',
		code: 'DICOMObject["00700051"]',
	},
	{
		label: 'RETIRED_GraphicLayerRecommendedDisplayRGBValue (00700067)',
		code: 'DICOMObject["00700067"]',
	},
	{
		label: 'RETIRED_CompositingMethod (00701206)',
		code: 'DICOMObject["00701206"]',
	},
	{
		label: 'RETIRED_BeamOrderIndexTrial (00741024)',
		code: 'DICOMObject["00741024"]',
	},
	{
		label: 'RETIRED_DoubleExposureMetersetTrial (00741038)',
		code: 'DICOMObject["00741038"]',
	},
	{
		label: 'RETIRED_DoubleExposureFieldDeltaTrial (0074103A)',
		code: 'DICOMObject["0074103A"]',
	},
	{
		label: 'RETIRED_RelatedProcedureStepSequence (00741220)',
		code: 'DICOMObject["00741220"]',
	},
	{
		label: 'RETIRED_ProcedureStepRelationshipType (00741222)',
		code: 'DICOMObject["00741222"]',
	},
	{
		label: 'RETIRED_TopicTitle (00880904)',
		code: 'DICOMObject["00880904"]',
	},
	{
		label: 'RETIRED_TopicSubject (00880906)',
		code: 'DICOMObject["00880906"]',
	},
	{
		label: 'RETIRED_TopicAuthor (00880910)',
		code: 'DICOMObject["00880910"]',
	},
	{
		label: 'RETIRED_TopicKeywords (00880912)',
		code: 'DICOMObject["00880912"]',
	},
	{
		label: 'RETIRED_EscapeTriplet (#1000XX00-XXFF)',
		code: 'DICOMObject["#1000XX00-XXFF"]',
	},
	{
		label: 'RETIRED_RunLengthTriplet (#1000XX00-XXFF)',
		code: 'DICOMObject["#1000XX00-XXFF"]',
	},
	{
		label: 'RETIRED_HuffmanTableSize (#1000XX00-XXFF)',
		code: 'DICOMObject["#1000XX00-XXFF"]',
	},
	{
		label: 'RETIRED_HuffmanTableTriplet (#1000XX00-XXFF)',
		code: 'DICOMObject["#1000XX00-XXFF"]',
	},
	{
		label: 'RETIRED_ShiftTableSize (#1000XX00-XXFF)',
		code: 'DICOMObject["#1000XX00-XXFF"]',
	},
	{
		label: 'RETIRED_ShiftTableTriplet (#1000XX00-XXFF)',
		code: 'DICOMObject["#1000XX00-XXFF"]',
	},
	{
		label: 'RETIRED_ZonalMap (#1010XX00-XXFF)',
		code: 'DICOMObject["#1010XX00-XXFF"]',
	},
	{
		label: 'RETIRED_ColorImagePrintingFlag (20000062)',
		code: 'DICOMObject["20000062"]',
	},
	{
		label: 'RETIRED_CollationFlag (20000063)',
		code: 'DICOMObject["20000063"]',
	},
	{
		label: 'RETIRED_AnnotationFlag (20000065)',
		code: 'DICOMObject["20000065"]',
	},
	{
		label: 'RETIRED_ImageOverlayFlag (20000067)',
		code: 'DICOMObject["20000067"]',
	},
	{
		label: 'RETIRED_PresentationLUTFlag (20000069)',
		code: 'DICOMObject["20000069"]',
	},
	{
		label: 'RETIRED_ImageBoxPresentationLUTFlag (2000006A)',
		code: 'DICOMObject["2000006A"]',
	},
	{
		label: 'RETIRED_ReferencedStoredPrintSequence (20000510)',
		code: 'DICOMObject["20000510"]',
	},
	{
		label: 'RETIRED_ReferencedImageOverlayBoxSequence (20200130)',
		code: 'DICOMObject["20200130"]',
	},
	{
		label: 'RETIRED_ReferencedVOILUTBoxSequence (20200140)',
		code: 'DICOMObject["20200140"]',
	},
	{
		label: 'RETIRED_ReferencedOverlayPlaneSequence (20400010)',
		code: 'DICOMObject["20400010"]',
	},
	{
		label: 'RETIRED_ReferencedOverlayPlaneGroups (20400011)',
		code: 'DICOMObject["20400011"]',
	},
	{
		label: 'RETIRED_OverlayPixelDataSequence (20400020)',
		code: 'DICOMObject["20400020"]',
	},
	{
		label: 'RETIRED_OverlayMagnificationType (20400060)',
		code: 'DICOMObject["20400060"]',
	},
	{
		label: 'RETIRED_OverlaySmoothingType (20400070)',
		code: 'DICOMObject["20400070"]',
	},
	{
		label: 'RETIRED_OverlayOrImageMagnification (20400072)',
		code: 'DICOMObject["20400072"]',
	},
	{
		label: 'RETIRED_MagnifyToNumberOfColumns (20400074)',
		code: 'DICOMObject["20400074"]',
	},
	{
		label: 'RETIRED_OverlayForegroundDensity (20400080)',
		code: 'DICOMObject["20400080"]',
	},
	{
		label: 'RETIRED_OverlayBackgroundDensity (20400082)',
		code: 'DICOMObject["20400082"]',
	},
	{
		label: 'RETIRED_OverlayMode (20400090)',
		code: 'DICOMObject["20400090"]',
	},
	{
		label: 'RETIRED_ThresholdDensity (20400100)',
		code: 'DICOMObject["20400100"]',
	},
	{
		label: 'RETIRED_ReferencedImageBoxSequenceRetired (20400500)',
		code: 'DICOMObject["20400500"]',
	},
	{
		label: 'RETIRED_PrintJobID (21000010)',
		code: 'DICOMObject["21000010"]',
	},
	{
		label: 'RETIRED_ReferencedPrintJobSequencePullStoredPrint (21000500)',
		code: 'DICOMObject["21000500"]',
	},
	{
		label: 'RETIRED_PrintQueueID (21100099)',
		code: 'DICOMObject["21100099"]',
	},
	{
		label: 'RETIRED_QueueStatus (21200010)',
		code: 'DICOMObject["21200010"]',
	},
	{
		label: 'RETIRED_PrintJobDescriptionSequence (21200050)',
		code: 'DICOMObject["21200050"]',
	},
	{
		label: 'RETIRED_ReferencedPrintJobSequence (21200070)',
		code: 'DICOMObject["21200070"]',
	},
	{
		label: 'RETIRED_PrintManagementCapabilitiesSequence (21300010)',
		code: 'DICOMObject["21300010"]',
	},
	{
		label: 'RETIRED_PrinterCharacteristicsSequence (21300015)',
		code: 'DICOMObject["21300015"]',
	},
	{
		label: 'RETIRED_FilmBoxContentSequence (21300030)',
		code: 'DICOMObject["21300030"]',
	},
	{
		label: 'RETIRED_ImageBoxContentSequence (21300040)',
		code: 'DICOMObject["21300040"]',
	},
	{
		label: 'RETIRED_AnnotationContentSequence (21300050)',
		code: 'DICOMObject["21300050"]',
	},
	{
		label: 'RETIRED_ImageOverlayBoxContentSequence (21300060)',
		code: 'DICOMObject["21300060"]',
	},
	{
		label: 'RETIRED_PresentationLUTContentSequence (21300080)',
		code: 'DICOMObject["21300080"]',
	},
	{
		label: 'RETIRED_ProposedStudySequence (213000A0)',
		code: 'DICOMObject["213000A0"]',
	},
	{
		label: 'RETIRED_OriginalImageSequence (213000C0)',
		code: 'DICOMObject["213000C0"]',
	},
	{
		label: 'RETIRED_AdditionalRTROIIdentificationCodeSequence (300600B9)',
		code: 'DICOMObject["300600B9"]',
	},
	{
		label: 'RETIRED_FrameOfReferenceRelationshipSequence (300600C0)',
		code: 'DICOMObject["300600C0"]',
	},
	{
		label: 'RETIRED_RelatedFrameOfReferenceUID (300600C2)',
		code: 'DICOMObject["300600C2"]',
	},
	{
		label: 'RETIRED_FrameOfReferenceTransformationType (300600C4)',
		code: 'DICOMObject["300600C4"]',
	},
	{
		label: 'RETIRED_BeamDosePointDepth (300A0088)',
		code: 'DICOMObject["300A0088"]',
	},
	{
		label: 'RETIRED_BeamDosePointEquivalentDepth (300A0089)',
		code: 'DICOMObject["300A0089"]',
	},
	{
		label: 'RETIRED_BeamDosePointSSD (300A008A)',
		code: 'DICOMObject["300A008A"]',
	},
	{
		label: 'RETIRED_Arbitrary (40000010)',
		code: 'DICOMObject["40000010"]',
	},
	{
		label: 'RETIRED_TextComments (40004000)',
		code: 'DICOMObject["40004000"]',
	},
	{
		label: 'RETIRED_ResultsID (40080040)',
		code: 'DICOMObject["40080040"]',
	},
	{
		label: 'RETIRED_ResultsIDIssuer (40080042)',
		code: 'DICOMObject["40080042"]',
	},
	{
		label: 'RETIRED_ReferencedInterpretationSequence (40080050)',
		code: 'DICOMObject["40080050"]',
	},
	{
		label: 'RETIRED_ReportProductionStatusTrial (400800FF)',
		code: 'DICOMObject["400800FF"]',
	},
	{
		label: 'RETIRED_InterpretationRecordedDate (40080100)',
		code: 'DICOMObject["40080100"]',
	},
	{
		label: 'RETIRED_InterpretationRecordedTime (40080101)',
		code: 'DICOMObject["40080101"]',
	},
	{
		label: 'RETIRED_InterpretationRecorder (40080102)',
		code: 'DICOMObject["40080102"]',
	},
	{
		label: 'RETIRED_ReferenceToRecordedSound (40080103)',
		code: 'DICOMObject["40080103"]',
	},
	{
		label: 'RETIRED_InterpretationTranscriptionDate (40080108)',
		code: 'DICOMObject["40080108"]',
	},
	{
		label: 'RETIRED_InterpretationTranscriptionTime (40080109)',
		code: 'DICOMObject["40080109"]',
	},
	{
		label: 'RETIRED_InterpretationTranscriber (4008010A)',
		code: 'DICOMObject["4008010A"]',
	},
	{
		label: 'RETIRED_InterpretationText (4008010B)',
		code: 'DICOMObject["4008010B"]',
	},
	{
		label: 'RETIRED_InterpretationAuthor (4008010C)',
		code: 'DICOMObject["4008010C"]',
	},
	{
		label: 'RETIRED_InterpretationApproverSequence (40080111)',
		code: 'DICOMObject["40080111"]',
	},
	{
		label: 'RETIRED_InterpretationApprovalDate (40080112)',
		code: 'DICOMObject["40080112"]',
	},
	{
		label: 'RETIRED_InterpretationApprovalTime (40080113)',
		code: 'DICOMObject["40080113"]',
	},
	{
		label: 'RETIRED_PhysicianApprovingInterpretation (40080114)',
		code: 'DICOMObject["40080114"]',
	},
	{
		label: 'RETIRED_InterpretationDiagnosisDescription (40080115)',
		code: 'DICOMObject["40080115"]',
	},
	{
		label: 'RETIRED_InterpretationDiagnosisCodeSequence (40080117)',
		code: 'DICOMObject["40080117"]',
	},
	{
		label: 'RETIRED_ResultsDistributionListSequence (40080118)',
		code: 'DICOMObject["40080118"]',
	},
	{
		label: 'RETIRED_DistributionName (40080119)',
		code: 'DICOMObject["40080119"]',
	},
	{
		label: 'RETIRED_DistributionAddress (4008011A)',
		code: 'DICOMObject["4008011A"]',
	},
	{
		label: 'RETIRED_InterpretationID (40080200)',
		code: 'DICOMObject["40080200"]',
	},
	{
		label: 'RETIRED_InterpretationIDIssuer (40080202)',
		code: 'DICOMObject["40080202"]',
	},
	{
		label: 'RETIRED_InterpretationTypeID (40080210)',
		code: 'DICOMObject["40080210"]',
	},
	{
		label: 'RETIRED_InterpretationStatusID (40080212)',
		code: 'DICOMObject["40080212"]',
	},
	{
		label: 'RETIRED_Impressions (40080300)',
		code: 'DICOMObject["40080300"]',
	},
	{
		label: 'RETIRED_ResultsComments (40084000)',
		code: 'DICOMObject["40084000"]',
	},
	{
		label: 'RETIRED_CurveDimensions (50000005)',
		code: 'DICOMObject["50000005"]',
	},
	{
		label: 'RETIRED_NumberOfPoints (50000010)',
		code: 'DICOMObject["50000010"]',
	},
	{
		label: 'RETIRED_TypeOfData (50000020)',
		code: 'DICOMObject["50000020"]',
	},
	{
		label: 'RETIRED_CurveDescription (50000022)',
		code: 'DICOMObject["50000022"]',
	},
	{
		label: 'RETIRED_AxisUnits (50000030)',
		code: 'DICOMObject["50000030"]',
	},
	{
		label: 'RETIRED_AxisLabels (50000040)',
		code: 'DICOMObject["50000040"]',
	},
	{
		label: 'RETIRED_DataValueRepresentation (50000103)',
		code: 'DICOMObject["50000103"]',
	},
	{
		label: 'RETIRED_MinimumCoordinateValue (50000104)',
		code: 'DICOMObject["50000104"]',
	},
	{
		label: 'RETIRED_MaximumCoordinateValue (50000105)',
		code: 'DICOMObject["50000105"]',
	},
	{
		label: 'RETIRED_CurveRange (50000106)',
		code: 'DICOMObject["50000106"]',
	},
	{
		label: 'RETIRED_CurveDataDescriptor (50000110)',
		code: 'DICOMObject["50000110"]',
	},
	{
		label: 'RETIRED_CoordinateStartValue (50000112)',
		code: 'DICOMObject["50000112"]',
	},
	{
		label: 'RETIRED_CoordinateStepValue (50000114)',
		code: 'DICOMObject["50000114"]',
	},
	{
		label: 'RETIRED_CurveActivationLayer (50001001)',
		code: 'DICOMObject["50001001"]',
	},
	{
		label: 'RETIRED_AudioType (50002000)',
		code: 'DICOMObject["50002000"]',
	},
	{
		label: 'RETIRED_AudioSampleFormat (50002002)',
		code: 'DICOMObject["50002002"]',
	},
	{
		label: 'RETIRED_NumberOfChannels (50002004)',
		code: 'DICOMObject["50002004"]',
	},
	{
		label: 'RETIRED_NumberOfSamples (50002006)',
		code: 'DICOMObject["50002006"]',
	},
	{
		label: 'RETIRED_SampleRate (50002008)',
		code: 'DICOMObject["50002008"]',
	},
	{
		label: 'RETIRED_TotalTime (5000200A)',
		code: 'DICOMObject["5000200A"]',
	},
	{
		label: 'RETIRED_AudioSampleData (5000200C)',
		code: 'DICOMObject["5000200C"]',
	},
	{
		label: 'RETIRED_AudioComments (5000200E)',
		code: 'DICOMObject["5000200E"]',
	},
	{
		label: 'RETIRED_CurveLabel (50002500)',
		code: 'DICOMObject["50002500"]',
	},
	{
		label: 'RETIRED_CurveReferencedOverlaySequence (50002600)',
		code: 'DICOMObject["50002600"]',
	},
	{
		label: 'RETIRED_CurveReferencedOverlayGroup (50002610)',
		code: 'DICOMObject["50002610"]',
	},
	{
		label: 'RETIRED_CurveData (50003000)',
		code: 'DICOMObject["50003000"]',
	},
	{
		label: 'RETIRED_OverlayPlanes (60000012)',
		code: 'DICOMObject["60000012"]',
	},
	{
		label: 'RETIRED_OverlayPlaneOrigin (60000052)',
		code: 'DICOMObject["60000052"]',
	},
	{
		label: 'RETIRED_OverlayCompressionCode (60000060)',
		code: 'DICOMObject["60000060"]',
	},
	{
		label: 'RETIRED_OverlayCompressionOriginator (60000061)',
		code: 'DICOMObject["60000061"]',
	},
	{
		label: 'RETIRED_OverlayCompressionLabel (60000062)',
		code: 'DICOMObject["60000062"]',
	},
	{
		label: 'RETIRED_OverlayCompressionDescription (60000063)',
		code: 'DICOMObject["60000063"]',
	},
	{
		label: 'RETIRED_OverlayCompressionStepPointers (60000066)',
		code: 'DICOMObject["60000066"]',
	},
	{
		label: 'RETIRED_OverlayRepeatInterval (60000068)',
		code: 'DICOMObject["60000068"]',
	},
	{
		label: 'RETIRED_OverlayBitsGrouped (60000069)',
		code: 'DICOMObject["60000069"]',
	},
	{
		label: 'RETIRED_OverlayFormat (60000110)',
		code: 'DICOMObject["60000110"]',
	},
	{
		label: 'RETIRED_OverlayLocation (60000200)',
		code: 'DICOMObject["60000200"]',
	},
	{
		label: 'RETIRED_OverlayCodeLabel (60000800)',
		code: 'DICOMObject["60000800"]',
	},
	{
		label: 'RETIRED_OverlayNumberOfTables (60000802)',
		code: 'DICOMObject["60000802"]',
	},
	{
		label: 'RETIRED_OverlayCodeTableLocation (60000803)',
		code: 'DICOMObject["60000803"]',
	},
	{
		label: 'RETIRED_OverlayBitsForCodeWord (60000804)',
		code: 'DICOMObject["60000804"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorGray (60001100)',
		code: 'DICOMObject["60001100"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorRed (60001101)',
		code: 'DICOMObject["60001101"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorGreen (60001102)',
		code: 'DICOMObject["60001102"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorBlue (60001103)',
		code: 'DICOMObject["60001103"]',
	},
	{
		label: 'RETIRED_OverlaysGray (60001200)',
		code: 'DICOMObject["60001200"]',
	},
	{
		label: 'RETIRED_OverlaysRed (60001201)',
		code: 'DICOMObject["60001201"]',
	},
	{
		label: 'RETIRED_OverlaysGreen (60001202)',
		code: 'DICOMObject["60001202"]',
	},
	{
		label: 'RETIRED_OverlaysBlue (60001203)',
		code: 'DICOMObject["60001203"]',
	},
	{
		label: 'RETIRED_OverlayComments (60004000)',
		code: 'DICOMObject["60004000"]',
	},
	{
		label: 'RETIRED_CoefficientsSDVN (7FE00020)',
		code: 'DICOMObject["7FE00020"]',
	},
	{
		label: 'RETIRED_CoefficientsSDHN (7FE00030)',
		code: 'DICOMObject["7FE00030"]',
	},
	{
		label: 'RETIRED_CoefficientsSDDN (7FE00040)',
		code: 'DICOMObject["7FE00040"]',
	},
	{
		label: 'RETIRED_VariablePixelData (7F000010)',
		code: 'DICOMObject["7F000010"]',
	},
	{
		label: 'RETIRED_VariableNextDataGroup (7F000011)',
		code: 'DICOMObject["7F000011"]',
	},
	{
		label: 'RETIRED_VariableCoefficientsSDVN (7F000020)',
		code: 'DICOMObject["7F000020"]',
	},
	{
		label: 'RETIRED_VariableCoefficientsSDHN (7F000030)',
		code: 'DICOMObject["7F000030"]',
	},
	{
		label: 'RETIRED_VariableCoefficientsSDDN (7F000040)',
		code: 'DICOMObject["7F000040"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'undefined (#---------------------------------------------------------------------------)',
		code: 'DICOMObject["#---------------------------------------------------------------------------"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'undefined (# Private Creator Data Elements)',
		code: 'DICOMObject["# Private Creator Data Elements"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'PrivateGroupLength (00090000)',
		code: 'DICOMObject["00090000"]',
	},
	{
		label: 'PrivateCreator (00090010-u-00FF)',
		code: 'DICOMObject["00090010-u-00FF"]',
	},
	{
		label: 'IllegalGroupLength (00010000)',
		code: 'DICOMObject["00010000"]',
	},
	{
		label: 'IllegalPrivateCreator (00010010-u-00FF)',
		code: 'DICOMObject["00010010-u-00FF"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'undefined (#---------------------------------------------------------------------------)',
		code: 'DICOMObject["#---------------------------------------------------------------------------"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'undefined (# A "catch all" for group length elements)',
		code: 'DICOMObject["# A "catch all" for group length elements"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'GenericGroupLength (00000000)',
		code: 'DICOMObject["00000000"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'undefined (#---------------------------------------------------------------------------)',
		code: 'DICOMObject["#---------------------------------------------------------------------------"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'undefined (# Retired data elements from previous editions of the DICOM standard)',
		code: 'DICOMObject["# Retired data elements from previous editions of the DICOM standard"]',
	},
	{
		label: 'undefined (#)',
		code: 'DICOMObject["#"]',
	},
	{
		label: 'RETIRED_CommandLengthToEnd (00000001)',
		code: 'DICOMObject["00000001"]',
	},
	{
		label: 'RETIRED_CommandRecognitionCode (00000010)',
		code: 'DICOMObject["00000010"]',
	},
	{
		label: 'RETIRED_Initiator (00000200)',
		code: 'DICOMObject["00000200"]',
	},
	{
		label: 'RETIRED_Receiver (00000300)',
		code: 'DICOMObject["00000300"]',
	},
	{
		label: 'RETIRED_FindLocation (00000400)',
		code: 'DICOMObject["00000400"]',
	},
	{
		label: 'RETIRED_NumberOfMatches (00000850)',
		code: 'DICOMObject["00000850"]',
	},
	{
		label: 'RETIRED_ResponseSequenceNumber (00000860)',
		code: 'DICOMObject["00000860"]',
	},
	{
		label: 'RETIRED_DialogReceiver (00004000)',
		code: 'DICOMObject["00004000"]',
	},
	{
		label: 'RETIRED_TerminalType (00004010)',
		code: 'DICOMObject["00004010"]',
	},
	{
		label: 'RETIRED_MessageSetID (00005010)',
		code: 'DICOMObject["00005010"]',
	},
	{
		label: 'RETIRED_EndMessageID (00005020)',
		code: 'DICOMObject["00005020"]',
	},
	{
		label: 'RETIRED_DisplayFormat (00005110)',
		code: 'DICOMObject["00005110"]',
	},
	{
		label: 'RETIRED_PagePositionID (00005120)',
		code: 'DICOMObject["00005120"]',
	},
	{
		label: 'RETIRED_TextFormatID (00005130)',
		code: 'DICOMObject["00005130"]',
	},
	{
		label: 'RETIRED_NormalReverse (00005140)',
		code: 'DICOMObject["00005140"]',
	},
	{
		label: 'RETIRED_AddGrayScale (00005150)',
		code: 'DICOMObject["00005150"]',
	},
	{
		label: 'RETIRED_Borders (00005160)',
		code: 'DICOMObject["00005160"]',
	},
	{
		label: 'RETIRED_Copies (00005170)',
		code: 'DICOMObject["00005170"]',
	},
	{
		label: 'RETIRED_CommandMagnificationType (00005180)',
		code: 'DICOMObject["00005180"]',
	},
	{
		label: 'RETIRED_Erase (00005190)',
		code: 'DICOMObject["00005190"]',
	},
	{
		label: 'RETIRED_Print (000051A0)',
		code: 'DICOMObject["000051A0"]',
	},
	{
		label: 'RETIRED_Overlays (000051B0)',
		code: 'DICOMObject["000051B0"]',
	},
	{
		label: 'RETIRED_MRDRDirectoryRecordOffset (00041504)',
		code: 'DICOMObject["00041504"]',
	},
	{
		label: 'RETIRED_NumberOfReferences (00041600)',
		code: 'DICOMObject["00041600"]',
	},
	{
		label: 'RETIRED_LengthToEnd (00080001)',
		code: 'DICOMObject["00080001"]',
	},
	{
		label: 'RETIRED_RecognitionCode (00080010)',
		code: 'DICOMObject["00080010"]',
	},
	{
		label: 'RETIRED_OverlayDate (00080024)',
		code: 'DICOMObject["00080024"]',
	},
	{
		label: 'RETIRED_CurveDate (00080025)',
		code: 'DICOMObject["00080025"]',
	},
	{
		label: 'RETIRED_OverlayTime (00080034)',
		code: 'DICOMObject["00080034"]',
	},
	{
		label: 'RETIRED_CurveTime (00080035)',
		code: 'DICOMObject["00080035"]',
	},
	{
		label: 'RETIRED_DataSetType (00080040)',
		code: 'DICOMObject["00080040"]',
	},
	{
		label: 'RETIRED_DataSetSubtype (00080041)',
		code: 'DICOMObject["00080041"]',
	},
	{
		label: 'RETIRED_NuclearMedicineSeriesType (00080042)',
		code: 'DICOMObject["00080042"]',
	},
	{
		label: 'RETIRED_NetworkID (00081000)',
		code: 'DICOMObject["00081000"]',
	},
	{
		label: 'RETIRED_ReferencedResultsSequence (00081100)',
		code: 'DICOMObject["00081100"]',
	},
	{
		label: 'RETIRED_ReferencedOverlaySequence (00081130)',
		code: 'DICOMObject["00081130"]',
	},
	{
		label: 'RETIRED_ReferencedCurveSequence (00081145)',
		code: 'DICOMObject["00081145"]',
	},
	{
		label: 'RETIRED_LossyImageCompressionRetired (00082110)',
		code: 'DICOMObject["00082110"]',
	},
	{
		label: 'RETIRED_TransducerPosition (00082200)',
		code: 'DICOMObject["00082200"]',
	},
	{
		label: 'RETIRED_TransducerOrientation (00082204)',
		code: 'DICOMObject["00082204"]',
	},
	{
		label: 'RETIRED_AnatomicStructure (00082208)',
		code: 'DICOMObject["00082208"]',
	},
	{
		label: 'RETIRED_TransducerPositionSequence (00082240)',
		code: 'DICOMObject["00082240"]',
	},
	{
		label: 'RETIRED_TransducerPositionModifierSequence (00082242)',
		code: 'DICOMObject["00082242"]',
	},
	{
		label: 'RETIRED_TransducerOrientationSequence (00082244)',
		code: 'DICOMObject["00082244"]',
	},
	{
		label: 'RETIRED_TransducerOrientationModifierSequence (00082246)',
		code: 'DICOMObject["00082246"]',
	},
	{
		label: 'RETIRED_AnatomicStructureSpaceOrRegionCodeSequenceTrial (00082251)',
		code: 'DICOMObject["00082251"]',
	},
	{
		label: 'RETIRED_AnatomicPortalOfEntranceCodeSequenceTrial (00082253)',
		code: 'DICOMObject["00082253"]',
	},
	{
		label: 'RETIRED_AnatomicApproachDirectionCodeSequenceTrial (00082255)',
		code: 'DICOMObject["00082255"]',
	},
	{
		label: 'RETIRED_AnatomicPerspectiveDescriptionTrial (00082256)',
		code: 'DICOMObject["00082256"]',
	},
	{
		label: 'RETIRED_AnatomicPerspectiveCodeSequenceTrial (00082257)',
		code: 'DICOMObject["00082257"]',
	},
	{
		label: 'RETIRED_AnatomicLocationOfExaminingInstrumentDescriptionTrial (00082258)',
		code: 'DICOMObject["00082258"]',
	},
	{
		label: 'RETIRED_AnatomicLocationOfExaminingInstrumentCodeSequenceTrial (00082259)',
		code: 'DICOMObject["00082259"]',
	},
	{
		label: 'RETIRED_AnatomicStructureSpaceOrRegionModifierCodeSequenceTrial (0008225A)',
		code: 'DICOMObject["0008225A"]',
	},
	{
		label: 'RETIRED_OnAxisBackgroundAnatomicStructureCodeSequenceTrial (0008225C)',
		code: 'DICOMObject["0008225C"]',
	},
	{
		label: 'RETIRED_IdentifyingComments (00084000)',
		code: 'DICOMObject["00084000"]',
	},
	{
		label: 'RETIRED_InsurancePlanIdentification (00101050)',
		code: 'DICOMObject["00101050"]',
	},
	{
		label: 'RETIRED_CADFileFormat (00140023)',
		code: 'DICOMObject["00140023"]',
	},
	{
		label: 'RETIRED_ComponentReferenceSystem (00140024)',
		code: 'DICOMObject["00140024"]',
	},
	{
		label: 'RETIRED_MaterialPropertiesFileFormatRetired (00140045)',
		code: 'DICOMObject["00140045"]',
	},
	{
		label: 'RETIRED_Radionuclide (00180030)',
		code: 'DICOMObject["00180030"]',
	},
	{
		label: 'RETIRED_EnergyWindowCenterline (00180032)',
		code: 'DICOMObject["00180032"]',
	},
	{
		label: 'RETIRED_EnergyWindowTotalWidth (00180033)',
		code: 'DICOMObject["00180033"]',
	},
	{
		label: 'RETIRED_TherapyType (00180037)',
		code: 'DICOMObject["00180037"]',
	},
	{
		label: 'RETIRED_TherapyDescription (00180039)',
		code: 'DICOMObject["00180039"]',
	},
	{
		label: 'RETIRED_HardcopyCreationDeviceID (00181011)',
		code: 'DICOMObject["00181011"]',
	},
	{
		label: 'RETIRED_HardcopyDeviceManufacturer (00181017)',
		code: 'DICOMObject["00181017"]',
	},
	{
		label: 'RETIRED_HardcopyDeviceSoftwareVersion (0018101A)',
		code: 'DICOMObject["0018101A"]',
	},
	{
		label: 'RETIRED_HardcopyDeviceManufacturerModelName (0018101B)',
		code: 'DICOMObject["0018101B"]',
	},
	{
		label: 'RETIRED_AngularPosition (00181141)',
		code: 'DICOMObject["00181141"]',
	},
	{
		label: 'RETIRED_RotationOffset (00181146)',
		code: 'DICOMObject["00181146"]',
	},
	{
		label: 'RETIRED_UpperLowerPixelValues (00181240)',
		code: 'DICOMObject["00181240"]',
	},
	{
		label: 'RETIRED_AcquisitionComments (00184000)',
		code: 'DICOMObject["00184000"]',
	},
	{
		label: 'RETIRED_PostprocessingFunction (00185021)',
		code: 'DICOMObject["00185021"]',
	},
	{
		label: 'RETIRED_DynamicRange (00185030)',
		code: 'DICOMObject["00185030"]',
	},
	{
		label: 'RETIRED_TotalGain (00185040)',
		code: 'DICOMObject["00185040"]',
	},
	{
		label: 'RETIRED_ImageTransformationMatrix (00185210)',
		code: 'DICOMObject["00185210"]',
	},
	{
		label: 'RETIRED_ImageTranslationVector (00185212)',
		code: 'DICOMObject["00185212"]',
	},
	{
		label: 'RETIRED_DopplerSampleVolumeXPositionRetired (00186038)',
		code: 'DICOMObject["00186038"]',
	},
	{
		label: 'RETIRED_DopplerSampleVolumeYPositionRetired (0018603A)',
		code: 'DICOMObject["0018603A"]',
	},
	{
		label: 'RETIRED_TMLinePositionX0Retired (0018603C)',
		code: 'DICOMObject["0018603C"]',
	},
	{
		label: 'RETIRED_TMLinePositionY0Retired (0018603E)',
		code: 'DICOMObject["0018603E"]',
	},
	{
		label: 'RETIRED_TMLinePositionX1Retired (00186040)',
		code: 'DICOMObject["00186040"]',
	},
	{
		label: 'RETIRED_TMLinePositionY1Retired (00186042)',
		code: 'DICOMObject["00186042"]',
	},
	{
		label: 'RETIRED_ParallelReductionFactorInPlaneRetired (00189096)',
		code: 'DICOMObject["00189096"]',
	},
	{
		label: 'RETIRED_BulkMotionStatus (00189166)',
		code: 'DICOMObject["00189166"]',
	},
	{
		label: 'RETIRED_ChemicalShiftMinimumIntegrationLimitInHz (00189195)',
		code: 'DICOMObject["00189195"]',
	},
	{
		label: 'RETIRED_ChemicalShiftMaximumIntegrationLimitInHz (00189196)',
		code: 'DICOMObject["00189196"]',
	},
	{
		label: 'RETIRED_IsotopeNumber (00200014)',
		code: 'DICOMObject["00200014"]',
	},
	{
		label: 'RETIRED_PhaseNumber (00200015)',
		code: 'DICOMObject["00200015"]',
	},
	{
		label: 'RETIRED_IntervalNumber (00200016)',
		code: 'DICOMObject["00200016"]',
	},
	{
		label: 'RETIRED_TimeSlotNumber (00200017)',
		code: 'DICOMObject["00200017"]',
	},
	{
		label: 'RETIRED_AngleNumber (00200018)',
		code: 'DICOMObject["00200018"]',
	},
	{
		label: 'RETIRED_OverlayNumber (00200022)',
		code: 'DICOMObject["00200022"]',
	},
	{
		label: 'RETIRED_CurveNumber (00200024)',
		code: 'DICOMObject["00200024"]',
	},
	{
		label: 'RETIRED_LUTNumber (00200026)',
		code: 'DICOMObject["00200026"]',
	},
	{
		label: 'RETIRED_ImagePosition (00200030)',
		code: 'DICOMObject["00200030"]',
	},
	{
		label: 'RETIRED_ImageOrientation (00200035)',
		code: 'DICOMObject["00200035"]',
	},
	{
		label: 'RETIRED_Location (00200050)',
		code: 'DICOMObject["00200050"]',
	},
	{
		label: 'RETIRED_ImageGeometryType (00200070)',
		code: 'DICOMObject["00200070"]',
	},
	{
		label: 'RETIRED_MaskingImage (00200080)',
		code: 'DICOMObject["00200080"]',
	},
	{
		label: 'RETIRED_ReportNumber (002000AA)',
		code: 'DICOMObject["002000AA"]',
	},
	{
		label: 'RETIRED_SeriesInStudy (00201000)',
		code: 'DICOMObject["00201000"]',
	},
	{
		label: 'RETIRED_AcquisitionsInSeries (00201001)',
		code: 'DICOMObject["00201001"]',
	},
	{
		label: 'RETIRED_ImagesInSeries (00201003)',
		code: 'DICOMObject["00201003"]',
	},
	{
		label: 'RETIRED_AcquisitionsInStudy (00201004)',
		code: 'DICOMObject["00201004"]',
	},
	{
		label: 'RETIRED_ImagesInStudy (00201005)',
		code: 'DICOMObject["00201005"]',
	},
	{
		label: 'RETIRED_Reference (00201020)',
		code: 'DICOMObject["00201020"]',
	},
	{
		label: 'RETIRED_OtherStudyNumbers (00201070)',
		code: 'DICOMObject["00201070"]',
	},
	{
		label: 'RETIRED_SourceImageIDs (00203100-31FF)',
		code: 'DICOMObject["00203100-31FF"]',
	},
	{
		label: 'RETIRED_ModifyingDeviceID (00203401)',
		code: 'DICOMObject["00203401"]',
	},
	{
		label: 'RETIRED_ModifiedImageID (00203402)',
		code: 'DICOMObject["00203402"]',
	},
	{
		label: 'RETIRED_ModifiedImageDate (00203403)',
		code: 'DICOMObject["00203403"]',
	},
	{
		label: 'RETIRED_ModifyingDeviceManufacturer (00203404)',
		code: 'DICOMObject["00203404"]',
	},
	{
		label: 'RETIRED_ModifiedImageTime (00203405)',
		code: 'DICOMObject["00203405"]',
	},
	{
		label: 'RETIRED_ModifiedImageDescription (00203406)',
		code: 'DICOMObject["00203406"]',
	},
	{
		label: 'RETIRED_OriginalImageIdentification (00205000)',
		code: 'DICOMObject["00205000"]',
	},
	{
		label: 'RETIRED_OriginalImageIdentificationNomenclature (00205002)',
		code: 'DICOMObject["00205002"]',
	},
	{
		label: 'RETIRED_LensConstantDescription (00221094)',
		code: 'DICOMObject["00221094"]',
	},
	{
		label: 'RETIRED_OphthalmicAxialLengthAcquisitionMethodCodeSequence (00221153)',
		code: 'DICOMObject["00221153"]',
	},
	{
		label: 'RETIRED_OphthalmicAxialLengthQualityMetricTypeCodeSequence (00221265)',
		code: 'DICOMObject["00221265"]',
	},
	{
		label: 'RETIRED_OphthalmicAxialLengthQualityMetricTypeDescription (00221273)',
		code: 'DICOMObject["00221273"]',
	},
	{
		label: 'RETIRED_ImageDimensions (00280005)',
		code: 'DICOMObject["00280005"]',
	},
	{
		label: 'RETIRED_Planes (00280012)',
		code: 'DICOMObject["00280012"]',
	},
	{
		label: 'RETIRED_ImageFormat (00280040)',
		code: 'DICOMObject["00280040"]',
	},
	{
		label: 'RETIRED_ManipulatedImage (00280050)',
		code: 'DICOMObject["00280050"]',
	},
	{
		label: 'RETIRED_CompressionRecognitionCode (0028005F)',
		code: 'DICOMObject["0028005F"]',
	},
	{
		label: 'RETIRED_CompressionCode (00280060)',
		code: 'DICOMObject["00280060"]',
	},
	{
		label: 'RETIRED_CompressionOriginator (00280061)',
		code: 'DICOMObject["00280061"]',
	},
	{
		label: 'RETIRED_CompressionLabel (00280062)',
		code: 'DICOMObject["00280062"]',
	},
	{
		label: 'RETIRED_CompressionDescription (00280063)',
		code: 'DICOMObject["00280063"]',
	},
	{
		label: 'RETIRED_CompressionSequence (00280065)',
		code: 'DICOMObject["00280065"]',
	},
	{
		label: 'RETIRED_CompressionStepPointers (00280066)',
		code: 'DICOMObject["00280066"]',
	},
	{
		label: 'RETIRED_RepeatInterval (00280068)',
		code: 'DICOMObject["00280068"]',
	},
	{
		label: 'RETIRED_BitsGrouped (00280069)',
		code: 'DICOMObject["00280069"]',
	},
	{
		label: 'RETIRED_PerimeterTable (00280070)',
		code: 'DICOMObject["00280070"]',
	},
	{
		label: 'RETIRED_PerimeterValue (00280071)',
		code: 'DICOMObject["00280071"]',
	},
	{
		label: 'RETIRED_PredictorRows (00280080)',
		code: 'DICOMObject["00280080"]',
	},
	{
		label: 'RETIRED_PredictorColumns (00280081)',
		code: 'DICOMObject["00280081"]',
	},
	{
		label: 'RETIRED_PredictorConstants (00280082)',
		code: 'DICOMObject["00280082"]',
	},
	{
		label: 'RETIRED_BlockedPixels (00280090)',
		code: 'DICOMObject["00280090"]',
	},
	{
		label: 'RETIRED_BlockRows (00280091)',
		code: 'DICOMObject["00280091"]',
	},
	{
		label: 'RETIRED_BlockColumns (00280092)',
		code: 'DICOMObject["00280092"]',
	},
	{
		label: 'RETIRED_RowOverlap (00280093)',
		code: 'DICOMObject["00280093"]',
	},
	{
		label: 'RETIRED_ColumnOverlap (00280094)',
		code: 'DICOMObject["00280094"]',
	},
	{
		label: 'RETIRED_SmallestValidPixelValue (00280104)',
		code: 'DICOMObject["00280104"]',
	},
	{
		label: 'RETIRED_LargestValidPixelValue (00280105)',
		code: 'DICOMObject["00280105"]',
	},
	{
		label: 'RETIRED_SmallestImagePixelValueInPlane (00280110)',
		code: 'DICOMObject["00280110"]',
	},
	{
		label: 'RETIRED_LargestImagePixelValueInPlane (00280111)',
		code: 'DICOMObject["00280111"]',
	},
	{
		label: 'RETIRED_ImageLocation (00280200)',
		code: 'DICOMObject["00280200"]',
	},
	{
		label: 'RETIRED_TransformLabel (00280400)',
		code: 'DICOMObject["00280400"]',
	},
	{
		label: 'RETIRED_TransformVersionNumber (00280401)',
		code: 'DICOMObject["00280401"]',
	},
	{
		label: 'RETIRED_NumberOfTransformSteps (00280402)',
		code: 'DICOMObject["00280402"]',
	},
	{
		label: 'RETIRED_SequenceOfCompressedData (00280403)',
		code: 'DICOMObject["00280403"]',
	},
	{
		label: 'RETIRED_DetailsOfCoefficients (00280404)',
		code: 'DICOMObject["00280404"]',
	},
	{
		label: 'undefined (# according to the DICOM standard the following 4 attributes)',
		code: 'DICOMObject["# according to the DICOM standard the following 4 attributes"]',
	},
	{
		label: 'undefined (# should have a tag in the range of 002804x1 to 002804x3)',
		code: 'DICOMObject["# should have a tag in the range of 002804x1 to 002804x3"]',
	},
	{
		label: 'RETIRED_RowsForNthOrderCoefficients (00280410)',
		code: 'DICOMObject["00280410"]',
	},
	{
		label: 'RETIRED_ColumnsForNthOrderCoefficients (00280411)',
		code: 'DICOMObject["00280411"]',
	},
	{
		label: 'RETIRED_CoefficientCoding (00280412)',
		code: 'DICOMObject["00280412"]',
	},
	{
		label: 'RETIRED_CoefficientCodingPointers (00280413)',
		code: 'DICOMObject["00280413"]',
	},
	{
		label: 'RETIRED_DCTLabel (00280700)',
		code: 'DICOMObject["00280700"]',
	},
	{
		label: 'RETIRED_DataBlockDescription (00280701)',
		code: 'DICOMObject["00280701"]',
	},
	{
		label: 'RETIRED_DataBlock (00280702)',
		code: 'DICOMObject["00280702"]',
	},
	{
		label: 'RETIRED_NormalizationFactorFormat (00280710)',
		code: 'DICOMObject["00280710"]',
	},
	{
		label: 'RETIRED_ZonalMapNumberFormat (00280720)',
		code: 'DICOMObject["00280720"]',
	},
	{
		label: 'RETIRED_ZonalMapLocation (00280721)',
		code: 'DICOMObject["00280721"]',
	},
	{
		label: 'RETIRED_ZonalMapFormat (00280722)',
		code: 'DICOMObject["00280722"]',
	},
	{
		label: 'RETIRED_AdaptiveMapFormat (00280730)',
		code: 'DICOMObject["00280730"]',
	},
	{
		label: 'RETIRED_CodeNumberFormat (00280740)',
		code: 'DICOMObject["00280740"]',
	},
	{
		label: 'undefined (# according to the DICOM standard the following 5 attributes)',
		code: 'DICOMObject["# according to the DICOM standard the following 5 attributes"]',
	},
	{
		label: 'undefined (# should have a tag in the range of 002808x0 to 002808x8)',
		code: 'DICOMObject["# should have a tag in the range of 002808x0 to 002808x8"]',
	},
	{
		label: 'RETIRED_CodeLabel (00280800)',
		code: 'DICOMObject["00280800"]',
	},
	{
		label: 'RETIRED_NumberOfTables (00280802)',
		code: 'DICOMObject["00280802"]',
	},
	{
		label: 'RETIRED_CodeTableLocation (00280803)',
		code: 'DICOMObject["00280803"]',
	},
	{
		label: 'RETIRED_BitsForCodeWord (00280804)',
		code: 'DICOMObject["00280804"]',
	},
	{
		label: 'RETIRED_ImageDataLocation (00280808)',
		code: 'DICOMObject["00280808"]',
	},
	{
		label: 'RETIRED_GrayScale (00281080)',
		code: 'DICOMObject["00281080"]',
	},
	{
		label: 'RETIRED_GrayLookupTableDescriptor (00281100)',
		code: 'DICOMObject["00281100"]',
	},
	{
		label: 'RETIRED_LargeRedPaletteColorLookupTableDescriptor (00281111)',
		code: 'DICOMObject["00281111"]',
	},
	{
		label: 'RETIRED_LargeGreenPaletteColorLookupTableDescriptor (00281112)',
		code: 'DICOMObject["00281112"]',
	},
	{
		label: 'RETIRED_LargeBluePaletteColorLookupTableDescriptor (00281113)',
		code: 'DICOMObject["00281113"]',
	},
	{
		label: 'RETIRED_GrayLookupTableData (00281200)',
		code: 'DICOMObject["00281200"]',
	},
	{
		label: 'RETIRED_LargeRedPaletteColorLookupTableData (00281211)',
		code: 'DICOMObject["00281211"]',
	},
	{
		label: 'RETIRED_LargeGreenPaletteColorLookupTableData (00281212)',
		code: 'DICOMObject["00281212"]',
	},
	{
		label: 'RETIRED_LargeBluePaletteColorLookupTableData (00281213)',
		code: 'DICOMObject["00281213"]',
	},
	{
		label: 'RETIRED_LargePaletteColorLookupTableUID (00281214)',
		code: 'DICOMObject["00281214"]',
	},
	{
		label: 'RETIRED_ImagePresentationComments (00284000)',
		code: 'DICOMObject["00284000"]',
	},
	{
		label: 'RETIRED_BiPlaneAcquisitionSequence (00285000)',
		code: 'DICOMObject["00285000"]',
	},
	{
		label: 'RETIRED_MaskPointers (00286030)',
		code: 'DICOMObject["00286030"]',
	},
	{
		label: 'RETIRED_LargestMonochromePixelValue (00289099)',
		code: 'DICOMObject["00289099"]',
	},
	{
		label: 'RETIRED_StudyStatusID (0032000A)',
		code: 'DICOMObject["0032000A"]',
	},
	{
		label: 'RETIRED_StudyPriorityID (0032000C)',
		code: 'DICOMObject["0032000C"]',
	},
	{
		label: 'RETIRED_StudyIDIssuer (00320012)',
		code: 'DICOMObject["00320012"]',
	},
	{
		label: 'RETIRED_StudyVerifiedDate (00320032)',
		code: 'DICOMObject["00320032"]',
	},
	{
		label: 'RETIRED_StudyVerifiedTime (00320033)',
		code: 'DICOMObject["00320033"]',
	},
	{
		label: 'RETIRED_StudyReadDate (00320034)',
		code: 'DICOMObject["00320034"]',
	},
	{
		label: 'RETIRED_StudyReadTime (00320035)',
		code: 'DICOMObject["00320035"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStartDate (00321000)',
		code: 'DICOMObject["00321000"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStartTime (00321001)',
		code: 'DICOMObject["00321001"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStopDate (00321010)',
		code: 'DICOMObject["00321010"]',
	},
	{
		label: 'RETIRED_ScheduledStudyStopTime (00321011)',
		code: 'DICOMObject["00321011"]',
	},
	{
		label: 'RETIRED_ScheduledStudyLocation (00321020)',
		code: 'DICOMObject["00321020"]',
	},
	{
		label: 'RETIRED_ScheduledStudyLocationAETitle (00321021)',
		code: 'DICOMObject["00321021"]',
	},
	{
		label: 'RETIRED_ReasonForStudy (00321030)',
		code: 'DICOMObject["00321030"]',
	},
	{
		label: 'RETIRED_StudyArrivalDate (00321040)',
		code: 'DICOMObject["00321040"]',
	},
	{
		label: 'RETIRED_StudyArrivalTime (00321041)',
		code: 'DICOMObject["00321041"]',
	},
	{
		label: 'RETIRED_StudyCompletionDate (00321050)',
		code: 'DICOMObject["00321050"]',
	},
	{
		label: 'RETIRED_StudyCompletionTime (00321051)',
		code: 'DICOMObject["00321051"]',
	},
	{
		label: 'RETIRED_StudyComponentStatusID (00321055)',
		code: 'DICOMObject["00321055"]',
	},
	{
		label: 'RETIRED_StudyComments (00324000)',
		code: 'DICOMObject["00324000"]',
	},
	{
		label: 'RETIRED_IssuerOfAdmissionID (00380011)',
		code: 'DICOMObject["00380011"]',
	},
	{
		label: 'RETIRED_ScheduledAdmissionDate (0038001A)',
		code: 'DICOMObject["0038001A"]',
	},
	{
		label: 'RETIRED_ScheduledAdmissionTime (0038001B)',
		code: 'DICOMObject["0038001B"]',
	},
	{
		label: 'RETIRED_ScheduledDischargeDate (0038001C)',
		code: 'DICOMObject["0038001C"]',
	},
	{
		label: 'RETIRED_ScheduledDischargeTime (0038001D)',
		code: 'DICOMObject["0038001D"]',
	},
	{
		label: 'RETIRED_ScheduledPatientInstitutionResidence (0038001E)',
		code: 'DICOMObject["0038001E"]',
	},
	{
		label: 'RETIRED_DischargeDate (00380030)',
		code: 'DICOMObject["00380030"]',
	},
	{
		label: 'RETIRED_DischargeTime (00380032)',
		code: 'DICOMObject["00380032"]',
	},
	{
		label: 'RETIRED_DischargeDiagnosisDescription (00380040)',
		code: 'DICOMObject["00380040"]',
	},
	{
		label: 'RETIRED_DischargeDiagnosisCodeSequence (00380044)',
		code: 'DICOMObject["00380044"]',
	},
	{
		label: 'RETIRED_IssuerOfServiceEpisodeID (00380061)',
		code: 'DICOMObject["00380061"]',
	},
	{
		label: 'RETIRED_DistanceSourceToSupport (00400307)',
		code: 'DICOMObject["00400307"]',
	},
	{
		label: 'RETIRED_ReferencedProcedureStepSequence (00400330)',
		code: 'DICOMObject["00400330"]',
	},
	{
		label: 'RETIRED_SpecimenAccessionNumber (0040050A)',
		code: 'DICOMObject["0040050A"]',
	},
	{
		label: 'RETIRED_SpecimenSequence (00400550)',
		code: 'DICOMObject["00400550"]',
	},
	{
		label: 'RETIRED_SpecimenDescriptionSequenceTrial (00400552)',
		code: 'DICOMObject["00400552"]',
	},
	{
		label: 'RETIRED_SpecimenDescriptionTrial (00400553)',
		code: 'DICOMObject["00400553"]',
	},
	{
		label: 'RETIRED_SlideIdentifier (004006FA)',
		code: 'DICOMObject["004006FA"]',
	},
	{
		label: 'RETIRED_PixelSpacingSequence (004008D8)',
		code: 'DICOMObject["004008D8"]',
	},
	{
		label: 'RETIRED_CoordinateSystemAxisCodeSequence (004008DA)',
		code: 'DICOMObject["004008DA"]',
	},
	{
		label: 'RETIRED_VitalStainCodeSequenceTrial (004009F8)',
		code: 'DICOMObject["004009F8"]',
	},
	{
		label: 'RETIRED_PlacerOrderNumberProcedure (00401006)',
		code: 'DICOMObject["00401006"]',
	},
	{
		label: 'RETIRED_FillerOrderNumberProcedure (00401007)',
		code: 'DICOMObject["00401007"]',
	},
	{
		label: 'RETIRED_RequestedProcedureDescriptionTrial (00401060)',
		code: 'DICOMObject["00401060"]',
	},
	{
		label: 'RETIRED_ReasonForTheImagingServiceRequest (00402001)',
		code: 'DICOMObject["00402001"]',
	},
	{
		label: 'RETIRED_PlacerOrderNumberImagingServiceRequestRetired (00402006)',
		code: 'DICOMObject["00402006"]',
	},
	{
		label: 'RETIRED_FillerOrderNumberImagingServiceRequestRetired (00402007)',
		code: 'DICOMObject["00402007"]',
	},
	{
		label: 'RETIRED_GeneralPurposeScheduledProcedureStepStatus (00404001)',
		code: 'DICOMObject["00404001"]',
	},
	{
		label: 'RETIRED_GeneralPurposePerformedProcedureStepStatus (00404002)',
		code: 'DICOMObject["00404002"]',
	},
	{
		label: 'RETIRED_GeneralPurposeScheduledProcedureStepPriority (00404003)',
		code: 'DICOMObject["00404003"]',
	},
	{
		label: 'RETIRED_ScheduledProcessingApplicationsCodeSequence (00404004)',
		code: 'DICOMObject["00404004"]',
	},
	{
		label: 'RETIRED_MultipleCopiesFlag (00404006)',
		code: 'DICOMObject["00404006"]',
	},
	{
		label: 'RETIRED_ResultingGeneralPurposePerformedProcedureStepsSequence (00404015)',
		code: 'DICOMObject["00404015"]',
	},
	{
		label: 'RETIRED_ReferencedGeneralPurposeScheduledProcedureStepSequence (00404016)',
		code: 'DICOMObject["00404016"]',
	},
	{
		label: 'RETIRED_RelevantInformationSequence (00404022)',
		code: 'DICOMObject["00404022"]',
	},
	{
		label: 'RETIRED_ReferencedGeneralPurposeScheduledProcedureStepTransactionUID (00404023)',
		code: 'DICOMObject["00404023"]',
	},
	{
		label: 'RETIRED_RequestedSubsequentWorkitemCodeSequence (00404031)',
		code: 'DICOMObject["00404031"]',
	},
	{
		label: 'RETIRED_NonDICOMOutputCodeSequence (00404032)',
		code: 'DICOMObject["00404032"]',
	},
	{
		label: 'RETIRED_FindingsFlagTrial (0040A007)',
		code: 'DICOMObject["0040A007"]',
	},
	{
		label: 'RETIRED_FindingsSequenceTrial (0040A020)',
		code: 'DICOMObject["0040A020"]',
	},
	{
		label: 'RETIRED_FindingsGroupUIDTrial (0040A021)',
		code: 'DICOMObject["0040A021"]',
	},
	{
		label: 'RETIRED_ReferencedFindingsGroupUIDTrial (0040A022)',
		code: 'DICOMObject["0040A022"]',
	},
	{
		label: 'RETIRED_FindingsGroupRecordingDateTrial (0040A023)',
		code: 'DICOMObject["0040A023"]',
	},
	{
		label: 'RETIRED_FindingsGroupRecordingTimeTrial (0040A024)',
		code: 'DICOMObject["0040A024"]',
	},
	{
		label: 'RETIRED_FindingsSourceCategoryCodeSequenceTrial (0040A026)',
		code: 'DICOMObject["0040A026"]',
	},
	{
		label: 'RETIRED_DocumentingOrganizationIdentifierCodeSequenceTrial (0040A028)',
		code: 'DICOMObject["0040A028"]',
	},
	{
		label: 'RETIRED_MeasurementPrecisionDescriptionTrial (0040A047)',
		code: 'DICOMObject["0040A047"]',
	},
	{
		label: 'RETIRED_UrgencyOrPriorityAlertsTrial (0040A057)',
		code: 'DICOMObject["0040A057"]',
	},
	{
		label: 'RETIRED_SequencingIndicatorTrial (0040A060)',
		code: 'DICOMObject["0040A060"]',
	},
	{
		label: 'RETIRED_DocumentIdentifierCodeSequenceTrial (0040A066)',
		code: 'DICOMObject["0040A066"]',
	},
	{
		label: 'RETIRED_DocumentAuthorTrial (0040A067)',
		code: 'DICOMObject["0040A067"]',
	},
	{
		label: 'RETIRED_DocumentAuthorIdentifierCodeSequenceTrial (0040A068)',
		code: 'DICOMObject["0040A068"]',
	},
	{
		label: 'RETIRED_IdentifierCodeSequenceTrial (0040A070)',
		code: 'DICOMObject["0040A070"]',
	},
	{
		label: 'RETIRED_ObjectBinaryIdentifierTrial (0040A074)',
		code: 'DICOMObject["0040A074"]',
	},
	{
		label: 'RETIRED_DocumentingObserverIdentifierCodeSequenceTrial (0040A076)',
		code: 'DICOMObject["0040A076"]',
	},
	{
		label: 'RETIRED_ProcedureIdentifierCodeSequenceTrial (0040A085)',
		code: 'DICOMObject["0040A085"]',
	},
	{
		label: 'RETIRED_ObjectDirectoryBinaryIdentifierTrial (0040A089)',
		code: 'DICOMObject["0040A089"]',
	},
	{
		label: 'RETIRED_EquivalentCDADocumentSequence (0040A090)',
		code: 'DICOMObject["0040A090"]',
	},
	{
		label: 'RETIRED_DateOfDocumentOrVerbalTransactionTrial (0040A110)',
		code: 'DICOMObject["0040A110"]',
	},
	{
		label: 'RETIRED_TimeOfDocumentCreationOrVerbalTransactionTrial (0040A112)',
		code: 'DICOMObject["0040A112"]',
	},
	{
		label: 'RETIRED_ReportStatusIDTrial (0040A125)',
		code: 'DICOMObject["0040A125"]',
	},
	{
		label: 'RETIRED_ObservationCategoryCodeSequenceTrial (0040A167)',
		code: 'DICOMObject["0040A167"]',
	},
	{
		label: 'RETIRED_BibliographicCitationTrial (0040A16A)',
		code: 'DICOMObject["0040A16A"]',
	},
	{
		label: 'undefined (# the following attribute is defined as SQ in the DICOM standard)',
		code: 'DICOMObject["# the following attribute is defined as SQ in the DICOM standard"]',
	},
	{
		label: 'undefined (# it was defined as CS in Supplement 23 frozen draft from 1997.)',
		code: 'DICOMObject["# it was defined as CS in Supplement 23 frozen draft from 1997."]',
	},
	{
		label: 'undefined (#0040A170    CS  RETIRED_ObservationClassTrial   1   DICOM/retired)',
		code: 'DICOMObject["#0040A170    CS  RETIRED_ObservationClassTrial   1   DICOM/retired"]',
	},
	{
		label: 'RETIRED_ReferencedObservationUIDTrial (0040A172)',
		code: 'DICOMObject["0040A172"]',
	},
	{
		label: 'RETIRED_ReferencedObservationClassTrial (0040A173)',
		code: 'DICOMObject["0040A173"]',
	},
	{
		label: 'RETIRED_ReferencedObjectObservationClassTrial (0040A174)',
		code: 'DICOMObject["0040A174"]',
	},
	{
		label: 'RETIRED_ObservationDateTrial (0040A192)',
		code: 'DICOMObject["0040A192"]',
	},
	{
		label: 'RETIRED_ObservationTimeTrial (0040A193)',
		code: 'DICOMObject["0040A193"]',
	},
	{
		label: 'RETIRED_MeasurementAutomationTrial (0040A194)',
		code: 'DICOMObject["0040A194"]',
	},
	{
		label: 'RETIRED_IdentificationDescriptionTrial (0040A224)',
		code: 'DICOMObject["0040A224"]',
	},
	{
		label: 'RETIRED_CoordinatesSetGeometricTypeTrial (0040A290)',
		code: 'DICOMObject["0040A290"]',
	},
	{
		label: 'RETIRED_AlgorithmCodeSequenceTrial (0040A296)',
		code: 'DICOMObject["0040A296"]',
	},
	{
		label: 'RETIRED_AlgorithmDescriptionTrial (0040A297)',
		code: 'DICOMObject["0040A297"]',
	},
	{
		label: 'RETIRED_PixelCoordinatesSetTrial (0040A29A)',
		code: 'DICOMObject["0040A29A"]',
	},
	{
		label: 'RETIRED_CurrentObserverTrial (0040A307)',
		code: 'DICOMObject["0040A307"]',
	},
	{
		label: 'RETIRED_ReferencedAccessionSequenceTrial (0040A313)',
		code: 'DICOMObject["0040A313"]',
	},
	{
		label: 'RETIRED_ReportStatusCommentTrial (0040A33A)',
		code: 'DICOMObject["0040A33A"]',
	},
	{
		label: 'RETIRED_ProcedureContextSequenceTrial (0040A340)',
		code: 'DICOMObject["0040A340"]',
	},
	{
		label: 'RETIRED_VerbalSourceTrial (0040A352)',
		code: 'DICOMObject["0040A352"]',
	},
	{
		label: 'RETIRED_AddressTrial (0040A353)',
		code: 'DICOMObject["0040A353"]',
	},
	{
		label: 'RETIRED_TelephoneNumberTrial (0040A354)',
		code: 'DICOMObject["0040A354"]',
	},
	{
		label: 'RETIRED_VerbalSourceIdentifierCodeSequenceTrial (0040A358)',
		code: 'DICOMObject["0040A358"]',
	},
	{
		label: 'RETIRED_ReportDetailSequenceTrial (0040A380)',
		code: 'DICOMObject["0040A380"]',
	},
	{
		label: 'RETIRED_ObservationSubjectUIDTrial (0040A402)',
		code: 'DICOMObject["0040A402"]',
	},
	{
		label: 'RETIRED_ObservationSubjectClassTrial (0040A403)',
		code: 'DICOMObject["0040A403"]',
	},
	{
		label: 'RETIRED_ObservationSubjectTypeCodeSequenceTrial (0040A404)',
		code: 'DICOMObject["0040A404"]',
	},
	{
		label: 'RETIRED_ObservationSubjectContextFlagTrial (0040A600)',
		code: 'DICOMObject["0040A600"]',
	},
	{
		label: 'RETIRED_ObserverContextFlagTrial (0040A601)',
		code: 'DICOMObject["0040A601"]',
	},
	{
		label: 'RETIRED_ProcedureContextFlagTrial (0040A603)',
		code: 'DICOMObject["0040A603"]',
	},
	{
		label: 'RETIRED_RelationshipSequenceTrial (0040A731)',
		code: 'DICOMObject["0040A731"]',
	},
	{
		label: 'RETIRED_RelationshipTypeCodeSequenceTrial (0040A732)',
		code: 'DICOMObject["0040A732"]',
	},
	{
		label: 'RETIRED_LanguageCodeSequenceTrial (0040A744)',
		code: 'DICOMObject["0040A744"]',
	},
	{
		label: 'RETIRED_UniformResourceLocatorTrial (0040A992)',
		code: 'DICOMObject["0040A992"]',
	},
	{
		label: 'RETIRED_TemplateVersion (0040DB06)',
		code: 'DICOMObject["0040DB06"]',
	},
	{
		label: 'RETIRED_TemplateLocalVersion (0040DB07)',
		code: 'DICOMObject["0040DB07"]',
	},
	{
		label: 'RETIRED_TemplateExtensionFlag (0040DB0B)',
		code: 'DICOMObject["0040DB0B"]',
	},
	{
		label: 'RETIRED_TemplateExtensionOrganizationUID (0040DB0C)',
		code: 'DICOMObject["0040DB0C"]',
	},
	{
		label: 'RETIRED_TemplateExtensionCreatorUID (0040DB0D)',
		code: 'DICOMObject["0040DB0D"]',
	},
	{
		label: 'RETIRED_CountsIncluded (00541400)',
		code: 'DICOMObject["00541400"]',
	},
	{
		label: 'RETIRED_DeadTimeCorrectionFlag (00541401)',
		code: 'DICOMObject["00541401"]',
	},
	{
		label: 'RETIRED_ImageRotationRetired (00700040)',
		code: 'DICOMObject["00700040"]',
	},
	{
		label: 'RETIRED_DisplayedAreaTopLeftHandCornerTrial (00700050)',
		code: 'DICOMObject["00700050"]',
	},
	{
		label: 'RETIRED_DisplayedAreaBottomRightHandCornerTrial (00700051)',
		code: 'DICOMObject["00700051"]',
	},
	{
		label: 'RETIRED_GraphicLayerRecommendedDisplayRGBValue (00700067)',
		code: 'DICOMObject["00700067"]',
	},
	{
		label: 'RETIRED_BeamOrderIndexTrial (00741024)',
		code: 'DICOMObject["00741024"]',
	},
	{
		label: 'RETIRED_DoubleExposureMetersetTrial (00741038)',
		code: 'DICOMObject["00741038"]',
	},
	{
		label: 'RETIRED_DoubleExposureFieldDeltaTrial (0074103A)',
		code: 'DICOMObject["0074103A"]',
	},
	{
		label: 'RETIRED_RelatedProcedureStepSequence (00741220)',
		code: 'DICOMObject["00741220"]',
	},
	{
		label: 'RETIRED_ProcedureStepRelationshipType (00741222)',
		code: 'DICOMObject["00741222"]',
	},
	{
		label: 'RETIRED_TopicTitle (00880904)',
		code: 'DICOMObject["00880904"]',
	},
	{
		label: 'RETIRED_TopicSubject (00880906)',
		code: 'DICOMObject["00880906"]',
	},
	{
		label: 'RETIRED_TopicAuthor (00880910)',
		code: 'DICOMObject["00880910"]',
	},
	{
		label: 'RETIRED_TopicKeywords (00880912)',
		code: 'DICOMObject["00880912"]',
	},
	{
		label: 'undefined (# according to the DICOM standard the following 6 attributes)',
		code: 'DICOMObject["# according to the DICOM standard the following 6 attributes"]',
	},
	{
		label: 'undefined (# should have a tag in the range of 1000xxx0 to 1000xxx5)',
		code: 'DICOMObject["# should have a tag in the range of 1000xxx0 to 1000xxx5"]',
	},
	{
		label: 'RETIRED_EscapeTriplet (10000010)',
		code: 'DICOMObject["10000010"]',
	},
	{
		label: 'RETIRED_RunLengthTriplet (10000011)',
		code: 'DICOMObject["10000011"]',
	},
	{
		label: 'RETIRED_HuffmanTableSize (10000012)',
		code: 'DICOMObject["10000012"]',
	},
	{
		label: 'RETIRED_HuffmanTableTriplet (10000013)',
		code: 'DICOMObject["10000013"]',
	},
	{
		label: 'RETIRED_ShiftTableSize (10000014)',
		code: 'DICOMObject["10000014"]',
	},
	{
		label: 'RETIRED_ShiftTableTriplet (10000015)',
		code: 'DICOMObject["10000015"]',
	},
	{
		label: 'undefined (# according to the DICOM standard the following attribute)',
		code: 'DICOMObject["# according to the DICOM standard the following attribute"]',
	},
	{
		label: 'undefined (# should have the tag 1010xxxx where "x" is "0" to "F")',
		code: 'DICOMObject["# should have the tag 1010xxxx where "x" is "0" to "F""]',
	},
	{
		label: 'RETIRED_ZonalMap (10100004)',
		code: 'DICOMObject["10100004"]',
	},
	{
		label: 'RETIRED_ColorImagePrintingFlag (20000062)',
		code: 'DICOMObject["20000062"]',
	},
	{
		label: 'RETIRED_CollationFlag (20000063)',
		code: 'DICOMObject["20000063"]',
	},
	{
		label: 'RETIRED_AnnotationFlag (20000065)',
		code: 'DICOMObject["20000065"]',
	},
	{
		label: 'RETIRED_ImageOverlayFlag (20000067)',
		code: 'DICOMObject["20000067"]',
	},
	{
		label: 'RETIRED_PresentationLUTFlag (20000069)',
		code: 'DICOMObject["20000069"]',
	},
	{
		label: 'RETIRED_ImageBoxPresentationLUTFlag (2000006A)',
		code: 'DICOMObject["2000006A"]',
	},
	{
		label: 'RETIRED_ReferencedStoredPrintSequence (20000510)',
		code: 'DICOMObject["20000510"]',
	},
	{
		label: 'RETIRED_ReferencedImageOverlayBoxSequence (20200130)',
		code: 'DICOMObject["20200130"]',
	},
	{
		label: 'RETIRED_ReferencedVOILUTBoxSequence (20200140)',
		code: 'DICOMObject["20200140"]',
	},
	{
		label: 'RETIRED_ReferencedOverlayPlaneSequence (20400010)',
		code: 'DICOMObject["20400010"]',
	},
	{
		label: 'RETIRED_ReferencedOverlayPlaneGroups (20400011)',
		code: 'DICOMObject["20400011"]',
	},
	{
		label: 'RETIRED_OverlayPixelDataSequence (20400020)',
		code: 'DICOMObject["20400020"]',
	},
	{
		label: 'RETIRED_OverlayMagnificationType (20400060)',
		code: 'DICOMObject["20400060"]',
	},
	{
		label: 'RETIRED_OverlaySmoothingType (20400070)',
		code: 'DICOMObject["20400070"]',
	},
	{
		label: 'RETIRED_OverlayOrImageMagnification (20400072)',
		code: 'DICOMObject["20400072"]',
	},
	{
		label: 'RETIRED_MagnifyToNumberOfColumns (20400074)',
		code: 'DICOMObject["20400074"]',
	},
	{
		label: 'RETIRED_OverlayForegroundDensity (20400080)',
		code: 'DICOMObject["20400080"]',
	},
	{
		label: 'RETIRED_OverlayBackgroundDensity (20400082)',
		code: 'DICOMObject["20400082"]',
	},
	{
		label: 'RETIRED_OverlayMode (20400090)',
		code: 'DICOMObject["20400090"]',
	},
	{
		label: 'RETIRED_ThresholdDensity (20400100)',
		code: 'DICOMObject["20400100"]',
	},
	{
		label: 'RETIRED_ReferencedImageBoxSequenceRetired (20400500)',
		code: 'DICOMObject["20400500"]',
	},
	{
		label: 'RETIRED_PrintJobID (21000010)',
		code: 'DICOMObject["21000010"]',
	},
	{
		label: 'RETIRED_DestinationAE (21000140)',
		code: 'DICOMObject["21000140"]',
	},
	{
		label: 'RETIRED_ReferencedPrintJobSequencePullStoredPrint (21000500)',
		code: 'DICOMObject["21000500"]',
	},
	{
		label: 'RETIRED_PrintQueueID (21100099)',
		code: 'DICOMObject["21100099"]',
	},
	{
		label: 'RETIRED_QueueStatus (21200010)',
		code: 'DICOMObject["21200010"]',
	},
	{
		label: 'RETIRED_PrintJobDescriptionSequence (21200050)',
		code: 'DICOMObject["21200050"]',
	},
	{
		label: 'RETIRED_ReferencedPrintJobSequence (21200070)',
		code: 'DICOMObject["21200070"]',
	},
	{
		label: 'RETIRED_PrintManagementCapabilitiesSequence (21300010)',
		code: 'DICOMObject["21300010"]',
	},
	{
		label: 'RETIRED_PrinterCharacteristicsSequence (21300015)',
		code: 'DICOMObject["21300015"]',
	},
	{
		label: 'RETIRED_FilmBoxContentSequence (21300030)',
		code: 'DICOMObject["21300030"]',
	},
	{
		label: 'RETIRED_ImageBoxContentSequence (21300040)',
		code: 'DICOMObject["21300040"]',
	},
	{
		label: 'RETIRED_AnnotationContentSequence (21300050)',
		code: 'DICOMObject["21300050"]',
	},
	{
		label: 'RETIRED_ImageOverlayBoxContentSequence (21300060)',
		code: 'DICOMObject["21300060"]',
	},
	{
		label: 'RETIRED_PresentationLUTContentSequence (21300080)',
		code: 'DICOMObject["21300080"]',
	},
	{
		label: 'RETIRED_ProposedStudySequence (213000A0)',
		code: 'DICOMObject["213000A0"]',
	},
	{
		label: 'RETIRED_OriginalImageSequence (213000C0)',
		code: 'DICOMObject["213000C0"]',
	},
	{
		label: 'RETIRED_FrameOfReferenceRelationshipSequence (300600C0)',
		code: 'DICOMObject["300600C0"]',
	},
	{
		label: 'RETIRED_RelatedFrameOfReferenceUID (300600C2)',
		code: 'DICOMObject["300600C2"]',
	},
	{
		label: 'RETIRED_FrameOfReferenceTransformationType (300600C4)',
		code: 'DICOMObject["300600C4"]',
	},
	{
		label: 'RETIRED_BeamDosePointDepth (300A0088)',
		code: 'DICOMObject["300A0088"]',
	},
	{
		label: 'RETIRED_BeamDosePointEquivalentDepth (300A0089)',
		code: 'DICOMObject["300A0089"]',
	},
	{
		label: 'RETIRED_BeamDosePointSSD (300A008A)',
		code: 'DICOMObject["300A008A"]',
	},
	{
		label: 'RETIRED_Arbitrary (40000010)',
		code: 'DICOMObject["40000010"]',
	},
	{
		label: 'RETIRED_TextComments (40004000)',
		code: 'DICOMObject["40004000"]',
	},
	{
		label: 'RETIRED_ResultsID (40080040)',
		code: 'DICOMObject["40080040"]',
	},
	{
		label: 'RETIRED_ResultsIDIssuer (40080042)',
		code: 'DICOMObject["40080042"]',
	},
	{
		label: 'RETIRED_ReferencedInterpretationSequence (40080050)',
		code: 'DICOMObject["40080050"]',
	},
	{
		label: 'RETIRED_ReportProductionStatusTrial (400800FF)',
		code: 'DICOMObject["400800FF"]',
	},
	{
		label: 'RETIRED_InterpretationRecordedDate (40080100)',
		code: 'DICOMObject["40080100"]',
	},
	{
		label: 'RETIRED_InterpretationRecordedTime (40080101)',
		code: 'DICOMObject["40080101"]',
	},
	{
		label: 'RETIRED_InterpretationRecorder (40080102)',
		code: 'DICOMObject["40080102"]',
	},
	{
		label: 'RETIRED_ReferenceToRecordedSound (40080103)',
		code: 'DICOMObject["40080103"]',
	},
	{
		label: 'RETIRED_InterpretationTranscriptionDate (40080108)',
		code: 'DICOMObject["40080108"]',
	},
	{
		label: 'RETIRED_InterpretationTranscriptionTime (40080109)',
		code: 'DICOMObject["40080109"]',
	},
	{
		label: 'RETIRED_InterpretationTranscriber (4008010A)',
		code: 'DICOMObject["4008010A"]',
	},
	{
		label: 'RETIRED_InterpretationText (4008010B)',
		code: 'DICOMObject["4008010B"]',
	},
	{
		label: 'RETIRED_InterpretationAuthor (4008010C)',
		code: 'DICOMObject["4008010C"]',
	},
	{
		label: 'RETIRED_InterpretationApproverSequence (40080111)',
		code: 'DICOMObject["40080111"]',
	},
	{
		label: 'RETIRED_InterpretationApprovalDate (40080112)',
		code: 'DICOMObject["40080112"]',
	},
	{
		label: 'RETIRED_InterpretationApprovalTime (40080113)',
		code: 'DICOMObject["40080113"]',
	},
	{
		label: 'RETIRED_PhysicianApprovingInterpretation (40080114)',
		code: 'DICOMObject["40080114"]',
	},
	{
		label: 'RETIRED_InterpretationDiagnosisDescription (40080115)',
		code: 'DICOMObject["40080115"]',
	},
	{
		label: 'RETIRED_InterpretationDiagnosisCodeSequence (40080117)',
		code: 'DICOMObject["40080117"]',
	},
	{
		label: 'RETIRED_ResultsDistributionListSequence (40080118)',
		code: 'DICOMObject["40080118"]',
	},
	{
		label: 'RETIRED_DistributionName (40080119)',
		code: 'DICOMObject["40080119"]',
	},
	{
		label: 'RETIRED_DistributionAddress (4008011A)',
		code: 'DICOMObject["4008011A"]',
	},
	{
		label: 'RETIRED_InterpretationID (40080200)',
		code: 'DICOMObject["40080200"]',
	},
	{
		label: 'RETIRED_InterpretationIDIssuer (40080202)',
		code: 'DICOMObject["40080202"]',
	},
	{
		label: 'RETIRED_InterpretationTypeID (40080210)',
		code: 'DICOMObject["40080210"]',
	},
	{
		label: 'RETIRED_InterpretationStatusID (40080212)',
		code: 'DICOMObject["40080212"]',
	},
	{
		label: 'RETIRED_Impressions (40080300)',
		code: 'DICOMObject["40080300"]',
	},
	{
		label: 'RETIRED_ResultsComments (40084000)',
		code: 'DICOMObject["40084000"]',
	},
	{
		label: 'RETIRED_CurveDimensions (50000005)',
		code: 'DICOMObject["50000005"]',
	},
	{
		label: 'RETIRED_NumberOfPoints (50000010)',
		code: 'DICOMObject["50000010"]',
	},
	{
		label: 'RETIRED_TypeOfData (50000020)',
		code: 'DICOMObject["50000020"]',
	},
	{
		label: 'RETIRED_CurveDescription (50000022)',
		code: 'DICOMObject["50000022"]',
	},
	{
		label: 'RETIRED_AxisUnits (50000030)',
		code: 'DICOMObject["50000030"]',
	},
	{
		label: 'RETIRED_AxisLabels (50000040)',
		code: 'DICOMObject["50000040"]',
	},
	{
		label: 'RETIRED_DataValueRepresentation (50000103)',
		code: 'DICOMObject["50000103"]',
	},
	{
		label: 'RETIRED_MinimumCoordinateValue (50000104)',
		code: 'DICOMObject["50000104"]',
	},
	{
		label: 'RETIRED_MaximumCoordinateValue (50000105)',
		code: 'DICOMObject["50000105"]',
	},
	{
		label: 'RETIRED_CurveRange (50000106)',
		code: 'DICOMObject["50000106"]',
	},
	{
		label: 'RETIRED_CurveDataDescriptor (50000110)',
		code: 'DICOMObject["50000110"]',
	},
	{
		label: 'RETIRED_CoordinateStartValue (50000112)',
		code: 'DICOMObject["50000112"]',
	},
	{
		label: 'RETIRED_CoordinateStepValue (50000114)',
		code: 'DICOMObject["50000114"]',
	},
	{
		label: 'RETIRED_CurveActivationLayer (50001001)',
		code: 'DICOMObject["50001001"]',
	},
	{
		label: 'RETIRED_AudioType (50002000)',
		code: 'DICOMObject["50002000"]',
	},
	{
		label: 'RETIRED_AudioSampleFormat (50002002)',
		code: 'DICOMObject["50002002"]',
	},
	{
		label: 'RETIRED_NumberOfChannels (50002004)',
		code: 'DICOMObject["50002004"]',
	},
	{
		label: 'RETIRED_NumberOfSamples (50002006)',
		code: 'DICOMObject["50002006"]',
	},
	{
		label: 'RETIRED_SampleRate (50002008)',
		code: 'DICOMObject["50002008"]',
	},
	{
		label: 'RETIRED_TotalTime (5000200A)',
		code: 'DICOMObject["5000200A"]',
	},
	{
		label: 'RETIRED_AudioSampleData (5000200C)',
		code: 'DICOMObject["5000200C"]',
	},
	{
		label: 'RETIRED_AudioComments (5000200E)',
		code: 'DICOMObject["5000200E"]',
	},
	{
		label: 'RETIRED_CurveLabel (50002500)',
		code: 'DICOMObject["50002500"]',
	},
	{
		label: 'RETIRED_CurveReferencedOverlaySequence (50002600)',
		code: 'DICOMObject["50002600"]',
	},
	{
		label: 'RETIRED_CurveReferencedOverlayGroup (50002610)',
		code: 'DICOMObject["50002610"]',
	},
	{
		label: 'RETIRED_CurveData (50003000)',
		code: 'DICOMObject["50003000"]',
	},
	{
		label: 'RETIRED_OverlayPlanes (60000012)',
		code: 'DICOMObject["60000012"]',
	},
	{
		label: 'RETIRED_OverlayPlaneOrigin (60000052)',
		code: 'DICOMObject["60000052"]',
	},
	{
		label: 'RETIRED_OverlayCompressionCode (60000060)',
		code: 'DICOMObject["60000060"]',
	},
	{
		label: 'RETIRED_OverlayCompressionOriginator (60000061)',
		code: 'DICOMObject["60000061"]',
	},
	{
		label: 'RETIRED_OverlayCompressionLabel (60000062)',
		code: 'DICOMObject["60000062"]',
	},
	{
		label: 'RETIRED_OverlayCompressionDescription (60000063)',
		code: 'DICOMObject["60000063"]',
	},
	{
		label: 'RETIRED_OverlayCompressionStepPointers (60000066)',
		code: 'DICOMObject["60000066"]',
	},
	{
		label: 'RETIRED_OverlayRepeatInterval (60000068)',
		code: 'DICOMObject["60000068"]',
	},
	{
		label: 'RETIRED_OverlayBitsGrouped (60000069)',
		code: 'DICOMObject["60000069"]',
	},
	{
		label: 'RETIRED_OverlayFormat (60000110)',
		code: 'DICOMObject["60000110"]',
	},
	{
		label: 'RETIRED_OverlayLocation (60000200)',
		code: 'DICOMObject["60000200"]',
	},
	{
		label: 'RETIRED_OverlayCodeLabel (60000800)',
		code: 'DICOMObject["60000800"]',
	},
	{
		label: 'RETIRED_OverlayNumberOfTables (60000802)',
		code: 'DICOMObject["60000802"]',
	},
	{
		label: 'RETIRED_OverlayCodeTableLocation (60000803)',
		code: 'DICOMObject["60000803"]',
	},
	{
		label: 'RETIRED_OverlayBitsForCodeWord (60000804)',
		code: 'DICOMObject["60000804"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorGray (60001100)',
		code: 'DICOMObject["60001100"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorRed (60001101)',
		code: 'DICOMObject["60001101"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorGreen (60001102)',
		code: 'DICOMObject["60001102"]',
	},
	{
		label: 'RETIRED_OverlayDescriptorBlue (60001103)',
		code: 'DICOMObject["60001103"]',
	},
	{
		label: 'RETIRED_OverlaysGray (60001200)',
		code: 'DICOMObject["60001200"]',
	},
	{
		label: 'RETIRED_OverlaysRed (60001201)',
		code: 'DICOMObject["60001201"]',
	},
	{
		label: 'RETIRED_OverlaysGreen (60001202)',
		code: 'DICOMObject["60001202"]',
	},
	{
		label: 'RETIRED_OverlaysBlue (60001203)',
		code: 'DICOMObject["60001203"]',
	},
	{
		label: 'RETIRED_OverlayComments (60004000)',
		code: 'DICOMObject["60004000"]',
	},
	{
		label: 'RETIRED_CoefficientsSDVN (7FE00020)',
		code: 'DICOMObject["7FE00020"]',
	},
	{
		label: 'RETIRED_CoefficientsSDHN (7FE00030)',
		code: 'DICOMObject["7FE00030"]',
	},
	{
		label: 'RETIRED_CoefficientsSDDN (7FE00040)',
		code: 'DICOMObject["7FE00040"]',
	},
	{
		label: 'RETIRED_VariablePixelData (7F000010)',
		code: 'DICOMObject["7F000010"]',
	},
	{
		label: 'RETIRED_VariableNextDataGroup (7F000011)',
		code: 'DICOMObject["7F000011"]',
	},
	{
		label: 'RETIRED_VariableCoefficientsSDVN (7F000020)',
		code: 'DICOMObject["7F000020"]',
	},
	{
		label: 'RETIRED_VariableCoefficientsSDHN (7F000030)',
		code: 'DICOMObject["7F000030"]',
	},
	{
		label: 'RETIRED_VariableCoefficientsSDDN (7F000040)',
		code: 'DICOMObject["7F000040"]',
	},
];
