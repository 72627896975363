import React from 'react';

export default props => (
	<svg width={12} height={16} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M3 12.4985H9V10.9985H3V12.4985ZM3 9.49854H9V7.99854H3V9.49854ZM1.5 15.4985C1.0875 15.4985 0.734375 15.3517 0.440625 15.0579C0.146875 14.7642 0 14.411 0 13.9985V1.99854C0 1.58604 0.146875 1.23291 0.440625 0.93916C0.734375 0.64541 1.0875 0.498535 1.5 0.498535H7.5L12 4.99854V13.9985C12 14.411 11.8531 14.7642 11.5594 15.0579C11.2656 15.3517 10.9125 15.4985 10.5 15.4985H1.5ZM6.75 5.74854V1.99854H1.5V13.9985H10.5V5.74854H6.75Z"
			fill="currentColor"
		/>
	</svg>
);
