import updateSynchronizedScreen from './updateSynchronizedScreen';

const addSynchronizedScreen = ({ synchronizedScreens, newScreen }) => {
	const screenNumberIndex = synchronizedScreens.current.findIndex(
		(synchronizedScreen) => synchronizedScreen.screenNumber === newScreen.screenNumber
	);

	if (screenNumberIndex !== -1) {
		return updateSynchronizedScreen({ synchronizedScreens, updatedScreen: newScreen });
	}

	synchronizedScreens.current.push(newScreen);
};

export default addSynchronizedScreen;
