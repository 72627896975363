import React, { createContext, useContext, useState } from 'react';

const ValueSetContext = createContext({});

const ValueSetContextProvider = ({ children }) => {
	const [modalityList, setModalityList] = useState([]);
	const [bodyPartList, setBodyPartList] = useState([]);
	const [lateralityList, setLateralityList] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [sexList, setSexList] = useState([]);
	const [procedureCodeList, setProcedureCodeList] = useState([]);
	const [valueSetLoaded, setValueSetLoaded] = useState(false);

	return (
		<ValueSetContext.Provider
			value={{
				modalityList,
				setModalityList,
				bodyPartList,
				setBodyPartList,
				lateralityList,
				setLateralityList,
				statusList,
				setStatusList,
				sexList,
				setSexList,
				procedureCodeList,
				setProcedureCodeList,
				valueSetLoaded,
				setValueSetLoaded,
			}}
		>
			{children}
		</ValueSetContext.Provider>
	);
};

const useValueSetContext = () => useContext(ValueSetContext);

export { ValueSetContext, useValueSetContext, ValueSetContextProvider as default };
