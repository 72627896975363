// @flow

import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';

export const calculatePatientAge = (birthDate) => {
	if (!birthDate) {
		return;
	}

	const today = new Date();
	const birthDateObj = new Date(birthDate);

	let patientAge = today.getFullYear() - birthDateObj.getFullYear(); // calculate age
	const monthDiff = today.getMonth() - birthDateObj.getMonth();

	// Adjust the age if the current month is before the birth month, or if it's the same month but today's date is before the birth date
	if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
		patientAge--;
	}

	if (patientAge >= 2) {
		patientAge = `${patientAge}y`;
	} else if (patientAge >= 0 && patientAge < 2) {
		if (today.toDateString() === birthDateObj.toDateString()) {
			patientAge = `${1}d`;
		} else {
			const ageInMonths = differenceInMonths(today, birthDateObj);
			if (ageInMonths >= 1) {
				patientAge = `${ageInMonths}m`;
			} else {
				const ageInDays = differenceInDays(today, birthDateObj);
				if (ageInDays === 30) {
					// This is where a month has 31 days, ex: studyDate = Dec 08 2023, birthDate = Jan 07 2024
					// differenceInMonths = 0 and ageInDays = 30. It does not make sense to show 30d in this case -> show 1m
					patientAge = `${1}m`;
				} else if (ageInDays > 0) {
					patientAge = `${ageInDays}d`;
				}
			}
		}
	}

	return patientAge;
};
