import React, { useState, useEffect } from 'react';
import { Typography, Grid, IconButton, Badge, Avatar, CircularProgress } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { generateThumbnail } from '@rs-ui/views/ImageViewerView3D/features/Import/utils/importUtils';
import { UploadingTypeToggle } from '../UploadingTypeToggle/UploadingTypeToggle';

export const ImportDialogItem = ({
	encapsulate,
	isBadgeVisible,
	badgeContent,
	onDelete,
	onChangeImportCapabilities,
	file,
	type,
	thumbnailAvailable,
}) => {
	const [src, setSrc] = useState(null);
	useEffect(() => {
		const getThumbnail = async () => {
			const srcUrl = await generateThumbnail(file, [40, 40]);
			setSrc(srcUrl);
		};
		if (thumbnailAvailable) {
			getThumbnail();
		}
	}, [file, thumbnailAvailable]);

	return (
		<Grid container alignItems="center" direction="row" justifyContent="space-between" wrap="nowrap">
			<Grid
				container
				alignItems="center"
				direction="row"
				sx={{
					overflow: 'hidden',
					gap: 2,
				}}
			>
				<Grid item>
					<Avatar src={src} sx={{ bgcolor: 'var(--rsprimary-main)' }} variant="square">
						{thumbnailAvailable ? !src && <CircularProgress /> : <AssignmentIcon />}
					</Avatar>
				</Grid>
				<Grid item>
					{type === 'pdf' && (
						<UploadingTypeToggle
							isActive={!!encapsulate}
							onChangeImportCapabilities={onChangeImportCapabilities}
						/>
					)}
					<Badge
						badgeContent={badgeContent}
						invisible={!isBadgeVisible}
						sx={{
							'& .MuiBadge-badge': {
								right: -27,
								background: 'var(--rsprimary-main)',
								color: '#fff',
								textTransform: 'none',
							},
						}}
					/>
					<Typography noWrap align="left" sx={{ color: '#fff', textTransform: 'none' }}>
						{file?.name}
					</Typography>
				</Grid>
			</Grid>

			<Grid item xs="auto">
				<IconButton
					edge="end"
					onClick={e => {
						e.stopPropagation();
						onDelete(e);
					}}
				>
					<CloseRoundedIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};
