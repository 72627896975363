import React from 'react';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box } from '@mui/system';
import { blueBox, Label, supportInfo, SupportText } from './helpStyles';
import HelpIcon from '@mui/icons-material/Help';

const LiveSupport = ({ setView }) => {
	return (
		<>
			<Box
				sx={{
					...blueBox,
					marginBottom: 19,
					'@media (max-width: 600px)': {
						margin: '0px !important',
						width: '338px !important',
						maxWidth: '338px!important',
						padding: '0px !important',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',

						'& > span': {
							margin: '0px !important',
						},
					},
				}}
				onClick={() => setView('faqs')}
			>
				<HelpIcon sx={{ fontSize: 55 }} />
				<h4 style={{ fontSize: '20px', margin: '11px 0 4px' }}>FAQ</h4>
				<span style={{ fontSize: 14, marginTop: 20 }}>
					Browse frequently asked
					<br />
					questions.
				</span>
			</Box>
			<Box
				sx={{
					...supportInfo,
					'@media (max-width: 600px)': {
						margin: '10px 0px 54px 0px !important',
						width: '338px !important',
						maxWidth: '338px!important',
						padding: '47px 0px !important',
						height: 'inherit',
					},
				}}
			>
				<SupportAgentIcon sx={{ fontSize: 55, marginBottom: '26.5px' }} />
				<Label>OFFICE</Label>
				<SupportText>+1 (888) 343-9146</SupportText>
				<Label style={{ marginTop: '31px' }}>EMAIL</Label>
				<SupportText style={{ marginBottom: '31px' }}>SUPPORT@RAMSOFT.COM</SupportText>
			</Box>
		</>
	);
};

export default LiveSupport;
