import getStudyDiagnosticReports from './getStudyDiagnosticReports';
import { DIAGNOSTIC_REPORT_STATUS } from '../consts/consts';

const getStudyPreliminaryDiagnosticReports = ({ studyId, diagnosticReports }) =>
	getStudyDiagnosticReports({ studyId, diagnosticReports }).filter(diagnosticReport => {
		const signDelayStatus = diagnosticReport?.extension?.find(element =>
			element?.url?.includes('signDelayStatus')
		)?.valueString;
		return (
			diagnosticReport.status === DIAGNOSTIC_REPORT_STATUS.PRELIMINARY ||
			(diagnosticReport.status === DIAGNOSTIC_REPORT_STATUS.UNKNOWN && signDelayStatus === 'started')
		);
	});

export default getStudyPreliminaryDiagnosticReports;
