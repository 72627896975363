const createPatientDocumentPayload = (patientId, attachment) => {
	return {
		resourceType: 'DocumentReference',
		id: -1,
		subject: {
			id: patientId,
			reference: `Patient/${patientId}`,
		},
		status: 'current',
		docStatus: 'final',
		category: [
			{
				coding: [
					{
						code: '56444-3',
						display: 'Healthcare communication Document',
					},
				],
			},
		],
		type: {
			coding: [
				{
					system: 'http://loinc.org',
					code: '55188-7',
					display: 'Patient data Document',
				},
			],
		},
		content: [
			{
				attachment: attachment,
			},
		],
	};
};

export default createPatientDocumentPayload;
