import { useSearchParams } from 'react-router-dom';

const useGetSearchParams = () => {
	const [searchParams] = useSearchParams();
	const patientInfo = searchParams.get('PatientInfo');
	const patientId = searchParams.get('patientId') || searchParams.get('PatientID');
	const orderId = searchParams.get('orderId');
	const studyId = searchParams.get('studyId') || searchParams.get('StudyId');
	const internalManagingOrganizationID = searchParams.get('internalManagingOrganizationID');
	const issuerOfPatientID = searchParams.get('IssuerOfPatientID');
	const popOutID = searchParams.get('popOutID');
	const isSharedStudy = searchParams.get('SharedStudy') == 'true';
	const studyInstanceUIDs = searchParams.get('StudyInstanceUIDs');
	const creatorBlumeId = searchParams.get('creatorBlumeId') || null;
	const documentId = searchParams.get('documentId');

	return {
		patientInfo,
		patientId,
		orderId,
		studyId,
		internalManagingOrganizationID,
		issuerOfPatientID,
		popOutID,
		isSharedStudy,
		studyInstanceUIDs,
		creatorBlumeId,
		documentId,
	};
};

export default useGetSearchParams;
