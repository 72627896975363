import React from 'react';

export default props => (
	<svg width={12} height={16} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6 9.49854C6.4125 9.49854 6.76562 9.35166 7.05937 9.05791C7.35312 8.76416 7.5 8.41104 7.5 7.99854C7.5 7.58604 7.35312 7.23291 7.05937 6.93916C6.76562 6.64541 6.4125 6.49854 6 6.49854C5.5875 6.49854 5.23438 6.64541 4.94062 6.93916C4.64687 7.23291 4.5 7.58604 4.5 7.99854C4.5 8.41104 4.64687 8.76416 4.94062 9.05791C5.23438 9.35166 5.5875 9.49854 6 9.49854ZM3 12.4985H9V12.0673C9 11.7673 8.91875 11.4923 8.75625 11.2423C8.59375 10.9923 8.36875 10.8048 8.08125 10.6798C7.75625 10.5423 7.42187 10.436 7.07812 10.361C6.73437 10.286 6.375 10.2485 6 10.2485C5.625 10.2485 5.26562 10.286 4.92188 10.361C4.57812 10.436 4.24375 10.5423 3.91875 10.6798C3.63125 10.8048 3.40625 10.9923 3.24375 11.2423C3.08125 11.4923 3 11.7673 3 12.0673V12.4985ZM10.5 15.4985H1.5C1.0875 15.4985 0.734375 15.3517 0.440625 15.0579C0.146875 14.7642 0 14.411 0 13.9985V1.99854C0 1.58604 0.146875 1.23291 0.440625 0.93916C0.734375 0.64541 1.0875 0.498535 1.5 0.498535H7.5L12 4.99854V13.9985C12 14.411 11.8531 14.7642 11.5594 15.0579C11.2656 15.3517 10.9125 15.4985 10.5 15.4985ZM10.5 13.9985V5.63604L6.8625 1.99854H1.5V13.9985H10.5Z"
			fill="currentColor"
		/>
	</svg>
);
